// deleteNodeFunctions.js

import { useCallback } from "react";

//JUST IF NEEDED IN THE FUTURE
export const useDeleteNodeWithoutRerouting = (setNodes) => {
  return useCallback(
    (nodeId) => {
      console.log("nodeId ", nodeId);
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    },
    [setNodes]
  );
};

export const useDeleteNode = (
  setEdges,
  nodes,
  edges,
  diagramDetails,
  setDiagramDetails,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  MarkerType
) => {

  return useCallback(
    (deleted) => {
      console.log("deleted",deleted);
      console.log("diagramDetails",diagramDetails);
      deleted.forEach((del) => {
        // Step 1: Find the node in diagramDetails where del.id matches detail.id
        const nodeToDelete = diagramDetails?.find(
          (detail) => del?.id?.toString() === detail?.id?.toString()
        );
        if (nodeToDelete) {
          // Step 2: Find the parent node in diagramDetails based on the node's ParentDBID
          const parentNode = diagramDetails.find(
            (item) => item?.Dbid?.toString() === nodeToDelete?.ParentDBID?.toString()
          );
          // Step 3: Find the child node in diagramDetails based on the node's ParentDBID
          const childNode = diagramDetails.find(
            (item) => item?.ParentDBID?.toString() === nodeToDelete?.Dbid?.toString()
          );
          if (parentNode && childNode) {
            // Step 4: Update childNode properties with parentNode details
            childNode.ParentDBID = parentNode.Dbid;
            childNode.Parent_Action = parentNode.TExecName;
            childNode.Parent_Explain = parentNode.Explain;
          }
          if (!parentNode && childNode) {
            // Step 5: Update childNode properties if no parentNode
            childNode.ParentDBID = "";
            childNode.Parent_Action = "";
            childNode.Parent_Explain = "";
          }
        }
      });

      // Proceed with deletion after logging parent details
      const updatedDiagramDetails = diagramDetails?.filter(
        (detail) => !deleted.some((del) => del.id.toString() === detail.id.toString())
      );

      console.log("updatedDiagramDetails ", updatedDiagramDetails);

      if (updatedDiagramDetails) {
        // Update diagramDetails with the filtered array
        setDiagramDetails(updatedDiagramDetails);
      }

      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
              animated: true,
              style: {
                strokeWidth: 4,
                stroke: "red",
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: "red",
              },
            }))
          );

          const updatedEdges = createdEdges.map((edge) => ({
            ...edge,
            timeoutId: setTimeout(() => {
              setEdges((prevEdges) =>
                prevEdges.map((prevEdge) =>
                  prevEdge.id === edge.id
                    ? {
                        ...prevEdge,
                        animated: false,
                        style: {
                          strokeWidth: 1,
                        },
                        markerEnd: {
                          type: MarkerType.ArrowClosed,
                          width: 15,
                          height: 15,
                        },
                      }
                    : prevEdge
                )
              );
            }, 3000),
          }));

          return [...remainingEdges, ...updatedEdges];
        }, edges)
      );
    },
    [
      setEdges,
      nodes,
      edges,
      diagramDetails,
      getIncomers,
      getOutgoers,
      getConnectedEdges,
      MarkerType,
    ]
  );
};






export const useUpdateNodeAndChildren = (nodes, edges) => {
  const updateNodeAndChildren = useCallback(
    (parentNode, node) => {
      // Calculate the new position based on the dragged node's position
      const offsetY = parentNode.position.y - node.position.y + 100;

      // Update the position of the current node
      node.position.y = node.position.y + offsetY;

      // Recursively update children nodes
      edges
        .filter((edge) => edge.source === node.id)
        .forEach((edge) => {
          const connectedNode = nodes.find((n) => n.id === edge.target);
          if (connectedNode) {
            updateNodeAndChildren(node, connectedNode);
          }
        });
    },
    [nodes, edges]
  );

  return updateNodeAndChildren;
};

export const useNodeDragStop = (
  setNodes,
  nodes,
  edges,
  updateNodeAndChildren
) => {
  return useCallback(
    (event, draggedNode) => {
      var connectedNodes = edges
        .filter((edge) => edge.source === draggedNode.id)
        .map((edge) => edge.target);

      // Update the dragged node and its connected nodes
      nodes.forEach((node) => {
        if (node.id === draggedNode.id || connectedNodes.includes(node.id)) {
          updateNodeAndChildren(draggedNode, node);
        }
      });

      setNodes([...nodes]); // Trigger a state update to re-render
    },
    [setNodes, nodes, edges, updateNodeAndChildren]
  );
};

// export const useNodeClick = (setSelectedNode, toggleDrawer) => {
//   return useCallback(
//     (event, clickedNode) => {
//       console.log("clickedNode ", clickedNode);
//       setSelectedNode(clickedNode);
//       toggleDrawer(2);
//     },
//     [setSelectedNode]
//   );
// };


// export const useNodeClick = (setSelectedNode, toggleDrawer ,setTabValue ,tabValue) => {
//   return useCallback(
//     (event, clickedNode) => {
//       console.log("clickedNode ", clickedNode);
//       setSelectedNode(clickedNode);
//       toggleDrawer(2);
//       if (tabValue !== "2"){
//         setTabValue("1")
//       }
      
//     },
//     [setSelectedNode]
//   );
// };


export const useNodeClick = (setSelectedNode, toggleDrawer, setTabValue, tabValue) => {
  return useCallback(
    (event, clickedNode) => {
      console.log("clickedNode ", clickedNode);
      setSelectedNode(clickedNode);
      toggleDrawer(2);
      setTabValue("1"); 
    },
    [setSelectedNode, toggleDrawer, setTabValue]
  );
};