import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import themeProvider from "../../ThemeProvider";
import { ThemeProvider } from "@mui/material";
import LoginView from "./LoginView";
import { LoginHelper } from "../../apiCalls/UserAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function LoginBusiness() {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    username: "",
    password: "",
    tenant: "",
  });

  function handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }
  const handleClearClick = (name) => {
    setState({
      ...state,
      [name]: "",
    });
  };

  //Login On Enter
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  //submission variables
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  // alert variables
  const alertRef = React.useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };
  async function handleSubmit(event) {
    event.preventDefault();
    console.log("State ", state);
    setSubmit(true);
    if (state.username !== "" && state.password !== "" && state.tenant) {
      console.log("SUBMIT");
      const response = await LoginHelper(
        state.username,
        state.password,
        state.tenant
      );
      console.log("response ", response);
      if (response === true) {
        setError("");
        setLoggedIn(true);
        navigate("/Landing");
      }
      // } else {
      //   setError("Wrong Infor");
      //   handleEditContactInfoClick();
      // }
    } else {
      if (state.username === "") {
        toast.error("Please enter a username");
      }
      if (state.password === "") {
        toast.error("Please enter a password");
      }
      if (state.tenant === "") {
        toast.error("Please enter a tenant");
      }
    }
  }

  //GoogleLogin

  function handleCallbackResponse(response) {
    console.log("Encoded JWT ID token " + response.credential);
    var userObject = jwtDecode(response.credential);
    console.log("userObject ", userObject);
    // setUser(userObject);
    localStorage.setItem("user", JSON.stringify(userObject));
    setLoggedIn(true);
  }

  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <LoginView
        state={state}
        setState={setState}
        handleClearClick={handleClearClick}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
        handleSubmit={handleSubmit}
        loggedIn={loggedIn}
        handleCallbackResponse={handleCallbackResponse}
        submit={submit}
      />
    </ThemeProvider>
  );
}
export default LoginBusiness;
