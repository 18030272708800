import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./multiSelector/MultiSelectorStyles.css";
import React, { useEffect, useState } from "react";

export default function SelectorData({ apiData, selected, setSelected }) {
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const isAllSelected =
    apiData.length > 0 && selected.length === apiData.length;

  useEffect(() => {
    const categoriesSet = new Set();
    apiData.forEach((section) => {
      categoriesSet.add(section.Category);
    });
    setUniqueCategories(Array.from(categoriesSet));
  }, [apiData]);

  const handleToggle = (value) => () => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.some(
        (section) => section.Category === value
      );

      if (isSelected) {
        return prevSelected.filter((section) => section.Category !== value);
      } else {
        const selectedSections = apiData.filter(
          (section) => section.Category === value
        );
        return [...prevSelected, ...selectedSections];
      }
    });
  };

  const handleSelectAll = () => {
    setSelected((prevSelected) => {
      return prevSelected.length === apiData.length ? [] : [...apiData];
    });
  };

  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (!areArraysEqual(selected, apiData)) {
      setSelected(apiData);
    }
  }, [apiData, setSelected]);


  return (
    <div className="selt-data">
      <List>
        <ListItem button onClick={handleSelectAll}>
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < apiData.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={isAllSelected ? "Unselect All" : "Select All"}
          />
        </ListItem>
        {/* {uniqueCategories.map((Category) => (
          !Category.includes("JSON") && (
            <ListItem key={Category} button onClick={handleToggle(Category)}>
              <ListItemIcon>
                <Checkbox
                  checked={selected.some((item) => item.Category === Category)}
                />
              </ListItemIcon>
              <ListItemText primary={Category} />
            </ListItem>
          )
        ))} */}

        {uniqueCategories
          .filter(
            (Category) =>
              Category.trim() !== "" &&
              !Category.includes("JSON") &&
              !Category.includes("DefaultStyles") && 
              !Category.includes("Theme Template")
          )
          .map((Category) => (
            <ListItem key={Category} button onClick={handleToggle(Category)}>
              <ListItemIcon>
                <Checkbox
                  checked={selected.some((item) => item.Category === Category)}
                />
              </ListItemIcon>
              <ListItemText primary={Category} />
            </ListItem>
          ))}
      </List>
    </div>
  );
}
