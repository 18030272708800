import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  MiniMap,
  MarkerType,
  Controls,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  Background,
  BackgroundVariant,
  ControlButton,
} from "reactflow";
import "reactflow/dist/style.css";
import DynamicNode from "../../nodes/DynamicNode.js";
import DesktopSidebar from "../../Components/diagramsComponents/sideBar/DesktopSideBar.js";
import "../../index.css";
// import { initialNodes, initialEdges } from "../../nodesAndEdges.js";
import { jsonData } from "../../nodesAndEdges.js";
import { useDrop, useDragOver } from "../../functions/DragnDrop.js";
import {
  useSave,
  useRestore,
  processData,
} from "../../functions/SaveAndRestore.js";
import { useLayoutedElements } from "../../functions/ElksAlignment.js";
import { useDeleteNode } from "../../functions/NodeFunctions.js";
import {
  useNodeDragStop,
  useUpdateNodeAndChildren,
  useNodeClick,
} from "../../functions/NodeFunctions.js";
import {
  useFlipArrow,
  useDeleteEdge,
  useEdgeClick,
} from "../../functions/EdgeFunctions.js";
import NavBar from "../../Components/navbarComponents/NavBar.js";
import { Theme } from "../../Components/IncludeFile/Theme.js";
import {
  createRequestBody,
  postResource,
  GetAccessKeyFromDemo24,
  loadLoginData,
} from "../../apiCalls/DiagramsAPI.js";
import FloatingEdge from "../../functions/FloatingEdge.js";
import FloatingConnectionLine from "../../functions/ConnectionLine.js";
import DrawerComponent from "../../Components/diagramsComponents/DrawerComponent.js";
import CustomMarkers from "../../functions/MarkerFunctions.js";
import { ToastContainer } from "react-toastify";
import NodeDrawerComponent from "../../Components/diagramsComponents/nodeDrawerComponents/NodeDrawerComponent.js";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import themeProvider from "../../ThemeProvider.js";
import { useAppContext } from "../../Components/diagramsComponents/RegionContext.js";
// const flowKey = "flowJson";

// const fitViewOptions = { padding: 4 };

const nodeTypes = {
  dynamicNode: DynamicNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

console.log("edgeTypes",edgeTypes)
const Diagrams = () => {
  //Get props
  const location = useLocation();
  //project selected from QinfoDiagHeader
  let { project } = location.state || {};
  const [diagram, setDiagram] = useState({});
  const [fetched, setFetched] = useState(false);
  const [properties, setProperties] = useState([]);
  const [diagramDetails, setDiagramDetails] = useState(null);
  const [initialDiagramDetails, setInitialDiagramDetails] = useState();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  //Tools by template
  const [data, setData] = useState(null);
  const { setViewport } = useReactFlow();

  const [tabValue, setTabValue] = useState("");

  //Get Tools By Template Backend call
  async function fetchTools(diagTempID) {
    const accessKey = await GetAccessKeyFromDemo24();
    const tools = await postResource(
      "GetQuickInfo",
      createRequestBody({
        accessKey: accessKey,
        guid: `${window.APIProvider.recordPath.ListDiagToolsByTemplate}`,
        param1: diagTempID.toString(),
      })
    );
    return tools;
  }

  //Get tools of this template
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchTools(project.diagTempID);
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [regions, setRegions] = React.useState([]);

  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";



  const fetchRegions1 = React.useCallback(async () => {
    if (!accessKey) {
      console.error("No access key available");
    }

    // setIsLoading(true);
    try {
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: "Get Regions",
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: "",
        })
      );

      setRegions(response);
      // setError(null);
      return response;
    } catch (err) {
    } finally {
      // setIsLoading(false);
    }
  }, [accessKey]);

  const getRegionInfo = useCallback(
    async (regionName) => {
      if (!regionName) return "HI";

      // Fetch regions if not already loaded
      if (!regions.length) {
        const ret = await fetchRegions1();

        if (ret) {
          return (
            ret.find(
              (region) =>
                region.Region.toLowerCase() === regionName.toLowerCase()
            ) || "1"
          );
        }
      } else {
        return (
          regions.find(
            (region) => region.Region.toLowerCase() === regionName.toLowerCase()
          ) || "2"
        );
      }
    },
    [regions, fetchRegions1]
  );

  //get info from RegionContext
  // const { getRegionInfo } = useAppContext();
  const [regionInfo, setRegionInfo] = useState(null);

  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      const info = await getRegionInfo(storedRegion);
      console.log("Region Names:", info);
      setRegionInfo(info);
      // Call fetchData only if info is valid and fetched is false
      if (!fetched && data && info) {
        getData(info);
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  React.useEffect(() => {
    fetchRegions();
  }, [fetched, data]);

  //get tool properties and initialize canvas
  async function getData(info) {
    try {
      setDiagram(project);
      var toolProperties;

      // toolProperties = JSON.parse(info.ToolsJson);
      
      if (info.ToolsJson && typeof info.ToolsJson === "string") {
        try {
          toolProperties = JSON.parse(info.ToolsJson);
        } catch (e) {
          console.error("Invalid ToolsJson:", e);
          toolProperties = null;
        }
      } else {
        console.error("info.ToolsJson is undefined or not a string");
        toolProperties = null;
      }

      if (project.region === "Generic") {
        setNodes(project.nodes ? JSON.parse(project.nodes) : []);
        setEdges(project.edges ? JSON.parse(project.edges) : []);
      } else {
        //get steps for initializing canvas
        const accessKey = await GetAccessKeyFromDemo24();
        const response = await postResource(
          "GetQuickInfo",
          createRequestBody({
            accessKey: accessKey,
            guid: `${window.APIProvider.recordPath.GetDiagramDetails}`,
            param1: project?.diagID?.toString(),
          })
        );
        console.log("steps on canvas ", response);
        const parsedResponse = JSON.parse(response[0].Column1);
        console.log("parsedResponse ", parsedResponse);
        let canvasNodes = [];
        let canvasEdges = [];
        if (parsedResponse) {
          const result = processData(parsedResponse, data, toolProperties);
          canvasNodes = result.nodes;
          canvasEdges = result.edges;
        }
        setDiagramDetails(parsedResponse);
        setInitialDiagramDetails(parsedResponse);
        setNodes(canvasNodes);
        setEdges(canvasEdges);
      }
      setProperties(toolProperties); // Update the state after fetching all data
      setFetched(true); // Set fetched to true after fetching
      return toolProperties;
    } catch (error) {
      throw error;
    }
  }
  // setViewport(
  //   diagram && diagram.viewPort && diagram.viewPort !== ""
  //     ? JSON.parse(diagram.viewPort)
  //     : { x: 0, y: 0, zoom: 1 }
  // );
  //for drawers
  const [openDrawer, setOpenDrawer] = useState(null);
  const [clickedEdge, setClickedEdge] = useState();
  const [selectedNode, setSelectedNode] = useState(null);
  //shrink the whole sidebar
  const [showmenudsk, setShowdskmenu] = useState(false);
  //React flow variables
  // const [nodes, setNodes, onNodesChange] = useNodesState([]);
  // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const reactFlowWrapper = useRef(null);
  const [rfInstance, setRfInstance] = useState(null);
  const { getLayoutedElements } = useLayoutedElements();
  const { getNode } = useReactFlow();
  //Function that control the toggle of the right drawer between (edge and node)
  const toggleDrawer = (drawerId) => {
    setOpenDrawer((prevDrawer) => (prevDrawer === drawerId ? null : drawerId));
  };
  //Drag and Drop
  const onDrop = useDrop(
    reactFlowWrapper,
    rfInstance,
    setNodes,
    diagramDetails,
    setDiagramDetails,
    project.region
  );
  const onDragOver = useDragOver();
  //Save and Restore
  // const onSave = useSave(flowKey, rfInstance);
  const onSave = useSave(
    diagram,
    rfInstance,
    setDiagram,
    diagramDetails,
    project.region
  );
  // const onRestore = useRestore(flowKey, setNodes, setEdges, setViewport);
  const onRestore = useRestore(
    diagram,
    setNodes,
    setEdges,
    setViewport,
    initialDiagramDetails,
    data,
    properties,
    project.region
  );
  //node functions
  //handle node change (for controlling delete)
  const handleNodesChange = (changes) => {
    const nextChanges = changes.reduce((acc, change) => {
      // if this change is supposed to remove a node we want to validate it first
      if (change.type === "remove") {
        const node = getNode(change.id);

        // if the node can be removed, keep the change, otherwise we skip the change and keep the node
        if (node?.data[0]?.isDeletable !== "false") {
          return [...acc, change];
        }

        // change is skipped, node is kept
        return acc;
      }

      // all other change types are just put into the next changes arr
      return [...acc, change];
    }, []);

    // apply the changes we kept
    onNodesChange(nextChanges);
  };
  //recursive node movement
  const updateNodeAndChildren = useUpdateNodeAndChildren(nodes, edges);
  const handleNodeDragStop = useNodeDragStop(
    setNodes,
    nodes,
    edges,
    updateNodeAndChildren
  );

  //delete node
  const onDeleteNode = useDeleteNode(
    setEdges,
    nodes,
    edges,
    diagramDetails,
    setDiagramDetails,
    getIncomers,
    getOutgoers,
    getConnectedEdges,
    MarkerType
  );

  
  //edge functions
  //flip edge
  const handleFlipArrow = useFlipArrow(setEdges);
  //delete edge
  const handleDeleteEdge = useDeleteEdge(setEdges);
  //on edge click
  // const handleEdgeClick = useEdgeClick(handleFlipArrow);
  // const handleEdgeClick = useEdgeClick(setClickedEdge, toggleDrawer);
  // const handleNodeClick = useNodeClick(setSelectedNode, toggleDrawer);


  // const handleEdgeClick = useEdgeClick(
  //   setClickedEdge,
  //   toggleDrawer,
  //   setTabValue,
  //   tabValue
  // );
  // const handleNodeClick = useNodeClick(
  //   setSelectedNode,
  //   toggleDrawer,
  //   setTabValue,
  //   tabValue
  // );

  const handleEdgeClick = useEdgeClick(setClickedEdge, toggleDrawer, setTabValue);
const handleNodeClick = useNodeClick(setSelectedNode, toggleDrawer, setTabValue, tabValue);




  //The default edge
  const defaultEdgeOptions = {
    // type: "smoothstep",
    type: "bezier",
        markerEnd: {
      type: null,
      width: 15,
      height: 15,
    },
    style: { 
      strokeWidth: 2,
    },

    animated: true
  };

  //creating a new edge
  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   []
  // );
  // const onConnect = useCallback(
  //   ({ source, target }) => {
  //     return setEdges((eds) =>
  //       nodes
  //         .filter((node) => node.id === source || node.selected)
  //         .reduce((eds, node) => addEdge({ source: node.id, target }, eds), eds)
  //     );
  //   },
  //   [nodes]
  // );

  //when 2 nodes get connected by a line this function gets called
  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;
      const sourceNode = nodes.find((node) => node.id === source);
      const edgeId = `e${source}-${target}${params.sourceHandle}${params.targetHandle}`;
      let edgeType = "bezier";

      if (sourceNode?.data[0]?.nodeType === "0") {
        edgeType = "smoothstep";
      }

      if (project.region.toLowerCase() === "ai ui") {
        console.log("diagramDetails ", diagramDetails);

        const targetDetailIndex = diagramDetails?.findIndex(
          (detail) => detail.id === target
        );
        const sourceDetail = diagramDetails?.find(
          (detail) => detail.id === source
        );

        if (targetDetailIndex !== -1 && sourceDetail) {
          // Create a copy of diagramDetails to update the targetDetail
          const updatedDiagramDetails = [...diagramDetails];

          //update to accept multiple parents
          updatedDiagramDetails[targetDetailIndex] = {
            ...updatedDiagramDetails[targetDetailIndex],
            ParentDBID: sourceDetail.Dbid,
            Parent_Action: sourceDetail.TExecName,
            Parent_Explain: sourceDetail.Explain,
          };

          // Update the state with the modified diagramDetails
          setDiagramDetails(updatedDiagramDetails);

          console.log("Updated diagramDetails ", updatedDiagramDetails);
        }
      }

      return setEdges((eds) =>
        addEdge({ ...params, id: edgeId, type: edgeType }, eds)
      );
    },
    [nodes, setEdges, diagramDetails, project.region]
  );

  //Form
  const navigate = useNavigate();

  const handleOpenClick = () => {
    // Navigate to the "/diagrams" route
    navigate("/Select");
  };

  //search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    console.log("e.target.value ", e.target.value);
    setSearchQuery(e.target.value);
  };
  const leftButton = {
    icon: Theme.Open,
    text: "Open",
    tooltip: "Click to open a different project or view a different template",
    handleClick: () => {
      // Handle click for the left button
      handleOpenClick();
    },
  };
  
 
  const rightButtons = [
    {
      icon: Theme.Save,
      text: "Save",
      color: "secondary",
      tooltip: "Click to save your canvas",
      handleClick: () => {
        onSave();
      },
    },
    {
      icon: Theme.Restore,
      text: "Restore",
      color: "secondary",
      tooltip: "Click to restore your canvas to the way it initially was",
      handleClick: () => {
        onRestore();
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      tooltip: "Click to go back to previous page",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  const [backgroundColor, setBackgroundColor] = useState(
    themeProvider.palette.bg.lightWhite
  );

  const changeBackgroundColor = () => {
    setBackgroundColor(
      backgroundColor === themeProvider.palette.bg.lightWhite
        ? "#d3d3d3"
        : themeProvider.palette.bg.lightWhite
    );
  };
  console.log("nodes ", nodes);
  console.log("edges ", edges);
  const handleMenushwDskButtonClick = () => {
    setShowdskmenu(!showmenudsk);
    console.log("big")
  };
  return (
    <>
      <CustomMarkers />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        isSearchBar={true}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        leftButton={leftButton}
        rightButtons={rightButtons}
        title={project.title}
        icon = {true}
        handleMenushwDskButtonClick= {handleMenushwDskButtonClick}
      />
      <div className={`dndflow`}>
        <DesktopSidebar
          showmenudsk={showmenudsk}
          setShowdskmenu={setShowdskmenu}
          nodes={data}
          properties={properties}
          diagID={diagram.diagID}
          startPanelsOpen={false}
          region={project.region}
          searchQuery={searchQuery}
        />
        <div
          className="reactflow-wrapper"
          ref={reactFlowWrapper}
          style={{
            display: "flex",
            width: "100%",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={handleNodesChange}
            onNodesDelete={onDeleteNode}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setRfInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            // onNodeDragStop={handleNodeDragStop}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodeClick={handleNodeClick}
            // onNodeContextMenu={handleNodeClick}
            connectionLineComponent={FloatingConnectionLine}
            onEdgeClick={handleEdgeClick}
            defaultEdgeOptions={defaultEdgeOptions}
            connectionMode="loose"
            edgeHitTestThreshold={100}
            style={{ background: backgroundColor }}
            // fitView
            // fitViewOptions={fitViewOptions}
          >
            {backgroundColor === themeProvider.palette.bg.lightWhite && (
              // <Background />
              //   <Background
              //   id="1"
              //   gap={10}
              //   color="#f1f1f1"
              //   variant={BackgroundVariant.Lines}
              // />

              <Background
                id="2"
                gap={20}
                color="#f1f1f1"
                variant={BackgroundVariant.Lines}
              />
            )}
            <Controls>
              <ControlButton
                onClick={() =>
                  getLayoutedElements({
                    "elk.algorithm": "layered",
                    "elk.direction": "RIGHT",
                    // "elk.direction": "LEFT",
                  })
                }
              >
                <AlignHorizontalLeftIcon />
              </ControlButton>
              <ControlButton
                onClick={() =>
                  getLayoutedElements({
                    "elk.algorithm": "layered",
                    "elk.direction": "DOWN",
                  })
                }
              >
                <AlignVerticalTopIcon />
              </ControlButton>
              <ControlButton
                onClick={() =>
                  getLayoutedElements({
                    "elk.algorithm": "org.eclipse.elk.force",
                  })
                }
              >
                <AlignVerticalCenterIcon />
              </ControlButton>
              <ControlButton onClick={changeBackgroundColor}>
                <FormatColorFillIcon />
              </ControlButton>
            </Controls>
            {/* <MiniMap /> */}
          </ReactFlow>
          {/* {tabValue === "2" && clickedEdge !== undefined &&(
            <DrawerComponent
              // isOpen={openDrawer}
              // onClose={toggleDrawer}
              edges={edges}
              setEdges={setEdges}
              clickedEdge={clickedEdge}
              diagramDetails={diagramDetails}
              setDiagramDetails={setDiagramDetails}
              setClickedEdge={setClickedEdge}
            />
          )}
          {tabValue === "1" && (
            <NodeDrawerComponent
              isOpen={true}
              onClose={toggleDrawer}
              nodes={nodes}
              setNodes={setNodes}
              selectedNode={selectedNode}
              region={project.region}
              diagramDetails={diagramDetails}
              setDiagramDetails={setDiagramDetails}
              data={data}
              onNodesDelete={onDeleteNode}
              setSelectedNode={setSelectedNode}
            />
          )} */}

{tabValue === "1" || tabValue === "2" ? (
      <NodeDrawerComponent
        isOpen={true}
        onClose={toggleDrawer}
        tabValue={tabValue}
        setTabValue={setTabValue}
        nodes={nodes}
        setNodes={setNodes}
        selectedNode={selectedNode}
        edges={edges}
        setEdges={setEdges}
        clickedEdge={clickedEdge}
        setClickedEdge={setClickedEdge}
        diagramDetails={diagramDetails}
        setDiagramDetails={setDiagramDetails}
        data={data}
        setSelectedNode={setSelectedNode}
        region={project.region}
      />
    ) : null}
        </div>
      </div>
    </>
  );
};

// export default Diagrams;
export default () => (
  <ReactFlowProvider>
    <Diagrams />
  </ReactFlowProvider>
);
