import React from "react";
import { Theme } from "../IncludeFile/Theme";
import themeProvider from "../../ThemeProvider";
import { ThemeProvider } from "@mui/material";
import "./sideBarCollapse.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

//when we implement dark and light mode
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }));

//direction options [0 = left | 1 = right | 2 = up | 3= down]
function SidebarCollapse({ showmenudsk, setShowdskmenu, direction }) {
  // const handleMenushwDskButtonClick = () => {
  //   setShowdskmenu(!showmenudsk);
  // };

  return (
    <ThemeProvider theme={themeProvider}>
      {/* <div
        className={
          direction === 0
            ? "sidebar-left-panel" + (showmenudsk ? " reverticn" : "")
            : direction === 1
              ? "sidebar-right-panel" + (showmenudsk ? " reverticn" : "")
              : direction === 2
                ? "sidebar-up-panel" + (showmenudsk ? " reverticn" : "")
                : "sidebar-down-panel" + (showmenudsk ? " reverticn" : "")
        }
        onClick={handleMenushwDskButtonClick}
      >
        <Tooltip
          arrow
          title="Show/Hide panel"
          placement={
            direction === 0
              ? "right-end"
              : direction === 1
                ? "left-end"
                : direction === 2
                  ? "bottom"
                  : "top"
          }
        >
          {Theme.ChevronRight}
        </Tooltip>
      </div> */}
    </ThemeProvider>
  );
}

export default SidebarCollapse;
