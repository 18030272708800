import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { RxStretchHorizontally } from "react-icons/rx";
import { BsAlignCenter } from "react-icons/bs";
import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
} from "react-icons/pi";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";

const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const AlignItems = ({
  matchedStyles,
  updateDefaultStyle,
  alignSelfValue,
  setAlignSelfValue,
  setDefaultStyleState,
  defaultStyle,
  setDefaultStyle,
  styleType,
}) => {
  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );

  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: { ...style.style, alignSelf: alignSelfValue },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
      // setDefaultStyleState(updatedStyle);
    }
  }, [alignSelfValue, styleType]);

  const handleButtonClick = (value) => {
    setAlignSelfValue(value);
  };

  return (
    <StyledAlignItems className="right-alig-top-edit">
      <PropertiesAccordion title={"Align Items"}>
        <div className="topicon topicon-for-edit-proprty">
          <div className="iconset">
            <div
              className={
                alignSelfValue === "flex-start"
                  ? "iconbtnflxtop-active-proprty"
                  : "iconbtnflxtop-proprty"
              }
              onClick={() => {
                handleButtonClick("flex-start");
              }}
            >
              <PiAlignLeftSimpleDuotone />
              <span className="tooltiptext">Left</span>
            </div>
          </div>
          <div className="iconset">
            <div
              className={
                alignSelfValue === "center"
                  ? "iconbtnflxtop-active-proprty"
                  : "iconbtnflxtop-proprty"
              }
              onClick={() => handleButtonClick("center")}
            >
              <BsAlignCenter />
              <span className="tooltiptext">Center</span>
            </div>
          </div>
          <div className="iconset">
            <div
              className={
                alignSelfValue === "flex-end"
                  ? "iconbtnflxtop-active-proprty"
                  : "iconbtnflxtop-proprty"
              }
              onClick={() => handleButtonClick("flex-end")}
            >
              <PiAlignRightSimpleBold />
              <span className="tooltiptext">Right</span>
            </div>
          </div>
          <div className="iconset">
            <div
              className={
                alignSelfValue === "stretch"
                  ? "iconbtnflxtop-active-proprty"
                  : "iconbtnflxtop-proprty"
              }
              onClick={() => handleButtonClick("stretch")}
            >
              <RxStretchHorizontally />
              <span className="tooltiptext">Stretch</span>
            </div>
          </div>
        </div>
        </PropertiesAccordion>
      
    </StyledAlignItems>
  );
};

export default AlignItems;
