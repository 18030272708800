import {
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { countries } from "../IncludeFile/Theme";
import { Textarea } from "@chakra-ui/react";
import {
  AddressJSON,
  CountryJSON,
  GenderCheckBoxJSON,
  GenderJSON,
  StarJSON,
} from "../IncludeFile/AddJSON";
import { useDataGridProProps } from "@mui/x-data-grid-pro/DataGridPro/useDataGridProProps";

const OptionSection = ({
  rightsideStyletab,
  controlsVisible,
  selectedItem,
  treeData,
  setTreeData,
  selectedItemID,
  defaultStyle,
  updateCustomStyle,
  CheckboxCheckedStreet1,
  setCheckboxCheckedStreet1,
  CheckboxCheckedStreet2,
  setCheckboxCheckedStreet2,
  CheckboxCheckedPostal,
  setCheckboxCheckedPostal,
  CheckboxCheckedCity,
  setCheckboxCheckedCity,
  CheckboxCheckedState,
  CheckboxCheckedCountry,
  setCheckboxCheckedCountry,
  setCheckboxCheckedState,
  dataSourceArID,
  fieldData,
  fieldNames,
  setFieldNames,
  fieldNamestable,
}) => {
  const [predefinedOption, setPredefinedOption] = useState("");
  const [defaultValues, setDefaultValues] = useState([]);
  const [selectedDefaultValue, setSelectedDefaultValue] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [textareaDataCheckBox, setTextareaDataCheckBox] = useState("");
  const [inputTextCheckbox, setInputTextCheckbox] = useState("");
  const [inputTextRadio, setInputTextRadio] = useState("");
  const [DateField, SetDateField] = useState([]);

  const findNodeById = (controlArray, id) => {
    if (!Array.isArray(controlArray)) {
      return null;
    }

    for (const control of controlArray) {
      if (control.id === id) {
        return control;
      }
      if (control.control && control.control.length > 0) {
        const found = findNodeById(control.control, id); // Recursively search for the node
        if (found) return found;
      }
    }
    return null;
  };
  //-------------------------------------Table-----------------------

  const [selectedValues, setSelectedValues] = useState({});

  const tableFieldNames = fieldData
    ? fieldData
        .filter((item) => item.elementtype === "array" && item.type === "table")
        .flatMap((item) => item.elements.map((element) => element.fieldname))
    : [];

  const extractTableControlDatas = (obj) => {
    let tableControlDatas = [];

    if (!obj) return tableControlDatas;

    const traverse = (obj) => {
      if (obj === null || typeof obj !== "object") {
        return;
      }
      if (Array.isArray(obj)) {
        obj.forEach((item) => traverse(item));
      } else if (typeof obj === "object") {
        if (
          (obj.type === "Table" && obj.control && obj.htmlTag === "td") ||
          (obj.controltype === "Row" && obj.control && obj.htmlTag === "td") ||
          (obj.type === "Table" && obj.data && obj.htmlTag === "td")
        ) {
          const dataWithoutBrackets = obj.data.replace(/[{}]/g, "").trim();
          tableControlDatas.push({
            option: obj.option || obj.option,
            data: dataWithoutBrackets,
          });
          obj.control &&
            obj.control.forEach((controlItem) => traverse(controlItem));
        } else {
          Object.values(obj).forEach((value) => traverse(value));
        }
      }
    };

    traverse(obj);

    return tableControlDatas;
  };

  const extractTableControlNames = (sourceData) => {
    const tableControlNames = [];
    const traverse = (obj) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => traverse(item));
      } else if (typeof obj === "object" && obj !== null) {
        if (obj.type === "Table" && obj.control) {
          obj.control.forEach((controlItem) => {
            if (
              controlItem.htmlTag === "th" &&
              controlItem.name &&
              controlItem.type === "Table"
            ) {
              tableControlNames.push(controlItem.name);
            }
            traverse(controlItem.control);
          });
        } else if (obj.type === "Row" && obj.control) {
          obj.control.forEach((controlItem) => {
            if (
              controlItem.htmlTag === "th" &&
              controlItem.name &&
              controlItem.type === "Table"
            ) {
              tableControlNames.push(controlItem.name);
            }
            traverse(controlItem.control);
          });
        } else if (obj.type === "Table" && obj.name && obj.htmlTag === "th") {
          tableControlNames.push(obj.name);
        } else {
          Object.values(obj).forEach((value) => traverse(value));
        }
      }
    };

    traverse(sourceData);
    return tableControlNames;
  };

  const tableNames = treeData ? extractTableControlNames(treeData) : [];

  const tableControlDatas = extractTableControlDatas(treeData);

  const handleSelectChange1 = (inputName, newValue) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [inputName]: newValue,
    }));
  };

  //--------------------------------------------------------Table------------------

  useEffect(() => {
    const fieldNamesDate = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "datetime") ||
              (item.elementtype === "field" && item.fieldtype === "date") ||
              (item.elementtype === "field" && item.fieldtype === "time")
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    SetDateField(fieldNamesDate);
  }, [fieldData]);

  useEffect(() => {
    setCheckboxCheckedCity(true);
    setCheckboxCheckedState(true);
    setCheckboxCheckedCountry(true);
    setCheckboxCheckedStreet1(true);
    setCheckboxCheckedStreet2(true);
    setCheckboxCheckedPostal(true);
  }, []);

  const applyStylesRecursively = (control, defaultStyle, parentID = "") => {
    const defaultSty = defaultStyle.find(
      (style) => style.styleType === control.styleType
    );

    const newID = parentID
      ? `${parentID}.${control.id.split(".").pop()}`
      : control.id;

    let updatedStyles = { ...defaultSty?.style };

    if (
      control.media === "CityState" ||
      control.media === "Address" ||
      control.media === "PostalCountry"
    ) {
      updatedStyles.flexDirection = "row";
    }

    if (control.media === "AddressHeader") {
      updatedStyles.width = "20%";
    }

    if (control.media === "AddressInput") {
      updatedStyles.width = "80%";
    }

    if (control.switchoption === "AddressContainer") {
      updatedStyles.marginTop = "0px";
      updatedStyles.marginLeft = "0px";
      updatedStyles.marginRight = "0px";
      updatedStyles.marginBottom = "0px";
      updatedStyles.paddingTop = "0px";

      updatedStyles.paddingRight = "0px";
      updatedStyles.paddingBottom = "0px";
      updatedStyles.paddingLeft = "0px";
    }

    const updatedControl = {
      ...control,
      id: newID,
      styles: JSON.stringify(updatedStyles),
    };

    if (updatedControl.control && updatedControl.control.length > 0) {
      updatedControl.control = updatedControl.control.map(
        (child, childIndex) => {
          const childNewID = `${newID}.${childIndex + 1}`;
          return applyStylesRecursively(
            { ...child, id: childNewID },
            defaultStyle,
            newID
          );
        }
      );
    }

    return updatedControl;
  };

  const calculateNextStyleIdBlocks = () => {
    const deletedItemId = localStorage.getItem("deletedItemId");

    const deletedItemNumber = deletedItemId
      ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
      : -1;

    let maxStyleId = 0;

    const findMaxStyleId = (node) => {
      const styleIdRegex = /([a-zA-Z]+)(\d*)/;
      if (typeof node.styleid === "string") {
        const matches = node.styleid.match(styleIdRegex);
        if (matches && matches[1] === "agrfb") {
          const currentId = parseInt(matches[2]);
          if (!isNaN(currentId) && currentId > maxStyleId) {
            maxStyleId = currentId;
          }
        }
      }

      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          findMaxStyleId(childNode);
        }
      }
    };

    treeData[0].Source[0].control.forEach((rootNode) => {
      findMaxStyleId(rootNode);
    });

    const nextStyleId =
      deletedItemNumber > maxStyleId ? deletedItemNumber + 1 : maxStyleId + 1;

    return `agrfb${nextStyleId}`;
  };

  function findMediaCity(treeData) {
    const foundNodes = [];

    function searchTree(node) {
      if (node.media === "City") {
        foundNodes.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodes;
  }

  const foundNodes = findMediaCity(AddressJSON[0]);

  const handleCheckboxChange1 = (event) => {
    const isChecked = event.target.checked;
    setCheckboxCheckedCity(isChecked);
    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      const findContainerSec = (controlArray) => {
        for (let control of controlArray) {
          if (control.media === "ContainerSec") {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundContainer = findContainerSec(control.control);
            if (foundContainer) return foundContainer;
          }
        }
        return null;
      };

      const containerSec = findContainerSec(selectedControl.control);

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "City") {
              if (containerSec) {
                containerSec.styles = JSON.stringify({
                  ...JSON.parse(containerSec.styles),
                  display: "none",
                  marginRight: CheckboxCheckedCity === true ? "50px" : "0px",
                });
              }
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        if (containerSec) {
          containerSec.styles = JSON.stringify({
            ...JSON.parse(containerSec.styles),
            display: "flex",

            marginRight: CheckboxCheckedState === true ? "10px" : "0px",
          });
        }

        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "City" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "City" && control.controltype === "TextBox"
        );

        if (!labelExists || !textBoxExists) {
          const newControls = [];
          const index = 0;
          const currentMaxStyleId = calculateNextStyleIdBlocks();
          const styleIdNumber = parseInt(
            currentMaxStyleId.replace(/[a-zA-Z]/g, "")
          );
          const newStyleId = `agrfb${styleIdNumber + index}`;
          const newStyleIDn = `agrfb${styleIdNumber + index + 1}`;

          const containerSec1 = findContainerSec(selectedControl.control);

          if (!labelExists) {
            const newLabel = {
              ...foundNodes[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);
            newControls.push(updatedLabel);
          }

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodes[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );
            newControls.push(updatedTextBox);
          }

          if (containerSec) {
            containerSec.control = [...containerSec.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  function findMediaStreet2(treeData) {
    const foundNodesStreet2 = [];

    function searchTree(node) {
      if (node.media === "Street2") {
        foundNodesStreet2.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodesStreet2;
  }

  const foundNodesStreet2 = findMediaStreet2(AddressJSON[0]);

  const handleCheckboxChangeStreet2 = (event) => {
    const isChecked = event.target.checked;
    setCheckboxCheckedStreet2(isChecked);
    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "Street2") {
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "Street2" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "Street2" && control.controltype === "TextBox"
        );

        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );
        const newStyleId = `agrfb${styleIdNumber + index}`;

        if (!labelExists || !textBoxExists) {
          const newControls = [];
          const findContainerSec = (controlArray) => {
            for (let control of controlArray) {
              if (control.media === "ContainerStreet2") {
                return control;
              }
              if (control.control && control.control.length > 0) {
                const foundContainer = findContainerSec(control.control);
                if (foundContainer) return foundContainer;
              }
            }
            return null;
          };
          const containerSec1 = findContainerSec(selectedControl.control);

          if (!labelExists) {
            const newLabel = {
              ...foundNodesStreet2[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);

            newControls.push(updatedLabel);
          }

          const newStyleIDn = newStyleId + 1;

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesStreet2[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );

            newControls.push(updatedTextBox);
          }

          const containerSec = findContainerSec(selectedControl.control);

          if (containerSec) {
            containerSec.control = [...containerSec.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  function findMediaStreet1(treeData) {
    const foundNodesStreet1 = [];

    function searchTree(node) {
      if (node.media === "Street1") {
        foundNodesStreet1.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodesStreet1;
  }

  const foundNodesStreet1 = findMediaStreet1(AddressJSON[0]);

  const handleCheckboxChangeStreet1 = (event) => {
    const isChecked = event.target.checked;
    setCheckboxCheckedStreet1(isChecked);
    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "Street1") {
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "Street1" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "Street1" && control.controltype === "TextBox"
        );

        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );
        const newStyleId = `agrfb${styleIdNumber + index}`;
        const findContainerSec = (controlArray) => {
          for (let control of controlArray) {
            if (control.media === "ContainerStreet1") {
              return control;
            }
            if (control.control && control.control.length > 0) {
              const foundContainer = findContainerSec(control.control);
              if (foundContainer) return foundContainer;
            }
          }
          return null;
        };

        const containerSec1 = findContainerSec(selectedControl.control);

        if (!labelExists || !textBoxExists) {
          const newControls = [];

          if (!labelExists) {
            const newLabel = {
              ...foundNodesStreet1[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);

            newControls.push(updatedLabel);
          }

          const newStyleIDn = newStyleId + 1;

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesStreet1[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );

            newControls.push(updatedTextBox);
          }

          const containerSec = findContainerSec(selectedControl.control);

          if (containerSec) {
            containerSec.control = [...containerSec.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  function findMediaStreetLine2(treeData) {
    const foundNodesStreet2 = [];

    function searchTree(node) {
      if (node.media === "State") {
        foundNodesStreet2.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodesStreet2;
  }

  const foundNodesState = findMediaStreetLine2(AddressJSON[0]);

  const handleCheckboxChangeState = (event) => {
    const isChecked = event.target.checked;
    setCheckboxCheckedState(isChecked);

    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      const findContainerSec = (controlArray) => {
        for (let control of controlArray) {
          if (control.media === "ContainerThird") {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundContainer = findContainerSec(control.control);
            if (foundContainer) return foundContainer;
          }
        }
        return null;
      };

      const containerSec1 = findContainerSec(selectedControl.control);

      if (containerSec1) {
        containerSec1.styles = JSON.stringify({
          ...JSON.parse(containerSec1.styles || "{}"),
          display: isChecked ? "flex" : "none",
        });
      }

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "State") {
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "State" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "State" && control.controltype === "TextBox"
        );

        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );
        const newStyleId = `agrfb${styleIdNumber + index}`;

        if (!labelExists || !textBoxExists) {
          const newControls = [];

          if (!labelExists) {
            const newLabel = {
              ...foundNodesState[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);
            newControls.push(updatedLabel);
          }

          const newStyleIDn = newStyleId + 1;

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesState[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );
            newControls.push(updatedTextBox);
          }

          if (containerSec1) {
            containerSec1.control = [...containerSec1.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  function findMediaPostal(treeData) {
    const foundNodes = [];

    function searchTree(node) {
      if (node.media === "Postal") {
        foundNodes.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodes;
  }

  const foundNodesPostal = findMediaPostal(AddressJSON[0]);

  const handleCheckboxChangePostal = (event) => {
    const isChecked = event.target.checked;
    setCheckboxCheckedPostal(isChecked);
    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      const findContainerSec = (controlArray) => {
        for (let control of controlArray) {
          if (control.media === "ContainerPostal") {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundContainer = findContainerSec(control.control);
            if (foundContainer) return foundContainer;
          }
        }
        return null;
      };

      const containerSec = findContainerSec(selectedControl.control);

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "Postal") {
              if (containerSec) {
                containerSec.styles = JSON.stringify({
                  ...JSON.parse(containerSec.styles),
                  display: "none",
                });
              }
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        if (containerSec) {
          containerSec.styles = JSON.stringify({
            ...JSON.parse(containerSec.styles),
            display: "flex",
            marginRight: "10px",
          });
        }

        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "Postal" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "Postal" && control.controltype === "TextBox"
        );

        if (!labelExists || !textBoxExists) {
          const newControls = [];
          const index = 0;
          const currentMaxStyleId = calculateNextStyleIdBlocks();
          const styleIdNumber = parseInt(
            currentMaxStyleId.replace(/[a-zA-Z]/g, "")
          );
          const newStyleId = `agrfb${styleIdNumber + index}`;
          const newStyleIDn = `agrfb${styleIdNumber + index + 1}`;

          const containerSec1 = findContainerSec(selectedControl.control);

          if (!labelExists) {
            const newLabel = {
              ...foundNodesPostal[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);
            newControls.push(updatedLabel);
          }

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesPostal[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );
            newControls.push(updatedTextBox);
          }

          if (containerSec) {
            containerSec.control = [...containerSec.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  const addCountryJSONToContainer = (controlArray, mediaToAdd, countryJSON) => {
    return controlArray.map((control) => {
      if (control.media === mediaToAdd && control.control.length === 0) {
        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );

        const styledCountryJSON = countryJSON.map((json, index) => {
          const newStyleId = `agrfb${styleIdNumber + index}`;
          return {
            ...applyStylesRecursively(json, defaultStyle),
            id: `${control.id}.${index + 1}`,
            styleid: newStyleId,
          };
        });

        control.control.push(...styledCountryJSON);
      }

      if (control.control && control.control.length > 0) {
        control.control = addCountryJSONToContainer(
          control.control,
          mediaToAdd,
          countryJSON
        );
      }
      return control;
    });
  };

  const handleCheckboxChangeCountry = (event) => {
    const isChecked = event.target.checked;
    setCheckboxCheckedCountry(isChecked);

    const findControlById = (controlArray, id) => {
      for (let control of controlArray) {
        if (control.id === id) {
          return control;
        }
        if (control.control && control.control.length > 0) {
          const foundControl = findControlById(control.control, id);
          if (foundControl) return foundControl;
        }
      }
      return null;
    };

    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) {
        return prevTreeData;
      }

      const findContainerCountry = (controlArray) => {
        for (let control of controlArray) {
          if (control.media === "ContainerCountry") {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundContainer = findContainerCountry(control.control);
            if (foundContainer) return foundContainer;
          }
        }
        return null;
      };

      const containerCountry = findContainerCountry(selectedControl.control);

      if (isChecked) {
        if (containerCountry) {
          containerCountry.styles = JSON.stringify({
            ...JSON.parse(containerCountry.styles || "{}"),
            display: "flex",
          });
        }

        selectedControl.control = addCountryJSONToContainer(
          selectedControl.control,
          "ContainerCountry",
          CountryJSON
        );
      } else {
        if (containerCountry) {
          containerCountry.styles = JSON.stringify({
            ...JSON.parse(containerCountry.styles || "{}"),
            display: "none",
          });
        }

        const removeControls = (controlArray, mediaToRemove) => {
          return controlArray.filter((control) => {
            if (control.media === mediaToRemove) {
              return false;
            }

            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control, mediaToRemove);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(
          selectedControl.control,
          "Country"
        );
      }

      return updatedTreeData;
    });
  };

  const updateTreeDatanewValueAddressCountryTextbox = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressCountryTextbox(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressCountryTextbox = (
    controls,
    selectedItemID,
    newValue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryTextbox(
            control.control,
            newValue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressCountryTextbox(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressCountryTextbox = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "CountryTextbox") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryTextbox(
            control.control,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const findAddressCountryTextboxData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsCountryTextbox(
          source.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsCountryTextbox = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressCountryTextboxControl = findAddressCountryTextboxControl(
          control.control
        );
        if (addressCountryTextboxControl) {
          return addressCountryTextboxControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsCountryTextbox(
          control.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressCountryTextboxControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "CountryTextbox") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressCountryTextboxControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleCountryChange = (event) => {
    const newValue = event.target.value;
    setSelectedCountry(newValue);

    const updatedTreeDataAddressCountryTextbox =
      updateTreeDatanewValueAddressCountryTextbox(
        treeData,
        selectedItemID,
        newValue
      );
    setTreeData(updatedTreeDataAddressCountryTextbox);
  };

  useEffect(() => {
    if (selectedItem?.controltype === "Address") {
      const initialDataCountryTextbox = findAddressCountryTextboxData(
        treeData,
        selectedItemID
      );
      setSelectedCountry(initialDataCountryTextbox);
    }
  }, [treeData, selectedItemID, selectedItem]);

  // -------------
  const handleBoxToggle = () => {
    const isChecked = !switchCheckedRadio;
    setSwitchCheckedRadio(isChecked);

    if (isChecked) {
      setTextFieldValueRadio("Other");
      addNewGenderJsonEntrySwitchRadio("Other");
    } else {
      setTextFieldValueRadio("");
      removeGenderJsonEntrySwitchRadio();
    }
  };

  const handleBoxTogglecheckbox = () => {
    const isChecked = !switchCheckedCheckBox;
    setSwitchCheckedCheckBox(isChecked);

    if (isChecked) {
      setTextFieldValueCheckBox("Other");
      addNewGenderJsonEntrySwitchCheckBox("Other");
    } else {
      setTextFieldValueCheckBox("");
      removeGenderJsonEntrySwitchCheckBox();
    }
  };
  // -------------
  const Years = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990",
    "1989",
    "1988",
    "1987",
    "1986",
    "1985",
    "1984",
    "1983",
    "1982",
    "1981",
    "1980",
    "1979",
    "1978",
    "1977",
    "1976",
    "1975",
    "1974",
    "1973",
    "1972",
    "1971",
    "1970",
    "1969",
    "1968",
    "1967",
    "1966",
    "1965",
    "1964",
    "1963",
    "1962",
    "1961",
    "1960",
    "1959",
    "1958",
    "1957",
    "1956",
    "1955",
    "1954",
    "1953",
    "1952",
    "1951",
    "1950",
    "1949",
    "1948",
    "1947",
    "1946",
    "1945",
    "1944",
    "1943",
    "1942",
    "1941",
    "1940",
    "1939",
    "1938",
    "1937",
    "1936",
    "1935",
    "1934",
    "1933",
    "1932",
    "1931",
    "1930",
    "1929",
    "1928",
    "1927",
    "1926",
    "1925",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const genders = ["Male", "Female", "Non-Binary"];

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const None = ["data1", "data2", "data3", "data4"];

  const [noneValue, setNoneValue] = useState(None);

  const updateTreeDatanewValueAddressCountryDropdown = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressCountryDropdown(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressCountryDropdown = (
    controls,
    selectedItemID,
    newValue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryDropdown(
            control.control,
            newValue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressCountryDropdown(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressCountryDropdown = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "DropDown") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryDropdown(
            control.control,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const handlePredefinedOptionChange = (event) => {
    const selectedOption = event.target.value;
    setPredefinedOption(selectedOption);

    switch (selectedOption) {
      case "Years":
        setDefaultValues(Years);
        break;
      case "Countries":
        setDefaultValues(countries.filter((country) => country.trim() !== ""));
        break;
      case "Months":
        setDefaultValues(months);
        break;
      case "Gender":
        setDefaultValues(genders);
        break;
      case "Days":
        setDefaultValues(days);
        break;
      case "None":
        setDefaultValues(noneValue);
        break;
      default:
        setDefaultValues([noneValue]);
    }
  };

  const handleDefaultValueChange = (event) => {
    const selectedValue = event.target.value;

    setSelectedDefaultValue(selectedValue);

    const combinedValue = defaultValues
      .filter((value) => value.toString().trim() !== "")
      .map((value) => value.toString().trim())
      .sort((a, b) => (a === selectedValue ? -1 : b === selectedValue ? 1 : 0))
      .join(", ");

    const updatedTreeDataAddressCountryDropdown =
      updateTreeDatanewValueAddressCountryDropdown(
        treeData,
        selectedItemID,
        combinedValue
      );

    setTreeData(updatedTreeDataAddressCountryDropdown);
  };

  const [editedDataDropDown, setEditedDataDropDown] = useState("");

  //--------------------editedData DropDown---------------------

  const updateTreeDatanewValueAddressDropDown = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressDropDown(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressDropDown = (
    controls,
    selectedItemID,
    newValue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressDropDown(
            control.control,
            newValue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressDropDown(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressDropDown = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "DropDown") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressDropDown(
            control.control,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const findAddressDropDownData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsDropDown(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsDropDown = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressDropDownControl = findAddressDropDownControl(
          control.control
        );
        if (addressDropDownControl) {
          return addressDropDownControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsDropDown(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressDropDownControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "DropDown") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressDropDownControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChangeAddressDropDown = (event) => {
    const newValue = event.target.value;
    const newValueArray = newValue.split(/[,|\n]/).map((item) => item.trim());

    setEditedDataDropDown(newValue);
    setNoneValue(newValueArray);
    setDefaultValues(newValueArray);
    const updatedTreeDataAddressDropDown =
      updateTreeDatanewValueAddressDropDown(treeData, selectedItemID, newValue);
    setTreeData(updatedTreeDataAddressDropDown);
  };

  useEffect(() => {
    if (selectedItem?.controltype === "DropDown") {
      const initialDataDropDown = findAddressDropDownData(
        treeData,
        selectedItemID
      );
      setEditedDataDropDown(initialDataDropDown);
    }
  }, [treeData, selectedItemID, selectedItem]);

  const [textareaDataRadio, setTextareaDataRadio] = useState("");

  const [selectedArrayName, setSelectedArrayName] = useState("");
  const [selectedArrayNameCheckbox, setSelectedArrayNameCheckbox] =
    useState("");
  const [selectedArrayNameRadio, setSelectedArrayNameRadio] = useState("");

  useEffect(() => {
    const findRadioData = (controlArray) => {
      let radioData = [];

      controlArray.forEach((control) => {
        if (control.id === selectedItem.id) {
          const childControls = control.control;

          childControls.forEach((container) => {
            if (container.control) {
              const filtered = container.control.filter(
                (control) => control.media === "Radio"
              );
              radioData.push(...filtered);
            }
          });
        }
      });

      return radioData.filter((item) => item.media === "Radio");
    };

    if (
      treeData &&
      treeData[0] &&
      treeData[0].Source &&
      treeData[0].Source[0].control
    ) {
      const radioValues = findRadioData(treeData[0].Source[0].control);

      setTextareaDataRadio(radioValues.map((item) => item.data).join("\n"));
      setInputTextRadio(radioValues.map((item) => item.data).join("\n"));
      setInputTextRadio(radioValues.map((item) => item.data).join("\n"));
    }
  }, [treeData, selectedItem]);

  useEffect(() => {
    const dataValues = textareaDataRadio
      .split("\n")
      .map((value) => value.trim());

    const checkArrayMatch = (data) => {
      if (data.includes("")) {
        return "None";
      }

      if (data.some((value) => months.includes(value))) {
        return "Months";
      } else if (data.some((value) => genders.includes(value))) {
        return "Gender";
      } else if (data.some((value) => None.includes(value))) {
        return "None";
      } else if (data.some((value) => days.includes(value))) {
        return "Days";
      }
      return "None";
    };

    const arrayName = checkArrayMatch(dataValues);
    setSelectedArrayName(arrayName);
  }, [textareaDataRadio]);

  useEffect(() => {
    const findCheckBoxData = (controlArray) => {
      let CheckBoxData = [];

      controlArray.forEach((control) => {
        if (control.id === selectedItem.id) {
          const childControls = control.control;

          childControls.forEach((container) => {
            if (container.control) {
              const filtered = container.control.filter(
                (control) => control.media === "CheckBox"
              );
              CheckBoxData.push(...filtered);
            }
          });
        }
      });

      return CheckBoxData.filter((item) => item.media === "CheckBox");
    };

    if (
      treeData &&
      treeData[0] &&
      treeData[0].Source &&
      treeData[0].Source[0].control
    ) {
      const CheckBoxValues = findCheckBoxData(treeData[0].Source[0].control);

      setTextareaDataCheckBox(
        CheckBoxValues.map((item) => item.data).join("\n")
      );

      setInputTextCheckbox(CheckBoxValues.map((item) => item.data).join("\n"));
    }
  }, [treeData, selectedItem]);

  useEffect(() => {
    const dataValues = textareaDataCheckBox
      .split("\n")
      .map((value) => value.trim());

    const checkArrayMatch = (data) => {
      if (data.includes("")) {
        return "None";
      }

      if (data.some((value) => months.includes(value))) {
        return "Months";
      } else if (data.some((value) => genders.includes(value))) {
        return "Gender";
      } else if (data.some((value) => None.includes(value))) {
        return "None";
      } else if (data.some((value) => days.includes(value))) {
        return "Days";
      }

      return "None";
    };

    const arrayName = checkArrayMatch(dataValues);

    setSelectedArrayNameCheckbox(arrayName);
  }, [textareaDataCheckBox]);

  useEffect(() => {
    const dataValues = textareaDataRadio
      .split("\n")
      .map((value) => value.trim());

    const checkArrayMatch = (data) => {
      if (data.includes("")) {
        return "None";
      }

      if (data.some((value) => months.includes(value))) {
        return "Months";
      } else if (data.some((value) => genders.includes(value))) {
        return "Gender";
      } else if (data.some((value) => None.includes(value))) {
        return "None";
      } else if (data.some((value) => days.includes(value))) {
        return "Days";
      }

      return "None";
    };

    const arrayName = checkArrayMatch(dataValues);

    setSelectedArrayNameRadio(arrayName);
  }, [textareaDataRadio]);

  const handleRadioChange = (event, mediaToAdd, GenderJSON, values = []) => {
    const isChecked = event.target.checked;

    const addJSONToContainer = (
      controlArray,
      mediaToAdd,
      GenderJSON,
      values
    ) => {
      return controlArray.map((control) => {
        if (control.media === mediaToAdd) {
          control.control = [];

          const currentMaxStyleId = calculateNextStyleIdBlocks();
          const styleIdNumber = parseInt(
            currentMaxStyleId.replace(/[a-zA-Z]/g, "")
          );

          values.forEach((value, i) => {
            const styledJSON = GenderJSON.map((json, index) => {
              const newStyleId = `agrfb${styleIdNumber + index + i * GenderJSON.length}`;
              const updatedJson = { ...json, data: value, inputText: value };
             

              return {
                ...applyStylesRecursively(updatedJson, defaultStyle),
                id: `${control.id}.${index + 1 + i * GenderJSON.length}`,
                styleid: newStyleId,
              };
            });
            control.control.push(...styledJSON);
          });
        }

        if (control.control && control.control.length > 0) {
          control.control = addJSONToContainer(
            control.control,
            mediaToAdd,
            GenderJSON,
            values
          );
        }
        return control;
      });
    };

    if (values.length > 0) {
      setTextareaDataRadio(values.join("\n"));
    }

    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const updateControlById = (controlArray) => {
        return controlArray.map((control) => {
          if (control.id === selectedItem.id) {
            if (isChecked) {
              control.control = addJSONToContainer(
                control.control,
                mediaToAdd,
                GenderJSON,
                values
              );
            } else {
              control.control = removeControls(control.control, mediaToAdd);
            }
          }
          if (control.control && control.control.length > 0) {
            control.control = updateControlById(control.control);
          }
          return control;
        });
      };

      updatedTreeData[0].Source[0].control = updateControlById(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  const removeControls = (controlArray, mediaToRemove) => {
    return controlArray.filter((control) => {
      if (control.media === mediaToRemove) {
        return false;
      }
      if (control.control && control.control.length > 0) {
        control.control = removeControls(control.control, mediaToRemove);
      }
      return true;
    });
  };

  const handleTextareaChangeRadio = (event) => {};

  const handleTextareaKeyDownRadio = (event) => {
    if (event.key === "Enter" || textareaDataRadio === "") {
      event.preventDefault();

      const updatedValue = textareaDataRadio + "\n";

      setTextareaDataRadio(updatedValue);

      addNewGenderJsonEntry();
    }
  };

  const addNewGenderJsonEntry = () => {};

  const [switchCheckedRadio, setSwitchCheckedRadio] = useState(false);
  const [spreadCheckRadio, setSpreadCheckRadio] = useState(false);
  const [textFieldValueRadio, setTextFieldValueRadio] = useState("");
  const [columnValueRadio, setColumnValueRadio] = useState("1");

  const checkColumnCount = (treeData) => {
    const controls = treeData[0].Source[0].control;
    const relevantControl = controls.find(
      (control) => control.id === selectedItem.id
    );

    if (relevantControl) {
      const childControl = relevantControl.control.find(
        (child) => child.media === "RadioMainContainer"
      );
      if (childControl) {
        const existingStyles = childControl.styles
          ? JSON.parse(childControl.styles)
          : {};
        return existingStyles.columnCount || 1;
      }
    }
    return 1;
  };

  const checkColumnCount1 = (treeData) => {
    const controls = treeData[0]?.Source[0]?.control || [];
    const relevantControl = controls.find(
      (control) => control.id === selectedItem.id
    );

    if (relevantControl) {
      return relevantControl.control.some((childControl) => {
        const existingStyles = childControl.styles
          ? JSON.parse(childControl.styles)
          : {};
        return existingStyles.columnCount > 1;
      });
    }
    return false;
  };

  const checkColumnCountCheckBox = (treeData) => {
    const controls = treeData[0].Source[0].control;
    const relevantControl = controls.find(
      (control) => control.id === selectedItem.id
    );

    if (relevantControl) {
      const childControl = relevantControl.control.find(
        (child) => child.media === "CheckBoxMainContainer"
      );
      if (childControl) {
        const existingStyles = childControl.styles
          ? JSON.parse(childControl.styles)
          : {};
        return existingStyles.columnCount || 1;
      }
    }
    return 1;
  };

  const checkColumnCount1CheckBox = (treeData) => {
    const controls = treeData[0]?.Source[0]?.control || [];
    const relevantControl = controls.find(
      (control) => control.id === selectedItem.id
    );

    if (relevantControl) {
      return relevantControl.control.some((childControl) => {
        const existingStyles = childControl.styles
          ? JSON.parse(childControl.styles)
          : {};
        return existingStyles.columnCount > 1;
      });
    }
    return false;
  };

  useEffect(() => {
    const columnCountCheck = checkColumnCount1(treeData);

    if (columnCountCheck > 0) {
      setSpreadCheckRadio(columnCountCheck);
    }
  }, [treeData, selectedItem]);

  useEffect(() => {
    const columnCountCheck = checkColumnCount1CheckBox(treeData);

    if (columnCountCheck > 0) {
      setSpreadCheckCheckBox(columnCountCheck);
    }
  }, [treeData, selectedItem]);

  useEffect(() => {
    const columnCount = checkColumnCount(treeData);
    setColumnValueRadio(columnCount);
  }, [treeData, selectedItem]);

  useEffect(() => {
    const columnCountCheckBox = checkColumnCountCheckBox(treeData);
    setColumnValueCheckBox(columnCountCheckBox);
  }, [treeData, selectedItem]);

  const handleColumnChangeRadio = (e) => {
    const newValue = e.target.value;

    if (!isNaN(newValue) && newValue >= 0) {
      setColumnValueRadio(newValue);

      setTreeData((prevTreeData) => {
        const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

        const addJSONToControl = (controlArray, parentId) => {
          return controlArray.map((control) => {
            if (control.id === parentId) {
              control.control = control.control.map((childControl) => {
                if (childControl.media === "RadioMainContainer") {
                  const existingStyles = childControl.styles
                    ? JSON.parse(childControl.styles)
                    : {};
                  updateCustomStyle(childControl.styleid, {
                    ...existingStyles,
                    columnCount: newValue,
                    display: "",
                  });

                  childControl.styles = JSON.stringify({
                    ...existingStyles,
                    columnCount: newValue,
                    display: "",
                  });
                }
                if (childControl.control && childControl.control.length > 0) {
                  childControl.control = addJSONToControl(
                    childControl.control,
                    parentId
                  );
                }
                return childControl;
              });
            }
            return control;
          });
        };

        updatedTreeData[0].Source[0].control = addJSONToControl(
          updatedTreeData[0].Source[0].control,
          selectedItem.id
        );

        return updatedTreeData;
      });
    } else {
      console.error("Invalid column count value:", newValue);
    }
  };

  const checkSwitchOptionRadioControl = (treeData, selectedItemId) => {
    const controls = treeData[0].Source[0].control;
    const relevantControl = controls.find(
      (control) => control.id === selectedItemId
    );

    if (relevantControl) {
      const findRadioControlWithData = (controlArray) => {
        for (let control of controlArray) {
          if (control.switchoption === "RadioControl") {
            return control.data;
          }
          if (control.control && control.control.length > 0) {
            const nestedResult = findRadioControlWithData(control.control);
            if (nestedResult) {
              return nestedResult;
            }
          }
        }
        return null;
      };

      return findRadioControlWithData(relevantControl.control);
    }

    return null;
  };

  useEffect(() => {
    const radioControlData = checkSwitchOptionRadioControl(
      treeData,
      selectedItem.id
    );

    if (radioControlData !== null) {
      setSwitchCheckedRadio(true);
      setTextFieldValueRadio(radioControlData);
    } else {
      setSwitchCheckedRadio(false);
      setTextFieldValueRadio("");
    }
  }, [treeData, selectedItem]);

  const checkSwitchOptionCheckBoxControl = (treeData, selectedItemId) => {
    const controls = treeData[0].Source[0].control;
    const relevantControl = controls.find(
      (control) => control.id === selectedItemId
    );

    if (relevantControl) {
      const findCheckBoxControlWithData = (controlArray) => {
        for (let control of controlArray) {
          if (control.switchoption === "CheckBoxControl") {
            return control.data;
          }
          if (control.control && control.control.length > 0) {
            const nestedResult = findCheckBoxControlWithData(control.control);
            if (nestedResult) {
              return nestedResult;
            }
          }
        }
        return null;
      };

      return findCheckBoxControlWithData(relevantControl.control);
    }

    return null;
  };

  useEffect(() => {
    const CheckBoxControlData = checkSwitchOptionCheckBoxControl(
      treeData,
      selectedItem.id
    );

    if (CheckBoxControlData !== null) {
      setSwitchCheckedCheckBox(true);
      setTextFieldValueCheckBox(CheckBoxControlData);
    } else {
      setSwitchCheckedCheckBox(false);
      setTextFieldValueCheckBox("");
    }
  }, [treeData, selectedItem]);

  const handleSwitchChangeRadio = (event) => {
    event.preventDefault();
    const isChecked = event.target.checked;
    if (spreadCheckRadio && switchCheckedRadio) {
      setSwitchCheckedRadio(isChecked);
    }

    if (isChecked) {
      setTextFieldValueRadio("Male");
      addNewGenderJsonEntrySwitchRadio("Male");
    } else {
      setTextFieldValueRadio("");
      removeGenderJsonEntrySwitchRadio();
    }
  };

  const handleSpreadCheckRadio = (e) => {
    const isChecked = !spreadCheckRadio;
    setSpreadCheckRadio(isChecked);
  };

  const handleTextFieldChangeRadio = (event) => {
    const newValue = event.target.value;
    setTextFieldValueRadio(newValue);
    updateGenderJsonEntryRadio(newValue);
  };

  const addNewGenderJsonEntrySwitchRadio = (initialValue) => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const currentMaxStyleId = calculateNextStyleIdBlocks();
      const styleIdNumber = parseInt(
        currentMaxStyleId.replace(/[a-zA-Z]/g, "")
      );

      const newGenderJsonEntry = GenderJSON.map((json, index) => {
        const newStyleId = `agrfb${styleIdNumber + index}`;
        return {
          ...applyStylesRecursively(
            {
              ...json,
              data: initialValue,
              switchoption: "RadioControl",
              inputText: initialValue,
            },defaultStyle,
            {
              ...json,
              data: initialValue,
              switchoption: "RadioControl",
              inputText: initialValue,
            },
            
          ),
          id: `${selectedItem.id}.2.${index + 1}_other`,
          styleid: newStyleId,
        };
      });

      const addJSONToControl = (controlArray) => {
        return controlArray.map((control) => {
          if (control.id === selectedItem.id) {
            control.control = control.control.map((childControl) => {
              if (childControl.media === "RadioMainContainer") {
                childControl.control.push(...newGenderJsonEntry);
              }
              if (childControl.control && childControl.control.length > 0) {
                childControl.control = addJSONToControl(childControl.control);
              }
              return childControl;
            });
          }
          return control;
        });
      };

      updatedTreeData[0].Source[0].control = addJSONToControl(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  const removeGenderJsonEntrySwitchRadio = () => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const removeJSONFromControl = (controlArray) => {
        return controlArray.map((control) => {
          if (control.id === selectedItem.id) {
            control.control = control.control.map((childControl) => {
              if (childControl.media === "RadioMainContainer") {
                childControl.control = childControl.control.filter(
                  (item) => !item.id.endsWith("_other")
                );
              }
              if (childControl.control && childControl.control.length > 0) {
                childControl.control = removeJSONFromControl(
                  childControl.control
                );
              }
              return childControl;
            });
          }
          return control;
        });
      };

      updatedTreeData[0].Source[0].control = removeJSONFromControl(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  const updateGenderJsonEntryRadio = (newValue) => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const updateJSONInControl = (controlArray) => {
        return controlArray.map((control) => {
          if (control.media === "RadioMainContainer") {
            control.control = control.control.map((item, index) => {
              if (item.id.endsWith("_other")) {
                return {
                  ...item,
                  id: `${selectedItem.id}.2.${index + 1}_other`,
                  data: newValue,
                  inputText: newValue,
                  inputText: newValue,
                };
              }
              return item;
            });
          }

          if (control.control && control.control.length > 0) {
            control.control = updateJSONInControl(control.control);
          }
          return control;
        });
      };
      updatedTreeData[0].Source[0].control = updateJSONInControl(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  //  ----------checkbox --------------------

  const handleCheckBoxChange = (
    event,
    mediaToAdd,
    GenderCheckBoxJSON,
    values = []
  ) => {
    const isChecked = event.target.checked;

    const addJSONToContainer = (
      controlArray,
      mediaToAdd,
      GenderCheckBoxJSON,
      values
    ) => {
      return controlArray.map((control) => {
        if (control.media === mediaToAdd) {
          control.control = [];

          const currentMaxStyleId = calculateNextStyleIdBlocks();
          const styleIdNumber = parseInt(
            currentMaxStyleId.replace(/[a-zA-Z]/g, "")
          );

          values.forEach((value, i) => {
            const styledJSON = GenderCheckBoxJSON.map((json, index) => {
              const newStyleId = `agrfb${styleIdNumber + index + i * GenderCheckBoxJSON.length}`;
              const updatedJson = { ...json, data: value, inputText: value };
             

              return {
                ...applyStylesRecursively(updatedJson, defaultStyle),
                id: `${control.id}.${index + 1 + i * GenderCheckBoxJSON.length}`,
                styleid: newStyleId,
              };
            });
            control.control.push(...styledJSON);
          });
        }

        if (control.control && control.control.length > 0) {
          control.control = addJSONToContainer(
            control.control,
            mediaToAdd,
            GenderCheckBoxJSON,
            values
          );
        }
        return control;
      });
    };

    if (values.length > 0) {
      setTextareaDataCheckBox(values.join("\n"));
    }

    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const updateControlById = (controlArray) => {
        return controlArray.map((control) => {
          if (control.id === selectedItem.id) {
            if (isChecked) {
              control.control = addJSONToContainer(
                control.control,
                mediaToAdd,
                GenderCheckBoxJSON,
                values
              );
            } else {
              control.control = removeControls(control.control, mediaToAdd);
            }
          }
          if (control.control && control.control.length > 0) {
            control.control = updateControlById(control.control);
          }
          return control;
        });
      };

      updatedTreeData[0].Source[0].control = updateControlById(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  const handleTextareaChangeCheckBox = (event) => {
    const updatedValue = event.target.value;

    setTextareaDataCheckBox(updatedValue);

    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const updatedValues = updatedValue.split("\n");

      const findNodeById = (controlArray, id) => {
        if (!Array.isArray(controlArray)) {
          return null;
        }

        for (const control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const found = findNodeById(control.control, id);
            if (found) return found;
          }
        }
        return null;
      };

      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (matchingNode) {
        const updateControl = (controlArray, mediaToUpdate) => {
          return controlArray
            .map((control) => {
              if (control.media === mediaToUpdate) {
                control.control = control.control.map((item, index) => {
                  if (updatedValues[index]) {
                    item.data = updatedValues[index];
                    item.inputText = updatedValues[index];
                  } else {
                    item.data = "";
                    item.inputText = "";
                  }
                  return item;
                });
              }

              if (control.control && control.control.length > 0) {
                control.control = updateControl(control.control, mediaToUpdate);
              }
              return control;
            })
            .filter((control) => {
              if (control.controltype === "Checkbox" && control.data === "") {
                return false;
              }
              return true;
            });
        };

        matchingNode.control = updateControl(
          matchingNode.control,
          "CheckBoxMainContainer"
        );
        return updatedTreeData;
      } else {
        console.error("No matching node found for the selected ID");
        return prevTreeData;
      }
    });
  };

  const handleTextareaKeyDownCheckBox = (event) => {
    if (event.key === "Enter" || textareaDataCheckBox === "") {
      event.preventDefault();

      const updatedValue = textareaDataCheckBox + "\n";

      setTextareaDataCheckBox(updatedValue);

      addNewGenderJsonEntryCheckBox();
    }
  };

  const addNewGenderJsonEntryCheckBox = () => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const currentMaxStyleId = calculateNextStyleIdBlocks();
      const styleIdNumber = parseInt(
        currentMaxStyleId.replace(/[a-zA-Z]/g, "")
      );

      const newGenderJsonEntry = GenderCheckBoxJSON.map((json, index) => {
        const newStyleId = `agrfb${styleIdNumber + index}`;
        return {
          ...applyStylesRecursively(
            {
              ...json,
              data: "",
              switchoption: `data${styleIdNumber + index}`,
              inputText: "",
            },  defaultStyle,
            {
              ...json,
              data: "",
              switchoption: `data${styleIdNumber + index}`,
              inputText: "",
            },
          
          ),
          id: `${selectedItem.id}.2.${newStyleId + 1}_new`,
          styleid: newStyleId,
        };
      });

      const findNodeById = (controlArray, id) => {
        if (!Array.isArray(controlArray)) {
          return null;
        }

        for (const control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const found = findNodeById(control.control, id);
            if (found) return found;
          }
        }
        return null;
      };

      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (matchingNode) {
        const addJSONToControl = (controlArray) => {
          return controlArray.map((control) => {
            if (control.media === "CheckBoxMainContainer") {
              control.control.push(...newGenderJsonEntry);
            }

            if (control.control && control.control.length > 0) {
              control.control = addJSONToControl(control.control);
            }
            return control;
          });
        };

        matchingNode.control = addJSONToControl(matchingNode.control);

        return updatedTreeData;
      } else {
        console.error("No matching node found for the selected ID");
        return prevTreeData;
      }
    });
  };

  const [switchCheckedCheckBox, setSwitchCheckedCheckBox] = useState(false);

  const [spreadCheckCheckBox, setSpreadCheckCheckBox] = useState(false);

  const [textFieldValueCheckBox, setTextFieldValueCheckBox] = useState("");
  const [columnValueCheckBox, setColumnValueCheckBox] = useState("1");

  const handleColumnChangeCheckBox = (e) => {
    const newValue = e.target.value;

    if (!isNaN(newValue) && newValue >= 0) {
      setColumnValueCheckBox(newValue);

      setTreeData((prevTreeData) => {
        const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

        const addJSONToControl = (controlArray, parentId) => {
          return controlArray.map((control) => {
            if (control.id === parentId) {
              control.control = control.control.map((childControl) => {
                if (childControl.media === "CheckBoxMainContainer") {
                  const existingStyles = childControl.styles
                    ? JSON.parse(childControl.styles)
                    : {};
                  updateCustomStyle(childControl.styleid, {
                    ...existingStyles,
                    columnCount: newValue,
                    display: "",
                  });

                  childControl.styles = JSON.stringify({
                    ...existingStyles,
                    columnCount: newValue,
                    display: "",
                  });
                }
                if (childControl.control && childControl.control.length > 0) {
                  childControl.control = addJSONToControl(
                    childControl.control,
                    parentId
                  );
                }
                return childControl;
              });
            }
            return control;
          });
        };

        updatedTreeData[0].Source[0].control = addJSONToControl(
          updatedTreeData[0].Source[0].control,
          selectedItem.id
        );

        return updatedTreeData;
      });
    } else {
      console.error("Invalid column count value:", newValue);
    }
  };

  const handleSwitchChangeCheckBox = (event) => {
    const isChecked = event.target.checked;
    setSwitchCheckedCheckBox(isChecked);

    if (isChecked) {
      setTextFieldValueCheckBox("Male");
      addNewGenderJsonEntrySwitchCheckBox("Male");
    } else {
      removeGenderJsonEntrySwitchCheckBox();
    }
  };

  const handleSpreadCheckCheckBox = (e) => {
    // const isChecked = e.target.checked;
    const isChecked = !spreadCheckCheckBox;
    setSpreadCheckCheckBox(isChecked);
  };
  const handleTextFieldChangeCheckBox = (event) => {
    const newValue = event.target.value;
    setTextFieldValueCheckBox(newValue);
    updateGenderJsonEntryCheckBox(newValue);
  };

  const addNewGenderJsonEntrySwitchCheckBox = (initialValue) => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const currentMaxStyleId = calculateNextStyleIdBlocks();
      const styleIdNumber = parseInt(
        currentMaxStyleId.replace(/[a-zA-Z]/g, "")
      );

      const newGenderJsonEntry = GenderCheckBoxJSON.map((json, index) => {
        const newStyleId = `agrfb${styleIdNumber + index}`;
      
        return {
          ...applyStylesRecursively(
            {
              ...json,
              data: initialValue,
              switchoption: "CheckBoxControl",
              inputText: initialValue,
            },
            defaultStyle,
            {
              ...json,
              data: initialValue,
              switchoption: "CheckBoxControl",
              inputText: initialValue,
            },
           
          ),
          id: `${selectedItem.id}.2.${index + 1}_other`,
          styleid: newStyleId,
        };
      });

      const addJSONToControl = (controlArray) => {
        return controlArray.map((control) => {
          if (control.id === selectedItem.id) {
            control.control = control.control.map((childControl) => {
              if (childControl.media === "CheckBoxMainContainer") {
                childControl.control.push(...newGenderJsonEntry);
              }
              if (childControl.control && childControl.control.length > 0) {
                childControl.control = addJSONToControl(childControl.control);
              }
              return childControl;
            });
          }
          return control;
        });
      };

      updatedTreeData[0].Source[0].control = addJSONToControl(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  const updateGenderJsonEntryCheckBox = (newValue) => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const updateJSONInControl = (controlArray) => {
        return controlArray.map((control) => {
          if (control.media === "CheckBoxMainContainer") {
            control.control = control.control.map((item, index) => {
              if (item.id.endsWith("_other")) {
                return {
                  ...item,
                  id: `${selectedItem.id}.2.${index + 1}_other`,
                  data: newValue,
                  inputText: newValue,
                  inputText: newValue,
                };
              }
              return item;
            });
          }

          if (control.control && control.control.length > 0) {
            control.control = updateJSONInControl(control.control);
          }
          return control;
        });
      };
      updatedTreeData[0].Source[0].control = updateJSONInControl(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  const removeGenderJsonEntrySwitchCheckBox = () => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));
      const removeJSONFromControl = (controlArray) => {
        return controlArray.map((control) => {
          if (control.id === selectedItem.id) {
            control.control = control.control.map((childControl) => {
              if (childControl.media === "CheckBoxMainContainer") {
                childControl.control = childControl.control.filter(
                  (item) => !item.id.endsWith("_other")
                );
              }
              if (childControl.control && childControl.control.length > 0) {
                childControl.control = removeJSONFromControl(
                  childControl.control
                );
              }
              return childControl;
            });
          }
          return control;
        });
      };
      updatedTreeData[0].Source[0].control = removeJSONFromControl(
        updatedTreeData[0].Source[0].control
      );

      return updatedTreeData;
    });
  };

  // -----------------Rating------------------------------
  const [textareaDataRating, setTextareaDataRating] = useState("");
  const [textareaDataColorRating, setTextareaDataColorRating] = useState("");

  const countExistingStarControls = (controlArray) => {
    let count = 0;
    controlArray.forEach((control) => {
      if (control.type === "Star") {
        count++;
      }
      if (control.control && control.control.length > 0) {
        count += countExistingStarControls(control.control);
      }
    });
    return count;
  };

  const countB8860BStars = (controlArray) => {
    let count = 0;
    controlArray.forEach((control) => {
      if (control.type === "Star") {
        const stylesObject = JSON.parse(control.styles || "{}");
        if (stylesObject.color === "#B8860B") {
          count++;
        }
      }

      if (control.control && control.control.length > 0) {
        count += countB8860BStars(control.control);
      }
    });
    return count;
  };

  useEffect(() => {
    if (selectedItem && selectedItem.controltype === "RatingBlock") {
      const findNodeById = (controlArray, id) => {
        if (!Array.isArray(controlArray)) return null;
        for (const control of controlArray) {
          if (control.id === id) return control;
          if (control.control && control.control.length > 0) {
            const found = findNodeById(control.control, id);
            if (found) return found;
          }
        }
        return null;
      };

      const matchingNode = findNodeById(
        treeData[0].Source[0].control,
        selectedItem.id
      );

      if (matchingNode) {
        const existingStarCount = countExistingStarControls(
          matchingNode.control
        );
        const countGoldenStars = countB8860BStars(matchingNode.control);
        setTextareaDataRating(existingStarCount.toString());
        setTextareaDataColorRating(countGoldenStars.toString());
      } else {
        console.error("No matching node found for the selected ID");
      }
    }
  }, [selectedItem, treeData]);

  const handleAddControlStar = (event) => {
    const inputNumber = event.target.value;
    setTextareaDataRating(inputNumber);

    if (!isNaN(inputNumber) && inputNumber >= 0) {
      addNewStarJsonEntry(inputNumber);

      const colorRatingNumber = textareaDataColorRating;

      if (!isNaN(colorRatingNumber) && colorRatingNumber >= 0) {
        applyColorToStars(colorRatingNumber, "#B8860B");
      }
    }
  };

  const addNewStarJsonEntry = (numberToAdd) => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      let currentMaxStyleId = calculateNextStyleIdBlocks();
      const styleIdNumber = parseInt(
        currentMaxStyleId.replace(/[a-zA-Z]/g, "")
      );

      const findNodeById = (controlArray, id) => {
        if (!Array.isArray(controlArray)) return null;
        for (const control of controlArray) {
          if (control.id === id) return control;
          if (control.control && control.control.length > 0) {
            const found = findNodeById(control.control, id);
            if (found) return found;
          }
        }
        return null;
      };

      const removeExistingStarControls = (controlArray) => {
        return controlArray.map((control) => {
          if (control.media === "RatingInput" && control.control) {
            control.control = control.control.filter(
              (subControl) => subControl.type !== "Star"
            );
          }
          if (control.control && control.control.length > 0) {
            control.control = removeExistingStarControls(control.control);
          }
          return control;
        });
      };

      const getMaxExistingId = (controlArray) => {
        let maxId = 0;
        controlArray.forEach((control) => {
          const idNumber = parseInt(control.id.split(".").pop(), 10);
          if (!isNaN(idNumber)) {
            maxId = Math.max(maxId, idNumber);
          }
          if (control.control && control.control.length > 0) {
            const nestedMaxId = getMaxExistingId(control.control);
            maxId = Math.max(maxId, nestedMaxId);
          }
        });
        return maxId;
      };
      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (matchingNode) {
        matchingNode.control = removeExistingStarControls(matchingNode.control);

        const maxExistingId = getMaxExistingId(matchingNode.control);
        const newStarJsonEntries = Array.from({ length: numberToAdd }, (_, i) =>
          StarJSON.map((json, index) => {
            const newStyleId = `agrfb${styleIdNumber + index + i * StarJSON.length}`;
            const newId = `${selectedItem.id}.2.${maxExistingId + index + 1 + i * StarJSON.length}`;
            const styledControl = applyStylesRecursively(
              { ...json, data: "" },
              defaultStyle
            );
            return {
              ...styledControl,
              id: newId,
              styleid: newStyleId,
            };
          })
        );

        const addJSONToControl = (controlArray) => {
          return controlArray.map((control) => {
            if (control.media === "RatingInput") {
              control.control = [
                ...(control.control || []),
                ...newStarJsonEntries.flat(),
              ];
            }
            if (control.control && control.control.length > 0) {
              control.control = addJSONToControl(control.control);
            }
            return control;
          });
        };

        matchingNode.control = addJSONToControl(matchingNode.control);

        return updatedTreeData;
      } else {
        console.error("No matching node found for the selected ID");
        return prevTreeData;
      }
    });
  };

  const handleColorChange = (event) => {
    const inputNumber = event.target.value;
    const newColor = "#B8860B";
    setTextareaDataColorRating(event.target.value);

    if (isNaN(inputNumber) || inputNumber < 1) {
      console.error("Invalid number entered");
      return;
    }
    applyColorToStars(inputNumber, newColor);
  };

  const applyColorToStars = (numberToColor, color) => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findNodeById = (controlArray, id) => {
        if (!Array.isArray(controlArray)) {
          return null;
        }

        for (const control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const found = findNodeById(control.control, id);
            if (found) return found;
          }
        }
        return null;
      };

      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (matchingNode) {
        const updateColorInControl = (controlArray) => {
          return controlArray.map((control, index) => {
            if (control.type === "Star") {
              const stylesObject = JSON.parse(control.styles || "{}");

              if (index < numberToColor) {
                stylesObject.color = color;
              } else {
                stylesObject.color = "#000000";
              }

              control.styles = JSON.stringify(stylesObject);
            }

            if (control.control && control.control.length > 0) {
              control.control = updateColorInControl(control.control);
            }

            return control;
          });
        };

        matchingNode.control = updateColorInControl(matchingNode.control);
        return updatedTreeData;
      } else {
        console.error("No matching node found for the selected ID");
        return prevTreeData;
      }
    });
  };

  useEffect(() => {
    if (predefinedOption === "Years") {
      setDefaultValues(Years);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Countries") {
      setDefaultValues(countries);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Months") {
      setDefaultValues(months);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Days") {
      setDefaultValues(days);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Gender") {
      setDefaultValues(genders);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "None") {
      setDefaultValues(None);
      setSelectedDefaultValue(firstItem);
    } else {
      setDefaultValues(None);
      setSelectedDefaultValue(firstItem);
    }
  }, [predefinedOption]);

  //------------------------editedData for Date---------------------------------------//

  const [editedDate, setEditedDataDate] = useState("");
  const [editedDateDS, setEditedDataDateDS] = useState("");
  const [showCustomDateInput, setShowCustomDateInput] = useState(false);

  const handleDateOptionChange = (e) => {
    const newValue = e.target.value;
    setEditedDataDateDS(newValue);
    const updatedTreeDataDate = updateTreeDatanewValueDateDS(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeDataDate);
  };

  const updateTreeDatanewValueDateDS = (treeData, selectedItemID, newValue) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueDateDS(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueDateDS = (controls, selectedItemID, newValue) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinDateDS(control.control, newValue),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueDateDS(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinDateDS = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "DateField") {
        return {
          ...control,
          inputText: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinDateDS(control.control, newValue),
        };
      }

      return control;
    });
  };

  const findDateDataDS = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsDateDS(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsDateDS = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const DateControl = findDateControlDS(control.control);
        if (DateControl) {
          return DateControl.inputText;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsDateDS(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findDateControlDS = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "DateField") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findDateControlDS(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  useEffect(() => {
    if (selectedItem?.controltype === "DateBlock") {
      const initialDataDate = findDateDataDS(treeData, selectedItemID);
      setEditedDataDateDS(initialDataDate);
    }
  }, [treeData, selectedItemID, selectedItem]);

  //

  const updateTreeDatanewValueDate = (treeData, selectedItemID, newValue) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueDate(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueDate = (controls, selectedItemID, newValue) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinDate(control.control, newValue),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueDate(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinDate = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "DateField") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinDate(control.control, newValue),
        };
      }

      return control;
    });
  };

  const findDateData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsDate(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsDate = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const DateControl = findDateControl(control.control);
        if (DateControl) {
          return DateControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsDate(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findDateControl = (controls) => {
    if (!Array.isArray(controls)) return null;

    for (let control of controls) {
      if (control?.checkposition === "DateField") {
        return control;
      }
      if (Array.isArray(control?.control) && control.control.length > 0) {
        const foundControl = findDateControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChangeDate = () => {
    const newValue = "";
    setShowCustomDateInput(false);

    setEditedDataDate(newValue);
    const updatedTreeDataDate = updateTreeDatanewValueDate(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeDataDate);
  };

  const handleFieldChangeCurrent = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setShowCustomDateInput(false);

    setEditedDataDate(formattedDate);
    const updatedTreeDataDate = updateTreeDatanewValueDate(
      treeData,
      selectedItemID,
      formattedDate
    );
    setTreeData(updatedTreeDataDate);
  };

  const handleCustomDateChange = (event) => {
    const selectedDate = event.target.value;
    setEditedDataDate(selectedDate);

    const updatedTreeDataDate = updateTreeDatanewValueDate(
      treeData,
      selectedItemID,
      selectedDate
    );
    setTreeData(updatedTreeDataDate);
  };

  useEffect(() => {
    if (selectedItem?.controltype === "DateBlock") {
      const initialDataDate = findDateData(treeData, selectedItemID);
      setEditedDataDate(initialDataDate);
    }
  }, [treeData, selectedItemID, selectedItem]);

  const findLabelData = (treeData, selectedItemID, labelType) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsForLabelType(
          source.control,
          selectedItemID,
          labelType
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsForLabelType = (controls, selectedItemID, labelType) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const labelControl = findLabelControl(control.control, labelType);
        if (labelControl) {
          return labelControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsForLabelType(
          control.control,
          selectedItemID,
          labelType
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findLabelControl = (controls, labelType) => {
    for (let control of controls) {
      if (control.checkposition === labelType) {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findLabelControl(control.control, labelType);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const LABEL_TYPES = {
    CountryLabel: "CountryLabel",
    CountryTextbox: "CountryTextbox",
    AddressStreet1: "AddressStreet1",
    AddressStreet1Textbox: "AddressStreet1Textbox",
    AddressStreet2: "AddressStreet2",
    AddressStreet2Textbox: "AddressStreet2Textbox",
    CityLabel: "CityLabel",
    CityTextbox: "CityTextbox",
    StateLabel: "StateLabel",
    StateTextbox: "StateTextbox",
    PostalLabel: "PostalLabel",
    PostalTextbox: "PostalTextbox",
  };

  const initializeFieldData = (
    treeData,
    selectedItemID,
    labelType,
    setData,
    setCheckbox
  ) => {
    const data = findLabelData(treeData, selectedItemID, labelType);
    setData(data);
    if (setCheckbox) {
      setCheckbox(data);
    }
  };

  useEffect(() => {
    if (!selectedItem) return;
    const controlType = selectedItem.controltype;

    switch (controlType) {
      case "Address":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.CountryLabel,
          setCheckboxCheckedCountry
        );
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.AddressStreet1,
          setCheckboxCheckedStreet1
        );
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.AddressStreet2,
          setCheckboxCheckedStreet2
        );
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.CityLabel,
          setCheckboxCheckedCity
        );
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.StateLabel,
          setCheckboxCheckedState
        );
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.PostalLabel,
          setCheckboxCheckedPostal
        );
        break;

      default:
        break;
    }
  }, [treeData, selectedItemID, selectedItem]);

  const findControlDataByNameAndId = (
    controls,
    controlName,
    selectedItemID
  ) => {
    for (const control of controls) {
      if (
        control.controlName === controlName &&
        control.id.startsWith(selectedItemID)
      ) {
        return control.data || "";
      }

      if (control.control && control.control.length > 0) {
        const result = findControlDataByNameAndId(
          control.control,
          controlName,
          selectedItemID
        );
        if (result) return result;
      }
    }
    return null;
  };

  const dropdownData = findControlDataByNameAndId(
    treeData[0].Source[0].control,
    "Dropdown1",
    selectedItem.id
  );

  let firstItem = "";
  if (dropdownData) {
    firstItem = dropdownData.split(",")[0].trim();
  } else {
    console.log("dropdownData is null or undefined");
  }

  useEffect(() => {
    if (predefinedOption === "Years") {
      setDefaultValues(Years);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Countries") {
      setDefaultValues(countries);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Months") {
      setDefaultValues(months);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Days") {
      setDefaultValues(days);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "Gender") {
      setDefaultValues(genders);
      setSelectedDefaultValue(firstItem);
    } else if (predefinedOption === "None") {
      setDefaultValues(None);
      setSelectedDefaultValue(firstItem);
    } else {
      setDefaultValues(None);
      setSelectedDefaultValue(firstItem);
    }
  }, [predefinedOption]);

  useEffect(() => {
    if (dropdownData) {
      const dropdownArray = dropdownData.split(", ").map((item) => item.trim());
      const isYears = Years.every((year) => dropdownArray.includes(year));
      const isCountries = countries.every((country) =>
        dropdownArray.includes(country)
      );
      const isMonths = months.every((month) => dropdownArray.includes(month));
      const isDays = days.every((day) => dropdownArray.includes(day));
      const isGenders = genders.every((gender) =>
        dropdownArray.includes(gender)
      );
      if (isYears) {
        setPredefinedOption("Years");
      } else if (isCountries) {
        setPredefinedOption("Countries");
      } else if (isMonths) {
        setPredefinedOption("Months");
      } else if (isDays) {
        setPredefinedOption("Days");
      } else if (isGenders) {
        setPredefinedOption("Gender");
      } else {
        setPredefinedOption("None");
      }
    } else {
      setPredefinedOption("None");
    }
  }, [dropdownData]);

  //------------------------editedData for Date---------------------------------------//

  const itemsToCheck = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Male",
    "Female",
    "Non-Binary",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const shouldShowTextarea = !itemsToCheck.some((item) =>
    textareaDataRadio.includes(item)
  );

  const isEditedDateHighlighted = findDateData(treeData, selectedItemID);

  const currentDate = new Date().toISOString().split("T")[0];

  const isCurrentButtonHighlighted = isEditedDateHighlighted === currentDate;

  const isNoneDate = isEditedDateHighlighted === "";

  const isCustomDate =
    isEditedDateHighlighted !== "" && isEditedDateHighlighted !== currentDate;

  //

  useEffect(() => {
    const fieldNames = fieldData
      ? fieldData
          .filter(
            (item) => item.elementtype === "field" && item.fieldtype === "text"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setFieldNames(fieldNames);
  }, [fieldData]);

  const [checkboxField, setCheckboxField] = useState([]);
  const [RadioField, setRadioField] = useState([]);

  useEffect(() => {
    const fieldNamesCheckbox = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setCheckboxField(fieldNamesCheckbox);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesRadio = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setRadioField(fieldNamesRadio);
  }, [fieldData]);

  const dataInputTextArray =
    typeof inputTextCheckbox === "string"
      ? inputTextCheckbox.split("\n")
      : Array.isArray(inputTextCheckbox)
        ? inputTextCheckbox
        : [];

  const dataInputTextArrayRadio =
    typeof inputTextRadio === "string"
      ? inputTextRadio.split("\n")
      : Array.isArray(inputTextRadio)
        ? inputTextRadio
        : [];

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectChange = (index, value) => {
    setSelectedOptions((prev) => ({ ...prev, [index]: value }));
  
    setTextareaDataCheckBox((prev) => {
      const updatedValues = prev.split("\n");
      updatedValues[index] = value;
      return updatedValues.join("\n");
    });
  
    const findNodeById = (controlArray, id) => {
      if (!Array.isArray(controlArray)) {
        return null;
      }
  
      for (const control of controlArray) {
        if (control.id === id) {
          return control;
        }
        if (control.control && control.control.length > 0) {
          const found = findNodeById(control.control, id);
          if (found) return found;
        }
      }
      return null;
    };

    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));
      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );
  
      if (matchingNode) {
        matchingNode.control = updateControl(
          matchingNode.control,
          "CheckBoxMainContainer",
          index,
          value
        );
        return updatedTreeData;
      } else {
        console.error("No matching node found for the selected ID");
        return prevTreeData;
      }
    });
  };
  
  const updateControl = (controlArray, mediaToUpdate, index, value) => {
    return controlArray.map((control) => {
      if (control.media === mediaToUpdate) {
        if (control.control[index]) {
          control.control[index].data = value;
        }
      }
      if (control.control && control.control.length > 0) {
        control.control = updateControl(
          control.control,
          mediaToUpdate,
          index,
          value
        );
      }
      return control;
    });
  };
  



  //==================================

  const handleSelectChangeRadio = (index, value) => {
    setSelectedOptions((prev) => ({ ...prev, [index]: value }));

    setTextareaDataRadio((prev) => {
      const updatedValues = prev.split("\n");
      updatedValues[index] = value;
      return updatedValues.join("\n");
    });

    const findNodeById = (controlArray, id) => {
      if (!Array.isArray(controlArray)) {
        return null;
      }

      for (const control of controlArray) {
        if (control.id === id) {
          return control;
        }
        if (control.control && control.control.length > 0) {
          const found = findNodeById(control.control, id);
          if (found) return found;
        }
      }
      return null;
    };

    // Update treeData with the new selected value.
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));
      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (matchingNode) {
        matchingNode.control = updateControlRadio(
          matchingNode.control,
          "RadioMainContainer",
          index,
          value
        );
        return updatedTreeData;
      } else {
        console.error("No matching node found for the selected ID");
        return prevTreeData;
      }
    });
  };

  const updateControlRadio = (controlArray, mediaToUpdate, index, value) => {
    return controlArray.map((control) => {
      if (control.media === mediaToUpdate) {
        if (control.control[index]) {
          control.control[index].data = value;
        }
      }
      if (control.control && control.control.length > 0) {
        control.control = updateControl(
          control.control,
          mediaToUpdate,
          index,
          value
        );
      }
      return control;
    });
  };
  
  return (
    <React.Fragment>
      {controlsVisible &&
         
        rightsideStyletab === "3" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <>
              {selectedItem &&
                selectedItem.controltype === "DropDown" &&
                controlsVisible &&
                  (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <div className="inpt-fild-test">Predefined Options</div>
                          <div className="inpt-cntr-unit unit-option">
                            <select
                              className="theme-input-slt"
                              value={predefinedOption}
                              onChange={handlePredefinedOptionChange}
                            >
                              <option value="None">None</option>
                              <option value="Years">Years</option>
                              <option value="Countries">Countries</option>
                              <option value="Months">Months</option>
                              <option value="Gender">Gender</option>
                              <option value="Days">Days</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <div className="inpt-fild-test">Default Value</div>
                          <div className="inpt-cntr-unit unit-option">
                            <select
                              className="theme-input-slt"
                              value={selectedDefaultValue}
                              onChange={handleDefaultValueChange}
                            >
                              {Array.isArray(defaultValues) &&
                                defaultValues.map((value, index) => (
                                  <option key={index} value={value}>
                                    {value}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {predefinedOption === "None" && (
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="optns-predefnd">
                            <div className="inpt-fild-test">Field Dropdown</div>
                            <Textarea
                              className="theme-input-drpdown"
                              value={noneValue}
                              onChange={handleFieldChangeAddressDropDown}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "Table" &&
                controlsVisible &&
                  (
                  <div className="card-info-ovr-flow">
                    <TableContainer>
                      <Table
                        className="custom-table"
                        style={{ height: "100%" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>TableCells</TableCell>
                            <TableCell>Data Source</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableNames.map((controlName, index) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{ width: "30%", color: "#0056b3" }}
                              >
                                {controlName}
                              </TableCell>
                              <TableCell style={{ width: "100%" }}>
                                <Select
                                  className="data-fild-size"
                                  value={
                                    selectedValues[controlName] ||
                                    tableControlDatas.find(
                                      (pair) =>
                                        pair.option === controlName &&
                                        tableFieldNames.includes(
                                          pair.data.replace(/[{}]/g, "").trim()
                                        )
                                    )?.data ||
                                    ""
                                  }
                                  onChange={(e) =>
                                    handleSelectChange1(
                                      controlName,
                                      e.target.value
                                    )
                                  }
                                >
                                  {tableControlDatas.map((pair, pairIndex) => {
                                    const fieldNameMatch =
                                      tableFieldNames.includes(
                                        pair.data.replace(/[{}]/g, "").trim()
                                      );
                                    if (
                                      pair.option === controlName &&
                                      fieldNameMatch
                                    ) {
                                      return (
                                        <MenuItem
                                          key={pairIndex}
                                          value={pair.data}
                                        >
                                          {pair.data}
                                        </MenuItem>
                                      );
                                    }
                                    return null;
                                  })}

                                  {tableFieldNames.map(
                                    (fieldName, fieldIndex) => {
                                      const fieldNameWithoutBraces = fieldName
                                        .replace(/[{}]/g, "")
                                        .trim();
                                      const dataExists = tableControlDatas.some(
                                        (pair) =>
                                          pair.option === controlName &&
                                          pair.data.includes(
                                            fieldNameWithoutBraces
                                          )
                                      );
                                      if (!dataExists) {
                                        return (
                                          <MenuItem
                                            key={fieldIndex}
                                            value={fieldName}
                                          >
                                            {fieldName}
                                          </MenuItem>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </Select>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "MultipleChoice" &&
                controlsVisible &&
                  (
                  <div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        {selectedArrayNameCheckbox === "None" && (
                          <div style={{ display: "flex", width: "100%" }}>
                            <div className="optns-predefnd">
                              <div className="inpt-fild-test">Options</div>

                              <textarea
                                className="theme-input-drpdown"
                                value={textareaDataCheckBox}
                                onChange={handleTextareaChangeCheckBox}
                                onKeyDown={handleTextareaKeyDownCheckBox}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div class="inpt-fild-test">Data Source</div>
                          <div
                            className="advnce-field-stree"
                            style={{ padding: "2px" }}
                          >
                           {dataInputTextArray.map((item, index) => {
  const matchingNode = findNodeById(treeData[0].Source[0].control, selectedItem.id);
  const controlNode =
    matchingNode &&
    matchingNode.control.find(
      (ctrl) => ctrl.media === "CheckBoxMainContainer" && ctrl.control[index]
    );

  const currentValue = controlNode ? controlNode.control[index].data : "";

  return (
    <div
      key={index}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1px",
      }}
    >
      <div className="fld-name">{item}</div>
      <div className="dt-src-name">
        {dataSourceArID !== 0 && (
          <FormControl>
            <select
              className="theme-input-slt-dtsrc"
              MenuProps={{
                PaperProps: {
                  className: "dt-dropdn-menu",
                },
                MenuListProps: {
                  className: "custom-menu-list",
                },
              }}
              value={currentValue || ""}
              onChange={(e) => handleSelectChange(index, e.target.value)}
            >
               <option value="" >
    Select an option
  </option>
              {checkboxField.map((fieldName, idx) => (
                <option
                  key={idx}
                  value={`{${fieldName.toLowerCase()}}`}
                  className="mui_root_name"
                >
                  {fieldName}
                </option>
              ))}
            </select>
          </FormControl>
        )}
      </div>
    </div>
  );
})}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <div className="inpt-fild-test">Predefined Options</div>
                          <div className="inpt-cntr-unit unit-option">
                            <select
                              className="theme-input-slt"
                              onChange={(event) => {
                                switch (event.target.value) {
                                  case "Gender":
                                    handleCheckBoxChange(
                                      { target: { checked: true } },
                                      "CheckBoxMainContainer",
                                      GenderCheckBoxJSON,
                                      ["Male", "Female", "Non-Binary"],
                                      selectedItem.id
                                    );
                                    break;
                                  case "None":
                                    handleCheckBoxChange(
                                      { target: { checked: true } },
                                      "CheckBoxMainContainer",
                                      GenderCheckBoxJSON,
                                      ["data1", "data2", "data3", "data4"],
                                      selectedItem.id
                                    );
                                    break;
                                  case "Days":
                                    handleCheckBoxChange(
                                      { target: { checked: true } },
                                      "CheckBoxMainContainer",
                                      GenderCheckBoxJSON,
                                      [
                                        "Sunday",
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday",
                                        "Saturday",
                                      ],
                                      selectedItem.id
                                    );
                                    break;
                                  case "Months":
                                    handleCheckBoxChange(
                                      { target: { checked: true } },
                                      "CheckBoxMainContainer",
                                      GenderCheckBoxJSON,
                                      [
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                      ],
                                      selectedItem.id
                                    );
                                    break;
                                  default:
                                    break;
                                }
                              }}
                              value={selectedArrayNameCheckbox}
                            >
                              <option value="None">None</option>
                              <option value="Gender">Gender</option>
                              <option value="Days">Days</option>
                              <option value="Months">Months</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

             
                    {/* --------------------- */}
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="dspl-otr-otion">
                          <div className="inpt-fild-test">Display Other Options</div>
                          <div className="tggle-switch unit-option">
                            <div
                              className={`toggle-box ${switchCheckedCheckBox ? "active" : "inactive"}`}
                              onClick={handleBoxTogglecheckbox}
                            >
                              <span
                                className={
                                  switchCheckedCheckBox
                                    ? "on-text"
                                    : "inactive-on"
                                }
                              >
                                ON
                              </span>
                              <span
                                className={
                                  !switchCheckedCheckBox
                                    ? "on-text"
                                    : "inactive-off"
                                }
                              >
                                OFF
                              </span>
                            </div>
                            {switchCheckedCheckBox && (
                              <input
                                className="fild-inpt-options"
                                value={textFieldValueCheckBox}
                                onChange={handleTextFieldChangeCheckBox}
                                placeholder="Enter text"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* --------------------------- */}

                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="dspl-otr-otion">
                          <div className="inpt-fild-test">Spread to Columns</div>
                          <div className="tggle-switch unit-option">
                            <div
                              className={`toggle-box ${spreadCheckCheckBox ? "active" : "inactive"}`}
                              onClick={handleSpreadCheckCheckBox}
                            >
                              <span
                                className={
                                  spreadCheckCheckBox
                                    ? "on-text"
                                    : "inactive-on"
                                }
                              >
                                ON
                              </span>
                              <span
                                className={
                                  !spreadCheckCheckBox
                                    ? "on-text"
                                    : "inactive-off"
                                }
                              >
                                OFF
                              </span>
                            </div>
                            {spreadCheckCheckBox && (
                              <input
                                className="fild-inpt-options"
                                type="number"
                                value={columnValueCheckBox}
                                onChange={handleColumnChangeCheckBox}
                                placeholder="Enter number of columns"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "SingleChoice" &&
                controlsVisible &&
                  (
                  <div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        {selectedArrayNameRadio === "None" && (
                          <div style={{ display: "flex", width: "100%" }}>
                            <div className="optns-predefnd">
                              <div className="inpt-fild-test">Options</div>

                              <textarea
                                className="theme-input-drpdown"
                                value={textareaDataRadio}
                                onChange={handleTextareaChangeRadio}
                                onKeyDown={handleTextareaKeyDownRadio}
                              />
                            </div>
                           
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="clr-schm-option">
  <div className="drowerseparater">
    <div className="label-algnmt">
      <div className="inpt-fild-test">Data Source</div>
      <div
        className="advnce-field-stree"
        style={{ padding: "2px" }}
      >
        {dataInputTextArrayRadio.map((item, index) => {
          // Logic to determine current value
          const matchingNode = findNodeById(
            treeData[0].Source[0].control,
            selectedItem.id
          );
          const controlNode =
            matchingNode &&
            matchingNode.control.find(
              (ctrl) =>
                ctrl.media === "RadioMainContainer" &&
                ctrl.control[index]
            );

          const currentValue = controlNode?.control[index]?.data || "";

          return (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1px",
              }}
            >
              <div className="fld-name">{item}</div>
              <div>
                {dataSourceArID !== 0 && (
                  <FormControl>
                    <select
                      className="theme-input-slt-dtsrc"
                      MenuProps={{
                        PaperProps: {
                          className: "dt-dropdn-menu",
                        },
                        MenuListProps: {
                          className: "custom-menu-list",
                        },
                      }}
                      value={currentValue}
                      onChange={(e) =>
                        handleSelectChangeRadio(
                          index,
                          e.target.value
                        )
                      }
                    >
                      {/* Placeholder Option */}
                      <option value="">Select an option</option>
                      {/* Render Options */}
                      {RadioField.map((fieldName, idx) => (
                        <option
                          key={idx}
                          value={`{${fieldName.toLowerCase()}}`}
                          className="mui_root_name"
                        >
                          {fieldName}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
</div>

                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <div className="inpt-fild-test">Predefined Options</div>
                          <div className="inpt-cntr-unit unit-option">
                            <select
                              className="theme-input-slt"
                              onChange={(event) => {
                                switch (event.target.value) {
                                  case "Gender":
                                    handleRadioChange(
                                      { target: { checked: true } },
                                      "RadioMainContainer",
                                      GenderJSON,
                                      ["Male", "Female", "Non-Binary"],
                                      selectedItem.id
                                    );
                                    break;
                                  case "None":
                                    handleRadioChange(
                                      { target: { checked: true } },
                                      "RadioMainContainer",
                                      GenderJSON,
                                      ["data1", "data2", "data3", "data4"],
                                      selectedItem.id
                                    );
                                    break;
                                  case "Days":
                                    handleRadioChange(
                                      { target: { checked: true } },
                                      "RadioMainContainer",
                                      GenderJSON,
                                      [
                                        "Sunday",
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday",
                                        "Saturday",
                                      ],
                                      selectedItem.id
                                    );
                                    break;
                                  case "Months":
                                    handleRadioChange(
                                      { target: { checked: true } },
                                      "RadioMainContainer",
                                      GenderJSON,
                                      [
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                      ],
                                      selectedItem.id
                                    );
                                    break;
                                  default:
                                    break;
                                }
                              }}
                              // value={selectedArrayName}
                              value={selectedArrayNameRadio}
                            >
                              <option value="None">None</option>
                              <option value="Gender">Gender</option>
                              <option value="Days">Days</option>
                              <option value="Months">Months</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="dspl-otr-otion">
                          <div className="inpt-fild-test">Display Other Options </div>
                          <div className="tggle-switch unit-option">
                            <div
                              className={`toggle-box ${switchCheckedRadio ? "active" : "inactive"}`}
                              onClick={handleBoxToggle}
                            >
                              <span
                                className={
                                  switchCheckedRadio ? "on-text" : "inactive-on"
                                }
                              >
                                ON
                              </span>
                              <span
                                className={
                                  !switchCheckedRadio
                                    ? "on-text"
                                    : "inactive-off"
                                }
                              >
                                OFF
                              </span>
                            </div>
                            {switchCheckedRadio && (
                              <input
                                className="fild-inpt-options"
                                value={textFieldValueRadio}
                                onChange={handleTextFieldChangeRadio}
                                placeholder="Enter text"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="dspl-otr-otion">
                          <div className="inpt-fild-test">Spread to Columns</div>
                          <div className="tggle-switch unit-option">
                            <div
                              className={`toggle-box ${spreadCheckRadio ? "active" : "inactive"}`}
                              onClick={handleSpreadCheckRadio}
                            >
                              <span
                                className={
                                  spreadCheckRadio ? "on-text" : "inactive-on"
                                }
                              >
                                ON
                              </span>
                              <span
                                className={
                                  !spreadCheckRadio ? "on-text" : "inactive-off"
                                }
                              >
                                OFF
                              </span>
                            </div>
                            {spreadCheckRadio && (
                              <input
                                className="fild-inpt-options"
                                type="number"
                                value={columnValueRadio}
                                onChange={handleColumnChangeRadio}
                                placeholder="Enter number of columns"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "RatingBlock" &&
                controlsVisible &&
                  (
                  <div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Rating Scale</div>
                          <input
                            type="number"
                            className="tab-txtbox unit-option"
                            value={textareaDataRating}
                            onChange={handleAddControlStar}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Ratings</div>
                          <input
                            type="number"
                            className="tab-txtbox unit-option"
                            value={textareaDataColorRating}
                            onChange={handleColorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "DateBlock" &&
                controlsVisible &&
                  (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater-optns">
                        <div className="optns-predefnd">
                          <div className="inpt-fild-test">Default Date</div>
                          <div
                            className="inpt-cntr-unit-date"
                            style={{ marginTop: "5px" }}
                          >
                            <Button
                              onClick={handleFieldChangeDate}
                              style={{
                                backgroundColor: isNoneDate
                                  ? "#0075e3"
                                  : "initial",
                                color: isNoneDate ? "white" : "initial",
                              }}
                            >
                              None
                            </Button>
                            <Button
                              onClick={() => setShowCustomDateInput(true)}
                              style={{
                                backgroundColor: isCustomDate
                                  ? "#0075e3"
                                  : "initial",
                                color: isCustomDate ? "white" : "initial",
                              }}
                            >
                              Custom
                            </Button>
                            <Button
                              onClick={handleFieldChangeCurrent}
                              style={{
                                backgroundColor: isCurrentButtonHighlighted
                                  ? "#0075e3"
                                  : "initial",
                                color: isCurrentButtonHighlighted
                                  ? "white"
                                  : "initial",
                              }}
                            >
                              Current
                            </Button>
                          </div>
                        </div>
                        {/* </div> */}
                        {showCustomDateInput && (
                          <div style={{ marginTop: "10px" }}>
                            <input
                              type="date"
                              value={editedDate}
                              onChange={handleCustomDateChange}
                              style={{
                                width: "100%",
                                outline: "none",
                                height: "30px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {dataSourceArID !== 0 && (
                      <div className="dt-src-name">
                        <div className="clr-schm-option">
                          <div className="drowerseparater">
                            <div className="optns-predefnd">
                              {/* <FormControl> */}
                              <div className="tab-titles dt-type inpt-fild-test">
                                Data Source
                              </div>
                              <div className="inpt-cntr-unit unit-option">
                                <select
                                  className="theme-input-slt"
                                  labelId="select-field-label"
                                  value={editedDateDS}
                                  onChange={handleDateOptionChange}
                                  // className="hnd_slt_text-name"
                                  MenuProps={{
                                    PaperProps: {
                                      className: "dt-dropdn-menu",
                                    },
                                    MenuListProps: {
                                      className: "custom-menu-list",
                                    },
                                  }}
                                >
                                  {DateField.map((fieldName, index) => (
                                    <option
                                      key={index}
                                      value={`{${fieldName.toLowerCase()}}`}
                                      className="mui_root_name"
                                    >
                                      {fieldName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* </FormControl> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "Address" &&
                controlsVisible &&
                  (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="city-span">
                          <input
                            className="chbx-size"
                            type="checkbox"
                            // defaultChecked={!isCheckboxCheckedStreet1()}
                            checked={CheckboxCheckedStreet1}
                            onChange={handleCheckboxChangeStreet1}
                          />

                          <span>Street Address</span>
                        </div>
                        <div className="city-span">
                          <input
                            className="chbx-size"
                            type="checkbox"
                            // defaultChecked={!isCheckboxCheckedStreet2()}
                            checked={CheckboxCheckedStreet2}
                            onChange={handleCheckboxChangeStreet2}
                          />
                          <span>Street Address2</span>
                        </div>
                        <div className="city-span">
                          <input
                            className="chbx-size"
                            type="checkbox"
                            checked={CheckboxCheckedCity}
                            onChange={handleCheckboxChange1}
                          />
                          <span>City</span>
                        </div>
                        <div className="city-span">
                          <input
                            className="chbx-size"
                            type="checkbox"
                            checked={CheckboxCheckedState}
                            onChange={handleCheckboxChangeState}
                          />
                          <span>State </span>
                        </div>
                        <div className="city-span">
                          <input
                            className="chbx-size"
                            type="checkbox"
                            // defaultChecked={!isCheckboxCheckedPostal()}
                            checked={CheckboxCheckedPostal}
                            onChange={handleCheckboxChangePostal}
                          />

                          <span>Postal/Zip Code</span>
                        </div>
                        <div className="city-span">
                          <input
                            className="chbx-size-contry"
                            type="checkbox"
                            checked={CheckboxCheckedCountry}
                            onChange={handleCheckboxChangeCountry}
                          />

                          <span>Country</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      {CheckboxCheckedCountry && (
                        <div>
                          <div className="clr-schm-option">
                            <div className="drowerseparater">
                              <div className="optns-predefnd">
                                <div className="inpt-fild-test">Country</div>
                                <div className="inpt-cntr-unit unit-option">
                                  <select
                                    className="theme-input-slt"
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                  >
                                    {countries.map((country, index) => (
                                      <option key={index} value={country}>
                                        {country}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
            </>,
            targetElement
          );
        })()}
    </React.Fragment>
  );
};

export default OptionSection;
