import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../assets/icofontcharts/icofont.min.css";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "../Components/IncludeFile/Theme";
import { ReadArray } from "../Components/Api/ApiCalls";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const Discover = ({
  setIsOpenDiscover,
  isOpenDiscover,
  setIsOpen,
  selectedArrayName,
  setSelectedArrayName,
  setArrayName,
  setDescription,
  setIsOpenSelectpage,
  authData
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {  DataSourceArID, ArID } = location.state;
  let accessKey = authData.accessKey || "";
  const arrayName = location.state ? location.state.ArrayName : null;
  const description = location.state ? location.state.Description : null;
  const category = location.state ? location.state.Category : null;

  const [selectedValues, setSelectedValues] = useState({});
  const [discoverEditPage, seDiscoverEditPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arrayNames, setArrayNames] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  
  const [arrayNameError, setArrayNameError] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const Source = location.state ? location.state.Source : null;
  // const loginData = JSON.parse(sessionStorage.getItem("loginData"));
 
let APIProvider = window.APIProvider;

  const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`
  
  let newSource = null;
  try {
    let intermediateSource = JSON.parse(Source);
    newSource = typeof intermediateSource === 'string' ? JSON.parse(intermediateSource) : intermediateSource;
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }


  function extractNames(obj) {
    let namesWithType = [];
    const traverse = (obj) => {
      if (obj === null) {
        return;
      }
      if (Array.isArray(obj)) {
        obj.forEach((item) => traverse(item));
      } else if (typeof obj === "object") {
        if (
          "type" in obj &&
          (obj.type === "TextBox" ||
            obj.type === "TextArea" ||
            obj.type === "Date" ||
            obj.type === "Image" ||
            obj.type === "Switch" ||
            obj.type === "Checkbox" ||
            obj.type === "Radio") &&
          "name" in obj
        ) {
          namesWithType.push({ name: obj.name, type: obj.type });
        }

        Object.values(obj).forEach((value) => {
          traverse(value);
        });
      }
    };
    traverse(obj);
    return namesWithType;
  }



  const classes = useStyles();

  const extractTableControlNames = (sourceData) => {
    const tableControlNames = [];

    const traverse = (obj) => {
      if (obj === null) {
        return;
      }
      if (Array.isArray(obj)) {
        obj.forEach((item) => traverse(item));
      } else if (typeof obj === "object") {
        if (obj.type === "Table" && obj.control) {
          obj.control.forEach((controlItem) => {
            if (controlItem.tableHeader && controlItem.type === "Table") {
              tableControlNames.push(controlItem.tableHeader);
            }
            traverse(controlItem.control);
          });
        } else if (obj.controltype === "Row" && obj.control) {
          obj.control.forEach((controlItem) => {
            if (controlItem.tableHeader && controlItem.type === "Table") {
              tableControlNames.push(controlItem.tableHeader);
            }
            traverse(controlItem.control);
          });
        } else if (obj.type === "Table" && obj.tableHeader) {
          tableControlNames.push(obj.tableHeader);
        } else {
          Object.values(obj).forEach((value) => traverse(value));
        }
      }
    };

    traverse(sourceData);
    return tableControlNames;
  };


  const [sourceData, setSourceData] = useState(null);
  const [fieldData, setFieldData] = useState([]);
  const [fieldNames, setFieldNames] = useState([]);

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = await ReadArray(DataSourceArID,accessKey);
        if (jsonData && jsonData.length > 0) {
          setSourceData(jsonData[0].Source);
          const fetchedSourceData = jsonData[0].Source;
          setFieldData(fetchedSourceData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [DataSourceArID]);
  

  const names = extractNames(newSource ? newSource : []);
  const tableControlNames = extractTableControlNames(
    newSource ? newSource : []
  );

  const handleCreate1 = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }

    setLoading(true);

    try {
      if (updatedSource) {
        const requestBodywriteArray = {
          accessKey: authData.accessKey,
          guid:APIProvider.GUID.WriteArrayGUID,
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: JSON.stringify({
            ArID: ArID,
            ArrayName: arrayName,
            Description: description,
            Source: updatedSource,
            Category: category,
            IsTemplate: "0",
            htmlOutput: "new",
            DataSourceArID: DataSourceArID,
          }),
        };

        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBodywriteArray),
          }
        );
        if (response.ok) {
     
          const stateObject = {
            ArID: ArID,
            ArrayName: arrayName,
            Description: description,
            Category: category,
            Source: updatedSource,
            IsTemplate: "0",
            htmlOutput: "new",
            DataSourceArID: DataSourceArID || 0,
          };

          setLoading(false);
          navigate(`/Form/${ArID}`, { state: stateObject });
        } else {
          console.error("Error:", response.statusText);
        }
      } else {
        console.error("Error: Source data is missing.");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  useEffect(() => {
    const fieldNames = fieldData
      ? fieldData
          .filter((item) => item.elementtype === "field")
          .map((item) => item.fieldname.toLowerCase())
      : [];
    setFieldNames(fieldNames);
  }, [fieldData]);

  const findClosestMatch = (inputName) => {
    if (typeof inputName !== "string") {
      return "";
    }
    const lowercaseName = inputName.toLowerCase();
    const closestMatch = fieldNames.find((fieldName) =>
      fieldName.includes(lowercaseName)
    );
    return closestMatch ? closestMatch : "";
  };



  const modifiedSource = newSource

  const updateDescription = (obj, inputName, selectedValues) => {
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        updateDescription(item, inputName, selectedValues);
      });
    } else if (typeof obj === "object") {
      if (obj.type === "Table" && obj.control) {
        obj.control.forEach((controlItem) => {
          updateDescription(controlItem, inputName, selectedValues);
        });
      } else if (obj.controltype === "Row" && obj.control) {
        obj.control.forEach((controlItem) => {
          updateDescription(controlItem, inputName, selectedValues);
        });
      } else if (obj.type === "Table" && obj.tableHeader) {
        const closestMatch = selectedValues[obj.tableHeader];
        if (closestMatch !== undefined && closestMatch !== "") {
          obj.data = `{${closestMatch}}`;
        }
      } else if (
        (obj.type === "TextBox" ||
          obj.type === "TextArea" ||
          obj.type === "Date" ||
          obj.type === "Image" ||
          obj.type === "Switch" ||
          obj.type === "Checkbox" ||
          obj.type === "Radio") &&
        obj.name
      ) {

        const closestMatch =
          selectedValues[obj.name] || findClosestMatch(obj.name);

        if (closestMatch !== undefined && closestMatch !== "") {
          obj.inputText = `{${closestMatch}}`;

          if (obj.type === "Date") {
            obj.inputText = `{${closestMatch}}`;
            obj.data = `{${closestMatch}}`;
          } else if (obj.type === "Image" || obj.type === "Switch") {
            obj.imageText = `{${closestMatch}}`;
          } else if (
            obj.type === "Checkbox" ||
            obj.type === "Radio" ||
            obj.type === "TextBox" ||
            obj.type === "TextArea"
          ) {
            obj.data = `{${closestMatch}}`;
          } else {
            obj.inputText = `{${closestMatch}}`;
          }
        }
      } else {
        Object.values(obj).forEach((value) => {
          updateDescription(value, inputName, selectedValues);
        });
      }
    }
  };

  names.forEach((inputName) => {
    updateDescription(modifiedSource, inputName, selectedValues);
  });

  const updatedSource = JSON.stringify(modifiedSource);

  const handleSelectChange = (inputName, newValue) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [inputName]: newValue,
    }));
  };

  const tableFieldNames = fieldData
    ? fieldData
        .filter((item) => item.elementtype === "array" && item.type === "table")
        .flatMap((item) => item.elements.map((element) => element.fieldname))
    : [];

  const handleBack = () => {
    setIsOpenDiscover(false);
    seDiscoverEditPage(true);
    setIsOpen(false);
  };

  const handleBack1 = () => {
    setIsOpenDiscover(false);
    setIsOpen(false);
    setIsOpenSelectpage(false);
  };

  const handleCancelClick = () => {
    setArrayNameError("");
    setLoading(false);
  };

  const handleSave = () => {
  
    setIsEditMode(false);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Refersh",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSave();
      },
      tooltip: "Save",
    },
  ];

  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    fetchDataarrayname();
  }, []);

  const fetchDataarrayname = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    try {
      const requestBody = 
      {
        accessKey: authData.accessKey,
        guid:APIProvider.GUID.ReadArrayAllGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: "",
      };
     

      const response = await fetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

   const jsonData = await response.json();
   const apiResponse = JSON.parse(jsonData.apiResponse);

   const filteredJsonData = apiResponse
   .filter((item) => item.IsTemplate === 2)
   


      // const filteredJsonData = jsonData.filter((item) => item.isTemplate === 2);
      setFilteredData(filteredJsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectChange1 = (event) => {
    const selectedArID = event.target.value;

    const selectedItem = filteredData.find(
      (item) => item.arrayName === selectedArID
    );
    setSelectedItem(selectedItem);
    setSelectedArrayName(selectedArID);
  };



  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    const requestBody = {
      accessKey:authData.accessKey,
      guid: APIProvider.GUID.ReadArrayAllGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: "",
    };

    const fetchArrayNames = async () => {
      try {
        const response = await fetch(baseURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const data = await response.json();

          const apiResponse = JSON.parse(data.apiResponse);

          const filteredNames = apiResponse
            .filter((item) => item.IsTemplate === 2)
            .map((item) => item.ArrayName);

         

          setArrayNames(filteredNames);
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error fetching array names:", error);
      }
    };
    fetchArrayNames();
  }, []);

  const handleCreateEdit = () => {
    setIsOpenDiscover(true);
  };
  return (
    <>
      <CustomPopUpForm
        open={isOpenDiscover}
        onClose={handleBack1}
        title="Discover"
        height="60%"
      >
        <>
          <div className="card-info-ovr-flow">
            <TableContainer>
              <Table className="custom-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Control</TableCell>
                    <TableCell>Data Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {" "}
                  {names.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ width: "30%", color: "#0056b3" }}>
                        {item.name}
                      </TableCell>
                      <TableCell style={{ width: "100%" }}>
                        <Select
                          className="data-fild-size-dis"
                          value={
                            selectedValues[item.name] ||
                            findClosestMatch(item.name)
                          }
                          onChange={(e) =>
                            handleSelectChange(item.name, e.target.value)
                          }
                        >
                          {fieldNames
                            .filter((fieldName) => {
                              if (
                                item.type === "TextBox" ||
                                item.type === "TextArea"
                              ) {
                                return fieldData.find(
                                  (field) =>
                                    field.fieldname &&
                                    field.fieldname.toLowerCase() ===
                                      fieldName.toLowerCase() &&
                                    field.elementtype === "field" &&
                                    field.fieldtype === "text"
                                );
                              } else if (item.type === "Date") {
                                const foundField = fieldData.find(
                                  (field) =>
                                    field.fieldname &&
                                    field.fieldname.toLowerCase() ===
                                      fieldName.toLowerCase() &&
                                    field.elementtype === "field" &&
                                    (field.fieldtype === "datetime" ||
                                      field.fieldtype === "time" ||
                                      field.fieldtype === "date")
                                );
                                return foundField;
                              } else if (item.type === "Image") {
                                const foundField = fieldData.find(
                                  (field) =>
                                    field.fieldname &&
                                    field.fieldname.toLowerCase() ===
                                      fieldName.toLowerCase() &&
                                    field.elementtype === "field" &&
                                    field.fieldtype === "Image"
                                );
                                return foundField;
                              } else if (
                                item.type === "Switch" ||
                                item.type === "Checkbox" ||
                                item.type === "Radio"
                              ) {
                                const foundField = fieldData.find(
                                  (field) =>
                                    field.fieldname &&
                                    field.fieldname.toLowerCase() ===
                                      fieldName.toLowerCase() &&
                                    field.elementtype === "field" &&
                                    field.fieldtype === "boolean" || field.fieldname &&
                                    field.fieldname.toLowerCase() ===
                                      fieldName.toLowerCase() &&
                                    field.elementtype === "field" &&
                                    field.fieldtype === "number" && (field.controltype === "Checkbox" || field.controltype === "Switch" || field.controltype === "Radio" ))

                                       return foundField;
                              }
                            })
                            .map((fieldName, index) => (
                              <MenuItem
                                key={index}
                                value={fieldName.toLowerCase()}
                              >
                                {fieldName}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                  {tableControlNames.map((controlName, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ width: "30%", color: "#0056b3" }}>
                        {controlName}
                      </TableCell>
                      <TableCell style={{ width: "100%" }}>
                        <Select
                          className="data-fild-size-dis"
                          value={selectedValues[controlName]}
                          onChange={(e) =>
                            handleSelectChange(controlName, e.target.value)
                          }
                        >
                          {tableFieldNames.map((fieldName, index) => (
                            <MenuItem key={index} value={fieldName}>
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
        <>
          <div style={{ width: "93%", display: "flex" }}>
            <Button fullWidth onClick={handleBack}>
              Back
            </Button>
            <Button variant="contained" fullWidth onClick={handleCreate1}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Next"
              )}
            </Button>
          </div>
        </>
      </CustomPopUpForm>

      <CustomPopUpForm
        open={discoverEditPage}
        onClose={handleBack1}
        title="Create New Form"
        iconButtons={isEditMode ? iconButtonsSaveAndRestore : iconButtonsEdit}
        height="60%"
      >
        <>
          {!isEditMode ? (
            <>
              <div className="card-info card-info-ovr-flow">
                <div className="card-info-inside card-info-inside-view">
                  <Typography className="card-info-inside-text">
                    Form Name*:
                  </Typography>
                  <div>
                    <Typography style={{ color: "#000" }}>
                      {arrayName}
                    </Typography>
                  </div>
                </div>
                <div className="card-info-inside">
                  <Typography className="card-info-inside-text">
                    Description:
                  </Typography>
                  <div>
                    <Typography style={{ color: "#000" }}>
                      {description}
                    </Typography>
                  </div>
                </div>
                <div className="card-info-inside">
                  <Typography className="card-info-inside-text">
                    Category:
                  </Typography>
                  <div>
                    <Typography style={{ color: "#000" }}>
                      {category}
                    </Typography>
                  </div>
                </div>
                <div className="card-info-inside">
                  <Typography className="card-info-inside-text">
                    DataSourceField:
                  </Typography>
                  <div>
                    <Typography style={{ color: "#000" }}>
                      {selectedArrayName}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="card-info">
              <div className="card-info-inside card-info-inside-view">
                <Typography style={{ fontWeight: "bold" }}>
                  Form Name*:
                </Typography>
                <TextField
                  required
                  type="text"
                  className="edit-popup"
                  name="Form Name"
                  onChange={(e) => {
                    setArrayName(e.target.value);
                    setArrayNameError("");
                  }}
                  value={arrayName}
                  error={!!arrayNameError}
                  helperText={arrayNameError}
                />
              </div>
              <div className="card-info-inside">
                <Typography style={{ fontWeight: "bold" }}>
                  Description:
                </Typography>
                <TextField
                  type="text"
                  className="edit-popup"
                  name="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </div>
              <div className="card-info-inside">
                <Typography style={{ fontWeight: "bold" }}>
                  Category:
                </Typography>
                <TextField
                  type="text"
                  className="edit-popup"
                  name="Category"
                  value={category}
                  readOnly
                />
              </div>
              <div className="card-info-inside">
                <Typography style={{ fontWeight: "bold" }}>
                  DataSource:
                </Typography>
                <Select
                  value={selectedArrayName}
                  onChange={handleSelectChange1}
                  className={`${classes.customSelectRoot} data-select-crd`}
                >
                  <MenuItem value="0">Select DataSource </MenuItem>
                  {arrayNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </>
        <>
          <div style={{ width: "96%", display: "flex" }}>
            <Button fullWidth onClick={handleBack1}>
              Back
            </Button>
            <Button variant="contained" fullWidth onClick={handleCreateEdit}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Next"
              )}
            </Button>
          </div>
        </>
      </CustomPopUpForm>
    </>
  );
};

export default Discover;