import { toast } from "react-toastify";
import { getFormattedLocalDateTime } from "../functions/ModifiedDate";
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";

let APIProvider = window.APIProvider;


const DEFAULT_USERID = "-1";

let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {},
  mode: "cors",
};

function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

function GetDefaultUserID() {
  return DEFAULT_USERID;
}

const createRequestBody = ({
  accessKey,
  guid,
  param1 = "",
  param2 = "",
  param3 = "",
  param4 = "",
  recordSet = "",
  body = "",
}) => {

  return {
    accessKey,
    guid,
    param1,
    param2,
    param3,
    param4,
    recordSet,
    body,
  };
};

function replaceNullsWithEmptyString(obj) {
  // Check if obj is null or undefined
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // If obj is an array, map over its elements
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceNullsWithEmptyString(item));
  }

  // If obj is an object, recursively replace null values
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value === null ? "" : replaceNullsWithEmptyString(value),
    ])
  );
}
async function GetDiagCardsInfo(obj) {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.DiagramListProjectPage + obj,
      GET_HEADER
    );
    const diagTemplates = await response.json();
    console.log("diagTemplates ", diagTemplates);
    return diagTemplates;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting Templates: " + error.message);
  }
}
async function GetDiagTools(DiagTempID) {
  console.log("param 1 ", DiagTempID);
  const response = await fetch(
    APIProvider.baseURL +
      APIProvider.recordPath.ListDiagTools +
      "?DiagTempID=" +
      DiagTempID,
    GET_HEADER
  );
  const diagTools = await response.json();
  const sanitizedDiagTools = replaceNullsWithEmptyString(diagTools);
  console.log("sanitizedDiagTools ", sanitizedDiagTools);
  return sanitizedDiagTools;
}
async function UpdateDiagTools(diagTools) {
  console.log("diagTools ", diagTools);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify([diagTools]), // Wrap diagTools in an array
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpsertDiagTools,
      request
    );
    
    if (response.ok) {
      toast.success("Tool List Updated!");
    }
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error saving data: " + error.message);
  }
}

async function GetToolProperties(diagToolID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.DiagToolsProperties +
        "?diagToolID=" +
        diagToolID,
      GET_HEADER
    );
    const toolProperties = await response.json();
    console.log("toolProperties ", toolProperties);
    return toolProperties;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting properties: " + error.message);
  }
}
async function UpdateToolProperties(toolProperties) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(toolProperties),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpsertDiagToolsProperties,
      request
    );

    toast.success("Data Saved!");
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error saving data: " + error.message);
  }
}
async function GetToolPropertiesAll() {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.GetAllToolProperties,
      GET_HEADER
    );
    const toolPropertiesAll = await response.json();
    console.log("toolPropertiesAll ", toolPropertiesAll);
    return toolPropertiesAll;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting properties: " + error.message);
  }
}
async function UpdateTemplateHeader(template) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(template),
    };
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.UpsertQinfoDiagTemplateHeader,
      request
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      // console.log("outfields:", jsonResponse.outfields);
      return jsonResponse;
    } else {
      console.error("Error: ", response.statusText);
    }
    // console.log("response ", response);
    // return response;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function UpdateTemplateDetails(template) {
  console.log("template ", template);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(template),
    };
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.UpsertQinfoDiagTemplateDetails,
      request
    );
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function UnselectToolInTemplate(DetDiagTempID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.DeleteDiagToolxReferenceTemplate +
        "?DetDiagTempID=" +
        DetDiagTempID,
      GET_HEADER
    );
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting properties: " + error.message);
  }
}
async function UpdateDiagHeader(project) {
  console.log("project ", project);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(project),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpsertQinfoDiagHeader,
      request
    );
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function GetGlyphicon() {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.GetGlyphicons,
      GET_HEADER
    );
    const icons = await response.json();
    // console.log("icons ", icons);
    return icons;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting icons: " + error.message);
  }
}
async function MakeToolInactive(DiagToolID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.MakeQinfoDiagToolsinactive +
        "?DiagToolID=" +
        DiagToolID,
      GET_HEADER
    );
    const success = await response.json();
    toast.success("Tool is inactive");
    return success;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error making tool inactive: " + error.message);
  }
}
async function GetRegions() {
  try {
    const response = await fetch(
      APIProvider.baseURL + "Get_Regions",
      GET_HEADER
    );
    const regions = await response.json();
    console.log("regions ", regions);
    return regions;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting regions: " + error.message);
  }
}

//resourceURL needs to be:
// APIProvider.recordPath.MakeQinfoDiagToolsinactive +
//         "?DiagToolID=" +
//         DiagToolID,
/*
https://localhost:7154/List_Diag_Tools?route=List%20Diag%20Tools%20By%20Template&param1=1
*/
async function getResource(resourceURL) {
  try {
    const response = await fetch(APIProvider.baseURL + resourceURL, GET_HEADER);
    const responseJSON = await response.json();
    console.log("responseJSON ", responseJSON);
    return responseJSON;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting recourse: " + error.message);
  }
}
//responseURL needs to be: APIProvider.recordPath.UpsertDiagToolsProperties
async function postResource(resourceURL, json) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(json),
    };
    const response = await fetch(APIProvider.baseURL + resourceURL, request);
    // const response = await fetch(
    //   "https://aicloud.agiline.com:7355/" + resourceURL,
    //   request
    // );
    const data = await response.json();
    if (data.isSuccessStatusCode) {
      console.log("apiResponse ", JSON.parse(data.apiResponse));
      return JSON.parse(data.apiResponse);
    } else {
      console.error("Error: ", data.errorMessage);
    }
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting recourse: " + error.message);
  }
}


const secretKey = APIProvider.secretKey.secretKey


  const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const loadLoginData = () => {
    const encryptedData = sessionStorage.getItem("loginData");

    if (encryptedData) {
      try {
        const decryptedData = decryptData(encryptedData);
        return decryptedData;
      } catch (error) {
        console.error("Failed to decrypt login data:", error);
      }
    } else {
      console.warn("No login data found in sessionStorage");
    }
  };

function getAccessKey() {
  const loginData = loadLoginData();

  if (loginData) {
    const loginDataJSON = (loginData);
    if (loginDataJSON && loginDataJSON.accessKey) {
      return loginDataJSON.accessKey;
    }
  }
  return null;
}

async function GetAccessKeyFromDemo24() {
  // let accessKey = localStorage.getItem("accessKey");
  const authData = loadLoginData()
  let accessKey = authData.accessKey;

  if (!accessKey) {
    // If no access key in local storage, call LoginDemo24
    console.log("Access key not found, logging in...");
    const loginResponse = await LoginDemo24();
    if (loginResponse.ok) {
      const data = await loginResponse.json();
      accessKey = data.accessKey; // Assuming the response contains the access key
      // localStorage.setItem("accessKey", accessKey); // Store access key in local storage
    } else {
      console.error("Login failed.");
      return null;
    }
  }

  // Check session with the existing or newly fetched access key
  const sessionResponse = await CheckSession(accessKey);
  console.log("sessionResponse ", sessionResponse);
  const sessionData = await sessionResponse.json();
  console.log("sessionData ", sessionData);
  if (sessionData.isActive) {
    console.log("Session is active.");
    return accessKey;
  } else {
    console.log("Session is not active, logging in again...");
    
    const loginResponse = await LoginDemo24();
    if (loginResponse.ok) {
      const data = await loginResponse.json();
      accessKey = data.accessKey;
      // localStorage.setItem("accessKey", accessKey); // Update access key in local storage
      return accessKey;
    } else {
      console.error("Login failed again.");
      return null;
    }
  }
}

async function CheckSession(key) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify({
        accessKey: key,
      }),
    };
    const response = await fetch(
      "https://aicloud.agiline.com:7355/GetSession",
      request
    ); // Assuming endpoint
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error checking session: " + error.message);
  }
}

async function LoginDemo24() {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify({
        userName: "admin",
        password: "test",
        tenant: "demo24",
      }),
    };
    const response = await fetch(
      "https://aicloud.agiline.com:7355/Login",
      request
    ); // Assuming endpoint
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error during login: " + error.message);
  }
}

function replaceUnderscoresWithSpaces(input) {
  if (!input) {
    return input; // Return the input as is if it's null or empty
  }

  return input.replace(/_/g, " ");
}
function updateDataWithUserAndDates(data) {
  // const loginData = JSON.parse(sessionStorage.getItem("loginData"));
const loginData = loadLoginData();

  const userName = loginData?.userName || "Unknown User"; // Use default value if userName is not available
  const todayDate = getFormattedLocalDateTime();

  // Set 'createdBy' and 'createdDate' if not already present
  if (!data.createdBy) {
    data.createdBy = userName;
  }
  if (!data.createdDate) {
    data.createdDate = todayDate;
  }

  // Update 'modifiedBy' and 'modifiedDate' with current values
  data.modifiedBy = userName;
  data.modifiedDate = todayDate;

  return data;
}

export {
  GetDiagTools,
  UpdateDiagTools,
  GetToolProperties,
  UpdateToolProperties,
  GetToolPropertiesAll,
  UpdateTemplateHeader,
  GetDiagCardsInfo,
  UpdateTemplateDetails,
  UnselectToolInTemplate,
  UpdateDiagHeader,
  GetGlyphicon,
  MakeToolInactive,
  GetRegions,
  getResource,
  postResource,
  replaceUnderscoresWithSpaces,
  createRequestBody,
  GetAccessKeyFromDemo24,
  getAccessKey,
  updateDataWithUserAndDates,
  loadLoginData
};
