import { Card, CardContent, Box, Button, CardActions } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function MenuCardComponent({
  data,
  size,
  textSize,
  handleDetails,
  handleEditDiagram,
  caller,
  isAddCard,
  editTemplate,
}) {
  // Determine if the current card is a template based on diagID
  const isTemplate = data?.diagID === 0;

  // Function to render a button with specific text and onClick action
  const renderActionButton = (text, onClick) => (
    <Button
      sx={{
        fontSize: "1rem",
        width: "50%",
        height: "85%",
        borderRadius: "30px",
      }}
      variant="outlined"
      onClick={onClick}
    >
      {text}
    </Button>
  );

  return (
    <Card
      sx={{
        width: `${size}px`,
        height: "auto",
        marginBottom: "1rem",
        marginRight: "1rem",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        transition: "box-shadow 0.3s",
        "&:hover": {
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <CardContent sx={{ height: isAddCard ? "100%" : "auto" }}>
        {isAddCard === 1 ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleEditDiagram}
          >
            <AddIcon fontSize="large" color="primary" sx={{ fontSize: 100 }} />
          </Box>
        ) : (
          <>
            {/* Icons if template edit if project delete */}
            <Box sx={{ position: "absolute", top: 8, right: 8 }}>
              {isTemplate ? (
                <EditIcon
                  color="primary"
                  onClick={() => editTemplate?.(data?.diagTempID)}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <HighlightOffIcon color="error" />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {data?.mediaURL && (
                <img
                  src={data.mediaURL}
                  alt={data?.name}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginRight: "0.5rem",
                  }}
                />
              )}
              <p
                style={{
                  color: "#1976d2",
                  fontWeight: "bold",
                  fontSize: textSize,
                }}
              >
                {data?.title ? data?.title : data?.name}
              </p>
            </Box>

            <Box sx={{ fontSize: textSize * 0.75, textAlign: "center" }}>
              <div dangerouslySetInnerHTML={{ __html: data?.explanation }} />
            </Box>
          </>
        )}
      </CardContent>
      {/* if isAddCard No buton needed else: */}
      {!isAddCard && (
        <CardActions
          sx={{ marginTop: "auto", display: "flex", justifyContent: "center" }}
        >
          {caller === 1 && isTemplate ? (
            renderActionButton("Select", () => handleDetails(data))
          ) : (
            <>
              {renderActionButton("Overview", () => handleDetails(data))}
              {renderActionButton("Edit", () => handleEditDiagram(data))}
            </>
          )}
        </CardActions>
      )}
    </Card>
  );
}
