import React, { useEffect } from "react";
import {
  Form,
  Title,
  Anchor,
  Button,
  OR,
  AssistedLoginButton,
  buttonLogoStyle,
} from "../../pages/Login/LoginStyles";
// import jwtDecode from "jwt-decode";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
function LoginCard(props) {
  return (
    <Form onSubmit={props.handleSubmit}>
      <Title>Log In</Title>
      <TextField
        name="username"
        onChange={props.handleChange}
        fullWidth
        size="small"
        label="Username"
        type="username"
        variant="outlined"
        value={props.state.username}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: props.state.username ? "visible" : "hidden",
                padding: 0,
              }}
              onClick={() => props.handleClearClick("username")}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
        sx={{
          marginTop: 2,
          "& .Mui-focused .MuiIconButton-root": {
            color: "primary.main",
          },
        }}
        error={props.submit && !props.state.username}
      />
      <TextField
        fullWidth
        size="small"
        label="Password"
        variant="outlined"
        type="password"
        name="password"
        value={props.state.password}
        onChange={props.handleChange}
        // onKeyPress={props.handleKeyPress}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: props.state.password ? "visible" : "hidden",
                padding: 0,
              }}
              onClick={() => props.handleClearClick("password")}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
        sx={{
          marginTop: 2,
          "& .Mui-focused .MuiIconButton-root": {
            color: "primary.main",
          },
        }}
        error={props.submit && !props.state.password}
      />
      <TextField
        name="tenant"
        onChange={props.handleChange}
        fullWidth
        size="small"
        label="Tenant"
        type="tenant"
        variant="outlined"
        value={props.state.tenant}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: props.state.tenant ? "visible" : "hidden",
                padding: 0,
              }}
              onClick={() => props.handleClearClick("tenant")}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
        sx={{
          marginTop: 2,
          "& .Mui-focused .MuiIconButton-root": {
            color: "primary.main",
          },
        }}
        error={props.submit && !props.state.tenant}
      />
      <FormControlLabel
        control={<Checkbox defaultChecked size="small" />}
        label={<Typography sx={{ fontSize: "12px" }}>Remember Me</Typography>}
        style={{
          alignSelf: "flex-start ",
        }}
      />
      <Button onClick={props.handleSubmit}>Log In</Button>
      {/* <AssistedLoginButton>
        <img src={googleLogo} alt="googleLogo" style={buttonLogoStyle} />
        Log In With Google
      </AssistedLoginButton> */}

      {/* <div className="App">
        <div
          id="signInDiv"
          style={{ marginTop: "0.5rem" }}
        ></div>
      </div> */}
      <div style={{ marginTop: "0.5rem" }}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
          <GoogleLogin
            text="continue_with"
            theme="outline"
            size="large"
            shape="rectangular"
            width={288}
            onSuccess={props.handleCallbackResponse}
            onError={() => console.log("Login Failed")}
          />
        </GoogleOAuthProvider>
      </div>

      <Anchor href="#">Forgot your password?</Anchor>
      {/* <OR>OR</OR>
      <Anchor href="#">Browse As Guest</Anchor> */}
    </Form>
  );
}
export default LoginCard;
