import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Divider,
  Collapse,
  ThemeProvider,
  IconButton,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import themeProvider from "../../../ThemeProvider";

function BoxTemplate({
  children,
  icon,
  title,
  button,
  buttonOnClick,
  subTitle,
  selectedNode
}) {
  const [collapsed, setCollapsed] = useState(false);

  console.log("selectedNodefromparams", selectedNode)

  console.log("selectedNodefromparams", selectedNode)

  const handleToggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

 

  

  let nodeBackgroundValue = null;

  selectedNode.data.forEach(item => {
      if (item.style) {
          item.style.forEach(styleItem => {
              if (styleItem.propertyName === "Node_Background") {
                  nodeBackgroundValue = styleItem.diagPropvalue;
              }
          });
      }
  });

  const nodeNameColor = selectedNode.data.find(
    (dataItem) => dataItem.nodename
  )?.nodename.color;

  return (
    <Grid
      style={{
        border: "1px solid #A9A9A9",
        padding: "0.5rem",
        borderRadius: "10px",
        marginBottom: "0.5rem",
      }}
    >
      <ThemeProvider theme={themeProvider}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor : nodeBackgroundValue ,
            borderRadius:"4px",
            padding:"7px 5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {icon}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  display: "flex",
                }}
              >
                {title}
              </Typography>
              {subTitle && (
                <Typography variant="body2" gutterBottom>
                  {subTitle}
                </Typography>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {button && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={buttonOnClick}
                style={{
                  minHeight: "40px",
                  maxHeight: "40px",
                }}
              >
                {button}
              </Button>
            )}
            <IconButton
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleToggleCollapse}
              style={{
                minHeight: "40px",
                maxHeight: "40px",
                color : nodeNameColor
              }}
            >
              {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </div>
        </div>
        <Collapse in={!collapsed}>
          <Divider sx={{ borderColor: "black", margin: "1rem 0" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              margin: "0.5rem",
            }}
          >
            {children}
          </div>
        </Collapse>
      </ThemeProvider>
    </Grid>
  );
}

export default BoxTemplate;
