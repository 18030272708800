const AddressJSON = [
  [
    {
      id: "",
      name: "Address",
      controlName: "Container1",
      tableHeader: "MainContainer",
      data: "",
      type: "Container",
      parentid: "",
      htmlTag: "div",
      controltype: "Address",
      styleid: "agrfb30",
      styleType: "Container",
      mediaURl: "square-poll-horizontal",
      check: false,
      switchcheck: false,
      title: "Datablocks",
      value: "",
      media: "Address",
      option: "false",
      switchoption: "AddressContainer",
      position: 1,
      checkposition: "right",
      tab: "",
      inputType: "Date",
      inputText: "",
      imageText: "",
      sourceData: "sourceData",
      controlChange: "Container",
      tableProp: "MainBlock",
      url: " ",
      control: [
        {
          id: "",
          name: "AddressHeaderContainer",
          controlName: "Container2",
          tableHeader: "",
          data: "",
          type: "Container",
          parentid: "",
          htmlTag: "div",
          controltype: "Container",
          styles: "",
          styleid: "agrfb31",
          styleType: "Container",
          mediaURl: "square-poll-horizontal",
          check: false,
          switchcheck: false,
          title: "Datablocks",
          value: "",
          media: "AddressHeaderContriner",
          option: "false",
          switchoption: "AddressContainer",
          position: 1,
          checkposition: "AddressHeaderContainer",
          tab: "",
          inputType: "Date",
          inputText: "",
          imageText: "",
          sourceData: "sourceData",
          controlChange: "Container",
          tableProp: "ChildBlock",
          url: " ",
          control: [
            {
              id: "",
              name: "AddressHeader",
              controlName: "Header1",
              tableHeader: "",
              data: "Address",
              type: "Label",
              parentid: "0.1",
              htmlTag: "label",
              controltype: "Label",
              styles: "",
              styleid: "",
              styleType: "Label",
              mediaURl: "heading",
              check: false,
              switchcheck: false,
              title: "Display",
              value: "",
              media: "MainAddressHeader",
              option: "false",
              switchoption: "false",
              position: 1,
              checkposition: "AddressHeader",
              tab: "",
              inputType: "Date",
              inputText: "",
              imageText: "",
              sourceData: "sourceData",
              controlChange: "Header",
              tableProp: "ChildBlock",
              url: " ",
              control: [],
            },
          ],
        },
        {
          id: "",
          name: "AddressSubContainer",
          controlName: "Container3",
          tableHeader: "",
          data: "",
          type: "Container",
          parentid: "",
          htmlTag: "div",
          controltype: "Container",
          styles: "",
          styleid: "",
          styleType: "Container",
          mediaURl: "square-poll-horizontal",
          check: false,
          switchcheck: false,
          title: "Datablocks",
          value: "",
          media: "AddressInput",
          option: "false",
          switchoption: "AddressContainer",
          position: 1,
          checkposition: "AddressSubContainer",
          tab: "",
          inputType: "Date",
          inputText: "",
          imageText: "",
          sourceData: "sourceData",
          controlChange: "Container",
          tableProp: "ChildBlock",
          url: " ",
          control: [
            {
              id: "",
              name: "StreetAddress1Container",
              controlName: "Container5",
              tableHeader: "",
              data: "",
              type: "Container",
              parentid: "",
              htmlTag: "div",
              controltype: "Container",
              styles: "",
              styleid: "",
              styleType: "Container",
              mediaURl: "square-poll-horizontal",
              check: false,
              switchcheck: false,
              title: "Datablocks",
              value: "",
              media: "ContainerStreet1",
              option: "false",
              switchoption: "AddressContainer",
              position: 1,
              checkposition: "StreetAddress1Container",
              tab: "",
              inputType: "Date",
              inputText: "",
              imageText: "",
              sourceData: "sourceData",
              controlChange: "Container",
              tableProp: "ChildBlock",
              url: " ",
              control: [
                {
                  id: "",
                  name: "Street1 Address TextBox",
                  controlName: "TextBox2",
                  tableHeader: "",
                  data: "Street Address",
                  type: "TextBox",
                  parentid: "",
                  htmlTag: "input",
                  controltype: "TextBox",

                  styles: "",
                  styleid: "agrfb36",
                  styleType: "TextBox",
                  mediaURl: "text-height",

                  check: false,
                  switchcheck: false,
                  title: "Input",

                  value: "",
                  media: "Street1",
                  option: "false",
                  switchoption: "false",
                  position: 1,
                  checkposition: "AddressStreet1Textbox",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "TextBox",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [],
                },
                {
                  id: "",
                  name: "Street1 Address_Label",
                  controlName: "Label2",
                  tableHeader: "",
                  data: "Street Address",
                  type: "Label",
                  parentid: "0.1",
                  htmlTag: "label",
                  controltype: "Label",
                  styles: "",
                  styleid: "agrfb35",
                  styleType: "Label",
                  mediaURl: "font",
                  check: false,
                  switchcheck: false,
                  title: "Display",
                  value: "",
                  media: "Street1",
                  option: "false",
                  switchoption: "false",
                  position: 1,
                  checkposition: "AddressStreet1",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "Label",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [],
                },
              ],
            },
            {
              id: "",
              name: "Street Address Container",
              controlName: "Container4",
              tableHeader: "",
              data: "",
              type: "Container",
              parentid: "",
              htmlTag: "div",
              controltype: "Container",
              styles: "",
              styleid: "",
              styleType: "Container",
              mediaURl: "square-poll-horizontal",
              check: false,
              switchcheck: false,
              title: "Datablocks",
              value: "",
              media: "ContainerStreet2",
              option: "false",
              switchoption: "AddressContainer",
              position: 1,
              checkposition: "StreetAddressContainer",
              tab: "",
              inputType: "Date",
              inputText: "",
              imageText: "",
              sourceData: "sourceData",
              controlChange: "Container",
              tableProp: "ChildBlock",
              url: " ",
              control: [
                {
                  id: "",
                  name: "Street2 Address TextBox",
                  controlName: "TextBox1",
                  tableHeader: "",
                  data: "Street Address Line 2",
                  type: "TextBox",
                  parentid: "",
                  htmlTag: "input",
                  controltype: "TextBox",
                  styles: "",
                  styleid: "",
                  styleType: "TextBox",
                  mediaURl: "text-height",
                  check: false,
                  switchcheck: false,
                  title: "Input",
                  value: "",
                  media: "Street2",
                  option: "false",
                  switchoption: "false",
                  position: 1,
                  checkposition: "AddressStreet2Textbox",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "TextBox",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [],
                },

                {
                  id: "",
                  name: "Street2 Address Label",
                  controlName: "Label1",
                  tableHeader: "",
                  data: "Street Address Line 2",
                  type: "Label",
                  parentid: "",
                  htmlTag: "label",
                  controltype: "Label",
                  styles: "",
                  styleid: "",
                  styleType: "Label",
                  mediaURl: "font",
                  check: false,
                  switchcheck: false,
                  title: "Display",
                  value: "",
                  media: "Street2",
                  option: "false",
                  switchoption: "false",
                  position: 1,
                  checkposition: "AddressStreet2",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "Label",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [],
                },
              ],
            },
            {
              id: "",
              name: "City & State Container",
              controlName: "Container6",
              tableHeader: "",
              data: "",
              type: "Container",
              parentid: "",
              htmlTag: "div",
              controltype: "Container",
              styles: "",
              styleid: "",
              styleType: "Container",
              mediaURl: "square-poll-horizontal",
              check: false,
              switchcheck: false,
              title: "Datablocks",
              value: "",
              media: "CityState",
              option: "false",
              switchoption: "AddressContainer",
              position: 1,
              checkposition: "CityStateContainer",
              tab: "",
              inputType: "Date",
              inputText: "",
              imageText: "",
              sourceData: "sourceData",
              controlChange: "Container",
              tableProp: "ChildBlock",
              url: " ",
              control: [
                {
                  id: "",
                  name: "City Container",
                  controlName: "Container7",
                  tableHeader: "",
                  data: "",
                  type: "Container",
                  parentid: "",
                  htmlTag: "div",
                  controltype: "Container",
                  styles: "",
                  styleid: "",
                  styleType: "Container",
                  mediaURl: "square-poll-horizontal",
                  check: false,
                  switchcheck: false,
                  title: "Datablocks",
                  value: "",
                  media: "ContainerSec",
                  option: "false",
                  switchoption: "AddressContainer",
                  position: 1,
                  checkposition: "CityContainer",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "Container",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [
                    {
                      id: "",
                      name: "City TextBox",
                      controlName: "TextBox3",
                      tableHeader: "",
                      data: "City",
                      type: "TextBox",
                      parentid: "",
                      htmlTag: "input",
                      controltype: "TextBox",
                      styles: " ",
                      styleid: "",
                      styleType: "TextBox",
                      mediaURl: "text-height",
                      check: false,
                      switchcheck: false,
                      title: "Input",
                      value: "",
                      media: "City",
                      option: "false",
                      switchoption: "false",
                      position: 1,
                      checkposition: "CityTextbox",
                      tab: "",
                      inputType: "Date",
                      inputText: "",
                      imageText: "",
                      sourceData: "sourceData",
                      controlChange: "TextBox",
                      tableProp: "ChildBlock",
                      url: " ",
                      control: [],
                    },
                    {
                      id: "",
                      name: "City Label",
                      controlName: "Label3",
                      tableHeader: "",
                      data: "City",
                      type: "Label",
                      parentid: "",
                      htmlTag: "label",
                      controltype: "Label",
                      styles: " ",
                      styleid: "",
                      styleType: "Label",
                      mediaURl: "font",
                      check: false,
                      switchcheck: false,
                      title: "Display",
                      value: "",
                      media: "City",
                      option: "false",
                      switchoption: "false",
                      position: 1,
                      checkposition: "CityLabel",
                      tab: "",
                      inputType: "Date",
                      inputText: "",
                      imageText: "",
                      sourceData: "sourceData",
                      controlChange: "Label",
                      tableProp: "ChildBlock",
                      url: " ",
                      control: [],
                    },
                  ],
                },
                {
                  id: "",
                  name: "State Container",
                  controlName: "Container8",
                  tableHeader: "",
                  data: "",
                  type: "Container",
                  parentid: "",
                  htmlTag: "div",
                  controltype: "Container",
                  styles: "",
                  styleid: "",
                  styleType: "Container",
                  mediaURl: "square-poll-horizontal",
                  check: false,
                  switchcheck: false,
                  title: "Datablocks",
                  value: "",
                  media: "ContainerThird",
                  option: "false",
                  switchoption: "AddressContainer",
                  position: 1,
                  checkposition: "StateContainer",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "Container",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [
                    {
                      id: "",
                      name: "State TextBox",
                      controlName: "TextBox4",
                      tableHeader: "",
                      data: "State",
                      type: "TextBox",
                      parentid: "",
                      htmlTag: "input",
                      controltype: "TextBox",
                      styles: "",
                      styleid: "",
                      styleType: "TextBox",
                      mediaURl: "text-height",
                      check: false,
                      switchcheck: false,
                      title: "Input",
                      value: "",
                      media: "State",
                      option: "false",
                      switchoption: "false",
                      position: 1,
                      checkposition: "StateTextbox",
                      tab: "",
                      inputType: "Date",
                      inputText: "",
                      imageText: "",
                      sourceData: "sourceData",
                      controlChange: "TextBox",
                      tableProp: "ChildBlock",
                      url: " ",
                      control: [],
                    },
                    {
                      id: "",
                      name: "State Label",
                      controlName: "Label4",
                      tableHeader: "",
                      data: "State",
                      type: "Label",
                      parentid: "",
                      htmlTag: "label",
                      controltype: "Label",
                      styles: "",
                      styleid: "",
                      styleType: "Label",
                      mediaURl: "font",
                      check: false,
                      switchcheck: false,
                      title: "Display",
                      value: "",
                      media: "State",
                      option: "false",
                      switchoption: "false",
                      position: 1,
                      checkposition: "StateLabel",
                      tab: "",
                      inputType: "Date",
                      inputText: "",
                      imageText: "",
                      sourceData: "sourceData",
                      controlChange: "Label",
                      tableProp: "ChildBlock",
                      url: " ",
                      control: [],
                    },
                  ],
                },
              ],
            },
            {
              id: "",
              name: "Postal & Country Container",
              controlName: "Container9",
              tableHeader: "",
              data: "",
              type: "Container",
              parentid: "",
              htmlTag: "div",
              controltype: "Container",
              styles: "",
              styleid: "",
              styleType: "Container",
              mediaURl: "square-poll-horizontal",
              check: false,
              switchcheck: false,
              title: "Datablocks",
              value: "",
              media: "PostalCountry",
              option: "false",
              switchoption: "AddressContainer",
              position: 1,
              checkposition: "PostalCountryContainer",
              tab: "",
              inputType: "Date",
              inputText: "",
              imageText: "",
              sourceData: "sourceData",
              controlChange: "Container",
              tableProp: "ChildBlock",
              url: " ",
              control: [
                {
                  id: "",
                  name: "Postal Container",
                  controlName: "Container10",
                  tableHeader: "",
                  data: "",
                  type: "Container",
                  parentid: "",
                  htmlTag: "div",
                  controltype: "Container",
                  styles: "",
                  styleid: "",
                  styleType: "Container",
                  mediaURl: "square-poll-horizontal",
                  check: false,
                  switchcheck: false,
                  title: "Datablocks",
                  value: "",
                  media: "ContainerPostal",
                  option: "false",
                  switchoption: "AddressContainer",
                  position: 1,
                  checkposition: "PostalContainer",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "Container",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [
                    {
                      id: "",
                      name: "Postal TextBox",
                      controlName: "TextBox5",
                      tableHeader: "",
                      data: "Postal Code",
                      type: "TextBox",
                      parentid: "",
                      htmlTag: "input",
                      controltype: "TextBox",
                      styles: "",
                      styleid: "",
                      styleType: "TextBox",
                      mediaURl: "text-height",
                      check: false,
                      switchcheck: false,
                      title: "Input",
                      value: "",
                      media: "Postal",
                      option: "false",
                      switchoption: "false",
                      position: 1,
                      checkposition: "PostalTextbox",
                      tab: "",
                      inputType: "Date",
                      inputText: "",
                      imageText: "",
                      sourceData: "sourceData",
                      controlChange: "TextBox",
                      tableProp: "ChildBlock",
                      url: " ",
                      control: [],
                    },
                    {
                      id: "",
                      name: "Postal Label",
                      controlName: "Label5",
                      tableHeader: "",
                      data: "Postal Code",
                      type: "Label",
                      parentid: "0.1",
                      htmlTag: "label",
                      controltype: "Label",
                      styles: "",
                      styleid: "",
                      styleType: "Label",
                      mediaURl: "font",
                      check: false,
                      switchcheck: false,
                      title: "Display",
                      value: "",
                      media: "Postal",
                      option: "false",
                      switchoption: "false",
                      position: 1,
                      checkposition: "PostalLabel",
                      tab: "",
                      inputType: "Date",
                      inputText: "",
                      imageText: "",
                      sourceData: "sourceData",
                      controlChange: "Label",
                      tableProp: "ChildBlock",
                      url: " ",
                      control: [],
                    },
                  ],
                },
                {
                  id: "",
                  name: "Country Container",
                  controlName: "Container10",
                  tableHeader: "",
                  data: "",
                  type: "Container",
                  parentid: "",
                  htmlTag: "div",
                  controltype: "Container",
                  styles: "",
                  styleid: "",
                  styleType: "Container",
                  mediaURl: "square-poll-horizontal",
                  check: false,
                  switchcheck: false,
                  title: "Datablocks",
                  value: "",
                  media: "ContainerCountry",
                  option: "false",
                  switchoption: "AddressContainer",
                  position: 1,
                  checkposition: "CountryContainer",
                  tab: "",
                  inputType: "Date",
                  inputText: "",
                  imageText: "",
                  sourceData: "sourceData",
                  controlChange: "Container",
                  tableProp: "ChildBlock",
                  url: " ",
                  control: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
];

const CountryJSON = [
  {
    id: "0.1.2",
    name: "Country TextBox",
    controlName: "TextBox1",
    tableHeader: "",
    data: "Country",
    type: "TextBox",
    parentid: "0.1",
    htmlTag: "input",
    controltype: "TextBox",
    styles: "",
    styleid: "agrfb36",
    styleType: "TextBox",
    mediaURl: "text-height",
    check: false,
    switchcheck: false,
    title: "Input",
    value: "",
    media: "Country",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "CountryTextbox",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "TextBox",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
  {
    id: "0.1.1",
    name: "Country Label1",
    controlName: "Label1",
    tableHeader: "",
    data: "Country",
    type: "Label",
    parentid: "0.1",
    htmlTag: "label",
    controltype: "Label",
    styles: "",
    styleid: "agrfb35",
    styleType: "Label",
    mediaURl: "font",
    check: false,
    switchcheck: false,
    title: "Display",
    value: "",
    media: "Country",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "CountryLabel",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Label",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
];

const ShortTextJSON = [
  {
    id: "",
    name: "ShortText",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: null,
    htmlTag: "div",
    controltype: "ShortText",
    styles: "",
    styleid: "agrfb4",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    title: "Datablocks",
    check: false,
    switchcheck: false,
    value: "",
    media: "ShortText",
    option: "false",
    switchoption: "",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "TextBox",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "",
        name: "ShortText Label",
        controlName: "Label1",
        tableHeader: "",
        data: "ShortText",
        type: "Label",
        parentid: null,
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb6",
        styleType: "Label",
        mediaURl: "font",
        title: "Display",
        check: false,
        switchcheck: false,
        value: "",
        media: "ShortTextLabel",
        option: "false",
        switchoption: "true",
        position: 1,
        checkposition: "ShortTextLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "TextBox",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "",
        name: "ShortText TextBox",
        controlName: "TextBox1",
        tableHeader: "",
        data: "TextBox",
        type: "TextBox",
        parentid: null,
        htmlTag: "input",
        controltype: "TextBox",
        styles: "",
        styleid: "agrfb7",
        styleType: "TextBox",
        mediaURl: "text-height",
        title: "Input",
        check: false,
        switchcheck: false,
        value: "",
        media: "ShortTextTextBox",
        option: "false",
        switchoption: "true",
        position: 1,
        checkposition: "ShortTextTextBox",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "TextBox",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];

const LongTextJSON = [
  {
    id: "",
    name: "LongText",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: null,
    htmlTag: "div",
    controltype: "LongText",
    styles: "",
    styleid: "agrfb4",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    title: "Datablocks",
    check: false,
    switchcheck: false,
    value: "",
    media: "LongText",
    option: "false",
    switchoption: "",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "TextBox",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.1",
        name: "LongText Label",
        controlName: "Label1",
        tableHeader: "",
        data: "LongText",
        type: "Label",
        parentid: null,
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb6",
        styleType: "Label",
        mediaURl: "font",
        title: "Display",
        check: false,
        switchcheck: false,
        value: "",
        media: "LongTextLabel",
        option: "false",
        switchoption: "true",
        position: 1,
        checkposition: "LongTextLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "TextBox",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "",
        name: "LongText TextArea",
        controlName: "TextArea1",
        tableHeader: "",
        data: "TextArea",
        type: "TextArea",
        parentid: null,
        htmlTag: "input",
        controltype: "TextArea",
        styles: "",
        styleid: "agrfb5",
        styleType: "TextArea",
        mediaURl: "text-width",
        title: "Input",
        check: false,
        switchcheck: false,
        value: "",
        media: "LongTextTextArea",
        option: "false",
        switchoption: "true",
        position: 1,
        checkposition: "LongTextTextbox",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "TextBox",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];

const MultipleChoiceJSON = [
  {
    id: "0.1",
    name: "MultipleChoice",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "MultipleChoice",
    styles: "",
    styleid: "agrfb2",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "MultipleChoiceMaincontainer",
    option: "false",
    switchoption: "true",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "",
        name: "MultipleChoice Label",
        controlName: "Label1",
        tableHeader: "",
        data: "MultipleChoice",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb3",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "MultipleChoiceLabel",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "MultipleChoiceLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "0.1.2",
        name: "CheckboxContainer",
        controlName: "Checkbox1",
        tableHeader: "",
        data: "",
        type: "Container",
        parentid: "0.1",
        htmlTag: "div",
        controltype: "Container",
        styles: "",
        styleid: "agr1",
        styleType: "RadioContainer",
        mediaURl: "square-check",
        title: "Containers",
        tab: "",
        inputType: "Date",
        media: "CheckBoxMainContainer",
        inputText: "",
        imageText: "",
        checkposition: "CheckBoxMainContainer",
        sourceData: "sourceData",
        controlChange: "Checkbox",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];

const GenderCheckBoxJSON = [
  {
    id: "",
    name: "Checkbox",
    controlName: "Checkbox2",
    tableHeader: "",
    data: "",
    type: "Checkbox",
    parentid: "0.1.2",
    htmlTag: "input",
    controltype: "Checkbox",
    styles: "",
    styleid: "agrfb4",
    styleType: "Checkbox",
    mediaURl: "square-check",
    check: false,
    switchcheck: false,
    title: "Input",
    value: "",
    media: "CheckBox",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Checkbox",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
];

const SingleChoiceJSON = [
  {
    id: "",
    name: "SingleChoice",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: null,
    htmlTag: "div",
    controltype: "SingleChoice",
    styles: "",
    media: "SingleChoiceMainContainer",
    styleid: "agrfb23",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    title: "Datablocks",
    tableProp: "MainBlock",
    control: [
      {
        id: "",
        name: "SingleChoice Label",
        controlName: "Label1",
        tableHeader: "",
        data: "SingleChoice",
        type: "Label",
        parentid: null,
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb24",
        styleType: "Label",
        mediaURl: "font",
        media: "SingleChoiceLabel",
        checkposition: "SingleChoiceLabel",
        title: "Display",
        tableProp: "ChildBlock",
        control: [],
      },
      {
        id: "",
        name: "RadioContainer",
        controlName: "Radio1",
        tableHeader: "",
        data: "",
        type: "Container",
        parentid: "0.1",
        htmlTag: "div",
        controltype: "Container",
        styles: "",
        styleid: "agrf1",
        styleType: "RadioContainer",
        mediaURl: "circle-dot",
        title: "Datablocks",
        tab: "",
        inputType: "Date",
        media: "RadioMainContainer",
        checkposition: "RadioMainContainer",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Radio",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];

const DropDownJSON = [
  {
    id: "0.1",
    name: "DropDown",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "DropDown",
    styles: "",
    styleid: "agrfb275",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "DropDown",
    option: "false",
    switchoption: "",
    position: 1,
    checkposition: "DropDownContainer",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.1.1",
        name: "DropDown Label",
        controlName: "Label1",
        tableHeader: "",
        data: "DropDown",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb276",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "DropDownLabel",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "DropDownLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "0.1.2.1",
        name: "Dropdown",
        controlName: "Dropdown1",
        tableHeader: "",
        data: "data1,data2,data3,data4",
        type: "Dropdown",
        parentid: "0.1.2",
        htmlTag: "select",
        controltype: "Dropdown",
        styles: "",
        styleid: "agrfb278",
        styleType: "Dropdown",
        mediaURl: "square-caret-down",
        check: false,
        switchcheck: false,
        title: "Input",
        value: "",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "DropDown",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Dropdown",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];
const FullNameJSON = [
  {
    id: "0.0",
    name: "FullName",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "FullName",
    styles: "",
    styleid: "agrfb303",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "FullName",
    option: "false",
    switchoption: "AddressContainer",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.0.0",
        name: "FullName Label",
        controlName: "Header1",
        tableHeader: "",
        data: "Full Name",
        type: "Label",
        parentid: "0.0",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb304",
        styleType: "Label",
        mediaURl: "heading",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "FullNameHeader",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "FullName",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Header",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "0.0.1",
        name: "FullNameSubContainer",
        controlName: "Container2",
        tableHeader: "",
        data: "",
        type: "Container",
        parentid: "0.0",
        htmlTag: "div",
        controltype: "Container",
        styles: "",
        styleid: "agrfb305",
        styleType: "Container",
        mediaURl: "square-poll-horizontal",
        check: false,
        switchcheck: false,
        title: "Containers",
        value: "",
        media: "FirstLastName",
        option: "false",
        switchoption: "AddressContainer",
        position: 1,
        checkposition: "FullNameSubCont",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Container",
        tableProp: "ChildBlock",
        url: " ",
        control: [
          {
            id: "0.0.1.0",
            name: "PrefixContainer",
            controlName: "Container4",
            tableHeader: "",
            data: "",
            type: "Container",
            parentid: "0.0.1",
            htmlTag: "div",
            controltype: "Container",
            styles: "",
            styleid: "agrfb316",
            styleType: "Container",
            mediaURl: "square-poll-horizontal",
            check: false,
            switchcheck: false,
            title: "Containers",
            value: "",
            media: "ContainerPrefix",
            option: "false",
            switchoption: "AddressContainer",
            position: 1,
            checkposition: "PrefixContainer",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Container",
            tableProp: "ChildBlock",
            url: " ",
            control: [],
          },
          {
            id: "0.0.1.1",
            name: "FirstNameContainer",
            controlName: "Container3",
            tableHeader: "",
            data: "",
            type: "Container",
            parentid: "0.0.1",
            htmlTag: "div",
            controltype: "Container",
            styles: "",
            styleid: "agrfb306",
            styleType: "Container",
            mediaURl: "square-poll-horizontal",
            check: false,
            switchcheck: false,
            title: "Containers",
            value: "",
            media: "FirstNameContainer",
            option: "false",
            switchoption: "AddressContainer",
            position: 1,
            checkposition: "FirstNameContainer",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Container",
            tableProp: "ChildBlock",
            url: " ",
            control: [
              {
                id: "0.0.1.1.0",
                name: "FirstNameTextBox",
                controlName: "TextBox1",
                tableHeader: "",
                data: "First Name",
                type: "TextBox",
                parentid: "0.0.1.1",
                htmlTag: "input",
                controltype: "TextBox",
                styles: "",
                styleid: "agrfb307",
                styleType: "TextBox",
                mediaURl: "text-height",
                check: false,
                switchcheck: false,
                title: "Input",
                value: "",
                media: "",
                option: "false",
                switchoption: "false",
                position: 1,
                checkposition: "FirstNameTextBox",
                tab: "",
                inputType: "Date",
                inputText: "",
                imageText: "",
                sourceData: "sourceData",
                controlChange: "TextBox",
                tableProp: "ChildBlock",
                url: " ",
                control: [],
              },
              {
                id: "0.0.1.1.1",
                name: "FirstNameLabel",
                controlName: "Label1",
                tableHeader: "",
                data: "First Name",
                type: "Label",
                parentid: "0.0.1.1",
                htmlTag: "label",
                controltype: "Label",
                styles: "",
                styleid: "agrfb308",
                styleType: "Label",
                mediaURl: "font",
                check: false,
                switchcheck: false,
                title: "Display",
                value: "",
                media: "FirstNameLabel",
                option: "false",
                switchoption: "false",
                position: 1,
                checkposition: "FirstName",
                tab: "",
                inputType: "Date",
                inputText: "",
                imageText: "",
                sourceData: "sourceData",
                controlChange: "Label",
                tableProp: "ChildBlock",
                url: " ",
                control: [],
              },
            ],
          },
          {
            id: "0.0.1.2",
            name: "MiddleNameContainer",
            controlName: "Container4",
            tableHeader: "",
            data: "",
            type: "Container",
            parentid: "0.0.1",
            htmlTag: "div",
            controltype: "Container",
            styles: "",
            styleid: "agrfb309",
            styleType: "Container",
            mediaURl: "square-poll-horizontal",
            check: false,
            switchcheck: false,
            title: "Containers",
            value: "",
            media: "ContainerMiddleName",
            option: "false",
            switchoption: "AddressContainer",
            position: 1,
            checkposition: "MiddleNameContainer",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Container",
            tableProp: "ChildBlock",
            url: " ",
            control: [],
          },
          {
            id: "0.0.1.3",
            name: "LastNameContainer",
            controlName: "Container4",
            tableHeader: "",
            data: "",
            type: "Container",
            parentid: "0.0.1",
            htmlTag: "div",
            controltype: "Container",
            styles: "",
            styleid: "agrfb310",
            styleType: "Container",
            mediaURl: "square-poll-horizontal",
            check: false,
            switchcheck: false,
            title: "Containers",
            value: "",
            media: "LastNameContainer",
            option: "false",
            switchoption: "AddressContainer",
            position: 1,
            checkposition: "LastNameContainer",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Container",
            tableProp: "ChildBlock",
            url: " ",
            control: [
              {
                id: "0.0.1.3.0",
                name: "Last Name TextBox",
                controlName: "TextBox2",
                tableHeader: "",
                data: "Last Name",
                type: "TextBox",
                parentid: "0.0.1.3",
                htmlTag: "input",
                controltype: "TextBox",
                styles: "",
                styleid: "agrfb311",
                styleType: "TextBox",
                mediaURl: "text-height",
                check: false,
                switchcheck: false,
                title: "Input",
                value: "",
                media: "",
                option: "false",
                switchoption: "false",
                position: 1,
                checkposition: "LastNameTextBox",
                tab: "",
                inputType: "Date",
                inputText: "",
                imageText: "",
                sourceData: "sourceData",
                controlChange: "TextBox",
                tableProp: "ChildBlock",
                url: " ",
                control: [],
              },
              {
                id: "0.0.1.3.1",
                name: "Last Name Label",
                controlName: "Label2",
                tableHeader: "",
                data: "Last Name",
                type: "Label",
                parentid: "0.0.1.3",
                htmlTag: "label",
                controltype: "Label",
                styles: "",
                styleid: "agrfb312",
                styleType: "Label",
                mediaURl: "font",
                check: false,
                switchcheck: false,
                title: "Display",
                value: "",
                media: "LastName",
                option: "false",
                switchoption: "false",
                position: 1,
                checkposition: "LastName",
                tab: "",
                inputType: "Date",
                inputText: "",
                imageText: "",
                sourceData: "sourceData",
                controlChange: "Label",
                tableProp: "ChildBlock",
                url: " ",
                control: [],
              },
            ],
          },
        ],
      },
    ],
  },
];

const MiddleNameJSON = [
  {
    id: "0.0.1.1.0",
    name: "MiddleName TextBox",
    controlName: "TextBox2",
    tableHeader: "",
    data: "Middle Name",
    type: "TextBox",
    parentid: "0.0.1.1",
    htmlTag: "input",
    controltype: "TextBox",
    styles: "",
    styleid: "agrfb3102",
    styleType: "TextBox",
    mediaURl: "text-height",
    check: false,
    switchcheck: false,
    title: "Input",
    value: "",
    media: "MiddleName",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "MiddleNameTextBox",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "TextBox",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
  {
    id: "0.0.1.1.1",
    name: "MiddleName Label",
    controlName: "Label2",
    tableHeader: "",
    data: "Middle Name",
    type: "Label",
    parentid: "0.0.1.1",
    htmlTag: "label",
    controltype: "Label",
    styles: "",
    styleid: "agrfb3103",
    styleType: "Label",
    mediaURl: "font",
    check: false,
    switchcheck: false,
    title: "Display",
    value: "",
    media: "MiddleName",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "MiddleNameLabel",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Label",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
];

const PrefixJSON = [
  {
    id: "0.0.1.0.1",
    name: "Prefix",
    controlName: "TextBox2",
    tableHeader: "",
    data: "Prefix",
    type: "TextBox",
    parentid: "0.0.1.1",
    htmlTag: "input",
    controltype: "TextBox",
    styles: "",
    styleid: "agrfb317",
    styleType: "TextBox",
    mediaURl: "text-height",
    check: false,
    switchcheck: false,
    title: "Input",
    value: "",
    media: "Prefix",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "PrefixTextBox",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "TextBox",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
  {
    id: "0.0.1.0.2",
    name: "Prefix Label",
    controlName: "Label2",
    tableHeader: "",
    data: "Prefix",
    type: "Label",
    parentid: "0.0.1.1",
    htmlTag: "label",
    controltype: "Label",
    styles: "",
    styleid: "agrfb318",
    styleType: "Label",
    mediaURl: "font",
    check: false,
    switchcheck: false,
    title: "Display",
    value: "",
    media: "Prefix",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "PrefixLabel",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Label",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
];

const HeadingJSON = [
  {
    id: "0.0",
    name: "Heading",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Heading",
    styles: "",
    styleid: "agrfb3364",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "HeadingContainer",
    option: "false",
    switchoption: "true",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.0.0",
        name: "HeadingSubContainer",
        controlName: "Container2",
        tableHeader: "",
        data: "",
        type: "Container",
        parentid: "0.0",
        htmlTag: "div",
        controltype: "Container",
        styles: "",
        styleid: "agrfb3366",
        styleType: "Container",
        mediaURl: "square-poll-horizontal",
        check: false,
        switchcheck: false,
        title: "Containers",
        value: "",
        media: "ImageContainer",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "HeadingSubContainer",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Container",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "0.0.2",
        name: "Container3",
        controlName: "Container3",
        tableHeader: "",
        data: "",
        type: "Container",
        parentid: "0.0",
        htmlTag: "div",
        controltype: "Container",
        styles:"",
        styleid: "agrfb22094415426436",
        styleType: "Container",
        mediaURl: "square-poll-horizontal",
        check: false,
        switchcheck: false,
        title: "Containers",
        value: "",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "LabelHeading",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Container",
        tableProp: "ChildBlock",
        url: "",
        control: [
          {
            id: "",
            name: "Header1",
            controlName: "Header1",
            tableHeader: "",
            data: "Header",
            type: "Header",
            parentid: "0.1",
            htmlTag: "h1",
            controltype: "Header",
            styles: "",
            styleid: "agrfb22094401",
            styleType: "Header",
            mediaURl: "heading",
            check: false,
            switchcheck: false,
            title: "Display",
            value: "",
            media: "",
            option: "false",
            switchoption: "false",
            position: 1,
            checkposition: "Heading",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Header",
            tableProp: "ChildBlock",
            url: "",
            control: [],
          },
          {
            id: "",
            name: "SubHeader1",
            controlName: "SubHeader1",
            tableHeader: "",
            data: "SubHeader",
            type: "SubHeader",
            parentid: "0.1",
            htmlTag: "h4",
            controltype: "SubHeader",
            styles: "",
            styleid: "agrfb22094402",
            styleType: "SubHeader",
            mediaURl: "h",
            check: false,
            switchcheck: false,
            title: "Display",
            value: "",
            media: "",
            option: "false",
            switchoption: "false",
            position: 1,
            checkposition: "SubHeader",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "SubHeader",
            tableProp: "ChildBlock",
            url: "",
            control: [],
          },
        ],
      },
    ],
  },
];



const ImageJSON = [
  {
    id: "0.0.0.0",
    name: "HeaderImage",
    controlName: "Image1",
    tableHeader: "",
    data: "Image",
    type: "Image",
    parentid: "0.0.0",
    htmlTag: "img",
    controltype: "Image",
    styles: "",
    styleid: "agrfb3367",
    styleType: "Image",
    mediaURl: "image",
    check: false,
    switchcheck: false,
    title: "Display",
    value: "",
    media: "",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "ImageHeading",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Image",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
];

const PhoneJSON = [
  {
    id: "0.1",
    name: "PhoneNumber",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "PhoneNumber",
    styles: "",
    styleid: "agrfb308",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "PhoneContainer",
    option: "false",
    switchoption: "true",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.1.1",
        name: "NumberLabel",
        controlName: "Label1",
        tableHeader: "",
        data: "Phone Number",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb309",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "PhoneLabel",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "PhoneNumberLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "0.1.2",
        name: "NumberTextBox",
        controlName: "TextBox1",
        tableHeader: "",
        data: "0000000000",
        type: "TextBox",
        parentid: "0.1",
        htmlTag: "input",
        controltype: "TextBox",
        styles: "",
        styleid: "agrfb310",
        styleType: "TextBox",
        mediaURl: "text-height",
        check: false,
        switchcheck: false,
        title: "Input",
        value: "",
        media: "PhoneTextBox",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "PhoneTextBox",
        tab: "Phone Number",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "TextBox",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];

const EmailJSON = [
  {
    id: "0.1",
    name: "Email",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Email",
    styles: "",
    styleid: "agrfb310",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "EmailContainer",
    option: "false",
    switchoption: "true",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.1.1",
        name: "EmailLabel",
        controlName: "Label1",
        tableHeader: "",
        data: "Email",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb311",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "EamilLabel",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "EmailLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "0.1.2",
        name: "EmailTextBox",
        controlName: "TextBox1",
        tableHeader: "",
        data: "email@gmail.com",
        type: "TextBox",
        parentid: "0.1",
        htmlTag: "input",
        controltype: "TextBox",
        styles: "",
        styleid: "agrfb312",
        styleType: "TextBox",
        mediaURl: "text-height",
        check: false,
        switchcheck: false,
        title: "Input",
        value: "",
        media: "EmailTextBox",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "EmailTextBox",
        tab: "Email",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "TextBox",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];

const DateJSON = [
  {
    id: "0.1",
    name: "DateBlock",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "DateBlock",
    styles: "",
    styleid: "agrfb184",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "DateContainer",
    option: "false",
    switchoption: "AddressContainer",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.1.1",
        name: "DateLabel",
        controlName: "Label1",
        tableHeader: "",
        data: "Date",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb185",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "DateLabel",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "DateLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
      {
        id: "0.1.2",
        name: "Date",
        controlName: "Date1",
        tableHeader: "",
        data: "",
        type: "Date",
        parentid: "0.1",
        htmlTag: "input",
        controltype: "Date",
        styles: "",
        styleid: "agrfb186",
        styleType: "Date",
        mediaURl: "calendar-days",
        check: false,
        switchcheck: false,
        title: "Input",
        value: "",
        media: "DateField",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "DateField",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Date",
        tableProp: "ChildBlock",
        url: " ",
        control: [],
      },
    ],
  },
];

const GenderJSON = [
  {
    id: "0.1.1",
    name: "Radio",
    controlName: "Radio2",
    tableHeader: "",
    data: "Male",
    type: "Radio",
    parentid: "0.1",
    htmlTag: "input",
    controltype: "Radio",
    styles: "",
    styleid: "agrfb21",
    styleType: "Radio",
    mediaURl: "circle-dot",
    check: false,
    switchcheck: false,
    title: "Input",
    value: 1,
    media: "Radio",
    option: "false",
    switchoption: "true",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Radio",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
];

const StarJSON = [
  {
    id: "0.0.1.0",
    name: "Star1",
    controlName: "Star1",
    tableHeader: "",
    data: "star",
    type: "Star",
    parentid: "0.0.1",
    htmlTag: "div",
    controltype: "Star",
    styles: "",
    styleid: "agrfb219",
    styleType: "Icon",
    mediaURl: "star",
    check: false,
    switchcheck: false,
    title: "Icon",
    value: "",
    media: "",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Star",
    tableProp: "ChildBlock",
    url: " ",
    control: [],
  },
];

const RatingJSON = [
  {
    id: "0.0",
    name: "Ratings",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "RatingBlock",
    styles: "",
    styleid: "agrfb216",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Datablocks",
    value: "",
    media: "Rating",
    option: "false",
    switchoption: "true",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "MainBlock",
    url: " ",
    control: [
      {
        id: "0.0.0",
        name: "RatingSubContainer",
        controlName: "Container3",
        tableHeader: "",
        data: "",
        type: "Container",
        parentid: "0.0",
        htmlTag: "div",
        controltype: "Container",
        styles: "",

        styleid: "agrfb224",
        styleType: "Container",
        mediaURl: "square-poll-horizontal",
        check: false,
        switchcheck: false,
        title: "Containers",
        value: "",
        media: "RatingConatiner",
        option: "false",
        switchoption: "true",
        position: 1,
        checkposition: "right",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Container",
        tableProp: "ChildBlock",
        url: " ",
        control: [
          {
            id: "0.0.0.0",
            name: "RatingLabel",
            controlName: "Label1",
            tableHeader: "",
            data: "Star Rating",
            type: "Label",
            parentid: "0.0.0",
            htmlTag: "label",
            controltype: "Label",
            styles: "",
            styleid: "agrfb217",
            styleType: "Label",
            mediaURl: "font",
            check: false,
            switchcheck: false,
            title: "Display",
            value: "",
            media: "RatingLabel",
            option: "false",
            switchoption: "false",
            position: 1,
            checkposition: "RatingLabel",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Label",
            tableProp: "ChildBlock",
            url: " ",
            control: [],
          },
        ],
      },
      {
        id: "0.0.1",
        name: "RatingStarContainer",
        controlName: "Container2",
        tableHeader: "MainContainer",
        data: "",
        type: "Container",
        parentid: "0.0",
        htmlTag: "div",
        controltype: "Container",
        styles: "",
        styleid: "agrfb218",
        styleType: "Container",
        mediaURl: "square-poll-horizontal",
        check: false,
        switchcheck: false,
        title: "Containers",
        value: "",
        media: "RatingInput",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "RatingStarInput",

        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Container",
        tableProp: "ChildBlock",
        url: " ",
        control: [
          {
            id: "0.0.1.0",
            name: "Star1",
            controlName: "Star1",
            tableHeader: "",
            data: "star",
            type: "Star",
            parentid: "0.0.1",
            htmlTag: "div",
            controltype: "Star",
            styleid: "agrfb219",
            styleType: "Icon",
            mediaURl: "star",
            check: false,
            switchcheck: false,
            title: "Icon",
            value: "",
            media: "",
            option: "false",
            switchoption: "false",
            position: 1,
            checkposition: "right",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Star",
            tableProp: "ChildBlock",
            url: " ",
            control: [],
          },
          {
            id: "0.0.1.1",
            name: "Star5",
            controlName: "Star5",
            tableHeader: "",
            data: "star",
            type: "Star",
            parentid: "0.0.1",
            htmlTag: "div",
            controltype: "Star",
            styles:
              '{\n  "display": "flex",\n  "alignSelf": "initial",\n  "justifySelf": "center",\n  "color": "#000000",\n  "fontSize": "13px",\n  "marginTop": "2px",\n  "marginRight": "2px",\n  "marginBottom": "2px",\n  "marginLeft": "2px",\n  "width": "40px",\n  "height": "40px",\n  "minHeight": "auto",\n  "minWidth": "auto",\n  "maxHeight": "none",\n  "maxWidth": "none"\n}',
            styleid: "agrfb223",
            styleType: "Icon",
            mediaURl: "star",
            check: false,
            switchcheck: false,
            title: "Icon",
            value: "",
            media: "",
            option: "false",
            switchoption: "false",
            position: 1,
            checkposition: "right",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Star",
            tableProp: "ChildBlock",
            url: " ",
            control: [],
          },
        ],
      },
    ],
  },
];

export {
  ShortTextJSON,
  LongTextJSON,
  MultipleChoiceJSON,
  SingleChoiceJSON,
  DropDownJSON,
  FullNameJSON,
  CountryJSON,
  AddressJSON,
  MiddleNameJSON,
  PrefixJSON,
  HeadingJSON,
  EmailJSON,
  PhoneJSON,
  ImageJSON,
  DateJSON,
  GenderCheckBoxJSON,
  GenderJSON,
  RatingJSON,
  StarJSON,
};
