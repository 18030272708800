import React, { useState, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  addEdge,
  Handle,
  useReactFlow,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
import "reactflow/dist/style.css";

// Custom Node Component with Nested React Flow
export const SubflowNode = ({ data }) => {
  const [nodes, setNodes] = useState(data.subflowNodes || []);
  const [edges, setEdges] = useState(data.subflowEdges || []);
  const [nodeSize, setNodeSize] = useState({ width: 300, height: 200 });

  // Restrict node movement within the box boundaries
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        return applyNodeChanges(
          changes.map((change) => {
            if (change.type === "position") {
              // Restrict node position within the bounds of the box
              const { width, height } = nodeSize;
              const { position } = change;
              const nodeWidth = 100; // Assumed width of inner nodes, can adjust
              const nodeHeight = 50; // Assumed height of inner nodes, can adjust

              // Ensure nodes don't go outside the box
              position.x = Math.max(0, Math.min(position.x, width - nodeWidth));
              position.y = Math.max(
                0,
                Math.min(position.y, height - nodeHeight)
              );
            }
            return change;
          }),
          nds
        );
      });
    },
    [setNodes, nodeSize]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const resizeHandler = (e) => {
    const newWidth = Math.max(
      200,
      e.clientX - e.target.getBoundingClientRect().left
    );
    const newHeight = Math.max(
      100,
      e.clientY - e.target.getBoundingClientRect().top
    );
    setNodeSize({ width: newWidth, height: newHeight });
  };

  return (
    <div
      style={{
        position: "relative",
        width: nodeSize.width,
        height: nodeSize.height,
        border: "2px solid #000",
        overflow: "hidden", // Hide nodes that go outside the box
        background: "#f0f0f0",
      }}
    >
      <ReactFlowProvider>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        panOnDrag={false} // Disable panning
        zoomOnScroll={false} // Disable zooming
        zoomOnPinch={false} // Disable pinch zooming
        nodesDraggable={true} // Allow moving nodes inside
        nodesConnectable={true}
        fitView
      >
        {/* <MiniMap />
          <Controls />
          <Background /> */}
      </ReactFlow>
      </ReactFlowProvider>
      {/* Resize handle */}
      <div
        onMouseDown={(e) => resizeHandler(e)}
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "10px",
          height: "10px",
          backgroundColor: "gray",
          cursor: "se-resize",
        }}
      />
    </div>
  );
};
