import { debounce } from 'lodash';
import React, { useState } from 'react'
import NavBar from '../navbarComponents/NavBar';
import { Theme } from '../IncludeFile/Theme';
import { useMenu } from '../../reusableComponents/menuUtils';
import ThemeCards from './ThemeCards';
import { useAppState } from '../../PopUpForm/AppStateProvider ';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { loadLoginData } from '../../apiCalls/DiagramsAPI';

const ThemeTemplates = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentComponent, setCurrentComponent] = useState("1");

    // const location = useLocation();
    // const authData = location.state?.authData;
  
      const authData = loadLoginData();

    
  const { isMenuOpenleft, toggleMenu } = useMenu();

    const debouncedHandleSearch = debounce((query) => {
        setSearchQuery(query);
      }, 100);

    const handleSearch = (e) => {
        const newQuery = e.target.value;
        debouncedHandleSearch(newQuery);
      };

      const navigate = useNavigate()

      const handleBack = ()=>{
        navigate("/Landing");
      }

      const rightButtons = [
      
        {
          icon: Theme.GoBack,
          text: "Back",
          color: "background",
          handleClick: () => {
            handleBack();
          },
        },
      ];

   

  



      const renderComponent = (currentComponent) => {
        switch (currentComponent) {
          case "1":
            return (
              <ThemeCards
              searchQuery={searchQuery}
              authData={authData}
              />
            );
         
          default:
            return null;
        }
      };
    

  return (
    <div className="popup-form">
        <>
        <NavBar
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          isSearchBar={true}
          rightButtons={rightButtons}
          title="Choose Theme Template"
          // isMenuOpenleft={isMenuOpenleft}
          toggleMenu={toggleMenu}
          isMenuOpenleft={true}
        />
         <div id="pop-render-form">
         {renderComponent(currentComponent)}
         </div>
        </>
          
    </div>
  )
}

export default ThemeTemplates