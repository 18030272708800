import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../assets/icofontcharts/icofont.min.css";
import { Theme } from "../Components/IncludeFile/Theme";
import { ReadArray } from "../Components/Api/ApiCalls";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import { makeStyles } from "@mui/styles";
import Discover from "./Discover";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const ViewCardInfo = ({authData}) => {
  let APIProvider = window.APIProvider;
  const location = useLocation();
  const ArrayName = location.state ? location.state.ArrayName : null;
  const Category = location.state ? location.state.Category : null;
  const [editedcategory, seteditedcategory] = useState(Category);
  const [editedArrayName, setEditedArrayName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [arrayNameError, setArrayNameError] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [isOpenSelectPage, setIsOpenSelectpage] = useState(true);
  const [arrayNames, setArrayNames] = useState([]);
  const [selectedArrayName, setSelectedArrayName] = useState("");
  const [selectedArrayId, setSelectedArrayId] = useState(null);
  const [showDiscover, setShowDiscover] = useState(false);
  const [isOpenDiscover, setIsOpenDiscover] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [formNameExists, setFormNameExists] = useState(false);
  // const loginData = JSON.parse(sessionStorage.getItem("loginData"));
  const navigate = useNavigate();
  let accessKey = authData.accessKey || "";
  
  const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`

  const handleCreate = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    setLoading(true);
    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      }

      const requestBody = {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.ReadArrayAllGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: "",
      };

      const fetchDataResponseArray = await fetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (fetchDataResponseArray.ok) {
        const data = await fetchDataResponseArray.json();
        const apiResponse = JSON.parse(data.apiResponse);
        const arrayNames = apiResponse.map((item) => item.ArrayName);

        if (
          arrayNames.some(
            (name) => name.toLowerCase() === editedArrayName.toLowerCase()
          )
        ) {
          toast.error(
            "Array name already exists. Please choose a different name."
          );
          return;
        }
      } else {
        console.error(
          "Error fetching data:",
          fetchDataResponseArray.statusText
        );
        return;
      }

   

      const requestBodyarrayname = {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.ReadArrayByNameGUID,
        param1: ArrayName,
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: "",
      };

      const fetchDataResponse = await fetch(
        baseURL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBodyarrayname),
        }
      );

      if (fetchDataResponse.ok) {
        const resultData = await fetchDataResponse.json();

        const parsedResponse = JSON.parse(resultData.apiResponse);
        const ArID = parsedResponse[0].ArID;

        const jsonData = await ReadArray(ArID,accessKey);

        if (jsonData) {
          const sourceData = jsonData[0].Source;

          const requestBodywriteArray = {
            accessKey: authData.accessKey,
            guid: APIProvider.GUID.WriteArrayGUID,
            param1: "",
            param2: "",
            param3: "",
            param4: "",
            recordSet: "",
            body: JSON.stringify({
              ArID: "0",
              ArrayName: editedArrayName,
              Description: editedDescription,
              Source: sourceData,
              Category: Category,
              IsTemplate: "0",
              htmlOutput: "new",
              DataSourceArID: selectedArrayId || 0,
            }),
          };

          const response = await fetch(
           `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBodywriteArray),
            }
          );
          if (response.ok) {
            const requestBodyarrayname = {
              accessKey: authData.accessKey,
              guid: APIProvider.GUID.ReadArrayByNameGUID,
              param1: editedArrayName,
              param2: "",
              param3: "",
              param4: "",
              recordSet: "",
              body: "",
            };

            const fetchDataResponse = await fetch(
              baseURL,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBodyarrayname),
              }
            );


            if (fetchDataResponse.ok) {
              const resultData = await fetchDataResponse.json();

              const parsedResponse = JSON.parse(resultData.apiResponse);
              const arID1 = parsedResponse[0].ArID;

              const newArrayName = parsedResponse[0].ArrayName;
              const newDescription = parsedResponse[0].Description;
              const newCategory = parsedResponse[0].Category;
              const newdataSourceArID = parsedResponse[0].DataSourceArID;
              const jsonData = await ReadArray(arID1,accessKey);
              if (jsonData) {
                if (Category === "Blank Form") {
                  navigate(`/Form/${arID1}`, {
                    state: {
                      ArrayName: newArrayName,
                      ArID: arID1,
                      source: JSON.stringify(sourceData),
                      dataSourceArID: newdataSourceArID,
                      Category: newCategory,
                      Description: newDescription,
                    },
                  });
                } else if (newdataSourceArID > 0) {
                  navigate("/Open", {
                    state: {
                      ArrayName: newArrayName,
                      ArID: arID1,
                      Source: JSON.stringify(sourceData),
                      DataSourceArID: newdataSourceArID,
                      Category: newCategory,
                      Description: newDescription,
                  
                    },
                  
                  });
                } else {
                  navigate(`/Form/${arID1}`, {
                    state: {
                      ArrayName: newArrayName,
                      ArID: arID1,
                      source: JSON.stringify(sourceData),
                      dataSourceArID: newdataSourceArID,
                      Category: newCategory,
                      Description: newDescription,
                     
                    },
                  });
                }
              }
            } else {
              console.error(
                "Error fetching data based on ArrayName:",
                fetchDataResponse.statusText
              );
            }
          } else {
            console.error("Error creating array:");
          }
        }
      } else {
        console.error(
          "Error fetching data based on ArrayName:",
          fetchDataResponse.statusText
        );
      }
    
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
    setShowDiscover(true);
    setIsOpen(true);
  };

  useEffect(() => {
  
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    const requestBody = {
      accessKey: authData.accessKey,
      guid: APIProvider.GUID.ReadArrayAllGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: "",
    };

    const fetchArrayNames = async () => {
      try {
        const response = await fetch(baseURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const data = await response.json();

          const apiResponse = JSON.parse(data.apiResponse);

          const filteredNames = apiResponse
            .filter((item) => item.IsTemplate === 2)
            .map((item) => item.ArrayName);

          const filteredData = apiResponse.filter(
            (item) => item.IsTemplate === 2
          );

          setRes(filteredData);
          setArrayNames(filteredNames);
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error fetching array names:", error);
      }
    };
    fetchArrayNames();
  }, []);

  const [res, setRes] = useState([]);

  const handleArrayNameChange1 = async (event) => {
    setSelectedArrayName(event.target.value);
    try {
      const selectedArray = res.find(
        (item) => item.ArrayName === event.target.value
      );

      if (selectedArray) {
        setSelectedArrayId(selectedArray.ArID);
      } else {
        console.error("Selected array not found");
      }
    } catch (error) {
      console.error("Error fetching array names:", error);
    }
  };

  const toggleEditState = () => {
    setIsEditable(!isEditable);
  };

  const handleSave = async () => {
    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      } else {
        setArrayNameError("");
      }
      if (formNameExists) {
        toast.error(
          "Form name already exists. Please choose a different name."
        );
        return;
      }
      toggleEditState();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Refresh",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSave();
      },
      tooltip: "Save",
    },
  ];

  const handleCancelClick = () => {
    setEditedArrayName("");
    setEditedDescription("");
    setSelectedArrayName("");
    setSelectedArrayId(null);
  };

  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  const handleCancel = () => {
    setIsOpen(false);
    setIsOpenSelectpage(false);
  };

  const classes = useStyles();

  return (
    <CustomPopUpForm
      open={isOpenSelectPage}
      onClose={handleCancel}
      title="Create New Form"
      iconButtons={isEditable ? iconButtonsSaveAndRestore : iconButtonsEdit}
      height="60%"
    >
      <>
        {!isEditable ? (
          <>
            <div className="card-info">
              <div className="card-info-inside-view">
                <Typography className="card-info-inside-text">
                  Form Name*:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedArrayName}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Description:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedDescription}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Category:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedcategory}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Data Source:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {selectedArrayName}
                  </Typography>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card-info card-info-view-crd">
            <div className="card-info-inside card-info-inside-view">
              <Typography style={{ fontWeight: "bold" }}>
                Form Name*:
              </Typography>
              <TextField
                required
                type="text"
                className="edit-popup"
                name="Name"
                onChange={(e) => {
                  setEditedArrayName(e.target.value);
                  setArrayNameError("");
                }}
                value={editedArrayName}
                error={!!arrayNameError}
                helperText={arrayNameError}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Description:
              </Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Title"
                onChange={(e) => setEditedDescription(e.target.value)}
                value={editedDescription}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Category:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Explanation"
                value={editedcategory}
                style={{ backgroundColor: "#bdbdbd" }}
                readOnly
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Data Source:
              </Typography>
              <Select
                value={selectedArrayName || "0"}
                onChange={handleArrayNameChange1}
                className={`${classes.customSelectRoot} data-select-crd`}
              >
                <MenuItem value="0">Select DataSource </MenuItem>
                {arrayNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </>
      <>
        <div style={{ width: "96%", display: "flex" }}>
          <Button fullWidth onClick={handleCancel}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleCreate}
            disabled={formNameExists}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
          </Button>
        </div>
        {showDiscover && (
          <Discover
            ArrayName={editedArrayName}
            Description={editedDescription}
            Category={editedcategory}
            setArrayName={setEditedArrayName}
            setDescription={setEditedDescription}
            setCategory={seteditedcategory}
            setIsOpen={setIsOpen}
            isOpenDiscover={isOpenDiscover}
            setIsOpenDiscover={setIsOpenDiscover}
            selectedArrayName={selectedArrayName}
            selectedArrayId={selectedArrayId}
            setSelectedArrayId={setSelectedArrayId}
            setSelectedArrayName={setSelectedArrayName}
            handleArrayNameChange1={handleArrayNameChange1}
            isOpen={isOpen}
            isOpenSelectPage={isOpenSelectPage}
            setIsOpenSelectpage={setIsOpenSelectpage}
            authData={authData}
          />
        )}
      </>
    </CustomPopUpForm>
  );
};

export default ViewCardInfo;
