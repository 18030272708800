import React, { useState, useEffect, useCallback } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import { useLocation } from "react-router-dom";
import DynamicNode from "../../nodes/DynamicNode.js";
import FloatingEdge from "../../functions/FloatingEdge.js";
import {
  getResource,
  replaceUnderscoresWithSpaces,
} from "../../apiCalls/DiagramsAPI.js";
import { SubflowNode } from "../../nodes/SubflowNode.js";

const jsonData = [
  {
    TExecName: "Start New Conversation",
    Explain: "",
    TaskTypeID: 50,
    ParamExplain1: "API Version",
    Param1: "",
    ParamExplain2: "",
    Param2: "",
    isActive: 1,
    Seq: 0,
    IncludePrompt: 1,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 150,
  },
  {
    TExecName: "Library Master Loop",
    Explain: "Loop Library and Contact to get matching",
    TaskTypeID: 68,
    Parent_Action: "Start New Conversation",
    Parent_Explain: "",
    ParamExplain1: "Filter",
    Param1: "QLibDetID in (4740)",
    ParamExplain2: "",
    Param2: "",
    isActive: 1,
    Seq: 20,
    IncludePrompt: 1,
    IncludeAIResponse: 1,
    Bid: 12,
    Dbid: 98,
    ParentDBID: 150,
  },
  {
    TExecName: "Master Loop",
    Explain: "Loop Library and Contact to get matching",
    TaskTypeID: 49,
    Parent_Action: "Master Loop",
    Parent_Explain: "Loop Library and Contact to get matching",
    ParamExplain1: "table name",
    Param1: "Contacts",
    ParamExplain2: "filter",
    Param2: "contactiD in (608)",
    isActive: 1,
    Seq: 25,
    IncludePrompt: 1,
    IncludeAIResponse: 1,
    Bid: 12,
    Dbid: 152,
    ParentDBID: 98,
  },
  {
    TExecName: "Send Prompt",
    Explain: "Merge & Send Prompt",
    TaskTypeID: 54,
    Parent_Action: "Master Loop",
    Parent_Explain: "Loop Library and Contact to get matching",
    ParamExplain1: "Prompt ",
    Param1:
      'Will supply you with two documents: first document is grant, second document is the applicant for this grant fact sheet.\r\nThis is the first document {Qinfolibdetails.ContentDetails} \r\nEnd of first document\r\n\r\nThis is the second document which is a detailed fact sheet delineating the objectives, strengths, and activities of a particular organization. \r\n{Contacts.Notes}\r\nEnd of second document\r\n\r\nYour job is to match both entries and provide a thorough, point-by-point analysis suggesting specific ways in which the organization, as described in the Fact Sheet, could enhance its suitability and competitiveness for the grant detailed in the request for proposal.     \r\nDraw on the organization\'s current attributes and potential opportunities for growth, aligning them with the grantor\'s needs and preferences.   \r\nYour analysis should include each of the following criteria: "alignment with the grant purpose", "applicant eligibility", "actionable steps to get the grant", "potential challenges", "strategies to overcome these challenges" and "how to best improve their score"\r\n\r\n\r\nProvide your response as a complete json array  for each area :  "criteria", "individual_score" ,"maximum_points", "explanation", "reasons_for_scoring", "rationale", "recommendations", "key_points_to_include_in_grant_response". Make sure that the json is complete and doesnt have anything extra. Your response will have text before and after the code block',
    ParamExplain2: "",
    Param2: "",
    isActive: 1,
    Seq: 30,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 99,
    ParentDBID: 152,
  },
  {
    TExecName: "Capture Code",
    Explain: "Extract code - ",
    TaskTypeID: 31,
    Parent_Action: "Send Prompt",
    Parent_Explain: "Merge & Send Prompt",
    ParamExplain1: "Extract Type",
    Param1: "JSON",
    ParamExplain2: "Prompt if return Extract Type not found (do not repeat)",
    Param2:
      "Did not get the expected output as JSON reprocess your answer and make sure the response does contain the JSON, add text before and after json code block",
    isActive: 1,
    Seq: 40,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 146,
    ParentDBID: 99,
  },
  {
    TExecName: "Process Scoring",
    Explain: "Put in match and in score",
    TaskTypeID: 43,
    Parent_Action: "Capture Code",
    Parent_Explain: "Extract code - ",
    ParamExplain1: "",
    Param1: "",
    ParamExplain2: "",
    Param2: "",
    isActive: 1,
    Seq: 50,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 151,
    ParentDBID: 146,
  },
  {
    TExecName: "Close Loop",
    Explain: "",
    TaskTypeID: 35,
    Parent_Action: "Process Scoring",
    Parent_Explain: "Put in match and in score",
    ParamExplain1: "",
    Param1: "",
    ParamExplain2: "",
    Param2: "",
    isActive: 1,
    Seq: 60,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 100,
    ParentDBID: 151,
  },
];

const nodeTypes = {
  dynamicNode: DynamicNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

const DiagramsAIUI = () => {
  const location = useLocation();
  const { diagTempID } = location.state || {};

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [fetchedData, setFetchedData] = useState(null);

  const fetchTools = async () => {
    const tools = await getResource(
      `${window.APIProvider.recordPath.ListDiagTools}?route=${replaceUnderscoresWithSpaces(
        window.APIProvider.recordPath.ListDiagTools
      )}&param1=AI UI`
    );
    return tools;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTools(diagTempID);
        setFetchedData(data);
        const nodes = jsonData.map((item, index) => {
          // Filter the fetched data to find the relevant entry
          const filteredData = data.find(
            (dataItem) =>
              dataItem?.taskTypeID?.toString() === item?.TaskTypeID?.toString()
          );

          console.log("filteredData ", filteredData);
          return {
            id: item.Dbid.toString(), // Unique ID for each node
            type: "dynamicNode", // Specify node type
            position: { x: 100, y: 100 + index * 150 }, // Position of the node
            data: [filteredData, null, item.Dbid], // Pass only relevant data
            // draggable: true,
          };
        });
        setNodes(nodes);
        const edges = jsonData
          .filter((item) => item.ParentDBID)
          .map((item) => ({
            id: `e${item.ParentDBID}-${item.Dbid}`,
            source: item.ParentDBID.toString(),
            target: item.Dbid.toString(),
            type: "floating",
            markerEnd: {
              type: "arrowclosed",
              width: 15,
              height: 15,
            },
          }));
        setEdges(edges);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [diagTempID]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      fitView
      style={{ width: "100%", height: "90vh" }}
    >
      <MiniMap />
      <Controls />
      <Background />
    </ReactFlow>
  );
};

export default DiagramsAIUI;
