import { useCallback } from "react";
import {
  createRequestBody,
  postResource,
  getAccessKey,
  updateDataWithUserAndDates,
} from "../apiCalls/DiagramsAPI";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
// export const useSave = (flowKey, rfInstance) => {
//   return useCallback(() => {
//     if (rfInstance) {
//       let flow = rfInstance.toObject();
//       console.log("flow ", flow);
//       // Temporarily saving to local storage
//       localStorage.setItem(flowKey, JSON.stringify(flow));
//     }
//   }, [rfInstance]);
// };

export const useSave = (
  diagram,
  rfInstance,
  setDiagram,
  diagramDetails,
  region
) => {
  return useCallback(async () => {
    if (rfInstance) {
      console.log("diagram details ", diagramDetails);
      const flow = rfInstance.toObject();
      console.log("flow.nodes ", flow.nodes);
      console.log("flow.edges ", flow.edges);
      // console.log("diagramDetails ", diagramDetails);

      // if (region.toLowerCase() === "ai ui") {
      //   try {
      //     const accessKey = getAccessKey();
      //     const saveResponse = await postResource(
      //       "PostQuickInfo",
      //       createRequestBody({
      //         accessKey: accessKey,
      //         guid: `${window.APIProvider.recordPath.UpdateProject}`,
      //         recordSet: diagramDetails,
      //         // recordSet: JSON.stringify(diagramDetails),
      //       })
      //     );
      //     console.log("saveResponse ", saveResponse);
      //   } catch (error) {
      //     console.error("Error saving data:", error);
      //     // Optionally, you could add additional error handling here,
      //     // such as showing a toast notification or updating some error state.
      //   }
      // }

      // Prepare diagram data
      const diagramData = {
        DiagID: diagram.diagID,
        DiagTempID: diagram.diagTempID,
        Title: diagram.title,
        Description: diagram.explanation,
        Nodes: JSON.stringify(flow.nodes),
        Edges: JSON.stringify(flow.edges),
        ViewPort: JSON.stringify(flow.viewport),
      };
      // Optionally update the diagram state here, if needed
      setDiagram({
        diagTempID: diagram.diagTempID,
        diagID: diagram.diagID,
        name: diagram.name,
        title: diagram.title,
        explanation: diagram.explanation,
        notes: diagram.notes,
        showMinMap: diagram.showMinMap,
        layout: diagram.layout,
        nodes: JSON.stringify(flow.nodes),
        edges: JSON.stringify(flow.edges),
        viewPort: JSON.stringify(flow.viewport),
      });
      const diagramInfo = {
        diagTempID: diagram.diagTempID,
        diagID: diagram.diagID,
        name: diagram.name,
        title: diagram.title,
        explanation: diagram.explanation,
        notes: diagram.notes,
        showMinMap: diagram.showMinMap,
        layout: diagram.layout,
        nodes: JSON.stringify(flow.nodes),
        edges: JSON.stringify(flow.edges),
        viewPort: JSON.stringify(flow.viewport),
      };
      console.log("diagram ", diagram);
      localStorage.setItem("diagramInfo", JSON.stringify(diagramInfo));
      // localStorage.setItem("userEmail", JSON.stringify(value));

      // Call UpdateDiagHeader with diagramData
      // UpdateDiagHeader([diagramData])
      //   .then(() => {
      //     toast.success("Diagram header updated successfully.");
      //   })
      //   .catch((error) => {
      //     toast.error("Error updating diagram header:", error);
      //   });
    }
  }, [diagram, rfInstance, diagramDetails, region]);
};

// export const useRestore = (flowKey, setNodes, setEdges, setViewport) => {
//   return useCallback(() => {
//     const restoreFlow = async () => {
//       // Temporarily restoring from local storage
//       const flow = JSON.parse(localStorage.getItem(flowKey));

//       if (flow) {
//         const { x = 0, y = 0, zoom = 1 } = flow.viewport;
//         setNodes(flow.nodes || []);
//         setEdges(flow.edges || []);
//         setViewport({ x, y, zoom });
//       }
//     };

//     restoreFlow();
//   }, [flowKey, setNodes, setEdges, setViewport]);
export const useRestore = (
  diagram,
  setNodes,
  setEdges,
  setViewport,
  initialDiagramDetails,
  data,
  properties,
  region
) => {
  return useCallback(() => {
    const restoreFlow = async () => {
      if (region.toLowerCase() === "ai ui") {
        console.log("data ", data);
        console.log("properties ", properties);
        console.log("initialDiagramDetails ", initialDiagramDetails);
        console.log("diagram.nodes ", diagram.nodes);
        console.log("diagram.edges ", diagram.edges);
      } else {
        // Temporarily restoring from local storage
        const initialNodes =
          diagram.nodes !== "" ? JSON.parse(diagram.nodes) : [];
        const initialEdges =
          diagram.nodes !== "" ? JSON.parse(diagram.edges) : [];
        const initialViewPort =
          diagram.nodes !== ""
            ? JSON.parse(diagram.viewPort)
            : { x: 0, y: 0, zoom: 1 };
        setNodes(initialNodes);
        setEdges(initialEdges);
        setViewport(initialViewPort);
      }
    };

    restoreFlow();
  }, [
    diagram,
    setNodes,
    setEdges,
    setViewport,
    initialDiagramDetails,
    data,
    properties,
  ]);
};

//Restoring AI UI
export const processData = (jsonData, data, properties) => {
  const nodes = [];
  const edges = [];
  let currentX = 100; // Starting X position for the first graph
  let currentY = 100; // Starting Y position (reset for each graph)
  const verticalSpacing = 100;
  const horizontalSpacing = 200; // Increased to give space between nodes
  let inLoop = false;
  let loopStartY = 0;

  // Maps to organize nodes by ParentDBID and Dbid
  const parentToChildren = {};
  const nodeMap = {};

  // Fill nodeMap and parentToChildren
  jsonData.forEach((item, index) => {
    nodeMap[item.Dbid] = item; // Map each node by Dbid for fast lookup

    if (item.ParentDBID) {
      if (!parentToChildren[item.ParentDBID]) {
        parentToChildren[item.ParentDBID] = [];
      }
      parentToChildren[item.ParentDBID].push({ item, index }); // Store with original index for tie-breaking
    } else {
      if (!parentToChildren[null]) {
        parentToChildren[null] = [];
      }
      parentToChildren[null].push({ item, index }); // Root nodes (start of a graph)
    }
  });

  // Helper to sort children by Seq and original order
  const sortChildren = (children) => {
    return children.sort((a, b) => {
      if (a.item.Seq !== b.item.Seq) {
        return a.item.Seq - b.item.Seq; // Sort by Seq
      }
      return a.index - b.index; // Sort by original order as tiebreaker
    });
  };

  const getNodeById = (id) => nodes.find((node) => node.id === id);

  const getNodeByParentDBID = (item) => {
    const foundNode = jsonData.find((data) => {
      return data?.Dbid?.toString() === item.ParentDBID?.toString();
    });

    return foundNode;
  };
  // const getShortenedUuid = () => {
  //   return uuidv4().split("-")[0]; // Take only the first part of the UUID
  // };

  // const getNodeId = () => getShortenedUuid();
  // const usedIds = new Set();
  const usedIds = new Set(jsonData?.map((data) => data.Dbid));
  const getNodeId = () => {
    let newId;
    do {
      newId = Math.floor(10000 + Math.random() * 90000);
    } while (usedIds.has(newId));
    usedIds.add(newId);
    return newId.toString();
  };

  // Depth-First Search (DFS) to traverse nodes in topological order
  const addNodeAndChildren = (item) => {
    const nodeID = getNodeId();
    let filteredData = data.find(
      (dataItem) =>
        dataItem?.tasktypeid?.toString() === item?.TaskTypeID?.toString()
    );

    console.log("filteredData", filteredData);
    if (filteredData) {
      item.id = nodeID;
    }
    const category = filteredData.category;
    console.log("category ", category);
    var matchedData = properties[category];
    console.log("matchedData ", matchedData);
    const nodeId = filteredData?.diagtoolid?.toString();
    const nodeData =
      properties?.Nodes.find((node) => node.id.toString() === nodeId) || null;
    if (nodeData !== null) {
      matchedData = { ...matchedData, ...nodeData.customstyle };
    }
    //if filteredProperties = true add properties.customstyle
    const nodeHeight = filteredData?.node_Height
      ? filteredData?.node_Height + "px"
      : "75px";
    const nodeWidth = filteredData?.node_Width
      ? filteredData?.node_Width + "px"
      : "200px";
    const isDraggable = filteredData?.isDraggable === 1 ? true : false;
    // Create a new array to store the merged parameters
    const mergedParams = filteredData?.params?.map((filteredParam) => {
      // Find matching item parameter by TaskParamID
      const matchingItemParam = item?.Params?.find(
        (itemParam) => itemParam?.TaskParamID === filteredParam?.taskParamID
      );

      // Merge properties from matchingItemParam if it exists, without modifying filteredData or item
      return {
        taskParamID:
          matchingItemParam?.TaskParamID || filteredParam?.taskParamID || "",
        direction:
          matchingItemParam?.direction || filteredParam?.direction || "",
        paramName:
          matchingItemParam?.paramName || filteredParam?.paramName || "",
        paramExplanation:
          matchingItemParam?.paramExplanation ||
          filteredParam?.paramExplanation ||
          "",
        paramType:
          matchingItemParam?.paramType || filteredParam?.paramType || "",
        dbid: matchingItemParam?.DBID || filteredParam?.DBID || "",
        bDetPid: matchingItemParam?.BDetPid || filteredParam?.BDetPid || "",
        paramValue:
          matchingItemParam?.ParamValue || filteredParam?.ParamValue || "",
        internalNotes:
          matchingItemParam?.InternalNotes ||
          filteredParam?.InternalNotes ||
          "",
      };
    });
    // Create a new object based on filteredData, replacing params with mergedParams
    const filteredDataWithMergedParams = {
      ...filteredData,
      params: mergedParams, // Replace params with mergedParams
    };
    const node = {
      id: nodeID,
      type: "dynamicNode",
      data: [filteredDataWithMergedParams, matchedData, nodeID],
      style: { width: nodeWidth, height: nodeHeight },
      draggable: true,
    };

    // Positioning logic
    if (filteredData?.nodeType === 3) {
      // Start of a loop
      inLoop = true;
      loopStartY = currentY;
      node.position = { x: currentX, y: currentY };
      currentY += verticalSpacing;
      currentX += horizontalSpacing;
    } else if (filteredData?.nodeType === 6) {
      // End of a loop
      inLoop = false;
      currentX -= horizontalSpacing;
      node.position = { x: currentX, y: currentY };
      currentY += verticalSpacing;
    } else {
      if (inLoop) {
        node.position = { x: currentX, y: currentY };
        currentY += verticalSpacing;
      } else {
        if (item.ParentDBID) {
          const parentNode = getNodeById(item.ParentDBID.toString());
          if (parentNode) {
            currentY = parentNode.position.y + verticalSpacing;
          }
        }
        node.position = { x: currentX, y: currentY };
        currentY += verticalSpacing;
      }
    }

    nodes.push(node);

    const parent = getNodeByParentDBID(item);
    // Add edge if it has a parent
    if (parent) {
      edges.push({
        id: `e${item.ParentDBID}-${item.Dbid}`,
        source: parent.id.toString(),
        target: nodeID.toString(),
        type: "floating",
        markerEnd: { type: "arrowclosed", width: 15, height: 15 },
      });
    }

    // Recursively process children
    const children = parentToChildren[item.Dbid];
    if (children) {
      sortChildren(children).forEach((child) => addNodeAndChildren(child.item));
    }
  };

  // Start with root nodes (those without ParentDBID)
  const rootNodes = parentToChildren[null] || [];

  // Process each root node (each graph) separately
  sortChildren(rootNodes).forEach((root, index) => {
    if (index > 0) {
      // After the first graph, reset currentY and shift currentX for next graph
      currentX += horizontalSpacing * 3; // Adjust this spacing based on node size
      currentY = 100; // Reset Y position for the new graph
    }
    addNodeAndChildren(root.item);
  });

  return { nodes, edges };
};
