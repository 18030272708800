import { Position, MarkerType } from "reactflow";

import { Padding, Twitter } from "@mui/icons-material";
import rainbow from "./assets/rainbow.png";
import email from "./assets/email.svg";

const type1 = "dynamicNode";
const type = "ResizableNodeSelected";
const style = {
  background: "#fff",
  border: "1px solid black",
  borderRadius: 15,
  fontSize: 12,
};
export const initialNodes = [
  {
    id: "1",
    type: "input",
    data: { label: "input" },
    position: { x: 0, y: 0 },
    draggable: false,
  },
  // {
  //   id: "2",
  //   type: type1,
  //   data:   {
  //     DiagTempID: 1,
  //     Caption: "D Node",
  //     Description: "Input Node Description",
  //     SEQ: 1,
  //     Node_Size: 50,
  //     StartOnly: "1|0",
  //     EndOnly: "1|0",
  //     Handles: "2",
  //     Node_CSS: "",
  //     HTML_CSS: "",
  //     HTML_Text: "",
  //     IsResizable: "",
  //     Icon: "",
  //     ObjectID: "",
  //     Other_Link1: 2,
  //   },
  //   // data: { label: "node 2" },
  //   position: { x: 0, y: 100 },
  //   style: style,
  //   elk: { direction: "RIGHT" },
  // },
  {
    id: "2",
    // type: "dynamicNode",
    type: "default",
    data: { label: "node 2a" },
    position: { x: 0, y: 200 },
    // style: style,
    draggable: true,
  },
  {
    id: "3",
    type: "default",
    data: { label: "node 3" },
    position: { x: 300, y: 0 },
  },
  {
    id: "4",
    type: "default",
    data: { label: "node 4" },
    position: { x: 400, y: 200 },
  },
  // {
  //   id: "2a",
  //   type: type,
  //   data: { label: "node 2a" },
  //   position: { x: 0, y: 200 },
  //   style: style,
  //   elk: { direction: "RIGHT" },
  // },
  // {
  //   id: "2b",
  //   type: type,
  //   data: { label: "node 2b" },
  //   position: { x: 0, y: 300 },
  //   style: style,
  //   elk: { direction: "RIGHT" },
  // },
  // {
  //   id: "2c",
  //   type: type,
  //   data: { label: "node 2c" },
  //   position: { x: 0, y: 400 },
  //   style: style,
  //   elk: { direction: "RIGHT" },
  // },
  // {
  //   id: "2d",
  //   type: type,
  //   data: { label: "node 2d" },
  //   position: { x: 0, y: 500 },
  //   style: style,
  //   elk: { direction: "RIGHT" },
  // },
  // {
  //   id: "3",
  //   type: type,
  //   data: { label: "node 3" },
  //   position: { x: 200, y: 100 },
  //   style: style,
  //   elk: { direction: "RIGHT" },
  // },
];

export const initialEdges = [
  // { id: "e12", source: "1", target: "2", animated: true },
  // { id: "e13", source: "1", target: "3", animated: true },
  // { id: "e22a", source: "2", target: "2a", animated: true },
  // { id: "e22b", source: "2", target: "2b", animated: true },
  // { id: "e22c", source: "2", target: "2c", animated: true },
  // { id: "e2c2d", source: "2c", target: "2d", animated: true },
  {
    id: "e12",
    source: "1",
    target: "2",
    type: "floating",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 30,
      height: 30,
    },
  },
  {
    id: "e34",
    source: "3",
    target: "4",
    type: "smoothstep",
    markerEnd: "circle-marker",
    // markerEnd: "logo",
    // markerEnd: {
    //   type: MarkerType.Custom,
    //   elementId: 'circle-marker', // ID of the custom marker element
    // },
    markerStart: {
      type: MarkerType.Arrow, // or any other type you want
      color: "#FF0072", // customize color if needed
    },
    label: "circle marker",
    // labelBgPadding: [8, 4],
    // labelBgBorderRadius: 4,
    labelBgStyle: {
      fill: "#FFCC00",
      color: "#fff",
      fillOpacity: 0.7,
      padding: "10rem",
    },
    // labelStyle: { fill: 'red', fontWeight: 700, fontSize:100 },
  },
  // { id: "e13", source: "1", target: "3" },
  // { id: "e22a", source: "2", target: "2a" },
  // { id: "e22b", source: "2", target: "2b" },
  // { id: "e22c", source: "2", target: "2c" },
  // { id: "e2c2d", source: "2c", target: "2d" },
];
// export const nodess = [
//   {
//     id: '1',
//     position: { x: 0, y: 0 },
//     data: { label: 'Node 1', resizable: true },
//     type: 'resizeRotate',
//     targetPosition: Position.Left,
//     sourcePosition: Position.Right,
//     selected: true,
//     style: { width: 180, height: 100 },
//   },
//   {
//     id: '2',
//     position: { x: 300, y: 0 },
//     data: { label: 'Node 2', rotatable: true },
//     type: 'resizeRotate',
//     targetPosition: Position.Left,
//     sourcePosition: Position.Right,
//     style: { width: 180, height: 100 },
//   },
// ];

// export const edgess = [
//   {
//     id: '1->2',
//     source: '1',
//     target: '2',
//     type: 'smoothstep',
//   },
// ];
export default function MarkersExample() {
  return (
    <>
      <svg  style={{ position: "absolute", top: 0, left: 0 }}>
        <defs>
          <marker
            id="logo"
            viewBox="0 0 40 40"
            markerHeight={20}
            markerWidth={20}
            refX={20}
            refY={40}
          >
            <path
              d="M35 23H25C23.8954 23 23 23.8954 23 25V35C23 36.1046 23.8954 37 25 37H35C36.1046 37 37 36.1046 37 35V25C37 23.8954 36.1046 23 35 23Z"
              stroke="#1A192B"
              stroke-width="2"
              fill="white"
            />
            <path
              d="M35 3H25C23.8954 3 23 3.89543 23 5V15C23 16.1046 23.8954 17 25 17H35C36.1046 17 37 16.1046 37 15V5C37 3.89543 36.1046 3 35 3Z"
              stroke="#FF0072"
              stroke-width="2"
              fill="white"
            />
            <path
              d="M15 23H5C3.89543 23 3 23.8954 3 25V35C3 36.1046 3.89543 37 5 37H15C16.1046 37 17 36.1046 17 35V25C17 23.8954 16.1046 23 15 23Z"
              stroke="#1A192B"
              stroke-width="2"
              fill="white"
            />
            <path
              d="M15 3H5C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17H15C16.1046 17 17 16.1046 17 15V5C17 3.89543 16.1046 3 15 3Z"
              stroke="#1A192B"
              stroke-width="2"
              fill="white"
            />
            <path
              d="M17 13C18.6569 13 20 11.6569 20 10C20 8.34315 18.6569 7 17 7C15.3431 7 14 8.34315 14 10C14 11.6569 15.3431 13 17 13Z"
              fill="white"
            />
            <path
              d="M23 13C24.6569 13 26 11.6569 26 10C26 8.34315 24.6569 7 23 7C21.3431 7 20 8.34315 20 10C20 11.6569 21.3431 13 23 13Z"
              fill="white"
            />
            <path
              d="M30 20C31.6569 20 33 18.6569 33 17C33 15.3431 31.6569 14 30 14C28.3431 14 27 15.3431 27 17C27 18.6569 28.3431 20 30 20Z"
              fill="white"
            />
            <path
              d="M30 26C31.6569 26 33 24.6569 33 23C33 21.3431 31.6569 20 30 20C28.3431 20 27 21.3431 27 23C27 24.6569 28.3431 26 30 26Z"
              fill="white"
            />
            <path
              d="M17 33C18.6569 33 20 31.6569 20 30C20 28.3431 18.6569 27 17 27C15.3431 27 14 28.3431 14 30C14 31.6569 15.3431 33 17 33Z"
              fill="white"
            />
            <path
              d="M23 33C24.6569 33 26 31.6569 26 30C26 28.3431 24.6569 27 23 27C21.3431 27 20 28.3431 20 30C20 31.6569 21.3431 33 23 33Z"
              fill="white"
            />
            <path
              d="M30 25C31.1046 25 32 24.1046 32 23C32 21.8954 31.1046 21 30 21C28.8954 21 28 21.8954 28 23C28 24.1046 28.8954 25 30 25Z"
              fill="#1A192B"
            />
            <path
              d="M17 32C18.1046 32 19 31.1046 19 30C19 28.8954 18.1046 28 17 28C15.8954 28 15 28.8954 15 30C15 31.1046 15.8954 32 17 32Z"
              fill="#1A192B"
            />
            <path
              d="M23 32C24.1046 32 25 31.1046 25 30C25 28.8954 24.1046 28 23 28C21.8954 28 21 28.8954 21 30C21 31.1046 21.8954 32 23 32Z"
              fill="#1A192B"
            />
            <path opacity="0.35" d="M22 9.5H18V10.5H22V9.5Z" fill="#1A192B" />
            <path
              opacity="0.35"
              d="M29.5 17.5V21.5H30.5V17.5H29.5Z"
              fill="#1A192B"
            />
            <path opacity="0.35" d="M22 29.5H18V30.5H22V29.5Z" fill="#1A192B" />
            <path
              d="M17 12C18.1046 12 19 11.1046 19 10C19 8.89543 18.1046 8 17 8C15.8954 8 15 8.89543 15 10C15 11.1046 15.8954 12 17 12Z"
              fill="#1A192B"
            />
            <path
              d="M23 12C24.1046 12 25 11.1046 25 10C25 8.89543 24.1046 8 23 8C21.8954 8 21 8.89543 21 10C21 11.1046 21.8954 12 23 12Z"
              fill="#FF0072"
            />
            <path
              d="M30 19C31.1046 19 32 18.1046 32 17C32 15.8954 31.1046 15 30 15C28.8954 15 28 15.8954 28 17C28 18.1046 28.8954 19 30 19Z"
              fill="#FF0072"
            />
          </marker>
        </defs>
      </svg>
    </>
  );
}

export const jsonData = [
  {
    TExecName: "Library Master Loop",
    Explain: "Loop Library and Contact to get matching",
    TaskTypeID: 68,
    Parent_Action: "Start New Conversation",
    Parent_Explain: "",
    Params: [
      {
        TaskParamID: 54,
        ParamName: "Filter",
        ParamExplanation:
          'Filter used for the select query exp :"QLibDetID in (4809)"',
        DBID: 98,
        BDetPid: 4,
        ParamValue: "QLibDetID in (4822)",
        InternalNotes:
          "For testing a normal send prompt and capture code, i was using 4740 here (Library Master Loop) and 608 in the  other loop (Master Loop). For testing file extract and extract by rules (Carington), i am using 4809 or 4811 in here.",
      },
    ],
    isActive: 1,
    Seq: 20,
    IncludePrompt: 1,
    IncludeAIResponse: 1,
    Bid: 12,
    Dbid: 98,
    ParentDBID: 150,
  },
  {
    TExecName: "Send Prompt",
    Explain: "Merge & Send Prompt",
    TaskTypeID: 54,
    Parent_Action: "Master Loop",
    Parent_Explain: "Loop Library and Contact to get matching",
    Params: [
      {
        TaskParamID: 44,
        ParamName: "Prompt",
        ParamExplanation:
          'Prompt that will replace value names by values. Works with Query , Master Loop and Upload Files. Example : \' i will provide you with 2 variables and an uploaded file :  variable 1 "{testQuery1}" and variable 2 "{QinfoLibDetails.ContentDetails}". find corelation between the 2 variables and the uploaded \'.(variable 1 is a Query, varibale 2 is from Library Master loop)\r\nNote: when using the Upload Files, the aichat assistant will know what uplpaded file we are tallking about.',
        DBID: 99,
        BDetPid: 9,
        ParamValue:
          'Will supply you with two documents: first document is grant, second document is the applicant for this grant fact sheet.\r\nThis is the first document {QinfoLibDetails.ContentDetails} \r\nEnd of first document\r\n\r\nThis is the second document which is a detailed fact sheet delineating the objectives, strengths, and activities of a particular organization. \r\n{Contacts.Notes}\r\nEnd of second document\r\n\r\nYour job is to match both entries and provide a thorough, point-by-point analysis suggesting specific ways in which the organization, as described in the Fact Sheet, could enhance its suitability and competitiveness for the grant detailed in the request for proposal.     \r\nDraw on the organization\'s current attributes and potential opportunities for growth, aligning them with the grantor\'s needs and preferences.   \r\nYour analysis should include each of the following criteria: "alignment with the grant purpose", "applicant eligibility", "actionable steps to get the grant", "potential challenges", "strategies to overcome these challenges" and "how to best improve their score"\r\n\r\n\r\nProvide your response as a complete json array  for each area :  "criteria", "individual_score" ,"maximum_points", "explanation", "reasons_for_scoring", "rationale", "recommendations", "key_points_to_include_in_grant_response". Make sure that the json is complete and doesnt have anything extra. Your response will have text before and after the code block',
        InternalNotes: "",
      },
    ],
    isActive: 1,
    Seq: 30,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 99,
    ParentDBID: 152,
  },
  {
    TExecName: "Close Loop",
    Explain: "",
    TaskTypeID: 35,
    Parent_Action: "Process Scoring",
    Parent_Explain: "Put in match and in score",
    isActive: 1,
    Seq: 60,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 100,
    ParentDBID: 151,
  },
  {
    TExecName: "Capture Code",
    Explain: "Extract code - ",
    TaskTypeID: 31,
    Parent_Action: "Send Prompt",
    Parent_Explain: "Merge & Send Prompt",
    isActive: 1,
    Seq: 40,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 146,
    ParentDBID: 99,
  },
  {
    TExecName: "Start New Conversation",
    Explain: "",
    TaskTypeID: 50,
    Params: [
      {
        TaskParamID: 38,
        ParamName: "AI Engine",
        ParamExplanation:
          "Version of chat engine (if not set, will use gpt 4 turbo)",
      },
      {
        TaskParamID: 39,
        ParamName: "AI Profile",
        ParamExplanation:
          "Ai Profile Name. If left empty will use a default ai chat assistant. (if not set will use a default assistant)",
      },
    ],
    isActive: 1,
    Seq: 0,
    IncludePrompt: 1,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 150,
  },
  {
    TExecName: "Process Scoring",
    Explain: "Put in match and in score",
    TaskTypeID: 43,
    Parent_Action: "Capture Code",
    Parent_Explain: "Extract code - ",
    isActive: 1,
    Seq: 50,
    IncludePrompt: 0,
    IncludeAIResponse: 0,
    Bid: 12,
    Dbid: 151,
    ParentDBID: 146,
  },
  {
    TExecName: "Master Loop",
    Explain: "Loop Library and Contact to get matching",
    TaskTypeID: 49,
    Parent_Action: "Master Loop",
    Parent_Explain: "Loop Library and Contact to get matching",
    Params: [
      {
        TaskParamID: 33,
        ParamName: "Table Name",
        ParamExplanation: "Name of the table we will use to SELECT from",
        DBID: 152,
        BDetPid: 5,
        ParamValue: "Contacts",
        InternalNotes: "",
      },
      {
        TaskParamID: 34,
        ParamName: "Filter",
        ParamExplanation:
          "Filter for the SELECT statement exp : ContactID in (1234)",
        DBID: 152,
        BDetPid: 6,
        ParamValue: "contactiD in (608)",
        InternalNotes: "",
      },
      {
        TaskParamID: 35,
        ParamName: "Primary key",
        ParamExplanation:
          "Primary key of the table we will SELECT from. Will also be added in the Select statement",
        DBID: 152,
        BDetPid: 7,
        ParamValue: "ContactID",
        InternalNotes: "",
      },
      {
        TaskParamID: 36,
        ParamName: "Field 1",
        ParamExplanation:
          'Variable Name in the query result. Will be used in the select Statement. Example "Notes".',
        DBID: 152,
        BDetPid: 8,
        ParamValue: "Notes",
        InternalNotes: "",
      },
      {
        TaskParamID: 59,
        ParamName: "Field 2",
        ParamExplanation:
          'Variable Name in the query result. Will be used in the select Statement. Example "Notes".',
      },
      {
        TaskParamID: 60,
        ParamName: "Field 3",
        ParamExplanation:
          'Variable Name in the query result. Will be used in the SELECT Statement. Example "Notes".',
      },
    ],
    isActive: 1,
    Seq: 25,
    IncludePrompt: 1,
    IncludeAIResponse: 1,
    Bid: 12,
    Dbid: 152,
    ParentDBID: 98,
  },
];
