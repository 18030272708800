import { styled } from "@mui/material";
import themeProvider from "../../ThemeProvider";

export const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
  backgroundColor: "#f0f0f0",
});
export const ContainerStyle = styled("div")({
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  boxShadow: "0 0 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)",
  minWidth: "21rem",
  paddingTop: themeProvider.spacing(2),
  paddingBottom: themeProvider.spacing(2),
  // marginTop: "2.313rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
export const SecondContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Form = styled("form")({
  backgroundColor: "#FFFFFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "85%", // Change the height to make it bigger
  height: "100%",
  textAlign: "center",
});

export const Title = styled("h1")({
  fontWeight: "bold",
  margin: "0",
  color: themeProvider.palette.primary.main,
});

export const Anchor = styled("a")({
  color: themeProvider.palette.primary.main,
  fontSize: "14px",
  textDecoration: "underline",
  margin: "0.3rem",
});
export const OR = styled("a")({
  color: "#BBBBBB",
  fontSize: "14px",
  margin: "0.3rem",
});

export const Button = styled("button")({
  borderRadius: "5px",
  border: `1px solid ${themeProvider.palette.primary.main}`,
  backgroundColor: themeProvider.palette.primary.main,
  width: "100%",
  color: "#FFFFFF",
  fontSize: "12px",
  padding: "12px 45px",
  letterSpacing: "1px",
  textTransform: "uppercase",
});
export const AssistedLoginButton = styled("button")({
  borderRadius: "5px",
  border: `1px solid ${themeProvider.palette.primary.main}`,
  backgroundColor: "#FFFFFF",
  width: "100%",
  color: themeProvider.palette.primary.main,
  marginTop: "0.5rem",
  fontSize: "12px",
  padding: "12px 45px",
  letterSpacing: "1px",
  textTransform: "uppercase",
});

export const buttonLogoStyle = {
  width: "20px",
  height: "0.7rem",
  marginRight: "0.5rem",
};

// export const BannerContainer = styled("div")({
//   // minWidth: "50rem",
//   width: "auto",
//   height: "160px",
//   display: "flex",
//   alignItems: "center",
//   padding: "16px",
//   backgroundColor: "white",
//   borderRadius: "10px",
// });
// export const GifContainer = styled("div")({
//   height: "100%",
//   marginRight: "16px",
// });
// export const Gif = styled("img")({
//   width: "100%",
//   height: "100%",
//   objectFit: "cover",
// });
// export const BannerContent = styled("div")({
//   flex: 1,
// });

// export const TextBanner = styled("div")({
//   width: "640px",
//   height: "160px",
//   display: "flex",
//   color: "#333",
//   fontSize: "17px",
//   flexWrap: "wrap",
//   overflow: "hidden",
// });
export const BannerContainer = styled("div")({
  width: "100%",
  maxWidth: "1200px", // Sets a max width to ensure it doesn't stretch too far on large screens
  height: "auto",
  display: "flex",
  alignItems: "center",
  padding: "1rem",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for better visual appearance
  margin: "0 auto", // Centers the banner
  gap: "1rem", // Ensures consistent spacing between elements
  flexWrap: "wrap", // Wraps elements on smaller screens
});

export const GifContainer = styled("div")({
  flex: "0 0 80px", // Fixed width to ensure consistent size
  height: "80px",
  marginRight: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Gif = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain", // Keeps the aspect ratio
});

export const BannerContent = styled("div")({
  flex: 1,
  minWidth: "200px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

export const TextBanner = styled("div")({
  width: "100%",
  // maxWidth: "640px",
  fontSize: "1rem",
  color: "#333",
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
  textAlign: "left",
  lineHeight: "1.4",
  "@media (max-width: 600px)": {
    fontSize: "0.9rem", // Smaller text on smaller screens
    maxWidth: "100%", // Allow content to take full width
  },
});
