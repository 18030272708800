import React, { useEffect, useState } from "react";
import { Box, Input, TextField, Typography } from "@mui/material";
import TabsComponent from "../diagramsComponents/TabsComponent";
import { Theme, Image, countries } from "../IncludeFile/Theme";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import "./RecursiveTreeView.css";
import Controls from "../IncludeFile/Controls";
import { AddressJSON, CountryJSON } from "../IncludeFile/AddJSON";
import { ReadArray } from "../Api/ApiCalls";

const GeneralSection = ({
  tabValue,
  setTabValue,
  rightsidetabs,
  selectedItem,
  isRootItem,
  treeData,
  editedData,
  handleEditChange,
  tabSelect,
  handleEnterForNumber,
  handleAgree,
  handlePopupClick,
  isPopupVisible,
  handleClose,
  selectedOption,
  dataSourceArID,
  fieldNames,
  fieldNamestable,
  handleChange,
  inputValueRadio,
  handleInputChangeRadio,
  handleAddControlRadio,
  inputValue,
  handleInputChange,
  handleAddControlCheckbox,
  selectedOptioncheck,
  handleCheckboxChange,
  selectedPositioncheck,
  handleCheckboxPositonChange,
  selectedOptionSwitchcheck,
  handleSwitchChange,
  inputRef,
  handleImageChange,
  handleImageClick,
  handleDateChange,
  isOpen,
  handleEditChangeName1,
  handleRenameConfirm,
  controlsVisible,
  addNewColumn,
  selectedControlType,
  handleControlTypeChange,
  editedName,
  handleEditChangeforTable,
  fieldData,
  setFieldNames,
  handleRadioChange,
  selectedControl,
  setTreeData,
  setEditedData,
  selectedItemID,
  defaultStyle,
  selectedNode,
  updateCustomStyle,
  isRequiredFullName,
  setIsRequiredFullName,
  isRequiredAddress,
  setIsRequiredAddress,
  CheckboxCheckedStreet1,
  CheckboxCheckedStreet2,
  CheckboxCheckedCity,
  CheckboxCheckedState,
  CheckboxCheckedPostal,
  CheckboxCheckedCountry,
  setFieldData,
  setFieldNamestable,
  authData,
}) => {
  const [editedDataRatingLabel, setEditedDataRatingLabel] = useState("");
  const [controlChanged, setcontrolChanged] = useState(null);
  const [DateField, SetDateField] = useState([]);
  const [ImageField, setImageField] = useState([]);
  const [IframeField, setIframeField] = useState([]);
  const [switchField, setSwitchField] = useState([]);
  const [checkboxField, setCheckboxField] = useState([]);
  const [RadioField, setRadioField] = useState([]);
  const [editedDataHeader, setEditedDataHeader] = useState("");
  const [editedDataStreet1, setEditedDataStreet1] = useState("");
  const [editedDataStreet2, setEditedDataStreet2] = useState("");
  const [editedDataCityLabel, setEditedDataCityLabel] = useState("");
  const [editedDataStateLabel, setEditedDataStateLabel] = useState("");
  const [editedDataPostalLabel, setEditedDataPostalLabel] = useState("");
  const [editedDataCountryLabel, setEditedDataCountryLabel] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [editedDataLongTextLabel, setEditedDataLongTextLabel] = useState("");
  const [editedDataLongTextName, setEditedDataLongTextName] = useState("");
  const [editedDataLongTextNameTextbox, setEditedDataLongTextNameTextbox] =
    useState("");
  const [editedDataShortTextLabel, setEditedDataShortTextLabel] = useState("");
  const [editedDataShortTextName, setEditedDataShortTextName] = useState("");
  const [editedDataShortTextNameTextbox, setEditedDataShortTextNameTextbox] =
    useState("");
  const [editedDataPhoneLabel, setEditedDataPhoneLabel] = useState("");
  const [editedDataEmailLabel, setEditedDataEmailLabel] = useState("");
  const [editedDataDropDownLabel, setEditedDataDropDownLabel] = useState("");
  const [editedDataFullName, setEditedDataFullName] = useState("");
  const [editedDataFirstName, setEditedDataFirstName] = useState("");
  const [editedDataLastName, setEditedDataLastName] = useState("");
  const [editedDataMiddleNameLabel, setEditedDataMiddleNameLabel] =
    useState("");
  const [editedDataPrefixLabel, setEditedDataPrefixLabel] = useState("");
  const [editedDataHeading, setEditedDataHeading] = useState("");
  const [editedDataSubHeading, setEditedDataSubHeading] = useState("");
  const [editedDataSingleChoiceLabel, setEditedDataSingleChoiceLabel] =
    useState("");

  const [editedDataCheckboxHeading, setEditedDataCheckboxHeading] =
    useState("");
  const [isCountryLabelPresent, setIsCountryLabelPresent] = useState(false);
  const [isMiddleNameLabelPresent, setIsMiddleNameLabelPresent] =
    useState(false);
  const [isPrefixLabelPresent, setIsPrefixLabelPresent] = useState(false);
  const [editedDataStreet1Present, setEditedDataStreet1Present] = useState("");
  const [editedDataStreet2Present, setEditedDataStreet2Present] = useState("");
  const [editedDataCityLabelPresent, setEditedDataCityLabelPresent] =
    useState("");
  const [editedDataStateLabelPresent, setEditedDataStateLabelPresent] =
    useState("");
  const [editedDataPostalLabelPresent, setEditedDataPostalLabelPresent] =
    useState("");
  const [editedDataDateLabel, setEditedDataDateLabel] = useState("");
  const [editedDataDateLabelOption, setEditedDataDateLabelOption] =
    useState("");
  const [label1DataValue, setLabel1DataValue] = useState("");
  const [tableFieldNames, setTableFieldNames] = useState([]);

  const [alignMent, setAlignMent] = useState("");
  const [FontSize, setFontSize] = useState(12);

  // Fetch the data when dataSourceArID changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ReadArray(dataSourceArID, authData);
        const fetchedSourceData = data[0].Source;

        // Store fetched data
        setFieldData(fetchedSourceData);

        // Extract field names from the elements inside the 'table' type
        const tableData = fetchedSourceData.find(
          (item) => item.elementtype === "array" && item.type === "table"
        );

        if (tableData && tableData.elements) {
          const fieldNamesInTable = tableData.elements
            .filter((item) => item.elementtype === "field")
            .map((item) => item.fieldname);

          setTableFieldNames(fieldNamesInTable);

          // Optionally set the first field name as the default for Field Label1
          if (fieldNamesInTable.length > 0) {
            setLabel1DataValue(fieldNamesInTable[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dataSourceArID]);

  useEffect(() => {
    if (editedDataFirstName.includes("*") || editedDataLastName.includes("*")) {
      setIsRequiredFullName(true);
    } else {
      setIsRequiredFullName(false);
    }
  }, [editedDataFirstName, editedDataLastName]);

  useEffect(() => {
    const fieldNames = fieldData
      ? fieldData
          .filter(
            (item) => item.elementtype === "field" && item.fieldtype === "text"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setFieldNames(fieldNames);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesImage = fieldData
      ? fieldData
          .filter(
            (item) => item.elementtype === "field" && item.fieldtype === "Image"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setImageField(fieldNamesImage);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesIframe = fieldData
      ? fieldData
          .filter(
            (item) =>
              item.elementtype === "field" && item.fieldtype === "Iframe"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setIframeField(fieldNamesIframe);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesSwitch = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setSwitchField(fieldNamesSwitch);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesCheckbox = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setCheckboxField(fieldNamesCheckbox);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesRadio = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setRadioField(fieldNamesRadio);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesDate = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "datetime") ||
              (item.elementtype === "field" && item.fieldtype === "date") ||
              (item.elementtype === "field" && item.fieldtype === "time")
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    SetDateField(fieldNamesDate);
  }, [fieldData]);

  const findControlcontrolChange = (controls, selectedControlType) => {
    for (const control of controls) {
      if (control.controlChange === selectedControlType) {
        return control.controlChange;
      }
      if (control.control && control.control.length > 0) {
        const nestedControlcontrolChange = findControlcontrolChange(
          control.control,
          selectedControlType
        );
        if (nestedControlcontrolChange) return nestedControlcontrolChange;
      }
    }
    return null;
  };

  useEffect(() => {
    if (treeData.length > 0 && treeData[0].Source && treeData[0].Source[0]) {
      const changed = findControlcontrolChange(
        treeData[0].Source[0].control,
        selectedControlType
      );
      setcontrolChanged(changed);
    }
  }, [selectedControlType]);

  const menuItems = Controls.filter(
    (category) => category.title === "Display" || category.title === "Input"
  ).flatMap((category) =>
    category.items
      .filter(
        (item) =>
          ![
            "List",
            "Table",
            "TableLabel",
            "Radio",
            "Dropdown",
            "Date",
            "Divider",
            "Switch",
            "Checkbox",
            "Iframe",
            "Row",
          ].includes(item.controltype)
      )
      .map((item) => ({
        key: item.controltype,
        value: item.controltype,
      }))
  );

  menuItems.sort((a, b) => a.value.localeCompare(b.value));

  const applyStylesRecursively = (control, defaultStyle, parentID = "") => {
    const defaultSty = defaultStyle.find(
      (style) => style.styleType === control.styleType
    );

    const newID = parentID
      ? `${parentID}.${control.id.split(".").pop()}`
      : control.id;

    let updatedStyles = { ...defaultSty?.style };

    if (
      control.media === "CityState" ||
      control.media === "Address" ||
      control.media === "PostalCountry"
    ) {
      updatedStyles.flexDirection = "row";
    }

    if (control.media === "AddressHeader") {
      updatedStyles.width = "20%";
    }

    if (control.media === "AddressInput") {
      updatedStyles.width = "80%";
    }

    if (control.switchoption === "AddressContainer") {
      updatedStyles.marginTop = "0px";
      updatedStyles.marginLeft = "0px";
      updatedStyles.marginRight = "0px";
      updatedStyles.marginBottom = "0px";
      updatedStyles.paddingTop = "0px";

      updatedStyles.paddingRight = "0px";
      updatedStyles.paddingBottom = "0px";
      updatedStyles.paddingLeft = "0px";
    }

    const updatedControl = {
      ...control,
      id: newID,
      styles: JSON.stringify(updatedStyles),
    };

    if (updatedControl.control && updatedControl.control.length > 0) {
      updatedControl.control = updatedControl.control.map(
        (child, childIndex) => {
          const childNewID = `${newID}.${childIndex + 1}`;
          return applyStylesRecursively(
            { ...child, id: childNewID },
            defaultStyle,
            newID
          );
        }
      );
    }

    return updatedControl;
  };

  const calculateNextStyleIdBlocks = () => {
    const deletedItemId = localStorage.getItem("deletedItemId");

    const deletedItemNumber = deletedItemId
      ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
      : -1;

    let maxStyleId = 0;

    const findMaxStyleId = (node) => {
      const styleIdRegex = /([a-zA-Z]+)(\d*)/;
      if (typeof node.styleid === "string") {
        const matches = node.styleid.match(styleIdRegex);
        if (matches && matches[1] === "agrfb") {
          const currentId = parseInt(matches[2]);
          if (!isNaN(currentId) && currentId > maxStyleId) {
            maxStyleId = currentId;
          }
        }
      }

      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          findMaxStyleId(childNode);
        }
      }
    };

    treeData[0].Source[0].control.forEach((rootNode) => {
      findMaxStyleId(rootNode);
    });

    const nextStyleId =
      deletedItemNumber > maxStyleId ? deletedItemNumber + 1 : maxStyleId + 1;

    return `agrfb${nextStyleId}`;
  };

  function findMediaCity(treeData) {
    const foundNodes = [];

    function searchTree(node) {
      if (node.media === "City") {
        foundNodes.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodes;
  }

  const foundNodes = findMediaCity(AddressJSON[0]);

  function findMediaStreet2(treeData) {
    const foundNodesStreet2 = [];

    function searchTree(node) {
      if (node.media === "Street2") {
        foundNodesStreet2.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodesStreet2;
  }

  const foundNodesStreet2 = findMediaStreet2(AddressJSON[0]);

  const handleCheckboxChangeStreet2 = (event) => {
    const isChecked = event.target.checked;
    // setCheckboxCheckedStreet2(isChecked);
    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "Street2") {
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "Street2" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "Street2" && control.controltype === "TextBox"
        );

        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );
        const newStyleId = `agrfb${styleIdNumber + index}`;

        if (!labelExists || !textBoxExists) {
          const newControls = [];
          const findContainerSec = (controlArray) => {
            for (let control of controlArray) {
              if (control.media === "ContainerStreet2") {
                return control;
              }
              if (control.control && control.control.length > 0) {
                const foundContainer = findContainerSec(control.control);
                if (foundContainer) return foundContainer;
              }
            }
            return null;
          };
          const containerSec1 = findContainerSec(selectedControl.control);

          if (!labelExists) {
            const newLabel = {
              ...foundNodesStreet2[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);

            newControls.push(updatedLabel);
          }

          const newStyleIDn = newStyleId + 1;

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesStreet2[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );

            newControls.push(updatedTextBox);
          }

          const containerSec = findContainerSec(selectedControl.control);

          if (containerSec) {
            containerSec.control = [...containerSec.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  function findMediaStreet1(treeData) {
    const foundNodesStreet1 = [];

    function searchTree(node) {
      if (node.media === "Street1") {
        foundNodesStreet1.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodesStreet1;
  }

  const foundNodesStreet1 = findMediaStreet1(AddressJSON[0]);

  const handleCheckboxChangeStreet1 = (event) => {
    const isChecked = event.target.checked;
    // setCheckboxCheckedStreet1(isChecked);
    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "Street1") {
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "Street1" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "Street1" && control.controltype === "TextBox"
        );

        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );
        const newStyleId = `agrfb${styleIdNumber + index}`;
        const findContainerSec = (controlArray) => {
          for (let control of controlArray) {
            if (control.media === "ContainerStreet1") {
              return control;
            }
            if (control.control && control.control.length > 0) {
              const foundContainer = findContainerSec(control.control);
              if (foundContainer) return foundContainer;
            }
          }
          return null;
        };

        const containerSec1 = findContainerSec(selectedControl.control);

        if (!labelExists || !textBoxExists) {
          const newControls = [];

          if (!labelExists) {
            const newLabel = {
              ...foundNodesStreet1[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);

            newControls.push(updatedLabel);
          }

          const newStyleIDn = newStyleId + 1;

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesStreet1[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );

            newControls.push(updatedTextBox);
          }

          const containerSec = findContainerSec(selectedControl.control);

          if (containerSec) {
            containerSec.control = [...containerSec.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  function findMediaStreetLine2(treeData) {
    const foundNodesStreet2 = [];

    function searchTree(node) {
      if (node.media === "State") {
        foundNodesStreet2.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodesStreet2;
  }

  const foundNodesState = findMediaStreetLine2(AddressJSON[0]);

  // const handleCheckboxChangeState = (event) => {
  //   const isChecked = event.target.checked;
  //   setCheckboxCheckedState(isChecked);
  //   setTreeData((prevTreeData) => {
  //     if (!prevTreeData) {
  //       console.error("prevTreeData is undefined");
  //       return prevTreeData;
  //     }

  //     const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

  //     const findControlById = (controlArray, id) => {
  //       for (let control of controlArray) {
  //         if (control.id === id) {
  //           return control;
  //         }
  //         if (control.control && control.control.length > 0) {
  //           const foundControl = findControlById(control.control, id);
  //           if (foundControl) return foundControl;
  //         }
  //       }
  //       return null;
  //     };

  //     const selectedControl = findControlById(
  //       updatedTreeData[0].Source[0].control,
  //       selectedItem.id
  //     );

  //     if (!selectedControl) return prevTreeData;

  //     if (!isChecked) {
  //       const removeControls = (controlArray) => {
  //         return controlArray.filter((control) => {
  //           if (control.media === "State") {
  //             return false;
  //           }
  //           if (control.control && control.control.length > 0) {
  //             control.control = removeControls(control.control);
  //           }
  //           return true;
  //         });
  //       };

  //       selectedControl.control = removeControls(selectedControl.control);
  //     } else {
  //       const labelExists = selectedControl.control.some(
  //         (control) =>
  //           control.media === "State" && control.controltype === "Label"
  //       );
  //       const textBoxExists = selectedControl.control.some(
  //         (control) =>
  //           control.media === "State" && control.controltype === "TextBox"
  //       );

  //       const index = 0;
  //       const currentMaxStyleId = calculateNextStyleIdBlocks();
  //       const styleIdNumber = parseInt(
  //         currentMaxStyleId.replace(/[a-zA-Z]/g, "")
  //       );
  //       const newStyleId = `agrfb${styleIdNumber + index}`;

  //       const findContainerSec = (controlArray) => {
  //         for (let control of controlArray) {
  //           if (control.media === "ContainerThird") {
  //             return control;
  //           }
  //           if (control.control && control.control.length > 0) {
  //             const foundContainer = findContainerSec(control.control);
  //             if (foundContainer) return foundContainer;
  //           }
  //         }
  //         return null;
  //       };
  //       const containerSec1 = findContainerSec(selectedControl.control);
  //       if (!labelExists || !textBoxExists) {
  //         const newControls = [];

  //         if (!labelExists) {
  //           const newLabel = {
  //             ...foundNodesState[0],
  //             id: `${containerSec1.id}.1`,
  //             styleid: newStyleId,
  //           };

  //           const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);

  //           newControls.push(updatedLabel);
  //         }

  //         const newStyleIDn = newStyleId + 1;

  //         if (!textBoxExists) {
  //           const newTextBox = {
  //             ...foundNodesState[1],
  //             id: `${containerSec1.id}.2`,
  //             styleid: newStyleIDn,
  //           };

  //           const updatedTextBox = applyStylesRecursively(
  //             newTextBox,
  //             defaultStyle
  //           );

  //           newControls.push(updatedTextBox);
  //         }

  //         const containerSec = findContainerSec(selectedControl.control);

  //         if (containerSec) {
  //           containerSec.control = [...containerSec.control, ...newControls];
  //         }
  //       }
  //     }

  //     return updatedTreeData;
  //   });
  // };

  const handleCheckboxChangeState = (event) => {
    const isChecked = event.target.checked;
    // setCheckboxCheckedState(isChecked);

    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      const findContainerSec = (controlArray) => {
        for (let control of controlArray) {
          if (control.media === "ContainerThird") {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundContainer = findContainerSec(control.control);
            if (foundContainer) return foundContainer;
          }
        }
        return null;
      };

      const containerSec1 = findContainerSec(selectedControl.control);

      // If container is found, apply display flex or none based on isChecked
      if (containerSec1) {
        containerSec1.styles = JSON.stringify({
          ...JSON.parse(containerSec1.styles || "{}"),
          display: isChecked ? "flex" : "none", // Toggle between flex and none
        });
      }

      if (!isChecked) {
        // Remove controls when unchecked
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "State") {
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        // Add controls when checked
        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "State" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "State" && control.controltype === "TextBox"
        );

        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );
        const newStyleId = `agrfb${styleIdNumber + index}`;

        if (!labelExists || !textBoxExists) {
          const newControls = [];

          if (!labelExists) {
            const newLabel = {
              ...foundNodesState[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);
            newControls.push(updatedLabel);
          }

          const newStyleIDn = newStyleId + 1;

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesState[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );
            newControls.push(updatedTextBox);
          }

          if (containerSec1) {
            containerSec1.control = [...containerSec1.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  function findMediaPostal(treeData) {
    const foundNodes = [];

    function searchTree(node) {
      if (node.media === "Postal") {
        foundNodes.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodes;
  }

  const foundNodesPostal = findMediaPostal(AddressJSON[0]);

  const handleCheckboxChangePostal = (event) => {
    const isChecked = event.target.checked;
    // setCheckboxCheckedPostal(isChecked);
    setTreeData((prevTreeData) => {
      if (!prevTreeData) {
        return prevTreeData;
      }

      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const findControlById = (controlArray, id) => {
        for (let control of controlArray) {
          if (control.id === id) {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundControl = findControlById(control.control, id);
            if (foundControl) return foundControl;
          }
        }
        return null;
      };

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) return prevTreeData;

      const findContainerSec = (controlArray) => {
        for (let control of controlArray) {
          if (control.media === "ContainerPostal") {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundContainer = findContainerSec(control.control);
            if (foundContainer) return foundContainer;
          }
        }
        return null;
      };

      const containerSec = findContainerSec(selectedControl.control);

      if (!isChecked) {
        const removeControls = (controlArray) => {
          return controlArray.filter((control) => {
            if (control.media === "Postal") {
              if (containerSec) {
                containerSec.styles = JSON.stringify({
                  ...JSON.parse(containerSec.styles),
                  display: "none",
                });
              }
              return false;
            }
            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(selectedControl.control);
      } else {
        if (containerSec) {
          containerSec.styles = JSON.stringify({
            ...JSON.parse(containerSec.styles),
            display: "flex",
            marginRight: "10px",
          });
        }

        const labelExists = selectedControl.control.some(
          (control) =>
            control.media === "Postal" && control.controltype === "Label"
        );
        const textBoxExists = selectedControl.control.some(
          (control) =>
            control.media === "Postal" && control.controltype === "TextBox"
        );

        if (!labelExists || !textBoxExists) {
          const newControls = [];
          const index = 0;
          const currentMaxStyleId = calculateNextStyleIdBlocks();
          const styleIdNumber = parseInt(
            currentMaxStyleId.replace(/[a-zA-Z]/g, "")
          );
          const newStyleId = `agrfb${styleIdNumber + index}`;
          const newStyleIDn = `agrfb${styleIdNumber + index + 1}`;

          const containerSec1 = findContainerSec(selectedControl.control);

          if (!labelExists) {
            const newLabel = {
              ...foundNodesPostal[0],
              id: `${containerSec1.id}.1`,
              styleid: newStyleId,
            };

            const updatedLabel = applyStylesRecursively(newLabel, defaultStyle);
            newControls.push(updatedLabel);
          }

          if (!textBoxExists) {
            const newTextBox = {
              ...foundNodesPostal[1],
              id: `${containerSec1.id}.2`,
              styleid: newStyleIDn,
            };

            const updatedTextBox = applyStylesRecursively(
              newTextBox,
              defaultStyle
            );
            newControls.push(updatedTextBox);
          }

          if (containerSec) {
            containerSec.control = [...containerSec.control, ...newControls];
          }
        }
      }

      return updatedTreeData;
    });
  };

  const addCountryJSONToContainer = (controlArray, mediaToAdd, countryJSON) => {
    return controlArray.map((control) => {
      if (control.media === mediaToAdd && control.control.length === 0) {
        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );

        const styledCountryJSON = countryJSON.map((json, index) => {
          const newStyleId = `agrfb${styleIdNumber + index}`;
          return {
            ...applyStylesRecursively(json, defaultStyle),
            id: `${control.id}.${index + 1}`,
            styleid: newStyleId,
          };
        });

        control.control.push(...styledCountryJSON);
      }

      if (control.control && control.control.length > 0) {
        control.control = addCountryJSONToContainer(
          control.control,
          mediaToAdd,
          countryJSON
        );
      }
      return control;
    });
  };

  const handleCheckboxChangeCountry = (event) => {
    const isChecked = event.target.checked;
    // setCheckboxCheckedCountry(isChecked);

    const findControlById = (controlArray, id) => {
      for (let control of controlArray) {
        if (control.id === id) {
          return control;
        }
        if (control.control && control.control.length > 0) {
          const foundControl = findControlById(control.control, id);
          if (foundControl) return foundControl;
        }
      }
      return null;
    };

    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const selectedControl = findControlById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (!selectedControl) {
        return prevTreeData;
      }

      const findContainerCountry = (controlArray) => {
        for (let control of controlArray) {
          if (control.media === "ContainerCountry") {
            return control;
          }
          if (control.control && control.control.length > 0) {
            const foundContainer = findContainerCountry(control.control);
            if (foundContainer) return foundContainer;
          }
        }
        return null;
      };

      const containerCountry = findContainerCountry(selectedControl.control);

      if (isChecked) {
        if (containerCountry) {
          containerCountry.styles = JSON.stringify({
            ...JSON.parse(containerCountry.styles || "{}"),
            display: "flex",
          });
        }

        selectedControl.control = addCountryJSONToContainer(
          selectedControl.control,
          "ContainerCountry",
          CountryJSON
        );
      } else {
        if (containerCountry) {
          containerCountry.styles = JSON.stringify({
            ...JSON.parse(containerCountry.styles || "{}"),
            display: "none",
          });
        }

        const removeControls = (controlArray, mediaToRemove) => {
          return controlArray.filter((control) => {
            if (control.media === mediaToRemove) {
              return false;
            }

            if (control.control && control.control.length > 0) {
              control.control = removeControls(control.control, mediaToRemove);
            }
            return true;
          });
        };

        selectedControl.control = removeControls(
          selectedControl.control,
          "Country"
        );
      }

      return updatedTreeData;
    });
  };

  const updateTreeDatanewValueAddressCountryTextbox = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressCountryTextbox(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressCountryTextbox = (
    controls,
    selectedItemID,
    newValue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryTextbox(
            control.control,
            newValue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressCountryTextbox(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressCountryTextbox = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "CountryTextbox") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryTextbox(
            control.control,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const findAddressCountryTextboxData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsCountryTextbox(
          source.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsCountryTextbox = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressCountryTextboxControl = findAddressCountryTextboxControl(
          control.control
        );
        if (addressCountryTextboxControl) {
          return addressCountryTextboxControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsCountryTextbox(
          control.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressCountryTextboxControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "CountryTextbox") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressCountryTextboxControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleCountryChange = (event) => {
    const newValue = event.target.value;
    setSelectedCountry(newValue);
    const updatedTreeDataAddressCountryTextbox =
      updateTreeDatanewValueAddressCountryTextbox(
        treeData,
        selectedItemID,
        newValue
      );
    setTreeData(updatedTreeDataAddressCountryTextbox);
  };

  //--------------------editedData DropDownLabel---------------------

  const updateTreeDatanewValueAddressDropDownLabel = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressDropDownLabel(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressDropDownLabel = (
    controls,
    selectedItemID,
    newValue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressDropDownLabel(
            control.control,
            newValue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressDropDownLabel(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressDropDownLabel = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "DropDownLabel") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressDropDownLabel(
            control.control,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const findAddressDropDownLabelData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsDropDownLabel(
          source.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsDropDownLabel = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressDropDownLabelControl = findAddressDropDownLabelControl(
          control.control
        );
        if (addressDropDownLabelControl) {
          return addressDropDownLabelControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsDropDownLabel(
          control.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressDropDownLabelControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "DropDownLabel") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressDropDownLabelControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChangeAddressDropDownLabel = (event) => {
    const newValue = event.target.value;
    setEditedDataDropDownLabel(newValue);
    const updatedTreeDataAddressDropDownLabel =
      updateTreeDatanewValueAddressDropDownLabel(
        treeData,
        selectedItemID,
        newValue
      );
    setTreeData(updatedTreeDataAddressDropDownLabel);
  };

  useEffect(() => {
    if (selectedItem?.controltype === "DropDown") {
      const initialDataDropDownLabel = findAddressDropDownLabelData(
        treeData,
        selectedItemID
      );
      setEditedDataDropDownLabel(initialDataDropDownLabel);
    }
  }, [treeData, selectedItemID, selectedItem]);

  // heading

  const updateTreeDatanewValueAddressHeading = (
    treeData,
    selectedItemID,
    newValue,
    textAlign,
    fontSize
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressHeading(
            source.control,
            selectedItemID,
            newValue,
            fontSize
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressHeading = (
    controls,
    selectedItemID,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressHeading(
            control.control,
            selectedItemID,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressHeading = (
    controls,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "Heading") {
        let styles = control.styles ? JSON.parse(control.styles) : {};

        if (fontSize) styles.fontSize = fontSize;

        return {
          ...control,
          data: newValue,
          styles: JSON.stringify(styles),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const updateTreeDatanewValueAddressSubHeading = (
    treeData,
    selectedItemID,
    newValue,
    textAlign,
    fontSize
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressSubHeading(
            source.control,
            selectedItemID,
            newValue,
            fontSize
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressSubHeading = (
    controls,
    selectedItemID,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressSubHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressSubHeading(
            control.control,
            selectedItemID,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressSubHeading = (
    controls,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "SubHeader") {
        let styles = control.styles ? JSON.parse(control.styles) : {};

        if (fontSize) styles.fontSize = fontSize;

        return {
          ...control,
          data: newValue,
          styles: JSON.stringify(styles),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressSubHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const findNodeById = (controlArray, id) => {
    if (!Array.isArray(controlArray)) {
      return null;
    }

    for (const control of controlArray) {
      if (control.id === id) {
        return control; // Return the node that matches selectedItem.id
      }
      if (control.control && control.control.length > 0) {
        const found = findNodeById(control.control, id); // Recursively search for the node
        if (found) return found;
      }
    }
    return null;
  };

  const matchingNode = findNodeById(
    treeData[0].Source[0].control,
    selectedItem.id
  );

  function collectStylesofcontainer(node, result = []) {
    if (!node) return result; // Check if the node is valid

    if (node.checkposition === "Heading") {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    // Recursively call for child nodes if they exist
    if (Array.isArray(node.control) && node.control.length > 0) {
      node.control.forEach((child) => collectStylesofcontainer(child, result));
    }

    return result;
  }

  function collectStylesofcontainerforAlignment(node, result = []) {
    if (!node) return result; // Check if the node is valid

    if (
      node.checkposition === "SubHeader" ||
      node.checkposition === "Heading"
    ) {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    // Recursively call for child nodes if they exist
    if (Array.isArray(node.control) && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStylesofcontainerforAlignment(child, result)
      );
    }

    return result;
  }

  const stylesofcontainer = matchingNode
    ? collectStylesofcontainer(matchingNode)
    : [];

  const stylesofcontainerForAlignment = matchingNode
    ? collectStylesofcontainerforAlignment(matchingNode)
    : [];

  function applyFontColorToStylesconatiner(stylesArray, alignment) {
    stylesArray.forEach((style) => {
      let styles;
      try {
        styles = JSON.parse(style.styles); // Parse styles safely
      } catch (e) {
        console.error("Failed to parse styles:", style.styles);
        return; // Skip to the next style if parsing fails
      }
      styles.textAlign = alignment;

      console.log("styles.textAlign", styles.textAlign);
      setAlignMent(styles.textAlign);
      updateCustomStyle(style.styleid, styles);
    });
  }

  const applyFontSizeToContainer = (stylesArray, FontSize) => {
    stylesArray.forEach((style) => {
      let styles;
      try {
        styles = JSON.parse(style.styles); // Parse styles safely
      } catch (e) {
        console.error("Failed to parse styles:", style.styles);
        return; // Skip to the next style if parsing fails
      }
      styles.fontSize = FontSize; // Apply font size
      setFontSize(FontSize); // Update the font size state
      updateCustomStyle(style.styleid, styles); // Update the style in the DOM/state
    });
  };

  const handleAlignmentChangeHeading = (alignment) => {
    applyFontColorToStylesconatiner(stylesofcontainerForAlignment, alignment);
  };

  useEffect(() => {
    // Assuming stylesArray is updated elsewhere, we parse the first element's textAlign if it exists
    if (
      stylesofcontainerForAlignment &&
      stylesofcontainerForAlignment.length > 0
    ) {
      let styles;
      try {
        styles = JSON.parse(stylesofcontainerForAlignment[0].styles); // Parse styles safely
        if (styles.textAlign) {
          setAlignMent(styles.textAlign); // Set the alignment state
        }
      } catch (e) {
        console.error(
          "Failed to parse styles:",
          stylesofcontainerForAlignment[0].styles
        );
      }
    }
  }, [stylesofcontainerForAlignment]);

  useEffect(() => {
    // Assuming stylesArray is updated elsewhere, we parse the first element's textAlign if it exists
    if (stylesofcontainer && stylesofcontainer.length > 0) {
      let styles;
      try {
        styles = JSON.parse(stylesofcontainer[0].styles); // Parse styles safely
        if (styles.fontSize) {
          setFontSize(styles.fontSize); // Set the alignment state
        }
      } catch (e) {
        console.error("Failed to parse styles:", stylesofcontainer[0].styles);
      }
    }
  }, [stylesofcontainer]);

  const handleFontSizeChangeHeading = (fontSize) => {
    applyFontSizeToContainer(stylesofcontainer, fontSize);
  };

  const findAddressHeadingData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsHeading(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsHeading = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressHeadingControl = findAddressHeadingControl(
          control.control
        );
        if (addressHeadingControl) {
          return addressHeadingControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsHeading(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressHeadingControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "Heading") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressHeadingControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const findAddressSubHeadingData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsSubHeading(
          source.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsSubHeading = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressSubHeadingControl = findAddressSubHeadingControl(
          control.control
        );
        if (addressSubHeadingControl) {
          return addressSubHeadingControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsSubHeading(
          control.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressSubHeadingControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "SubHeader") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressSubHeadingControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChangeAddressHeading = (event) => {
    const newValue = event.target.value;
    setEditedDataHeading(newValue);
    const updatedTreeDataAddressHeading = updateTreeDatanewValueAddressHeading(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeDataAddressHeading);
  };

  const handleFieldChangeAddressSubHeading = (event) => {
    const newValue = event.target.value;
    setEditedDataSubHeading(newValue);
    const updatedTreeDataAddressSubHeading =
      updateTreeDatanewValueAddressSubHeading(
        treeData,
        selectedItemID,
        newValue
      );
    setTreeData(updatedTreeDataAddressSubHeading);
  };

  // const parentID = "0"

  const findMaxNumber = (node, controlType) => {
    let maxNumber = 0;
    if (node.control) {
      for (const child of node.control) {
        if (child.type === controlType) {
          const number = parseInt(child.name.replace(controlType, ""));
          if (!isNaN(number) && number > maxNumber) {
            maxNumber = number;
          }
        }
        const childMaxNumber = findMaxNumber(child, controlType);
        if (childMaxNumber > maxNumber) {
          maxNumber = childMaxNumber;
        }
      }
    }
    return maxNumber;
  };

  const handleDuplicate = () => {
    const findNodeById = (controlArray, id) => {
      if (!Array.isArray(controlArray)) {
        return null;
      }

      for (const control of controlArray) {
        if (control.id === id) {
          return control; // Return the node that matches selectedItem.id
        }
        if (control.control && control.control.length > 0) {
          const found = findNodeById(control.control, id); // Recursively search for the node
          if (found) return found;
        }
      }
      return null;
    };

    // Helper function to recursively update IDs and styleids for all nested controls
    const updateChildIdsAndStyleIds = (controlArray, parentId, parentNode) => {
      controlArray.forEach((control, index) => {
        control.id = `${parentId}.${index + 1}`; // Generate a new unique ID
        control.styleid = generateUniqueStyleId(parentNode); // Generate a unique styleid for each child
        if (control.control && control.control.length > 0) {
          updateChildIdsAndStyleIds(control.control, control.id, control); // Recurse for nested controls
        }
      });
    };

    // Find the node that matches selectedItem.id in treeData
    const matchingNode = findNodeById(
      treeData[0].Source[0].control,
      selectedItem.id
    );

    const newItem = JSON.parse(JSON.stringify(matchingNode));

    const parentID = "0"; // Assuming the duplicated control will be a direct child of Root
    newItem.id = generateUniqueId(parentID); // Generate a new ID for the duplicated item
    const parentItem = findItemById(treeData, parentID); // Find parent to generate styleid
    newItem.styleid = generateUniqueStyleId(parentItem);

    const baseName = selectedItem.name.replace(/\d+$/, ""); // Base name without numbers
    const maxNumber = findMaxNumber(parentItem, baseName); // Find max number to rename duplicate
    const duplicatedName = `${baseName}${maxNumber + 1}`; // New name for duplicate
    newItem.name = duplicatedName;

    // Recursively update IDs and styleids for all children in the duplicated item
    updateChildIdsAndStyleIds(newItem.control, newItem.id, newItem);

    // Add the duplicated item to treeData
    setTreeData((prevTreeData) => {
      const updatedTreeData = prevTreeData.map((treeItem) => {
        if (treeItem.Source && treeItem.Source.length > 0) {
          return {
            ...treeItem,
            Source: treeItem.Source.map((sourceItem) => {
              if (sourceItem.type === "Root") {
                return {
                  ...sourceItem,
                  control: [...sourceItem.control, newItem], // Add the duplicated item
                };
              }
              return sourceItem;
            }),
          };
        }
        return treeItem;
      });
      return updatedTreeData;
    });
  };

  // Helper function to recursively generate unique IDs and styleids for nested children
  const updateChildIdsAndStyleIds = (controlArray, parentId, parentNode) => {
    controlArray.forEach((control, index) => {
      control.id = `${parentId}.${index + 1}`; // New ID based on parent ID and index
      control.styleid = generateUniqueStyleId(parentNode); // Generate new styleid based on parent node
      if (control.control && control.control.length > 0) {
        updateChildIdsAndStyleIds(control.control, control.id, control); // Recurse for nested controls
      }
    });
  };

  // Helper function to recursively generate unique IDs for nested children
  const updateChildIds = (controlArray, parentId) => {
    controlArray.forEach((control, index) => {
      control.id = `${parentId}.${index + 1}`; // New ID based on parent ID and index
      if (control.control && control.control.length > 0) {
        updateChildIds(control.control, control.id); // Recurse for nested controls
      }
    });
  };

  const generateUniqueId = (parentId) => {
    const parentItem = findItemById(treeData, parentId);
    if (parentItem) {
      const childIndexes = parentItem.control.map((child) => {
        const lastPart = child.id.split(".").pop();
        return isNaN(lastPart) ? 0 : parseInt(lastPart);
      });
      const maxChildIndex = Math.max(0, ...childIndexes);

      return `${parentId}.${maxChildIndex + 1}`;
    }
    return `${parentId}.1`;
  };

  const generateUniqueStyleId = (parentNode) => {
    let maxStyleId = 0;

    const findMaxStyleId = (node) => {
      const styleIdRegex = /([a-zA-Z]+)(\d*)/;
      if (typeof node.styleid === "string") {
        const matches = node.styleid.match(styleIdRegex);
        if (matches && matches[1] === "agrfb") {
          const currentId = parseInt(matches[2]);
          if (!isNaN(currentId) && currentId > maxStyleId) {
            maxStyleId = currentId;
          }
        }
      }

      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          findMaxStyleId(childNode);
        }
      }
    };

    if (parentNode && parentNode.control) {
      for (const childNode of parentNode.control) {
        findMaxStyleId(childNode);
      }
    }

    return `agrfb${maxStyleId + 1}`;
  };

  const findItemById = (data, id) => {
    for (const rootItem of data) {
      for (const sourceItem of rootItem.Source) {
        const foundItem = findItemRecursive(sourceItem, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  };

  const findItemRecursive = (item, id) => {
    if (item.id === id) {
      return item;
    }

    if (item.control) {
      for (const controlItem of item.control) {
        const foundItem = findItemRecursive(controlItem, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }

    return null;
  };

  useEffect(() => {
    if (selectedItem?.controltype === "Heading") {
      const initialDataHeading = findAddressHeadingData(
        treeData,
        selectedItemID
      );
      setEditedDataHeading(initialDataHeading);
    }
  }, [treeData, selectedItemID, selectedItem]);

  useEffect(() => {
    if (selectedItem?.controltype === "Heading") {
      const initialDataHeading = findAddressSubHeadingData(
        treeData,
        selectedItemID
      );
      setEditedDataSubHeading(initialDataHeading);
    }
  }, [treeData, selectedItemID, selectedItem]);

  const updateTreeDataForLabelType = (
    treeData,
    selectedItemID,
    newValue,
    labelType
  ) => {
    return treeData.map((item) => ({
      ...item,
      Source: item.Source.map((source) => ({
        ...source,
        control: updateControlsForLabelType(
          source.control,
          selectedItemID,
          newValue,
          labelType
        ),
      })),
    }));
  };

  const updateControlsForLabelType = (
    controls,
    selectedItemID,
    newValue,
    labelType
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinLabelType(
            control.control,
            newValue,
            labelType
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsForLabelType(
            control.control,
            selectedItemID,
            newValue,
            labelType
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinLabelType = (controls, newValue, labelType) => {
    return controls.map((control) => {
      if (control.checkposition === labelType) {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinLabelType(
            control.control,
            newValue,
            labelType
          ),
        };
      }

      return control;
    });
  };

  const findLabelData = (treeData, selectedItemID, labelType) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsForLabelType(
          source.control,
          selectedItemID,
          labelType
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsForLabelType = (controls, selectedItemID, labelType) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const labelControl = findLabelControl(control.control, labelType);
        if (labelControl) {
          return labelControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsForLabelType(
          control.control,
          selectedItemID,
          labelType
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findLabelControl = (controls, labelType) => {
    for (let control of controls) {
      if (control.checkposition === labelType) {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findLabelControl(control.control, labelType);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChange = (event, labelType, setEditedData, setTreeData) => {
    const newValue = event.target.value;
    setEditedData(newValue);
    const updatedTreeData = updateTreeDataForLabelType(
      treeData,
      selectedItemID,
      newValue,
      labelType
    );
    setTreeData(updatedTreeData);
  };

  const LABEL_TYPES = {
    Header: "AddressHeader",
    AddressStreet1: "AddressStreet1",
    AddressStreet2: "AddressStreet2",
    CityLabel: "CityLabel",
    StateLabel: "StateLabel",
    PostalLabel: "PostalLabel",
    CountryLabel: "CountryLabel",
    CountryTextbox: "CountryTextbox",
    MultipleChoiceLabel: "MultipleChoiceLabel",
    SingleChoiceLabel: "SingleChoiceLabel",
    FullName: "FullName",
    FirstName: "FirstName",
    LastName: "LastName",
    PrefixLabel: "PrefixLabel",
    MiddleNameLabel: "MiddleNameLabel",
    EmailLabel: "EmailLabel",
    PhoneNumberLabel: "PhoneNumberLabel",
    ShortTextLabel: "ShortTextLabel",
    ShortTextTextBox: "ShortTextTextBox",
    LongTextLabel: "LongTextLabel",
    LongTextTextbox: "LongTextTextbox",
    DropDownLabel: "DropDownLabel",
    DateLabel: "DateLabel",
    RatingLabel: "RatingLabel",
    AddressHeaderContainer: "AddressHeaderContainer",
    AddressSubContainer: "AddressSubContainer",
    StreetAddress1Container: "StreetAddress1Container",
    AddressStreet1Textbox: "AddressStreet1Textbox",
    StreetAddressContainer: "StreetAddressContainer",
    AddressStreet2Textbox: "AddressStreet2Textbox",
    CityStateContainer: "CityStateContainer",
    CityContainer: "CityContainer",
    CityTextbox: "CityTextbox",
    StateContainer: "StateContainer",
    StateTextbox: "StateTextbox",
    PostalCountryContainer: "PostalCountryContainer",
    PostalContainer: "PostalContainer",
    PostalTextbox: "PostalTextbox",
    CountryContainer: "CountryContainer",
    CountryTextbox: "CountryTextbox",
    DateField: "DateField",
    DropDownContainer: "DropDownContainer",
    DropDown: "DropDown",
    CheckBoxMainContainer: "CheckBoxMainContainer",
    ShortTextTextBox: "ShortTextTextBox",
    LongTextTextbox: "LongTextTextbox",
  };

  const initializeFieldData = (
    treeData,
    selectedItemID,
    labelType,
    setData,
    setCheckbox
  ) => {
    const data = findLabelData(treeData, selectedItemID, labelType);
    setData(data);
    if (setCheckbox) {
      setCheckbox(data);
    }
  };

  useEffect(() => {
    if (!selectedItem) return;

    const controlType = selectedItem.controltype;

    switch (controlType) {
      case "Address":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.Header,
          setEditedDataHeader
        );

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.AddressStreet1,
          setEditedDataStreet1
          // setCheckboxCheckedStreet1
        );

        const dataStreet1Label = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.AddressStreet1
        );
        setEditedDataStreet1Present(dataStreet1Label !== "");

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.AddressStreet2,
          setEditedDataStreet2
          // setCheckboxCheckedStreet2
        );

        const dataStreet2Label = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.AddressStreet2
        );
        setEditedDataStreet2Present(dataStreet2Label !== "");

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.CityLabel,
          setEditedDataCityLabel
          // setCheckboxCheckedCity
        );
        const dataCityLabel = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.CityLabel
        );
        setEditedDataCityLabelPresent(dataCityLabel !== "");

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.StateLabel,
          setEditedDataStateLabel
          // setCheckboxCheckedState
        );

        const dataStateLabel = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.StateLabel
        );
        setEditedDataStateLabelPresent(dataStateLabel !== "");

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.PostalLabel,
          setEditedDataPostalLabel
          // setCheckboxCheckedPostal
        );
        const dataPostalLabel = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.PostalLabel
        );
        setEditedDataPostalLabelPresent(dataPostalLabel !== "");

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.CountryLabel,
          setEditedDataCountryLabel
          // setCheckboxCheckedCountry
        );

        const dataCountryLabel = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.CountryLabel
        );
        setIsCountryLabelPresent(dataCountryLabel !== "");

        const initialDataCountryTextbox = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.CountryTextbox
        );
        setSelectedCountry(initialDataCountryTextbox);
        break;

      case "MultipleChoice":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.MultipleChoiceLabel,
          setEditedDataCheckboxHeading
        );
        break;

      case "SingleChoice":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.SingleChoiceLabel,
          setEditedDataSingleChoiceLabel
        );
        break;

      case "PhoneNumber":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.PhoneNumberLabel,
          setEditedDataPhoneLabel
        );
        break;

      case "DropDown":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.DropDownLabel,
          setEditedDataDropDownLabel
        );
        break;

      case "Email":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.EmailLabel,
          setEditedDataEmailLabel
        );
        break;

      case "ShortText":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.ShortTextLabel,
          setEditedDataShortTextLabel
        );
        break;

      case "LongText":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.LongTextLabel,
          setEditedDataLongTextLabel
        );
        break;
      case "FullName":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.FullName,
          setEditedDataFullName
        );
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.FirstName,
          setEditedDataFirstName
        );
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.LastName,
          setEditedDataLastName
        );

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.PrefixLabel,
          setEditedDataPrefixLabel
        );
        const dataPrefixLabel = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.PrefixLabel
        );
        setIsPrefixLabelPresent(dataPrefixLabel !== "");

        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.MiddleNameLabel,
          setEditedDataMiddleNameLabel
        );
        const dataMiddleNameLabel = findLabelData(
          treeData,
          selectedItemID,
          LABEL_TYPES.MiddleNameLabel
        );
        setIsMiddleNameLabelPresent(dataMiddleNameLabel !== "");
        break;

      case "DateBlock":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.DateLabel,
          setEditedDataDateLabel
        );
        break;
      case "RatingBlock":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.RatingLabel,
          setEditedDataRatingLabel
        );
        break;

      default:
        break;
    }
  }, [treeData, selectedItemID, selectedItem]);

  // -------------------------

  const handleSwitchChangeFullName = () => {
    setIsRequiredFullName(!isRequiredFullName);
    const updatedFirstNameLabel = !isRequiredFullName
      ? `${editedDataFirstName} *`
      : editedDataFirstName.replace(" *", "");
    const updatedLastNameLabel = !isRequiredFullName
      ? `${editedDataLastName} *`
      : editedDataLastName.replace(" *", "");
    setEditedDataFirstName(updatedFirstNameLabel);
    setEditedDataLastName(updatedLastNameLabel);
    const updatedTreeDataFullName = updateTreeDatanewValueFullName(
      treeData,
      selectedItemID,
      updatedFirstNameLabel,
      updatedLastNameLabel
    );
    setTreeData(updatedTreeDataFullName);
  };

  const updateTreeDatanewValueFullName = (
    treeData,
    selectedItemID,
    updatedFirstNameLabel,
    updatedLastNameLabel
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueFullName(
            source.control,
            selectedItemID,
            updatedFirstNameLabel,
            updatedLastNameLabel
          ),
        })),
      };
    });
  };

  const updateControlsnewValueFullName = (
    controls,
    selectedItemID,
    updatedFirstNameLabel,
    updatedLastNameLabel
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinFullName(
            control.control,
            updatedFirstNameLabel,
            updatedLastNameLabel
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueFullName(
            control.control,
            selectedItemID,
            updatedFirstNameLabel,
            updatedLastNameLabel
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinFullName = (
    controls,
    updatedFirstNameLabel,
    updatedLastNameLabel
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "FirstName") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedFirstNameLabel,
          styles: JSON.stringify(styles),
        };
      }
      if (control.checkposition === "LastName") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedLastNameLabel,
          styles: JSON.stringify(styles),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinFullName(
            control.control,
            updatedFirstNameLabel,
            updatedLastNameLabel
          ),
        };
      }
      return control;
    });
  };

  //---------------------------------------------------FullName----------

  const updateTreeDatanewValueAddress = (
    treeData,
    selectedItemID,
    updatedStreet1Label,
    updatedCityLabel,
    updatedStateLabel,
    updatedPostalLabel
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddress(
            source.control,
            selectedItemID,
            updatedStreet1Label,
            updatedCityLabel,
            updatedStateLabel,
            updatedPostalLabel
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddress = (
    controls,
    selectedItemID,
    updatedStreet1Label,
    updatedCityLabel,
    updatedStateLabel,
    updatedPostalLabel
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddress(
            control.control,
            updatedStreet1Label,
            updatedCityLabel,
            updatedStateLabel,
            updatedPostalLabel
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddress(
            control.control,
            selectedItemID,
            updatedStreet1Label,
            updatedCityLabel,
            updatedStateLabel,
            updatedPostalLabel
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddress = (
    controls,
    updatedStreet1Label,
    updatedCityLabel,
    updatedStateLabel,
    updatedPostalLabel
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "AddressStreet1") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedStreet1Label,
          styles: JSON.stringify(styles),
        };
      }
      if (control.checkposition === "CityLabel") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedCityLabel,
          styles: JSON.stringify(styles),
        };
      }
      if (control.checkposition === "StateLabel") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedStateLabel,
          styles: JSON.stringify(styles),
        };
      }
      if (control.checkposition === "PostalLabel") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedPostalLabel,
          styles: JSON.stringify(styles),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddress(
            control.control,
            updatedStreet1Label,
            updatedCityLabel,
            updatedStateLabel,
            updatedPostalLabel
          ),
        };
      }
      return control;
    });
  };

  // -------------------------------------------

  return (
    <>
      <Box>
        <TabsComponent
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabs={rightsidetabs}
        >
          <div
            key="1"
            className="gnr-tile-name"
            style={{ paddingTop: "0px !important" }}
          >
            <div className="titleSection-gnr">
              {selectedItem &&
                controlsVisible &&
                selectedItem.type !== "FooterContainer" &&
                selectedItem.type !== "HeaderContainer" && (
                  <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div className="inputfildtet inpt-fild-test">
                        <div>Name</div>
                        <div>
                          <input
                            className="tab-txtbox"
                            value={editedName}
                            onKeyUp={handleRenameConfirm}
                            onChange={handleEditChangeName1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {selectedItem &&
                controlsVisible &&
                selectedItem.type !== "Root" &&
                selectedItem.type !== "Container" &&
                selectedItem.styleType !== "Icon" &&
                selectedItem.type !== "FooterContainer" &&
                selectedItem.type !== "HeaderContainer" &&
                selectedItem.type !== "RadioContainer" &&
                selectedItem.type !== "Radio" &&
                selectedItem.type !== "Checkbox" &&
                selectedItem.type !== "Date" &&
                selectedItem.type !== "Table" &&
                selectedItem.type !== "List" &&
                selectedItem.type !== "TableLabel" &&
                // selectedItem.controltype !== "Row" &&
                selectedItem.type !== "Divider" &&
                selectedItem.type !== "Iframe" &&
                selectedItem.type !== "Switch" &&
                selectedItem.inputType !== "Row" &&
                !isRootItem(selectedItem, treeData) && (
                  <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div className="optns-predefnd">
                        <div className="inpt-fild-test">
                          Change Control Type :
                        </div>
                        <div style={{width:"98%"}} >
                          {selectedItem &&
                            controlsVisible &&
                            selectedItem.type !== "Root" &&
                            selectedItem.type !== "Container" &&
                            selectedItem.type !== "FooterContainer" &&
                            selectedItem.type !== "HeaderContainer" &&
                            selectedItem.type !== "Table" &&
                            selectedItem.type !== "List" &&
                            selectedItem.type !== "TableLabel" &&
                            !isRootItem(selectedItem, treeData) && (
                              <Select
                                value={selectedControlType}
                                onChange={(e) =>
                                  handleControlTypeChange(e, selectedItem.id)
                                }
                                className="inpt-cntr-unit"
                                MenuProps={{
                                  PaperProps: {
                                    className: "dt-dropdn-menu",
                                  },
                                  MenuListProps: {
                                    className: "custom-menu-list",
                                  },
                                }}
                              >
                                {menuItems.map((menuItem) => (
                                  <MenuItem
                                    key={menuItem.key}
                                    value={menuItem.value}
                                  >
                                    {menuItem.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                        </div>
                      </div>
                    </div>
                    {selectedItem &&
                      Array.isArray(treeData) &&
                      treeData.forEach((item) => {
                        const findAndLogMatchedObjects = (controlItems) => {
                          controlItems.forEach((controlItem) => {
                            if (
                              controlItem.controlName ===
                              selectedItem.controlName
                            ) {
                            }

                            if (
                              controlItem.control &&
                              controlItem.control.length > 0
                            ) {
                              findAndLogMatchedObjects(controlItem.control);
                            }
                          });
                        };

                        item.Source.forEach((sourceItem) => {
                          findAndLogMatchedObjects(sourceItem.control);
                        });
                      })}
                  </div>
                )}
            </div>

            {selectedItem &&
              controlsVisible &&
              selectedItem.type !== "Container" &&
              selectedItem.type !== "HeaderContainer" &&
              selectedItem.type !== "ContainerLayout" &&
              selectedItem.type !== "FooterContainer" &&
              selectedItem.type !== "Image" &&
              selectedItem.type !== "Divider" &&
              selectedItem.type !== "Date" &&
              selectedItem.type !== "Radio-Container" &&
              selectedItem.type !== "Switch" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.type !== "Slider" &&
              selectedItem.styleType !== "Icon" &&
              selectedItem.inputType !== "Row" &&
              selectedItem.inputType !== "Header" &&
              controlChanged !== "Divider" &&
              controlChanged !== "Image" &&
              controlChanged !== "Slider" &&
              controlChanged !== "Switch" &&
              selectedItem.htmlTag !== "table" &&
              selectedItem.htmlTag !== "thead" &&
              selectedItem.htmlTag !== "tr" &&
              !isRootItem(selectedItem, treeData) && (
                <div className="clr-schm-option">
                  <div className="drowerseparater">
                    <div className="title-set-wrap gnr-data-src">
                      {selectedItem.type === "Iframe" ? (
                        <div className="inpt-fild-test">Enter Iframe URL</div>
                      ) : (
                        <div className="inpt-fild-test">Text</div>
                      )}{" "}
                      <div className="heading-file-gnr">
                        <div className="titleSection-ifrm ">
                          <input
                            className="tab-txtbox"
                            value={editedData}
                            onChange={handleEditChange}
                            onKeyUp={
                              selectedItem.type === "TextBox" &&
                              (tabSelect === "Phone Number" ||
                                tabSelect === "Email")
                                ? handleEnterForNumber
                                : handleAgree
                            }
                          />
                          <span
                            className="iconBtn popIcn"
                            onClick={() => handlePopupClick(selectedItem.data)}
                            title="Edit title in popup"
                          >
                            {Theme.Open}
                          </span>
                        </div>
                      </div>
                      <div>
                        {isPopupVisible && (
                          <Dialog
                            open={isOpen}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="lg"
                          >
                            <div className="expre-edit-place">
                              <DialogTitle
                                className="exedit"
                                style={{ width: "400px" }}
                              >
                                Expression Editor
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </DialogTitle>

                              {selectedItem && selectedItem.type === "List" && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* <h5>List is separated by comma or enter key</h5> */}
                                </div>
                              )}
                            </div>

                            <textarea
                              className="gnrtextarea"
                              value={editedData || selectedOption}
                              onChange={handleEditChange}
                            />
                            <div className="slct-itm-nam-sty">
                              <DialogActions className="custombtn">
                                <Button
                                  onClick={handleClose}
                                  className="cancelbtn"
                                >
                                  <span>Cancel</span>
                                </Button>
                                <Button
                                  onClick={handleAgree}
                                  autoFocus
                                  className="savebtn"
                                >
                                  <span>Save</span>
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type !== "Container" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.type !== "HeaderContainer" &&
              selectedItem.type !== "ContainerLayout" &&
              selectedItem.type !== "FooterContainer" &&
              selectedItem.type !== "Divider" &&
              selectedItem.type !== "Radio-Container" &&
              selectedItem.type !== "Radio" &&
              selectedItem.styleType !== "Icon" &&
              selectedItem.type !== "List" &&
              selectedItem.type !== "Iamge" &&
              selectedItem.type !== "Table" &&
              selectedItem.type !== "Date" &&
              selectedItem.type !== "Checkbox" &&
              selectedItem.type !== "Image" &&
              selectedItem.type !== "Iframe" &&
              selectedItem.type !== "Switch" &&
              tabSelect !== "Phone Number" &&
              tabSelect !== "Email" &&
              selectedItem.type !== "Slider" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.sourceData !== "" &&
              selectedItem.tableHeader !== "tableHead" &&
              controlChanged !== "Divider" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <FormControl className="gnr-data-src">
                            <div className="inpt-fild-test">Data Source:</div>
                            <Select
                              labelId="select-field-label"
                              value={
                                (editedData && editedData.toLowerCase()) ||
                                (selectedOption && selectedOption.toLowerCase())
                              }
                              onChange={handleEditChange}
                              className="inpt-cntr-unit"
                              MenuProps={{
                                PaperProps: {
                                  className: "dt-dropdn-menu",
                                },
                                MenuListProps: {
                                  className: "custom-menu-list",
                                },
                              }}
                            >
                              {fieldNames.map((fieldName, index) => (
                                <MenuItem
                                  key={index}
                                  value={`{${fieldName.toLowerCase()}}`}
                                  className="mui_root_name"
                                >
                                  {fieldName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Date" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (selectedOption && selectedOption.toLowerCase()) ||
                            (editedData && editedData.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          <MenuItem value="xx" className="mui_root_name">
                            None
                          </MenuItem>
                          {DateField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Image" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div className="label-algnmt">
                        <div className="inpt-fild-test">Data Source:</div>
                    <div className="gnr-data-src">
                      {/* <FormControl> */}
                        <Select
                          labelId="select-field-label"
                          value={
                            (selectedOption && selectedOption.toLowerCase()) ||
                            (editedData && editedData.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="inpt-cntr-unit"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          <MenuItem value="xx" className="mui_root_name">
                            None
                          </MenuItem>
                          {ImageField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      {/* </FormControl> */}
                    </div>
                    </div>
                    </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Iframe" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          {/* <FormControl className="gnr-data-src"> */}
                            <div className="inpt-fild-test">Data Source</div>
                            <Select
                              labelId="select-field-label"
                              value={
                                (selectedOption &&
                                  selectedOption.toLowerCase()) ||
                                (editedData && editedData.toLowerCase())
                              }
                              onChange={handleEditChange}
                              // className="hnd_slt_text-name"
                              className="inpt-cntr-unit"
                              MenuProps={{
                                PaperProps: {
                                  className: "dt-dropdn-menu",
                                },
                                MenuListProps: {
                                  className: "custom-menu-list",
                                },
                              }}
                            >
                              <MenuItem value="xx" className="mui_root_name">
                                None
                              </MenuItem>
                              {IframeField.map((fieldName, index) => (
                                <MenuItem
                                  key={index}
                                  value={`{${fieldName.toLowerCase()}}`}
                                  className="mui_root_name"
                                >
                                  {fieldName}
                                </MenuItem>
                              ))}
                            </Select>
                          {/* </FormControl> */}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Switch" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (selectedOption && selectedOption.toLowerCase()) ||
                            (editedData && editedData.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          <MenuItem value="xx" className="mui_root_name">
                            None
                          </MenuItem>
                          {switchField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Checkbox" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (editedData && editedData.toLowerCase()) ||
                            (selectedOption && selectedOption.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          <MenuItem value="xx" className="mui_root_name">
                            None
                          </MenuItem>
                          {checkboxField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Radio" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (editedData && editedData.toLowerCase()) ||
                            (selectedOption && selectedOption.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          <MenuItem value="xx" className="mui_root_name">
                            None
                          </MenuItem>
                          {RadioField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem && controlsVisible && (
              <div className="dt-type">
                {selectedItem &&
                  Array.isArray(treeData) &&
                  treeData.forEach((item) => {
                    const findAndLogMatchedObjects = (controlItems) => {
                      controlItems.forEach((controlItem) => {
                        if (
                          controlItem.controlName === selectedItem.controlName
                        ) {
                        }

                        if (
                          controlItem.control &&
                          controlItem.control.length > 0
                        ) {
                          findAndLogMatchedObjects(controlItem.control);
                        }
                      });
                    };

                    item.Source.forEach((sourceItem) => {
                      findAndLogMatchedObjects(sourceItem.control);
                    });
                  })}
              </div>
            )}

            {selectedItem &&
              selectedItem.type === "Table" &&
              selectedItem.tableHeader !== "tableHead" &&
              selectedItem.htmlTag !== "thead" &&
              selectedItem.htmlTag !== "table" &&
              selectedItem.htmlTag !== "tr" &&
              controlsVisible && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            selectedOption &&
                            selectedOption.replace(/[{}]/g, "")
                          }
                          onChange={handleEditChangeforTable}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          {fieldNamestable.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={fieldName}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              selectedItem.type === "TextBox" &&
              controlChanged === "TextBox" &&
              selectedItem.controltype !== "ShortText" &&
              controlsVisible && (
                <div className="clr-schm-option">
                  <div className="drowerseparater">
                    <div class="optns-predefnd">
                      <FormControl className="custom-edit-text-bx">
                        <div className="inpt-fild-test">Data Type</div>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tabSelect}
                          onChange={(event) =>
                            handleChange(event, selectedItem.id)
                          }
                          className="inpt-cntr-unit"
                          MenuProps={{
                            PaperProps: {
                              className: "cstm-dropdown-menu-text",
                            },
                            MenuListProps: {
                              className: "cstm-menu-list-text",
                            },
                          }}
                        >
                          <MenuItem value={"Text"} className="cst-menu-item">
                            {Theme.texttype} Text{" "}
                          </MenuItem>
                          <MenuItem
                            value={"Phone Number"}
                            className="cst-menu-item"
                          >
                            {Theme.Phone} Phone Number
                          </MenuItem>
                          <MenuItem value={"Email"} className="cst-menu-item">
                            {Theme.Email} Email
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}

            <div className="edit-value-wrap gnr-tile-name ">
              {selectedItem &&
                selectedItem.type === "Radio" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="dt-type">Create Multiple : </div>
                      <div className="titleSection">
                        <input
                          type="number"
                          className="tab-txtbox"
                          value={inputValueRadio}
                          onChange={handleInputChangeRadio}
                        />
                        <span
                          className="iconBtn"
                          title="Add"
                          onClick={() => handleAddControlRadio()}
                        >
                          {Theme.Add}
                        </span>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="chck-stst-type">State</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedOptioncheck}
                            onChange={(e) =>
                              handleRadioChange(
                                e.target.value === "true",
                                selectedItem.id
                              )
                            }
                            className="custom-native-select"
                          >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                          </NativeSelect>
                        </FormControl>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="check-bxtype">Position</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedPositioncheck}
                            onChange={(e) =>
                              handleCheckboxPositonChange(
                                e.target.value === "right",
                                selectedItem.id
                              )
                            }
                            style={{ position: "static" }}
                          >
                            <option value="left">Right</option>
                            <option value="right">Left</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}

              {(selectedItem && selectedItem.type === "Checkbox") ||
                (controlChanged === "Checkbox" &&
                  controlsVisible &&
                  selectedItem.styleType !== "RadioContainer" && (
                    <div className="dt-type">
                      <div className="tab-titles">Create Multiple :</div>
                      <div className="titleSection">
                        <input
                          type="number"
                          className="tab-txtbox"
                          value={inputValue}
                          onChange={handleInputChange}
                        />
                        <span
                          className="iconBtn"
                          title="Add"
                          onClick={() => handleAddControlCheckbox()}
                        >
                          {Theme.Add}
                        </span>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="chck-stst-type">State</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedOptioncheck}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e.target.value === "true",
                                selectedItem.id
                              )
                            }
                            className="custom-native-select"
                          >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                          </NativeSelect>
                        </FormControl>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="check-bxtype">Position</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedPositioncheck}
                            onChange={(e) =>
                              handleCheckboxPositonChange(
                                e.target.value === "right",
                                selectedItem.id
                              )
                            }
                            style={{ position: "static" }}
                          >
                            <option value="left">Right </option>
                            <option value="right">Left</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                    </div>
                  ))}

              {selectedItem &&
                selectedItem.type === "Checkbox" &&
                controlChanged === "Checkbox" &&
                controlsVisible && (
                  <div className="dt-type">
                    <div className="tab-titles">Create Multiple :</div>
                    <div className="titleSection">
                      <input
                        type="number"
                        className="tab-txtbox"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      <span
                        className="iconBtn"
                        title="Add"
                        onClick={() => handleAddControlCheckbox()}
                      >
                        {Theme.Add}
                      </span>
                    </div>

                    <div className="gnr-tile-name dt-type">
                      <div className="chck-stst-type">State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptioncheck}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                          className="custom-native-select"
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>

                    <div className="gnr-tile-name dt-type">
                      <div className="check-bxtype">Position</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedPositioncheck}
                          onChange={(e) =>
                            handleCheckboxPositonChange(
                              e.target.value === "right",
                              selectedItem.id
                            )
                          }
                          style={{ position: "static" }}
                        >
                          <option value="left">Right</option>
                          <option value="right">Left</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.type === "Switch" &&
                controlChanged === "Switch" &&
                controlsVisible && (
                  <>
                    <div className="gnr-tile-name dt-type">
                      <div>State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptionSwitchcheck}
                          onChange={(e) =>
                            handleSwitchChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </>
                )}

              {(selectedItem && selectedItem.type === "Switch") ||
                (controlChanged === "Switch" && controlsVisible && (
                  <>
                    <div className="gnr-tile-name dt-type">
                      <div>State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptionSwitchcheck}
                          onChange={(e) =>
                            handleSwitchChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </>
                ))}

              {selectedItem &&
                selectedItem.type === "Image" &&
                controlChanged === "Image" &&
                controlsVisible && (
                  <div className="clr-schm-option">
                    <div className="drowerseparater">
                  <div style={{width:"98%"}}>
                    <div className="inpt-fild-test">Select Image :</div>
                    <div className="heading-file-gnr">
                      <div className="titleSection-ifrm">
                      <input
                        type="file"
                        id="imagePicker"
                        ref={inputRef}
                        accept="image/jpeg, image/png"
                        style={{ display: "none" }}
                        onChange={(e) => handleImageChange(e, selectedItem.id)}
                        className="tab-txtbox"
                      />

                      <label onClick={handleImageClick} className="dtsrc-img-slct">
                        <AddPhotoAlternateIcon />
                      </label>
                    </div>
                    </div>
                  </div>
                  </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.styleType === "Date" &&
                controlsVisible && (
                  <div className="tab-titles dt-type">
                    <div>Select Date</div>
                    <div className="titleSection">
                      {selectedItem.inputType === "text" ? (
                        <input
                          className="tab-txtbox"
                          type="date"
                          value={selectedItem.data}
                          onChange={(e) => handleDateChange(e, selectedItem.id)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <input
                          className="tab-txtbox"
                          type="date"
                          value={selectedItem.data}
                          onChange={(e) => handleDateChange(e, selectedItem.id)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.htmlTag === "table" &&
                controlsVisible && (
                  <div className="tbl-row-col-btn">
                    <Button onClick={addNewColumn}>Add Columns</Button>
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "Address" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <div>
                              <input
                                className="tab-txtbox"
                                value={editedDataHeader}
                                onChange={(event) =>
                                  handleFieldChange(
                                    event,
                                    "AddressHeader",
                                    setEditedDataHeader,
                                    setTreeData
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Sublabels</div>
                          <div className="advnce-field-stree">
                            {CheckboxCheckedStreet1 && (
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Street1</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataStreet1}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        "AddressStreet1",
                                        setEditedDataStreet1,
                                        setTreeData
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {CheckboxCheckedStreet2 && (
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Street2</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataStreet2}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        "AddressStreet2",
                                        setEditedDataStreet2,
                                        setTreeData
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {CheckboxCheckedCity && (
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field City</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataCityLabel}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        "CityLabel",
                                        setEditedDataCityLabel,
                                        setTreeData
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {CheckboxCheckedState && (
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field State</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataStateLabel}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        "StateLabel",
                                        setEditedDataStateLabel,
                                        setTreeData
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {CheckboxCheckedPostal && (
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Postal</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataPostalLabel}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        "PostalLabel",
                                        setEditedDataPostalLabel,
                                        setTreeData
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            <div>
                              {CheckboxCheckedCountry && (
                                <>
                                  <div className="fld-advnce">
                                    <div className="fld-name">
                                      Field Country
                                    </div>
                                    <TextField
                                      className="inpt-text-fld-name"
                                      value={editedDataCountryLabel}
                                      onChange={(event) =>
                                        handleFieldChange(
                                          event,
                                          LABEL_TYPES.CountryLabel,
                                          setEditedDataCountryLabel,
                                          setTreeData
                                        )
                                      }
                                      InputProps={{
                                        classes: { input: "fild-inpt" },
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Data Source</div>
                          <div className="advnce-field-stree">
                            {CheckboxCheckedStreet1 && (
                              <>
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field Street1</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataStreet1}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          "AddressStreet1",
                                          setEditedDataStreet1,
                                          setTreeData
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              </>
                            )}
                            {CheckboxCheckedStreet2 && (
                              <>
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field Street2</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataStreet2}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          "AddressStreet2",
                                          setEditedDataStreet2,
                                          setTreeData
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              </>
                            )}

                            {CheckboxCheckedCity && (
                              <>
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field City</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataCityLabel}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          "CityLabel",
                                          setEditedDataCityLabel,
                                          setTreeData
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              </>
                            )}
                            {CheckboxCheckedState && (
                              <>
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field State</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataStateLabel}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          "StateLabel",
                                          setEditedDataStateLabel,
                                          setTreeData
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              </>
                            )}
                            {CheckboxCheckedPostal && (
                              <>
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field Postal</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataPostalLabel}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          "PostalLabel",
                                          setEditedDataPostalLabel,
                                          setTreeData
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              </>
                            )}
                            {CheckboxCheckedCountry && (
                              <>
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field Country</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataCountryLabel}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          LABEL_TYPES.CountryLabel,
                                          setEditedDataCountryLabel,
                                          setTreeData
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}
              {selectedItem &&
                selectedItem.controltype === "LongText" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataLongTextLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "LongTextLabel",
                                  setEditedDataLongTextLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}
              {selectedItem &&
                selectedItem.controltype === "ShortText" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataShortTextLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "ShortTextLabel",
                                  setEditedDataShortTextLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "PhoneNumber" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataPhoneLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "PhoneNumberLabel",
                                  setEditedDataPhoneLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "Email" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            {/* <Typography>Field Label sdfghje</Typography> */}
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataEmailLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "EmailLabel",
                                  setEditedDataEmailLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "Heading" &&
                controlsVisible && (
                  <>
                    


                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Sublabels</div>
                            <div className="advnce-field-stree">
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Header</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataHeading}
                                    onChange={handleFieldChangeAddressHeading}
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="fld-advnce">
                                <div className="fld-name">Field SubHeader</div>
                                <TextField
                                  className="inpt-text-fld-name"
                                  value={editedDataSubHeading}
                                  onChange={handleFieldChangeAddressSubHeading}
                                  InputProps={{
                                    classes: { input: "fild-inpt" },
                                  }}
                                />
                              </div>
                           
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    {dataSourceArID !== 0 && (
                      <div>
                        <div className="clr-schm-option">
                          <div className="drowerseparater">
                            <div className="label-algnmt">
                              <div className="inpt-fild-test">
                                Data Source 
                              </div>

                              <div className="advnce-field-stree">
                                <>
                                  <div className="fld-advnce-data">
                                    <div className="fld-name">
                                      Field Header
                                    </div>
                                    <div className="dt-src-name">
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataHeading} 
                                          onChange={handleFieldChangeAddressHeading}
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </>
                                <>
                                  <div className="fld-advnce-data">
                                    <div className="fld-name">
                                      Field SubHeader
                                    </div>
                                    <div className="dt-src-name">
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataSubHeading} // Bind to the same value as TextField
                                          onChange={handleFieldChangeAddressSubHeading
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </>
                              
                            
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  

                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr gnr-alignment">
                            <div className="inpt-fild-test label-algnmt">
                              Text Alignment
                            </div>
                            <div className="btn-group-gnr">
                              <Button
                                onClick={() =>
                                  handleAlignmentChangeHeading("left")
                                }
                                className={`button-gnr ${alignMent === "left" ? "active" : ""}`}
                              >
                                Left
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAlignmentChangeHeading("center")
                                }
                                className={`button-gnr ${alignMent === "center" ? "active" : ""}`}
                              >
                                Center
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAlignmentChangeHeading("right")
                                }
                                className={`button-gnr ${alignMent === "right" ? "active" : ""}`}
                              >
                                Right
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr gnr-alignment">
                            <div className="inpt-fild-test label-algnmt">
                              Heading Size
                            </div>
                            <div className="btn-group-gnr">
                              <Button
                                onClick={() =>
                                  handleFontSizeChangeHeading("0.875em")
                                }
                                className={`button-gnr ${FontSize === "0.875em" ? "active" : ""}`}
                              >
                                Default
                              </Button>
                              <Button
                                onClick={() =>
                                  handleFontSizeChangeHeading("2em")
                                }
                                className={`button-gnr ${FontSize === "2em" ? "active" : ""}`}
                              >
                                Large
                              </Button>
                              <Button
                                onClick={() =>
                                  handleFontSizeChangeHeading("0.75em")
                                }
                                className={`button-gnr ${FontSize === "0.75em" ? "active" : ""}`}
                              >
                                Small
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "DropDown" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataDropDownLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "DropDownLabel",
                                  setEditedDataDropDownLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "FullName" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="dspl-otr-otion">
                            <div className="inpt-fild-test">Required</div>
                            <div className="tggle-switch unit-option">
                              <div
                                className={`toggle-box ${isRequiredFullName ? "active" : "inactive"}`}
                                onClick={handleSwitchChangeFullName}
                              >
                                <span
                                  className={
                                    isRequiredFullName
                                      ? "on-text"
                                      : "inactive-on"
                                  }
                                >
                                  ON
                                </span>
                                <span
                                  className={
                                    !isRequiredFullName
                                      ? "on-text"
                                      : "inactive-off"
                                  }
                                >
                                  OFF
                                </span>
                              </div>
                              {/* {isRequiredFullName && (
              <input
                className="fild-inpt-options"
                value={isRequiredFullName}
                onChange={handleSwitchChangeFullName}
                placeholder="Enter text"
              />
            )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Sublabels</div>
                            <div className="advnce-field-stree">
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Label</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataFullName}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        "FullName",
                                        setEditedDataFullName,
                                        setTreeData
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="fld-advnce">
                                <div className="fld-name">First Name</div>
                                <TextField
                                  className="inpt-text-fld-name"
                                  value={editedDataFirstName}
                                  onChange={(event) =>
                                    handleFieldChange(
                                      event,
                                      "FirstName",
                                      setEditedDataFirstName,
                                      setTreeData
                                    )
                                  }
                                  InputProps={{
                                    classes: { input: "fild-inpt" },
                                  }}
                                />
                              </div>
                              <div className="fld-advnce">
                                <div className="fld-name">Last Name</div>
                                <TextField
                                  className="inpt-text-fld-name"
                                  value={editedDataLastName}
                                  onChange={(event) =>
                                    handleFieldChange(
                                      event,
                                      "LastName",
                                      setEditedDataLastName,
                                      setTreeData
                                    )
                                  }
                                  InputProps={{
                                    classes: { input: "fild-inpt" },
                                  }}
                                />
                              </div>
                              {isPrefixLabelPresent && (
                                <>
                                  <div className="fld-advnce">
                                    <div className="fld-name">Field Prefix</div>
                                    <TextField
                                      className="inpt-text-fld-name"
                                      value={editedDataPrefixLabel}
                                      onChange={(event) =>
                                        handleFieldChange(
                                          event,
                                          "PrefixLabel",
                                          setEditedDataPrefixLabel,
                                          setTreeData
                                        )
                                      }
                                      InputProps={{
                                        classes: { input: "fild-inpt" },
                                      }}
                                    />
                                  </div>
                                </>
                              )}

                              {isMiddleNameLabelPresent && (
                                <>
                                  <div className="fld-advnce">
                                    <div className="fld-name">
                                      Field MiddleName
                                    </div>
                                    <TextField
                                      className="inpt-text-fld-name"
                                      value={editedDataMiddleNameLabel}
                                      onChange={(event) =>
                                        handleFieldChange(
                                          event,
                                          "MiddleNameLabel",
                                          setEditedDataMiddleNameLabel,
                                          setTreeData
                                        )
                                      }
                                      InputProps={{
                                        classes: { input: "fild-inpt" },
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              {/* <div>
                        <Typography>Required</Typography>
                        <label style={switchStyle}>
                          <input
                            type="checkbox"
                            checked={isRequiredFullName}
                            onChange={handleSwitchChangeFullName}
                            style={{ opacity: 0, width: 0, height: 0 }}
                          />
                          <span style={sliderStyle1}>
                            <span style={sliderBeforeStyle1}></span>
                          </span>
                        </label>
                      </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {dataSourceArID !== 0 && (
                      <div>
                        <div className="clr-schm-option">
                          <div className="drowerseparater">
                            <div className="label-algnmt">
                              <div className="inpt-fild-test">
                                Data Source 
                              </div>

                              <div className="advnce-field-stree">
                                <>
                                  <div className="fld-advnce-data">
                                    <div className="fld-name">
                                      Field First Name
                                    </div>
                                    <div className="dt-src-name">
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataFirstName} // Bind to the same value as TextField
                                          onChange={(e) =>
                                            handleFieldChange(
                                              {
                                                target: {
                                                  value: e.target.value,
                                                },
                                              }, // Mock event for handleFieldChange
                                              "FirstName",
                                              setEditedDataFirstName,
                                              setTreeData
                                            )
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </>
                                <>
                                  <div className="fld-advnce-data">
                                    <div className="fld-name">
                                      Field Last Name
                                    </div>
                                    <div className="dt-src-name">
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataLastName} // Bind to the same value as TextField
                                          onChange={(e) =>
                                            handleFieldChange(
                                              {
                                                target: {
                                                  value: e.target.value,
                                                },
                                              }, // Mock event for handleFieldChange
                                              "LastName",
                                              setEditedDataLastName,
                                              setTreeData
                                            )
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </>
                                {isPrefixLabelPresent && (
                                  <>
                                    <div className="fld-advnce-data">
                                      <div className="fld-name">
                                        Field Prefix
                                      </div>
                                      <div className="dt-src-name">
                                        <FormControl>
                                          <select
                                            className="theme-input-slt-dtsrc"
                                            value={editedDataPrefixLabel}
                                            onChange={(e) =>
                                              handleFieldChange(
                                                {
                                                  target: {
                                                    value: e.target.value,
                                                  },
                                                },
                                                "PrefixLabel",
                                                setEditedDataPrefixLabel,
                                                setTreeData
                                              )
                                            }
                                            MenuProps={{
                                              PaperProps: {
                                                className: "dt-dropdn-menu",
                                              },
                                              MenuListProps: {
                                                className: "custom-menu-list",
                                              },
                                            }}
                                          >
                                            <option value="">
                                              select DataSource
                                            </option>
                                            {fieldNames.map(
                                              (fieldName, index) => (
                                                <option
                                                  key={index}
                                                  value={`{${fieldName.toLowerCase()}}`}
                                                  className="mui_root_name"
                                                >
                                                  {fieldName}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {isMiddleNameLabelPresent && (
                                  <>
                                    <div className="fld-advnce-data">
                                      <div className="fld-name">
                                        Field MiddleName
                                      </div>
                                      <div className="dt-src-name">
                                        <FormControl>
                                          <select
                                            className="theme-input-slt-dtsrc"
                                            value={editedDataMiddleNameLabel} // Bind to the same value as TextField
                                            onChange={(e) =>
                                              handleFieldChange(
                                                {
                                                  target: {
                                                    value: e.target.value,
                                                  },
                                                }, // Mock event for handleFieldChange
                                                "MiddleNameLabel",
                                                setEditedDataMiddleNameLabel,
                                                setTreeData
                                              )
                                            }
                                            MenuProps={{
                                              PaperProps: {
                                                className: "dt-dropdn-menu",
                                              },
                                              MenuListProps: {
                                                className: "custom-menu-list",
                                              },
                                            }}
                                          >
                                            <option value="">
                                              select DataSource
                                            </option>
                                            {fieldNames.map(
                                              (fieldName, index) => (
                                                <option
                                                  key={index}
                                                  value={`{${fieldName.toLowerCase()}}`}
                                                  className="mui_root_name"
                                                >
                                                  {fieldName}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "SingleChoice" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr optns-predefnd">
                            <div className="inpt-fild-test label-algnmt">
                              Field Label
                            </div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataSingleChoiceLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "SingleChoiceLabel",
                                  setEditedDataSingleChoiceLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "MultipleChoice" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr inpt-fild-test">
                            <div className="inpt-fild-test label-algnmt">
                              Field Postal
                            </div>
                            <input
                              className="tab-txtbox"
                              value={editedDataCheckboxHeading}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "MultipleChoiceLabel",
                                  setEditedDataCheckboxHeading,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}
              {selectedItem &&
                selectedItem.controltype === "DateBlock" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="inputfildtet inpt-fild-test">
                          <div>Field Label</div>
                          <input
                            className="tab-txtbox theme-input-drpdown"
                            value={editedDataDateLabel}
                            onChange={(event) =>
                              handleFieldChange(
                                event,
                                "DateLabel",
                                setEditedDataDateLabel,
                                setTreeData
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ marginLeft: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "RatingBlock" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataRatingLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "RatingLabel",
                                  setEditedDataRatingLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "10px" }}>
                      <Button variant="contained" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    </div>
                  </>
                )}
            </div>
          </div>

          {controlsVisible && <div key="2"> </div>}
        </TabsComponent>
      </Box>
    </>
  );
};

export default GeneralSection;