import { toast } from "react-toastify";
import { loadLoginData } from "../../apiCalls/DiagramsAPI";
import { useNavigate } from "react-router-dom";

let APIProvider = window.APIProvider;

export async function ReadArray(arId, accessKey) {
  const GetReadArrayArid = {
    accessKey: accessKey,
    guid: window.APIProvider.GUID.ReadArrayGUID,
    param1: String(arId),
    param2: "",
    param3: "",
    param4: "",
    recordSet: "",
    body: "",
  };

  try {
    const response = await fetch(
      `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(GetReadArrayArid),
      }
    );

    if (!response.ok) {
      console.error("Error fetching data:", response.statusText);
      return null;
    }

    const data = await response.json();
    const resultData = JSON.parse(data.apiResponse);

    const jsonData = [
      {
        arid: resultData[0].ArID,
        arrayName: resultData[0].ArrayName,
        description: resultData[0].Description,
        dataSourceArID: resultData[0].DataSourceArID,
        Source: JSON.parse(resultData[0].Source.replace(/~/g, '"')),
        IsTemplate: resultData[0].IsTemplate,
        HtmlOutput: resultData[0].HtmlOutput,
      },
    ];
    return jsonData;
  } catch (error) {
    console.error("Error fetching data:", error);

    if (error.message === "Source is null or undefined") {
      window.location.href = `/NotFound?arID=${arId}`;
    }
    return null;
  }
}

export async function WriteArray(
  treeData,
  arId,
  arrayName,
  description,
  category,
  htmlFileContent,
  dataSourceArID,
  accessKey
) {
  try {
    const requestBody = {
      accessKey: accessKey,
      guid: APIProvider.GUID.WriteArrayGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: JSON.stringify({
        ArID: arId,
        ArrayName: arrayName,
        Description: description,
        Source: JSON.stringify(treeData[0].Source, null, 2),
        Category: category,
        IsTemplate: "0",
        htmlOutput: htmlFileContent,
        DataSourceArID: dataSourceArID,
      }),
    };

    const response = await fetch(
      `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response;
  } catch (error) {
    console.error("Error", error);
    toast.error("Error");
  }
}

export async function WriteArray1(
  treeData,
  arId,
  arrayName,
  description,
  category,
  htmlFileContent,
  dataSourceArID,
  accessKey
) {
  try {
    const requestBody = {
      accessKey: accessKey,
      guid: APIProvider.GUID.WriteArrayGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: JSON.stringify({
        ArID: arId,
        ArrayName: arrayName,
        Description: description,
        Source: JSON.stringify(treeData[0].Source, null, 2),
        Category: category,
        IsTemplate: "1",
        htmlOutput: htmlFileContent,
        DataSourceArID: dataSourceArID,
      }),
    };

    const response = await fetch(
      `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error ");
  }
}

export async function WriteArrayTheme(
  treeData,
  arId,
  arrayName,
  description,
  category,
  htmlFileContent,
  dataSourceArID,
  accessKey
) {
  try {
    const requestBody = {
      accessKey: accessKey,
      guid: APIProvider.GUID.WriteArrayGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: JSON.stringify({
        ArID: arId,
        ArrayName: arrayName,
        Description: description,
        Source: JSON.stringify(treeData[0].Source, null, 2),
        Category: category,
        IsTemplate: "4",
        htmlOutput: htmlFileContent,
        DataSourceArID: dataSourceArID,
      }),
    };

    console.log("requestBody",requestBody)

    const response = await fetch(
      `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response;
  } catch (error) {
    console.error("Error", error);
    toast.error("Error");
  }
}
