import React, { useMemo } from "react";

function Preview({ propertyData, tools, selectedItemID, tempNodes }) {
  // Find the selected tool by its ID
  const selectedTool = tools.find(
    (tool) => tool?.diagtoolid?.toString() === selectedItemID?.toString()
  );

  const nodeId = selectedTool?.diagtoolid?.toString();

  // Retrieve default or special properties based on node ID
  const specialProperties = useMemo(
    () =>
      propertyData?.Nodes?.find((node) => node.id.toString() === nodeId) ||
      null,
    [propertyData?.Nodes, nodeId]
  );

  const properties = useMemo(
    () =>
      specialProperties
        ? specialProperties.customstyle
        : tempNodes[selectedItemID]
          ? tempNodes[selectedItemID]
          : propertyData[selectedTool?.category || "<all>"] || {},
    [specialProperties, propertyData, selectedTool, tempNodes,selectedItemID]
  );

  // Set node dimensions with fallbacks
  const nodeHeight = useMemo(
    () => (selectedTool?.nodeheight ? `${selectedTool.nodeheight}px` : "75px"),
    [selectedTool?.nodeheight]
  );
  const nodeWidth = useMemo(
    () => (selectedTool?.nodewidth ? `${selectedTool.nodewidth}px` : "240px"),
    [selectedTool?.nodewidth]
  );

  const IconElement = React.memo(
    ({ iconSize, displayBootstrapIcon, displayUrlIcon, IconLink }) => (
      <>
        {displayBootstrapIcon === "flex" && (
          <i
            className={`glyphicon ${IconLink}`}
            style={{ fontSize: iconSize, display: displayBootstrapIcon }}
          />
        )}
        {displayUrlIcon === "flex" && (
          <img
            src={IconLink}
            alt="icon"
            style={{
              width: iconSize,
              height: iconSize,
              display: displayUrlIcon,
            }}
          />
        )}
      </>
    )
  );

  // Memoized icon properties
  const iconProps = useMemo(() => {
    const { IconSize, IconType, IconLink } = properties?.caption || {};
    return {
      iconSize:
        IconSize === "large" ? "48px" : IconSize === "medium" ? "36px" : "24px",
      displayBootstrapIcon: IconType === "Bootstrap" ? "flex" : "none",
      displayUrlIcon: IconType === "Url" ? "flex" : "none",
      IconLink,
    };
  }, [properties?.caption]);

  // Memoized body display properties
  const displayProperties = useMemo(() => {
    const bodyType = properties?.body?.bodyType;
    return {
      text: bodyType !== "1" && bodyType !== "2" ? "block" : "none",
      html: bodyType === "1" ? "block" : "none",
      image: bodyType === "2" ? "block" : "none",
    };
  }, [properties?.body?.bodyType]);

  // Render
  return (
    <>
      {/* NODE */}
      <div
        style={{
          ...(properties?.node || {}),
          height: nodeHeight,
          width: nodeWidth,
          borderStyle: "solid",
        }}
      >
        {/* CAPTION */}
        <div
          style={{
            ...(properties?.caption || {}),
            display: "flex",
            borderRadius: properties?.node?.borderRadius ?? "0px",
          }}
        >
          {properties?.caption?.IconPosition === "Left" && (
            <IconElement {...iconProps} />
          )}
          <span className="captionSpan" style={properties?.caption}>
            {properties?.caption?.IconPosition === "Center" && (
              <IconElement {...iconProps} />
            )}
            {selectedTool?.caption || selectedTool?.name || ""}
          </span>
          {properties?.caption?.IconPosition === "Right" && (
            <IconElement {...iconProps} />
          )}
        </div>

        {/* CAPTION DIVIDER */}
        <div
          style={{
            ...(properties?.captionDivider || {}),
            borderStyle: properties?.captionDivider ? "solid" : "none",
          }}
        />

        {/* BODY */}
        <div style={properties?.body || {}}>
          {properties?.body?.bodyType === "1" ? (
            <div
              dangerouslySetInnerHTML={{ __html: properties?.body?.content }}
              style={{ display: displayProperties?.html }}
            />
          ) : properties?.body?.bodyType === "2" ? (
            <img
              src={properties?.body?.content}
              alt="icon"
              style={{ display: displayProperties?.image }}
            />
          ) : (
            <div style={{ display: displayProperties?.text }}>
              {properties?.body?.content}
            </div>
          )}
        </div>

        {/* LINK DIVIDER */}
        <div
          style={{
            ...(properties?.linkDivider || {}),
            borderStyle: properties?.linkDivider ? "solid" : "none",
          }}
        />
      </div>
    </>
  );
}

export default Preview;
