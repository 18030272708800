import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import PopUpRenderer from "./PopUpForm/PopUpRenderer";
import RecursiveTreeView from "./Components/RecursiveTreeView/RecursiveTreeView";
import Landing from "./pages/landingPage/Landing";
import Diagrams from "./pages/diagramPages/Diagrams";
import DiagramsRenderer from "./Components/diagramsComponents/diagramsFormComponents/DiagramsRenderer";
import PanelComponent from "./reusableComponents/PanelComponent";
import NotFound from "./Components/RecursiveTreeView/NotFound";
import CreateInfo from "./PopUpForm/CreateInfo";
import ViewCardInfo from "./PopUpForm/ViewCardInfo";
import NodeSelector from "./pages/diagramPages/NodeSelectorPage";
import NotificationCenter from "./Components/AlertComponents/NotificationCenter";
import themeProvider from "./ThemeProvider";
import NodeProperties from "./Components/diagramsComponents/toolProperties/NodeProperties";
import Discover from "./PopUpForm/Discover";
import DiscoverEdit from "./PopUpForm/DiscoverEdit";
import EditTemplatePage from "./pages/diagramPages/EditTemplatePage";
import EditControls from "./pages/diagramPages/EditControls";
import EditControlsStyles from "./pages/diagramPages/EditControlsStyles";
import Login from "./pages/Login";
import LoginBusiness from "./pages/Login/LoginBusiness";
import { AppProvider } from "./Components/diagramsComponents/RegionContext";
import DiagramsAIUI from "./pages/diagramPages/DiagramsAIUI";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ThemeTemplates from "./Components/ThemeBuilder/ThemeTemplates";
import DiagramBuilderSidebar from "./pages/TestPage";
import { loadLoginData } from "./apiCalls/DiagramsAPI";
import PrivateRoute from "./PrivateRoute";

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.location.pathname === "/") {
        sessionStorage.clear();
        sessionStorage.setItem("loginData", null);
        sessionStorage.removeItem("loginData");
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  document.documentElement.style.setProperty(
    "--primary-main",
    themeProvider.palette.primary.main
  );
  document.documentElement.style.setProperty(
    "--primaryContrastColor-main",
    themeProvider.palette.primaryContrastColor.main
  );

  const loginData = loadLoginData();

  console.log("loginData:",loginData);
  
  
  useEffect(() => {
    if (window.location.pathname === "/") {
      sessionStorage.clear();
    }
  }, []);


return (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <AppProvider loginData={loginData}>
      <NotificationCenter />
      <Routes>
        <Route index element={<Login/>} />

          <Route
            path="/Landing"
            element={
              <PrivateRoute>
                <Landing />
              </PrivateRoute>
            }
          />

          <Route
            path="/Form/:arId"
            element={
              <PrivateRoute>
                <DndProvider backend={HTML5Backend}>
                  <RecursiveTreeView />
                </DndProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/Themes/:arId"
            element={
              <PrivateRoute>
                <DndProvider backend={HTML5Backend}>
                  <RecursiveTreeView />
                </DndProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/Open"
            element={
              <PrivateRoute>
                <PopUpRenderer />
              </PrivateRoute>
            }
          />
          <Route
            path="/test"
            element={
              <PrivateRoute>
                <DiagramBuilderSidebar />
              </PrivateRoute>
            }
          />
          <Route
            path="/Themes"
            element={
              <PrivateRoute>
                <ThemeTemplates />
              </PrivateRoute>
            }
          />

        <Route
          path="/Diagrams"
          element={
            <PrivateRoute>
              <Diagrams />
            </PrivateRoute>
          }
        />
        <Route
          path="/DiagramsAIUI"
          element={
            <PrivateRoute>
              <DiagramsAIUI />
            </PrivateRoute>
          }
        />
        <Route
          path="/Select"
          element={
            <PrivateRoute>
              <DiagramsRenderer />
            </PrivateRoute>
          }
        />
        <Route
          path="/PanelComponent"
          element={
            <PrivateRoute>
              <PanelComponent />
            </PrivateRoute>
          }
        />
        <Route
          path="/ViewCardInfo"
          element={
            <PrivateRoute>
              <ViewCardInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="/CreateInfo"
          element={
            <PrivateRoute>
              <CreateInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="/Discover"
          element={
            <PrivateRoute>
              <Discover />
            </PrivateRoute>
          }
        />
        <Route
          path="/DiscoverEdit"
          element={
            <PrivateRoute>
              <DiscoverEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/EditTemplates"
          element={
            <PrivateRoute>
              <EditTemplatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/ToolSelector"
          element={
            <PrivateRoute>
              <NodeSelector />
            </PrivateRoute>
          }
        />
        <Route
          path="/Edit"
          element={
            <PrivateRoute>
              <EditControls />
            </PrivateRoute>
          }
        />
        <Route
          path="/Styles"
          element={
            <PrivateRoute>
              <EditControlsStyles />
            </PrivateRoute>
          }
        />
        <Route
          path="/ToolProperties"
          element={
            <PrivateRoute>
              <NodeProperties />
            </PrivateRoute>
          }
        />

        <Route
          path="/Login2"
          element={
            <PrivateRoute>
              <LoginBusiness />
            </PrivateRoute>
          }
        />

          <Route
            path="*"
            element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            }
          />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;