// import React, { useEffect, useState, useRef, useMemo, memo } from "react";
// import {
//   Handle,
//   Position,
//   useUpdateNodeInternals,
//   NodeResizer,
// } from "reactflow";
// import { getPropertyValue } from "../functions/DragnDrop";
// import { drag } from "d3-drag";
// import { select } from "d3-selection";
// import styles from "../style.module.css";

// import {
//   getResource,
//   replaceUnderscoresWithSpaces,
// } from "../apiCalls/DiagramsAPI";
// const controlStyle = {
//   background: "transparent",
//   border: "none",
// };

// const DynamicCustomNode = ({ data, selected }) => {
//   // Destructure the data array
//   //nodData is info from QinfoDiagTools
//   //properties are from QinfoDiagPropertyDef and QinfoDiagToolsProperties and are not essential some nodes do not have properties
//   //id new/selected node id
//   //selected whether we entered this component to create a new node or edited a "selected" node

//   let [nodeData, properties, id] = data;

//   console.log("datafromdynamicnode",data);

//   const [isHovered, setIsHovered] = useState(false);
  
  

//   // Function to get property value from properties array
//   const nodeHeight = nodeData?.node_Height
//     ? nodeData.node_Height + "px"
//     : "125px";
//   const nodeWidth = nodeData?.node_Width ? nodeData.node_Width + "px" : "240px";
//   const handleCount = nodeData?.handles ? parseInt(nodeData.handles) : "4";

//   // let captionFontWeight = getPropertyValue("Caption_Font_weight", properties);
//   // captionFontWeight = captionFontWeight
//   //   ? JSON.parse(captionFontWeight)
//   //   : {
//   //       bold: "0",
//   //       italic: "0",
//   //       underline: "0",
//   //     };
//   // Parse Icon related properties from properties
//   // const iconType = getPropertyValue("Caption_IconType", properties);
//   // const iconLink = getPropertyValue("Caption_IconLink", properties);
//   // const iconPosition = getPropertyValue("Caption_IconPosition", properties);
//   // const iconSize = getPropertyValue("Caption_IconSize", properties);
//   // const iconSizeMapping = {
//   //   small: "24px", // Adjust this value according to your needs
//   //   medium: "36px", // Adjust this value according to your needs
//   //   large: "48px", // Adjust this value according to your needs
//   // };

//   // const body =
//   //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
//   // const selectedItem = jsonData.find(
//   //   (item) => item?.TaskTypeID?.toString() === nodeData?.taskTypeID?.toString()
//   // );
//   // const body = getPropertyValue("Body_Text", properties);
//   // let bodyType = getPropertyValue("Body_type", properties);
//   // if (bodyType === null) {
//   //   bodyType = "0";
//   // }
//   const getFlexAlignItems = (alignment) => {
//     switch (alignment?.toLowerCase()) {
//       case "top":
//         return "stretch";
//       case "vcenter":
//         return "stretch";
//       case "bottom":
//         return "stretch";
//       case "vstretch":
//         return "stretch";
//       default:
//         return "initial"; // or any default value you prefer
//     }
//   };
//   const getFlexJustifyContent = (alignment) => {
//     switch (alignment?.toLowerCase()) {
//       case "left":
//         return "flex-start";
//       case "hcenter":
//         return "center";
//       case "center":
//         return "center";
//       case "right":
//         return "flex-end";
//       default:
//         return "initial"; // or any default value you prefer
//     }
//   };
//   // const alignItemsValue = getFlexAlignItems(
//   //   getPropertyValue("Body_Alignment_Vertical", properties)
//   // );
//   // const bodyHorizontalAlignment = getFlexJustifyContent(
//   //   getPropertyValue("Body_Alignment_Horizontal", properties)
//   // );
//   // let bodyFontWeight = getPropertyValue("Body_Font_weight", properties);
//   // bodyFontWeight = bodyFontWeight
//   //   ? JSON.parse(bodyFontWeight)
//   //   : {
//   //       bold: "0",
//   //       italic: "0",
//   //       underline: "0",
//   //     };
//   // let textOverflow = getPropertyValue("Body_TextOverflow", properties);
//   // if (textOverflow === null) {
//   //   textOverflow = "1";
//   // }
//   // const url = getPropertyValue("Body_URL", properties);
//   // const imageFit = getPropertyValue("Body_Image_Fit", properties);
//   // let linkAlignment = getFlexJustifyContent(
//   //   getPropertyValue("Link_Alignment_Horizontal", properties)
//   // );
//   // let linkFontWeight = getPropertyValue("Link_Font_weight", properties);
//   // linkFontWeight = linkFontWeight
//   //   ? JSON.parse(linkFontWeight)
//   //   : {
//   //       bold: "0",
//   //       italic: "0",
//   //       underline: "0",
//   //     };

//   const calculateHandlePosition = (index) => {
//     const perSide = index % 4; // Assuming equal distribution on each side

//     switch (perSide) {
//       case 0: // Top side
//         return Position.Top;
//       case 1: // Bottom side
//         return Position.Bottom;
//       case 2: // Right side
//         return Position.Right;
//       case 3: // Left side
//         return Position.Left;
//       default:
//         return Position.Right; // Default to right
//     }
//   };
//   // Define handle positions based on handle count
//   const handles = useMemo(
//     () =>
//       Array.from({ length: 4 }, (_, i) => {
//         let floor = Math.floor(i / 4);
//         const handleId = `handle-${i}`;
//         return (
//           <Handle
//             key={handleId}
//             id={handleId}
//             type="source"
//             position={calculateHandlePosition(i)}
//             // className={nodeData?.nodeType === "0" ? "" : `${styles.handle}`}
//             // style={
//             //   i % 4 === 0 || i % 4 === 1
//             //     ? { marginLeft: 20 * floor }
//             //     : { marginTop: 20 * floor }
//             // }
//             isConnectable={
//               nodeData?.isDisabled
//                 ? nodeData.isDisabled.toLowerCase() === "false"
//                 : true
//             }
//           />
//         );
//       }),
//     [handleCount]
//   );
//   // const handles = useMemo(
//   //   () =>
//   //     Array.from({ length: handleCount }, (_, i) => {
//   //       let floor = Math.floor(i / 4);
//   //       const handleId = `handle-${i}`;
//   //       return (
//   //         <Handle
//   //           key={handleId}
//   //           id={handleId}
//   //           type="source"
//   //           position={calculateHandlePosition(i)}
//   //           className={nodeData?.nodeType === "0" ? "" : `${styles.handle}`}
//   //           style={
//   //             i % 4 === 0 || i % 4 === 1
//   //               ? { marginLeft: 20 * floor }
//   //               : { marginTop: 20 * floor }
//   //           }
//   //           isConnectable={
//   //             nodeData?.isDisabled
//   //               ? nodeData.isDisabled.toLowerCase() === "false"
//   //               : true
//   //           }
//   //         />
//   //       );
//   //     }),
//   //   [handleCount]
//   // );

//   const rotateControlRef = useRef(null);
//   const updateNodeInternals = useUpdateNodeInternals();
//   const [rotation, setRotation] = useState(0);
//   useEffect(() => {
//     if (!rotateControlRef.current) {
//       return;
//     }

//     const selection = select(rotateControlRef.current);
//     const dragHandler = drag().on("drag", (evt) => {
//       const dx = evt.x - 100;
//       const dy = evt.y - 100;
//       const rad = Math.atan2(dx, dy);
//       const deg = rad * (180 / Math.PI);
//       setRotation(180 - deg);
//       updateNodeInternals(id);
//     });

//     selection.call(dragHandler);
//   }, [id, updateNodeInternals]);
//   // const borderSize = getPropertyValue("Node_Border_size", properties) || "1";
//   // const nodeBorderColor =
//   //   getPropertyValue("Node_Border_color", properties) || "black";
//   // let nodePadding = getPropertyValue("Node_Padding", properties);
//   // nodePadding = nodePadding
//   //   ? JSON.parse(nodePadding)
//   //   : {
//   //       top: "0",
//   //       topUnit: "px",
//   //       right: "0",
//   //       rightUnit: "px",
//   //       bottom: "0",
//   //       bottomUnit: "px",
//   //       left: "0",
//   //       leftUnit: "px",
//   //     };
//   // let fontWeight = getPropertyValue("Node_Font_weight", properties);
//   // fontWeight = fontWeight
//   //   ? JSON.parse(fontWeight)
//   //   : {
//   //       bold: "0",
//   //       italic: "0",
//   //       underline: "0",
//   //     };

//   // let captionDividerSize = getPropertyValue("Caption_Divider_size", properties);
//   // let captionDividerColor = getPropertyValue(
//   //   "Caption_Divider_color",
//   //   properties
//   // );
//   // let linkDividerSize = getPropertyValue("Link_Divider_size", properties);
//   // let linkDividerColor = getPropertyValue("Link_Divider_color", properties);
//   // Remove the resizeObserver error
//   useEffect(() => {
//     const errorHandler = (e) => {
//       if (
//         e.message.includes(
//           "ResizeObserver loop completed with undelivered notifications" ||
//             "ResizeObserver loop limit exceeded"
//         )
//       ) {
//         const resizeObserverErr = document.getElementById(
//           "webpack-dev-server-client-overlay"
//         );
//         if (resizeObserverErr) {
//           resizeObserverErr.style.display = "none";
//         }
//       }
//     };
//     window.addEventListener("error", errorHandler);

//     return () => {
//       window.removeEventListener("error", errorHandler);
//     };
//   }, []);
//   const IconElement = React.memo(
//     ({ iconSize, displayBootstrapIcon, displayUrlIcon, IconLink }) => (
//       <>
//         {displayBootstrapIcon === "flex" && (
//           <i
//             className={`glyphicon ${IconLink}`}
//             style={{ fontSize: iconSize, display: displayBootstrapIcon }}
//           />
//         )}
//         {displayUrlIcon === "flex" && (
//           <img
//             src={IconLink}
//             alt="icon"
//             style={{
//               width: iconSize,
//               height: iconSize,
//               display: displayUrlIcon,
//             }}
//           />
//         )}
//       </>
//     )
//   );
//   const iconProps = useMemo(
//     () => ({
//       iconSize:
//         properties?.caption?.IconSize === "large"
//           ? "48px"
//           : properties?.caption?.IconSize === "medium"
//             ? "36px"
//             : "24px",
//       displayBootstrapIcon:
//         properties?.caption?.IconType === "Bootstrap" ? "flex" : "none",
//       displayUrlIcon: properties?.caption?.IconType === "Url" ? "flex" : "none",
//       IconLink: properties?.caption?.IconLink,
//     }),
//     [
//       properties?.caption?.IconSize,
//       properties?.caption?.IconType,
//       properties?.caption?.IconLink,
//     ]
//   );
//   const displayProperties = useMemo(
//     () => ({
//       text:
//         properties?.body?.bodyType !== "1" && properties?.body?.bodyType !== "2"
//           ? "block"
//           : "none",
//       html: properties?.body?.bodyType === "1" ? "block" : "none",
//       image: properties?.body?.bodyType === "2" ? "block" : "none",
//     }),
//     [properties?.body?.bodyType]
//   );


//   console.log("properties",properties)
//   return (
//     <>
//       {/* NODE */}
//       <div
//         style={{
//           ...(properties?.node || {}),
//           width: "100%",
//           height: "100%",
//           borderStyle: "solid",
//           transform: `rotate(${rotation}deg)`,
//           opacity: nodeData?.isDisabled?.toLowerCase() === "true" ? 0.5 : 1,
//         }}
//       >
//         <NodeResizer isVisible={selected} minWidth={100} minHeight={30} />
//         <div
//           ref={rotateControlRef}
//           style={{
//             display: selected ? "block" : "none",
//           }}
//           className={`nodrag ${styles.rotateHandle}`}
//         />
//         {handles}
//         {/* CAPTION */}
//         <div
//           style={{
//             ...(properties?.caption || {}),
//             display: "flex",
//             borderRadius: properties?.node?.borderRadius ?? "0px",
//           }}
//         >
//           {/* {properties.caption.IconPosition === "flex-start" && ( */}
//           {properties?.caption?.IconPosition === "Left" && (
//             <IconElement {...iconProps} />
//           )}
//           <span className="captionSpan" style={properties?.caption}>
//             {properties?.caption?.IconPosition === "Center" && (
//               <IconElement {...iconProps} />
//             )}
//              {nodeData?.category && nodeData.category}
//              <br></br>
//             {nodeData?.caption
//               ? nodeData.caption
//               : nodeData?.name && nodeData.name}
//               <br></br>
//             {nodeData?.description && nodeData.description}
//             <br></br>
//           </span>
//           {properties?.caption?.IconPosition === "Right" && (
//             <IconElement {...iconProps} />
//           )}
//         </div>
//         {/* CAPTION DIVIDER */}
//         <div
//           style={{
//             ...(properties?.captionDivider || {}),
//             borderStyle: properties?.captionDivider ? "solid" : "none",
//           }}
//         />
//         {/* BODY */}
//         <div style={properties?.body || {}}>
//           {properties?.body?.bodyType === "1" ? (
//             <div
//               dangerouslySetInnerHTML={{ __html: properties?.body?.content }}
//               style={{ display: displayProperties?.html }}
//             />
//           ) : properties?.body?.bodyType === "2" ? (
//             <img
//               src={properties?.body?.content}
//               alt="icon"
//               style={{ display: displayProperties?.image }}
//             />
//           ) : (
//             <>
//               <div style={{ display: displayProperties?.text }}>
//                 {properties?.body?.content}
//               </div>
//             </>
//           )}
//         </div>
//         {/* LINK DIVIDER */}
//         <div
//           style={{
//             ...(properties?.linkDivider || {}),
//             borderStyle: properties?.linkDivider ? "solid" : "none",
//           }}
//         />
//         {/* LINK */}
//         {/* <div
//           style={{
//             display: "flex",
//             cursor: "pointer",
//             ...properties.link,
//           }}
//         >
//           <a href="www.example.com" style={properties.link}>
//             Example link
//           </a>
//         </div> */}
//       </div>
//     </>
//   );
// };

// export default memo(DynamicCustomNode);




import React, { useEffect, useState, useRef, useMemo, memo } from "react";
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  NodeResizer,
} from "reactflow";
import { getPropertyValue } from "../functions/DragnDrop";
import { drag } from "d3-drag";
import { select } from "d3-selection";
import styles from "../style.module.css";

import {
  getResource,
  replaceUnderscoresWithSpaces,
} from "../apiCalls/DiagramsAPI";
const controlStyle = {
  background: "transparent",
  border: "none",
};

const DynamicCustomNode = ({ data, selected }) => {
  // Destructure the data array
  //nodData is info from QinfoDiagTools
  //properties are from QinfoDiagPropertyDef and QinfoDiagToolsProperties and are not essential some nodes do not have properties
  //id new/selected node id
  //selected whether we entered this component to create a new node or edited a "selected" node

  let [nodeData, properties, id] = data;
  // let [nodeData, id] = data;

//   const properties = {
//     "body": {
//         "alignItems": "VCenter",
//         "backgroundColor": "#ffffff",
//         "bodyType": "0",
//         "color": "#000000",
//         "fontFamily": "Arial",
//         "fontSize": "18px",
//         "fontWeight": "{'bold': 0, 'italic': 0, 'underline': 0 }",
//         "justifyContent": "Left",
//         "objectFit": "2",
//         "textOverflow": "2"
//     },
//     "caption": {
//         "backgroundColor": "#9fc1bb",
//         "color": "#f8f7f7",
//         "fontFamily": "Arial",
//         "fontSize": "18px",
//         "fontWeight": "{'bold':0,'italic':0,'underline':0}",
//         "IconLink": "https://i.imgur.com/MotzIjQ.png",
//         "IconPosition": "Left",
//         "IconSize": "small",
//         "IconType": "Url",
//         "justifyContent": "Left"
//     },
//     "captionDivider": {
//         "borderColor": "#ffffff",
//         "borderWidth": "1px"
//     },
//     "link": {
//         "backgroundColor": "#ffffff",
//         "color": "#0000FF",
//         "fontFamily": "Arial",
//         "fontSize": "14px",
//         "fontWeight": "{'bold':0,'italic':0,'underline':1}",
//         "justifyContent": "Left"
//     },
//     "linkDivider": {
//         "borderColor": "#D3D3D3",
//         "borderWidth": "1px"
//     },
//     "node": {
//         // "backgroundColor": "#c7e7e2",
//         // "borderColor": "transparent",
//         "borderColor": "#ccc",
//         "borderRadius": "10px",
//         // "borderWidth": "1",
//         "borderWidth": "2",
//         "color": "#ffffff",
//         "fontFamily": "Arial",
//         "fontSize": "18px",
//         "fontWeight": "{'bold': 0, 'italic': 0, 'underline': 0 }",
//         "padding": "{'top':'0','topUnit':'px','right':'0','rightUnit':'px','bottom':'0','bottomUnit':'px','left':'0','leftUnit':'px'}"
//     }
// }


  // Function to get property value from properties array
  const nodeHeight = nodeData?.node_Height
    ? nodeData.node_Height + "px"
    : "125px";
  const nodeWidth = nodeData?.node_Width ? nodeData.node_Width + "px" : "240px";
  const handleCount = nodeData?.handles ? parseInt(nodeData.handles) : "4";

  // let captionFontWeight = getPropertyValue("Caption_Font_weight", properties);
  // captionFontWeight = captionFontWeight
  //   ? JSON.parse(captionFontWeight)
  //   : {
  //       bold: "0",
  //       italic: "0",
  //       underline: "0",
  //     };
  // Parse Icon related properties from properties
  // const iconType = getPropertyValue("Caption_IconType", properties);
  // const iconLink = getPropertyValue("Caption_IconLink", properties);
  // const iconPosition = getPropertyValue("Caption_IconPosition", properties);
  // const iconSize = getPropertyValue("Caption_IconSize", properties);
  // const iconSizeMapping = {
  //   small: "24px", // Adjust this value according to your needs
  //   medium: "36px", // Adjust this value according to your needs
  //   large: "48px", // Adjust this value according to your needs
  // };

  // const body =
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
  // const selectedItem = jsonData.find(
  //   (item) => item?.TaskTypeID?.toString() === nodeData?.taskTypeID?.toString()
  // );
  // const body = getPropertyValue("Body_Text", properties);
  // let bodyType = getPropertyValue("Body_type", properties);
  // if (bodyType === null) {
  //   bodyType = "0";
  // }
  const getFlexAlignItems = (alignment) => {
    switch (alignment?.toLowerCase()) {
      case "top":
        return "stretch";
      case "vcenter":
        return "stretch";
      case "bottom":
        return "stretch";
      case "vstretch":
        return "stretch";
      default:
        return "initial"; // or any default value you prefer
    }
  };
  const getFlexJustifyContent = (alignment) => {
    switch (alignment?.toLowerCase()) {
      case "left":
        return "flex-start";
      case "hcenter":
        return "center";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "initial"; // or any default value you prefer
    }
  };
  // const alignItemsValue = getFlexAlignItems(
  //   getPropertyValue("Body_Alignment_Vertical", properties)
  // );
  // const bodyHorizontalAlignment = getFlexJustifyContent(
  //   getPropertyValue("Body_Alignment_Horizontal", properties)
  // );
  // let bodyFontWeight = getPropertyValue("Body_Font_weight", properties);
  // bodyFontWeight = bodyFontWeight
  //   ? JSON.parse(bodyFontWeight)
  //   : {
  //       bold: "0",
  //       italic: "0",
  //       underline: "0",
  //     };
  // let textOverflow = getPropertyValue("Body_TextOverflow", properties);
  // if (textOverflow === null) {
  //   textOverflow = "1";
  // }
  // const url = getPropertyValue("Body_URL", properties);
  // const imageFit = getPropertyValue("Body_Image_Fit", properties);
  // let linkAlignment = getFlexJustifyContent(
  //   getPropertyValue("Link_Alignment_Horizontal", properties)
  // );
  // let linkFontWeight = getPropertyValue("Link_Font_weight", properties);
  // linkFontWeight = linkFontWeight
  //   ? JSON.parse(linkFontWeight)
  //   : {
  //       bold: "0",
  //       italic: "0",
  //       underline: "0",
  //     };

  const calculateHandlePosition = (index) => {
    const perSide = index % 4; // Assuming equal distribution on each side

    switch (perSide) {
      case 0: // Top side
        return Position.Top;
      case 1: // Bottom side
        return Position.Bottom;
      case 2: // Right side
        return Position.Right;
      case 3: // Left side
        return Position.Left;
      default:
        return Position.Right; // Default to right
    }
  };
  // Define handle positions based on handle count
  const handles = useMemo(
    () =>
      Array.from({ length: 4 }, (_, i) => {
        let floor = Math.floor(i / 4);
        const handleId = `handle-${i}`;
        return (
          <Handle
            key={handleId}
            id={handleId}
            type="source"
            position={calculateHandlePosition(i)}
            // className={nodeData?.nodeType === "0" ? "" : `${styles.handle}`}
            // style={
            //   i % 4 === 0 || i % 4 === 1
            //     ? { marginLeft: 20 * floor }
            //     : { marginTop: 20 * floor }
            // }
            isConnectable={
              nodeData?.isDisabled
                ? nodeData.isDisabled.toLowerCase() === "false"
                : true
            }
          />
        );
      }),
    [handleCount]
  );
  // const handles = useMemo(
  //   () =>
  //     Array.from({ length: handleCount }, (_, i) => {
  //       let floor = Math.floor(i / 4);
  //       const handleId = `handle-${i}`;
  //       return (
  //         <Handle
  //           key={handleId}
  //           id={handleId}
  //           type="source"
  //           position={calculateHandlePosition(i)}
  //           className={nodeData?.nodeType === "0" ? "" : `${styles.handle}`}
  //           style={
  //             i % 4 === 0 || i % 4 === 1
  //               ? { marginLeft: 20 * floor }
  //               : { marginTop: 20 * floor }
  //           }
  //           isConnectable={
  //             nodeData?.isDisabled
  //               ? nodeData.isDisabled.toLowerCase() === "false"
  //               : true
  //           }
  //         />
  //       );
  //     }),
  //   [handleCount]
  // );

  const rotateControlRef = useRef(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const [rotation, setRotation] = useState(0);
  useEffect(() => {
    if (!rotateControlRef.current) {
      return;
    }

    const selection = select(rotateControlRef.current);
    const dragHandler = drag().on("drag", (evt) => {
      const dx = evt.x - 100;
      const dy = evt.y - 100;
      const rad = Math.atan2(dx, dy);
      const deg = rad * (180 / Math.PI);
      setRotation(180 - deg);
      updateNodeInternals(id);
    });

    selection.call(dragHandler);
  }, [id, updateNodeInternals]);
  // const borderSize = getPropertyValue("Node_Border_size", properties) || "1";
  // const nodeBorderColor =
  //   getPropertyValue("Node_Border_color", properties) || "black";
  // let nodePadding = getPropertyValue("Node_Padding", properties);
  // nodePadding = nodePadding
  //   ? JSON.parse(nodePadding)
  //   : {
  //       top: "0",
  //       topUnit: "px",
  //       right: "0",
  //       rightUnit: "px",
  //       bottom: "0",
  //       bottomUnit: "px",
  //       left: "0",
  //       leftUnit: "px",
  //     };
  // let fontWeight = getPropertyValue("Node_Font_weight", properties);
  // fontWeight = fontWeight
  //   ? JSON.parse(fontWeight)
  //   : {
  //       bold: "0",
  //       italic: "0",
  //       underline: "0",
  //     };

  // let captionDividerSize = getPropertyValue("Caption_Divider_size", properties);
  // let captionDividerColor = getPropertyValue(
  //   "Caption_Divider_color",
  //   properties
  // );
  // let linkDividerSize = getPropertyValue("Link_Divider_size", properties);
  // let linkDividerColor = getPropertyValue("Link_Divider_color", properties);
  // Remove the resizeObserver error
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);
  const IconElement = React.memo(
    ({ iconSize, displayBootstrapIcon, displayUrlIcon, IconLink }) => (
      <>
        {displayBootstrapIcon === "flex" && (
          <i
            className={`glyphicon ${IconLink}`}
            style={{ fontSize: iconSize, display: displayBootstrapIcon }}
          />
        )}
        {displayUrlIcon === "flex" && (
          <img
            src={IconLink}
            alt="icon"
            style={{
              width: iconSize,
              height: iconSize,
              display: displayUrlIcon,
            }}
          />
        )}
      </>
    )
  );
  const iconProps = useMemo(
    () => ({
      iconSize:
        properties?.caption?.IconSize === "large"
          ? "48px"
          : properties?.caption?.IconSize === "medium"
            ? "36px"
            : "24px",
      displayBootstrapIcon:
        properties?.caption?.IconType === "Bootstrap" ? "flex" : "none",
      displayUrlIcon: properties?.caption?.IconType === "Url" ? "flex" : "none",
      IconLink: properties?.caption?.IconLink,
    }),
    [
      properties?.caption?.IconSize,
      properties?.caption?.IconType,
      properties?.caption?.IconLink,
    ]
  );
  const displayProperties = useMemo(
    () => ({
      text:
        properties?.body?.bodyType !== "1" && properties?.body?.bodyType !== "2"
          ? "block"
          : "none",
      html: properties?.body?.bodyType === "1" ? "block" : "none",
      image: properties?.body?.bodyType === "2" ? "block" : "none",
    }),
    [properties?.body?.bodyType]
  );

  return (
    <>
      {/* NODE */}
      <div className="dynamic-height-node"

        style={{
          ...(properties?.node || {}),
          width: "100%",
          // height: "100%",
          height:"auto",
          borderStyle: "solid",
          padding:"5px",
          transform: `rotate(${rotation}deg)`,
          opacity: nodeData?.isDisabled?.toLowerCase() === "true" ? 0.5 : 1,
          borderStyle: "solid",
          border : selected ? "2px solid #1565C0" : "2px solid #A9A9A9"
        }}
      >
        <NodeResizer isVisible={selected} minWidth={100} minHeight={30} className="custom-resizer"/>
        <div
          ref={rotateControlRef}
          style={{
            display: selected ? "block" : "none",
          }}
          className={`nodrag ${styles.rotateHandle}`}
        />
        {handles}
        {/* CAPTION */}

        <div className="caption-inside-text"
          style={{
            ...(properties?.caption || {}),
            display: "flex",
            borderRadius: properties?.node?.borderRadius ?? "0px",
            flexDirection : "column"
          }}
        >
          {/* {properties.caption.IconPosition === "flex-start" && ( */}
          <div style={{display:"flex",}}>
          <div style={properties.fileIcon}>
          {properties?.caption?.IconPosition === "Left" && (
            <IconElement {...iconProps} />
          )}
          </div>
          
          <div style={{...properties?.caption, ...properties.captionSpan}}>
            <div>
            <div>
            {properties?.caption?.IconPosition === "Center" && (
              <IconElement {...iconProps} />
            )}
            </div>
            <div style={properties.nodecategory}>
            {nodeData?.category && nodeData.category}
              </div>
            <div style={properties.nodename}>
            {nodeData?.caption? nodeData.caption : nodeData?.name && nodeData.name}
            </div>
            </div>
            </div>
          </div>
          
          {properties?.caption?.IconPosition === "Right" && (
            <IconElement {...iconProps} />
          )}
         
          <div style={properties.nodedescription} >
              {nodeData?.description && nodeData.description}
              </div>
        </div>
        {/* CAPTION DIVIDER */}
        <div
          style={{
            ...(properties?.captionDivider || {}),
            // borderStyle: properties?.captionDivider ? "solid" : "none",
          }}
        />
        {/* BODY */}
        <div style={properties?.body || {}}>
          {properties?.body?.bodyType === "1" ? (
            <div
              dangerouslySetInnerHTML={{ __html: properties?.body?.content }}
              style={{ display: displayProperties?.html }}
            />
          ) : properties?.body?.bodyType === "2" ? (
            <img
              src={properties?.body?.content}
              alt="icon"
              style={{ display: displayProperties?.image }}
            />
          ) : (
            <>
              <div style={{ display: displayProperties?.text }}>
                {properties?.body?.content}
              </div>
            </>
          )}
        </div>
        {/* LINK DIVIDER */}
        <div
          style={{
            ...(properties?.linkDivider || {}),
            // borderStyle: properties?.linkDivider ? "solid" : "none",
          }}
        />
        {/* LINK */}
        {/* <div
          style={{
            display: "flex",
            cursor: "pointer",
            ...properties.link,
          }}
        >
          <a href="www.example.com" style={properties.link}>
            Example link
          </a>
        </div> */}
      </div>
    </>
  );
};

export default memo(DynamicCustomNode);


