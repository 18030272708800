import React, { useState } from "react";
import {
  Box,
  Typography,
  Collapse,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";

const DiagramBuilderSidebar = () => {
  const [isParametersCollapsed, setIsParametersCollapsed] = useState(false);
  const [isNotesCollapsed, setIsNotesCollapsed] = useState(false);
  const [userNotes, setUserNotes] = useState("");

  const toggleParametersCollapse = () => {
    setIsParametersCollapsed(!isParametersCollapsed);
  };

  const toggleNotesCollapse = () => {
    setIsNotesCollapsed(!isNotesCollapsed);
  };

  const handleNotesChange = (event) => {
    setUserNotes(event.target.value);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center" gap={2}>
            <img
              src="/api/placeholder/48/48"
              alt="Node Icon"
              style={{ width: 48, height: 48 }}
            />
            <Typography variant="h5">Node Name</Typography>
          </Box>
        }
      />
      <CardContent>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6">Parameters</Typography>
            <Button onClick={toggleParametersCollapse}>
              {isParametersCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
          </Box>
          <Collapse in={!isParametersCollapsed}>
            <Box display="grid" gap={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">Parameter 1</Typography>
                <Box position="relative" width="100%">
                  <TextField
                    variant="outlined"
                    placeholder="Enter parameter 1"
                    InputProps={{
                      endAdornment: (
                        <Button>
                          <InfoIcon />
                        </Button>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="start"
                justifyContent="space-between"
              >
                <Typography variant="body1">Parameter 2</Typography>
                <Box position="relative" width="100%">
                  <TextField
                    variant="outlined"
                    placeholder="Enter parameter 2"
                    multiline
                    rows={2}
                    InputProps={{
                      endAdornment: (
                        <Button>
                          <InfoIcon />
                        </Button>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Collapse>
        </Box>

        <Box mt={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6">User Notes</Typography>
            <Button onClick={toggleNotesCollapse}>
              {isNotesCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
          </Box>
          <Collapse in={!isNotesCollapsed}>
            <TextField
              variant="outlined"
              placeholder="Enter user notes..."
              multiline
              rows={3}
              value={userNotes}
              onChange={handleNotesChange}
              fullWidth
            />
          </Collapse>
        </Box>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button variant="contained" color="primary">
            Save
          </Button>
          <Button variant="outlined" color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="success">
            Preview
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default DiagramBuilderSidebar;
