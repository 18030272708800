import React from "react";
import { ContainerStyle, StyledDiv } from "./LoginStyles";
import { Box, useMediaQuery } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import BottomBanner from "../../Components/loginComponents/BottomBanner";
import RotatingBanner from "../../Components/loginComponents/RotatingBanner";
import LoginCard from "../../Components/loginComponents/LoginCard";

function LoginView(props) {
  const images = [
    "https://agiline.com/wp-content/uploads/data_experience_banner-change-1a.png",
    "https://agiline.com/wp-content/uploads/aiConnect_page_v1.png",
    "https://agiline.com/wp-content/uploads/banner-CV360-1.jpg",
  ];
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <StyledDiv>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          width="100%"
        >
          <ContainerStyle>
            <LoginCard {...props} />
          </ContainerStyle>
          {!isSmallScreen && (
            <Box ml={5} style={{ width: "45vw" }}>
              <Box mb={2}>
                <RotatingBanner images={images} />
              </Box>
              <Box>
                <BottomBanner
                  gifSrc={
                    "https://lh3.googleusercontent.com/a-/ALV-UjXaIG9JZq7zuZgQOWeOYbzrR2Gr5PYhYLgCOdC2lRvvoF1WZpyU=s64-p-k-rw-no"
                  }
                >
                  Agiline Software is a technology company specializing in
                  custom software development, IT consulting, and enterprise
                  solutions. They serve industries like healthcare, education,
                  and finance, focusing on delivering tailored software that
                  meets specific business needs. With expertise in .NET, Java,
                  cloud services, and mobile development, Agiline emphasizes
                  quality and innovation. The company uses agile methodologies
                  to ensure efficient and timely project delivery, supporting
                  clients in their digital transformation with scalable and
                  secure solutions.
                </BottomBanner>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </StyledDiv>
  );
}

export default LoginView;
