import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { Theme } from "../Components/IncludeFile/Theme";
import { ReadArray } from "../Components/Api/ApiCalls";

import { toast } from "react-toastify";
import { useAppState } from "./AppStateProvider ";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import { makeStyles } from "@mui/styles";
import DiscoverEdit from "./DiscoverEdit";
import Discover from "./Discover";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const CreateInfo = ({authData}) => {

  const location = useLocation();
  const ArID = location.state ? location.state.ArID : null;
  const arrayName = location.state ? location.state.ArrayName : null;
  const description = location.state ? location.state.Description : null;
  const category = location.state ? location.state.Category : null;
  const dataSourceArID = location.state ? location.state.DataSourceArID : null;

  const [isSaved, setIsSaved] = useState(false);
  const [editedArrayName, setEditedArrayName] = useState(arrayName);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedcategory, seteditedcategory] = useState(category);
  const [editeddataSourceArID, setediteddataSourceArID] =
    useState(dataSourceArID);
  const [isOpen, setIsOpen] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [arrayNameError, setArrayNameError] = useState("");
  const [dataSourceArrayName, setDataSourceArrayName] = useState("");
  const { appState, setArrayData } = useAppState();
  const [showDiscoverEdit, setShowDiscoverEdit] = useState(false);
  const [source, setSource] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [arID, setArID] = useState(null);
  const [isOpenDiscoverEdit, setIsOpenDiscoverEdit] = useState(true);
  const [loading, setLoading] = useState(false);

  // const loginData = JSON.parse(sessionStorage.getItem("loginData"));

  let accessKey = authData.accessKey

  let APIProvider = window.APIProvider;

  const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`


  const handleOpenDiscoverEdit = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }

    setLoading(true);
    try {
      if (ArID) {

        const jsonData = await ReadArray(ArID, accessKey);
       

        if (jsonData) {
          await handleEditArray(
            arID,
            editedArrayName,
            editedDescription,
            editedcategory,
            editeddataSourceArID
          );
          setEditedArrayName(editedArrayName);
          setEditedDescription(editedDescription);
          seteditedcategory(editedcategory);
          setediteddataSourceArID(editeddataSourceArID);
        } else {
          console.error("Error: Unable to fetch jsonData");
          toast.error("Error: Unable to fetch jsonData");
        }
      } else {
        console.error("Error: Unable to fetch arID");
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error:");
    }

    try {
      const requestBodyarrayname = {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.ReadArrayByNameGUID,
        param1: editedArrayName,
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: "",
      };

      const fetchDataResponse = await fetch(
        baseURL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBodyarrayname),
        }
      );

      if (fetchDataResponse.ok) {
        const resultData = await fetchDataResponse.json();


        const parsedResponse = JSON.parse(resultData.apiResponse);
        const arID = parsedResponse[0].ArID;

        const newArrayName = parsedResponse[0].arrayName;
        const newDescription = parsedResponse[0].description;
        const newCategory = parsedResponse[0].category;
        const newdataSourceArID = parsedResponse[0].dataSourceArID;

        const jsonData = await ReadArray(arID, accessKey);
          const sourceData = jsonData[0].Source;
          const parsedSourceData = sourceData;

          if (parsedSourceData[0]?.control?.length === 0) {
            navigate(`/Form/${arID}`, {
              state: {
                ArID: arID,
                ArrayName: newArrayName,
                Description: newDescription,
                Category: newCategory,
                Source: JSON.stringify(sourceData),
                IsTemplate: "0",
                DataSourceArID: newdataSourceArID,
        
              },
            });
            setLoading(false);
            setShowDiscoverEdit(false);
            setIsOpen(false);
            setIsOpenDiscoverEdit(false);
          } else {
            setArrayData(
              newArrayName,
              newDescription,
              newCategory,
              newdataSourceArID
            );
            setLoading(false);
            setShowDiscoverEdit(true);
            setIsOpen(true);
            setIsOpenDiscoverEdit(true);
          }
        
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error:");
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    setIsSaved(false);
  };

  const handleCancelClick = () => {
    setEditedArrayName(arrayName);
    setEditedDescription(description);
    seteditedcategory(category);
    setediteddataSourceArID(dataSourceArID);
    setArrayNameError("");
    setLoading(false);
    setIsOpenDiscoverEdit(false);
  };

  const handleEditArray = async (
    arID,
    editedArrayName,
    editedDescription,
    editedCategory,
    editeddataSourceArID
  ) => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }

    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      } else {
        setArrayNameError("");
      }

      const requestBody = {
        accessKey: authData.accessKey,
        guid:APIProvider.GUID.EditArrayGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: JSON.stringify({
          ArID: arID,
          ArrayName: editedArrayName,
          Description: editedDescription,
          Category: editedCategory,
          IsTemplate: "0",
          DataSourceArID: editeddataSourceArID,
        }),
      };
      const response = await fetch(
      `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
      } else {
        console.error("Failed to edit item.");
        toast.error("Failed to edit item.");
        const responseBody = await response.text();
        console.error("Response Body:", responseBody);
        toast.error("Response Body:");
      }
    } catch (error) {
      console.error("Error editing item:", error);
      toast.error("Error editing item:");
    }
  };

  const navigate = useNavigate();

  const handleSave = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }

    setIsSaved(true);
    setIsEditMode(false);

    const requestarrayname = {
      accessKey: authData.accessKey,
      guid: APIProvider.GUID.ReadArrayByNameGUID,
      param1: arrayName,
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: "",
    };
    try {
      const fetchDataResponse1 = await fetch(
        baseURL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestarrayname),
        }
      );

      if (!fetchDataResponse1.ok) {
        throw new Error(
          "Error fetching data based on arrayName: " +
            fetchDataResponse1.statusText
        );
      }
      const readData = await fetchDataResponse1.json();
      const resultData1 = JSON.parse(readData.apiResponse);
      const arID1 = resultData1[0].ArID;

      if (!arID1) {
        throw new Error("Error: Unable to fetch arID");
      }
      const parsedApiResponse = await ReadArray(arID1,accessKey);
      const jsonData = parsedApiResponse[0].arid;
      if (!jsonData) {
        throw new Error("Error: Unable to fetch jsonData");
      }

      await handleEditArray(
        arID1,
        editedArrayName,
        editedDescription,
        editedcategory,
        editeddataSourceArID
      );

      setEditedArrayName(editedArrayName);
      setEditedDescription(editedDescription);
      seteditedcategory(editedcategory);
      setediteddataSourceArID(editeddataSourceArID);
 
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error: " + error.message);
      return;
    }
  };

  const [fetched1, setFetch] = useState(false);

  useEffect(() => {
    const fetchDataSourceArrayName = async (dataSourceArID) => {
      try {
        setFetch(true);
        const jsonData = await ReadArray(dataSourceArID,accessKey);
        const arrayName = jsonData[0].arrayName
        setDataSourceArrayName(arrayName);
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        setFetch(false);
      }
    };

    fetchDataSourceArrayName(editeddataSourceArID);
  }, [dataSourceArID]);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {

    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    fetchDataarrayname();
  }, []);

  const GetReadArrayAll = {
    accessKey: authData.accessKey,
    guid: APIProvider.GUID.ReadArrayAllGUID,
    param1: "",
    param2: "",
    param3: "",
    param4: "",
    recordSet: "",
    body: "",
  };


  const fetchDataarrayname = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    
    try {
      const response = await fetch(
        baseURL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(GetReadArrayAll),
        }
      );

      if (response.ok) {
        const resultData = await response.json();

        if (resultData.apiResponse) {
          const data = JSON.parse(resultData.apiResponse);
          const filteredJsonData = data.filter(
            (item) => Number(item.IsTemplate) === 2
          );
          setFilteredData(filteredJsonData);

        } else {
          console.error("Error: apiResponse is missing");
        }
      } else {
        console.error("Failed to fetch array names");
      }
    } catch (error) {
      console.error("Error fetching array names:", error);
    }
  };
  const handleSelectChange = (event) => {
    const selectedArID = event.target.value;
    const selectedItem = filteredData.find(
      (item) => item.arID === parseInt(selectedArID)
    );
    setSelectedItem(selectedItem);
    setediteddataSourceArID(selectedArID);
  };

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Refersh",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSave();
      },
      tooltip: "Savedsdfgh",
    },
  ];

  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  const classes = useStyles();

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleArrayNameChange = (e) => {
    const trimmedValue = e.target.value;
    setEditedArrayName(trimmedValue);
    setArrayNameError("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestBodyarrayname = {
          accessKey: authData.accessKey,
          guid: APIProvider.GUID.ReadArrayByNameGUID,
          param1: arrayName,
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: "",
        };
        const fetchDataResponse = await fetch(
          baseURL,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBodyarrayname),
          }
        );

        if (fetchDataResponse.ok) {
          const readData = await fetchDataResponse.json();
          const resultData = JSON.parse(readData.apiResponse);
          const arID = resultData[0].ArID;

          if (arID) {
            const jsonData = await ReadArray(arID,accessKey);
              const sourceData = jsonData[0].Source;
              setSource(sourceData);
              setSourceData(sourceData);
              setArID(arID);
           
          } else {
            console.error("Error: Unable to fetch arID");
          }
        } else {
          console.error(
            "Error fetching data based on arrayName:",
            fetchDataResponse.statusText
          );
          toast.error("Error fetching data based on arrayName:");
        }
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("Error:");
      }
    };

    fetchData();
  }, []);


const [dataSourceName, setDataSourceName] = useState("")

  const fetchDataSource = async () => {
    try {
      const jsonData = await ReadArray(editeddataSourceArID || dataSourceArID, accessKey);
      setDataSourceName(jsonData[0].arrayName)
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  
  fetchDataSource();

  return (
    <CustomPopUpForm
      open={isOpen}
      onClose={handleCancel}
      title="Overview"
      iconButtons={isEditMode ? iconButtonsSaveAndRestore : iconButtonsEdit}
      height="60%"
    >
      <>
        {!isEditMode ? (
          <>
            <div className="card-info card-info-ovr-flow">
              <div className="card-info-inside card-info-inside-view">
                <Typography className="card-info-inside-text">
                  Form Name*:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedArrayName}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Description:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedDescription}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Category:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedcategory}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  DataSourceField:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {fetched1 ? "Loading..." : dataSourceName}
                  </Typography>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card-info">
            <div className="card-info-inside card-info-inside-view">
              <Typography style={{ fontWeight: "bold" }}>
                Form Name*:
              </Typography>
              <TextField
                required
                type="text"
                className="edit-popup"
                name="Form Name"
                onChange={handleArrayNameChange}
                value={editedArrayName}
                error={!!arrayNameError}
                helperText={arrayNameError}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Description:
              </Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Description"
                onChange={(e) => setEditedDescription(e.target.value)}
                value={editedDescription}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Category:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Category"
                value={editedcategory}
                style={{ backgroundColor: "#bdbdbd" }}
                readOnly
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                DataSourceField:
              </Typography>
              <Select
                onChange={handleSelectChange}
                value={editeddataSourceArID || "0"}
                className={`${classes.customSelectRoot} data-select-crd`}
              >
                <MenuItem value="0">Select DataSource</MenuItem>
                {filteredData.map((item) => (
                  <MenuItem key={item.ArID} value={item.ArID}>
                    {item.ArrayName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </>
      <>
        <div style={{ width: "96%", display: "flex" }}>
          <Button fullWidth onClick={handleCancel}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleOpenDiscoverEdit}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
          </Button>
        </div>
        {showDiscoverEdit && (
          <DiscoverEdit
            showDiscoverEdit={showDiscoverEdit}
            setShowDiscoverEdit={setShowDiscoverEdit}
            arrayName={editedArrayName}
            description={editedDescription}
            category={editedcategory}
            dataSourceArID={editeddataSourceArID}
            source={source}
            sourceData={sourceData}
            arID={arID}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isOpenDiscoverEdit={isOpenDiscoverEdit}
            setIsOpenDiscoverEdit={setIsOpenDiscoverEdit}
            authData={authData}
          />
        )}
      </>
    </CustomPopUpForm>
  );
};

export default CreateInfo;
