import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Star, StarBorder } from "@mui/icons-material";
import {
  IconButton,
  ThemeProvider,
  Card,
  CardActionArea,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";

function getPropertyValue(data, propertyName) {
  const foundItem = data.find((item) => item.propertyName === propertyName);
  return foundItem ? foundItem.diagPropvalue : null;
}

export const NodePanel = ({
  title,
  items,
  collapsed,
  onToggle,
  onDragStart,
  toggleFavorite,
  favoriteNodes,
  groupedNodes,
  properties,
}) => {
 

  let nodeNamecolor = ''
  
  if (title === "Favorites") {
    nodeNamecolor = "#fff";
  } else if (properties[title] && properties[title].nodename) {
    nodeNamecolor = properties[title].nodename.color;
  }
  
  let nodebackgrounColor = "";

  if (title) {
    if (title === "Favorites") {
      nodebackgrounColor = "#1976D2";
    } else {
      const fileNodes = groupedNodes[title];
      fileNodes.forEach((node) => {
        const backgroundStyle = node.style.find(
          (style) => style.propertyName === "Node_Background"
        );
        if (backgroundStyle) {
          nodebackgrounColor = backgroundStyle.diagPropvalue;
        }
      });
    }
  }

  return (
    <div> 
    <div className="node-left-panel"
      style={{
        display: "flex",
        flexDirection: "row",
        // fontSize: "medium",
        fontSize: "small",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "0.2rem",
        cursor:"pointer",
        backgroundColor : nodebackgrounColor
      }}
      onClick={onToggle}
    >
      <div style={{color : nodeNamecolor}}>{title}</div>
      <div>
        <IconButton>
          {collapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </IconButton>
      </div>
    </div>
    {!collapsed && (
      <div className="node-dnd">
        {items.map((node, index) => {
          const borderColor =
            getPropertyValue(node?.style, "Node_Border_color") === "transparent"
              ? getPropertyValue(node?.style, "Node_Background")
              : getPropertyValue(node?.style, "Node_Border_color");

          // Check if the current node is a favorite
          const isFavorite = favoriteNodes.some(
            (favNode) => favNode.diagtoolid === node.diagtoolid
          );

          return (
            <Card key={index} className="dndnode node-dnd-drpdn" sx={{ display: "flex" }}>
           
              <Box
                sx={{
                  width: "9%", // 1/12 of the card width
                  backgroundColor: borderColor,
                  flexShrink: 0,
                }}
              />

              <CardActionArea
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // p: 1,
                  width: "92%",
                  padding:"4px"
                }} // Remaining 11/12 width
                draggable
                onDragStart={(event) => onDragStart(event, node)}
              >
                {getPropertyValue(node?.style, "Caption_IconType") === "Url" &&
                  getPropertyValue(node?.style, "Caption_IconLink") !== "" && (
                    <img
                      src={getPropertyValue(node?.style, "Caption_IconLink")}
                      alt="icon"
                      width={12.5}
                      height={12.5}
                      style={{ marginRight: "0.5rem" }}
                    />
                  )}
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {node?.caption ? node?.caption : node?.name}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Tooltip
                  title={
                    isFavorite ? "Remove from favorites" : "Add to favorites"
                  }
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleFavorite(node?.diagtoolid);
                    }}
                  >
                    {isFavorite ? (
                      <Star fontSize="small" sx={{ color: "#FFD700" }} />
                    ) : (
                      <StarBorder fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </CardActionArea>
            </Card>
          );
        })}
      </div>
    )}
  </div>
  );
};
