import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrOpenDiag from "../../../PopUpForm/CreateOrOpenDiag";
import themeProvider from "../../../ThemeProvider";
import { ThemeProvider, Button } from "@mui/material";
import NavBar from "../../navbarComponents/NavBar";
import { Theme } from "../../IncludeFile/Theme";
import { useMenu } from "../../../reusableComponents/menuUtils";
import {
  createRequestBody,
  postResource,
  getAccessKey,
  updateDataWithUserAndDates,
  loadLoginData,
} from "../../../apiCalls/DiagramsAPI";
import Overview from "../Overview";
import { ToastContainer, toast } from "react-toastify";
import DiagHeader from "../DiagHeader";
import { useAppContext } from "../RegionContext";
import { getFormattedLocalDateTime } from "../../../functions/ModifiedDate";

function DiagramsRenderer() {
  const navigate = useNavigate();

  // const { getRegionInfo } = useAppContext();
  const [regionInfo, setRegionInfo] = useState(null);

  const [regions, setRegions] = React.useState([]);
 
  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";


  const fetchRegions1 = React.useCallback(async () => {
    if (!accessKey) {
      console.error("No access key available");
    }
  
    // setIsLoading(true);
    try {
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody( {
      accessKey: accessKey,
      guid: "Get Regions",  
      param1: "",           
      param2: "",          
      param3: "",            
      param4: "",            
      recordSet: "",         
      body: ""               
    })
      );
  
      setRegions(response);
      // setError(null);
      return response;
    } catch (err) {

    } finally {
      // setIsLoading(false);
    }
  }, [accessKey]);


  const getRegionInfo = useCallback(
    async (regionName) => {
      if (!regionName) return "HI";

      // Fetch regions if not already loaded
      if (!regions.length) {
        const ret = await fetchRegions1();

        console.log("ret12121",ret)
        if (ret) {
          return (
            ret.find(
              (region) =>
                region.Region.toLowerCase() === regionName.toLowerCase()
            ) || "1"
          );
        }
      } else {
        return (
          regions.find(
            (region) => region.Region.toLowerCase() === regionName.toLowerCase()
          ) || "2"
        );
      }
    },
    [regions, fetchRegions1]
  );


  // const storedRegion = localStorage.getItem("region");
  // var regionInfo;
  // if (storedRegion) {
  //   regionInfo = getRegionInfo(storedRegion);
  //   console.log("regionInfo ", regionInfo);
  // } else {
  //   console.log("No region stored in localStorage");
  // }
  // React.useEffect(() => {
  // const fetchRegions = async () => {
  //   try {
  //     const storedRegion = localStorage.getItem("region");
  //     const info = getRegionInfo(storedRegion);
  //     console.log("Region Names:", info);
  //     setRegionInfo(info);
  //   } catch (error) {
  //     console.error("Error fetching regions:", error);
  //   }
  // };

  //   fetchRegions();
  // }, []);
  //API Variables
  //selected templates in the left sidebar
  const [selected, setSelected] = useState([]);
  //all the templates and projects
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);

  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      const info = await getRegionInfo(storedRegion);
      console.log("Region Names:", info);
      setRegionInfo(info);

      // Call fetchData only if info is valid and fetched is false
      if (!fetched && info) {
        console.log("Calling fetchData with info:", info);
        fetchData(info);
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  useEffect(() => {
    fetchRegions(); // Call fetchRegions once on component load
  }, []);

  //get templates and projects
  async function fetchData(info) {
    try {
      //Diagram List Project Page or Diagram List Project Page - Region AI UI
      // const accessKey = getAccessKey();
   
      const templates = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${info.API1}`,
        })
      );
      const selectedRegion = localStorage.getItem("region");
      const regionTemplates = templates?.filter(
        (item) => item?.region?.toLowerCase() === selectedRegion?.toLowerCase()
      );
      console.log("regionTemplates ", regionTemplates);
      setData(regionTemplates);
      console.log("JSON ", JSON.stringify(regionTemplates[0]));
      setSelected(regionTemplates);
      console.log("fetched ", fetched);
      setFetched(true);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error getting templates: " + error.message);
    }
  }
  //for CreateOrOpenDiag
  //selected template/project (for overview)
  const [selectedItem, setSelectedItem] = useState(null);

  //set selected template/project and open overview
  const handleDetails = (item) => {
    setSelectedItem(item);
    handlePopup();
  };

  //select project and go to /Diagrams
  const handleEditDiagram = async (item) => {
    const selectedProject = data.find((obj) => obj.diagID === item.diagID);
    navigate("/Diagrams", {
      state: { project: selectedProject },
    });
  };
  //Popup Variables
  const [open, setOpen] = useState(false);
  const [openDiagHeader, setOpenDiagHeader] = useState(false);

  const handlePopup = () => {
    setOpen(!open);
  };
  const handleDiagHeaderPopup = () => {
    setOpenDiagHeader(!openDiagHeader);
  };

  const editTemplate = (tempID) => {
    navigate("/ToolSelector", {
      state: { DiagTempID: tempID },
    });
  };

  //popup bottom buttons
  const handleBack = () => {
    setSelectedItem(null);
    setOpenDiagHeader(false);
    handlePopup();
  };
  const handleDiagHeaderBack = () => {
    handleDiagHeaderPopup();
  };

  const handleNext = () => {
    if (selectedItem.diagID === 0) {
      setOpen(false);
      setOpenDiagHeader(true);
    } else {
      handleEditDiagram(selectedItem);
    }
  };
  const handleDiagHeaderNext = async (data) => {
    try {
      const updatedData = updateDataWithUserAndDates(data);
      // const accessKey = getAccessKey();
      updatedData.description = updatedData.explanation;
      console.log("regionInfo ", regionInfo);
      updatedData.region = regionInfo.Region;
      const projectPostResponse = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.UpsertQinfoDiagHeader}`,
          body: JSON.stringify(data),
        })
      );
      if (projectPostResponse.Status === "ERROR") {
        toast.error("Error getting recourse: ");
      } else {
        toast.success("Saved!");
        handleEditDiagram(updatedData);
      }
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  };

  //navbar button
  //search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  //back
  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  //close left sidebar from the navbar
  const { isMenuOpenleft, toggleMenu } = useMenu();

  const handleSave = async (data) => {
    try {
      console.log("data Before", data);
      // const loginData = JSON.parse(sessionStorage.getItem("loginData"));
      const userName = authData?.userName || "Unknown User";
      const todayDate = getFormattedLocalDateTime();
      console.log("todayDate ", todayDate);
      if (!data.createdBy) {
        console.log("data.createdBy ", data.createdBy);
        data.createdBy = userName;
      }
      if (!data.createdDate) {
        console.log("data.createdDate ", data.createdDate);
        data.createdDate = todayDate;
      }
      // Update 'modifiedBy' and 'modifiedDate'
      data.modifiedBy = userName;
      data.modifiedDate = todayDate;
      data.region = regionInfo.Region;
      console.log("data After", data);
      // const accessKey = getAccessKey();
      const templatePostResponse = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.UpsertQinfoDiagTemplateHeader}`,
          body: JSON.stringify(data),
        })
      );
      console.log("templatePostResponse ", templatePostResponse);
      data.description = data.explanation;
      const projectPostResponse = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.UpsertQinfoDiagHeader}`,
          body: JSON.stringify(data),
        })
      );
      if (
        templatePostResponse.Status === "ERROR" &&
        projectPostResponse.Status === "ERROR"
      ) {
        toast.error("Error getting recourse: ");
      } else {
        toast.success("Saved!");
      }
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  };

  const [overviewDirtyFlag, setOverviewDirtyFlag] = useState(false);
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        isMenuOpenleft={true}
        toggleMenu={toggleMenu}
        title={`Choose ${localStorage.getItem("region")}Template`}
      />
      <CreateOrOpenDiag
        handleDetails={handleDetails}
        handleEditDiagram={handleEditDiagram}
        searchQuery={searchQuery}
        caller={1}
        isMenuOpenleft={isMenuOpenleft}
        selected={selected}
        setSelected={setSelected}
        data={data}
        fetched={fetchData}
        editTemplate={editTemplate}
      />
      {open && (
        <Overview
          open={open}
          handlePopup={handlePopup}
          item={selectedItem}
          handleBack={handleBack}
          handleNext={handleNext}
          handleSave={handleSave}
          data={data}
          overviewDirtyFlag={overviewDirtyFlag}
          setOverviewDirtyFlag={setOverviewDirtyFlag}
        />
      )}
     
      {openDiagHeader && (
        <DiagHeader
          open={openDiagHeader}
          handlePopup={handleDiagHeaderPopup}
          item={selectedItem}
          handleBack={handleDiagHeaderBack}
          handleNext={handleDiagHeaderNext}
          data={data}
        />
      )}
    </ThemeProvider>
  );
}

export default DiagramsRenderer;
