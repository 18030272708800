import React, { useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TabsComponent from "../TabsComponent";
// import { getPropertyValue } from "../../../functions/DragnDrop";
import Conditions from "./conditions/Conditions";
import Trigger from "./triggerNodeComponents/Trigger";
import Wait from "./waitNodeComponents/Wait";
import ConditionalSplit from "./conditionalNodeComponents/ConditionalSplit";
import SendEmail from "./sendEmailComponents/SendEmail";
import ScheduleSelector from "../Crono.js/CronExpression";
import ParamsComponent from "./ParamsComponent";
import EditNodeIcon from "../../../assets/EditNodeIcon.png";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DrawerComponent from "../DrawerComponent";

const NodeDrawerComponent = ({
  isOpen,
  onClose,
  tabValue,
  setTabValue,
  nodes,
  setNodes,
  selectedNode,
  edges,
  setEdges,
  clickedEdge,
  setClickedEdge,
  diagramDetails,
  setDiagramDetails,
  data,
  setSelectedNode,
  region
}) => {
  
  const isNodeSelected = !!selectedNode && !!selectedNode.data;
  const isEdgeSelected = !!clickedEdge;
  if (!isNodeSelected && !isEdgeSelected) {
    return null;
  }

  const tabs = [
    { label: "Information", value: "1" },
    { label: "Styles", value: "2" },
  ];

  const handleCancelNode = () => {
    setSelectedNode(null);
    setClickedEdge(null);
  };

  const handledeleteNode = () => {
    const updatedNodes = nodes.filter((node) => node.id !== selectedNode.id);
    const updateddiagramDetails = diagramDetails.filter(
      (node) => node.id !== selectedNode.id
    );
    setNodes(updatedNodes);
    setDiagramDetails(updateddiagramDetails);
    setSelectedNode(null);
  };

  const iconType = selectedNode?.data?.[1]?.caption?.IconType;
  const iconLink = selectedNode?.data?.[1]?.caption?.IconLink;
  const iconSize = selectedNode?.data?.[1]?.caption?.IconSize;
  const iconSizeMapping = {
    small: "24px",
    medium: "36px",
    large: "48px",
  };

  return (
    <div style={{ width: "500px", padding: "16px" , overflow : "auto" }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
     

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleCancelNode}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <TabsComponent tabValue={tabValue} setTabValue={setTabValue} tabs={tabs}>
      {isNodeSelected && (
        <div key="1">
           {isNodeSelected && (
            <div style={{display : "flex"}}>
      <div
          style={{ display: "flex", alignItems: "center", fontSize: "15px" }}
        >
          {iconType === "Url" && iconLink && (
            <img
              src={iconLink}
              alt="Icon"
              style={{
                width: iconSizeMapping[iconSize],
                marginRight: "0.5rem",
              }}
            />
          )}

          {iconType === "Bootstrap" && iconLink && (
            <i
              className={`fa fa-${iconLink}`}
              aria-hidden="true"
              style={{ marginRight: "0.5rem" }}
            ></i>
          )}

          {(!iconType || !iconLink) && (
            <img
              src={EditNodeIcon}
              alt="Icon"
              style={{
                width: "30px",
                marginRight: "0.5rem",
              }}
            />
          )}

          {selectedNode.data[0].caption}
        </div>
          <IconButton onClick={handledeleteNode}>
          <DeleteForeverIcon />
        </IconButton>
        </div>
      )}
          {selectedNode.data[0].diagToolID === "24" && <Trigger />}
          {selectedNode.data[0].diagToolID === "26" && <ConditionalSplit />}
          {selectedNode.data[0].diagToolID === "27" && <Wait />}
          {selectedNode.data[0].diagToolID === "28" && <SendEmail />}
          {selectedNode.data[0].diagToolID === "23" && <ScheduleSelector />}
          {region.toLowerCase() === "ai ui" && (
            <ParamsComponent
              nodes={nodes}
              setNodes={setNodes}
              nodeData={data}
              selectedNode={selectedNode}
              // data={selectedItem ? selectedItem : null}
              // diagramDetails={diagramDetails}
              // setDiagramDetails={setDiagramDetails}
              onClose={onClose}
            />
          )}
        </div>
      )}
       

        {isEdgeSelected && (
        <div key="2" style={{ width: "500px", padding: "16px" }}>
          <DrawerComponent
            edges={edges}
            setEdges={setEdges}
            clickedEdge={clickedEdge}
            diagramDetails={diagramDetails}
            setDiagramDetails={setDiagramDetails}
            setClickedEdge={setClickedEdge}
          />
        </div>
        )}
      </TabsComponent>
    </div>
  );
};

export default NodeDrawerComponent;
