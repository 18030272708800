import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Button, Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

export function EditToolbar(tag, handleClearTag, handleAdd) {
  return (
    <GridToolbarContainer>
      {/* <Button color="primary" startIcon={<AddIcon />} onClick={handleAdd}>
        Add Tool
      </Button> */}
      {tag && (
        <Chip
          label={tag}
          color="secondary"
          onDelete={handleClearTag}
          style={{ marginLeft: "10px" }}
        />
      )}
    </GridToolbarContainer>
  );
}
