import React, { useState, useEffect } from "react";
import { SplitScreen } from "./SplitScreen";
import MultiSelector from "./multiSelector/MultiSelector";
import MenuCardComponent from "./MenuCardComponent";
import { Grid, ThemeProvider } from "@mui/material";
import { GetDiagCardsInfo } from "../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import themeProvider from "../ThemeProvider";

function CreateOrOpenDiag({
  handleDetails,
  handleEditDiagram,
  searchQuery,
  caller,
  isMenuOpenleft,
  selected,
  setSelected,
  data,
  fetched,
  editTemplate,
}) {

  console.log("fetched",fetched ? selected : []);
  
  //filter for search (only for the cards)
  const itemsToDisplay = selected?.filter(
    (section) =>
      section?.name?.toLowerCase().includes(searchQuery) ||
      section?.title?.toLowerCase().includes(searchQuery)
  );

  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <SplitScreen
        leftWeight={1}
        rightWeight={2}
        isMenuOpenleft={isMenuOpenleft}
      >
        <MultiSelector
          data={fetched ? data : []}
          selected={fetched ? selected : []}
          setSelected={setSelected}
        />
        <Grid sx={{ flexGrow: 1 }} container padding={1}>
          {itemsToDisplay.map((item, index) => (
            <MenuCardComponent
              key={index}
              data={item}
              size={310}
              textSize={17}
              isMobile={window.innerWidth < 750 ? true : false}
              handleDetails={handleDetails}
              handleEditDiagram={handleEditDiagram}
              caller={caller}
              editTemplate={editTemplate}
            />
          ))}
        </Grid>
      </SplitScreen>
    </ThemeProvider>
  );
}

export default CreateOrOpenDiag;
