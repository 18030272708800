import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import "../../assets/icofontcharts/icofont.min.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ChooseRegion from "../../PopUpForm/ChooseRegion";
import SouthAmericaSharpIcon from "@mui/icons-material/SouthAmericaSharp";
import { useAppContext } from "../../Components/diagramsComponents/RegionContext.js";
import { createRequestBody, postResource } from "../../apiCalls/DiagramsAPI.js";

// const regionsOptions = ["Generic", "AI UI"];
const LIST_ITEMS = [
  "Form Builder",
  "Theme Builder",
  "Flow",
  "Edit Tools",
  "Edit Templates",
  "Change Region",
  "Edit Controls",
];
const LIST_ICONS = [
  <FontAwesomeIcon icon="fa-solid fa-cube" />,
  <BorderColorIcon />,
  <i className="icofont-chart-flow-2"></i>,
  <HandymanOutlinedIcon />,
  <SouthAmericaSharpIcon />,
  <BorderColorIcon />,
  <ModeEditIcon />,
];

const LIST_PATHS = [
  "/Open",
  "/Themes",
  "/Select",
  "/ToolSelector",
  "/EditTemplates",
  "/Region",
  "/Edit",
];

function LeftNavBar({ open, drawerWidth, authData }) {



  let navigate = useNavigate();

  const [pendingPath, setPendingPath] = React.useState("");
  const [openPopup, setOpenPopup] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState(
    localStorage.getItem("region") || ""
  );
  // React.useEffect(() => {
  //   const regionNames = await getRegions();
  //   console.log("Region Names:", regionNames);
  //   setRegions(regionNames);
  // }, []);

  const accessKey = authData?.accessKey || "";

  const fetchRegions = React.useCallback(async () => {
    if (!accessKey) {
      console.error("No access key available");
    }
  
    // setIsLoading(true);
    try {
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody( {
      accessKey: accessKey, 
      guid: "Get Regions",  
      param1: "",           
      param2: "",           
      param3: "",          
      param4: "",            
      recordSet: "",         
      body: ""               
    })
      );
  
      setRegions(response);
      // setError(null);
      return response;
    } catch (err) {

    } finally {
      // setIsLoading(false);
    }
  }, [accessKey]);
  
  React.useEffect(() => {
    const fetchRegions = async () => {
      try {
        const regionNames = await getRegions();
        console.log("Region Names:", regionNames);
        setRegions(regionNames);
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };

    fetchRegions();
  }, []);

  const getRegions = React.useCallback(async () => {
    if (!regions.length) {
      const ret = await fetchRegions();
      return ret.map((region) => region.Region);
    } else {
      return regions.map((region) => region.Region);
    }
  }, [regions, fetchRegions]);

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
    console.log("Selected Region: ", region);
    localStorage.setItem("region", region);
    if (pendingPath !== "") navigate(pendingPath);
  };

  // async function fetchData() {
  //   const accessKey = await GetAccessKeyFromDemo24();
  
  //   const response = await GetRegions();
  //   setRegions(response);
  // }
  // function handleItemClick(text, index) {
  //   navigate(`${LIST_PATHS[index]}`);
  // }

  function handleItemClick(text, index) {
    const path = LIST_PATHS[index];
    if (text === "Change Region") {
      setOpenPopup(true);
    } else if (
      (text === "Flow" || text === "Edit Tools" || text === "Edit Templates") &&
      selectedRegion === ""
    ) {
      setPendingPath(path);
      setOpenPopup(true);
    } else {
      navigate(path);
    }
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: open ? 1 : 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginTop: "55px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <List>
          {LIST_ITEMS.map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              onClick={() => handleItemClick(text, index)}
            >
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    "&.MuiListItemIcon-root": {
                      minWidth: "2.5rem",
                      maxWidth: "2.5rem",
                    },
                  }}
                >
                  {LIST_ICONS[index]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {openPopup && (
        <ChooseRegion
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          title={"Choose Region"}
          dropdownOptions={regions}
          selectedDropdownOption={selectedRegion}
          handleDropdownChange={handleRegionChange}
        />
      )}
    </Box>
  );
}

export default LeftNavBar;
