// edgeFunctions.js

import { useCallback } from "react";

// export const useFlipArrow = (setEdges, diagramDetails, setDiagramDetails) => {
//   return useCallback(
//     (edgeId) => {
//       setEdges((prevEdges) =>
//         prevEdges.map((edge) => {
//           return edge.id === edgeId
//             ? {
//                 ...edge,
//                 source: edge.target,
//                 sourceHandle: edge.targetHandle,
//                 target: edge.source,
//                 targetHandle: edge.sourceHandle,
//               }
//             : edge;
//         })
//       );
//     },
//     [setEdges, diagramDetails, setDiagramDetails]
//   );
// };
export const useFlipArrow = (setEdges, diagramDetails, setDiagramDetails) => {
  return useCallback(
    (edgeId) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeId) {
            // Create flipped edge first so we can use it in the diagramDetails update
            const flippedEdge = {
              ...edge,
              source: edge.target,
              sourceHandle: edge.targetHandle,
              target: edge.source,
              targetHandle: edge.sourceHandle,
            };

            // Update ParentDBID, Parent_Action, and Parent_Explain in diagramDetails
            setDiagramDetails((prevDetails) =>
              prevDetails.map((node) => {
                // Find the nodes we need to update
                const newSourceNode = prevDetails.find(
                  (n) => n?.id === flippedEdge?.source
                );
                const newTargetNode = prevDetails.find(
                  (n) => n?.id === flippedEdge?.target
                );
                // const child = prevDetails.find(
                //   (detail) => detail?.ParentDBID === newSourceNode?.Dbid
                // );

                // if (node.id === flippedEdge.source && newTargetNode) {
                //   // Update source node
                //   return {
                //     ...node,
                //     ParentDBID: newTargetNode.ParentDBID ?? "",
                //     Parent_Action: newTargetNode.Parent_Action ?? "",
                //     Parent_Explain: newTargetNode.Parent_Explain ?? "",
                //   };
                // }

                if (node.id === flippedEdge.target && newSourceNode) {
                  // Update target node
                  return {
                    ...node,
                    ParentDBID: newSourceNode.Dbid ?? "",
                    Parent_Action: newSourceNode.TExecName ?? "",
                    Parent_Explain: newSourceNode.Explain ?? "",
                  };
                }

                // if (node.id === child?.id && newTargetNode) {
                //   // Update child node
                //   return {
                //     ...node,
                //     ParentDBID: newTargetNode.Dbid ?? "",
                //     Parent_Action: newTargetNode.TExecName ?? "",
                //     Parent_Explain: newTargetNode.Explain ?? "",
                //   };
                // }

                return node;
              })
            );

            return flippedEdge;
          }
          return edge;
        })
      );
    },
    [setEdges, setDiagramDetails, diagramDetails]
  );
};

export const useChangeColor = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                style: { ...edge.style, stroke: newColor },
                markerEnd: {
                  ...edge.markerEnd,
                  color: newColor,
                },
              }
            : edge;
        })
      );
    },
    
    [setEdges]
  );
};

// export const useChangeMarkerEndColor = (setEdges) => {
//   return useCallback(
//     (edgeId, newColor) => {
//       setEdges((prevEdges) =>
//         prevEdges.map((edge) =>
//           edge.id === edgeId
//             ? {
//                 ...edge,
//                 markerEnd: {
//                   ...edge.markerEnd,
//                   color: newColor,
//                 },
//               }
//             : edge
//         )
//       );
//     },
//     [setEdges]
//   );
// };

export const useChangeMarkerEndColor = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeId) {
            if (typeof edge.markerEnd === "object") {
              // If markerEnd is an object, update its color property
              return {
                ...edge,
                markerEnd: {
                  ...edge.markerEnd,
                  color: newColor,
                },
              };
            } else if (typeof edge.markerEnd === "string") {
              // If markerEnd is a string, keep it as it is
              return edge;
            }
          }
          return edge; // For other edges, return them as they are
        })
      );
    },
    [setEdges]
  );
};

export const useChangeMarkerEnd = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerEnd) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerEnd: {
                  ...edge.markerEnd,
                  type: newMarkerEnd,
                  width: 15,
                  height: 15,
                },
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerEndSVG = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerEnd) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerEnd: newMarkerEnd,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerStart = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerStart) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerStart: {
                  ...edge.markerStart,
                  type: newMarkerStart,
                  width: 15,
                  height: 15,
                },
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerStartSVG = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerStart) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerStart: newMarkerStart,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeLineStyle = (setEdges) => {
  return useCallback(
    (edgeId, lineStyle) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                animated: lineStyle === "dashed" ? true : false,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};

export const useChangeThickness = (setEdges) => {
  return useCallback(
    (edgeId, newStrokeWidth) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                style: { ...edge.style, strokeWidth: newStrokeWidth },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabel = (setEdges) => {
  return useCallback(
    (edgeId, text) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                label: text,
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelFontStyle = (setEdges) => {
  return useCallback(
    (edgeId, newFontSize) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          console.log("edge.labelStyle ", edge.labelStyle);
          return edge.id === edgeId
            ? {
                ...edge,
                labelStyle: {
                  ...edge.labelStyle,
                  fontSize: newFontSize,
                },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelColorStyle = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          console.log("edge.labelStyle ", edge.labelStyle);
          return edge.id === edgeId
            ? {
                ...edge,
                labelStyle: {
                  ...edge.labelStyle,
                  fill: newColor,
                },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelBgStyle = (setEdges) => {
  return useCallback(
    (edgeId, newLabelBgStyle) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                labelBgStyle: { ...newLabelBgStyle },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeType = (setEdges) => {
  return useCallback(
    (edgeId, type) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                type: type,
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};

export const useDeleteEdge = (setEdges , setClickedEdge) => {
  return useCallback(
    (edgeId) => {
      setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== edgeId));
      setClickedEdge()
    },
    [setEdges]
  );
};

// export const useEdgeClick = (FlipArrow) => {
//   return useCallback(
//     (event, clickedEdge) => {
//       FlipArrow(clickedEdge.id);
//     },
//     [FlipArrow]
//   );
// };


// export const useEdgeClick = (setClickedEdge, toggleDrawer ,setTabValue ,tabValue) => {
  
//   return useCallback(
//     (event, clickedEdge) => {
//       setClickedEdge(clickedEdge);
//       toggleDrawer(1);
//       if (tabValue !== "1"){
//         setTabValue("2")
//       }
//       setTabValue("2")
  
//     },
//     [setClickedEdge]
//   );
// };


export const useEdgeClick = (setClickedEdge, toggleDrawer, setTabValue) => {
  return useCallback(
    (event, clickedEdge) => {
      setClickedEdge(clickedEdge);
      toggleDrawer(1);
      setTabValue("2"); 
    },
    [setClickedEdge, toggleDrawer, setTabValue]
  );
};