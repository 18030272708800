import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import themeProvider from "../../ThemeProvider";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import NavBar from "../../Components/navbarComponents/NavBar";
import {
  UpdateDiagTools,
  MakeToolInactive,
  UpdateTemplateDetails,
  UnselectToolInTemplate,
  UpdateToolProperties,
  postResource,
  createRequestBody,
  loadLoginData,
} from "../../apiCalls/DiagramsAPI";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import { CopyAllOutlined } from "@mui/icons-material";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { GetDiagTools, GetToolPropertiesAll } from "../../apiCalls/DiagramsAPI";
import { EditToolbar } from "../../Components/diagramsComponents/EditToolbar";
import { ToastContainer, toast } from "react-toastify";
import { Theme } from "../../Components/IncludeFile/Theme";
import "../../styles.css";

const NodeSelector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //When called from EditTemplatePage
  const { DiagTempID } = location.state || {};
  console.log("DiagTempID ", DiagTempID);
  const [tools, setTools] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [fetched, setFetched] = useState(false);
  const [tag, setTag] = useState("");
  const [infoPopup, setInfoPopup] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [filt, setFilt] = useState([]);

  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";

  // async function fetchProperties() {
  //   try {
  //     const fetchedData = await GetToolPropertiesAll();
  //     // setProperties(fetchedData);
  //     return fetchedData;
  //   } catch (error) {
  //     console.error("Fetch Error ", error);
  //     // toast.error("Error fetching data: " + error.message);
  //   }
  // }
  // async function saveProperties(properties) {
  //   try {
  //     const response = await UpdateToolProperties(properties);
  //     toast.success("Data Saved!");
  //   } catch (error) {
  //     console.error("Fetch Error ", error);
  //     toast.error("Error saving data: " + error.message);
  //   }
  // }

  // const getPropertyDiagPropIDAndSection = (properties, propertyName) => {
  //   const property = properties?.find(
  //     (item) =>
  //       item.propertyName?.toString().toLowerCase() ===
  //       propertyName?.toLowerCase()
  //   );
  //   return {
  //     diagPropID: property.diagPropID,
  //     propSection: property.propSection,
  //   };
  // };

  // async function addPropertiesToNewTool(tool) {
  //   const newToolProperty = [];
  //   try {
  //     const properties = await fetchProperties();
  //     properties.map((property, index) => {
  //       const { diagPropID, propSection } = getPropertyDiagPropIDAndSection(
  //         properties,
  //         property.propertyName
  //       );
  //       if (diagPropID && propSection) {
  //         const newProperty = {
  //           toolPropID: 0,
  //           diagToolID: tool.diagToolID,
  //           diagPropID: property.diagPropID,
  //           name: tool.name,
  //           propSection: propSection,
  //           propertyName: property.propertyName,
  //           diagPropValue: property.defaultValue,
  //         };
  //         newToolProperty.push(newProperty);
  //       }
  //     });
  //     saveProperties(newToolProperty);
  //   } catch (error) {
  //     toast.error("Error fetching default properties: " + error.message);
  //   }
  // }

  useEffect(() => {
    async function fetchData() {
      try {
        const preSelectedRegion = localStorage.getItem("region");
        console.log("preSelectedRegion ", preSelectedRegion);
        const fetchedData = await postResource(
          "GetQuickInfo",
          createRequestBody({
            accessKey: accessKey,
            guid: `${window.APIProvider.recordPath.ListDiagTools}`,
            param1: preSelectedRegion.toString(),
          })
        );
        setTools(fetchedData);
        //selectedNodes is an array that only has the ids
        setSelectedNodes(fetchedData.map((item) => item.diagtoolid));
        const uniqueRegions = [
          ...new Set(
            fetchedData
              .map((item) => item.source)
              .filter((source) => source !== null && source !== undefined)
          ),
        ];
        setRegions(uniqueRegions);
        setSelectedRegion(
          preSelectedRegion ? preSelectedRegion : uniqueRegions[0]
        );
        setFetched(true);
      } catch (error) {
        console.error("Fetch Error ", error);
        toast.error("Error fetching data: " + error.message);
      }
    }
    if (!fetched) {
      fetchData();
    }
  }, [fetched]);

  // useEffect(() => {
  //   setSearchQuery(selectedRegion);
  // }, [selectedRegion]);

  // const handleRegionChange = (region) => {
  //   setSelectedRegion(region);
  //   console.log("Selected Region: ", region);
  //   localStorage.setItem("region", region);
  // };

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // async function saveData(newTool) {
  //   try {
  //     const response = await UpdateDiagTools(newTool);
  //     console.log("response ", response);
  //   } catch (error) {
  //     console.error("Fetch Error ", error);
  //     toast.error("Error saving data: " + error.message);
  //   }
  // }

  // const handleAdd = () => {
  //   const newDiagToolID = Math.max(...tools.map((tool) => tool.diagToolID)) + 1;
  //   const newTool = {
  //     diagToolID: newDiagToolID.toString(),
  //     name: "New Tool " + newDiagToolID,
  //     title: "New Tool",
  //     explain: "New Tool Explanation",
  //     notes: "New Tool Notes",
  //     caption: "New Tool",
  //     description: "New Tool Description",
  //     isInactive: "0",
  //     createdBy: "Admin",
  //     modifiedBy: "Admin",
  //     autoSize: "0",
  //     node_Height: "0",
  //     node_Width: "4",
  //     nodeType: "0",
  //     handles: "0",
  //     isResizable: "0",
  //     isDraggable: "0",
  //     isDisabled: "0",
  //     isRotatable: "0",
  //     isDeletable: "0",
  //     link1: "",
  //     link1Text: "",
  //     paramLink1Name1: "",
  //     paramLink1Value1: "",
  //     link2: "",
  //     link2Text: "",
  //     paramLink2Name1: "",
  //     paramLink2Value1: "",
  //   };
  //   setTools([...tools, newTool]);
  //   saveData(newTool);
  //   // addPropertiesToNewTool(newTool);
  //   setSelectedNodes([...selectedNodes, newDiagToolID]);
  // };

  // const handleCopy = (rowID) => {
  //   const index = tools.findIndex((tool) => tool.diagToolID === rowID);

  //   if (index !== -1) {
  //     const copiedTool = { ...tools[index] };
  //     const newDiagToolID =
  //       Math.max(...tools.map((tool) => tool.diagToolID)) + 1;

  //     copiedTool.diagToolID = newDiagToolID.toString();
  //     copiedTool.name += " Copy " + newDiagToolID;
  //     copiedTool.title += " Copy";
  //     copiedTool.notes += " Copy";
  //     // copiedTool.explain += " Copy";
  //     copiedTool.caption += " Copy";
  //     setTools([...tools, copiedTool]);
  //     saveData(copiedTool);
  //     addPropertiesToNewTool(copiedTool);
  //     setSelectedNodes([...selectedNodes, newDiagToolID]);
  //   } else {
  //     console.error("Tool not found");
  //     toast.error("Tool Not Found");
  //   }
  // };

  // const handleDeleteClick = (id) => {
  //   setDeleteID(id);
  //   setDeletePopup(true);
  // };

  // const deleteTool = async () => {
  //   const response = await MakeToolInactive(deleteID);
  //   return !response.isError;
  // };
  // const handleDelete = () => {
  //   setDeletePopup(false);
  //   deleteTool();
  //   // if (deleteTool()) {
  //   //   setTools(tools.filter((tool) => tool.diagToolID !== deleteID));
  //   //   setSelectedNodes(
  //   //     selectedNodes.filter((selectedNode) => selectedNode !== deleteID)
  //   //   );
  //   // }
  // };

  const [rowModesModel, setRowModesModel] = useState({});

  // const handleEdit = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  // const handleSave = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  // };

  // const handleCancel = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });
  // };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // const handleRowEditStop = (params, event) => {
  //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
  //     event.defaultMuiPrevented = true;
  //   }
  // };

  const processRowUpdate = (newRow) => {
    const index = tools.findIndex((tool) => tool.diagToolID === newRow.id);

    if (index !== -1) {
      const updatedTool = { ...tools[index], ...newRow };
      const updatedTools = [...tools];
      updatedTools[index] = updatedTool;
      // saveData(updatedTool);
      setTools(updatedTools);
      return updatedTool;
    } else {
      console.error("Tool not found");
      return null;
    }
  };

  const handleNavigation = (path, propValue) => {
    console.log("propValue ", propValue);
    // Filter the tools based on selected tools
    const filteredTools = tools.filter((tool) =>
      propValue.includes(tool.diagtoolid)
    );
    console.log("tools ", tools);
    console.log("filteredTools ", filteredTools);
    navigate(path, { state: { prop: propValue, tools: filteredTools } });
  };

  const handleEditToolsProperties = async () => {
    try {
      console.log("NAVIGATION");
      handleNavigation("/ToolProperties", selectedNodes);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  // const handleEditToolsProperties = async () => {
  //   try {
  //     if (DiagTempID !== null && DiagTempID !== undefined) {
  // const selectedTools = tools
  //   .filter((tool) => selectedNodes.includes(tool.diagtoolid))
  //   .map(({ diagtoolid, detDiagTempID, seq }) => ({
  //     diagToolID: parseInt(diagtoolid),
  //     DiagTempID: parseInt(DiagTempID),
  //     detDiagTempID: parseInt(detDiagTempID),
  //     seq: parseInt(seq),
  //   }));
  //       const selectedTools= tools.filter
  //       console.log("SelectedTools ", selectedTools);
  //       const result = await UpdateTemplateDetails(selectedTools);
  //       console.log("result ", result);
  //       // Identify tools that need to be unselected
  //       const unselectedTools = tools.filter(
  //         (tool) =>
  //           tool.detDiagTempID !== "0" &&
  //           !selectedNodes.includes(tool.diagToolID)
  //       );
  //       console.log("unselectedTools ", unselectedTools);
  //       await Promise.all(
  //         unselectedTools.map(async (tool) => {
  //           await UnselectToolInTemplate(tool.detDiagTempID);
  //         })
  //       );
  //     }
  //     console.log("NAVIGATION");
  //     handleNavigation("/ToolProperties", selectedNodes);
  //   } catch (error) {
  //     console.error("Error fetching properties:", error);
  //   }
  // };

  const handleInfoPopup = () => {
    setInfoPopup(!infoPopup);
  };
  const leftButton = {
    icon: <InfoIcon />,
    text: "Info", // color: "primary", // "primary" or "secondary"
    handleClick: () => {
      // Handle click for the left button
      handleInfoPopup();
    },
  };

  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  //Columns in table
  const columns = React.useMemo(
    () => [
      { field: "name", headerName: "Name", editable: true, flex: 1 },
      {
        field: "title",
        headerName: "Title",
        editable: true,
        flex: 1,
        fontWeight: "bold",
      },
      {
        field: "notes",
        headerName: "Notes",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "caption",
        headerName: "Caption",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "description",
        headerName: "Description",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "source",
        headerName: "Source",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "toolTags",
        headerName: "Tags",
        editable: true,
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          if (!params.value) {
            return null; // Return null or empty content if params.value is empty
          }

          const values = params.value.split(",").map((value) => value.trim()); // Split by comma and trim whitespace

          return (
            <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
              {values.map((value, index) => {
                return (
                  <Chip
                    key={index}
                    label={value}
                    clickable
                    onClick={() => handleChipClick(value)}
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: 4 }}
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        field: "seq",
        headerName: "Sequence",
        width: 150,
        editable: true,
        sortable: true,
      },
      // {
      //   field: "actions",
      //   type: "actions",
      //   headerName: "Actions",
      //   width: 150,
      //   cellClassName: "actions",
      //   getActions: ({ id }) => {
      //     const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      //     if (isInEditMode) {
      //       return [
      //         <GridActionsCellItem
      //           icon={<SaveIcon />}
      //           label="Save"
      //           sx={{
      //             color: "primary.main",
      //           }}
      //           onClick={handleSave(id)}
      //         />,
      //         <GridActionsCellItem
      //           icon={<CancelIcon />}
      //           label="Cancel"
      //           className="textPrimary"
      //           onClick={handleCancel(id)}
      //           color="inherit"
      //         />,
      //       ];
      //     }

      //     return [
      //       <GridActionsCellItem
      //         icon={<EditIcon />}
      //         label="Edit"
      //         className="textPrimary"
      //         onClick={handleEdit(id)}
      //         color="inherit"
      //       />,
      //       <GridActionsCellItem
      //         icon={<CopyAllOutlined />}
      //         label="Copy"
      //         onClick={() => handleCopy(id)}
      //         color="inherit"
      //       />,
      //       <GridActionsCellItem
      //         icon={<DeleteOutline />}
      //         label="Delete"
      //         onClick={() => handleDeleteClick(id)}
      //         color="inherit"
      //       />,
      //     ];
      //   },
      // },
    ],
    [rowModesModel]
  );

  //Rows in table
  const rows = tools.map((tool, index) => ({
    id: tool.diagtoolid,
    name: tool.name,
    title: tool.title,
    notes: tool.notes,
    caption: tool.caption,
    description: tool.description,
    source: tool.source,
    toolTags: tool.tooltags,
    seq: tool.seq,
  }));

  // Function to calculate the row height based on the number of chips
  const getRowHeight = (params) => {
    if (!params.model.toolTags) return 52; // Default row height for empty content

    const values = params.model.toolTags
      .split(",")
      .map((value) => value.trim());

    const baseHeight = 52; // Default row height
    const extraHeightPerThreeTags = 52; // Extra height to add for every 2 tags
    const numberOfTags = values.length;

    // For every 2 tags, add 52 to the row height
    const extraHeight = Math.floor(numberOfTags / 3) * extraHeightPerThreeTags;

    return baseHeight + extraHeight;
  };

  //inactive row color
  const getRowClassName = (params) => {
    const tool = tools.find((item) => item.diagToolID === params.id);
    if (tool && tool.isInactive === 1) {
      return "inactive-row";
    }
    return "";
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setFilterModel({
      items: [{ field: "name", operator: "contains", value: e.target.value }],
    });
    handleClearTag();
  };
  // var filteredRows;
  // filteredRows = rows.filter((row) =>
  //   Object.values(row).some(
  //     (value) =>
  //       typeof value === "string" &&
  //       value.toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  // );
  // filteredRows = rows.filter((row) => {
  //   // Check if the search query matches any field in the row
  //   const matchesSearchQuery = Object.values(row).some(
  //     (value) =>
  //       typeof value === "string" &&
  //       value.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  //   // Check if the tag is not empty and if the row's toolTags contain the tag
  //   const matchesTag = tag
  //     ? row.toolTags &&
  //       typeof row.toolTags === "string" &&
  //       row.toolTags
  //         .split(",")
  //         .map((t) => t.trim())
  //         .includes(tag)
  //     : true;
  //   // If clickedTag is empty, return true to include the row
  //   // Check if the row's source matches the selectedRegion
  //   const matchesRegion =
  //     row.source.toLowerCase() === selectedRegion.toLowerCase();

  //   // Include the row only if it matches both the search query and the tag
  //   return matchesSearchQuery && matchesTag && matchesRegion;
  // });

  // console.log("filteredRows ", filteredRows);

  //tags functions
  const handleChipClick = (clickedTag) => {
    console.log("clickedTag ", clickedTag);
    console.log("tools ", tools);
    setTag(clickedTag);
    // filteredRows = tools.filter((tool) => {
    //   return (
    //     tool.toolTags &&
    //     typeof tool.toolTags === "string" &&
    //     tool.toolTags
    //       .split(",")
    //       .map((tag) => tag.trim())
    //       .includes(clickedTag)
    //   );
    // });
    handleTagFilterChange(clickedTag);
  };
  const handleClearTag = () => {
    setTag("");
    setFilterModel({
      items: [{ field: "name", operator: "contains", value: searchQuery }],
    });
  };
  const getApplyQuickFilterFnByTag = (value) => {
    return (cellValue) => {
      if (Array.isArray(cellValue)) {
        return cellValue.includes(value);
      }
      return false;
    };
  };
  const apiRef = useGridApiRef();
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const handleTagFilterChange = (clickedTag) => {
    setFilterModel({
      items: [
        { field: "toolTags", operator: "contains", value: `${clickedTag}` },
      ],
    });
  };
  console.log("filterModel ", filterModel);
  console.log("selectedNodes ", selectedNodes);
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        title={`Choose ${localStorage.getItem("region")} Tools`}
        isMenuOpenleft={false}
        leftButton={leftButton}
      />
      <div style={{ height: "94vh", overflowX: "auto" }}>
        <Box
          sx={{ width: "100%", padding: isSmallScreen ? "1rem" : "1rem 2rem" }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            // onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowClassName={getRowClassName}
            getRowHeight={getRowHeight} // Dynamically adjust row height
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              columns: {
                columnVisibilityModel: {
                  seq:
                    DiagTempID === null || DiagTempID === undefined
                      ? false
                      : true,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            checkboxSelection
            rowSelectionModel={selectedNodes}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedNodes(newRowSelectionModel);
            }}
            disableRowSelectionOnClick
            sx={{
              "& .MuiDataGrid-root": {
                width: "100%",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                color: themeProvider.palette.primary.dark,
              },
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  marginTop: "1em",
                  marginBottom: "1em",
                },
            }}
            slots={{
              // toolbar: () => EditToolbar(tag, handleClearTag, handleAdd),
              toolbar: () => EditToolbar(tag, handleClearTag),
            }}
            // filterModel={{
            //   items: [
            //     { field: "toolTags", operator: "contains", value: `${tag}` },
            //   ],
            // }}
            filterModel={filterModel}
          />
        </Box>
        <div
          style={{
            margin: "1rem",
            display: "flex",
            justifyContent: isSmallScreen ? "center" : "flex-end",
          }}
        >
          <div className="control-property-btn">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleEditToolsProperties}
              disabled={selectedNodes.length === 0}
            >
              Edit Tools Properties
            </Button>
          </div>
        </div>
        {/* {deletePopup && (
          <CustomPopUpForm
            open={deletePopup}
            onClose={() => setDeletePopup(false)}
            title="Are you sure you want to delete?"
          >
            <></>
            <>
              <Button fullWidth onClick={() => setDeletePopup(false)}>
                No
              </Button>
              <Button variant="contained" fullWidth onClick={handleDelete}>
                Yes
              </Button>
            </>
          </CustomPopUpForm>
        )} */}
      </div>
      {infoPopup && (
        <CustomPopUpForm
          open={infoPopup}
          onClose={handleInfoPopup}
          title="Information"
        >
          <Typography>
            This page allows you to choose tools and add/change their
            properties. Choose a number of tools by clicking the checkbox and
            when you are done press "Edit Tools Properties" button at the bottom
            right. If you want to show more or less tools in one page you can do
            so by setting the drop down at the bottom right names "Rows per
            page"
          </Typography>
          <></>
        </CustomPopUpForm>
      )}
    </ThemeProvider>
  );
};

export default NodeSelector;
