import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  let APIProvider = window.APIProvider;
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const secretKey = APIProvider.secretKey.secretKey


  const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  
  const checkLoginStatus = () => {
    try {
      const encryptedData = sessionStorage.getItem("loginData");
      if (!encryptedData) {
        console.warn("No loginData found in sessionStorage");
        setIsAuthenticated(false);
        return;
      }
  
      const decryptedData = decryptData(encryptedData);
      if (!decryptedData) {
        console.warn("Decryption failed or invalid data");
        setIsAuthenticated(false);
        return;
      }
  
      const loginData = decryptedData ? decryptedData : null;
      setIsAuthenticated(!!(loginData && loginData.accessKey));
    } catch (error) {
      console.error("Error in checkLoginStatus:", error);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkLoginStatus(); 
    window.addEventListener("storage", checkLoginStatus);
    return () => {   
      window.removeEventListener("storage", checkLoginStatus);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return children;
};

export default PrivateRoute;
