import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";
import debounce from "lodash/debounce";

const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const Size = ({
  matchedStyles,
  updateDefaultStyle,
  setDefaultStyleState,
  widthValue,
  setWidthValue,
  heightValue,
  setHeightValue,
  minWidthValue,
  minHeightValue,
  setMinHeightValue,
  setMinWidthValue,
  maxWidthValue,
  maxHeightValue,
  setMaxHeightValue,
  setMaxWidthValue,
  defaultStyle,
  setDefaultStyle,
  styleType,
}) => {



  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );



  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: {
              ...style.style,
              width: widthValue,
              height: heightValue,
              minWidth: minWidthValue,
              minHeight: minHeightValue,
              maxWidth: maxWidthValue,
              maxHeight: maxHeightValue,
            },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
      // setDefaultStyleState(updatedStyle);
    }
  }, [
    widthValue,
    heightValue,
    minWidthValue,
    minHeightValue,
    maxWidthValue,
    maxHeightValue,
    styleType,
  ]);

  const getNumericAndUnit = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }

    if (value === undefined || value === null) {
      return {
        numeric: "",
        unit: "px",
      };
    }

    if (value === "none") {
      return {
        numeric: "",
        unit: "",
      };
    }

    const match = value.match(/^(-?[\d.]+)(\D*)$/);
    if (match) {
      const numeric = match[1];
      const unit = match[2] !== "" ? match[2] : "px";

      return {
        numeric: numeric,
        unit: unit,
      };
    }
    return {
      numeric: "",
      unit: "px",
    };
  };


  const debouncedsetWidthValue = debounce((value) => {
    setWidthValue(value);
  }, 10);

  const debouncedsetHeightValue= debounce((value) => {
    setHeightValue(value);
  }, 10);

  const debouncedsetMinWidthValue= debounce((value) => {
    setMinWidthValue(value);
  }, 10);

  const debouncedsetMinHeightValue= debounce((value) => {
    setMinHeightValue(value);
  }, 10);

  const debouncedsetMaxWidthValue= debounce((value) => {
    setMaxWidthValue(value);
  }, 10);
  const debouncedsetMaxHeightValue = debounce((value) => {
    setMaxHeightValue(value);
  }, 10);

  const handleWidthNumericChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(widthValue).unit;
    debouncedsetWidthValue(`${numericValue}${unit}`);
  };

  const handleWidthUnitChange = (e) => {
    const unit = e.target.value;
    let numericValue = getNumericAndUnit(widthValue).numeric;

    if (unit === "auto") {
      numericValue = "";
      setWidthValue("auto");
    }

    debouncedsetWidthValue(`${numericValue}${unit}`);
  };

  const handleHeightNumericChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(heightValue).unit;
    debouncedsetHeightValue(`${numericValue}${unit}`);
  };

  const handleHeightUnitChange = (e) => {
    const unit = e.target.value;
    let numericValue = getNumericAndUnit(heightValue).numeric;

    if (unit === "auto") {
      numericValue = "";
      setHeightValue("auto");
    }

    debouncedsetHeightValue(`${numericValue}${unit}`);
  };

  const handleMinWidthNumericChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(minWidthValue).unit;
    debouncedsetMinWidthValue(`${numericValue}${unit}`);
  };

  const handleMinWidthUnitChange = (e) => {
    const unit = e.target.value;
    let numericValue = getNumericAndUnit(minWidthValue).numeric;

    if (unit === "auto") {
      numericValue = "";
      setMinWidthValue("auto");
    }

    debouncedsetMinWidthValue(`${numericValue}${unit}`);
  };

  const handleMinHeightNumericChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(minHeightValue).unit;
    debouncedsetMinHeightValue(`${numericValue}${unit}`);
  };

  const handleMinHeightUnitChange = (e) => {
    const unit = e.target.value;
    let numericValue = getNumericAndUnit(minHeightValue).numeric;

    if (unit === "auto") {
      numericValue = "";
      setMinHeightValue("auto");
    }

    debouncedsetMinHeightValue(`${numericValue}${unit}`);
  };

  const handleMaxWidthNumericChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(maxWidthValue).unit;
    debouncedsetMaxWidthValue(`${numericValue}${unit}`);
  };

  const handleMaxWidthUnitChange = (e) => {
    const unit = e.target.value;
    let numericValue = getNumericAndUnit(maxWidthValue).numeric;

    if (unit === "auto") {
      numericValue = "";
      setMaxWidthValue("auto");
    }

    debouncedsetMaxWidthValue(`${numericValue}${unit}`);
  };

  const handleMaxHeightNumericChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(maxHeightValue).unit;
    debouncedsetMaxHeightValue(`${numericValue}${unit}`);
  };

  const handleMaxHeightUnitChange = (e) => {
    const unit = e.target.value;
    let numericValue = getNumericAndUnit(maxHeightValue).numeric;

    if (unit === "auto") {
      numericValue = "";
      setMaxHeightValue("auto");
    }

    debouncedsetMaxHeightValue(`${numericValue}${unit}`);
  };
 
  return (
    <StyledAlignItems className="bor-remove-right-edit">
      <div>
       <PropertiesAccordion title={"Size"}>
          <>
            <div className="werghn">
              <div className="rht-flds-wrap">
                <div className="rht-fld-info">
                  <div className="ipubrdr">
                    <div className="rht-fld">
                      <input
                        type="number"
                        placeholder={widthValue === "auto" ? "auto" : ""}
                        value={getNumericAndUnit(widthValue).numeric}
                        onChange={handleWidthNumericChange}
                      />
                      <span className="unit-dropdown">
                        <Select
                          value={
                            widthValue === "auto"
                              ? "auto"
                              : getNumericAndUnit(widthValue).unit
                          }
                          onChange={handleWidthUnitChange}
                          style={{
                            width: "40px",
                            height: "27px",
                            padding: "8px 11px 10px 12px",
                          }}
                          displayEmpty
                          IconComponent={() => null}
                          MenuProps={{ style: { top: "5px" } }}
                          className="custom-dropdown"
                        >
                          <MenuItem value="px" className="custom-menuitem">
                            px
                          </MenuItem>
                          <MenuItem value="em" className="custom-menuitem">
                            em
                          </MenuItem>
                          <MenuItem value="%" className="custom-menuitem">
                            %
                          </MenuItem>
                          <MenuItem value="auto" className="custom-menuitem">
                            auto
                          </MenuItem>
                        </Select>
                      </span>
                    </div>
                  </div>
                  <div className="rht-info-edit">Width</div>
                </div>

                <div className="rht-fld-info">
                  <div className="ipubrdr">
                    <div className="rht-fld">
                      <input
                        type="number"
                        placeholder={heightValue === "auto" ? "auto" : ""}
                        value={getNumericAndUnit(heightValue).numeric}
                        onChange={handleHeightNumericChange}
                      />
                      <span className="unit-dropdown">
                        <Select
                          value={
                            heightValue === "auto"
                              ? "auto"
                              : getNumericAndUnit(heightValue).unit
                          }
                          onChange={handleHeightUnitChange}
                          style={{
                            width: "40px",
                            height: "27px",
                            padding: "8px 11px 10px 12px",
                          }}
                          displayEmpty
                          IconComponent={() => null}
                          MenuProps={{ style: { top: "5px" } }}
                          className="custom-dropdown"
                        >
                          <MenuItem value="px" className="custom-menuitem">
                            px
                          </MenuItem>
                          <MenuItem value="em" className="custom-menuitem">
                            em
                          </MenuItem>
                          <MenuItem value="%" className="custom-menuitem">
                            %
                          </MenuItem>
                          <MenuItem value="auto" className="custom-menuitem">
                            auto
                          </MenuItem>
                        </Select>
                      </span>
                    </div>
                  </div>
                  <div className="rht-info-edit">Height</div>
                </div>
              </div>
            </div>
          </>
          <>
            <div className="rht-flds-wrap">
              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      placeholder={minWidthValue === "auto" ? "auto" : ""}
                      value={getNumericAndUnit(minWidthValue).numeric}
                      onChange={handleMinWidthNumericChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={
                          minWidthValue === "auto"
                            ? "auto"
                            : getNumericAndUnit(minWidthValue).unit
                        }
                        onChange={handleMinWidthUnitChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                        <MenuItem value="auto" className="custom-menuitem">
                          auto
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info-edit">minWidth</div>
              </div>

              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      placeholder={minHeightValue === "auto" ? "auto" : ""}
                      value={getNumericAndUnit(minHeightValue).numeric}
                      onChange={handleMinHeightNumericChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={
                          minHeightValue === "auto"
                            ? "auto"
                            : getNumericAndUnit(minHeightValue).unit
                        }
                        onChange={handleMinHeightUnitChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                        <MenuItem value="auto" className="custom-menuitem">
                          auto
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info-edit">minHeight</div>
              </div>
            </div>
          </>
          <>
            <div className="rht-flds-wrap">
              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      placeholder={maxWidthValue === "auto" ? "auto" : ""}
                      value={getNumericAndUnit(maxWidthValue).numeric}
                      onChange={handleMaxWidthNumericChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={
                          maxWidthValue === "auto"
                            ? "auto"
                            : getNumericAndUnit(maxWidthValue).unit
                        }
                        onChange={handleMaxWidthUnitChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                        <MenuItem value="auto" className="custom-menuitem">
                          auto
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info-edit">maxWidth</div>
              </div>

              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      placeholder={maxHeightValue === "auto" ? "auto" : ""}
                      value={getNumericAndUnit(maxHeightValue).numeric}
                      onChange={handleMaxHeightNumericChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={
                          maxHeightValue === "auto"
                            ? "auto"
                            : getNumericAndUnit(maxHeightValue).unit
                        }
                        onChange={handleMaxHeightUnitChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                        <MenuItem value="auto" className="custom-menuitem">
                          auto
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info-edit">maxHeight</div>
              </div>
            </div>
          </>
        </PropertiesAccordion>
      </div>
    </StyledAlignItems>
  );
};

export default Size;
