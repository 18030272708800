import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CustomPopUpForm from "../layout/CustomPopUpForm";
function ChooseRegion({
  open,
  onClose,
  title,
  dropdownOptions,
  selectedDropdownOption,
  handleDropdownChange,
}) {
  console.log("dropdownOptions ", dropdownOptions);
  
  const [internalRegion, setInternalRegion] = useState(selectedDropdownOption);
  const regionChangeInternal = (region) => {
    setInternalRegion(region);
  };
  
  const handleSubmit = () => {
    handleDropdownChange(internalRegion);
    onClose();
  };


  console.log("internalRegion ", internalRegion);
  return (
    <CustomPopUpForm open={open} onClose={onClose} title={title}>
      {dropdownOptions &&
        selectedDropdownOption !== null &&
        selectedDropdownOption !== undefined &&
        handleDropdownChange && (
          <FormControl fullWidth size="small">
            <InputLabel>Region</InputLabel>
            <Select
              fullWidth
              value={internalRegion}
              onChange={(event) => regionChangeInternal(event.target.value)}
              label="Region"
              variant="outlined"
              size="small"
            >
              {dropdownOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
                // <MenuItem key={index} value={option.region}>
                //   {option.region}
                // </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      <Button
        style={{ marginLeft: "1rem", marginRight: "2.5rem" }}
        fullWidth
        variant="contained"
        onClick={handleSubmit}
      >
        Go
      </Button>
    </CustomPopUpForm>
  );
}

export default ChooseRegion;
