import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/system";

function LineStyleDropdown({ formData, onChange }) {
  const [selectedOption, setSelectedOption] = useState("dashed");

  const handleSelect = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    onChange(option);
  };

  const lineStyles = [
   
    { name: "Dashed Line", style: "dashed" },
    { name: "Straight Line", style: "solid" },
  ];

  return (
    <FormControl style={{width:"80%", marginLeft:"-5px"}}>
      <div className="rht-info fontlft-size">Line Style</div>
      <Select
        className="rht-fld-select rht-fld-select-flow"
        value={selectedOption}
        onChange={handleSelect}
        style={{width:"145px"}}
      >
        {lineStyles.map((option, index) => (
          <MenuItem key={index} value={option.style} style={{ height: "2rem" }}>
            <LineStylePreview style={lineStyle(option.style)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

// Styled component for line style preview
const LineStylePreview = styled("div")({
  width: "50px",
  height: "1px",
  display: "inline-block",
  borderTop: (props) =>
    props.style === "solid" ? "1px solid black" : "1px dashed black",
});

// Inline style function for line styles
const lineStyle = (style) => ({
  width: "50px",
  height: "1px",
  display: "inline-block",
  borderTop: style === "solid" ? "1px solid black" : "1px dashed black",
});

export default LineStyleDropdown;
