import React, { useRef } from "react";
import { Box, Button, Typography, MenuItem, Select } from "@mui/material";
import { ThemeProvider, Tooltip } from "@mui/material";
import { themeProvider } from "../../ThemeProvider";
import { Theme } from "../IncludeFile/Theme";

function NavBar({
  searchQuery,
  handleSearch,
  isSearchBar,
  leftButton,
  rightButtons,
  iconButtons,
  title,
  toggleMenu,
  isMenuOpenleft,
  dropdownOptions,
  selectedDropdownOption,
  handleDropdownChange,
  icon,
  handleMenushwDskButtonClick,
}) {
  const renderLeftButton = () =>
    leftButton ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip arrow title={leftButton.tooltip}>
          <Button
            variant="contained"
            color={leftButton.color || "primary"}
            style={{ height: "33px" }}
            onClick={leftButton.handleClick}
          >
            {leftButton.icon}
            <h6 className="addcontrollbutton">{leftButton.text}</h6>
          </Button>
        </Tooltip>
        {renderIconButtons()}
      </div>
    ) : (
      <div></div>
    );

  const renderIconButtons = () =>
    iconButtons
      ? iconButtons.map((iconButton, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={iconButton.handleClick}
            style={{
              marginLeft: "5px",
              height: "33px",
            }}
          >
            <Tooltip arrow title={iconButton.tooltip}>
              {iconButton.icon}
            </Tooltip>
          </Button>
        ))
      : null;

  const renderRightButtons = () =>
    rightButtons &&
    rightButtons.map((button, index) => (
      <Button
        key={index}
        color={button.color || "secondary"}
        variant="contained"
        className="addandsavebutton"
        onClick={button.handleClick}
      >
        <Tooltip
          arrow
          title={
            <span className="tooltipstyletopnav">
              {button.tooltip ? button.tooltip : button.text}
            </span>
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {button.icon}
            <h6 className="addcontrollbutton">{button.text}</h6>
          </div>
        </Tooltip>
      </Button>
    ));

  const inputRef = useRef(null);

  return (
    <ThemeProvider theme={themeProvider}>
      <div className="containerbtnsecction" style={{ paddingRight: "2px" }}>
        {isSearchBar && (
          <div className="menu-srch-form-wrap-frist">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="inputfield-wrap">
                <input
                  className="inputfield"
                  id="outlined-multiline-flexible"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => handleSearch(e)}
                  ref={inputRef}
                />
                <div className="inputfield-srch-icon">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      inputRef.current.focus();
                    }}
                  >
                    {Theme.Search}
                  </span>
                </div>
              </div>
            </Box>
          </div>
        )}

        {icon && (
          <Tooltip
            id="tooltipstyletoppanel"
            arrow
            title={
              <span className="tooltipstyletoppanel">Show/Hide left panel</span>
            }
          >
            <div
              onClick={handleMenushwDskButtonClick}
              className="left-sidebar-flow"
            >
              {Theme.Menu}
            </div>
          </Tooltip>
        )}

        {leftButton && (
          <div
            className="bordersec"
            style={{ marginLeft: !isSearchBar ? "0.5rem" : 0 }}
          />
        )}

        {isMenuOpenleft && (
          <div className="slect-wrap-slide">
            <Tooltip
              id="tooltipstyletoppanel"
              arrow
              title={
                <span className="tooltipstyletoppanel">
                  Show/Hide left panel
                </span>
              }
            >
              <span onClick={toggleMenu} className="left-panel-icon">
                {Theme.MenuOpenIcon}
              </span>
            </Tooltip>
          </div>
        )}

        <div
          className="addsavebtn"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
        >
          {renderLeftButton()}
          {title && (
            // <Tooltip arrow title={Pro}>
            <Typography className="custom-typogrpy">{title}</Typography>
            // </Tooltip>
          )}
          <div
            className="save-export-btn"
            style={{ display: "flex", alignItems: "center", padding: "10px" }}
          >
            {dropdownOptions &&
              selectedDropdownOption !== null &&
              selectedDropdownOption !== undefined &&
              handleDropdownChange && (
                <Select
                  value={selectedDropdownOption}
                  onChange={(event) => handleDropdownChange(event.target.value)}
                  style={{ marginRight: "10px", height: "33px" }}
                  variant="outlined"
                  size="small"
                >
                  {dropdownOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            {renderRightButtons()}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default NavBar;
