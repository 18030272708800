export function getFormattedLocalDateTime() {
  const todayDateTime = new Date().toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  // Replace the space between date and time with 'T'
  return todayDateTime.replace(" ", "T");
}
