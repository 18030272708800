import React from "react";
import {
  BannerContainer,
  GifContainer,
  Gif,
  BannerContent,
  TextBanner,
} from "../../pages/Login/LoginStyles";

const BottomBanner = ({ gifSrc, children }) => {
  const isImage =
    typeof children === "string" &&
    children.match(/\.(jpeg|jpg|gif|png)$/) !== null;

  return (
    <BannerContainer>
      <GifContainer>
        <Gif src={gifSrc} alt="GIF" />
      </GifContainer>
      <BannerContent>
        {isImage ? (
          <img
            src={children}
            alt="Content"
            // style={{ width: "640px", height: "160px", objectFit: "fill" }}
            style={{ width: "100%", maxHeight: "160px", objectFit: "fill" }}
          />
        ) : (
          <TextBanner>{children}</TextBanner>
        )}
      </BannerContent>
    </BannerContainer>
  );
};

export default BottomBanner;
