const DividerJSON = {
  id: "",
  name: "Divider1",
  controlName: "Divider1",
  tableHeader: "",
  data: "Divider",
  type: "Divider",
  parentid: "0",
  htmlTag: "hr",
  controltype: "Divider",
  styles: "",
  styleid: "",
  styleType: "Divider",
  mediaURl: "divide",
  check: false,
  switchcheck: false,
  title: "Display",
  value: "",
  media: "",
  option: "false",
  switchoption: "false",
  position: 1,
  checkposition: "left",
  tab: "Text",
  inputType: "Date",
  inputText: "",
  imageText: "",
  sourceData: "sourceData",
  controlChange: "Divider",
  tableProp: "null",
  imageText: "",
  control: [],
};

const initialTableJSON = {
  id: "",
  name: "Table1",
  controlName: "Table1",
  tableHeader: "tableHead",
  data: "",
  type: "Table",
  parentid: "0",
  htmlTag: "table",
  controltype: "Table",
  styleid: "",
  styles: "",
  styleType: "Table",
  mediaURl: "table",
  check: false,
  switchcheck: false,
  title: "Display",
  value: "",
  media: "",
  option: "false",
  switchoption: "false",
  position: 1,
  checkposition: "left",
  tab: "Text",
  inputType: "Header",
  inputText: "",
  imageText: "",
  sourceData: "",
  controlChange: "Table",
  imageText: "",
  control: [
    {
      id: "",
      name: "Table1_head",
      controlName: "Table1_head",
      tableHeader: "",
      data: "",
      type: "Table",
      parentid: "0.1",
      htmlTag: "thead",
      controltype: "Header",
      styleid: "",
      styles: "",
      styleType: "Table",
      mediaURl: "table",
      check: false,
      switchcheck: false,
      title: "Display",
      value: "",
      media: "",
      option: "false",
      switchoption: "false",
      position: 1,
      checkposition: "left",
      tab: "Text",
      inputType: "Header",
      inputText: "",
      imageText: "",
      sourceData: "",
      controlChange: "Header",
      tableProp: "tableProp",
      imageText: "",
      control: [],
    },
    {
      id: "",
      name: "Row1",
      controlName: "Row1",
      data: "",
      tableHeader: "",
      type: "Table",
      parentid: "0.1",
      htmlTag: "tr",
      controltype: "Row",
      styles: "",
      styleid: "",
      styleType: "Table",
      mediaURl: "table",
      check: false,
      switchcheck: false,
      title: "Display",
      value: "",
      media: "",
      option: "false",
      switchoption: "false",
      position: 1,
      checkposition: "left",
      tab: "Text",
      inputType: "Row",
      inputText: "",
      imageText: "",
      sourceData: "",
      controlChange: "Row",
      tableProp: "propse",
      imageText: "",
      control: [],
    },
  ],
};

const DateControlJSON = [
  {
    id: "0.1",
    name: "Container1",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Container",
    styles: "",
    styleid: "agrfb1",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Containers",
    value: "",
    media: "AddTempContainer",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "DateContainer",
    tab: "Text",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "null",
    url: "   ",
    control: [
      {
        id: "0.1.1",
        name: "Label1",
        controlName: "Label1",
        tableHeader: "",
        data: "Label",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb2",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Date",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "DateLabel",
        tab: "Text",
        inputType: "text",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "null",
        url: "   ",
        control: [],
      },
      {
        id: "0.1",
        name: "Date1",
        controlName: "Date1",
        tableHeader: "",
        data: "2024-05-15",
        type: "Date",
        parentid: "0",
        htmlTag: "input",
        controltype: "Date",
        styles: "",
        styleid: "agrfb1",
        styleType: "Date",
        mediaURl: "calendar-days",
        check: false,
        switchcheck: false,
        title: "Input",
        value: "",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "right",
        tab: "Text",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Date",
        tableProp: "null",
        url: "   ",
        control: [],
      },
    ],
  },
];
const generatedJSON = [
  {
    id: "",
    name: "Container1",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Container",
    styles: "",
    styleid: "",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Containers",
    value: "",
    media: "AddTempContainer",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "left",
    tab: "Text",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",

    control: [
      {
        id: "",
        name: "Label1",
        controlName: "Label1",
        tableHeader: "",
        data: "Label",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Label",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "GeneralLabel",
        tab: "Text",
        inputType: "text",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        control: [],
      },
      {
        id: "",
        name: "TextBox1",
        controlName: "TextBox1",
        tableHeader: "",
        data: "TextBox",
        type: "TextBox",
        parentid: "0.1",
        htmlTag: "input",
        controltype: "TextBox",
        styles: "",
        styleid: "",
        styleType: "TextBox",
        mediaURl: "text-height",
        check: false,
        switchcheck: false,
        title: "Input",
        value: "",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "left",
        tab: "Text",
        inputType: "text",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "TextBox",
        control: [],
      },
    ],
  },
];

const ImageJSON = [
  {
    id: "",
    name: "Container1",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Container",
    styles: "",
    styleid: "agrfb1",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Containers",
    value: "",
    media: "AddTempContainer",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "left",
    tab: "Text",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "null",
    control: [
      {
        id: "",
        name: "Label1",
        controlName: "Label1",
        tableHeader: "",
        data: "Label",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Image",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "ImageLabel",
        tab: "Text",
        inputType: "text",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        control: [],
      },
      {
        id: "0.1.2",
        name: "Image1",
        controlName: "Image1",
        tableHeader: "",
        data: "Image",
        type: "Image",
        parentid: "0.1",
        htmlTag: "img",
        controltype: "Image",
        styles: "",
        styleid: "agrfb3",
        styleType: "Image",
        mediaURl: "image",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: null,
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "left",
        tab: "left",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Image",
        tableProp: "null",
        control: [],
      },
    ],
  },
];

const SwitchJSON = [
  {
    id: "",
    name: "Container",
    controlName: "Container",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Container",
    styles: "",
    styleid: "",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Containers",
    value: "",
    media: "AddTempContainer",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "left",
    tab: "Text",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "null",
    control: [
      {
        id: "",
        name: "Switch_Label",
        controlName: "Label1",
        tableHeader: "",
        data: "Switch_Label",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Switch",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "SwitchLabel",
        tab: "Text",
        inputType: "text",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        control: [],
      },
      {
        id: "",
        name: "Switch1",
        controlName: "Switch1",
        tableHeader: "",
        data: "Switch",
        type: "Switch",
        parentid: "0.1",
        htmlTag: "Switch",
        controltype: "Switch",
        styles: "",
        styleid: "agrfb3",
        styleType: "Switch",
        mediaURl: "toggle-on",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Switch",
        media: null,
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "left",
        tab: "left",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Switch",
        tableProp: "null",
        control: [],
      },
    ],
  },
];

const CheckboxJSON = [
  {
    id: "",
    name: "Container1",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "",
    htmlTag: "div",
    controltype: "Container",
    styles: "",
    styleid: "",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Containers",
    value: "",
    media: "AddTempContainer",
    option: "false",
    switchoption: "true",
    position: 1,
    checkposition: "right",
    tab: "",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "null",
    url: "   ",
    control: [
      {
        id: "",
        name: "Label1",
        controlName: "Label1",
        tableHeader: "",
        data: "Label",
        type: "Label",
        parentid: "",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Checkbox",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "MultipleChoiceLabel",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "null",
        url: "   ",
        control: [],
      },
      {
        id: "",
        name: "CheckboxContainer",
        controlName: "Checkbox1",
        tableHeader: "MainContainer",
        data: "",
        type: "Container",
        parentid: "",
        htmlTag: "div",
        controltype: "Container",
        styles: "",
        styleid: "",
        styleType: "RadioContainer",
        mediaURl: "square-check",
        title: "Containers",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Checkbox",
        tableProp: "null",
        url: "   ",
        control: [
          {
            id: "",
            name: "Checkbox2",
            controlName: "Checkbox2",
            tableHeader: "",
            data: "data",
            type: "Checkbox",
            parentid: "",
            htmlTag: "input",
            controltype: "Checkbox",
            styles: "",
            styleid: "",
            styleType: "Checkbox",
            mediaURl: "square-check",
            check: false,
            switchcheck: false,
            title: "Input",
            value: "Checkbox",
            media: "",
            option: "false",
            switchoption: "false",
            position: 1,
            checkposition: "right",
            tab: "",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Checkbox",
            tableProp: "null",
            url: "   ",
            control: [],
          },
        ],
      },
    ],
  },
];


const RadioJSON = [
  {
    id: "0.1",
    name: "Container1",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Container",
    styles: "",
    styleid: "agrfb1",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Containers",
    value: "",
    media: "AddTempContainer",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "left",
    tab: "Text",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "null",
    control: [
      {
        id: "0.1.1",
        name: "Label1",
        controlName: "Label1",
        tableHeader: "",
        data: "Label",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "agrfb2",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "SingleChoiceLabel",
        tab: "left",
        inputType: "Date",
        inputText: "",
        imageText: "Radio",
        sourceData: "sourceData",
        controlChange: "Label",
        tableProp: "null",
        control: [],
      },
      {
        id: "0.1.2",
        name: "Radio1",
        controlName: "Radio1",
        tableHeader: "MainContainer",
        data: "",
        type: "Container",
        parentid: "0.1",
        htmlTag: "div",
        controltype: "Radio",
        styles: "",
        styleid: "agrfb3",
        styleType: "RadioContainer",
        mediaURl: "circle-dot",
        title: "Input",
        tab: "left",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Radio",
        tableProp: "null",
        control: [
          {
            id: "0.1.2.1",
            name: "Radio2",
            controlName: "Radio2",
            tableHeader: "",
            data: "Radio",
            type: "Radio",
            parentid: "0.1.2",
            htmlTag: "input",
            controltype: "Radio",
            styles: "",
            styleid: "agrfb3",
            styleType: "Radio",
            mediaURl: "circle-dot",
            check: false,
            switchcheck: false,
            title: "Input",
            value: 1,
            media: 1,
            option: "false",
            switchoption: "false",
            position: 1,
            checkposition: "left",
            tab: "left",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: "sourceData",
            controlChange: "Radio",
            tableProp: "null",
            control: [],
          },
        ],
      },
    ],
  },
];

const IframeJSON = [
  {
    id: "",
    name: "Container1",
    controlName: "Container1",
    tableHeader: "MainContainer",
    data: "",
    type: "Container",
    parentid: "0",
    htmlTag: "div",
    controltype: "Container",
    styles: "",
    styleid: "agrfb1",
    styleType: "Container",
    mediaURl: "square-poll-horizontal",
    check: false,
    switchcheck: false,
    title: "Containers",
    value: "",
    media: "AddTempContainer",
    option: "false",
    switchoption: "false",
    position: 1,
    checkposition: "left",
    tab: "Text",
    inputType: "Date",
    inputText: "",
    imageText: "",
    sourceData: "sourceData",
    controlChange: "Container",
    tableProp: "null",
    control: [
      {
        id: "",
        name: "Label1",
        controlName: "Label1",
        tableHeader: "",
        data: "",
        type: "Label",
        parentid: "0.1",
        htmlTag: "label",
        controltype: "Label",
        styles: "",
        styleid: "",
        styleType: "Label",
        mediaURl: "font",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Iframe",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "IframeLabel",
        tab: "Text",
        inputType: "text",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Label",
        control: [],
      },
      {
        id: "",
        name: "Iframe1",
        controlName: "Iframe1",
        tableHeader: "",
        data: "Iframe",
        type: "Iframe",
        parentid: "0",
        htmlTag: "iframe",
        controltype: "Iframe",
        styles: "",
        styleid: "agrfb40",
        styleType: "Iframe",
        mediaURl: "code",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "Iframe",
        media: "",
        option: "false",
        switchoption: "false",
        position: 1,
        checkposition: "right",
        tab: "",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "sourceData",
        controlChange: "Iframe",
        tableProp: "null",
        control: [],
      },
    ],
  },
];

export {
  DividerJSON,
  initialTableJSON,
  DateControlJSON,
  generatedJSON,
  ImageJSON,
  SwitchJSON,
  CheckboxJSON,
  RadioJSON,
  IframeJSON,
};
