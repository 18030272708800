import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBar, Button } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LeftNavBar from "./LeftNavBar";
import themeProvider from "../../ThemeProvider";
import { useNavigate } from "react-router-dom";
import { Theme } from "../IncludeFile/Theme";
import { toast } from "react-toastify";

const drawerWidth = "200px";

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: themeProvider.palette.landingBg.navBar,
  height: "55px",
  alignContent: "center",
  alignItems: "center",
  borderBottom: "1px solid #ccc",
}));



function LandingNavBar({ title , authData}) {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleToggleDrawer = () => {
    setOpen(!open);
  };


  const handleLogOut = async () => {
    try {
      const response = await fetch("https://aicloud.agiline.com:7355/EndSession", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accessKey: authData.accessKey,
        }),
      });
  
      if (response.ok) {
        const contentType = response.headers.get("Content-Type");
  
        if (contentType && contentType.includes("application/json")) {
           sessionStorage.clear();
           localStorage.clear();
          navigate("/")
        } else {
          const textData = await response.text();
          sessionStorage.clear();
          localStorage.clear();
          console.log("End session successfully:", textData);
          toast.info("End session successfully")
          navigate("/")
        }
      } else {
        console.error("Failed to validate OTP:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error during OTP validation:", error);
    }
  };
  

  return (
    <Box sx={{ display: "flex" }}>
      <>
        <AppBarStyled position="fixed" open={open} elevation={0}>
          <IconButton
            // color="black"
            style={{ color: "black" }}
            aria-label="open drawer"
            onClick={handleToggleDrawer}
            edge="start"
            sx={{ mr: 1, ml: 1 }}
          >
            <MenuIcon />
          </IconButton>

          {title && (
            <Typography className="custom-typogrpy">{title}</Typography>
          )}
          <Button variant="contained" color="success"  
          onClick={handleLogOut} style={{position : "absolute" , right : "10px"}}>
          {Theme.Logout}
            <span className="log-out-page">Logout</span>
          </Button>
          
        </AppBarStyled>
        {open && <LeftNavBar open={open} drawerWidth={drawerWidth} authData={authData} />}
      </>
    </Box>
  );
}

export default LandingNavBar;
