import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";
import { Theme, fontFamilies } from "../Components/IncludeFile/Theme";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const Font = ({
  matchedStyles,
  updateDefaultStyle,
  setDefaultStyleState,
  fontSizeValue,
  setFontSizeValue,
  fontFamilyValue,
  setFontFamilyValue,
  fontWeightValue,
  setFontWeightValue,
  fontStyleValue,
  setFontStyleValue,
  textDecorationValue,
  settextDecorationValue,
  colorValue,
  setColorValue,
  textAlignValue,
  setTextAlignValue,
  showmenudsk,
  setShowdskmenu,
  defaultStyle,
  setDefaultStyle,
  styleType,
}) => {
 
 

  const getFontSizeSelectValue = (value) => {
    const predefinedSizes = ["35px", "24px", "18px", "16px", "14px", "12px"];
    if (predefinedSizes.includes(value)) {
      return value;
    } else {
      return "Custom";
    }
  };



  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );

  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: {
              ...style.style,
              fontSize: fontSizeValue,
              fontFamily: fontFamilyValue,
              fontWeight: fontWeightValue,
              fontStyle: fontStyleValue,
              textDecoration: textDecorationValue,
              color: colorValue,
              textAlign: textAlignValue,
            },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
      // setDefaultStyleState(updatedStyle);
    }
  }, [
    fontSizeValue,
    fontFamilyValue,
    fontWeightValue,
    fontStyleValue,
    textDecorationValue,
    colorValue,
    textAlignValue,
    styleType,
  ]);

  const getNumericAndUnit = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }

    if (value === undefined || value === null) {
      return {
        numeric: "",
        unit: "px",
      };
    }

    const match = value.match(/^(-?[\d.]+)(\D*)$/);
    if (match) {
      const numeric = match[1];
      const unit = match[2] !== "" ? match[2] : "px";
      return {
        numeric: numeric,
        unit: unit,
      };
    }
    return {
      numeric: "",
      unit: "px",
    };
  };

  const handleFontSizeClick = (event) => {
    const value = event.target.value;
    if (value === "Custom") {
      setFontSizeValue("");
    } else {
      setFontSizeValue(value);
    }
  };

  const handleFontSizeChange = (event) => {
    setFontSizeValue(event.target.value);
  };

  const handleFontFamilyChange = (event) => {
    setFontFamilyValue(event.target.value);
  };

  const handleFontStyleChange = () => {
    const newValue = fontStyleValue === "italic" ? "normal" : "italic";
    setFontStyleValue(newValue);
  };

  const handleTextDecorationChange = () => {
    const newValue = textDecorationValue === "underline" ? "none" : "underline";
    settextDecorationValue(newValue);
  };

  const handleTextAlignChange = (value) => {
    setTextAlignValue(value);
  };

  const handleFontWeightChange = () => {
    const newValue = fontWeightValue === "bold" ? "normal" : "bold";
    setFontWeightValue(newValue);
  };

  const handleColorClick = (event) => {
    setColorValue(event.target.value);
  };
  const [showLeftIcon, setShowLeftIcon] = useState(true);
  const handleClick = () => {
    setShowLeftIcon(!showLeftIcon);
    setShowdskmenu(!showmenudsk);
  };

  return (
    <StyledAlignItems className="bor-remove-right-edit">
      <div>


        <PropertiesAccordion title={"Font"}>
          <>
            <div>
              <div className="backgroundclr-for-edit">
                Color:{" "}
                <input
                  type="color"
                  value={colorValue}
                  onChange={handleColorClick}
                  className="inpt-size-edit"
                />
              </div>
            </div>

            <div className="stle-font">
              <div className="rht-fld-selectwrap for-fild-styl">
                <div className="rht-info-edit fontlft-size backgrounclr">Font Size</div>
                {styleType !== "Icon" && styleType !== "Table" && (
                  <Select
                    className="rht-fld-select-edit"
                    onChange={handleFontSizeClick}
                    value={getFontSizeSelectValue(fontSizeValue)}
                  >
                    {[
                      { value: "35px", label: "Headline1" },
                      { value: "24px", label: "Headline2" },
                      { value: "18px", label: "Headline3" },
                      { value: "16px", label: "LargeText" },
                      { value: "14px", label: "NormalText" },
                      { value: "12px", label: "SmallText" },
                    ].map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{ fontSize: option.value }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem value="Custom" style={{ fontSize: "15px" }}>
                      Custom Style
                    </MenuItem>
                  </Select>
                )}
              </div>
            </div>

            <div className="rht-fnt">
              <div className="ipubrdr inputbrder fontszefr-inp-for-edit">
                <div className="rht-fld-for-edit">
                  <input
                    type="number"
                    value={getNumericAndUnit(fontSizeValue).numeric}
                    onChange={(e) => {
                      const newValue = `${e.target.value}${getNumericAndUnit(fontSizeValue).unit}`;
                      handleFontSizeChange({ target: { value: newValue } });
                    }}
                  />
                  <span className="unit-dropdown unit-font-drop">
                    <Select
                      value={getNumericAndUnit(fontSizeValue).unit}
                      onChange={(e) => {
                        const newValue = `${getNumericAndUnit(fontSizeValue).numeric}${e.target.value}`;
                        handleFontSizeChange({ target: { value: newValue } });
                      }}
                      style={{
                        width: "40px",
                        height: "25px",
                        backgroundColor: "#CFD8DD",
                        fontSize: "15px",
                      }}
                      displayEmpty
                      IconComponent={() => null}
                      MenuProps={{
                        style: { top: "5px" },
                      }}
                    >
                      <MenuItem value="px" className="custom-menuitem">
                        px
                      </MenuItem>
                      <MenuItem value="em" className="custom-menuitem">
                        em
                      </MenuItem>
                      <MenuItem value="%" className="custom-menuitem">
                        %
                      </MenuItem>
                    </Select>
                  </span>
                </div>
              </div>
            </div>

            <div className="rht-fld-selectwrap font-style-displ">
              <div className="rht-info-edit fontlft-size">Font Family</div>

              <Select
                className="rht-fld-select-edit"
                value={fontFamilyValue}
                onChange={(e) => handleFontFamilyChange(e)}
              >
                {fontFamilies.map((font, index) => (
                  <MenuItem
                    key={index}
                    value={font}
                    style={{ fontFamily: font }}
                  >
                    {font}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ float: "left" }} className="textAlgnBtns">
              <div className="topmidle">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleFontWeightChange(styleType);
                  }}
                  className={
                    fontWeightValue === "bold" ? "button-active" : "buttontp"
                  }
                >
                  <div
                    style={{
                      width: "35px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={
                      fontWeightValue === "bold"
                        ? "mui-button-active"
                        : "mui-button"
                    }
                  >
                    {Theme.FormatBold}
                  </div>
                  <span className="tooltiptext">Bold</span>
                </button>
              </div>
            </div>
            <div style={{ float: "left" }} className="textAlgnBtns">
              <div className="topmidle">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleFontStyleChange(styleType);
                  }}
                  className={
                    fontStyleValue === "italic" ? "button-active" : "buttontp"
                  }
                >
                  <div
                    style={{
                      width: "35px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={
                      fontStyleValue === "italic"
                        ? "mui-button-active"
                        : "mui-button"
                    }
                  >
                    {Theme.FormatItalic}
                  </div>
                  <span className="tooltiptext">Italic</span>
                </button>
              </div>
            </div>
            <div className="textAlgnBtns">
              <div className="topmidle">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleTextDecorationChange(styleType);
                  }}
                  className={
                    textDecorationValue === "underline"
                      ? "button-active"
                      : "buttontp"
                  }
                >
                  <div
                    style={{
                      width: "35px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={
                      textDecorationValue === "underline"
                        ? "mui-button-active"
                        : "mui-button"
                    }
                  >
                    {Theme.FormatUnderline}
                  </div>
                  <span className="tooltiptext">underline</span>
                </button>
              </div>
            </div>
            <div style={{ float: "left" }} className="textAlgnBtns">
              <div className="topmidle">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleTextAlignChange("left");
                  }}
                  className={
                    textAlignValue === "left" ? "button-active" : "buttontp"
                  }
                >
                  <div
                    style={{
                      width: "35px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={
                      textAlignValue === "left"
                        ? "mui-button-active"
                        : "mui-button"
                    }
                  >
                    {Theme.FormatAlignLeft}
                  </div>
                  <span className="tooltiptext">Left</span>
                </button>
              </div>
              </div>
              <div style={{ float: "left" }}className="textAlgnBtns">
              <div className="topmidle">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleTextAlignChange("center");
                  }}
                  style={{ marginRight: "3px" }}
                  className={
                    textAlignValue === "center" ? "button-active" : "buttontp"
                  }
                >
                  <div
                    style={{
                      width: "35px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={
                      textAlignValue === "center"
                        ? "mui-button-active"
                        : "mui-button"
                    }
                  >
                    {Theme.FormatAlignCenter}
                  </div>
                  <span className="tooltiptext">Middle</span>
                </button>
              </div>
              </div>
              <div style={{ float: "left" }} className="textAlgnBtns">
              <div className="topmidle">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleTextAlignChange("right");
                  }}
                  style={{ marginRight: "3px" }}
                  className={
                    textAlignValue === "right" ? "button-active" : "buttontp"
                  }
                >
                  <div
                    style={{
                      width: "35px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={
                      textAlignValue === "right"
                        ? "mui-button-active"
                        : "mui-button"
                    }
                  >
                    {Theme.FormatAlignRight}
                  </div>
                  <span className="tooltiptext">Right</span>
                </button>
              </div>
            </div>
          </>
        </PropertiesAccordion>
      </div>
    </StyledAlignItems>
  );
};

export default Font;
