import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const BodyColour = ({
  matchedStyles,
  updateDefaultStyle,
  backgroundColorValue,
  setBackgroundColorValue,
  setDefaultStyleState,
  defaultStyle,
  setDefaultStyle,
  styleType,
}) => {

  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );

  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: {
              ...style.style,
              backgroundColor: backgroundColorValue,
            },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
      // setDefaultStyleState(updatedStyle);
    }
  }, [backgroundColorValue, styleType]);

  const handleBackgroundColorClick = (eventOrValue) => {
    const value = typeof eventOrValue === 'object' ? eventOrValue.target.value : eventOrValue;
    setBackgroundColorValue(value);
  };

  return (
    <StyledAlignItems className="bor-remove-right-edit">
      <PropertiesAccordion title={"Body Color"}>
        <div className="backgroundclr color-flex-body bg-color-iconset">
          <p className="backgroundclr-for-body">backgroundColor: </p>
          <input
            type="color"
            value={backgroundColorValue}
            onChange={handleBackgroundColorClick}
            style={{
              width: "26px",
              height: "26px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          />

          <div className="iconset icon-set-color clearColorWrap-body forbgClr">
            <div
              className="clearColor-body"
              onClick={(e) => {
                e.preventDefault();
                handleBackgroundColorClick("transparent");
              }}
            >
              <span className="crsLinelft-body"></span>
              <span className="crsLinerht-body"></span>
              <span className="tooltiptext">Transparent</span>
            </div>
          </div>
        </div>
      </PropertiesAccordion>
    </StyledAlignItems>
  );
};

export default BodyColour;