import React, { useState } from "react";
import { List } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import themeProvider from "../ThemeProvider";
import { Theme } from "../Components/IncludeFile/Theme";
import { useMenu } from "./menuUtils";

const PropertiesAccordion = ({ isOpen, title, onRightClick, children }) => {
  // const { isVisible, setIsVisible } = useMenu();
  
  const [isVisible, setIsVisible] = useState(
    isOpen !== undefined && isOpen !== null ? isOpen : true
  );
  const handleToggleVisibility = (event) => {
    event.preventDefault();
    setIsVisible(!isVisible);
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    if (onRightClick) {
      onRightClick(); // Call the function passed as prop
    }
  };

  return (
    <ThemeProvider theme={themeProvider}>
      {/* <div className={isAccordion && "fldsSepertr"}> */}
      <div style={{width:"98%"}}
        //  className="fldsSepertr"
        onContextMenu={handleRightClick}
      >
        {/* {isAccordion && ( */}
        <div className="tglBtn">
          <button onClick={(event) => handleToggleVisibility(event)}>
            {isVisible ? (
              <div className="downarrow expandedArw">{Theme.ArrowDown}</div>
            ) : (
              <div className="downarrow shrinkedArw">{Theme.ChevronRight}</div>
            )}
          </button>
          <div className="checkbdr">{title}</div>
        </div>
        {isVisible && (
          <form>
            <List>{children}</List>
          </form>
        )}
      </div>
    </ThemeProvider>
  );
};

export default PropertiesAccordion;
