import React, { useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import { styled } from "@mui/system";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import curve from "../../../assets/curve.png";
import straight from "../../../assets/straight.png";
import bezier from "../../../assets/bezier.png";
function MarkerDropdown({ formData, onChange, isEnd }) {
  // const [selectedOption, setSelectedOption] = useState(
  //   isEnd ? formData.markerEnd : formData.markerStart
  // );

  const [selectedOption, setSelectedOption] = useState(
    isEnd ? formData.markerEnd || "none" : formData.markerStart || "none"
  );

  const handleSelect = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    onChange(option);
  };

  const markerTypes = [
    { type: "none", name: "None" },
    { type: "arrowclosed", name: "Arrow Closed" },
    { type: "arrow", name: "Arrow" },
    { type: "closed-circle-marker", name: "Circle Closed" },
    { type: "circle-marker", name: "Circle" },
    { type: "closed-square-marker", name: "Square Closed" },
    { type: "square-marker", name: "Square" },
    { type: "closed-rhombus-marker", name: "Rhombus Closed" },
    { type: "rhombus-marker", name: "Rhombus" },
  ];

  return (
    <FormControl style={{width:"100%", marginLeft:"-5px"}}>
      {isEnd ? (
        <div className="rht-info fontlft-size">Marker End</div>
      ) : (
        <div className="rht-info fontlft-size">Marker Start</div>
      )}
      <Select
       className="rht-fld-select rht-fld-select-flow"
        value={selectedOption}
        onChange={handleSelect}
        style={{width:"147px"}}
      >
        {markerTypes.map((option, index) => (
          <MenuItem key={index} value={option.type} style={{ height: "2rem", width:"100%" }}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MarkerDropdown;
