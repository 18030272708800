const Controls = [
  {
    title: "Containers",
    sortID: 1,
    items: [
      {
        id:1.1,
        styleType: "HeaderContainer",
        controltype: "HeaderContainer",
        NameOFControl: "Header Container",
        description: "",
        title: "HeaderContainer",
        mediaURl: "newspaper",
        htmlTag: "header",
        control: [],
      },
      {
        id:1.2,
        styleType: "Container",
        controltype: "Container",
        NameOFControl: "Container",
        description: "",
        mediaURl: "square-poll-horizontal",
        htmlTag: "div",
        control: [],
      },
      {
        id:1.3,
        styleType: "FooterContainer",
        controltype: "FooterContainer",
        NameOFControl: "Footer Container",
        description: "",
        mediaURl: "copyright",
        htmlTag: "footer",
        control: [],
      },
      {
        id:1.4,
        styleType: "RadioContainer",
        controltype: "RadioContainer",
        NameOFControl: "Radio Container",
        description: "",
        mediaURl: "copyright",
        htmlTag: "footer",
        control: [],
      },
    ],
  },
  {
    title: "Display",
    sortID: 2,
    items: [
      {
        id:2.1,
        styleType: "Image",
        controltype: "Image",
        description: "Image",
        NameOFControl: "Image",
        mediaURl: "image",
        htmlTag: "img",
        control: [],
      },
      {
        id:2.2,
        styleType: "Iframe",
        controltype: "Iframe",
        description: "Iframe",
        NameOFControl: "Iframe",
        mediaURl: "code",
        htmlTag: "iframe",
        control: [],
      },
      {
        id:2.3,
        styleType: "Label",
        controltype: "Label",
        description: "Label",
        NameOFControl: "Label",
        mediaURl: "font",
        htmlTag: "label",
        control: [],
      },
      {
        id:2.4,
        styleType: "Divider",
        description: "Divider",
        controltype: "Divider",
        NameOFControl: "Divider",
        mediaURl: "divide",
        htmlTag: "hr",
        control: [],
      },
      {
        id:2.5,
        styleType: "Header",
        controltype: "Header",
        description: "Header",
        NameOFControl: "Header",
        mediaURl: "heading",
        htmlTag: "h1",
        control: [],
      },
      {
        id:2.6,
        styleType: "SubHeader",
        controltype: "SubHeader",
        description: "SubHeader",
        NameOFControl: "Sub Header",
        mediaURl: "h",
        htmlTag: "h4",
        control: [],
      },
      {
        id:2.7,
        styleType: "List",
        controltype: "List",
        description: ["data1", "data2", "data3", "data4"],
        NameOFControl: "List",
        mediaURl: "list",
        htmlTag: "li",
        control: [],
      },
      {
        id:2.8,
        styleType: "Table",
        controltype: "Table",
        description: "",
        NameOFControl: "Table",
        mediaURl: "table",
        htmlTag: "th",
        control: [],
      },
      {
        id:2.9,
        styleType: "TableLabel",
        controltype: "TableLabel",
        description: "TableLabel",
        NameOFControl: "TableLabel",
        mediaURl: "font",
        htmlTag: "label",
        control: [],
      },
      {
        id:2.10,
        styleType: "Row",
        controltype: "Row",
        description: "Row",
        NameOFControl: "Row",
        mediaURl: "font",
        htmlTag: "label",
        control: [],
      },
    ],
  },
  {
    title: "Input",
    sortID: 3,
    items: [
      {
        id:3.1,
        styleType: "Button",
        controltype: "Button",
        NameOFControl: "Button",
        description: "Button",
        mediaURl: "play",
        htmlTag: "button",
        control: [],
      },
      {
        id:3.2,
        styleType: "TextBox",
        controltype: "TextBox",
        NameOFControl: "TextBox",
        description: "TextBox",
        mediaURl: "text-height",
        htmlTag: "input",
        control: [],
      },
      {
        id:3.3,
        styleType: "TextArea",
        controltype: "TextArea",
        NameOFControl: "TextArea",
        description: "TextArea",
        mediaURl: "text-width",
        htmlTag: "input",
        control: [],
      },
      {
        id:3.4,
        styleType: "Checkbox",
        controltype: "Checkbox",
        NameOFControl: "Checkbox",
        description: "data",
        mediaURl: "square-check",
        htmlTag: "input",
        control: [],
      },
      {
        id:3.5,
        styleType: "Date",
        controltype: "Date",
        NameOFControl: "Date",
        description: "Date",
        mediaURl: "calendar-days",
        htmlTag: "input",
        control: [],
      },
      {
        id:3.6,
        styleType: "Dropdown",
        controltype: "Dropdown",
        NameOFControl: "Dropdown",
        description: ["data1", "data2", "data3", "data4"],
        mediaURl: "square-caret-down",
        htmlTag: "select",
        control: [],
      },
      {
        id:3.7,
        styleType: "Radio",
        controltype: "Radio",
        NameOFControl: "Radio",
        description: "data",
        mediaURl: "circle-dot",
        htmlTag: "input",
        control: [],
      },
      {
        id:3.8,
        styleType: "Switch",
        controltype: "Switch",
        NameOFControl: "Switch",
        description: "Switch",
        mediaURl: "toggle-on",
        htmlTag: "Switch",
        control: [],
      },
      
    ],
  },
 
  {
    title: "Icon",
    sortID: 4,
    items: [
      {
        id:4.1,
        styleType: "Icon",
        controltype: "Underline",
        NameOFControl: "underline",
        description: "underline",
        mediaURl: "underline",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.2,
        styleType: "Icon",
        controltype: "Heading",
        NameOFControl: "heading",
        description: "heading",
        mediaURl: "heading",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.3,
        styleType: "Icon",
        controltype: "Italic",
        NameOFControl: "italic",
        description: "italic",
        mediaURl: "italic",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.4,
        styleType: "Icon",
        controltype: "Bold",
        NameOFControl: "bold",
        description: "bold",
        mediaURl: "bold",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.5,
        styleType: "Icon",
        controltype: "Check",
        NameOFControl: "check",
        description: "check",
        mediaURl: "check",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.6,
        styleType: "Icon",
        controltype: "Font",
        NameOFControl: "font",
        description: "font",
        mediaURl: "font",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.7,
        styleType: "Icon",
        controltype: "Highlighter",
        NameOFControl: "highlighter",
        description: "highlighter",
        mediaURl: "highlighter",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.8,
        styleType: "Icon",
        controltype: "Indent",
        NameOFControl: "indent",
        description: "indent",
        mediaURl: "indent",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.9,
        styleType: "Icon",
        controltype: "Outdent",
        NameOFControl: "outdent",
        description: "outdent",
        mediaURl: "outdent",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.10,
        styleType: "Icon",
        controltype: "Download",
        NameOFControl: "download",
        description: "download",
        mediaURl: "download",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.11,
        styleType: "Icon",
        controltype: "File",
        NameOFControl: "file",
        description: "file",
        mediaURl: "file",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.12,
        styleType: "Icon",
        controltype: "Filter",
        NameOFControl: "filter",
        description: "filter",
        mediaURl: "filter",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.13,
        styleType: "Icon",
        controltype: "Paperclip",
        NameOFControl: "paperclip",
        description: "paperclip",
        mediaURl: "paperclip",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.14,
        styleType: "Icon",
        controltype: "Comment",
        NameOFControl: "comment",
        description: "comment",
        mediaURl: "comment",
        htmlTag: "div",
        control: [],
      },

      {
        id:4.15,
        styleType: "Icon",
        controltype: "Pen",
        NameOFControl: "pen",
        description: "pen",
        mediaURl: "pen",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.16,
        styleType: "Icon",
        controltype: "Gear",
        NameOFControl: "gear",
        description: "gear",
        mediaURl: "gear",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.17,
        styleType: "Icon",
        controltype: "Trash",
        NameOFControl: "trash",
        description: "trash",
        mediaURl: "trash",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.18,
        styleType: "Icon",
        controltype: "Tag",
        NameOFControl: "tag",
        description: "tag",
        mediaURl: "tag",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.19,
        styleType: "Icon",
        controltype: "Bookmark",
        NameOFControl: "bookmark",
        description: "bookmark",
        mediaURl: "bookmark",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.20,
        styleType: "Icon",
        controltype: "Plus",
        NameOFControl: "plus",
        description: "plus",
        mediaURl: "plus",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.21,
        styleType: "Icon",
        controltype: "Minus",
        NameOFControl: "minus",
        description: "minus",
        mediaURl: "minus",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.22,
        styleType: "Icon",
        controltype: "Folder",
        NameOFControl: "folder",
        description: "folder",
        mediaURl: "folder",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.23,
        styleType: "Icon",
        controltype: "Search",
        NameOFControl: "Search",
        description: "Search",
        mediaURl: "magnifying-glass",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.24,
        styleType: "Icon",
        controltype: "Location",
        NameOFControl: "location",
        description: "location",
        mediaURl: "location-dot",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.25,
        styleType: "Icon",
        controltype: "Refresh",
        NameOFControl: "refresh",
        description: "refresh",
        mediaURl: "rotate-right",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.26,
        styleType: "Icon",
        controltype: "Copy",
        NameOFControl: "copy",
        description: "copy",
        mediaURl: "copy",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.27,
        styleType: "Icon",
        controltype: "User",
        NameOFControl: "user",
        description: "user",
        mediaURl: "user",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.28,
        styleType: "Icon",
        controltype: "House",
        NameOFControl: "house",
        description: "house",
        mediaURl: "house",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.29,
        styleType: "Icon",
        controltype: "Phone",
        NameOFControl: "phone",
        description: "phone",
        mediaURl: "phone",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.30,
        styleType: "Icon",
        controltype: "Star",
        NameOFControl: "star",
        description: "star",
        mediaURl: "star",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.31,
        styleType: "Icon",
        controltype: "CircleXmark",
        NameOFControl: "circleXmark",
        description: "circleXmark",
        mediaURl: "circle-xmark",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.32,
        styleType: "Icon",
        controltype: "Bell",
        NameOFControl: "bell",
        description: "bell",
        mediaURl: "bell",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.33,
        styleType: "Icon",
        controltype: "Cart-shopping",
        NameOFControl: "cart-shopping",
        description: "cart-shopping",
        mediaURl: "cart-shopping",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.34,
        styleType: "Icon",
        controltype: "Circle-info",
        NameOFControl: "circle-info",
        description: "circle-info",
        mediaURl: "circle-info",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.35,
        styleType: "Icon",
        controltype: "Clock",
        NameOFControl: "clock",
        description: "clock",
        mediaURl: "clock",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.36,
        styleType: "Icon",
        controltype: "Gift",
        NameOFControl: "gift",
        description: "gift",
        mediaURl: "gift",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.37,
        styleType: "Icon",
        controltype: "Barcode",
        NameOFControl: "barcode",
        description: "barcode",
        mediaURl: "barcode",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.38,
        styleType: "Icon",
        controltype: "Video",
        NameOFControl: "video",
        description: "video",
        mediaURl: "video",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.39,
        styleType: "Icon",
        controltype: "Password",
        NameOFControl: "Password",
        description: "Password",
        mediaURl: "eye",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.40,
        styleType: "Icon",
        controltype: "Hide Password",
        NameOFControl: "Hide Password",
        description: "Hide Password",
        mediaURl: "eye-slash",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.41,
        styleType: "Icon",
        controltype: "Thumbs-up",
        NameOFControl: "thumbs-up",
        description: "thumbs-up",
        mediaURl: "thumbs-up",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.42,
        styleType: "Icon",
        controltype: "Thumbs-down",
        NameOFControl: "thumbs-down",
        description: "thumbs-down",
        mediaURl: "thumbs-down",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.43,
        styleType: "Icon",
        controltype: "Comments",
        NameOFControl: "comments",
        description: "comments",
        mediaURl: "comments",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.44,
        styleType: "Icon",
        controltype: "Wifi",
        NameOFControl: "wifi",
        description: "wifi",
        mediaURl: "wifi",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.45,
        styleType: "Icon",
        controltype: "Earth",
        NameOFControl: "earth",
        description: "earth",
        mediaURl: "earth",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.46,
        styleType: "Icon",
        controltype: "Users",
        NameOFControl: "users",
        description: "users",
        mediaURl: "users",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.47,
        styleType: "Icon",
        controltype: "Link",
        NameOFControl: "link",
        description: "link",
        mediaURl: "link",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.48,
        styleType: "Icon",
        controltype: "Chart-simple",
        NameOFControl: "chart-simple",
        description: "chart-simple",
        mediaURl: "chart-simple",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.49,
        styleType: "Icon",
        controltype: "Quote-left",
        NameOFControl: "quote-left",
        description: "quote-left",
        mediaURl: "quote-left",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.50,
        styleType: "Icon",
        controltype: "Quote-right",
        NameOFControl: "quote-right",
        description: "quote-right",
        mediaURl: "quote-right",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.51,
        styleType: "Icon",
        controltype: "Calendar-days",
        NameOFControl: "calendar-days",
        description: "calendar-days",
        mediaURl: "calendar-days",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.52,
        styleType: "Icon",
        controltype: "File-pdf",
        NameOFControl: "file-pdf",
        description: "file-pdf",
        mediaURl: "file-pdf",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.53,
        styleType: "Icon",
        controltype: "Fingerprint",
        NameOFControl: "fingerprint",
        description: "fingerprint",
        mediaURl: "fingerprint",
        htmlTag: "div",
        control: [],
      },
      {
        id:4.54,
        styleType: "Icon",
        controltype: "Microphone",
        NameOFControl: "microphone",
        description: "microphone",
        mediaURl: "microphone",
        htmlTag: "div",
        control: [],
      },
    ],
  },
  {
    title: "DataBlocks",
    sortID: 5,
    items: [
      {
        id: 5.1,
        styleType: "Container",
        controltype: "Address",
        NameOFControl: "Address",
        description: "",
        mediaURl: "location-dot",
        htmlTag: "div",
        stylemedia : "Address",
        control: [],
      },
      {
        id: 5.2,
        styleType: "Container",
        controltype: "ShortText",
        stylemedia : "ShortText",
        NameOFControl: "ShortText",
        description: "",
        mediaURl: "text-width",
        htmlTag: "div",
        control: [],
      },
      {
        id: 5.3,
        styleType: "Container",
        controltype: "LongText",
        stylemedia : "LongText",
        NameOFControl: "LongText",
        description: "",
        mediaURl: "text-height",
        htmlTag: "div",
        control: [],
      },

      {
        id: 5.4,
        styleType: "Container",
        controltype: "FullName",
        stylemedia : "FullName",
        NameOFControl: "FullName",
        description: "",
        mediaURl: "address-book",
        htmlTag: "div",
        control: [],
      },
      {
        id: 5.5,
        styleType: "Container",
        controltype: "DropDown",
        stylemedia : "DropDown",
        NameOFControl: "DropDown",
        description: "",
        mediaURl: "square-caret-down",
        htmlTag: "div",
        control: [],
      },

      {
        id: 5.6,
        styleType: "Container",
        controltype: "MultipleChoice",
        stylemedia : "MultipleChoice",
        NameOFControl: "MultipleChoice",
        description: "",
        mediaURl: "square-check",
        htmlTag: "div",
        control: [],
      },
      {
        id: 5.7,
        styleType: "Container",
        controltype: "SingleChoice",
        stylemedia : "SingleChoice",
        NameOFControl: "SingleChoice",
        description: "",
        mediaURl: "circle-dot",
        htmlTag: "div",
        control: [],
      },
      {
        id: 5.8,
        styleType: "Container",
        controltype: "PhoneNumber",
        stylemedia : "PhoneNumber",
        NameOFControl: "PhoneNumber",
        description: "",
        mediaURl: "phone",
        htmlTag: "div",
        control: [],
      },
      {
        id: 5.9,
        styleType: "Container",
        controltype: "Email",
        stylemedia : "Email",
        NameOFControl: "Email",
        description: "",
        mediaURl: "envelope",
        htmlTag: "div",
        control: [],
      },
      {
        id:5.10,
        styleType: "Container",
        controltype: "Heading",
        stylemedia : "Heading",
        NameOFControl: "Heading",
        description: "",
        mediaURl: "heading",
        htmlTag: "div",
        control: [],
      },
      {
        id: 5.11,
        styleType: "Container",
        controltype: "DateBlock",
        stylemedia : "DateBlock",
        NameOFControl: "Date",
        description: "",
        mediaURl: "calendar-days",
        htmlTag: "div",
        control: [],
      },
      {
        id: 5.12,
        styleType: "Container",
        controltype: "RatingBlock",
        stylemedia : "RatingBlock",
        NameOFControl: "RatingBlock",
        description: "",
        mediaURl: "star",
        htmlTag: "div",
        control: [],
      }
      
    ],
  },
];

export default Controls;
