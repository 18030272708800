import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Typography,
  MenuItem,
  Select,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import "../assets/icofontcharts/icofont.min.css";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import {
  DividerJSON,
  initialTableJSON,
  DateControlJSON,
  generatedJSON,
  ImageJSON,
  SwitchJSON,
  CheckboxJSON,
  RadioJSON,
  IframeJSON,
} from "../Components/IncludeFile/AllJSON";
import { ReadArray } from "../Components/Api/ApiCalls";
import { loadLoginData } from "../apiCalls/DiagramsAPI";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const AddTemplate = ({ setAddTemplate }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [arrayNames, setArrayNames] = useState([]);
  const [selectedArrayName, setSelectedArrayName] = useState("");
  const [editedArrayName, setEditedArrayName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedArrayId, setSelectedArrayId] = useState(0);

  const [dataFieldName, setDataFieldName] = useState(null);
  const [formNameExists, setFormNameExists] = useState(false);

  const [DynamicJSON, setDynamicJSON] = useState([]);

  const loginData = loadLoginData();
  let accessKey = loginData.accessKey || "";

  
 

  

  let APIProvider = window.APIProvider;

  const navigate = useNavigate();

  const handleCancel = () => {
    setIsOpen(false);
    setAddTemplate(false);
  };

  const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`;

  const [FormNameError, setFormNameError] = useState("");
  const [DataSourceError, setDataSourceError] = useState("");

  const handleArrayNameChange = (e) => {
    const trimmedValue = e.target.value;
    setEditedArrayName(trimmedValue);
    setFormNameError("");
  };

  const [defaultStyle, setDefaultStyle] = useState([]);

  const fetchDefaultStyle = useCallback(async () => {
    try {
      const jsonData = await ReadArray(10,accessKey);
      const fetchedData = jsonData[0].Source;
      setDefaultStyle(fetchedData);
    } catch (error) {
      console.error("Error during request:", error);
      toast.error("Error during request.");
      return null;
    }
  }, []);
  const GetIsTemplate = {
    accessKey: loginData.accessKey,
    guid: APIProvider.GUID.ReadArrayAllGUID,
    param1: "",
    param2: "",
    param3: "",
    param4: "",
    recordSet: "",
    body: "",
  };

  useEffect(() => {
    if (!loginData || !loginData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    fetchDefaultStyle();

    const fetchArrayNames = async () => {
      try {
        const response = await fetch(baseURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(GetIsTemplate),
        });

        if (response.ok) {
          const resultData = await response.json();

          if (resultData.apiResponse) {
            const data = JSON.parse(resultData.apiResponse);

            const filteredNames = data
              .filter((item) => Number(item.IsTemplate) === 2)
              .map((item) => item.ArrayName);

            setArrayNames(filteredNames);
          } else {
            console.error("Error: apiResponse is missing");
          }
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error fetching array names:", error);
      }
    };

    fetchArrayNames();
  }, []);

  const [res, setRes] = useState([]);

  const [ReadArrayallres, setReadArrayAllRes] = useState([]);

  useEffect(() => {
    if (!loginData || !loginData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }

    fetchDefaultStyle();

    const requestBody = {
      accessKey: loginData.accessKey,
      guid: APIProvider.GUID.ReadArrayAllGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: "",
    };

    const fetchArrayNames = async () => {
      try {
        const response = await fetch(baseURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const data = await response.json();

          const apiResponse = JSON.parse(data.apiResponse);

          setReadArrayAllRes(apiResponse);

          const filteredNames = apiResponse
            .filter((item) => item.IsTemplate === 2)
            .map((item) => item.ArrayName);

          const filteredData = apiResponse.filter(
            (item) => item.IsTemplate === 2
          );
          setRes(filteredData);

          setArrayNames(filteredNames);
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error fetching array names:", error);
      }
    };

    fetchArrayNames();
  }, []);

  const handleArrayNameChange1 = async (event) => {
    setSelectedArrayName(event.target.value);

    let TableJSON = JSON.parse(JSON.stringify(initialTableJSON));
    try {
      const selectedArray = res.find(
        (item) => item.ArrayName === event.target.value
      );

      if (selectedArray) {
        const jsonData = await ReadArray(selectedArray.ArID, accessKey);

        const parsedSource = jsonData[0].Source;

        setSelectedArrayId(selectedArray.ArID);
        setDataFieldName(parsedSource);
        const textFieldsTable = parsedSource.filter(
          (item) => item.elementtype === "array" && item.type === "table"
        );

        if (textFieldsTable.length > 0) {
          const numberOfObjectsInside = textFieldsTable[0].elements.length;
          for (let i = 1; i <= numberOfObjectsInside; i++) {
            TableJSON.control[0].control.push({
              id: "",
              name: `Header${i}`,
              controlName: `Header${i}`,
              tableHeader: `Header${i}`,
              data: `Header${i}`,
              type: "Table",
              parentid: "0.1.thead",
              htmlTag: "th",
              controltype: "Header",
              styleid: "",
              styles: "",
              styleType: "TableHeader",
              mediaURl: "table",
              check: false,
              switchcheck: false,
              title: "Display",
              value: "",
              media: "",
              option: "false",
              switchoption: "false",
              position: 1,
              checkposition: "left",
              tab: "Text",
              inputType: "Date",
              sourceData: "Cell",
              controlChange: `Header${i}`,
              tableProp: "tableProp",
            });

            TableJSON.control[1].control.push({
              id: "",
              name: `label${i}`,
              controlName: `label${i}`,
              tableHeader: `label${i}`,
              data: `Cell${i}`,
              type: "Table",
              parentid: "0.1.row1",
              htmlTag: "td",
              controltype: "Label",
              styles: "",
              styleid: "",
              styleType: "TableLabel",
              mediaURl: "font",
              check: false,
              switchcheck: false,
              title: "Display",
              value: "",
              media: "",
              option: `Header${i}`,
              switchoption: "false",
              position: 1,
              checkposition: "left",
              tab: "Text",
              inputType: "Date",
              sourceData: "Header",
              controlChange: `label${i}`,
              tableProp: "tableProp",
            });
          }
        } else {
          console.log(
            "No objects found with elementtype 'array' and type 'table'"
          );
        }

        const extractDataWithSourceHeader = (data, textFields) => {
          const dataArray = [];
          if (data.sourceData && data.sourceData === "Header") {
            if (textFields && textFields.length > 0) {
              dataArray.push(textFields[0].fieldname);
              textFields.shift();
            }
          }
          if (data.control && data.control.length > 0) {
            data.control.forEach((control) => {
              const subArray = extractDataWithSourceHeader(control, textFields);
              dataArray.push(...subArray);
            });
          }
          return dataArray;
        };

        let extractedData;

        if (
          textFieldsTable &&
          textFieldsTable.length > 0 &&
          textFieldsTable[0].elements
        ) {
          extractedData = extractDataWithSourceHeader(
            TableJSON,
            textFieldsTable[0].elements
          );
        } else {
          console.error(
            "textFieldsTable or its elements are undefined or empty."
          );
        }

        let currentTextFieldIndex = 0;

        const updateDataWithSourceHeader = (data) => {
          if (data.sourceData && data.sourceData === "Header") {
            if (extractedData && extractedData.length > currentTextFieldIndex) {
              data.data = `{${extractedData[currentTextFieldIndex]}}`;
              currentTextFieldIndex++;
            }
          }
          if (data.control && data.control.length > 0) {
            data.control.forEach((control) => {
              updateDataWithSourceHeader(control);
            });
          }
        };

        updateDataWithSourceHeader(TableJSON);

        const extractDataWithSourceCell = (data, textFields) => {
          const dataArray = [];
          if (data.sourceData && data.sourceData === "Cell") {
            if (textFields && textFields.length > 0) {
              dataArray.push(textFields[0].fieldname);
              textFields.shift();
            }
          }
          if (data.control && data.control.length > 0) {
            data.control.forEach((control) => {
              const subArray = extractDataWithSourceCell(control, textFields);
              dataArray.push(...subArray);
            });
          }
          return dataArray;
        };

        let extractedDataCell;
        if (
          textFieldsTable &&
          textFieldsTable.length > 0 &&
          textFieldsTable[0].elements
        ) {
          extractedDataCell = extractDataWithSourceCell(
            TableJSON,
            textFieldsTable[0].elements
          );
        } else {
          console.error(
            "textFieldsTable or its elements are undefined or empty."
          );
        }

        let currentTextFieldIndexCell = 0;

        const updateDataWithSourceCell = (data) => {
          if (data.sourceData && data.sourceData === "Cell") {
            if (
              extractedData &&
              extractedData.length > currentTextFieldIndexCell
            ) {
              data.data = extractedData[currentTextFieldIndexCell];
              currentTextFieldIndexCell++;
            }
          }
          if (data.control && data.control.length > 0) {
            data.control.forEach((control) => {
              updateDataWithSourceCell(control);
            });
          }
        };

        updateDataWithSourceCell(TableJSON);

        const sourceJSON = parsedSource;
        const RootJSON = [
          {
            id: "",
            name: "Root",
            type: "Root",
            styleid: "rootStyleId",
            styles:
              '{\n  "backgroundColor": "#FFFFFF",\n  "borderWidth": "4px",\n  "paddingBottom": 10,\n  "paddingLeft": 10,\n  "paddingRight": 10,\n  "paddingTop": 10,\n  "backgroundImage": null,\n  "width": "80%",\n  "maxWidth": "1200",\n  "margin": "auto",\n  "borderColor": "#F0F4FF",\n  "alignMent": "left",\n  "borderStyle": "solid"\n}',
            parentstyles:
              '{\n  "backgroundColor": "#efefef",\n  "backgroundImage": "null"}',
            control: [],
          },
        ];
        sourceJSON.forEach((item, index) => {
          switch (item.elementtype) {
            case "field":
              if (item.fieldtype === "text") {
                RootJSON[0].control.push(generatedJSON[0]);
              } else if (
                item.fieldtype === "date" ||
                item.fieldtype === "time" ||
                item.fieldtype === "datetime"
              ) {
                RootJSON[0].control.push(DateControlJSON[0]);
              } else if (item.fieldtype === "Image") {
                RootJSON[0].control.push(ImageJSON[0]);
              } else if (item.fieldtype === "Iframe") {
                RootJSON[0].control.push(IframeJSON[0]);
              } else if (
                item.fieldtype === "boolean" &&
                item.controltype === "Switch"
              ) {
                RootJSON[0].control.push(SwitchJSON[0]);
              } else if (
                item.fieldtype === "number" &&
                item.controltype === "Switch"
              ) {
                RootJSON[0].control.push(SwitchJSON[0]);
              } else if (
                item.fieldtype === "boolean" &&
                item.controltype === "Checkbox"
              ) {
                RootJSON[0].control.push(CheckboxJSON[0]);
              } else if (
                item.fieldtype === "number" &&
                item.controltype === "Checkbox"
              ) {
                RootJSON[0].control.push(CheckboxJSON[0]);
              } else if (
                item.fieldtype === "boolean" &&
                item.controltype === "Radio"
              ) {
                RootJSON[0].control.push(RadioJSON[0]);
              } else if (
                item.fieldtype === "number" &&
                item.controltype === "Radio"
              ) {
                RootJSON[0].control.push(RadioJSON[0]);
              }
              break;
            case "shape":
              RootJSON[0].control.push(DividerJSON);
              break;
            case "array":
              // if (item.elements.length > 0) {
              RootJSON[0].control.push(TableJSON);
              // }
              break;
            default:
              break;
          }
        });

        function applyStylesRecursively(control, defaultStyle) {
          control.forEach((controlItem) => {
            const defaultStyleItem = defaultStyle.find(
              (style) => style.styleType === controlItem.styleType
            );

            if (controlItem.controltype === "Container") {
              defaultStyleItem.style.borderWidth = "0px";
              defaultStyleItem.style.flexDirection = "row";
            }
            
            if (controlItem.checkposition === "DateContainer") {
              defaultStyleItem.style.justifyItems = "flex-start";
              defaultStyleItem.style.justifyContent = "flex-start";
            }
            
          

            if (controlItem.controltype === "Label") {
              defaultStyleItem.style.fontWeight = "normal";
              defaultStyleItem.style.maxWidth = "30%";
            }

            if (controlItem.htmlTag === "thead") {
              defaultStyleItem.style.display = "flex";
              defaultStyleItem.style.padding = "0px";
            }
            if (controlItem.controltype === "Row") {
              defaultStyleItem.style.display = "flex";
              defaultStyleItem.style.padding = "0px";
            }

            if (controlItem.checkposition === "DateContainer"){
              defaultStyleItem.style.alignItems = "flex-start";
              defaultStyleItem.style.alignContent = "flex-start";
              defaultStyleItem.style.justifyItems = "flex-start";
              defaultStyleItem.style.justifyContent = "flex-start";
            }

            if (controlItem.styleType === "RadioContainer") {
              defaultStyleItem.style.marginTop = "0px";
              defaultStyleItem.style.marginBottom = "0px";
              defaultStyleItem.style.marginLeft = "0px";
              defaultStyleItem.style.marginRight = "0px";
              defaultStyleItem.style.paddingTop = "0px";
              defaultStyleItem.style.paddingBottom = "0px";
              defaultStyleItem.style.paddingLeft = "0px";
              defaultStyleItem.style.paddingRight = "0px";
             
            }

            if (controlItem.styleType === "Table") {
              defaultStyleItem.style.margin = "0px";
            }

           

            if (controlItem.controltype === "Header") {
              if (defaultStyleItem) {
                if (!("fontWeight" in defaultStyleItem.style)) {
                  defaultStyleItem.style = {
                    ...defaultStyleItem.style,
                    fontWeight: "bold",
                  };
                }
              }
            }

            const shapeElements = sourceJSON.filter(
              (item) => item.elementtype === "shape" && item.type === "Line"
            );

            let shapeElement;

            shapeElements.forEach((element) => {
              shapeElement = element;
            });

            if (controlItem.controltype === "Divider") {
              defaultStyleItem.style.borderColor = shapeElement.color;
              defaultStyleItem.style.width = shapeElement.width;
            }

            if (defaultStyleItem) {
              controlItem.styles = JSON.stringify(defaultStyleItem.style);
              if (controlItem.control && controlItem.control.length > 0) {
                applyStylesRecursively(controlItem.control, defaultStyle);
              }
            } else {
              console.log(
                "No matching style found for style type:",
                controlItem.styleType
              );
            }
          });
        }
        RootJSON.forEach((item) => {
          applyStylesRecursively(item.control, defaultStyle);
        });

        setDynamicJSON(RootJSON);

        setDataSourceError("");
      } else {
        console.error("Selected array not found");
      }
    } catch (error) {
      console.error("Error fetching array names:", error);
    }
  };
  useEffect(() => {
    const updatedData = updatestyleidValues(DynamicJSON);
    setDynamicJSON(updatedData);
  }, []);

  const updatestyleidValues = (obj, parentID = "", index = 1, counter = {}) => {
    if (Array.isArray(obj)) {
      let newIndex = index;
      return obj.map((item, i) =>
        updatestyleidValues(item, parentID, newIndex++, counter)
      );
    } else if (typeof obj === "object" && obj !== null) {
      const newObj = { ...obj };
      if (parentID === "") {
        newObj.id = "0";
      } else {
        newObj.id = index === 1 ? `${parentID}.1` : `${parentID}.${index}`;
        newObj.parentid = parentID;
      }
      if (newObj.control && newObj.control.length > 0) {
        newObj.control = updatestyleidValues(
          newObj.control,
          `${newObj.id}`,
          1,
          counter
        );
      }

      for (let key in newObj) {
        if (key.startsWith("styleid") && newObj.type !== "Root") {
          const num = counter[key] || 1;
          newObj[key] = `agrfb${num}`;
          counter[key] = num + 1;
        }
      }
      return newObj;
    } else {
      return obj;
    }
  };

  const updatedDynamicJSON = updatestyleidValues(DynamicJSON);

  const rootObjectIndex = updatedDynamicJSON.findIndex(
    (obj) => obj.type === "Root"
  );
  if (rootObjectIndex !== -1) {
    updatedDynamicJSON[rootObjectIndex].id = "0";
  } else {
  }

  let filteredDataIndex = 0;

  function extractDataFromControls(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.fieldtype === "text"
      );

      for (let control of controls) {
        if (control.value === "Label") {
          if (filteredDataIndex < filteredData.length) {
            control.data =
              filteredData[filteredDataIndex].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredData[filteredDataIndex].fieldname.slice(1);
            control.name =
              filteredData[filteredDataIndex].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredData[filteredDataIndex].fieldname.slice(1) +
              "_" +
              control.value;

            filteredDataIndex++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControls(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControls(updatedDynamicJSON);

  let filteredDataIndextextbox = 0;

  function extractDataFromControlstextbox(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.fieldtype === "text"
      );

      for (let control of controls) {
        if (control.type === "TextBox") {
          if (filteredDataIndextextbox < filteredData.length) {
            control.data = `{${filteredData[filteredDataIndextextbox].fieldname}}`;
            control.name = `${filteredData[filteredDataIndextextbox].fieldname.charAt(0).toUpperCase() + filteredData[filteredDataIndextextbox].fieldname.slice(1) + "_" + control.type}`;

            filteredDataIndextextbox++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlstextbox(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlstextbox(updatedDynamicJSON);

  let filteredDataIndexDate = 0;

  function extractDataFromControlsDate(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) =>
          item.elementtype === "field" &&
          (item.fieldtype === "date" ||
            item.fieldtype === "time" ||
            item.fieldtype === "datetime")
      );

      for (let control of controls) {
        if (control.type === "Date") {
          if (filteredDataIndexDate < filteredData.length) {
            control.inputText = `{${filteredData[filteredDataIndexDate].fieldname}}`;
            filteredDataIndexDate++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsDate(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsDate(updatedDynamicJSON);

  //-----------------------------Date----------------------------------

  let filteredDataIndexDateControl = 0;

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  function extractDataFromControlsDateControl(controls) {
    if (dataFieldName !== null) {
      const filteredDataDateControl = dataFieldName.filter(
        (item) =>
          item.elementtype === "field" &&
          (item.fieldtype === "date" ||
            item.fieldtype === "time" ||
            item.fieldtype === "datetime")
      );

      for (let control of controls) {
        if (control.value === "Date") {
          if (filteredDataIndexDateControl < filteredDataDateControl.length) {
            control.data =
              filteredDataDateControl[filteredDataIndexDateControl].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataDateControl[
                filteredDataIndexDateControl
              ].fieldname.slice(1);
            control.name =
              filteredDataDateControl[filteredDataIndexDateControl].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataDateControl[
                filteredDataIndexDateControl
              ].fieldname.slice(1) +
              "_" +
              control.value;
            filteredDataIndexDateControl++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsDateControl(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsDateControl(updatedDynamicJSON);

  let filteredDataIndexDateName = 0;

  function extractDataFromControlsDateName(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) =>
          item.elementtype === "field" &&
          (item.fieldtype === "date" ||
            item.fieldtype === "time" ||
            item.fieldtype === "datetime")
      );

      for (let control of controls) {
        if (control.type === "Date") {
          if (filteredDataIndexDateName < filteredData.length) {
            control.data = `{${filteredData[filteredDataIndexDateName].fieldname}}`;
            control.name = `${filteredData[filteredDataIndexDateName].fieldname.charAt(0).toUpperCase() + filteredData[filteredDataIndexDateName].fieldname.slice(1)}`;
            filteredDataIndexDateName++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsDateName(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsDateName(updatedDynamicJSON);

  // ---------------------------Image ---------------------------------

  let filteredDataIndexImage = 0;

  function extractDataFromControlsImage(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.fieldtype === "Image"
      );

      for (let control of controls) {
        if (control.type === "Image") {
          if (filteredDataIndexImage < filteredData.length) {
            control.imageText = `{${filteredData[filteredDataIndexImage].fieldname}}`;
            control.data = `{${filteredData[filteredDataIndexImage].fieldname}}`;
            filteredDataIndexImage++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsImage(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsImage(updatedDynamicJSON);

  let filteredDataIndexImageControl = 0;

  function extractDataFromControlsImageControl(controls) {
    if (dataFieldName !== null) {
      const filteredDataImageControl = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.fieldtype === "Image"
      );

      for (let control of controls) {
        if (control.value === "Image") {
          if (filteredDataIndexImageControl < filteredDataImageControl.length) {
            control.data =
              filteredDataImageControl[filteredDataIndexImageControl].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataImageControl[
                filteredDataIndexImageControl
              ].fieldname.slice(1);
            control.name =
              filteredDataImageControl[filteredDataIndexImageControl].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataImageControl[
                filteredDataIndexImageControl
              ].fieldname.slice(1) +
              "_" +
              control.value;

            filteredDataIndexImageControl++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsImageControl(control.control);
        }
      }
    } else {
    }
  }
  extractDataFromControlsImageControl(updatedDynamicJSON);

  //--------------------------------Iframe-------------------------

  let filteredDataIndexIframe = 0;

  function extractDataFromControlsIframe(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.fieldtype === "Iframe"
      );
      const fixedIframeUrl = "https://aicloud.agiline.com";

      for (let control of controls) {
        if (control.type === "Iframe") {
          if (filteredDataIndexIframe < filteredData.length) {
            control.imageText = `{${filteredData[filteredDataIndexIframe].fieldname}}`;
            control.data = fixedIframeUrl;
            filteredDataIndexIframe++;
            control.data = fixedIframeUrl;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsIframe(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsIframe(updatedDynamicJSON);

  let filteredDataIndexIframeControl = 0;

  function extractDataFromControlsIframeControl(controls) {
    if (dataFieldName !== null) {
      const filteredDataIframeControl = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.fieldtype === "Iframe"
      );

      for (let control of controls) {
        if (control.value === "Iframe") {
          if (
            filteredDataIndexIframeControl < filteredDataIframeControl.length
          ) {
            control.data =
              filteredDataIframeControl[
                filteredDataIndexIframeControl
              ].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataIframeControl[
                filteredDataIndexIframeControl
              ].fieldname.slice(1);
            control.name =
              filteredDataIframeControl[
                filteredDataIndexIframeControl
              ].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataIframeControl[
                filteredDataIndexIframeControl
              ].fieldname.slice(1) +
              "_" +
              control.value;

            filteredDataIndexIframeControl++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsIframeControl(control.control);
        }
      }
    } else {
    }
  }
  extractDataFromControlsIframeControl(updatedDynamicJSON);

  //--------------------------------Switch-------------------------

  let filteredDataIndexSwitch = 0;

  function extractDataFromControlsSwitch(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.controltype === "Switch"
      );

      for (let control of controls) {
        if (control.type === "Switch") {
          if (filteredDataIndexSwitch < filteredData.length) {
            control.imageText = `{${filteredData[filteredDataIndexSwitch].fieldname}}`;
            filteredDataIndexSwitch++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsSwitch(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsSwitch(updatedDynamicJSON);

  let filteredDataIndexSwitchControl = 0;

  function extractDataFromControlsSwitchControl(controls) {
    if (dataFieldName !== null) {
      const filteredDataSwitchControl = dataFieldName.filter(
        (item) => item.elementtype === "field" && item.controltype === "Switch"
      );

      for (let control of controls) {
        if (control.value === "Switch_Label") {
          if (
            filteredDataIndexSwitchControl < filteredDataSwitchControl.length
          ) {
            control.data =
              filteredDataSwitchControl[
                filteredDataIndexSwitchControl
              ].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataSwitchControl[
                filteredDataIndexSwitchControl
              ].fieldname.slice(1);
            control.name =
              filteredDataSwitchControl[
                filteredDataIndexSwitchControl
              ].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataSwitchControl[
                filteredDataIndexSwitchControl
              ].fieldname.slice(1) +
              "_" +
              control.value;

            filteredDataIndexSwitchControl++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsSwitchControl(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsSwitchControl(updatedDynamicJSON);

  //-----------------Checkbox / Radio  ---------------------

  let filteredDataIndexCheckbox = 0;

  function extractDataFromControlsCheckbox(controls) {
    if (dataFieldName !== null) {
      const filteredData = dataFieldName.filter(
        (item) =>
          (item.elementtype === "field" && item.controltype === "Checkbox") ||
          item.controltype === "Radio"
      );

      for (let control of controls) {
        if (control.type === "Checkbox" || control.type === "Radio") {
          if (filteredDataIndexCheckbox < filteredData.length) {
            control.imageText = `{${filteredData[filteredDataIndexCheckbox].fieldname}}`;
            control.data = `{${filteredData[filteredDataIndexCheckbox].fieldname}}`;
            control.name = `${filteredData[filteredDataIndexCheckbox].fieldname}`;
            filteredDataIndexCheckbox++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsCheckbox(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsCheckbox(updatedDynamicJSON);

  let filteredDataIndexCheckboxControl = 0;

  function extractDataFromControlsCheckboxControl(controls) {
    if (dataFieldName !== null) {
      const filteredDataCheckboxControl = dataFieldName.filter(
        (item) =>
          item.elementtype === "field" &&
          (item.controltype === "Checkbox" || item.controltype === "Radio")
      );

      for (let control of controls) {
        if (control.value === "Checkbox" || control.imageText === "Radio") {
          if (
            filteredDataIndexCheckboxControl <
            filteredDataCheckboxControl.length
          ) {
            control.data =
              filteredDataCheckboxControl[
                filteredDataIndexCheckboxControl
              ].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataCheckboxControl[
                filteredDataIndexCheckboxControl
              ].fieldname.slice(1);
            control.name =
              filteredDataCheckboxControl[
                filteredDataIndexCheckboxControl
              ].fieldname
                .charAt(0)
                .toUpperCase() +
              filteredDataCheckboxControl[
                filteredDataIndexCheckboxControl
              ].fieldname.slice(1) +
              "_" +
              control.value;

            filteredDataIndexCheckboxControl++;
          }
        }

        if (control.control && Array.isArray(control.control)) {
          extractDataFromControlsCheckboxControl(control.control);
        }
      }
    } else {
    }
  }

  extractDataFromControlsCheckboxControl(updatedDynamicJSON);

  let filteredContainerIndex = 0;

  function extractContainerFromControls(controls) {
    for (let control of controls) {
      if (control.type === "Container") {
        control.name = "Container" + (filteredContainerIndex + 1);
        filteredContainerIndex++;
      }

      if (control.control && Array.isArray(control.control)) {
        extractContainerFromControls(control.control);
      }
    }
  }

  extractContainerFromControls(updatedDynamicJSON);

  const [effectRun, setEffectRun] = useState(false);

  useEffect(() => {
    const checkFormNameExists = async () => {
      try {
        const fetchDataResponseArray = await fetch(baseURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(GetIsTemplate),
        });
        if (fetchDataResponseArray.ok) {
          const resultData = await fetchDataResponseArray.json();

          if (resultData.apiResponse) {
            const data = JSON.parse(resultData.apiResponse);
            const arrayNames = data.map((item) => item.ArrayName);
            if (
              arrayNames.some(
                (name) => name.toLowerCase() === editedArrayName.toLowerCase()
              )
            ) {
              toast.error(
                "Form name already exists. Please choose a different name."
              );
              setFormNameExists(true);
            } else {
              setFormNameExists(false);
            }
          } else {
            console.error("Error: apiResponse is missing");
          }
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    if (editedArrayName.trim()) {
      checkFormNameExists();
      setEffectRun(true);
    }
  }, [editedArrayName]);

  const [dynamicJSONValid, setDynamicJSONValid] = useState(false);

  useEffect(() => {
    if (DynamicJSON && DynamicJSON.length > 0) {
      setDynamicJSONValid(true);
    } else {
      setDynamicJSONValid(false);
    }
  }, [DynamicJSON]);

  const handleNext = async () => {
    if (!loginData || !loginData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }

    setLoading(true);

    if (!editedArrayName.trim()) {
      setFormNameError("Form Name is required");
      setLoading(false);
      if (!selectedArrayName.trim()) {
        setDataSourceError("Data Source is required");
        return;
      }
      return;
    } else if (!selectedArrayName.trim()) {
      setDataSourceError("Data Source is required");
      setLoading(false);
      return;
    } else {
      setFormNameError("");
      setDataSourceError("");
    }

    if (formNameExists) {
      toast.error("Form name already exists. Please choose a different name.");
      setLoading(false);
      return;
    }

    if (!DynamicJSON || !DynamicJSON.length) {
      console.error("DynamicJSON is empty or invalid");
      return DynamicJSON;
    }

    const requestBody = {
      accessKey: loginData.accessKey,
      guid: APIProvider.GUID.WriteArrayGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: JSON.stringify({
        ArrayName: editedArrayName,
        Description: description,
        Source: JSON.stringify(updatedDynamicJSON),
        Category: "Json Form",
        IsTemplate: "0",
        htmlOutput: "new",
        DataSourceArID: selectedArrayId,
      }),
    };

    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        console.log("Data successfully posted");

        const requestBodyarrayname = {
          accessKey: loginData.accessKey,
          guid: APIProvider.GUID.ReadArrayByNameGUID,
          param1: editedArrayName,
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: "",
        };
        const readResponse = await fetch(baseURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBodyarrayname),
        });

        if (readResponse.ok) {
          const readData = await readResponse.json();

          const parsedResponse = JSON.parse(readData.apiResponse);
          const arID = parsedResponse[0].ArID;
          const newArrayName = parsedResponse[0].ArrayName;
          const newDescription = parsedResponse[0].Description;
          const newCategory = parsedResponse[0].Category;
          const newSource = parsedResponse[0].Source;
          const newdataSourceArID = parsedResponse[0].DataSourceArID;

          const stateObject = {
            arID: arID,
            ArrayName: newArrayName,
            Description: newDescription,
            Category: newCategory,
            source: newSource,
            IsTemplate: "0",
            htmlOutput: "new",
            DataSourceArID: newdataSourceArID,
          };
          setLoading(false);

          if (newdataSourceArID > 0) {
            navigate(`/Form/${arID}`, { state: stateObject });
          }
        } else {
          console.error("Failed to fetch arID");
        }
      } else {
        console.error("Failed to post data");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const classes = useStyles();

  return (
    <CustomPopUpForm
      open={isOpen}
      onClose={handleCancel}
      title="Create Form from JSON"
      height="60%"
    >
      <>
        <div className="card-info">
          <div className="card-info-inside card-info-inside-view">
            <Typography style={{ fontWeight: "bold" }}>Form Name*:</Typography>
            <TextField
              value={editedArrayName}
              onChange={handleArrayNameChange}
              className="edit-popup"
              error={!!FormNameError}
              helpertext={FormNameError}
            />
            {FormNameError && (
              <FormHelperText style={{ color: "red" }}>
                Form Name is required
              </FormHelperText>
            )}
          </div>
          <div className="card-info-inside">
            <Typography style={{ fontWeight: "bold" }}>Description:</Typography>
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="edit-popup"
            />
          </div>
          <div className="card-info-inside">
            <Typography style={{ fontWeight: "bold" }}>
              Data Source*:
            </Typography>
            <Select
              value={selectedArrayName}
              onChange={handleArrayNameChange1}
              className={`${classes.customSelectRoot} data-select-crd`}
              error={!!DataSourceError}
              helpertext={DataSourceError}
            >
              {arrayNames.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {DataSourceError && (
              <FormHelperText style={{ color: "red" }}>
                Data Source is required
              </FormHelperText>
            )}
          </div>
        </div>
      </>
      <div style={{ width: "90%", display: "flex" }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCancel}
          className="back-addtmplt"
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleNext}
          disabled={formNameExists || !dynamicJSONValid}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
        </Button>
      </div>
    </CustomPopUpForm>
  );
};

export default AddTemplate;
