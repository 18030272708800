import { toast } from "react-toastify";

const DEFAULT_USERID = "-1";

let APIProvider = window.APIProvider;

let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
    //   MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {
    //   MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
  },
  mode: "cors",
};

function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

// function GetAPI() {
//   return AquariusAPI;
// }

// function GetSessionUser() {
//   return userInfo;
// }

function GetDefaultUserID() {
  return DEFAULT_USERID;
}

//Start of API
async function LoginHelper(username, password, tenant) {
  try {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
        Authorization:
          "Basic " + btoa(username + ":" + password + ":" + tenant),
      },
      mode: "cors",
    };
    var url = APIProvider.baseURL + APIProvider.recordPath.LoginUser;
    var response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    var loginResponse = await response.json();

    if (loginResponse !== null && loginResponse.memberID !== null) {
      console.log("loginResponse TRUE", loginResponse);
      toast.success(loginResponse.apiResponse);
      return !loginResponse.isError;
    } else {
      toast.error("Please make sure all the information you entered is valid");
      return false;
    }
  } catch (error) {
    console.error("Login error:", error);
    toast.error("Please make sure all the information you entered is valid");
    return false;
  }
}
export { LoginHelper };
