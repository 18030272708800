import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Theme } from "../../IncludeFile/Theme";
import themeProvider from "../../../ThemeProvider";
import { ThemeProvider } from "@mui/styles";
import NavBar from "../../navbarComponents/NavBar";
import SaveAsConfirmation from "./SaveAsConfirmation";
import CustomPopUpForm from "../../../layout/CustomPopUpForm";
import { GetToolPropertiesAll } from "../../../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import {
  UpdateDiagTools,
  UpdateToolProperties,
  postResource,
  createRequestBody,
  loadLoginData,
} from "../../../apiCalls/DiagramsAPI";

import Preview from "./Preview";

import { Typography, Box, Button } from "@mui/material";
import ExpandableView from "../../../layout/ExpandableView";
import TabsComponent from "../TabsComponent";
import NodeDropDowns from "./NodeDropDowns";
import CaptionDropDowns from "./CaptionDropDowns";
import BodyDropDowns from "./BodyDropDowns";
import LinkDropDowns from "./LinkDropDowns";
import { TiExport } from "react-icons/ti";
import { GetToolProperties } from "../../../apiCalls/DiagramsAPI";
import PreviewNode from "./PreviewNode";
import DynamicNode from "../../../nodes/DynamicNode";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useAppContext } from "../RegionContext";
function NodeProperties() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedNodes = location.state?.prop;
  const tools = location.state?.tools;
  const [popup, setPopup] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const tabs = [{ label: "Style", value: "1" }];
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemID, setSelectedItemID] = useState();
  const [selectedItemPropertyGroup, setSelectedItemPropertyGroup] = useState();
  const [editedProperties, setEditedProperties] = useState();
  const [newProperties, setNewProperties] = useState();
  const [originalProperties, setOriginalProperties] = useState();
  const [leftSidebarTree, setLeftSidebarTree] = useState();
  const [leftCollapse, setLeftCollapse] = useState(false);
  const [fetched, setFetched] = useState(false);
  // State to store temporary properties
  const [tempNodes, setTempNodes] = useState({});

  let SidesList = null;
  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";

  //get info from RegionContext
  const { getRegionInfo } = useAppContext();
  const [regionInfo, setRegionInfo] = useState(null);

  const [data, setData] = useState(null);
  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      const info = await getRegionInfo(storedRegion);
      console.log("Region Names:", info);
      setRegionInfo(info);
      // Call fetchData only if info is valid and fetched is false
      if (info !== null && info !== undefined) {
        console.log("JSON.parse(info.ToolsJson) ", JSON.parse(info.ToolsJson));
        const toolJSON = JSON.parse(info.ToolsJson);
        setData(toolJSON);
        setNewProperties(toolJSON);
        return true;
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
      return false;
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await fetchRegions();
      console.log("fetchRegions result:", result);
      setFetched(result); // Only set fetched to true if fetchRegions returns true
    };

    fetchData(); // Call the async function
  }, [fetched]);

  async function getDiagToolProperties(diagToolID) {
    try {
      // let response = await GetToolProperties(diagToolID);
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.DiagToolsProperties}`,
          param1: diagToolID.toString(),
        })
      );
      console.log("response ", response);
      if (response[0].diagToolID !== 0) {
        return response;
      } else {
        let matchingTool = tools.find((tool) => tool.diagToolID === diagToolID);
        response[0].diagToolID = parseInt(diagToolID, 10);
        response[0].name = matchingTool.name;
        return response;
      }
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
      throw error;
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      // Map selectedNodes to getToolProperties calls and store the promises
      const propertyPromises = selectedNodes.map(async (toolID) => {
        return await getDiagToolProperties(toolID); // Return the promise
      });

      try {
        SidesList = await Promise.all(propertyPromises);
        setEditedProperties(SidesList);
        setOriginalProperties(SidesList);
        console.log("data ", data);
        console.log("selectedNodes ", selectedNodes);
        console.log("tools ", tools);
        console.log("SidesList ", SidesList);
        const updatedTree = SidesList.map((item) => ({
          id: item[0].diagtoolid,
          name:
            tools.find(
              (tool) =>
                tool.diagtoolid.toString() === item[0].diagtoolid.toString()
            )?.caption ||
            tools.find(
              (tool) =>
                tool.diagtoolid.toString() === item[0].diagtoolid.toString()
            )?.name ||
            "Tool",

          children: [
            {
              id: `${item[0].diagtoolid} Caption`,
              name: "Caption",
              children: [],
            },
            {
              id: `${item[0].diagtoolid} Node`,
              name: "Node",
              children: [],
            },
            {
              id: `${item[0].diagtoolid} Body`,
              name: "Body",
              children: [],
            },
            {
              id: `${item[0].diagtoolid} Link`,
              name: "Link",
              children: [],
            },
          ],
        }));
        console.log("updatedTree ", updatedTree);
        console.log("tools ", tools);
        setLeftSidebarTree(updatedTree);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching properties:", error);
      }
    };

    // Call the fetchData function
    fetchData();

    // Empty dependency array to run the effect only once
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const str = String(selectedItem.id);
      const [firstPart, secondPart] = str.split(/\s(.+)/);
      setSelectedItemID(parseInt(firstPart, 10));
      setSelectedItemPropertyGroup(secondPart);
    }
  }, [selectedItem, editedProperties]);
  //getting it from regions
  // async function fetchData() {
  //   try {
  //     const fetchedData = await GetToolPropertiesAll();
  //     setData(fetchedData);
  //   } catch (error) {
  //     console.error("Fetch Error ", error);
  //     // toast.error("Error fetching data: " + error.message);
  //   }
  // }
  // useEffect(() => {
  //   if (!data) {
  //     fetchData();
  //   }
  // }, [data]);
  //for getting the prop id
  const getPropertyDiagPropIDAndSection = (propertyName) => {
    const property = data?.find(
      (item) =>
        item.propertyName?.toString().toLowerCase() ===
        propertyName?.toLowerCase()
    );
    return {
      diagPropID: property.diagPropID,
      propSection: property.propSection,
    };
  };

  //for updating editedProperties
  // const handlePropertyChange = async (propName, propValue) => {
  //   // Check if the property already exists in editedProperties
  //   const propertyGroup = editedProperties.find(
  //     (group) => group[0]?.diagToolID === selectedItemID
  //   );
  //   const existingPropertyIndex = propertyGroup.findIndex(
  //     (property) => property.propertyName === propName
  //   );
  //   if (existingPropertyIndex === -1) {
  //     // if (data === null) {
  //     //   await fetchData();
  //     // }
  //     if (data) {
  //       // Get the property's diagPropID and propSection
  //       const { diagPropID, propSection } =
  //         getPropertyDiagPropIDAndSection(propName);

  //       // If the property doesn't exist, add it
  //       if (diagPropID && propSection) {
  //         const newProperty = {
  //           toolPropID: 0,
  //           diagToolID: selectedItemID,
  //           diagPropID,
  //           name: propertyGroup[0].name,
  //           propSection,
  //           propertyName: propName,
  //           diagPropValue: propValue,
  //         };
  //         // Add the new property to the existing property group
  //         let updatedPropertyGroup;
  //         if (propertyGroup[0].diagPropValue === null) {
  //           updatedPropertyGroup = [newProperty];
  //         } else {
  //           updatedPropertyGroup = [...propertyGroup, newProperty];
  //         }
  //         // Update the state with the modified properties
  //         setEditedProperties((prevProperties) =>
  //           prevProperties.map((group) =>
  //             group[0]?.diagToolID === selectedItemID
  //               ? updatedPropertyGroup
  //               : group
  //           )
  //         );
  //       }
  //     }
  //   } else if (existingPropertyIndex !== -1) {
  //     // Find the property object with the matching diagToolID and propName
  //     const updatedProperties = editedProperties.map((propertyGroup) => {
  //       return propertyGroup.map((property) => {
  //         if (
  //           property.diagToolID === selectedItemID &&
  //           property.propertyName === propName
  //         ) {
  //           // Update the property's diagPropValue
  //           return { ...property, diagPropValue: propValue };
  //         }
  //         return property;
  //       });
  //     });

  //     // Update the state with the modified properties
  //     setEditedProperties(updatedProperties);
  //   }
  // };
  //save as functionality
  async function saveasData(newTool) {
    try {
      const response = await UpdateDiagTools(newTool);
      console.log("response ", response);
      toast.success("Data Saved!");
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  }
  async function saveProperties(prop) {
    try {
      const filteredProperties = prop
        .flatMap((propertyGroup) => propertyGroup)
        .filter((property) => property.diagPropValue !== null);
      const response = await UpdateToolProperties(filteredProperties);
      navigate("/Landing");
      console.log("response ", response);
    } catch (error) {
      console.error("Fetch Error ", error);
    }
  }

  const handleSaveAs = () => {
    setPopup(!popup);
  };

  const handleSave = async (toolInfo) => {
    // Find the tool in SidesList with the same ID as selectedItemID
    const originalTool = editedProperties.find(
      (tool) => tool[0]?.diagToolID === selectedItemID
    );

    if (originalTool) {
      // Create a deep copy of the original tool
      const duplicatedTool = JSON.parse(JSON.stringify(originalTool));
      // Generate a new unique ID by adding 1 to the maximum existing ID number
      let maxID = 0;
      editedProperties.forEach((side) => {
        side.forEach((obj) => {
          if (obj.diagToolID > maxID) {
            maxID = obj.diagToolID;
          }
        });
      });

      duplicatedTool.forEach((obj) => {
        // Change the `diagToolID` to `maxID + 1`
        obj.diagToolID = maxID + 1;

        // Change the `name` property to `toolInfo.toolName` if `toolInfo.toolName` exists, otherwise to an empty string
        obj.name = toolInfo.toolName ? toolInfo.toolName : "";
      });
      saveProperties(duplicatedTool);
      // Optionally, modify any other properties as needed
      // Add the duplicated tool to editedProperties
      setEditedProperties((prev) => [...prev, duplicatedTool]);

      // Update leftSidebarTree
      const updatedLeftSidebarTree = [
        ...leftSidebarTree,
        {
          id: duplicatedTool[0].diagToolID,
          name: duplicatedTool[0].name,
          children: [
            {
              id: `${duplicatedTool[0].diagToolID} Caption`,
              name: "Caption",
              children: [],
            },
            {
              id: `${duplicatedTool[0].diagToolID} Node`,
              name: "Node",
              children: [],
            },
            {
              id: `${duplicatedTool[0].diagToolID} Body`,
              name: "Body",
              children: [],
            },
            {
              id: `${duplicatedTool[0].diagToolID} Link`,
              name: "Link",
              children: [],
            },
          ],
        },
      ];

      setLeftSidebarTree(updatedLeftSidebarTree);
      await saveasData({
        diagToolID: (maxID + 1).toString(),
        name: toolInfo.toolName ? toolInfo.toolName : "",
        title: toolInfo.toolTitle ? toolInfo.toolTitle : "",
        explain: toolInfo.toolExplain ? toolInfo.toolExplain : "",
        notes: toolInfo.toolNotes ? toolInfo.toolNotes : "",
        caption: toolInfo.toolCaption ? toolInfo.toolCaption : "",
        description: toolInfo.toolDescription ? toolInfo.toolDescription : "",
      });
      handleSaveAs();
    } else {
      console.error("Original tool not found!");
    }
  };

  //Nav bar components
  const handleInfoPopup = () => {
    setInfoPopup(!infoPopup);
  };
  const leftButton = {
    icon: <InfoIcon />,
    text: "Info", // color: "primary", // "primary" or "secondary"
    handleClick: () => {
      // Handle click for the left button
      handleInfoPopup();
    },
  };
  const rightButtons = [
    {
      icon: Theme.Save,
      text: "Save",
      color: "secondary",
      handleClick: () => {
        saveProperties(editedProperties);
      },
    },
    {
      icon: Theme.Restore,
      text: "Restore",
      color: "secondary",
      handleClick: () => {
        setEditedProperties(originalProperties);
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];
  // const newNodeData = [tools[0], editedProperties[0], 1];

  const [showLeftIcon, setShowLeftIcon] = useState(true);

  const handleIconClick = () => {
    setShowLeftIcon(!showLeftIcon);
    setLeftCollapse(!leftCollapse);
  };
  // const handleListItemClick = () => {

  // }
  console.log("fetched ", fetched);
  console.log("newProperties ", newProperties);

  const initializeProperties = (tool) => {
    const { diagtoolid, category } = tool;

    // Check if tool already has customstyle
    const existingNode = newProperties.Nodes.find(
      (node) => node.id === diagtoolid
    );
    console.log("existingNode ", existingNode);
    if (existingNode?.customstyle) {
      return JSON.parse(JSON.stringify(existingNode.customstyle));
    }

    // Otherwise, use category properties or <all> fallback
    const categoryProperties =
      newProperties[category] || newProperties["<all>"];
    console.log("categoryProperties ", categoryProperties);
    return JSON.parse(JSON.stringify(categoryProperties));
  };

  const handlePropertyChange = (tool, section, key, value) => {
    setTempNodes((prev) => {
      // Check if tool already exists in tempNodes
      if (!prev[tool.diagtoolid]) {
        // Initialize the tool's properties
        const initialProperties = initializeProperties(tool);
        return {
          ...prev,
          [tool.diagtoolid]: {
            ...initialProperties,
            [section]: {
              ...initialProperties[section], // Ensure section is initialized
              [key]: value, // Apply the change
            },
          },
        };
      }

      // If tool already exists, update the specific section and key
      return {
        ...prev,
        [tool.diagtoolid]: {
          ...prev[tool.diagtoolid],
          [section]: {
            ...prev[tool.diagtoolid][section], // Keep other keys in the section intact
            [key]: value, // Apply the change
          },
        },
      };
    });

    console.log(
      `Tool ${tool.diagtoolid} section '${section}' property '${key}' updated to:`,
      value
    );
  };

  console.log("tempNodes ", tempNodes);
  // Function to fetch tool's properties or initialize customstyle
  // const getToolProperties = (toolId, category) => {
  //   const toolNode = tools.find((tool) => tool.diagtoolid === toolId);

  //   if (toolNode && toolNode.customstyle) {
  //     // If customstyle exists, return it
  //     return { ...toolNode.customstyle };
  //   } else {
  //     // Get category or fallback properties
  //     const categoryProperties = properties[category] || properties["<all>"];
  //     // Return a deep copy of category properties as the new customstyle
  //     return JSON.parse(JSON.stringify(categoryProperties));
  //   }
  // };

  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        isSearchBar={true}
        rightButtons={rightButtons}
        title="Choose Tools properties"
        leftButton={leftButton}
      />
      {popup && (
        <SaveAsConfirmation
          open={popup}
          handleClose={handleSaveAs}
          handleSave={handleSave}
        />
      )}
      {infoPopup && (
        <CustomPopUpForm
          open={infoPopup}
          onClose={handleInfoPopup}
          title="Information"
        >
          <Typography>
            This page allows you to edit the properties of the tools on the left
            hand side of the workflow builder. You can also copy and tool and
            create a new tool using the Save As button.
          </Typography>
          <></>
        </CustomPopUpForm>
      )}
      <ExpandableView
        leftSidebarTree={leftSidebarTree}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleSaveAs={handleSaveAs}
        leftCollapse={leftCollapse}
        setLeftCollapse={setLeftCollapse}
        showLeftIcon={showLeftIcon}
        setShowLeftIcon={setShowLeftIcon}
      >
        {selectedItemID ? (
          // <PreviewNode
          //   properties={data}
          //   tools={tools}
          //   editedProperties={editedProperties}
          //   selectedItemID={selectedItemID}
          // />
          <Preview
            propertyData={data}
            tools={tools}
            selectedItemID={selectedItemID}
            tempNodes={tempNodes}
          />
        ) : (
          // <DynamicNode data={newNodeData} selected={true} />
          <></>
        )}
        <Box padding="0.5rem">
          <div className="inputfildtet" style={{ marginBottom: "0.5rem" }}>
            <div className="tools-group">
              <div>
                Tool -{" "}
                <span className="sltitmnem">
                  {
                    editedProperties
                      ?.flatMap((propertyGroup) => propertyGroup)
                      .find(
                        (property) => property.diagToolID === selectedItemID
                      )?.name
                  }
                </span>
                <span className="sltitmnem">{selectedItemPropertyGroup}</span>
              </div>
              <div className="top-left-icon-panel" onClick={handleIconClick}>
                {showLeftIcon ? <FaAngleRight /> : <FaAngleLeft />}
              </div>
            </div>
          </div>
          <TabsComponent
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabs={tabs}
          >
            <div key="1">
              {/* {selectedItemPropertyGroup === "Caption" && (
                <CaptionDropDowns
                  selectedItemID={selectedItemID}
                  editedProperties={editedProperties}
                  // handlePropertyChange={handlePropertyChange}
                />
              )} */}
              {selectedItemPropertyGroup === "Node" && (
                <NodeDropDowns
                  tools={tools}
                  selectedItemID={selectedItemID}
                  newProperties={newProperties}
                  handlePropertyChange={handlePropertyChange}
                  tempNodes={tempNodes}
                />
                // <NodeDropDowns
                //   selectedItemID={selectedItemID}
                //   editedProperties={editedProperties}
                //   handlePropertyChange={handlePropertyChange}
                // />
              )}
              {/* {selectedItemPropertyGroup === "Body" && (
                <BodyDropDowns
                  selectedItemID={selectedItemID}
                  editedProperties={editedProperties}
                  // handlePropertyChange={handlePropertyChange}
                />
              )} */}
              {/* {selectedItemPropertyGroup === "Link" && (
                <LinkDropDowns
                  selectedItemID={selectedItemID}
                  editedProperties={editedProperties}
                  // handlePropertyChange={handlePropertyChange}
                />
              )} */}
            </div>
          </TabsComponent>
        </Box>
      </ExpandableView>
    </ThemeProvider>
  );
}

export default NodeProperties;
