import React, { useState } from "react";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { Typography, Button, TextField } from "@mui/material";
import themeProvider from "../../ThemeProvider";
function DiagHeader({ open, handlePopup, item, handleNext, handleBack, data }) {
  const [formData, setFormData] = useState({
    diagTempID: item?.diagTempID ?? 0,
    diagID: item?.diagID ?? 0,
    name: item?.name ?? "",
    title: item?.title ?? "",
    explanation: item?.explanation ?? "",
    notes: item?.notes ?? "",
    showMinMap: item?.showMinMap ?? 1,
    layout: item?.layout ?? 1,
    nodes: item?.nodes ?? null,
    edges: item?.edges ?? null,
    viewPort: item?.viewPort ?? null,
    createdBy: item?.createdBy ?? null,
    createdDate: item?.createdDate ?? null,
    modifiedBy: item?.modifiedBy ?? null,
    modifiedDate: item?.modifiedDate ?? null,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Check the type of input element
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  return (
    <CustomPopUpForm
      open={open}
      onClose={handlePopup}
      title="Project Information"
    >
      <div className="card-info">
        <div>
          <Typography
            style={{
              fontWeight: "bold",
              color: themeProvider.palette.primary.main,
            }}
          >
            Title:
          </Typography>
          <TextField
            size="small"
            type="text"
            className="edit-popup"
            name="title"
            onChange={handleInputChange}
            value={formData.title}
          />
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          <Typography
            style={{
              fontWeight: "bold",
              color: themeProvider.palette.primary.main,
            }}
          >
            Description:
          </Typography>
          <TextField
            size="small"
            type="text"
            className="edit-popup"
            name="explanation"
            onChange={handleInputChange}
            value={formData.explanation}
          />
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          <Typography
            style={{
              fontWeight: "bold",
              color: themeProvider.palette.primary.main,
            }}
          >
            Notes:
          </Typography>
          <TextField
            sx={{ width: "98%", marginLeft: "auto", marginRight: "auto" }}
            type="text"
            name="notes"
            onChange={handleInputChange}
            value={formData.notes}
            multiline
          />
        </div>
      </div>
      <div style={{ width: "90%", display: "flex" }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleBack}
          className="back-addtmplt"
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={() => handleNext(formData)}
        >
          Next
        </Button>
      </div>
    </CustomPopUpForm>
  );
}

export default DiagHeader;
