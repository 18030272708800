import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useContext,
} from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DialogComponent from "./DialogComponent";
import {
  ReadArray,
  WriteArray,
  WriteArray1,
  WriteArrayTheme,
} from "../Api/ApiCalls";
import TreeItem from "./TreeItem";
import "./RecursiveTreeView.css";
import Controls from "../IncludeFile/Controls";
import { ImFilePicture } from "react-icons/im";
import {
  buttonStylesFlat,
  buttonStylesGradient,
  colorSchemes,
  fontFamilies,
  Theme,
  themes,
} from "../IncludeFile/Theme";
import ReactDOMServer from "react-dom/server";
import CustomHtmlTag from "./CustomHtmlTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams, useLocation } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import GeneralSection from "./GeneralSection";
import FormTopSection from "./FormTopSection";

import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Drawer,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  AddressJSON,
  DateJSON,
  DropDownJSON,
  EmailJSON,
  FullNameJSON,
  GenderCheckBoxJSON,
  GenderJSON,
  HeadingJSON,
  LongTextJSON,
  MultipleChoiceJSON,
  PhoneJSON,
  RatingJSON,
  ShortTextJSON,
  SingleChoiceJSON,
  StarJSON,
} from "../IncludeFile/AddJSON";
import AdvanceSection from "./AdvanceSection";
import OptionSection from "./OptionSection";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { AuthContext } from "../../PopUpForm/AppStateProvider ";
import { loadLoginData } from "../../apiCalls/DiagramsAPI";

const RecursiveTreeView = () => {
  let APIProvider = window.APIProvider;

  const [searchQuery, setSearchQuery] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [renderedControls, setRenderedControls] = useState([]);
  const [selectedControl, setSelectedControl] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuStyles, setContextMenuStyles] = useState("");
  const [customStyles, setCustomStyles] = useState({});
  const [editedName, setEditedName] = useState(" ");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [editedData, setEditedData] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const numberOfRadioInputs = 1;
  const numberOfCheckbox = 1;
  const [inputValue, setInputValue] = useState("");
  const [inputValueRadio, setInputValueRadio] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [showmenu, setShowmenu] = useState(false);
  const [showtab, setShowtab] = useState(false);
  const [showtabdsk, setShowdsktab] = useState(false);
  const [showmenudsk, setShowdskmenu] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const checkboxPosition = 1;
  // const [checkboxPosition, setCheckboxPosition] = useState(1);
  const [isSwitched, setIsSwitched] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedOptionControl, setSelectedOptionControl] = useState("");
  const [tabSelect, settabSelect] = React.useState("Text");
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedOptioncheck, setSelectedOptioncheck] = useState("false");
  const [selectedPositioncheck, setSelectedPositioncheck] = useState("right");
  const selectedOptionmedia = 1;
  // const [selectedOptionmedia, setSelectedOptionmedia] = useState(1);
  const [selectedOptionSwitchcheck, setSelectedOptionSwitchcheck] =
    useState("false");
  const [fieldNames, setFieldNames] = useState([]);
  const [fieldNamestable, setFieldNamestable] = useState([]);
  const [displayClicked, setDisplayClicked] = useState(false);
  const [IconsClicked, setIconClicked] = useState(false);
  const [DataClicked, setDataClicked] = useState(false);
  const [ContainerIcon, setContainerIcon] = useState(false);
  const [InputClicked, setInputClicked] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(true);
  const headerCount = 4;
  const cellCount = 4;
  const [tableHeader, setTableHeader] = useState("TableHeader");
  const [sourceData, setSourceData] = useState("");
  const [defaultStyle, setDefaultStyle] = useState("");
  const [inputType, setInputType] = useState("Date");
  const [inputText, setInputText] = useState("");
  const [imageText, setImageText] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [insertionPoint, setInsertionPoint] = useState(null);
  const [isRequiredFullName, setIsRequiredFullName] = useState("");
  const [isRequiredAddress, setIsRequiredAddress] = useState("");
  const [fileName, setFileName] = useState(null);
  const [editedDataFirstNameTextbox, setEditedDataFirstNameTextbox] =
    useState("");
  const [editedDataLastNameTextbox, setEditedDataLastNameTextbox] =
    useState("");
  const [bgColor, setBgColor] = useState("#ffffff");
  const Navigate = useNavigate();
  const [tabValue, setTabValue] = useState("1");
  const inputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [tableProp, settableProp] = useState("");
  const [typeofcontrol, setTypeOFControl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [CheckboxCheckedStreet1, setCheckboxCheckedStreet1] = useState("");
  const [CheckboxCheckedStreet2, setCheckboxCheckedStreet2] = useState("");
  const [CheckboxCheckedCity, setCheckboxCheckedCity] = useState("");
  const [CheckboxCheckedState, setCheckboxCheckedState] = useState("");
  const [CheckboxCheckedPostal, setCheckboxCheckedPostal] = useState("");
  const [CheckboxCheckedCountry, setCheckboxCheckedCountry] = useState("");
  const [CheckboxCheckedPrefix, setCheckboxCheckedPrefix] = useState(false);
  const [inputColor, setInputColor] = useState("#ffffff"); // Default color
  const [initialInputColorSet, setInitialInputColorSet] = useState(false);
  const [fontColor, setFontColor] = useState("#000000");
  const [initialColorSet, setInitialColorSet] = useState(false);
  const [FontSize, setFontSize] = useState(12);
  const [initialFontSizeSet, setInitialFontSizeSet] = useState(false);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [showTheme, setShowTheme] = useState(true);
  const [themeArray, setThemeArray] = useState([]);
  const [themeSource, setThemeSource] = useState([]);
  const [themehtmlouput, setThemehtmlouput] = useState([]);
  const { arId } = useParams();
  const contentRef = useRef();

  const [currentTable, setCurrentTable] = useState("");

  const rightsidetabs = [
    { label: "General", value: "1" },

    { label: "Style", value: "2" },

    // ...(selectedItem?.controltype !== ""
    //   ? [{ label: "Style", value: "2" }]
    //   : []),

    ...([
      "HeaderContainer",
      "Container",
      "FooterContainer",
      "Label",
      "Date",
      "RadioContainer",
      "Image",
      "Iframe",
      "Divider",
      "Header",
      "SubHeader",
      "List",
      "Table",
      "TableLabel",
      "Row",
      "Button",
      "TextBox",
      "TextArea",
      "Checkbox",
      "Dropdown",
      "Radio",
      "Switch",
      "FullName",
      "Email",
      "Heading",
      "LongText",
      "PhoneNumber",
      "ShortText",
    ].includes(selectedItem?.controltype) ||
    selectedItem?.styleType === "Icon" ||
    selectedItem?.type === "Root"
      ? []
      : [{ label: "Options", value: "3" }]),
    ...([
      "HeaderContainer",
      "Container",
      "FooterContainer",
      "DateBlock",
      "Label",
      "Date",
      "RadioContainer",
      "Image",
      "Iframe",
      "Divider",
      "Header",
      "SubHeader",
      "List",
      "Table",
      "TableLabel",
      "Row",
      "Button",
      "TextBox",
      "TextArea",
      "Checkbox",
      "DropDown",
      "Radio",
      "Switch",
      "DropDown",
      "RatingBlock",
    ].includes(selectedItem?.controltype) ||
    selectedItem?.styleType === "Icon" ||
    selectedItem?.type === "Root"
      ? []
      : [{ label: "Advance", value: "4" }]),
  ];

  const authData = loadLoginData();

  const accessKey = authData?.accessKey || "";

  const selectedTab = rightsidetabs.find((tab) => tab.value === tabValue);
  const rightsideStyletab = selectedTab ? selectedTab.value : null;

  const findDatablockChildControlIds = (controls) => {
    const ids = [];

    // Loop through each control at the current level
    for (const control of controls) {
      // Check if the current control's tableProp is "ChildBlock"
      if (control.tableProp === "ChildBlock") {
        ids.push(control.id); // Collect the ID if tableProp is "ChildBlock"
      }

      // If the control has nested controls, check them recursively
      if (control.control && control.control.length > 0) {
        ids.push(...findDatablockChildControlIds(control.control)); // Recursively check child controls
      }
    }

    return ids;
  };

  // Ensure treeData and treeData[0] are properly defined
  const datablockChildControlIds =
    treeData && treeData[0] && treeData[0].Source
      ? findDatablockChildControlIds(treeData[0].Source)
      : null;

  console.log(
    "Child Control IDs under 'Datablocks':",
    datablockChildControlIds
  );

  const findChildControlsByOption = (controls, optionValue) => {
    const result = [];

    // Loop through each control at the current level
    for (const control of controls) {
      // Check if the current control's option matches the specified value
      if (control.option === optionValue) {
        result.push(control); // Collect the entire control object if option matches
      }

      // If the control has nested controls, check them recursively
      if (control.control && control.control.length > 0) {
        result.push(...findChildControlsByOption(control.control, optionValue));
      }
    }

    return result;
  };

  // Find and log child controls for the option value "Template1HeaderContainer"
  const childControls =
    treeData && treeData[0] && treeData[0].Source
      ? findChildControlsByOption(
          treeData[0].Source,
          "Template1HeaderContainer"
        )
      : null;

  console.log(
    "Child Controls with option 'Template1HeaderContainer':",
    childControls
  );

  useEffect(() => {
    if (selectedItem) {
      setTypeOFControl(selectedItem.type);
    }
  }, [selectedItem]);

  useEffect(() => {
    setSelectedControlType(typeofcontrol);
  }, [typeofcontrol]);

  useEffect(() => {
    if (
      controlsVisible &&
      datablockChildControlIds &&
      !datablockChildControlIds.includes(selectedItemID)
    ) {
      setIconsforFocus(selectedItemID);
    }
  }, [customStyles, controlsVisible, selectedItemID, datablockChildControlIds]);

  const [selectedControlType, setSelectedControlType] = useState(typeofcontrol);

  const [readArrayStyles, setReadArrayStyles] = useState([]);

  const handleControlTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedControlType(selectedValue);

    const selectedItem = Controls.flatMap((category) => category.items).find(
      (item) => item.controltype === selectedValue
    );

    setDefaultStyle(readArrayStyles);

    const defaultStyles = Array.isArray(defaultStyle)
      ? defaultStyle.find((style) => style.styleType === selectedItem.styleType)
      : null;

    const defaultArray = defaultStyles.style;

    const mergedStyles = {
      ...defaultArray,
    };

    setCustomStyles(mergedStyles);

    setEditedName(selectedValue);

    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      controltype: selectedValue,
      type: selectedValue,
      styleType: selectedValue,

      htmlTag: selectedItem.htmlTag,
      styles: JSON.stringify({ ...mergedStyles }, null, 2),
      name: selectedValue,
      controlName: selectedValue,
      controlChange: selectedValue,
    }));

    const updatedTreeDataControlType = updateTreeDataControlType(
      treeData,
      selectedItemID,
      selectedValue,
      selectedItem.htmlTag,
      JSON.stringify({ ...mergedStyles }, null, 2),
      selectedControlType,
      selectedItem
    );
    setTreeData(updatedTreeDataControlType);
  };

  const updateTreeDataControlType = (
    treeData,
    selectedItemID,
    selectedValue,
    htmlTag,
    styles,
    name,
    selectedControlType
  ) => {
    return treeData.map((item) => {
      const updatedSourceControlType = item.Source.map((source) => ({
        ...source,
        control: updateControlsControlType(
          source.control,
          selectedItemID,
          selectedValue,
          htmlTag,
          styles,
          name,
          selectedControlType
        ),
      }));
      return { ...item, Source: updatedSourceControlType };
    });
  };

  const updateControlsControlType = (
    controls,
    selectedItemID,
    selectedValue,
    htmlTag,
    styles,
    name,
    selectedControlType,
    selectedItem
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        let dataValue = selectedValue;
        if (selectedValue === "Checkbox") {
          dataValue = "data";
        }
        return {
          ...c,
          controltype: selectedValue,
          type: selectedValue,
          styleType: selectedValue,

          htmlTag: htmlTag,
          styles: styles,
          name: selectedValue,
          controlName: selectedValue,
          controlChange: selectedValue,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsControlType(
            c.control,
            selectedItemID,
            selectedValue,
            htmlTag,
            styles,
            name,
            selectedControlType,
            selectedItem
          ),
        };
      }
      return c;
    });
  };

  const handleSwitchChange = (
    newValue,
    switchId,
    selectedOptionSwitchcheck
  ) => {
    const updateTreeDataSwitch1 = updateTreeDataSwitch(
      treeData,
      switchId,
      newValue,
      selectedOptionSwitchcheck
    );
    setTreeData(updateTreeDataSwitch1);
    setSelectedOptionSwitchcheck(newValue ? "true" : "false");
  };

  const updateTreeDataSwitch = (
    treeData,
    switchId,
    newValue,
    selectedOptionSwitchcheck
  ) => {
    return treeData.map((item) => {
      const updatedSourceSwitch = item.Source.map((source) => ({
        ...source,
        control: updateControls1Switch(
          source.control,
          switchId,
          newValue,
          selectedOptionSwitchcheck
        ),
      }));
      return { ...item, Source: updatedSourceSwitch };
    });
  };

  const updateControls1Switch = (
    controls,
    switchId,
    newValue,
    selectedOptionSwitchcheck
  ) => {
    return controls.map((c) => {
      if (c.id === switchId) {
        return {
          ...c,
          switchcheck: newValue,
          switchoption: selectedOptionSwitchcheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls1Switch(
            c.control,
            switchId,
            newValue,
            selectedOptionSwitchcheck
          ),
        };
      }
      return c;
    });
  };

  const handleSelectChange = (e, optionId) => {
    const selectedValue = e.target.value;
    const updateTreeDataSelect = updateTreeData1Select(
      treeData,
      optionId,
      selectedValue
    );

    setTreeData(updateTreeDataSelect);
  };

  const updateTreeData1Select = (
    treeData,
    optionId,
    selectedValue,
    selectedOptionControl
  ) => {
    return treeData.map((item) => {
      const updatedSourceSelect = item.Source.map((source) => ({
        ...source,
        control: updateControls1Select(
          source.control,
          optionId,
          selectedValue,
          selectedOptionControl
        ),
      }));
      return { ...item, Source: updatedSourceSelect };
    });
  };

  const updateControls1Select = (
    controls,
    optionId,
    selectedValue,
    selectedOptionControl
  ) => {
    return controls.map((c) => {
      if (c.id === optionId) {
        const dataArr = c.data.split(",");

        const newDataArr = [
          selectedValue,
          ...dataArr.filter((item) => item !== selectedValue),
        ];

        return {
          ...c,
          data: newDataArr.join(","),
        };
      }
      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls1Select(
            c.control,
            optionId,
            selectedValue,
            selectedOptionControl
          ),
        };
      }
      return c;
    });
  };

  const handleCheckboxPositonChange = (newValue, checkboxId) => {
    const updatedTreeData = updateTreeDataPosition(
      treeData,
      checkboxId,
      newValue,
      newValue ? "right" : "left"
    );
    setTreeData(updatedTreeData);
    setSelectedPositioncheck(newValue ? "right" : "left");
  };

  const updateTreeDataPosition = (
    treeData,
    checkboxId,
    newValue,
    selectedPositioncheck
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsPosition(
          source.control,
          checkboxId,
          newValue,
          selectedPositioncheck
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsPosition = (
    controls,
    checkboxId,
    newValue,
    selectedPositioncheck
  ) => {
    return controls.map((c) => {
      if (c.id === checkboxId) {
        return {
          ...c,
          media: newValue,
          checkposition: selectedPositioncheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsPosition(
            c.control,
            checkboxId,
            newValue,
            selectedPositioncheck
          ),
        };
      }
      return c;
    });
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (event, imageID) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = document.createElement("img");

    img.onload = () => {
      const maxWidth = 200;
      const maxHeight = 200;

      let width = img.width;
      let height = img.height;

      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;

        if (width > height) {
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();

          reader.onload = () => {
            const imageUrl = reader.result;

            const updateTreeData = updateTreeDataImage(
              treeData,
              imageID,
              imageUrl
            );
            setTreeData(updateTreeData);
          };

          reader.readAsDataURL(blob);
        },
        "image/jpeg",
        0.1
      );
    };

    img.src = URL.createObjectURL(file);
  };

  const updateTreeDataImage = (treeData, imageID, imageUrl) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsImage(source.control, imageID, imageUrl),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsImage = (controls, imageID, imageUrl) => {
    return controls.map((c) => {
      if (c.id === imageID) {
        return {
          ...c,
          media: imageUrl,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsImage(c.control, imageID, imageUrl),
        };
      }
      return c;
    });
  };

  const fixedIframeUrl = "https://aicloud.agiline.com";

  const [url, setURl] = useState(fixedIframeUrl);

  const handleIFrameClick = () => {
    const iframeUrl = inputRef.current.value;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (urlRegex.test(iframeUrl)) {
      const updateTreeData = updateTreeDataIFrame(
        treeData,
        selectedItem.id,
        iframeUrl
      );
      setTreeData(updateTreeData);
    } else {
      toast.error("Please enter a valid URL");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleIFrameClick();
    }
  };

  const handleIFrameChange = async (event, iframeID) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const iframeUrl = reader.result;

      const updateTreeData = updateTreeDataIFrame(
        treeData,
        iframeID,
        iframeUrl
      );
      setTreeData(updateTreeData);
    };

    reader.readAsDataURL(file);
  };

  const updateTreeDataIFrame = (treeData, iframeID, iframeUrl) => {
    const updatedTreeData = treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsIFrame(source.control, iframeID, iframeUrl),
      }));
      return { ...item, Source: updatedSource };
    });
    return updatedTreeData;
  };

  const updateControlsIFrame = (controls, iframeID, iframeUrl) => {
    return controls.map((c) => {
      if (c.id === iframeID) {
        return {
          ...c,
          data: iframeUrl,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsIFrame(c.control, iframeID, iframeUrl),
        };
      }
      return c;
    });
  };

  const [selectedValue, setSelectedValue] = useState("");

  const [RadioPosition, setRadioPosition] = useState(1);

  const handleRadioPositionChange = (position, uniqueId) => {
    const newPosition = parseInt(position);
    const updateTreeData = updateTreeDataRadioPosition(
      treeData,
      uniqueId,
      newPosition
    );
    setTreeData(updateTreeData);
  };

  const updateTreeDataRadioPosition = (treeData, uniqueId, newPosition) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsRadioPosition(
          source.control,
          uniqueId,
          newPosition
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsRadioPosition = (controls, uniqueId, newPosition) => {
    return controls.map((c) => {
      if (c.id === uniqueId) {
        return {
          ...c,
          media: newPosition,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsPosition(c.control, uniqueId, newPosition),
        };
      }
      return c;
    });
  };

  const updateControlCheck = (controls, selectedItem, uniqueKey) => {
    return controls.map((c) => {
      if (c.uniqueKey && c.uniqueKey.includes(selectedItem)) {
        const updatedCheck = {
          ...c.check,
          [selectedItem]: !c.check[selectedItem],
        };

        return {
          ...c,
          check: updatedCheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlCheck(c.control, selectedItem, uniqueKey),
        };
      }
      return c;
    });
  };

  const handleRadioChangeData = (e, selectedItem, uniqueKey) => {
    setSelectedValue(selectedItem);

    setTreeData((prevTreeData) => {
      const updatedTreeData = prevTreeData.map((item) => ({
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlCheckData(
            source.control,
            selectedItem,
            uniqueKey
          ),
        })),
      }));

      return updatedTreeData;
    });
  };

  const updateControlCheckData = (controls, selectedItem, uniqueKey) => {
    return controls.map((c) => {
      if (c.uniqueKey && c.uniqueKey.includes(selectedItem)) {
        const updatedCheckData = {
          ...c.data,
          [selectedItem]: !c.data[selectedItem],
        };

        return {
          ...c,
          data: updatedCheckData,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlCheckData(c.control, selectedItem, uniqueKey),
        };
      }
      return c;
    });
  };

  const handleCheckboxChange = (newValue, checkboxId, selectedOptioncheck) => {
    const updatedTreeData = updateTreeData1(
      treeData,
      checkboxId,
      newValue,
      selectedOptioncheck
    );
    setTreeData(updatedTreeData);
    setSelectedOptioncheck(newValue ? "true" : "false");
  };

  const updateTreeData1 = (
    treeData,
    checkboxId,
    newValue,
    selectedOptioncheck
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControls1(
          source.control,
          checkboxId,
          newValue,
          selectedOptioncheck
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControls1 = (
    controls,
    checkboxId,
    newValue,
    selectedOptioncheck
  ) => {
    return controls.map((c) => {
      if (c.id === checkboxId) {
        return {
          ...c,
          check: newValue,
          option: selectedOptioncheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls1(
            c.control,
            checkboxId,
            newValue,
            selectedOptioncheck
          ),
        };
      }
      return c;
    });
  };

  const handleRadioChange = (newValue, radioId, selectedOptioncheck) => {
    const updatedTreeData = updateTreeDataRadio1(
      treeData,
      radioId,
      newValue,
      selectedOptioncheck
    );
    setSelectedOptioncheck(newValue ? "true" : "false");
    setTreeData(updatedTreeData);
  };

  const updateTreeDataRadio1 = (
    treeData,
    radioId,
    newValue,
    selectedOptioncheck
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsRadio1(
          source.control,
          radioId,
          newValue,
          selectedOptioncheck
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsRadio1 = (
    controls,
    radioId,
    newValue,
    selectedOptioncheck
  ) => {
    let parentid = null;

    controls.forEach((c) => {
      if (c.id === radioId) {
        parentid = c.parentid;
      }
    });

    return controls.map((c) => {
      if (c.id === radioId) {
        return {
          ...c,
          check: newValue,
          option: selectedOptioncheck,
        };
      }
      if (c.parentid === parentid && c.controltype === "Radio") {
        return {
          ...c,
          check: false,
          option: selectedOptioncheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsRadio1(
            c.control,
            radioId,
            newValue,
            selectedOptioncheck
          ),
        };
      }

      return c;
    });
  };
  const handleChange = (event) => {
    const newTab = event.target.value;
    settabSelect(newTab);
    const updatedTreeData = updateTreeDatanewTab(
      treeData,
      selectedItemID,
      newTab
    );

    setTreeData(updatedTreeData);
  };

  const updateTreeDatanewTab = (treeData, selectedItemID, newTab) => {
    return treeData.map((item) => {
      const updatedSourcenewTab = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewTab(source.control, selectedItemID, newTab),
      }));

      return { ...item, Source: updatedSourcenewTab };
    });
  };

  const updateControlsnewTab = (controls, selectedItemID, newTab) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        return {
          ...c,
          tab: newTab,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewTab(c.control, selectedItemID, newTab),
        };
      }
      return c;
    });
  };

  const [Radios, setRadios] = useState([]);

  const handleInputChangeRadio = (e) => {
    let value = parseFloat(e.target.value);
    value = Math.min(Math.max(value, 1), 100);
    setInputValueRadio(value);
  };

  const handleAddControlRadio = () => {
    const numberToAdd = parseFloat(inputValueRadio);

    const newRadio = Array.from({ length: numberToAdd }, (_, index) => ({
      key: Radios.length + index + 1,
      value: Radios.length + index + 1,
    }));

    setRadios((prevRadios) => [...prevRadios, ...newRadio]);
    setInputValueRadio("");
    handleAddControl("Radio", {}, newRadio);
  };

  const handleInputChange = (e) => {
    let value = parseFloat(e.target.value);
    value = Math.min(Math.max(value, 1), 100);
    setInputValue(value);
  };

  const handleAddControlCheckbox = (
    checkValue,
    selectedCheckboxId,
    checkboxPosition
  ) => {
    let numberToAdd = parseFloat(inputValue);

    const newCheckboxes = Array.from({ length: numberToAdd }, (_, index) => ({
      key: checkboxes.length + index + 1,
      value: checkboxes.length + index + 1,
    }));

    setCheckboxes((prevCheckboxes) => [...prevCheckboxes, ...newCheckboxes]);
    setInputValue("");
    handleAddControl(
      "Checkbox",
      { check: checkValue },
      newCheckboxes,
      selectedCheckboxId
    );
  };

  const handleEditChange = (event) => {
    let newValue = event.target.value;

    if (newValue === "xx") {
      newValue = "";
    }

    if (/^"(.*)"$/g.test(newValue)) {
      newValue = newValue.replace(/^"(.*)"$/, "'$1'");
    }

    setEditedData(newValue);
    setSelectedOption(newValue);
    const updatedTreeData = updateTreeDatanewValue(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeData);
  };

  const updateTreeDatanewValue = (treeData, selectedItemID, newValue) => {
    return treeData.map((item) => {
      const updatedSourcenewValue = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewValue(
          source.control,
          selectedItemID,
          newValue
        ),
      }));
      return { ...item, Source: updatedSourcenewValue };
    });
  };

  const updateControlsnewValue = (
    controls,
    selectedItemID,
    newValue,
    selectedItemType
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        if (selectedItem.type === "Date") {
          return {
            ...c,
            inputText: newValue,
          };
        } else if (
          selectedItem.type === "Image" ||
          selectedItem.type === "Switch" ||
          selectedItem.type === "Iframe"
        ) {
          return {
            ...c,
            imageText: newValue,
          };
        } else if (selectedItem.type === "Checkbox") {
          return {
            ...c,
            data: newValue,
          };
        } else {
          return {
            ...c,
            data: newValue,
          };
        }
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewValue(
            c.control,
            selectedItemID,
            newValue,
            selectedItemType
          ),
        };
      }
      return c;
    });
  };

  const handleEditChangeforTable = (event) => {
    let newValue = event.target.value;

    if (/^"(.*)"$/g.test(newValue)) {
      newValue = newValue.replace(/^"(.*)"$/, "'$1'");
    }

    setEditedData(newValue);
    setSelectedOption(newValue);
    const updatedTreeDataforTable = updateTreeDatanewValueforTable(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeDataforTable);
  };

  const updateTreeDatanewValueforTable = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      const updatedSourcenewValueforTable = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewValueforTable(
          source.control,
          selectedItemID,
          newValue
        ),
      }));
      return { ...item, Source: updatedSourcenewValueforTable };
    });
  };

  function checkOptionMatch(control, option, newValue) {
    if (control.tableHeader === option) {
      control.data = newValue;
    }

    if (control.control && Array.isArray(control.control)) {
      control.control.forEach((childControl) =>
        checkOptionMatch(childControl, option, newValue)
      );
    }
  }

  const updateControlsnewValueforTable = (
    controls,
    selectedItemID,
    newValue,
    selectedItemType
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        let updatedValue = newValue;
        if (c.htmlTag === "td") {
          updatedValue = `{${newValue}}`;
          setEditedData(updatedValue);
          setSelectedOption(updatedValue);
          treeData.forEach((root) => {
            root.Source.forEach((source) => {
              source.control.forEach((control) => {
                checkOptionMatch(control, c.option, newValue);
              });
            });
          });
        }
        if (selectedItemType === "Date") {
          return {
            ...c,
            inputText: newValue,
          };
        } else if (
          selectedItemType === "Image" ||
          selectedItemType === "Switch" ||
          selectedItemType === "Iframe"
        ) {
          return {
            ...c,
            imageText: newValue,
          };
        } else if (selectedItemType === "Checkbox") {
          return {
            ...c,
            data: newValue,
          };
        } else {
          return {
            ...c,
            data: updatedValue,
          };
        }
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewValueforTable(
            c.control,
            selectedItemID,
            newValue,
            selectedItemType
          ),
        };
      }
      return c;
    });
  };

  const handlePopupClick = () => {
    setOpen(true);
    setPopupVisible(true);
  };

  const handleAgree = () => {
    let isValid = true;
    let errorMessage = null;
    let regex = null;

    switch (selectedItem.type) {
      case "TextBox":
        switch (tabSelect) {
          case "Phone Number":
            regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            errorMessage = "Invalid phone number";
            break;
          case "Email":
            regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            errorMessage = "Invalid email address";
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    if (regex) {
      isValid = regex.test(editedData);
    }

    if (isValid) {
      const updatedTreeData = updateTreeData(
        treeData,
        selectedItemID,
        editedData
      );

      setTreeData(updatedTreeData);
      handleClose();
    } else {
      toast.error(errorMessage);
    }
  };

  const updateTreeData = (
    treeData,
    selectedItemID,
    editedData,
    selectedOption
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControls(
          source.control,
          selectedItemID,
          editedData,
          selectedOption
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControls = (
    controls,
    selectedItemID,
    editedData,
    selectedOption
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        return {
          ...c,
          data: editedData || selectedOption,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls(
            c.control,
            selectedItemID,
            editedData,
            selectedOption
          ),
        };
      }
      return c;
    });
  };

  const handleEditChangeName1 = (e) => {
    let newValue = e.target.value;

    if (/^"(.*)"$/g.test(newValue)) {
      newValue = newValue.replace(/^"(.*)"$/, "'$1'");
    }

    setEditedName(newValue);

    if (selectedItem) {
      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        name: newValue,
        tableHeader: newValue,
      }));
    }
  };

  const collectControlNames = (controls, names) => {
    controls.forEach((control) => {
      names.push(control.name);
      if (control.control && control.control.length > 0) {
        collectControlNames(control.control, names);
      }
    });
  };

  const controlNames = [];

  if (treeData.length > 0) {
    treeData.forEach((item) => {
      if (item.Source && item.Source.length > 0) {
        item.Source.forEach((source) => {
          if (source.control && source.control.length > 0) {
            collectControlNames(source.control, controlNames);
          }
        });
      }
    });
  }

  const handleRenameConfirm = () => {
    if (!selectedItem) {
      return;
    }

    const updatedTreeName = updateTreeName(
      treeData,
      selectedItemID,
      editedName
    );
    setTreeData(updatedTreeName);
    setIsEditing(!isEditing);

    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      name: editedName,
    }));
  };

  const updateTreeName = (treeData, selectedItemID, editedName) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((sourceItem) => {
          return updateControlsName(sourceItem, selectedItemID, editedName);
        }),
      };
    });
  };

  const updateControlsName = (controls, selectedItemID, editedName) => {
    if (controls.id === selectedItemID) {
      return {
        ...controls,
        name: editedName,
      };
    }

    if (controls.control) {
      return {
        ...controls,
        control: controls.control.map((controlItem) => {
          return updateControlsName(controlItem, selectedItemID, editedName);
        }),
      };
    }

    return controls;
  };

  const handleClose = () => {
    setOpen(false);
    setShowPopup(false);
    const prevFocusedElement = document.querySelector(".focusingClass");
    const settingWrapper = document.querySelector(".setting-icon-wrapper");
    const DeleteWrapper = document.querySelector(".delete-icon-wrapper");
    if (prevFocusedElement) {
      prevFocusedElement.classList.remove("focusingClass");
    }
    if (settingWrapper) {
      settingWrapper.remove();
      console.log("Setting wrapper removed.");
    } else {
      console.log("No setting wrapper found.");
    }
    if (DeleteWrapper) {
      DeleteWrapper.remove();
      console.log("Delete wrapper removed.");
    } else {
      console.log("No Delete wrapper found.");
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    setContainerIcon(true);
    setIconClicked(true);
    setDataClicked(true);
    setInputClicked(true);
    setDisplayClicked(true);
    if (window.innerWidth > 991) {
      setShowdskmenu(false);
      setShowmenu(false);
    }
    if (window.innerWidth < 992) {
      setShowmenu(true);
      setShowdskmenu(false);
    }
  };

  const filterTreeData = (data, query) => {
    if (!query) {
      return data;
    }

    return data
      .map((item) => {
        const filteredChildren = item.control
          ? filterTreeData(item.control, query)
          : [];

        if (
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          filteredChildren.length > 0
        ) {
          return {
            ...item,
            control: filteredChildren,
          };
        }

        return null;
      })
      .filter(Boolean);
  };

  const updateIDsInTree = (treeData) => {
    treeData.forEach((root, index) => {
      root.Source[0].id = index.toString();
      updateChildIDs(root.Source[0].control, index.toString());
    });
  };

  const updateChildIDs = (children, parentID) => {
    children.forEach((child, index) => {
      if (child && child.id) {
        child.id = `${parentID}.${index}`;
        child.parentid = parentID;
        if (child.control && child.control.length > 0) {
          updateChildIDs(child.control, child.id);
        }
      }
    });
  };

  const findNodeById = (node, id) => {
    if (node.id === id) {
      return node;
    }
    if (node.control && node.control.length > 0) {
      for (const childNode of node.control) {
        const foundNode = findNodeById(childNode, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };

  const handleAddItem = () => {
    setTreeData([...treeData]);
  };

  // const handleControls = () => {
  //   setIsBoxVisible(!isBoxVisible);
  //   setOpenDialog(true);
  //   setShowdskmenu(false);
  // };
  const handleControls = () => {
    if (selectedNode.title === "Datablocks") {

          const prevIcons = document.querySelectorAll(".icon-container");
    prevIcons.forEach((icon) => icon.remove());

      // Remove the 'focusingClass' from any previously focused element
      const prevFocusedElement = document.querySelector(".focusingClass");
      if (prevFocusedElement) {
        prevFocusedElement.classList.remove("focusingClass");
      }

      // Get the first item from treeData
      const firstItem = treeData[0]?.Source[0];
      const firstItemElement = document.getElementById(firstItem?.id);

      // Add 'focusingClass' to the new first item element
      if (firstItemElement) {
        firstItemElement.classList.add("focusingClass");
      }

      // Update states with the new first item
      setSelectedNode(firstItem);
      setSelectedItem(firstItem);
      setSelectedControl(firstItem);
      setEditedName(firstItem?.name);
      setSelectedItemID(firstItem?.id);
    }

    // Toggle visibility and open dialog
    setIsBoxVisible(!isBoxVisible);
    setOpenDialog(true);
    setShowdskmenu(false);
  };

  const paintIconClick = () => {
    const prevFocusedElement = document.querySelector(".focusingClass");
    if (prevFocusedElement) {
      prevFocusedElement.classList.remove("focusingClass");
      const prevId = prevFocusedElement.id;
      const prevIconContainer = document.getElementById(
        `iconContainer-${prevId}`
      );
      if (prevIconContainer) {
        prevIconContainer.remove();
      }
    }
    setShowdsktab(false);
    setOpenpaint(false);
    setOpenpaint(true);
    setShowmenu(true);
    setShowdskmenu(true);
  };
  
  const handleMenuButtonClick = () => {
    setShowmenu(!showmenu);
    setShowdskmenu(false);
    setOpenpaint(false);
  };

  const onClickforFocus = (e) => {
    // If the clicked element is in datablockChildControlIds, focus on its parent with title "Datablocks"
    if (!datablockChildControlIds.includes(e.target.id)) {
      e.preventDefault();

      const prevFocusedElement = document.querySelector(".focusingClass");
      const element = document.getElementById(e.target.id);

      if (element && element.hasAttribute("styleid")) {
        const styleId = element.getAttribute("styleid");

        // Remove 'focusingClass' and icons from previously focused element
        if (prevFocusedElement) {
          prevFocusedElement.classList.remove("focusingClass");

          const prevId = prevFocusedElement.id;
          const prevIconContainer = document.getElementById(
            `iconContainer-${prevId}`
          );
          if (prevIconContainer) {
            prevIconContainer.remove(); // Remove previous icons
          }
        }

        // Add 'focusingClass' to the clicked element
        element.classList.add("focusingClass");

        // Show icons only if the element has a valid styleid
        if (
          styleId &&
          controlsVisible &&
          !datablockChildControlIds.includes(e.target.id)
        ) {
          setIconsforFocus(e.target.id);
        }

        if (!datablockChildControlIds.includes(e.target.id)) {
          handleSelectItem(e.target.id);
          setSelectedNode(e.target.id);
        }

        const navElement = document.getElementById("nav_" + e.target.id);
        if (navElement) {
          navElement.click();
        }

        setCurrentTable(styleId);

        // If it's a double-click and the tab is not shown, show the tab
        if (e.detail === 2 && !showtab) {
          handleTabshwButtonClick();
        }
      }
    } else {
      // If the clicked element is in datablockChildControlIds, focus on the parent with title "Datablocks"
      const element = document.getElementById(e.target.id);
      let parentDatablocksElement = null;

      // Find the parent with title "Datablocks"
      if (element) {
        let parent = element.parentElement;
        while (parent) {
          if (parent.getAttribute("tableProp") === "MainBlock") {
            parentDatablocksElement = parent;
            break;
          }
          parent = parent.parentElement;
        }
      }
      if (parentDatablocksElement) {
        const prevFocusedElement = document.querySelector(".focusingClass");

        // Remove 'focusingClass' and icons from previously focused element
        if (prevFocusedElement) {
          prevFocusedElement.classList.remove("focusingClass");

          const prevId = prevFocusedElement.id;
          const prevIconContainer = document.getElementById(
            `iconContainer-${prevId}`
          );
          if (prevIconContainer) {
            prevIconContainer.remove(); // Remove previous icons
          }
        }

        // Add 'focusingClass' to the parent
        parentDatablocksElement.classList.add("focusingClass");

        // Show icons on the parent element
        const parentId = parentDatablocksElement.id;
        if (parentDatablocksElement.hasAttribute("styleid")) {
          const styleId = parentDatablocksElement.getAttribute("styleid");
          if (styleId && controlsVisible) {
            setIconsforFocus(parentId);
          }
        }

        handleSelectItem(parentDatablocksElement.id);
        setSelectedNode(parentDatablocksElement.id);

        const navElement = document.getElementById(
          "nav_" + parentDatablocksElement.id
        );
        if (navElement) {
          navElement.click();
        }

        setCurrentTable(parentDatablocksElement.getAttribute("styleid"));
      }
    }
  };

  //   const onClickforFocus = (e) => {
  //  if(!datablockChildControlIds.includes(e.target.id))   {

  //     e.preventDefault();

  //     const prevFocusedElement = document.querySelector(".focusingClass");
  //     const element = document.getElementById(e.target.id);

  //     if (element && element.hasAttribute("styleid")) {
  //       const styleId = element.getAttribute("styleid");

  //       // Remove 'focusingClass' and icons from previously focused element
  //       if (prevFocusedElement) {
  //         prevFocusedElement.classList.remove("focusingClass");

  //         const prevId = prevFocusedElement.id;
  //         const prevIconContainer = document.getElementById(
  //           `iconContainer-${prevId}`
  //         );
  //         if (prevIconContainer) {
  //           prevIconContainer.remove(); // Remove previous icons
  //         }
  //       }

  //       // Add 'focusingClass' to the clicked element
  //       element.classList.add("focusingClass");

  //       // Show icons only if the element has a valid styleid
  //       if (styleId && controlsVisible && !datablockChildControlIds.includes(e.target.id)) {
  //         setIconsforFocus(e.target.id);
  //       }
  //       if ( !datablockChildControlIds.includes(e.target.id)) {
  //       handleSelectItem(e.target.id);
  //       setSelectedNode(e.target.id);
  //       }

  //       const navElement = document.getElementById("nav_" + e.target.id);
  //       if (navElement) {
  //         navElement.click();
  //       }

  //       setCurrentTable(styleId);

  //       // If it's a double-click and the tab is not shown, show the tab
  //       if (e.detail === 2 && !showtab) {
  //         handleTabshwButtonClick();
  //       }
  //     }
  //   }
  //   };

  const handleTabshwButtonClick = (e) => {
    setShowtab((prevShowtab) => !prevShowtab);
    setShowdsktab(true);
    setOpenpaint(true);
  };

  // const paintIconClick = () => {
  //   setShowdsktab(false);
  //   setOpenpaint(false);
  //   setOpenpaint(true);
  // };

  const handleMenushwDskButtonClick = () => {
    setShowdskmenu((prevShowdskmenu) => !prevShowdskmenu);
    setShowmenu(false);
  };
  const handleTabshwDskButtonClick = () => {
    setShowdsktab(true);
    setShowtab(true);
  };

  const calculateNextStyleId = () => {
    const deletedItemId = localStorage.getItem("deletedItemId");

    const deletedItemNumber = deletedItemId
      ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
      : -1;

    let maxStyleId = 0;

    const findMaxStyleId = (node) => {
      const styleIdRegex = /([a-zA-Z]+)(\d*)/;
      if (typeof node.styleid === "string") {
        const matches = node.styleid.match(styleIdRegex);
        if (matches && matches[1] === "agrfb") {
          const currentId = parseInt(matches[2]);
          if (!isNaN(currentId) && currentId > maxStyleId) {
            maxStyleId = currentId;
          }
        }
      }

      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          findMaxStyleId(childNode);
        }
      }
    };

    treeData[0].Source[0].control.forEach((rootNode) => {
      findMaxStyleId(rootNode);
    });

    const nextStyleId =
      deletedItemNumber > maxStyleId ? deletedItemNumber + 1 : maxStyleId + 1;

    const styleIdPrefix = showTheme ? "agrfb" : "agrfbTheme";

    return `${styleIdPrefix}${nextStyleId}`;
  };

  const findMaxNumber = (node, controlType) => {
    let maxNumber = 0;
    if (node.control) {
      for (const child of node.control) {
        if (child.type === controlType) {
          const number = parseInt(child.name.replace(controlType, ""));
          if (!isNaN(number) && number > maxNumber) {
            maxNumber = number;
          }
        }
        const childMaxNumber = findMaxNumber(child, controlType);
        if (childMaxNumber > maxNumber) {
          maxNumber = childMaxNumber;
        }
      }
    }
    return maxNumber;
  };

  const findMaxHeaderAndCellNumbers = (treeData) => {
    let maxHeaderNumber = 0;
    let maxCellNumber = 0;

    const traverse = (node) => {
      if (node.control) {
        for (const child of node.control) {
          if (child.htmlTag === "th") {
            const number = parseInt(child.tableHeader.replace("Header", ""));
            if (!isNaN(number) && number > maxHeaderNumber) {
              maxHeaderNumber = number;
            }
          } else if (child.controltype === "Row") {
            for (const cell of child.control) {
              if (cell.htmlTag === "td") {
                const number = parseInt(cell.tableHeader.replace("label", ""));
                if (!isNaN(number) && number > maxCellNumber) {
                  maxCellNumber = number;
                }
              }
            }
          }
          traverse(child);
        }
      }
    };

    traverse(treeData[0].Source[0]);
    return { maxHeaderNumber, maxCellNumber };
  };

  const addNewColumn = () => {
    const defaultlabel = defaultStyle.find(
      (style) => style.styleType === "TableLabel"
    );

    const defaultHeader = defaultStyle.find(
      (style) => style.styleType === "TableHeader"
    );

    const { maxHeaderNumber, maxCellNumber } =
      findMaxHeaderAndCellNumbers(treeData);

    const findTableNode = (node) => {
      if (node.type === "Table") {
        return node;
      } else if (node.control && Array.isArray(node.control)) {
        for (let i = 0; i < node.control.length; i++) {
          const result = findTableNode(node.control[i]);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    const tableNode = findTableNode(selectedNode);

    if (!tableNode) {
      return;
    }

    const theadNode = tableNode.control.find(
      (node) => node.controltype === "Header"
    );

    if (!theadNode) {
      return;
    }

    const newHeaderIndex = maxHeaderNumber + 1;
    const newlabelindex = maxCellNumber + 1;

    const styleid = calculateNextStyleId();

    const controlToAddfortablelabel = Controls.flatMap(
      (category) => category.items
    ).find((item) => item.styleType === "Label");

    const newHeader = {
      id: `${tableNode.id}.thead.${newHeaderIndex}`,
      name: `Header${newHeaderIndex}`,
      controlName: `Header${newHeaderIndex}`,
      tableHeader: `Header${newHeaderIndex}`,
      data: `Header${newHeaderIndex}`,
      type: "Table",
      parentid: `${tableNode.id}.thead`,
      htmlTag: "th",
      controltype: "Header",
      styles: JSON.stringify({ ...defaultHeader.style }, null, 2),
      styleid: `${styleid}.thead.${newHeaderIndex}`,
      styleType: "TableHeader",
      mediaURl: "table",
      check: false,
      switchcheck: false,
      title: "Display",
      value: "",
      media: "",
      option: false,
      switchoption: false,
      position: 1,
      checkposition: "left",
      tab: "Text",
      inputType: "Date",
      inputText: "",
      imageText: "",
      sourceData: "",
      tableProp: "tableProp",
      controlChange: `Header${newHeaderIndex}`,
    };

    theadNode.control.push(newHeader);

    tableNode.control
      .filter((node) => node.controltype === "Row")
      .forEach((row) => {
        const newCellID = `${row.id}.cell${newHeaderIndex}`;
        const newCell = {
          id: newCellID,
          name: `label${newlabelindex}`,
          controlName: `label${newlabelindex}`,
          tableHeader: `label${newlabelindex}`,
          data: `label${newlabelindex}`,
          type: "Table",
          parentid: `${row.id}`,
          htmlTag: "td",
          controltype: controlToAddfortablelabel.controltype,
          styles: JSON.stringify({ ...defaultlabel.style }, null, 2),
          styleid: `${styleid}.row_cell${newlabelindex}`,
          styleType: "TableLabel",
          mediaURl: controlToAddfortablelabel.mediaURl,
          check: false,
          switchcheck: false,
          title: "Display",
          value: "",
          media: "",
          option: `Header${newlabelindex}`,
          switchoption: false,
          position: 1,
          checkposition: "left",
          tab: "Text",
          inputType: "Date",
          inputText: "",
          imageText: "",
          sourceData: `Header`,
          tableProp: "tableProp",
          controlChange: `label${newlabelindex}`,
        };

        row.control.push(newCell);
      });

    setTreeData([...treeData]);
  };

  const findControlById = (id, controls) => {
    for (let control of controls) {
      if (control.id === id) {
        return control;
      }
      if (control.children) {
        const found = findControlById(id, control.children);
        if (found) return found;
      }
    }
    return null;
  };

  const handleMouseOver = (e, control) => {
    if (!isDragging) return;
    e.stopPropagation();

    const targetElement = e.target;
    const parentId = targetElement.getAttribute("parentid");

    const element = document.getElementById(control.id);

    const focusedNode = document.querySelector(".focusingClass");

    if (element) {
      if (focusedNode && focusedNode !== element) {
        focusedNode.classList.remove("focusingClass");
      }
      element.classList.add("focusingClass");
    }

    setInsertionPoint(control.id); // Set insertion point

    setSelectedItemID(control.id);
    setSelectedControl(control);
    setSelectedNode(control);
    setSelectedOptioncheck(control.check ? "true" : "false");
    setSelectedOptionSwitchcheck(control.switchcheck ? "true" : "false");
    setSelectedPositioncheck(
      control.checkposition || (control.media ? "left" : "right")
    );
    setEditedName(control.name || editedName);
    setEditedData(control.data || "");
    setSelectedOption(control.imageText || control.inputText || control.data);
    settabSelect(control.tab || "");
    setTableHeader(control.tableHeader ? "TableHeader" : "");
    setSourceData(control.sourceData ? "" : "");
    setSelectedControlType(
      control.controlChange || control.selectedControlType
    );
    setIsOptionSelected(!isOptionSelected);
    settableProp(control.tableProp);
  };

  const handleMouseOut = (e) => {
    if (!isDragging) return;
    e.stopPropagation();
    const focusedNode = document.querySelector(".focusingClass");

    if (focusedNode) {
      focusedNode.classList.remove("focusingClass");
    }

    setSelectedItemID(null);
    setSelectedControl(null);
    setSelectedNode(null);
  };

  const applyStylesRecursively = (control, defaultStyle, parentID = "") => {
    const defaultSty = defaultStyle.find(
      (style) => style.styleType === control.styleType
    );

    const newID = parentID
      ? `${parentID}.${control.id.split(".").pop()}`
      : control.id;

    let updatedStyles = { ...defaultSty?.style };

    if (control?.styleType === "Container") {
      updatedStyles.backgroundColor = bgColor; // Set background color to red
    }

    if (
      control.media === "Address" ||
      control.media === "AddressHeaderContriner"
    ) {
      updatedStyles.marginLeft = "0px";
    }

    if (
      control.media === "AddressInput" ||
      control.media === "ShortTextTextBox" ||
      control.media === "LongTextTextArea" ||
      control.media === "CheckBoxMainContainer" ||
      control.checkposition === "RadioMainContainer" ||
      control.media === "DropDownCont" ||
      control.media === "FirstLastName" ||
      control.media === "PhoneTextBox" ||
      control.media === "EmailTextBox" ||
      control.media === "DateField" ||
      control.media === "RatingInput" ||
      control.checkposition === "PrefixTextBox" ||
      control.checkposition === "MiddleNameTextBox"
    ) {
      updatedStyles.maxWidth = "100%";
    }

    if (
      control.media === "DropDownCont" ||
      control.media === "FirstLastName" ||
      control.media === "AddressInput" ||
      control.media === "ContainerStreet1" ||
      control.media === "ContainerStreet2" ||
      control.media === "CityState" ||
      control.media === "ContainerSec" ||
      control.media === "ContainerThird" ||
      control.media === "PostalCountry" ||
      control.media === "ContainerPostal" ||
      control.media === "ContainerCountry" ||
      control.media === "FullNameHeader" ||
      control.media === "CheckBoxMainContainer" ||
      control.media === "RadioMainContainer" ||
      control.media === "Rating" ||
      control.media === "RatingInput"
    ) {
      updatedStyles.marginLeft = "0px";
      updatedStyles.paddingBottom = "0px";
      updatedStyles.paddingTop = "0px";
      updatedStyles.paddingLeft = "0px";
      updatedStyles.paddingRight = "0px";
    }

    if (
      control.media === "ContainerSec" ||
      control.media === "ContainerPostal"
    ) {
      updatedStyles.marginRight = "10px";
    }
    if (control.media === "ContainerPostal") {
      updatedStyles.marginRight = "10px";
    }

    if (
      control.media === "ContainerMiddleName" ||
      control.media === "ContainerPrefix" ||
      control.media === "ContainerCountry"
    ) {
      updatedStyles.display = "none";
    }

    if (control.media === "HeadingContainer") {
      updatedStyles.flexDirection = "row";
    }

    if (control.media === "MainAddressHeader") {
      updatedStyles.maxWidth = "100%";
      updatedStyles.width = "100%";
    }

    if (
      control.checkposition === "AddressStreet1Textbox" ||
      control.checkposition === "AddressStreet2Textbox" ||
      control.checkposition === "CityTextbox" ||
      control.checkposition === "StateTextbox" ||
      control.checkposition === "PostalTextbox" ||
      control.checkposition === "CountryTextbox" ||
      control.checkposition === "FirstNameTextBox" ||
      control.checkposition === "LastNameTextBox" ||
      control.checkposition === "MiddleNameTextBox" ||
      control.checkposition === "PrefixTextBox"
    ) {
      updatedStyles.paddingLeft = "4px";
    }
    if (
      control.checkposition === "AddressStreet1" ||
      control.checkposition === "AddressStreet2" ||
      control.checkposition === "CityLabel" ||
      control.checkposition === "StateLabel" ||
      control.checkposition === "PostalLabel" ||
      control.checkposition === "CountryLabel" ||
      control.checkposition === "FirstName" ||
      control.checkposition === "LastName" ||
      control.checkposition === "MiddleNameLabel" ||
      control.checkposition === "PrefixLabel"
    ) {
      updatedStyles.marginLeft = "0px";
      updatedStyles.paddingLeft = "0px";
    }

    if (
      control.media === "Street1" ||
      control.media === "Street2" ||
      control.media === "City" ||
      control.media === "State" ||
      control.media === "Postal" ||
      control.media === "Country"
    ) {
      updatedStyles.marginTop = "2px";
      updatedStyles.marginBottom = "2px";
    }

    const updatedControl = {
      ...control,
      id: newID,
      styles: JSON.stringify(updatedStyles),
    };

    if (updatedControl.control && updatedControl.control.length > 0) {
      updatedControl.control = updatedControl.control.map(
        (child, childIndex) => {
          const childNewID = `${newID}.${childIndex + 1}`;
          return applyStylesRecursively(
            { ...child, id: childNewID },
            defaultStyle,
            newID
          );
        }
      );
    }

    return updatedControl;
  };

  const handleAddControl = (
    controlType,
    updatedStyles,
    newCheckboxes,
    generateNewKeys
  ) => {
    const defaultlabel = defaultStyle.find(
      (style) => style.styleType === "TableLabel"
    );

    const defaultHeader = defaultStyle.find(
      (style) => style.styleType === "TableHeader"
    );

    if (
      !treeData[0]?.Source[0]?.control ||
      !Array.isArray(treeData[0].Source[0].control)
    ) {
      console.error("Invalid treeData structure");
      toast.error("Invalid treeData structure");
      return;
    }

    if (selectedNode && selectedNode.styleType === "RadioContainer") {
      return;
    }

    const maxNumber = findMaxNumber(treeData[0].Source[0], controlType);
    const { maxHeaderNumber, maxCellNumber } =
      findMaxHeaderAndCellNumbers(treeData);
    const newName = `${controlType}${maxNumber + 1}`;
    const controlToAdd = Controls.flatMap((category) => category.items).find(
      (item) => item.controltype === controlType
    );

    const controlToAddfortablelabel = Controls.flatMap(
      (category) => category.items
    ).find((item) => item.styleType === "Label");

    if (controlToAdd) {
      setRenderedControls((prevControls) => [...prevControls, controlToAdd]);
    } else {
    }

    if (!controlToAdd) {
      console.log(`Control type ${controlType} not found in Controls data.`);
      return;
    }

    if (!selectedNode) {
      console.error("Control type is not selected.");
      toast.error("Control type is not selected");
      return;
    }

    if (selectedNode.styleType === "RadioContainer") {
      return;
    }

    const footerContainerIndex = treeData[0].Source[0].control.findIndex(
      (item) => item.type === "FooterContainer"
    );

    const headerContainerIndex = treeData[0].Source[0].control.findIndex(
      (item) => item.type === "HeaderContainer"
    );

    const getNextIDS = (treeData, controlType) => {
      let maxID = 0;

      treeData[0].Source[0].control.forEach((item) => {
        if (item.id && typeof item.id === "string") {
          const parts = item.id.split(".");
          const lastPart = parseInt(parts[parts.length - 1]);
          if (!isNaN(lastPart) && lastPart > maxID) {
            maxID = lastPart;
          }
        }
      });

      if (controlType === "HeaderContainer") {
        return 0;
      }
      return maxID + 1;
    };

    const customHtmlTag = controlToAdd.htmlTag;
    const styleid = calculateNextStyleId();

    setDefaultStyle(readArrayStyles);

    const defaultStyles = Array.isArray(defaultStyle)
      ? defaultStyle.find((style) => style.styleType === controlToAdd.styleType)
      : null;

    if (
      !defaultStyles ||
      !defaultStyles.style ||
      defaultStyles.style.length === 0
    ) {
      console.log(
        `Default styles for control type ${controlType} not found or empty.`
      );
      return;
    }

    const defaultRadioConatiner = defaultStyle.find(
      (style) => style.styleType === "RadioContainer"
    );

    const defaultCheckbox = defaultStyle.find(
      (style) => style.styleType === "Checkbox"
    );

    const defaultRadio = defaultStyle.find(
      (style) => style.styleType === "Radio"
    );

    const defaultArray = defaultStyles.style;

    const mergedStyles = {
      ...defaultArray,
      ...updatedStyles,
    };

    if (!selectedNode) {
      console.error("Control type is not selected.");
      toast.error("Please select a control type.");
      return;
    }

    const getValueForControlType = (type) => {
      switch (type) {
        case "Radio":
          return numberOfRadioInputs;
        default:
          return "";
      }
    };

    const getInputTextForControlType = (type) => {
      switch (type) {
        case "Date":
          return "";
        default:
          return "";
      }
    };

    let insertionIndex = -1;
    if (insertionPoint) {
      insertionIndex = treeData[0].Source[0].control.findIndex(
        (item) => item.id === insertionPoint
      );
    }

    if (insertionIndex === -1) {
      console.error("Insertion point not found.");
      insertionIndex = treeData[0].Source[0].control.length;
    }

    if (controlType === "HeaderContainer") {
      if (headerContainerIndex !== -1) {
        toast.info("HeaderContainer already exists.");
        return;
      }

      const newHeaderContainer = {
        id: "0.0H",
        name: newName,
        controlName: newName,
        tableHeader: "",
        data: controlToAdd.description,
        type: controlType,
        parentid: "0",
        styleid: styleid,
        htmlTag: customHtmlTag,
        styles: JSON.stringify({ ...mergedStyles }, null, 2),
        controltype: controlType,
        styleType: controlToAdd.styleType,
        mediaURl: controlToAdd.mediaURl,
        check: isChecked,
        switchcheck: isSwitched,
        title: Controls.find((category) =>
          category.items.some((item) => item.controltype === controlType)
        )?.title,
        value: getValueForControlType(controlType, controlToAdd.description),
        media: "",
        uniqueKey: generateNewKeys,
        option: selectedOptioncheck,
        position: selectedOptionmedia,
        switchoption: selectedOptionSwitchcheck,
        checkposition: selectedPositioncheck,
        tab: tabSelect,
        inputType: inputType,
        inputText: getInputTextForControlType(
          controlType,
          controlToAdd.description
        ),
        imageText: imageText,
        sourceData: "sourceData",
        controlChange: controlType,
        tableProp: "null",
        url: url,
        control: [],
      };

      console.log("newHeaderContainer",newHeaderContainer);
      

      if (
        treeData[0].Source[0].control.length > 0 &&
        treeData[0].Source[0].control[0].type !== "HeaderContainer"
      ) {
        treeData[0].Source[0].control.unshift(newHeaderContainer);
      } else {
        treeData[0].Source[0].control.push(newHeaderContainer);
      }

      setOpenDialog(false);
      setTreeData([...treeData]);
      return;
    }

    const getDataForControlType = (type, description) => {
      switch (type) {
        case "List":
          return description.join(",");
        case "Dropdown":
          return description.join(",");
        case "Date":
          return new Date().toISOString().slice(0, 10);
        case "Iframe":
          return fixedIframeUrl;
        default:
          return description;
      }
    };

    const getMediaForControlType = (type) => {
      switch (type) {
        case "Image":
          return selectedImage;
        case "Radio":
          return RadioPosition;
        default:
          return "";
      }
    };

    if (controlType === "FooterContainer") {
      if (footerContainerIndex !== -1) {
        toast.info("FooterContainer already exists.");
        return;
      }
      const newID = getNextIDS(treeData);
      const newNode = {
        id: `0.${newID}`,
        name: newName,
        controlName: newName,
        tableHeader: "",
        data: controlToAdd.description,
        type: controlType,
        htmlTag: customHtmlTag,
        controltype: controlType,
        styles: JSON.stringify({ ...mergedStyles }, null, 2),
        parentid: "0",
        styleid: styleid,
        styleType: controlToAdd.styleType,
        mediaURl: controlToAdd.mediaURl,
        check: isChecked,
        switchcheck: isSwitched,
        title: Controls.find((category) =>
          category.items.some((item) => item.controltype === controlType)
        )?.title,
        value: getValueForControlType(controlType, controlToAdd.description),
        media: getMediaForControlType(controlType, controlToAdd.description),
        uniqueKey: generateNewKeys,
        option: selectedOptioncheck,
        switchoption: selectedOptionSwitchcheck,
        position: selectedOptionmedia,
        checkposition: selectedPositioncheck,
        tab: tabSelect,
        inputType: inputType,
        inputText: getInputTextForControlType(
          controlType,
          controlToAdd.description
        ),
        imageText: imageText,
        sourceData: "sourceData",
        controlChange: controlType,
        tableProp: "null",
        url: url,
        control: [],
      };

      console.log("newNodefooter",newNode)

      treeData[0].Source[0].control.push(newNode);

      setOpenDialog(false);
      setTreeData([...treeData]);
      return;
    } else {
      const parentID = selectedNode.id;
      let maxChildIndex = 0;

      if (selectedNode.control && Array.isArray(selectedNode.control)) {
        selectedNode.control.forEach((child) => {
          if (child.id && typeof child.id === "string") {
            const childIndex = parseInt(child.id.split(".").pop(), 10);
            if (!isNaN(childIndex) && childIndex > maxChildIndex) {
              maxChildIndex = childIndex;
            }
          }
        });
      }

      const childID = `${parentID}.${maxChildIndex + 1}`;

      let newNode = {
        id: childID,
        name: newName,
        controlName: newName,
        tableHeader: "",
        data: getDataForControlType(controlType, controlToAdd.description),
        type: controlType,
        parentid: parentID,
        htmlTag: customHtmlTag,
        controltype: controlType,
        styles: JSON.stringify({ ...mergedStyles }, null, 2),
        styleid: styleid,
        styleType: controlToAdd.styleType,
        mediaURl: controlToAdd.mediaURl,
        check: isChecked,
        switchcheck: isSwitched,
        title: Controls.find((category) =>
          category.items.some((item) => item.controltype === controlType)
        )?.title,
        value: getValueForControlType(controlType, controlToAdd.description),
        media: getMediaForControlType(controlType, controlToAdd.description),
        uniqueKey: generateNewKeys,
        option: selectedOptioncheck,
        switchoption: selectedOptionSwitchcheck,
        position: selectedOptionmedia,
        checkposition: selectedPositioncheck,
        tab: tabSelect,
        inputType: inputType,
        inputText: getInputTextForControlType(
          controlType,
          controlToAdd.description
        ),
        imageText: imageText,
        sourceData: "sourceData",
        controlChange: controlType,
        tableProp: "null",
        url: url,
        control: [],
      };

      console.log("newNodech", newNode)

      const getParentNode = (root, node) => {
        if (!node || node.parentid === "0") {
          return root;
        }

        const findNode = (currentNode) => {
          if (currentNode.id === node.parentid) {
            return currentNode;
          }

          if (currentNode.control) {
            for (const childNode of currentNode.control) {
              const foundNode = findNode(childNode);
              if (foundNode) {
                return foundNode;
              }
            }
          }
          return null;
        };

        return findNode(root);
      };

      if (newCheckboxes) {
        newCheckboxes.forEach((checkbox, index) => {
          const styleid = calculateNextStyleId();
          const parentNode = getParentNode(treeData[0].Source[0], selectedNode);
          const childID = `${parentNode.id}.${parentNode.control.length + 1}`;

          const maxNumber = findMaxNumber(treeData[0].Source[0], controlType);
          const newName = `${controlType}${maxNumber + 1}`;

          if (!parentNode || !parentNode.id) {
            return;
          }

          let newNode = {
            id: childID,
            name: newName,
            controlName: newName,
            tableHeader: "",
            data: controlToAdd.description,
            type: controlType,
            parentid: parentNode.id,
            styleid: styleid,
            htmlTag: customHtmlTag,
            controltype: controlType,
            check: isChecked,
            switchcheck: isSwitched,
            styles: JSON.stringify({ ...mergedStyles }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: "sourceData",
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [],
          };

          parentNode.control.push(newNode);
        });

        if (footerContainerIndex !== -1) {
          const [removed] = treeData[0].Source[0].control.splice(
            footerContainerIndex,
            1
          );
          treeData[0].Source[0].control.push(removed);
        }

        setTreeData([...treeData]);
        setOpenDialog(false);
        return;
      }

      if (controlType === "Radio") {
        const parentID = selectedNode.id;

        if (selectedNode.controltype === "RadioContainer") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        if (selectedNode.controltype === "Radio") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        const childIDLayout =
          parentID + "." + (selectedNode.control.length + 1);
        const childIDContainer = childIDLayout + ".1";

        const newNameLayout = `${controlType}${maxNumber + 1}`;
        const newNameContainer = `${controlType}${maxNumber + 2}`;

        if (selectedNode.controltype === "RadioContainer") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        if (selectedNode.controltype === "Button") {
          toast.error("Cannot add a Radio inside Button.");
          return;
        }

        const newContainerLayout = {
          id: childIDLayout,
          name: "RadioContainer",
          controlName: newNameLayout,
          tableHeader: "",
          data: "",
          type: "Container",
          parentid: parentID,
          htmlTag: "div",
          controltype: "Container",
          styles: JSON.stringify({ ...defaultRadioConatiner.style }, null, 2),
          styleid: `agrf${maxNumber + 1}`,
          styleType: "RadioContainer",
          mediaURl: controlToAdd.mediaURl,
          title: "Containers",
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: "sourceData",
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [
            {
              id: childIDContainer,
              name: newNameContainer,
              controlName: newNameContainer,
              tableHeader: "",
              data: controlToAdd.description,
              type: "Radio",
              parentid: childIDLayout,
              htmlTag: customHtmlTag,
              controltype: controlType,
              styles: JSON.stringify({ ...defaultRadio.style }, null, 2),
              styleid: calculateNextStyleId(),
              styleType: controlToAdd.styleType,
              mediaURl: controlToAdd.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: "Input",
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              sourceData: "sourceData",
              controlChange: controlType,
              tableProp: "null",
              url: url,
              control: [],
            },
          ],
        };

        selectedNode.control.push(newContainerLayout);

        const itemToInsert = selectedNode.control.pop();
        selectedNode.control.splice(insertionIndex, 0, itemToInsert);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "Checkbox") {
        const parentID = selectedNode.id;
        const childIDLayout =
          parentID + "." + (selectedNode.control.length + 1);
        const childIDContainer = childIDLayout + ".1";

        const newNameLayout = `${controlType}${maxNumber + 1}`;
        const newNameContainer = `${controlType}${maxNumber + 2}`;

        if (selectedNode.controltype === "CheckboxContainer") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        if (selectedNode.controltype === "Checkbox") {
          toast.error("Cannot add a Checkbox inside another Checkbox.");
          return;
        }

        if (selectedNode.controltype === "Button") {
          toast.error("Cannot add a Checkbox inside Button.");
          return;
        }

        const newContainerLayout = {
          id: childIDLayout,
          name: "CheckboxContainer",
          controlName: newNameLayout,
          tableHeader: "",
          data: "",
          type: "Container",
          parentid: parentID,
          htmlTag: "div",
          controltype: "Container",
          styles: JSON.stringify({ ...defaultRadioConatiner.style }, null, 2),
          styleid: `agr${maxNumber + 1}`,
          styleType: "RadioContainer",
          mediaURl: controlToAdd.mediaURl,
          title: "Containers",
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: "sourceData",
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [
            {
              id: childIDContainer,
              name: newNameContainer,
              controlName: newNameContainer,
              tableHeader: "",
              data: controlToAdd.description,
              type: "Checkbox",
              parentid: childIDLayout,
              htmlTag: customHtmlTag,
              controltype: controlType,
              styles: JSON.stringify({ ...defaultCheckbox.style }, null, 2),
              styleid: calculateNextStyleId(),
              styleType: controlToAdd.styleType,
              mediaURl: controlToAdd.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              sourceData: "sourceData",
              controlChange: controlType,
              tableProp: "null",
              url: url,
              control: [],
            },
          ],
        };

        selectedNode.control.push(newContainerLayout);

        const itemToInsert = selectedNode.control.pop();
        selectedNode.control.splice(insertionIndex, 0, itemToInsert);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      const defaultTableHead = defaultStyle.find(
        (style) => style.styleType === "TableHead"
      );

      let globalCellCounter = 1;

      if (
        selectedNode.title === "Display" ||
        selectedNode.title === "Input" ||
        selectedNode.title === "Datablocks"
      ) {
        toast.error("Cannot add a control inside another control.");
        return;
      }

      if (controlType === "Table") {
        const parentID = selectedNode.id;
        const childTableIDLayout =
          parentID + "." + (selectedNode.control.length + 1);
        const tableID = getNextIDS(treeData, controlType);
        const styleidTable = calculateNextStyleId();

        if (selectedNode.controltype === "Table" && controlType === "Table") {
          toast.error("Cannot add a table inside another control.");
          return;
        }

        const rowCount = 1;

        const tableNode = {
          id: `${childTableIDLayout}`,
          name: `Table${tableID}`,
          controlName: `Table${tableID}`,
          tableHeader: "tableHead",
          data: controlToAdd.description,
          type: "Table",
          parentid: parentID,
          htmlTag: "table",
          controltype: controlType,
          styleid: styleidTable,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: "Header",
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: sourceData,
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [],
        };
        selectedNode.control.push(tableNode);
        const itemToInsert = selectedNode.control.pop();
        selectedNode.control.splice(insertionIndex, 0, itemToInsert);

        const theadNode = {
          id: `${childTableIDLayout}.${tableID}`,
          name: `Table${tableID}_head`,
          controlName: `Table${tableID}_head`,
          tableHeader: "",
          data: "",
          type: "Table",
          parentid: `${parentID}.${tableID}`,
          htmlTag: "thead",
          controltype: "Header",
          styleid: `${styleidTable}_head`,
          styles: JSON.stringify({ ...defaultTableHead.style }, null, 2),
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: "Header",
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: sourceData,
          controlChange: `Table${tableID}_head`,
          tableProp: "null",
          url: url,
          control: [],
        };

        tableNode.control.push(theadNode);

        for (let i = 1; i <= headerCount; i++) {
          const styleidHeader = `${styleidTable}_header_${i}`;
          theadNode.control.push({
            id: `${childTableIDLayout}.${tableID}.${i}`,
            name: `Header${maxHeaderNumber + i}`,
            controlName: `Header${maxHeaderNumber + i}`,
            tableHeader: `Header${maxHeaderNumber + i}`,
            data: `Header${maxHeaderNumber + i}`,
            type: "Table",
            parentid: `${parentID}.${tableID}.thead`,
            htmlTag: "th",
            controltype: "Header",
            styleid: styleidHeader,
            styleType: "TableHeader",
            styles: JSON.stringify({ ...defaultHeader.style }, null, 2),
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            controlChange: `Header${i}`,
            sourceData: sourceData,
            tableProp: "tableProp",
            url: url,
          });
        }

        for (let i = 1; i <= rowCount; i++) {
          const styleidRow = `${styleidTable}_row_${i}`;
          const row = {
            id: `${childTableIDLayout}.row${i}`,

            name: `Row${i}`,
            controlName: `Row${i}`,
            data: "",
            tableHeader: "",
            type: "Table",
            parentid: `${parentID}.${tableID}`,
            htmlTag: "tr",
            controltype: "Row",
            styleid: styleidRow,
            styles: JSON.stringify({ ...defaultTableHead.style }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: "Header",
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: sourceData,
            controlChange: "Row",
            tableProp: "null",
            url: url,
            control: [],
          };

          for (let j = 1; j <= cellCount; j++) {
            const styleidCell = `${styleidTable}_row${i}_cell${j}`;
            row.control.push({
              id: `${childTableIDLayout}.${tableID}.row${i}_cell${j}`,
              name: `label${maxCellNumber + j}`,
              controlName: `label${maxCellNumber + j}`,
              tableHeader: `label${maxCellNumber + j}`,
              data: `label${maxCellNumber + j}`,
              type: "Table",
              parentid: `${childTableIDLayout}.row${i}`,
              htmlTag: "td",
              controltype: controlToAddfortablelabel.controltype,
              styleid: styleidCell,
              styleType: "TableLabel",
              styles: JSON.stringify({ ...defaultlabel.style }, null, 2),
              mediaURl: controlToAddfortablelabel.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: `Header${maxCellNumber + j}`,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              controlChange: `Header${maxCellNumber + j}`,
              sourceData: sourceData,
              tableProp: "tableProp",
              url: url,
            });

            globalCellCounter++;
          }

          tableNode.control.push(row);
        }

        if (footerContainerIndex !== -1) {
          const [removed] = treeData[0].Source[0].control.splice(
            footerContainerIndex,
            1
          );
          treeData[0].Source[0].control.push(removed);
        }
        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      const getNextIDSforDatablocks = (parentNode) => {
        let maxID = 0;

        parentNode.control.forEach((item) => {
          if (item.id && typeof item.id === "string") {
            const parts = item.id.split(".");
            const lastPart = parseInt(parts[parts.length - 1]);
            if (!isNaN(lastPart) && lastPart > maxID) {
              maxID = lastPart;
            }
          }
        });

        return maxID + 1;
      };

      const applyStylesRecursively = (control, defaultStyle, parentID = "") => {
        const defaultSty = defaultStyle.find(
          (style) => style.styleType === control.styleType
        );

        const newID = parentID
          ? `${parentID}.${control.id.split(".").pop()}`
          : control.id;

        let updatedStyles = { ...defaultSty?.style };

        let treeAlgnment;

        if (treeData[0]?.Source?.length > 0) {
          const rootElement = treeData[0].Source[0];

          try {
            const styles = JSON.parse(rootElement.styles);
            treeAlgnment = styles.alignMent;
          } catch (error) {
            console.error("Failed to parse styles:", error);
          }
        } else {
          console.warn("Source array is empty or undefined.");
        }

        if (
          control.media === "CityState" ||
          control.media === "PostalCountry" ||
          control.media === "FirstLastName" ||
          control.media === "RatingInput"
        ) {
          updatedStyles.flexDirection = "row";
        }

        if (
          control.media === "Address" ||
          control.media === "FullName" ||
          control.media === "DropDown" ||
          control.media === "DropDownCont" ||
          control.media === "LongText" ||
          control.media === "ShortText" ||
          control.media === "EmailContainer" ||
          control.media === "PhoneContainer" ||
          control.media === "SingleChoiceContainer" ||
          control.media === "DateContainer" ||
          control.media === "MultipleChoiceMaincontainer" ||
          control.media === "SingleChoiceMainContainer" ||
          control.media === "Rating"
        ) {
          updatedStyles.flexDirection =
            treeAlgnment === "right" || treeAlgnment === "left"
              ? "row"
              : "column";
        }

        if (
          control.checkposition === "AddressHeader" ||
          control.checkposition === "ShortTextLabel" ||
          control.checkposition === "FullName" ||
          control.checkposition === "LongTextLabel" ||
          control.checkposition === "checkboxlabel" ||
          control.checkposition === "DropDownLabel" ||
          control.checkposition === "EmailLabel" ||
          control.checkposition === "Heading" ||
          control.checkposition === "PhoneNumberLabel" ||
          control.checkposition === "SingleChoiceLabel" ||
          control.checkposition === "MultipleChoiceLabel" ||
          control.checkposition === "DateLabel" ||
          control.checkposition === "RatingLabel" ||
          control.checkposition === "GeneralLabel" ||
          control.checkposition === "ImageLabel" ||
          control.checkposition === "SwitchLabel" ||
          control.checkposition === "IframeLabel"
        ) {
          updatedStyles.textAlign = treeAlgnment === "right" ? "right" : "left";
        }
        if (
          control.controltype === "Label" ||
          control.controltype === "TextBox" ||
          control.controltype === "Date" ||
          control.controltype === "Dropdown" ||
          control.controltype === "Radio" ||
          control.controltype === "Checkbox" ||
          control.controltype === "TextArea"
        ) {
          updatedStyles.fontSize = FontSize;
          updatedStyles.fontFamily = fontFamily;
        }

        if (control.htmlTag === "input" || control.htmlTag === "select") {
          updatedStyles.backgroundColor = inputColor;
        }

        if (
          control.media === "Address" ||
          control.media === "AddressHeaderContriner"
        ) {
          updatedStyles.marginLeft = "0px";
        }

        if (control.media === "HeadingContainer") {
          updatedStyles.flexDirection = "row";
        }

        if (
          control.checkposition === "Heading" ||
          control.checkposition === "SubHeader"
        ) {
          updatedStyles.width = "-webkit-fill-available";
        }

        if (
          control.media === "AddressHeaderContriner" ||
          control.media === "ShortTextLabel" ||
          control.media === "LongTextLabel" ||
          control.checkposition === "MultipleChoiceLabel" ||
          control.media === "SingleChoiceLabel" ||
          control.media === "DropDownLabel" ||
          control.media === "FullNameHeader" ||
          control.media === "PhoneLabel" ||
          control.media === "EamilLabel" ||
          control.media === "DateLabel" ||
          control.media === "RatingConatiner" ||
          control.checkposition === "PrefixLabel" ||
          control.checkposition === "MiddleNameLabel" ||
          control.media === "RatingConatiner" ||
          control.media === "MultipleChoiceLabel" ||
          control.media === "SingleChoiceLabel"
        ) {
          updatedStyles.maxWidth = "30%";
        }

        if (control.media === "ImageContainer") {
          updatedStyles.maxWidth = "auto";
          updatedStyles.width = "auto";
        }

        if (
          control.media === "DropDownCont" ||
          control.media === "FirstLastName" ||
          control.media === "AddressInput" ||
          control.media === "ContainerStreet1" ||
          control.media === "ContainerStreet2" ||
          control.media === "CityState" ||
          control.media === "ContainerSec" ||
          control.media === "ContainerThird" ||
          control.media === "PostalCountry" ||
          control.media === "ContainerPostal" ||
          control.media === "ContainerCountry" ||
          control.media === "ContainerPrefix" ||
          control.media === "FirstNameContainer" ||
          control.media === "ContainerMiddleName" ||
          control.media === "LastNameContainer" ||
          control.media === "CheckBoxMainContainer" ||
          control.media === "RadioMainContainer" ||
          control.media === "Rating"
        ) {
          updatedStyles.marginLeft = "0px";
          updatedStyles.paddingBottom = "0px";
          updatedStyles.paddingTop = "0px";
          updatedStyles.paddingLeft = "0px";
          updatedStyles.paddingRight = "0px";
        }

        if (control.media === "FullNameHeader") {
          updatedStyles.marginLeft = "0px";
          updatedStyles.paddingBottom = "0px";
          updatedStyles.paddingTop = "0px";
          updatedStyles.paddingLeft = "0px";
          updatedStyles.paddingRight = "4px";
        }

        if (
          control.media === "ContainerSec" ||
          control.media === "ContainerPostal"
        ) {
          updatedStyles.marginRight = "10px";
        }
        if (control.media === "ContainerPostal") {
          updatedStyles.marginRight = "10px";
        }

        if (
          control.media === "ContainerMiddleName" ||
          control.media === "ContainerPrefix" ||
          control.media === "ContainerCountry" ||
          control.media === "ImageContainer"
        ) {
          updatedStyles.display = "none";
        }

        if (
          control.media === "MainAddressHeader" ||
          control.media === "ImageHeading"
        ) {
          updatedStyles.maxWidth = "100%";
          updatedStyles.width = "100%";
        }

        if (
          control.checkposition === "AddressStreet1Textbox" ||
          control.checkposition === "AddressStreet2Textbox" ||
          control.checkposition === "CityTextbox" ||
          control.checkposition === "StateTextbox" ||
          control.checkposition === "PostalTextbox" ||
          control.checkposition === "CountryTextbox" ||
          control.checkposition === "FirstNameTextBox" ||
          control.checkposition === "LastNameTextBox" ||
          control.checkposition === "MiddleNameTextBox" ||
          control.checkposition === "PrefixTextBox"
        ) {
          updatedStyles.paddingLeft = "4px";
        }
        if (
          control.checkposition === "AddressStreet1" ||
          control.checkposition === "AddressStreet2" ||
          control.checkposition === "CityLabel" ||
          control.checkposition === "StateLabel" ||
          control.checkposition === "PostalLabel" ||
          control.checkposition === "CountryLabel" ||
          control.checkposition === "FirstName" ||
          control.checkposition === "LastName" ||
          control.checkposition === "MiddleNameLabel" ||
          control.checkposition === "PrefixLabel"
        ) {
          updatedStyles.marginLeft = "0px";
          updatedStyles.paddingLeft = "0px";
        }

        if (
          control.media === "Street1" ||
          control.media === "Street2" ||
          control.media === "City" ||
          control.media === "State" ||
          control.media === "Postal" ||
          control.media === "Country"
        ) {
          updatedStyles.marginTop = "2px";
          updatedStyles.marginBottom = "2px";
        }

        const updatedControl = {
          ...control,
          id: newID,
          styles: JSON.stringify(updatedStyles),
        };

        if (updatedControl.control && updatedControl.control.length > 0) {
          updatedControl.control = updatedControl.control.map(
            (child, childIndex) => {
              const childNewID = `${newID}.${childIndex + 1}`;
              return applyStylesRecursively(
                { ...child, id: childNewID },
                defaultStyle,
                newID
              );
            }
          );
        }

        return updatedControl;
      };

      const calculateNextStyleIdBlocks = () => {
        const deletedItemId = localStorage.getItem("deletedItemId");

        const deletedItemNumber = deletedItemId
          ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
          : -1;

        let maxStyleId = 0;

        const findMaxStyleId = (node) => {
          const styleIdRegex = /([a-zA-Z]+)(\d*)/;
          if (typeof node.styleid === "string") {
            const matches = node.styleid.match(styleIdRegex);
            if (matches && matches[1] === "agrfb") {
              const currentId = parseInt(matches[2]);
              if (!isNaN(currentId) && currentId > maxStyleId) {
                maxStyleId = currentId;
              }
            }
          }

          if (node.control && node.control.length > 0) {
            for (const childNode of node.control) {
              findMaxStyleId(childNode);
            }
          }
        };

        treeData[0].Source[0].control.forEach((rootNode) => {
          findMaxStyleId(rootNode);
        });

        const nextStyleId =
          deletedItemNumber > maxStyleId
            ? deletedItemNumber + 1
            : maxStyleId + 1;

        return `agrfb${nextStyleId}`;
      };

      if (controlType === "LongText") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        const LongTextCount = selectedNode.control.filter(
          (control) => control.controltype === "LongText"
        ).length;

        const adjustedLongText = LongTextJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

          const itemIndex = LongTextCount > 0 ? LongTextCount + 1 : index + 1;

          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,
              control: control.control.map(assignUniqueStyleId),
            };
          };
          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({
              ...item,
              id: newID,
            }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "DateBlock") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        const DateBlockCount = selectedNode.control.filter(
          (control) => control.controltype === "DateBlock"
        ).length;

        const adjustedLongText = DateJSON.map((item, index) => {
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

          const itemIndex = DateBlockCount > 0 ? DateBlockCount + 1 : index + 1;

          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,
              control: control.control.map(assignUniqueStyleId),
            };
          };
          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });
        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "ShortText") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        const ShortTextCount = selectedNode.control.filter(
          (control) => control.controltype === "ShortText"
        ).length;

        const adjustedLongText = ShortTextJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

          const itemIndex = ShortTextCount > 0 ? ShortTextCount + 1 : index + 1;

          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              control: control.control.map(assignUniqueStyleId),
            };
          };

          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "PhoneNumber") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        const PhoneNumberCount = selectedNode.control.filter(
          (control) => control.controltype === "PhoneNumber"
        ).length;

        const adjustedLongText = PhoneJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          // const newID = `0${baseID}.${getNextIDS(treeData) + index}`;
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;
            const itemIndex =
              PhoneNumberCount > 0 ? PhoneNumberCount + 1 : index + 1;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              control: control.control.map(assignUniqueStyleId),
            };
          };

          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "Email") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        const EmailsCount = selectedNode.control.filter(
          (control) => control.controltype === "Email"
        ).length;

        const adjustedLongText = EmailJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          // const newID = `0${baseID}.${getNextIDS(treeData) + index}`;
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            const itemIndex = EmailsCount > 0 ? EmailsCount + 1 : index + 1;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              control: control.control.map(assignUniqueStyleId),
            };
          };

          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "Heading") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();
        const HeadingCount = selectedNode.control.filter(
          (control) => control.controltype === "Heading"
        ).length;

        const adjustedLongText = HeadingJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            const itemIndex = HeadingCount > 0 ? HeadingCount + 1 : index + 1;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              control: control.control.map(assignUniqueStyleId),
            };
          };
          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "MultipleChoice") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();
        const MultipleChoiceCount = selectedNode.control.filter(
          (control) => control.controltype === "MultipleChoice"
        ).length;

        const adjustedMultipleChoice = MultipleChoiceJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

          const itemIndex =
            MultipleChoiceCount > 0 ? MultipleChoiceCount + 1 : index + 1;

          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            if (control.checkposition === "CheckBoxMainContainer") {
              const existingCheckBoxCount = control.control.filter(
                (c) => c.styleid && c.styleid.startsWith("agrfb")
              ).length;

              if (existingCheckBoxCount < 4) {
                for (let i = 0; i < 4; i++) {
                  const updatedNoneJSON = GenderCheckBoxJSON.map(
                    (noneItem, noneIndex) => ({
                      ...noneItem,
                      id: `${newID}.${getNextIDS(treeData) + noneIndex + i}`,
                      parentid: newID,
                      stylemedia: controlToAdd.stylemedia,
                      name:
                        itemIndex > 1
                          ? `${noneItem.name}${itemIndex - 1}`
                          : noneItem.name,

                      styleid: `agrfb${styleIdNumber + i}`,
                      data: `data${noneIndex + i}`,
                      inputText: `data${noneIndex + i}`,
                      switchoption: `data${noneIndex + i}`,
                    })
                  );

                  control.control = [...control.control, ...updatedNoneJSON];
                }
              }
            }

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,
              control: control.control.map(assignUniqueStyleId),
            };
          };

          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(
          insertionIndex,
          0,
          ...adjustedMultipleChoice
        );

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "SingleChoice") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        const SingleChoiceCount = selectedNode.control.filter(
          (control) => control.controltype === "SingleChoice"
        ).length;

        const adjustedSingleChoice = SingleChoiceJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            const itemIndex =
              SingleChoiceCount > 0 ? SingleChoiceCount + 1 : index + 1;

            if (control.checkposition === "RadioMainContainer") {
              // Prevent adding GenderJSON multiple times
              const existingRadioCount = control.control.filter(
                (c) => c.styleid && c.styleid.startsWith("agrfb")
              ).length;

              if (existingRadioCount < 4) {
                for (let i = 0; i < 4; i++) {
                  const updatedNoneJSON = GenderJSON.map(
                    (noneItem, noneIndex) => ({
                      ...noneItem,
                      id: `${newID}.${getNextIDS(treeData) + noneIndex + i}`,
                      parentid: newID,
                      stylemedia: controlToAdd.stylemedia,
                      name:
                        itemIndex > 1
                          ? `${noneItem.name}${itemIndex - 1}`
                          : noneItem.name,

                      styleid: `agrfb${styleIdNumber + i}`,
                      data: `data${noneIndex + i}`,
                      inputText: `data${noneIndex + i}`,
                      switchoption: `data${noneIndex + i}`,
                    })
                  );

                  control.control = [...control.control, ...updatedNoneJSON];
                }
              }
            }

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              control: control.control.map(assignUniqueStyleId),
            };
          };

          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedSingleChoice);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "DropDown") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();
        const DropDownCount = selectedNode.control.filter(
          (control) => control.controltype === "DropDown"
        ).length;
        const adjustedLongText = DropDownJSON.map((item, index) => {
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            const itemIndex = DropDownCount > 0 ? DropDownCount + 1 : index + 1;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              control: control.control.map(assignUniqueStyleId),
            };
          };
          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "FullName") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();
        const FullNameCount = selectedNode.control.filter(
          (control) => control.controltype === "FullName"
        ).length;
        const adjustedLongText = FullNameJSON.map((item, index) => {
          // const newID = `0${item.id.split(".")[0]}.${getNextIDS(treeData) + index}`;
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;
            const itemIndex = FullNameCount > 0 ? FullNameCount + 1 : index + 1;

            return {
              ...control,
              styleid: newStyleId,
              stylemedia: controlToAdd.stylemedia,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              parentid: newID,
              control: control.control.map(assignUniqueStyleId),
            };
          };
          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "Address") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        // Count how many Address controls already exist in the selectedNode
        const addressCount = selectedNode.control.filter(
          (control) => control.controltype === "Address"
        ).length;

        const adjustedAddress = AddressJSON[0].map((item, index) => {
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

          // Ensure the index starts from 1 for new Address controls
          const itemIndex = addressCount > 0 ? addressCount + 1 : index + 1;

          // Modify this logic to hide the index if it's 0
          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              stylemedia: controlToAdd.stylemedia,
              // Only append the index if it's greater than 0
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,
              control: control.control.map(assignUniqueStyleId),
            };
          };

          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({
              ...item,
              id: newID,
              // Only append the index if it's greater than 0
            }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedAddress);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "RatingBlock") {
        let currentMaxStyleId = calculateNextStyleIdBlocks();

        const SingleChoiceCount = selectedNode.control.filter(
          (control) => control.controltype === "RatingBlock"
        ).length;

        const adjustedSingleChoice = RatingJSON.map((item, index) => {
          const baseID = item.id.split(".")[0];
          const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

          const assignUniqueStyleId = (control) => {
            const styleIdNumber = parseInt(
              currentMaxStyleId.replace(/[a-zA-Z]/g, "")
            );
            const newStyleId = `agrfb${styleIdNumber + index}`;
            currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

            const itemIndex =
              SingleChoiceCount > 0 ? SingleChoiceCount + 1 : index + 1;

            if (control.checkposition === "RatingStarInput") {
              const existingRadioCount = control.control.filter(
                (c) => c.styleid && c.styleid.startsWith("agrfb")
              ).length;

              if (existingRadioCount < 4) {
                for (let i = 0; i < 4; i++) {
                  const updatedNoneJSON = StarJSON.map(
                    (noneItem, noneIndex) => ({
                      ...noneItem,
                      id: `${newID}.${getNextIDS(treeData) + noneIndex + i}`,
                      parentid: newID,
                      stylemedia: controlToAdd.stylemedia,
                      name:
                        itemIndex > 1
                          ? `${noneItem.name}${itemIndex - 1}`
                          : noneItem.name,

                      styleid: `agrfb${styleIdNumber + i}`,
                      data: `data${noneIndex + i}`,
                    })
                  );

                  control.control = [...control.control, ...updatedNoneJSON];
                }
              }
            }

            return {
              ...control,
              styleid: newStyleId,
              parentid: newID,
              name:
                itemIndex > 1
                  ? `${control.name}${itemIndex - 1}`
                  : control.name,

              control: control.control.map(assignUniqueStyleId),
            };
          };

          const updatedItem = applyStylesRecursively(
            assignUniqueStyleId({ ...item, id: newID }),
            defaultStyle,
            selectedNode.id
          );

          return updatedItem;
        });

        selectedNode.control.splice(insertionIndex, 0, ...adjustedSingleChoice);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (selectedNode && selectedNode.control) {
        if (
          selectedNode.title === "Display" ||
          selectedNode.title === "Input" ||
          selectedNode.title === "Icon" ||
          selectedNode.title === "Datablocks"
        ) {
          const newID = getNextIDS(treeData);

          const newNode = {
            id: `0.${newID}`,
            name: newName,
            controlName: newName,
            tableHeader: "",
            data: getDataForControlType(controlType, controlToAdd.description),
            type: controlType,
            parentid: "0",
            styleid: styleid,
            htmlTag: customHtmlTag,
            controltype: controlType,
            styles: JSON.stringify({ ...mergedStyles }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: sourceData,
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [],
          };

          treeData[0].Source[0].control.splice(
            footerContainerIndex,
            0,
            newNode
          );
          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }
        selectedNode.control.push(newNode);
        const itemToInsert = selectedNode.control.pop();
        selectedNode.control.splice(insertionIndex, 0, itemToInsert);

        setOpenDialog(false);
        setTreeData([...treeData]);
      } else {
        const { childID } = getNextIDS(treeData);

        if (
          Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title === "Display" ||
          Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title === "Input" ||
          Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title === "Icon" ||
          Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title === "Datablocks"
        ) {
          console.log(`Cannot add ${controlType} without a parent`);
          return;
        }

        const newNode = {
          id: childID,
          name: newName,
          controlName: newName,
          tableHeader: "",
          data: controlToAdd.description,
          type: controlType,
          parentid: "0",
          styleid: styleid,
          htmlTag: customHtmlTag,
          controltype: controlType,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: sourceData,
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [],
        };
        treeData[0].Source[0].control.splice(footerContainerIndex, 0, newNode);

        setOpenDialog(false);
        setTreeData([...treeData]);
      }
    }

    if (
      footerContainerIndex !== -1 &&
      footerContainerIndex !== treeData[0].Source[0].control.length - 1
    ) {
      const [removed] = treeData[0].Source[0].control.splice(
        footerContainerIndex,
        1
      );
      treeData[0].Source[0].control.push(removed);
      setTreeData([...treeData]);
    }
  };

  console.log("treeData", treeData);

  const onDragEnd = (result) => {
    setIsDragging(false);
    const { destination, source, draggableId } = result;
    let draggedControl = null;
    let sourceIndex = -1;
    let sourceControlArray = -1;

    Controls.forEach((controlArray, index) => {
      const foundControl = controlArray.items.find(
        (control) => String(control.id) === draggableId
      );
      if (foundControl) {
        draggedControl = foundControl;
        sourceIndex = source.index;
        sourceControlArray = index;
      }
    });

    if (!draggedControl) {
      return;
    }

    if (!destination) {
      handleAddControl(draggedControl.controltype);
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const findLastStyleId = (item) => {
    if (!item.control || item.control.length === 0) {
      return item.styleid;
    }
    const lastControl = item.control[item.control.length - 1];

    // Ensure that the recursion terminates when there is no deeper control
    if (!lastControl.control || lastControl.control.length === 0) {
      return lastControl.styleid;
    }

    return findLastStyleId(lastControl);
  };

  const handleDelete = (idToDelete) => {
    const itemToDelete = findItemById(treeData, idToDelete);

    if (itemToDelete && itemToDelete.id) {
      const lastStyleId = findLastStyleId(itemToDelete);

      localStorage.setItem("deletedItemId", lastStyleId);

      if (isRootItem(itemToDelete, treeData)) {
        return;
      }
      onDeleteItem(itemToDelete.id);
    }
  };

  const findItemById = (treeData, id) => {
    let foundItem = null;
    const searchTree = (node) => {
      if (node.id === id) {
        foundItem = node;
        return;
      }
      if (node.control) {
        for (const child of node.control) {
          searchTree(child);
        }
      }
    };

    treeData.forEach((rootItem) => {
      rootItem.Source.forEach((sourceItem) => {
        searchTree(sourceItem);
      });
    });

    return foundItem;
  };

  const onDeleteItem = (itemIdToDelete) => {
    let previousItem = null;

    const findPreviousItem = (node, parent = null) => {
      if (node.id === itemIdToDelete) {
        return previousItem;
      }

      previousItem = node;

      if (node.control) {
        for (let i = 0; i < node.control.length; i++) {
          const result = findPreviousItem(node.control[i], node);
          if (result) return result;
        }
      }
      return null;
    };

    treeData.forEach((rootItem) => {
      rootItem.Source.forEach((sourceItem) => {
        findPreviousItem(sourceItem);
      });
    });

    const deleteItemRecursively = (node) => {
      if (node.id === itemIdToDelete) {
        return null;
      }

      if (node.control) {
        const updatedControl = node.control
          .map((child) => deleteItemRecursively(child))
          .filter(Boolean);

        return {
          ...node,
          control: updatedControl,
        };
      }

      return node;
    };

    const updatedTreeData = treeData.map((rootItem) => ({
      ...rootItem,
      Source: rootItem.Source.map((sourceItem) => ({
        ...sourceItem,
        control: sourceItem.control
          .map((item) => deleteItemRecursively(item))
          .filter(Boolean),
      })),
    }));

    setTreeData(updatedTreeData);

    const element = document.getElementById(selectedItem.id);
    const focusedNode = document.querySelector(".focusingClass");

    if (element) {
      if (focusedNode) {
        focusedNode.classList.remove("focusingClass");
      }
      element.classList.add("focusingClass");
    }
    setTimeout(() => {
      const itemToSelect = previousItem || updatedTreeData[0]?.Source[0];
      if (itemToSelect) {
        setSelectedNode(itemToSelect);
        setSelectedItem(itemToSelect);
        setSelectedControl(itemToSelect);
        setEditedName(itemToSelect.name);
        setSelectedItemID(itemToSelect.id);
        setSelectedOptioncheck(itemToSelect.check ? "true" : "false");
        setSelectedOptionSwitchcheck(
          itemToSelect.switchcheck ? "true" : "false"
        );
        setSelectedPositioncheck(
          itemToSelect.checkposition || (itemToSelect.media ? "left" : "right")
        );
        setEditedName(itemToSelect.name || editedName);
        setEditedData(itemToSelect.data || "");
        setSelectedOption(
          itemToSelect.imageText || itemToSelect.inputText || itemToSelect.data
        );

        settabSelect(itemToSelect.tab || "");
        setTableHeader(itemToSelect.tableHeader ? "TableHeader" : "");
        setSourceData(itemToSelect.sourceData ? "" : "");
        setSelectedControlType(
          itemToSelect.controlChange || itemToSelect.selectedControlType
        );
        setIsOptionSelected(!isOptionSelected);
        settableProp(itemToSelect.tableProp ? "tableProp" : "null");
      }
    }, 0);
  };

  // const setIconsforFocus = (id) => {
  //   // if (id !== null) {
  //   //   // if (id.toString() === "0") {
  //   //     const rootIcons = document.querySelectorAll(".icon-container");
  //   //     rootIcons.forEach((icon) => icon.remove());
  //   //     return;
  //   //   // }
  //   // }

  //   const element = document.getElementById(id);

  //   if (element && element.hasAttribute("styleid")) {
  //     const styleId = element.getAttribute("styleid");

  //     if (styleId) {
  //       const prevIcons = document.querySelectorAll(".icon-container");
  //       prevIcons.forEach((icon) => icon.remove());

  //       const existingIconContainer = document.getElementById(
  //         `iconContainer-${id}`
  //       );
  //       if (!existingIconContainer) {
  //         let iconContainerHtml = `
  //       <span id="iconContainer-${id}" class="icon-container">
  //         <span class="icon-wrapper setting-icon-wrapper">
  //           <span class="fa fa-cog setting-icon" id="setIconFcs-${id}"></span>
  //           <span class="icon-text" id="setTextFcs-${id}">Properties</span>
  //         </span>
  //         ${
  //           id !== "0"
  //             ? `<span class="icon-wrapper delete-icon-wrapper">
  //                 <span class="fa fa-trash delete-icon" id="trashIconFcs-${id}"></span>
  //                 <span class="icon-text">Delete</span>
  //               </span>`
  //             : ""
  //         }
  //       </span>`;

  //         element.insertAdjacentHTML("afterend", iconContainerHtml);

  //         const updateIconPosition = () => {
  //           const iconContainer = document.getElementById(
  //             `iconContainer-${id}`
  //           );
  //           if (iconContainer) {
  //             const rect = element.getBoundingClientRect();
  //             iconContainer.style.top = `${rect.top + window.scrollY}px`;
  //             iconContainer.style.left = `${rect.right + window.scrollX}px`;
  //           }
  //         };

  //         updateIconPosition();

  //         // window.addEventListener("resize", updateIconPosition);
  //         // window.addEventListener("scroll", updateIconPosition);

  //         // document.getElementById('containertopsection').addEventListener('scroll', updateIconPosition);
  //         // document.getElementById('0').addEventListener('scroll', updateIconPosition);
  //         document
  //           .querySelectorAll(".container-scrol-icon")
  //           .forEach((element) => {
  //             element.addEventListener("scroll", updateIconPosition);
  //           });

  //         const settingIcon = document.getElementById(`setIconFcs-${id}`);
  //         const settingText = document.getElementById(`setTextFcs-${id}`);
  //         const deleteIcon = document.getElementById(`trashIconFcs-${id}`);

  //         if (settingIcon && settingText) {
  //           settingIcon.addEventListener("click", handleTabshwButtonClick);
  //           settingText.addEventListener("click", handleTabshwButtonClick);
  //         }

  //         if (deleteIcon) {
  //           deleteIcon.addEventListener("click", () => {
  //             handleDelete(id);
  //           });
  //         }
  //       }
  //     }
  //   }
  // };
  // ----------------
  // const setIconsforFocus = (id) => {
  //   // Remove any existing icons first
  //   const prevIcons = document.querySelectorAll(".icon-container");
  //   prevIcons.forEach((icon) => icon.remove());

  //   const element = document.getElementById(id);

  //   if (element && element.hasAttribute("styleid")) {
  //     const styleId = element.getAttribute("styleid");

  //     if (styleId) {
  //       const existingIconContainer = document.getElementById(
  //         `iconContainer-${id}`
  //       );
  //       if (!existingIconContainer) {
  //         let iconContainerHtml = `
  //           <span id="iconContainer-${id}" class="icon-container">
  //             <span class="icon-wrapper setting-icon-wrapper">
  //               <span class="fa fa-cog setting-icon" id="setIconFcs-${id}"></span>
  //               <span class="icon-text" id="setTextFcs-${id}">Properties</span>
  //             </span>
  //             ${
  //               id !== "0"
  //                 ? `<span class="icon-wrapper delete-icon-wrapper">
  //                     <span class="fa fa-trash delete-icon" id="trashIconFcs-${id}"></span>
  //                     <span class="icon-text">Delete</span>
  //                   </span>`
  //                 : ""
  //             }
  //           </span>`;

  //         element.insertAdjacentHTML("afterend", iconContainerHtml);

  //         const updateIconPosition = () => {
  //           const iconContainer = document.getElementById(
  //             `iconContainer-${id}`
  //           );
  //           if (iconContainer) {
  //             const rect = element.getBoundingClientRect();
  //             if (id === "0") {
  //               // Position icons at the right center for id "0"
  //               const containerHeight = iconContainer.offsetHeight;
  //               const elementHeight = rect.height;
  //               const topOffset = rect.top + window.scrollY + (elementHeight - containerHeight) / 2;

  //               iconContainer.style.top = `${topOffset}px`;
  //               iconContainer.style.left = `${rect.right + window.scrollX}px`;
  //             } else {
  //               // Default positioning for other ids
  //               iconContainer.style.top = `${rect.top + window.scrollY}px`;
  //               iconContainer.style.left = `${rect.right + window.scrollX}px`;
  //             }
  //           }
  //         };

  //         updateIconPosition();

  //         // Attach scroll listeners to update icon position
  //         document
  //           .querySelectorAll(".container-scrol-icon")
  //           .forEach((element) => {
  //             element.addEventListener("scroll", updateIconPosition);
  //           });

  //         const settingIcon = document.getElementById(`setIconFcs-${id}`);
  //         const settingText = document.getElementById(`setTextFcs-${id}`);
  //         const deleteIcon = document.getElementById(`trashIconFcs-${id}`);

  //         if (settingIcon && settingText) {
  //           settingIcon.addEventListener("click", handleTabshwButtonClick);
  //           settingText.addEventListener("click", handleTabshwButtonClick);
  //         }

  //         if (deleteIcon) {
  //           deleteIcon.addEventListener("click", () => {
  //             handleDelete(id);
  //           });
  //         }
  //       }
  //     }
  //   }
  // };
  // const setIconsforFocus = (id) => {
  //   // Remove any existing icons first
  //   const prevIcons = document.querySelectorAll(".icon-container");
  //   prevIcons.forEach((icon) => icon.remove());

  //   const element = document.getElementById(id);

  //   if (element && element.hasAttribute("styleid")) {
  //     const styleId = element.getAttribute("styleid");

  //     if (styleId) {
  //       const existingIconContainer = document.getElementById(
  //         `iconContainer-${id}`
  //       );
  //       if (!existingIconContainer) {
  //         let iconContainerHtml = `
  //           <span id="iconContainer-${id}" class="icon-container">
  //             <span class="icon-wrapper setting-icon-wrapper">
  //               <span class="fa fa-cog setting-icon" id="setIconFcs-${id}"></span>
  //               <span class="icon-text" id="setTextFcs-${id}">Properties</span>
  //             </span>
  //             ${
  //               id !== "0"
  //                 ? `<span class="icon-wrapper delete-icon-wrapper">
  //                     <span class="fa fa-trash delete-icon" id="trashIconFcs-${id}"></span>
  //                     <span class="icon-text">Delete</span>
  //                   </span>`
  //                 : ""
  //             }
  //           </span>`;

  //         element.insertAdjacentHTML("afterend", iconContainerHtml);

  //         const updateIconPosition = () => {
  //           const iconContainer = document.getElementById(
  //             `iconContainer-${id}`
  //           );
  //           if (iconContainer) {
  //             const rect = element.getBoundingClientRect();
  //             const elementCenterY = rect.top + window.scrollY + rect.height / 2;
  //             const iconHeight = iconContainer.offsetHeight;
  //             const centeredTopPosition = elementCenterY - iconHeight / 2;

  //             // Set the position to be centered
  //             // iconContainer.style.position = "absolute";
  //             iconContainer.style.top = `${centeredTopPosition}px`;
  //             iconContainer.style.left = `${rect.right + window.scrollX}px`;
  //           }
  //         };

  //         updateIconPosition();

  //         // Attach scroll listeners to update icon position
  //         document
  //           .querySelectorAll(".container-scrol-icon")
  //           .forEach((element) => {
  //             element.addEventListener("scroll", updateIconPosition);
  //           });

  //         // Attach window scroll listener to update icon position on scroll
  //         window.addEventListener("scroll", updateIconPosition);

  //         const settingIcon = document.getElementById(`setIconFcs-${id}`);
  //         const settingText = document.getElementById(`setTextFcs-${id}`);
  //         const deleteIcon = document.getElementById(`trashIconFcs-${id}`);

  //         if (settingIcon && settingText) {
  //           settingIcon.addEventListener("click", handleTabshwButtonClick);
  //           settingText.addEventListener("click", handleTabshwButtonClick);
  //         }

  //         if (deleteIcon) {
  //           deleteIcon.addEventListener("click", () => {
  //             handleDelete(id);
  //           });
  //         }
  //       }
  //     }
  //   }
  // };

  let lastFocusedId = null;

  const setIconsforFocus = (id) => {
    // // Remove any existing icons first
    // const prevIcons = document.querySelectorAll(".icon-container");
    // prevIcons.forEach((icon) => icon.remove());

    // Clear any existing icons from the previously focused control
    if (lastFocusedId && lastFocusedId !== id) {
      const previousIconContainer = document.getElementById(
        `iconContainer-${lastFocusedId}`
      );
      if (previousIconContainer) {
        previousIconContainer.remove();
      }
    }

    // Update the last focused ID
    lastFocusedId = id;

    const element = document.getElementById(id);

    if (element && element.hasAttribute("styleid")) {
      const styleId = element.getAttribute("styleid");

      if (styleId) {
        const existingIconContainer = document.getElementById(
          `iconContainer-${id}`
        );
        if (!existingIconContainer) {
          let iconContainerHtml = `
            <span id="iconContainer-${id}" class="icon-container">
              <span class="icon-wrapper setting-icon-wrapper">
                <span class="fa fa-cog setting-icon" id="setIconFcs-${id}"></span>
                <span class="icon-text" id="setTextFcs-${id}">Properties</span>
              </span>
              ${
                id !== "0"
                  ? `<span class="icon-wrapper delete-icon-wrapper">
                      <span class="fa fa-trash delete-icon" id="trashIconFcs-${id}"></span>
                      <span class="icon-text">Delete</span>
                    </span>`
                  : ""
              }
            </span>`;

          element.insertAdjacentHTML("afterend", iconContainerHtml);

          const updateIconPosition = () => {
            const iconContainer = document.getElementById(
              `iconContainer-${id}`
            );
            if (iconContainer) {
              const rect = element.getBoundingClientRect();
              const elementCenterY =
                rect.top + window.scrollY + rect.height / 2;
              const isInFooter = element.closest("footer");

              if (isInFooter) {
                const topCenterPositionY =
                  rect.top + window.scrollY - iconContainer.offsetHeight / 2;
                const centeredLeftPosition =
                  rect.left +
                  window.scrollX +
                  rect.width / 2 -
                  iconContainer.offsetWidth / 2;

                iconContainer.style.top = `${topCenterPositionY}px`;
                iconContainer.style.left = `${centeredLeftPosition}px`;
              } else if (id === "0") {
                iconContainer.style.top = `${elementCenterY - iconContainer.offsetHeight / 2}px`;
                iconContainer.style.left = `${rect.right + window.scrollX + 10}px`;
              } else {
                const bottomCenterLeftPosition = rect.bottom + window.scrollY;
                const centeredLeftPosition =
                  rect.left +
                  window.scrollX +
                  rect.width / 2 -
                  iconContainer.offsetWidth / 2;

                iconContainer.style.top = `${bottomCenterLeftPosition}px`;
                iconContainer.style.left = `${centeredLeftPosition}px`;
              }
            }
          };

          // Call updateIconPosition to set the initial position
          updateIconPosition();

          // Recalculate icon position on scroll, resize, and on click events
          document
            .querySelectorAll(".container-scrol-icon")
            .forEach((container) => {
              container.addEventListener("scroll", updateIconPosition);
            });
          window.addEventListener("scroll", updateIconPosition);
          window.addEventListener("resize", updateIconPosition);

          // Handle the interactions with the setting and delete icons
          const settingIcon = document.getElementById(`setIconFcs-${id}`);
          const settingText = document.getElementById(`setTextFcs-${id}`);
          const deleteIcon = document.getElementById(`trashIconFcs-${id}`);

          if (settingIcon && settingText) {
            settingIcon.addEventListener("click", handleTabshwButtonClick);
            settingText.addEventListener("click", handleTabshwButtonClick);
          }

          if (deleteIcon) {
            deleteIcon.addEventListener("click", () => {
              handleDelete(id);
            });
          }
        }
      }
    }
  };

  const handleSelectItem = (
    id,
    name,
    type,
    data,
    styles,
    styleid,
    htmlTag,
    text,
    styleType,
    check,
    media,
    option,
    position,
    switchcheck,
    switchoption,
    checkposition,
    value,
    tab,
    inputType,
    tableHeader,
    sourceData,
    controltype,
    controlName,
    controlChange,
    tableProp,
    inputText,
    imageText,
    newPosition,
    url,
    title
  ) => {
    const element = document.getElementById(id);
    const nodeListfocused = document.querySelectorAll(".focusingClass")[0];
    if (element && !datablockChildControlIds.includes(id)) {
      if (nodeListfocused) {
        nodeListfocused.classList.remove("focusingClass");
      }
      element.classList.add("focusingClass");

      setSelectedItemID(id);
      setSelectedControl((prevSelectedItem) => ({
        ...prevSelectedItem,
        type,
      }));

      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        id,
        name: name || editedName,
        type,
        data: data || editedData,
        styleid,
        styles,
        htmlTag,
        text,
        styleType,
        check: check || isChecked,
        media: media || checkboxPosition || RadioPosition,
        position: position || selectedOptionmedia,
        switchcheck: switchcheck || isSwitched,
        switchoption: switchoption || selectedOptionSwitchcheck,
        checkposition,
        tab: tabSelect || tab,
        inputType: inputType || inputType,
        tableHeader: tableHeader || tableHeader,
        sourceData: sourceData || sourceData,
        controltype,
        controlName,
        controlChange,
        tableProp,
        inputText,
        imageText,
        url,
        title,
      }));

      setSelectedOptioncheck(check ? "true" : "false");
      setSelectedOptionSwitchcheck(switchcheck ? "false" : "true");
      setSelectedPositioncheck(checkposition || (media ? "left" : "right"));
      setEditedName(name || editedName);
      setEditedData(data || editedData);
      setSelectedOption(imageText || inputText || data);

      settabSelect(tab || "");
      setTableHeader(tableHeader ? "TableHeader" : "");
      setSourceData(sourceData ? "" : "");
      setSelectedControlType(controlChange || selectedControlType);
      setIsOptionSelected(!isOptionSelected);
      settableProp(tableProp ? "tableProp" : "null");
    } else {
      console.error("Element with ID", id, "not found in the DOM");
    }
  };

  useLayoutEffect(() => {
    if (treeData.length > 0 && !selectedNode) {
      const firstItem = treeData[0].Source[0];
      const firstItemElement = document.getElementById(firstItem.id);
      if (firstItemElement) {
        firstItemElement.classList.add("focusingClass");
      }

      setSelectedNode(firstItem);
      setSelectedItem(firstItem);
      setSelectedControl(firstItem);
      setEditedName(firstItem.name);
      setSelectedItemID(firstItem.id);
    }
  }, [treeData, selectedNode]);

  // const updateCustomStyle = (styleid, style) => {
  //   setCustomStyles((prevCustomStyles) => ({
  //     ...prevCustomStyles,
  //     [styleid]: style,
  //   }));
  //   setTreeData((prevTreeData) => {
  //     const updatedTreeData = [...prevTreeData];
  //     const rootNode = updatedTreeData[0].Source[0];
  //     updateStyleInTree(rootNode, styleid, style);
  //     return updatedTreeData;
  //   });
  // };

  // const updateStyleInTree = (node, styleid, style) => {
  //   if (node.styleid === styleid) {
  //     node.styles = JSON.stringify({ ...style }, null, 2);
  //   }
  //   if (node.control && node.control.length > 0) {
  //     node.control.forEach((child) => updateStyleInTree(child, styleid, style));
  //   }
  // };

  const updateCustomStyle = (styleid, style, updateParentStyles = false) => {
    setCustomStyles((prevCustomStyles) => ({
      ...prevCustomStyles,
      [styleid]: style,
    }));

    setTreeData((prevTreeData) => {
      const updatedTreeData = [...prevTreeData];
      const rootNode = updatedTreeData[0].Source[0];
      updateStyleInTree(rootNode, styleid, style, updateParentStyles);
      return updatedTreeData;
    });
  };

  const updateStyleInTree = (node, styleid, style, updateParentStyles) => {
    if (node.styleid === styleid) {
      // Update `parentstyles` if specified, otherwise update `styles`
      if (updateParentStyles) {
        node.parentstyles = JSON.stringify({ ...style }, null, 2);
      } else {
        node.styles = JSON.stringify({ ...style }, null, 2);
      }
    }

    // Recursively apply to child nodes if they exist
    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        updateStyleInTree(child, styleid, style, updateParentStyles)
      );
    }
  };

  // const updateCustomStyle = (styleid, style, updateParentStyles = false) => {
  //   setCustomStyles((prevCustomStyles) => ({
  //       ...prevCustomStyles,
  //       [styleid]: style,
  //   }));

  //   setTreeData((prevTreeData) => {
  //       const updatedTreeData = [...prevTreeData];
  //       const rootNode = updatedTreeData[0].Source[0];
  //       updateStyleInTree(rootNode, styleid, style, updateParentStyles);
  //       return updatedTreeData;
  //   });
  // };

  // const updateStyleInTree = (node, styleid, style, updateParentStyles) => {
  //   if (node.styleid === styleid) {
  //       // Conditionally update parentstyles or styles
  //       if (updateParentStyles) {
  //           node.parentstyles = JSON.stringify(style, null, 2);
  //       } else {
  //           node.styles = JSON.stringify(style, null, 2);
  //       }
  //   }

  //   // Recursively apply to child nodes if they exist
  //   if (node.control && node.control.length > 0) {
  //       node.control.forEach((child) =>
  //           updateStyleInTree(child, styleid, style, updateParentStyles)
  //       );
  //   }
  // };

  const handleRightClick = (event, control) => {
    event.preventDefault();
    event.stopPropagation();

    const element = document.getElementById(control.id);
    const focusedNode = document.querySelector(".focusingClass");

    if (element) {

          // Remove any existing icons first
    const prevIcons = document.querySelectorAll(".icon-container");
    prevIcons.forEach((icon) => icon.remove());

      if (focusedNode) {
        focusedNode.classList.remove("focusingClass");
      }
      element.classList.add("focusingClass");
    }

    const styles = control.styles;
    const x = event.clientX;
    const y = event.clientY;

    setPopupPosition({ x, y });
    setContextMenuVisible(true);
    setSelectedItemID(control.id);
    setContextMenuStyles(styles);
    setSelectedControl(control);
    setSelectedNode(control);

    setSelectedItem(control);

    setSelectedOptioncheck(control.check ? "true" : "false");
    setSelectedOptionSwitchcheck(control.switchcheck ? "true" : "false");
    setSelectedPositioncheck(
      control.checkposition || (control.media ? "left" : "right")
    );
    setEditedName(control.name || editedName);
    setEditedData(control.data || "");
    setSelectedOption(control.imageText || control.inputText || control.data);

    settabSelect(control.tab || "");
    setTableHeader(control.tableHeader ? "TableHeader" : "");
    setSourceData(control.sourceData ? "" : "");
    setSelectedControlType(
      control.controlChange || control.selectedControlType
    );
    setIsOptionSelected(!isOptionSelected);
    settableProp(control.tableProp ? "tableProp" : "null");

    setShowPopup(true);
  };

  const renderControl = (control) => {
    if (!control || typeof control.id === "undefined") {
      return null;
    }

    const isItemSelected = control.id === selectedItemID;

    const onUpdateStyleFromTextarea = (updatedStyles) => {
      setContextMenuStyles(updatedStyles);

      try {
        const parsedStyles = JSON.parse(updatedStyles);
        updateCustomStyle(selectedControl.styleid, parsedStyles);
      } catch (error) {
        const keyValuePairs = updatedStyles.split(/\s*,\s*/);
        const nonJsonStyles = {};
        keyValuePairs.forEach((pair) => {
          const [key, ...valueParts] = pair.split(/\s*:\s*/);
          const value = valueParts.join(":");
          nonJsonStyles[key] = value;
        });
        updateCustomStyle(selectedControl.styleid, nonJsonStyles);
      }
    };

    const shouldRenderMediaUrl = control.styleType === "Icon";

    const controlTypeToRender = shouldRenderMediaUrl ? (
      <>
        {control.mediaURl && (
          <FontAwesomeIcon
            icon={{
              prefix: control.mediaURl.startsWith("fas")
                ? "fas"
                : control.mediaURl.startsWith("fab")
                  ? "fab"
                  : "fa",
              iconName: control.mediaURl,
            }}
          />
        )}
      </>
    ) : control.htmlTag === "input" ? (
      control.styleType
    ) : (
      control.data
    );

    const createdElement = (
     <CustomHtmlTag
        keyss={control.id}
        styleType={control.styleType}
        text={control.text}
        htmlTag={control.htmlTag}
        styleid={control.styleid}
        controltype={controlTypeToRender}
        isSelected={isItemSelected}
        styles={control.styles}
        onContextMenu={(event) => {
          handleRightClick(event, control);
        }}
        controlName={control.type}
        mediaURl={shouldRenderMediaUrl ? controlTypeToRender : ""}
        customStyles={customStyles}
        updateCustomStyle={updateCustomStyle}
        data={control.data}
        inputType={control.inputType}
        editedData={editedData}
        handleSelectItem={handleSelectItem}
        setIconsforFocus={setIconsforFocus}
        control={control}
        selectedNode={selectedNode}
        controlToAdd={control.controlToAdd}
        handleAddControl={handleAddControl}
        numberOfRadioInputs={numberOfRadioInputs}
        numberOfCheckbox={numberOfCheckbox}
        treeData={treeData}
        setTreeData={setTreeData}
        handleCheckboxChange={handleCheckboxChange}
        value={control.value}
        check={control.check}
        media={control.media}
        selectedImage={selectedImage}
        checkboxPosition={checkboxPosition}
        uniqueKey={control.uniqueKey}
        selectedValue={selectedValue}
        handleRadioChangeData={handleRadioChangeData}
        switchcheck={control.switchcheck}
        handleSelectChange={handleSelectChange}
        selectedOptionControl={selectedOptionControl}
        onClickforFocus={onClickforFocus}
        name={control.name}
        rightsideStyletab={rightsideStyletab}
        setOpenDialog={setOpenDialog}
        inputRef={inputRef}
        position={control.position}
        selectedPositioncheck={selectedPositioncheck}
        checkposition={control.checkposition}
        handleDateChange={handleDateChange}
        setControlsVisible={setControlsVisible}
        controlsVisible={controlsVisible}
        selectedInputType={inputType}
        inputText={control.inputText}
        imageText={control.imageText}
        url={control.url}
        selectedItem={selectedItem}
        setCustomStyles={setCustomStyles}
        defaultStyle={defaultStyle}
        setDefaultStyle={setDefaultStyle}
        selectedItemID={selectedItemID}
        onMouseOver={(event) => {
          handleMouseOver(event, control);
        }}
        onMouseOut={handleMouseOut}
        showTheme={showTheme}
        tableProp={control.tableProp}
      >


{/* {control.control &&
          control.control.length > 0 &&
          control.control.map((childControl) => (
            <React.Fragment key={childControl.id}>
              <div
                onContextMenu={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
               
              >
                {renderControl(childControl)}
              </div>
            </React.Fragment>
          ))} */}
{control.control &&
  control.control.length > 0 &&
  control.control.map((childControl) => (
    <React.Fragment key={childControl.id}>
      <div
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        style={{ display: 'contents' }}
      >
        {renderControl(childControl)}
      </div>
    </React.Fragment>
  ))}


        {selectedControl && selectedControl.id === control.id && (
          <React.Fragment onClick={handleRightClick}>
            <CustomPopUpForm
              onClose={handleClose}
              title={`Edit Styles: ${selectedControl.name}`}
              open={showPopup}
            >
              <div>
                <textarea
                  value={contextMenuStyles}
                  onChange={(e) => onUpdateStyleFromTextarea(e.target.value)}
                  style={{ width: "100%", minHeight: "300px" }}
                />
              </div>
            </CustomPopUpForm>
          </React.Fragment>
        )}
      </CustomHtmlTag>
      
    );
    return createdElement;
  };


  const accumulateHtmlContent = () => {
    let accumulatedContent = "";

    treeData.forEach((item) => {
      const controlsHtml = item.Source[0].control
        .map((control) => {
          const createdElement = renderControl(control);
          let htmlString = ReactDOMServer.renderToStaticMarkup(createdElement);
          return htmlString;
        })
        .join("");
      accumulatedContent += `${controlsHtml}`;
    });
    setHtmlContent(accumulatedContent);
  };

  useEffect(() => {
    accumulateHtmlContent();
  }, [accumulateHtmlContent]);

  useEffect(() => {
    const isThemesPage = window.location.href.includes("Themes");
    setShowTheme(!isThemesPage);
  }, []);

  const generateHTMLFile = (htmlContent) => {
    const templateArid = treeData[0]?.Source[0]?.templateArid;

    const tagMatches = htmlContent.matchAll(/<(?!(\/))(\S+?)(?=\s|\/|>)/g);
    const tags = [...tagMatches].map((match) => match[0].slice(1));

    const regex = /style="([^"]*)"/g;
    const styleMatches = [...htmlContent.matchAll(regex)];
    const styles = styleMatches.map((match) => `{${match[1]}}`);
    const styleidMatches = htmlContent.match(/styleid="([^"]*)"/g);
    const styleids = styleidMatches
      ? styleidMatches.map((styleidMatch) => {
          return styleidMatch.slice(9, -1);
        })
      : [];

    const nameMatches = htmlContent.match(/name="([^"]*)"/g);
    const names = nameMatches
      ? nameMatches.map((nameMatch) => {
          return nameMatch.slice(6, -1);
        })
      : [];

    htmlContent = htmlContent.replace(/ style="([^"]*)"/g, "");

    const tagStylePairs = tags.map((tag, index) => ({
      tag,
      style: styles[index] || "{}",
      styleid: styleids[index] || "",
      name: names[index] || "",
    }));

    let formattedTagWithStyle = "";
    tagStylePairs.forEach(({ tag, style, styleid, name }) => {
      let tagWithStyle = `${styleid ? `[styleid="${styleid}"]` : ""}`;
      if (style !== "{}" || styleid || name) {
        tagWithStyle += `${style === "{}" ? "" : style}`;
        tagWithStyle += `${style === "{}" ? "" : style}`;
        formattedTagWithStyle += `${tagWithStyle}\r\n`;
      }
    });

    let switchCSSGenerated = false;
    let sliderCSSGenerated = false;
    let RadioCSSGenerated = false;
    let CheckCSSGenerated = false;

    const generateStyle = `
<style>
/*-- GEN <generic> --*/

  ${formattedTagWithStyle
    .split("\r\n")
    .filter((line) => line.trim() !== "")
    .map((line) => {
      const [selector, properties] = line.split("{");
      const formattedProperties = properties ? properties.split(";") : [];
      const formattedPropertiesString = formattedProperties
        .filter((property) => property.trim() !== "")
        .map((property) => `${property.trim()}`)
        .map((property) => `${property.trim()}`)
        .join(";\r\n");
      return `${selector.trim()}{\r\n${formattedPropertiesString}\r\n`;
    })
    .join("\r\n")}

    tr{
    display :flex;
     }
 
      ${
        !switchCSSGenerated && names.includes("Switch")
          ? `  
   
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }
   
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
   
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
   
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
   
      input:checked + .slider {
        background-color: #2196F3;
      }
   
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
   
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
   
      .slider.round {
        border-radius: 34px;
      }
   
      .slider.round:before {
        border-radius: 50%;
      }
      `
          : ""
      }
  ${
    !sliderCSSGenerated && names.includes("Slider")
      ? `
  .sliderwidth {
  width:400px;
  }`
      : ""
  }

       ${
         !RadioCSSGenerated && names.includes("Radio")
           ? `  
   
      .check{
  margin-right: 5px;
}
.checkleft{
  margin-left: 5px;
}
      `
           : ""
       }

           ${
             !CheckCSSGenerated && names.includes("Checkbox")
               ? `  
   
      .check{
  margin-left: 5px;
}
.checkleft{
  margin-right: 5px;
}
      `
               : ""
           }

tr{
display : flex;
}
          
  </style>`;

    if (names.includes("Switch")) {
      switchCSSGenerated = true;
    }

    if (names.includes("Slider")) {
      sliderCSSGenerated = true;
    }

    if (names.includes("Radio")) {
      RadioCSSGenerated = true;
    }

    if (names.includes("Checkbox")) {
      CheckCSSGenerated = true;
    }

    let restOfHtmlContent = htmlContent;

    let headerContent = "";
    let footerContent = "";

    if (htmlContent.includes("<header")) {
      const headerStartIndex = htmlContent.indexOf("<header");
      const headerEndIndex =
        htmlContent.indexOf("</header>") + "</header>".length;
      headerContent = htmlContent.substring(headerStartIndex, headerEndIndex);
      restOfHtmlContent =
        htmlContent.substring(0, headerStartIndex) +
        htmlContent.substring(headerEndIndex);
    }

    if (restOfHtmlContent.includes("<footer")) {
      const footerStartIndex = restOfHtmlContent.indexOf("<footer");
      const footerEndIndex =
        restOfHtmlContent.indexOf("</footer>") + "</footer>".length;
      footerContent = restOfHtmlContent.substring(
        footerStartIndex,
        footerEndIndex
      );
      restOfHtmlContent =
        restOfHtmlContent.substring(0, footerStartIndex) +
        restOfHtmlContent.substring(footerEndIndex);
    }

    const bodyHeaderComment = (index) => {
      return `
<!-- Body header ${index} -------->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };
    const theadComment = () => {
      return `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 1 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };

    const closingDiv = `</div></div>`;

    const openingDiv = `<div class="form-group ctable col-sm-12">
    <div class="table-responsive">`;

    const extractTagsAfterHeader = (html) => {
      const headerEndIndex = html.indexOf("</header>");
      if (headerEndIndex !== -1) {
        return html.substring(headerEndIndex + "</header>".length);
      } else {
        return html;
      }
    };

    const tagsAfterHeader = extractTagsAfterHeader(htmlContent);
    let combinedOutput = `\t${bodyHeaderComment(1)}<div id="0.0X" class="childdiv" style="display: flex; flex-direction : column; margin:auto;  background-color :${styleObject.backgroundColor}; background-image:${styleObject.backgroundImage || null}; background-repeat: no-repeat;
 max-width: 900px;
    min-width: 900px;
background-position: center;
${templateArid === 8387 ? `width: 100%;` : `width:${styleObject.width};`};
background-size: cover;min-height: 100vh;">${tagsAfterHeader}`;
    let currentIndex = 0;
    let bodyHeaderIndex = 1;
    while (currentIndex !== -1) {
      const closingTableIndex = tagsAfterHeader.indexOf(
        "</table>",
        currentIndex
      );
      if (closingTableIndex !== -1) {
        const startTableIndex = tagsAfterHeader.lastIndexOf(
          "<table",
          closingTableIndex
        );
        if (startTableIndex !== -1) {
          const tableContent = tagsAfterHeader.substring(
            startTableIndex,
            closingTableIndex + "</table>".length
          );
          combinedOutput = combinedOutput.replace(
            tableContent,
            `${openingDiv}${tableContent}${closingDiv}${bodyHeaderComment(++bodyHeaderIndex)}`
          );
          currentIndex = closingTableIndex + "</table>".length;
        }
      } else {
        if (bodyHeaderIndex < 3) {
          combinedOutput += `\t${bodyHeaderComment(++bodyHeaderIndex)}`;
        } else {
          break;
        }
      }
    }

    let closingTheadIndex = combinedOutput.indexOf("</thead>");

    while (closingTheadIndex !== -1) {
      combinedOutput =
        combinedOutput.slice(0, closingTheadIndex + "</thead>".length) +
        `\n${theadComment(currentIndex)}\n` +
        combinedOutput.slice(closingTheadIndex + "</thead>".length);

      closingTheadIndex = combinedOutput.indexOf(
        "</thead>",
        closingTheadIndex + 1
      );
      currentIndex++;
    }

    const trComment = () => {
      return `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 2 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };

    const innerLoopComment = `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 3 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;

    const footerComment = (index) => {
      return `<!------------------------------------------------------>
<!-- Body Footer ${index} -------->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };

    let footerindex = 1;

    let closingTRIndex = combinedOutput.indexOf("</tr>");

    while (closingTRIndex !== -1) {
      combinedOutput =
        combinedOutput.slice(0, closingTRIndex + "</tr>".length) +
        `\n${trComment(currentIndex)}\n${footerComment(footerindex++)}\n` +
        combinedOutput.slice(closingTRIndex + "</tr>".length);

      closingTRIndex = combinedOutput.indexOf("</tr>", closingTRIndex + 1);
      currentIndex++;
    }

    let theadStartIndex = combinedOutput.indexOf(theadComment());
    let theadEndIndex = combinedOutput.indexOf(trComment());

    while (theadStartIndex !== -1 && theadEndIndex !== -1) {
      let contentBetweenComments = combinedOutput.substring(
        theadStartIndex + theadComment().length,
        theadEndIndex
      );

      let trCommentIndex = combinedOutput.indexOf(trComment(), theadEndIndex);
      if (trCommentIndex !== -1) {
        combinedOutput =
          combinedOutput.slice(0, trCommentIndex + trComment().length) +
          "\n" +
          contentBetweenComments +
          innerLoopComment +
          contentBetweenComments +
          combinedOutput.slice(trCommentIndex + trComment().length);
      }

      theadStartIndex = combinedOutput.indexOf(
        theadComment(),
        trCommentIndex + trComment().length
      );
      theadEndIndex = combinedOutput.indexOf(
        trComment(),
        trCommentIndex + trComment().length
      );
    }

    const sections = combinedOutput.split("<!-- Body header");

    for (let i = 1; i < sections.length; i++) {
      const bodyHeaderIndex = sections[i].indexOf("<!-- GEN <generic>");
      const closingIndex = sections[i].indexOf("-->");

      if (closingIndex !== -1) {
        if (bodyHeaderIndex === -1) {
          const bodyHeader = sections[i].substring(0, closingIndex + 3);
          const afterHeader = sections[i].substring(closingIndex + 3);
          sections[i] = `${bodyHeader}\n<!-- GEN <generic> -->\n${afterHeader}`;
        }

        const afterHeader = sections[i].substring(closingIndex + 3);
        if (!afterHeader.includes("<!-- Inner Query loop 1 --->")) {
          sections[i] +=
            `\r\n${theadComment()}${trComment()}${innerLoopComment}${footerComment(footerindex++)}`;
        }
      }
    }

    combinedOutput = sections.join(
      "<!------------------------------------------------------>\n<!-- Body header"
    );

    const formatAttributes = (attributes) => {
      return attributes.replace(/(\w+="[^"]+")/g, "\r\n$1").trim();
    };

    const footerFormattedContent = footerContent.replace(
      /<footer([^>]*)>/,
      (match, attributes) => {
        const formattedAttributes = formatAttributes(attributes);
        return `<footer\r\n      ${formattedAttributes}>`;
      }
    );

    const headerFormattedContent = headerContent.replace(
      /<header([^>]*)>/,
      (match, attributes) => {
        const formattedAttributes = formatAttributes(attributes);
        return `<header\r\n      ${formattedAttributes}>`;
      }
    );

    const formatTag = (tag, content) => {
      return content.replace(
        new RegExp(`<${tag}([^>]*)>`),
        (match, attributes) => {
          const formattedAttributes = formatAttributes(attributes);
          return `<${tag}\r\n      ${formattedAttributes}>`;
        }
      );
    };

    const formattedTags = {};

    const tagsRegex = /<(\w+)([^>]*)>/g;
    let match;
    while ((match = tagsRegex.exec(restOfHtmlContent)) !== null) {
      const tagName = match[1];
      if (tagName.toLowerCase() === "svg" || tagName.toLowerCase() === "path") {
        continue;
      }
      if (!formattedTags[tagName]) {
        formattedTags[tagName] = [];
      }
      formattedTags[tagName].push(match[0]);
    }

    for (const tagName in formattedTags) {
      const tagInstances = formattedTags[tagName];
      for (const tagInstance of tagInstances) {
        restOfHtmlContent = restOfHtmlContent.replace(
          tagInstance,
          formatTag(tagName, tagInstance)
        );
      }
    }

    const modifyCombinedOutput = () => {
      const trRegex = /(<thead[^>]*>)/g;
      const modifiedWithtr = combinedOutput.replace(trRegex, "$1<tr>");

      const closeTrRegex = /(<\/thead>)/g;
      const modifiedOutput = modifiedWithtr.replace(closeTrRegex, "</tr>$1");

      return modifiedOutput;
    };

    const modifiedCombinedOutput = modifyCombinedOutput(combinedOutput);

    const modifiedOutput = modifiedCombinedOutput.replace(
      /<footer[^>]*>.*?<\/footer>/s,
      ""
    );

    const wrapperDiv = `<div>\n`;

    const filteredLines = modifiedOutput
      .split(/(<\/?[^>]+>|<!--[^>]*?-->)(?![^<>]*<\/generic>)/g)
      .filter(
        (line) =>
          !line.includes("<header") &&
          !line.includes("<footer") &&
          !line.includes("</footer")
      )
      .filter((line) => line.trim() !== "")
      .map((line) => {
        if (line.includes("<!--")) {
          line += "\n";
        }
        if (line.includes("<generic>")) {
          line = line.replace("<generic>", "<generic> -->");
        }
        return line.trim() === "" ? "" : line.trim();
      })
      .join("\n")
      .replace(/<div[^>]+id="0\.2[LR]"[^>]*>[\s\S]*?<\/div>/g, "")
      .replace(/\n+/g, "\n")
      .replace(/(<div[^>]+id="0\.0X"[^>]*>)/, `${wrapperDiv}$1`)
      .replace(/<generic> -->/g, "<generic> -->\n");

    const head = `
    <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>${extractedArrayName}</title>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.0/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css" />
    <script src="https://cdn.jsdelivr.net/npm/flatpickr"></script>
</head>`;

    const body = `\r\n<body style="${
      templateArid === 8387
        ? `background-color: ${styleObject.borderColor}; background-image: ${parentstyleObject.backgroundImage || "none"}; background-position: center;`
        : ""
    }"
>\n<!------------------------------------------------------>\n<!-- Style Definition --->\n<!------------------------------------------------------>${generateStyle}
<!------------------------------------------------------>\n<!-- Page Header -------->\n<!------------------------------------------------------>\n<!-- GEN <generic> -->

<div class="maindiv" style="display: flex; flex-direction: column; background-image:${parentstyleObject.backgroundImage || null};background-repeat: no-repeat;
background-position: center;
${templateArid !== 8387 ? `min-height: 100vh;` : ""}
${templateArid === 8387 ? `width: 80%; margin:auto;` : ""};
 ${templateArid !== 8387 ? `background-color :${styleObject.borderColor};background-image:${parentstyleObject.backgroundImage || null}` : ""};
background-size: cover;" >
${headerFormattedContent}
${filteredLines}
</div>
</div>

<!------------------------------------------------------>
<!-- Page Footer -------->
<!------------------------------------------------------>
<!-- GEN <generic> -->
${footerFormattedContent}
  </div>
 
<!------------------------------------------------------>
<!-- Bottom Page -------->
<!------------------------------------------------------>
</body>
`;

    const htmlFileContent = `<!DOCTYPE html>\r\n<html lang="en">${head}${body}</html>`;
    return htmlFileContent;
  };

  const saveHtmlToFileSystem = async (content, fileName) => {
    try {
      const htmlFileContent = generateHTMLFile(content);
      if (window.showSaveFilePicker) {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: fileName,
          types: [
            {
              description: "HTML Files",
              accept: {
                "text/html": [".html"],
              },
            },
          ],
        });

        const writable = await fileHandle.createWritable({
          keepExistingData: false,
        });

        await writable.write(htmlFileContent);
        await writable.close();

        toast.success("HTML content saved to file system successfully");
      } else {
        const currentDate = new Date().toISOString().replace(/:/g, "-");
        const updatedFileName = `${extractedArrayName}_${currentDate}.html`;

        const blob = new Blob([htmlFileContent], { type: "text/html" });
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.download = updatedFileName;
        anchor.click();
        toast.success("HTML content saved to file system successfully");
      }
    } catch (error) {
      console.error("Error saving HTML content to file system:", error);
      toast.error("Error saving HTML content to file system:", error);
    }
  };

  const handleSaveToFile = async () => {
    await saveHtmlToFileSystem(htmlContent, `${extractedArrayName}.html`);
  };

  const [fieldData, setFieldData] = useState([]);

  const [extractedArrayName, setextractedArrayName] = useState("");
  const [tempNum, setTempNum] = useState();
  const [templateSource, setTemplateSource] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const jsonData = await ReadArray(arId, accessKey);

        if (jsonData) {
          setTreeData(jsonData);

          const arrayName = jsonData[0].arrayName;
          const templateNum = jsonData[0].IsTemplate;
          const dataSourceArID = jsonData[0].dataSourceArID;
          setTempNum(templateNum);
          setextractedArrayName(arrayName);

          const jsonDatanew = await ReadArray(dataSourceArID, accessKey);
          const fetchNewData = jsonDatanew[0].Source;

          setFieldData(fetchNewData);
        } else {
          navigate(`/NotFound?arID=${arId}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [arId]);

  useEffect(() => {
    const arrayFieldNames = fieldData
      ? fieldData
          .filter((item) => item.elementtype === "array")
          .flatMap((item) => item.elements.map((element) => element.fieldname))
      : [];
    setFieldNamestable(arrayFieldNames);
  }, [fieldData]);

  async function fetchDataFromUrl(arId) {
    const jsonData = await ReadArray(arId, accessKey);
    try {
      const formattedData = [
        {
          arid: jsonData[0].arid,
          arrayName: jsonData[0].arrayName,
          description: jsonData[0].description,
          dataSourceArID: jsonData[0].dataSourceArID,
          Source: jsonData[0].Source,
          IsTemplate: jsonData[0].IsTemplate,
          HtmlOutput: jsonData[0].HtmlOutput,
        },
      ];
      return formattedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  const handleGoBack = async () => {
    try {
      const fetchedData = await fetchDataFromUrl(arId);
      // console.log("treeDataBack", JSON.stringify(treeData));
      // console.log("fetchedDataBack", JSON.stringify(fetchedData));

      if (fetchedData) {
        if (JSON.stringify(treeData) !== JSON.stringify(fetchedData)) {
          setOpenConfirmation(true);
        } else {
          Navigate("/Open");
        }
      } else {
        console.log("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };
  const handleGoBackTheme = async () => {
    Navigate("/Themes");
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmGoBack = () => {
    setOpenConfirmation(false);
    Navigate("/Open");
  };

  const handleConfirmGoBackWithSave = () => {
    handleConfirmGoBack();
  };
  const isRootItem = (item, treeData) => {
    return treeData[0].Source[0].id === item.id;
  };

  useEffect(() => {
    setInputValue(inputValue);
  }, []);

  const handleEnterForNumber = (event) => {
    if (event.key === "Enter") {
      handleAgree(event);
    }
  };
  const handleDateChange = (event, timeID) => {
    const newDate = event.target.value;

    const updateTreeData = updateTreeDataDate(treeData, timeID, newDate);
    setTreeData(updateTreeData);

    if (selectedItem && selectedItem.id === timeID) {
      setSelectedItem({
        ...selectedItem,
        data: newDate,
      });
    }
  };

  const updateTreeDataDate = (treeData, timeID, newDate) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsDate(source.control, timeID, newDate),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsDate = (controls, timeID, newDate) => {
    return controls.map((c) => {
      if (c.id === timeID) {
        return {
          ...c,
          data: newDate,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsDate(c.control, timeID, newDate),
        };
      }
      return c;
    });
  };

  const rootStyle =
    treeData.length > 0 &&
    treeData[0].Source && // Check if Source exists
    treeData[0].Source.find((item) => item.type === "Root");

  const [tabValue1, setTabValue1] = useState(0);
  const [tabValue2, setTabValue2] = useState(0);
  const [openpaint, setOpenpaint] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenpaint(newOpen);
    const focusedNode = document.querySelector(".focusingClass");
    const settingWrapper = document.querySelector(".setting-icon-wrapper");
    const DeleteWrapper = document.querySelector(".delete-icon-wrapper");
    if (focusedNode) {
      focusedNode.classList.remove("focusingClass");
    }
    if (settingWrapper) {
      settingWrapper.remove();
      console.log("Setting wrapper removed.");
    } else {
      console.log("No setting wrapper found.");
    }
    if (DeleteWrapper) {
      DeleteWrapper.remove();
      console.log("Delete wrapper removed.");
    } else {
      console.log("No Delete wrapper found.");
    }
    // const prevIcons = document.querySelectorAll(".icon-container");
    // prevIcons.forEach((icon) => icon.remove());
  };

  const handleTabChange = (event, newValue) => {
    setTabValue1(newValue);
  };
  const handleNestedTabChange = (event, newValue) => {
    setTabValue2(newValue);
  };

  let styleObject = {};

  let parentstyleObject = {};

  if (rootStyle && rootStyle.styles) {
    try {
      styleObject = JSON.parse(rootStyle.styles);
    } catch (error) {
      console.error("Error parsing styles:", error);
    }
  }

  if (rootStyle && rootStyle.parentstyles) {
    try {
      parentstyleObject = JSON.parse(rootStyle.parentstyles);
    } catch (error) {
      console.error("Error parsing styles:", error);
    }
  }

  console.log("parentstyleObject", rootStyle);

  const [boderColor, setBoderColor] = useState("#ffffff");

  const [selectedColorScheme, setSelectedColorScheme] = useState(null);

  const rgbToHex = (rgb) => {
    const result = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

    return result
      ? `#${(
          (1 << 24) +
          (parseInt(result[1]) << 16) +
          (parseInt(result[2]) << 8) +
          parseInt(result[3])
        )
          .toString(16)
          .slice(1)
          .toUpperCase()}`
      : rgb === "transparent"
        ? "#000000"
        : rgb;
  };

  function applyInputColorToStylesforInput1(stylesArray) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);
      styles.backgroundColor = "#ffffff";
      setInputColor(styles.backgroundColor);
      updateCustomStyle(style.styleid, styles);
    });
  }

  const handleColorSchemeChange = (scheme) => {
    if (!scheme) return;

    const hexBgColor =
      scheme.backgroundColor === "transparent"
        ? "#00000000"
        : scheme.backgroundColor && scheme.backgroundColor.startsWith("rgb")
          ? rgbToHex(scheme.backgroundColor)
          : scheme.backgroundColor;

    const hexBorderColor =
      scheme.borderColor === "transparent"
        ? "#00000000"
        : scheme.borderColor && scheme.borderColor.startsWith("rgb")
          ? rgbToHex(scheme.borderColor)
          : scheme.borderColor;

    const hexFontColor =
      scheme.color && scheme.color.startsWith("rgb")
        ? rgbToHex(scheme.color)
        : scheme.color;

    setBgColor(hexBgColor);
    setBoderColor(hexBorderColor);
    setFontColor(hexFontColor);

    setSelectedColorScheme({
      ...scheme,
      parentBackgroundColor: hexBorderColor,
    });
  };

  const handleInputChangebgcolor = (e) => {
    const newColor = e.target.value;
    setBoderColor(newColor);
  };

  const [FormWidth, setFormWidth] = useState(100);
  const [verticalSpacing, setVerticalSpacing] = useState("0px");
  const [horizontalSpacing, setHorizontalSpacing] = useState("0px");

  useEffect(() => {
    const rootStyle =
      treeData.length > 0 &&
      treeData[0].Source.find((item) => item.type === "Root");
    const newStyles = JSON.parse(rootStyle && rootStyle.styles);

    if (newStyles) {
      updateCustomStyle("rootStyleId", {
        ...newStyles,
        backgroundColor: bgColor,
        borderColor: boderColor,
        maxWidth: FormWidth,
        paddingTop: verticalSpacing,
        paddingBottom: verticalSpacing,
        paddingLeft: horizontalSpacing,
        paddingRight: horizontalSpacing,
      });
    }
  }, [bgColor, boderColor, FormWidth, verticalSpacing, horizontalSpacing]);

  const [initialContainer, setInitialContainer] = useState(false);

  function collectStylesofcontainer(node, result = []) {
    if (node.styleType && node.styleType === "Container") {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) => collectStylesofcontainer(child, result));
    }

    return result;
  }

  // Assuming treeData is defined and contains your structure
  const stylesofcontainer = collectStylesofcontainer(
    treeData.length > 0 && treeData[0].Source[0]
  );

  function applyFontColorToStylesconatiner(stylesArray, bgColor) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);

      styles.backgroundColor = bgColor;

      updateCustomStyle(style.styleid, styles);
    });
  }

  useEffect(() => {
    if (initialContainer) {
      applyFontColorToStylesconatiner(stylesofcontainer, bgColor);
    }
  }, [bgColor, initialContainer]);

  useEffect(() => {
    const rootStyle =
      treeData.length > 0 &&
      treeData[0].Source.find((item) => item.type === "Root");

    const newStyles = JSON.parse(rootStyle && rootStyle.styles);

    setBgColor(newStyles.backgroundColor);
    setBoderColor(newStyles.borderColor);
    setFormWidth(newStyles.maxWidth);
    setVerticalSpacing(newStyles.paddingTop);
    setVerticalSpacing(newStyles.paddingBottom);
    setHorizontalSpacing(newStyles.paddingLeft);
    setHorizontalSpacing(newStyles.paddingRight);
  }, [rootStyle]);

  // function collectStyleIdsWithStyles(node, result = []) {
  //   if (node.data && node.data !== "") {
  //     result.push({
  //       styleid: node.styleid,
  //       styles: node.styles,
  //     });
  //   }

  //   if (node.control && node.control.length > 0) {
  //     node.control.forEach((child) => collectStyleIdsWithStyles(child, result));
  //   }

  //   return result;
  // }

  function collectStyleIdsWithStyles(node, result = []) {
    if (
      (node.controltype && node.controltype === "Label") ||
      node.controltype === "Date" ||
      node.controltype === "Header" ||
      node.controltype === "Iframe" ||
      node.controltype === "SubHeader" ||
      node.controltype === "List" ||
      node.controltype === "Table" ||
      node.controltype === "TextBox" ||
      node.controltype === "TextArea" ||
      node.controltype === "Checkbox" ||
      node.controltype === "Dropdown" ||
      node.controltype === "Radio" ||
      node.controltype === "Address" ||
      node.controltype === "ShortText" ||
      node.controltype === "LongText" ||
      node.controltype === "FullName" ||
      node.controltype === "MultipleChoice" ||
      node.controltype === "SingleChoice" ||
      node.controltype === "PhoneNumber" ||
      node.controltype === "Email" ||
      node.controltype === "Heading" ||
      node.controltype === "DateBlock"
    ) {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) => collectStyleIdsWithStyles(child, result));
    }

    return result;
  }
  const styleIdsWithStyles = useMemo(() => {
    return collectStyleIdsWithStyles(
      treeData.length > 0 && treeData[0].Source[0]
    );
  }, [treeData]);

  function handleSelectfontfamily(event) {
    const value = event.target.value;
    setFontFamily(value);
  }

  function applyFontColorToStyles(
    stylesArray,
    fontColor,
    FontSize,
    fontFamily
  ) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);

      styles.color = fontColor;
      styles.fontSize = `${FontSize}px`;
      styles.fontFamily = fontFamily;

      updateCustomStyle(style.styleid, styles);
    });
  }

  useEffect(() => {
    if (initialColorSet && initialFontSizeSet) {
      applyFontColorToStyles(
        styleIdsWithStyles,
        fontColor,
        FontSize,
        fontFamily
      );
    }
  }, [fontColor, FontSize, fontFamily]);

  useEffect(() => {
    if (!initialColorSet || !initialFontSizeSet) {
      if (styleIdsWithStyles.length > 0) {
        const firstStyle = JSON.parse(styleIdsWithStyles[0].styles);

        if (!initialColorSet && firstStyle.color) {
          if (fontColor !== firstStyle.color) {
            setFontColor(firstStyle.color);
          }
          setInitialColorSet(true);
        }

        if (!initialFontSizeSet && firstStyle.fontSize) {
          if (FontSize !== parseInt(firstStyle.fontSize, 10)) {
            setFontSize(parseInt(firstStyle.fontSize, 10));
          }
          setInitialFontSizeSet(true);
        }

        if (firstStyle.fontFamily && fontFamily !== firstStyle.fontFamily) {
          setFontFamily(firstStyle.fontFamily);
        }
      }
    }
  }, [
    styleIdsWithStyles,
    initialColorSet,
    initialFontSizeSet,
    fontColor,
    FontSize,
    fontFamily,
  ]);

  function collectStyleIdsWithStylesWidth(node, result = []) {
    if (
      node.controltype &&
      (node.controltype === "Label" ||
        node.controltype === "subHeader" ||
        node.controltype === "Header")
    ) {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStyleIdsWithStylesWidth(child, result)
      );
    }

    return result;
  }
  const [marginTop, setMarginTop] = useState(0);
  const [isInitialWidthSet, setIsInitialWidthSet] = useState(false);
  const [isInitialMarginTopSet, setIsInitialMarginTopSet] = useState(false);

  function collectStyleIdsWithStylesMarginTop(node, result = []) {
    if (
      node.controltype &&
      (node.controltype === "Address" ||
        node.controltype === "ShortText" ||
        node.controltype === "LongText" ||
        node.controltype === "FullName" ||
        node.controltype === "DropDown" ||
        node.controltype === "MultipleChoice" ||
        node.controltype === "SingleChoice" ||
        node.controltype === "PhoneNumber" ||
        node.controltype === "Email" ||
        node.controltype === "Heading" ||
        node.controltype === "DateBlock" ||
        node.controltype === "RatingBlock" ||
        node.media === "AddTempContainer")
    ) {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStyleIdsWithStylesMarginTop(child, result)
      );
    }

    return result;
  }

  const styleIdsWithStylesWidth = collectStyleIdsWithStylesWidth(
    treeData.length > 0 && treeData[0].Source[0]
  );

  const styleIdsWithStylesMarginTop = collectStyleIdsWithStylesMarginTop(
    treeData.length > 0 && treeData[0].Source[0]
  );

  function applyMarginTopToStyles(stylesArray, marginTop) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);

      styles.marginTop = `${marginTop}px`;

      updateCustomStyle(style.styleid, styles);
    });
  }

  useEffect(() => {
    if (!isInitialMarginTopSet && styleIdsWithStylesMarginTop.length > 0) {
      const firstStyle = JSON.parse(styleIdsWithStylesMarginTop[0].styles);

      if (firstStyle.marginTop) {
        const marginTopValue = parseInt(
          firstStyle.marginTop.replace("px", ""),
          10
        );
        setMarginTop(marginTopValue);
        setIsInitialMarginTopSet(true);
      }
    }
  }, [styleIdsWithStylesMarginTop, isInitialMarginTopSet]);

  useEffect(() => {
    if (isInitialMarginTopSet) {
      applyMarginTopToStyles(styleIdsWithStylesMarginTop, marginTop);
    }
  }, [marginTop, isInitialMarginTopSet]);

  function collectStyleIdsWithStylesforInput(node, result = []) {
    if (
      node.htmlTag &&
      (node.htmlTag === "input" || node.htmlTag === "select")
    ) {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStyleIdsWithStylesforInput(child, result)
      );
    }

    return result;
  }

  const styleIdsWithStylesforInput = collectStyleIdsWithStylesforInput(
    treeData.length > 0 && treeData[0].Source[0]
  );

  function applyInputColorToStylesforInput(stylesArray, inputColor) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);
      styles.backgroundColor = inputColor;
      updateCustomStyle(style.styleid, styles);
    });
  }
  useEffect(() => {
    if (window.innerWidth < 1001) {
      handleMenuButtonClick();
    }
  }, []);
  useEffect(() => {
    if (initialInputColorSet) {
      applyInputColorToStylesforInput(styleIdsWithStylesforInput, inputColor);
    }
  }, [inputColor, initialInputColorSet]);

  useEffect(() => {
    if (!initialInputColorSet && styleIdsWithStylesforInput.length > 0) {
      const firstStyle = JSON.parse(styleIdsWithStylesforInput[0].styles);

      if (firstStyle.backgroundColor) {
        setInputColor(firstStyle.backgroundColor);
      }

      setInitialInputColorSet(true);
    }
  }, [styleIdsWithStylesforInput, initialInputColorSet]);

  const [alignMent, setAlignMent] = useState("");

  useEffect(() => {
    if (treeData[0]?.Source?.length > 0) {
      const rootElement = treeData[0].Source[0];

      try {
        const styles = JSON.parse(rootElement.styles);
        const treeAlgnment = styles.alignMent;

        setAlignMent(treeAlgnment);
      } catch (error) {
        console.error("Failed to parse styles:", error);
      }
    } else {
      console.warn("Source array is empty or undefined.");
    }
  }, [treeData]);

  function collectStyleIdsWithStylesforContainer(node, result = []) {
    if (node.htmlTag) {
      if (node.tableHeader === "MainContainer" && node.media !== "HeadingContainer") {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
          type: "MainContainer",
        });
      }

      const positions = [
        "AddressHeader",
        "DropDownLabel",
        "EmailLabel",
        "FullName",
        "LongTextLabel",
        "ShortTextLabel",
        "Checkboxlabel",
        "PhoneNumberLabel",
        "MultipleChoiceLabel",
        "SingleChoiceLabel",
        "DateLabel",
        "RatingLabel",
        "GeneralLabel",
        "ImageLabel",
        "SwitchLabel",
        "IframeLabel",
      ];

      if (positions.includes(node.checkposition)) {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
          type: node.checkposition,
        });
      }
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStyleIdsWithStylesforContainer(child, result)
      );
    }

    return result;
  }

  const styleIdsWithStylesforContainer = collectStyleIdsWithStylesforContainer(
    treeData.length > 0 && treeData[0].Source[0]
  );

  function applyInputColorToStylesforContainer(stylesArray, alignMent) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);

      if (style.type === "MainContainer") {
        switch (alignMent) {
          case "left":
          case "right":
            styles.flexDirection = "row";
            break;
          case "column":
            styles.flexDirection = "column";
            break;
          default:
            break;
        }
      }

      if (
        [
          "AddressHeader",
          "ShortTextLabel",
          "LongTextLabel",
          "checkboxlabel",
          "DropDownLabel",
          "EmailLabel",
          "FullName",
          "PhoneNumberLabel",
          "SingleChoiceLabel",
          "MultipleChoiceLabel",
          "DateLabel",
          "RatingLabel",
          "GeneralLabel",
          "ImageLabel",
          "SwitchLabel",
          "IframeLabel",
        ].includes(style.type)
      ) {
        styles.textAlign = alignMent === "right" ? "right" : "left";
      }

      updateCustomStyle(style.styleid, styles);
    });
  }

  function collectStyleIdsWithStylesWidthRatingInput(node, result = []) {
    if (!node) {
      return result;
    }

    // Check if the node has the media property and it matches "RatingInput"
    if (node.media && node.media === "RatingInput") {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    // Check if the node has controls and iterate over them
    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStyleIdsWithStylesWidthRatingInput(child, result)
      );
    }

    return result;
  }

  function applyflexDireRating(stylesArray) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);

      // Update flexDirection to 'row'
      // styles.flexDirection = "row";

      // Call the function to update custom styles
      updateCustomStyle(style.styleid, styles);
    });
  }

  const handleSelectChange1 = (event) => {
    const value = event.target.value;

    // Check if the selected value is "column" and update styles accordingly
    if (value === "column") {
      const RatingstyleIds = collectStyleIdsWithStylesWidthRatingInput(
        treeData.length > 0 && treeData[0].Source[0]
      );

      applyflexDireRating(RatingstyleIds);
    }

    setAlignMent(value); // Update local state
    const updatedTreeData = [...treeData];
    const rootElement = updatedTreeData[0]?.Source[0];

    try {
      let styles = JSON.parse(rootElement.styles);
      styles.alignMent = value;
      rootElement.styles = JSON.stringify(styles);

      setTreeData(updatedTreeData);
    } catch (error) {
      console.error("Failed to update alignment:", error);
    }
  };

  useEffect(() => {
    applyInputColorToStylesforContainer(
      styleIdsWithStylesforContainer,
      alignMent
    );
  }, [alignMent]);

  const [selectedColor, setSelectedColor] = useState("#1976D2");
  const [selectedgradient, setselectedGradient] = useState("#1976D2");

  const handleButtonClick = (color) => {
    setSelectedColor(color);
  };

  const handleButtonClickGradient = (color) => {
    setselectedGradient(color);
  };

  function collectStyleIdsWithStylesforButton(node, result = []) {
    if (node.htmlTag && node.controltype === "Button") {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStyleIdsWithStylesforButton(child, result)
      );
    }

    return result;
  }

  const styleIdsWithStylesforButton = collectStyleIdsWithStylesforButton(
    treeData.length > 0 && treeData[0].Source[0]
  );

  function applyInputColorToStylesforButton(stylesArray, selectedColor) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);
      styles.backgroundColor = selectedColor;
      delete styles.background;
      updateCustomStyle(style.styleid, styles);
    });
  }

  useEffect(() => {
    applyInputColorToStylesforButton(
      styleIdsWithStylesforButton,
      selectedColor
    );
  }, [selectedColor]);

  function collectStyleIdsWithStylesforgradient(node, result = []) {
    if (node.htmlTag && node.controltype === "Button") {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    if (node.control && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStyleIdsWithStylesforgradient(child, result)
      );
    }

    return result;
  }

  const styleIdsWithStylesforgradient = collectStyleIdsWithStylesforgradient(
    treeData.length > 0 && treeData[0].Source[0]
  );

  function applyInputColorToStylesforgradient(stylesArray, selectedgradient) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);
      styles.backgroundColor = "";
      styles.background = selectedgradient;
      updateCustomStyle(style.styleid, styles);
    });
  }

  useEffect(() => {
    applyInputColorToStylesforgradient(
      styleIdsWithStylesforgradient,
      selectedgradient
    );
  }, [selectedgradient]);

  const hasButtonControl = (data) => {
    const findButton = (controls) => {
      for (const control of controls) {
        if (control.controltype === "Button") return true;
        if (control.control && findButton(control.control)) return true;
      }
      return false;
    };

    return data.some((item) => findButton(item.Source || []));
  };

  const showButtonStyles = hasButtonControl(treeData);

  const handleondragStart = () => {
    const firstItem = treeData[0].Source[0];
    setSelectedNode(firstItem);
    setSelectedItem(firstItem);
    setSelectedControl(firstItem);
    setEditedName(firstItem.name);
    setSelectedItemID(firstItem.id);

    const nodeListfocused = document.querySelectorAll(".focusingClass");
    nodeListfocused.forEach((node) => {
      if (node.id !== firstItem.id) {
        node.classList.remove("focusingClass");
      }
    });

    const firstItemElement = document.getElementById(firstItem.id);
    if (firstItemElement) {
      firstItemElement.classList.add("focusingClass");
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const updatedTreeData = [...treeData];

    if (
      selectedNode &&
      selectedNode.control &&
      selectedNode.control.length > 0
    ) {
      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      if (sourceIndex < selectedNode.control.length) {
        const [movedItem] = selectedNode.control.splice(sourceIndex, 1);
        selectedNode.control.splice(destinationIndex, 0, movedItem);

        setTreeData(updatedTreeData);
      } else {
        console.error("Source index is out of bounds");
      }
    } else {
      console.error("Selected node or its controls not found");
    }
  };

  const Images = [
    {
      Image1:
        "https://files.jotform.com/jufs/beril/form_files/galaxy-bg-image.6405a140a1b097.18087512.png?md5=UODyKOGQCLZs_lPkqUV3_Q&expires=1726133382",
      Image2:
        "https://files.jotform.com/jufs/beril/form_files/ocean-bg.6408a42b157931.17796133.png?md5=7KtjN3OUY8lTzQrOYGtW_A&expires=1726133517",
      Image3:
        "https://files.jotform.com/jufs/beril/form_files/morgane-le-breton-Ym4my-Xj8EY-unsplash%201.6390950a64d8e1.88003745.png?md5=Pz6c4G4BNnA3yLUf4hrBpA&expires=1726133982",
    },
  ];

  const handleImageClick1 = (imageUrl) => {
    const rootStyle =
      treeData.length > 0 &&
      treeData[0].Source.find((item) => item.type === "Root");

    const newStyles = JSON.parse(rootStyle && rootStyle.styles);

    updateCustomStyle("rootStyleId", {
      ...newStyles,
      backgroundImage: `url(${imageUrl})`,
    });
  };

  const handleThemeClick = (theme) => {
    const rootStyle =
      treeData.length > 0 &&
      treeData[0].Source.find((item) => item.type === "Root");

    const parentStyles = JSON.parse(rootStyle && rootStyle.parentstyles);
    const newStyles = JSON.parse(rootStyle && rootStyle.styles);
    setFontColor(theme.fontColor);
    updateCustomStyle("rootStyleId", {
      ...newStyles,
      backgroundColor: theme.backgroundColor,
      color: theme.fontColor,
    });

    updateCustomStyle(
      "rootStyleId",
      {
        ...parentStyles,
        backgroundImage: `url(${theme.backgroundImage})`,
      },
      true
    );
  };

  const handleRemoveBackgroundImage = (event) => {
    event.stopPropagation();
    const rootStyle =
      treeData.length > 0 &&
      treeData[0].Source.find((item) => item.type === "Root");

    const newStyles = JSON.parse(rootStyle && rootStyle.styles);

    const newStyle = {
      ...newStyles,
      backgroundImage: null,
    };
    updateCustomStyle("rootStyleId", newStyle);
  };

  const handleRemoveParentBackgroundImage = (event) => {
    // Find the "Root" item in the treeData
    event.stopPropagation();
    const rootItem =
      treeData.length > 0 &&
      treeData[0].Source.find((item) => item.type === "Root");

    if (!rootItem) return;

    // Parse the parentstyles JSON string
    const parentStyles = JSON.parse(rootItem.parentstyles || "{}");

    // Update the backgroundImage property
    const updatedParentStyles = {
      ...parentStyles,
      backgroundImage: null,
    };

    updateCustomStyle("rootStyleId", updatedParentStyles, true);

    // Update the rootItem's parentstyles property
    rootItem.parentstyles = JSON.stringify(updatedParentStyles);

    // Update the treeData or call any update function if needed
    updateTreeData([...treeData]);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newBackgroundImage = reader.result;
        const newStyles = JSON.parse(rootStyle && rootStyle.styles);

        const newStyle = {
          ...newStyles,
          backgroundImage: `url(${newBackgroundImage})`,
        };
        updateCustomStyle("rootStyleId", newStyle);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadparent = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newBackgroundImage = reader.result;

        // Parse current parentstyles if they exist
        const currentParentStyles = JSON.parse(rootStyle?.parentstyles || "{}");
        // Update parentstyles with the new background image
        const updatedParentStyles = {
          ...currentParentStyles,
          backgroundImage: `url(${newBackgroundImage})`,
        };
        // Update the tree data and apply the style change to parentstyles
        updateCustomStyle("rootStyleId", updatedParentStyles, true);
      };
      reader.readAsDataURL(file);
    }
  };

  const [showColorScheme, setShowColorScheme] = useState(false);
  const [showFormLayout, setShowFormLayout] = useState(false);

  const toggleColorScheme = () => {
    setShowColorScheme(!showColorScheme);
    setShowFormLayout(false);
  };
  const toggleFormLayout = () => {
    setShowColorScheme(false);
    setShowFormLayout(!showFormLayout);
  };

  const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`;

  async function GetQuickInfo(params) {
    try {
      const response = await fetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (response.ok) {
        const resultData = await response.json();

        if (resultData.apiResponse) {
          const apiData = JSON.parse(resultData.apiResponse);

          const template4Data = apiData.filter((item) => item.IsTemplate === 4);
          const arIDs = template4Data.map((item) => item.ArID);
          const htmlOutputs = [];
          for (const arID of arIDs) {
            const htmlOutput = await ReadArray(arID, accessKey);
            console.log(
              `HTML Output for ArID ${arID}:`,
              htmlOutput[0]?.HtmlOutput
            );

            let modifiedHtml = htmlOutput[0]?.HtmlOutput || "";

            const parser = new DOMParser();
            const doc = parser.parseFromString(modifiedHtml, "text/html");

            const elements = doc.querySelectorAll(
              '[styleid="agrfbTemp2Footer"], [styleid="agrfbTemp2Header"]'
            );
            elements.forEach((element) => {
              element.style.height = "20px";
            });

            const headerElements = doc.querySelectorAll(
              '[styleid="agrfbTemp1Head"], [styleid="agrfbTemp1Footer"]'
            );
            headerElements.forEach((headerElement) => {
              headerElement.style.minHeight = "20px";
              headerElement.style.height = "20px";
            });

            const labelElements = doc.querySelectorAll("label[styleid]");
            labelElements.forEach((label) => {
              label.style.fontSize = "5px";
              label.style.marginTop = "0px";
              label.style.marginRight = "0px";
              label.style.marginBottom = "0px";
              label.style.marginLeft = "4px";
              label.style.paddingTop = "0px";
              label.style.paddingRight = "0px";
              label.style.paddingBottom = "0px";
              label.style.paddingLeft = "0px";
              label.style.width = "90%";
            });

            // const elementsforchild = doc.querySelectorAll('[class="childdiv"]');
            // elementsforchild.forEach((element) => {
            //   element.style.height = "100px";
            //   element.style.width = "100px";
            //   element.style.removeProperty("min-height");
            //   element.style.removeProperty("min-width");
            //   element.style.removeProperty("max-width");
            // });

            if (arID === 8388) {
              const elementsForChild =
                doc.querySelectorAll('[class="childdiv"]');
              elementsForChild.forEach((element) => {
                element.style.height = "100px";
                element.style.width = "100px";
                element.style.removeProperty("min-height");
                element.style.removeProperty("max-width");
                element.style.removeProperty("min-width");
              });
            } else {
              const elementsForChild =
                doc.querySelectorAll('[class="childdiv"]');
              elementsForChild.forEach((element) => {
                element.style.height = "100px";
                element.style.width = "100px";
                element.style.removeProperty("min-height");
                element.style.removeProperty("min-width");
                element.style.removeProperty("max-width");
              });
            }

            if (arID === 8388) {
              const elementsformain = doc.querySelectorAll('[class="maindiv"]');
              elementsformain.forEach((element) => {
                element.style.height = "auto";
                element.style.width = "auto";
                element.style.removeProperty("min-height");
              });
            } else {
              const elementsformain = doc.querySelectorAll('[class="maindiv"]');
              elementsformain.forEach((element) => {
                element.style.height = "auto";
                element.style.width = "auto";
                element.style.removeProperty("min-height");
              });
            }

            modifiedHtml = doc.documentElement.outerHTML;

            console.log(`Modified HTML Output for ArID ${arID}:`, modifiedHtml);

            htmlOutputs.push({
              arID,
              html: modifiedHtml,
            });
          }

          setThemehtmlouput(htmlOutputs);
          setThemeArray(template4Data);
        } else {
          console.error("Error: apiResponse is missing");
        }
      } else {
        const errorText = await response.text();
        console.error(
          `Error fetching data: ${response.status} - ${response.statusText}`,
          errorText
        );
      }
    } catch (error) {
      console.error("Error during request:", error);
      toast.error("Error during request.");
    }
  }

  useEffect(() => {
    const params = {
      accessKey: accessKey,
      guid: APIProvider.GUID.ReadArrayAllGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: "",
    };

    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await GetQuickInfo(params);
        if (data) {
          console.log("Extracted Data:", data);
        } else {
          console.log("No data received or data is null.");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleSelect = async (arid, event) => {
    event.preventDefault();

    try {
      const jsonData = await ReadArray(arid, accessKey);
      setThemeSource(jsonData[0].Source);
      if (jsonData) {
        const rootStyle = jsonData[0].Source.find(
          (item) => item.type === "Root"
        );

        const styles = rootStyle ? JSON.parse(rootStyle.styles) : null;
        const pstyles = rootStyle ? JSON.parse(rootStyle.parentstyles) : null;

        const treeDatarootparentStyle = treeData[0].Source.find(
          (item) => item.type === "Root"
        );

        const treeDataparentStyles = treeDatarootparentStyle
          ? JSON.parse(treeDatarootparentStyle.parentstyles)
          : null;

        updateCustomStyle(
          "rootStyleId",
          {
            ...treeDataparentStyles,
            backgroundImage: pstyles?.backgroundImage || "",
          },
          true
        );

        const treeDatarootStyle = treeData[0].Source.find(
          (item) => item.type === "Root"
        );

        const treeDataStyles = treeDatarootStyle
          ? JSON.parse(treeDatarootStyle.styles)
          : null;

        updateCustomStyle("rootStyleId", {
          ...treeDataStyles,
          backgroundImage: styles?.backgroundImage || "",
          backgroundColor: styles?.backgroundColor || "",
          borderColor: styles?.borderColor || "",
        });

        setBoderColor(styles?.borderColor);
        setBgColor(styles?.backgroundColor);

        // Clear previous templateArid
        treeData[0].Source[0].templateArid = null;
        treeData[0].Source[0].templateArid = arid;

        function controlExists(option) {
          return treeData[0].Source[0].control.some((c) => c.option === option);
        }

        function findHeaderContainers(controls) {
          controls.forEach((control) => {
            if (arid === 8388) {
              // Remove old containers for Template1
              const removeContainers = (controls) => {
                for (let i = controls.length - 1; i >= 0; i--) {
                  const control = controls[i];
                  if (
                    [
                      "Temp2Footer",
                      "Temp2Right",
                      "Temp2Left",
                      "Temp2Header",
                    ].includes(control.option)
                  ) {
                    controls.splice(i, 1);
                  }
                  if (control.control && control.control.length > 0) {
                    removeContainers(control.control);
                  }
                }
              };

              // Remove containers from the main structure
              if (treeData.length > 0 && treeData[0].Source.length > 0) {
                removeContainers(treeData[0].Source[0].control);
              }
              setTreeData([...treeData]); // Update state

              // Add Template1 containers if they don't exist
              if (
                control.option === "Template1HeaderContainer" &&
                !controlExists("Template1HeaderContainer")
              ) {
                control.id = "0.1H";
                treeData[0].Source[0].control.unshift(control);
              }

              if (
                control.option === "Template1FooterContainer" &&
                !controlExists("Template1FooterContainer")
              ) {
                control.id = "0.1F";
                treeData[0].Source[0].control.push(control);
              }

              if (
                control.option === "Template1LeftContainer" &&
                !controlExists("Template1LeftContainer")
              ) {
                control.id = "0.2L";
                const parsedStyles = JSON.parse(control.styles);
                parsedStyles.width = `${FormWidth * 0.2}px`;
                control.styles = JSON.stringify(parsedStyles);

                const headerIndex = treeData[0].Source[0].control.findIndex(
                  (c) => c.option === "Template1HeaderContainer"
                );

                if (headerIndex !== -1) {
                  treeData[0].Source[0].control.splice(
                    headerIndex + 1,
                    0,
                    control
                  );
                } else {
                  treeData[0].Source[0].control.push(control);
                }
              }
            }

            if (arid === 8387) {
              // Similar logic for Template2 with duplicate control prevention
              const removeContainers = (controls) => {
                for (let i = controls.length - 1; i >= 0; i--) {
                  const control = controls[i];
                  if (
                    [
                      "Template1HeaderContainer",
                      "Template1LeftContainer",
                      "Template1RightContainer",
                      "Template1FooterContainer",
                    ].includes(control.option)
                  ) {
                    controls.splice(i, 1);
                  }
                  if (control.control && control.control.length > 0) {
                    removeContainers(control.control);
                  }
                }
              };

              if (treeData.length > 0 && treeData[0].Source.length > 0) {
                removeContainers(treeData[0].Source[0].control);
              }
              setTreeData([...treeData]);

              if (
                control.option === "Temp2Footer" &&
                !controlExists("Temp2Footer")
              ) {
                control.id = "0.1F";
                control.htmlTag = "footer";
                control.controlType = "FooterContainer";
                control.styleType = "FooterContainer";
                control.controlChange = "FooterContainer";
                control.type = "FooterContainer";
                treeData[0].Source[0].control.push(control);
              }

              if (
                control.option === "Temp2Left" &&
                !controlExists("Temp2Left")
              ) {
                control.id = "0.2L";
                const parsedStyles = JSON.parse(control.styles);
                parsedStyles.width = `${FormWidth * 0.2}px`;
                control.styles = JSON.stringify(parsedStyles);

                const headerIndex = treeData[0].Source[0].control.findIndex(
                  (c) => c.option === "Temp2Header"
                );

                if (headerIndex !== -1) {
                  treeData[0].Source[0].control.splice(
                    headerIndex + 1,
                    0,
                    control
                  );
                } else {
                  treeData[0].Source[0].control.push(control);
                }
              }

              if (
                control.option === "Temp2Right" &&
                !controlExists("Temp2Right")
              ) {
                control.id = "0.2R";
                const parsedStyles = JSON.parse(control.styles);
                parsedStyles.width = `${FormWidth * 0.2}px`;
                control.styles = JSON.stringify(parsedStyles);

                const footerIndex = treeData[0].Source[0].control.findIndex(
                  (c) => c.option === "Temp2Footer"
                );

                if (footerIndex !== -1) {
                  treeData[0].Source[0].control.splice(footerIndex, 0, control);
                } else {
                  treeData[0].Source[0].control.push(control);
                }
              }

              if (
                control.option === "Temp2Header" &&
                !controlExists("Temp2Header")
              ) {
                control.id = "0.1H";
                control.htmlTag = "header";
                control.controlType = "HeaderContainer";
                control.styleType = "HeaderContainer";
                control.controlChange = "HeaderContainer";
                control.type = "HeaderContainer";

                // const parsedStyles = JSON.parse(control.styles);
                // parsedStyles.maxWidth = `${FormWidth}px`;
                // control.styles = JSON.stringify(parsedStyles);

                treeData[0].Source[0].control.unshift(control);
              }
            }

            if (control.control && control.control.length > 0) {
              findHeaderContainers(control.control);
            }
          });
        }

        if (jsonData.length > 0 && jsonData[0].Source) {
          findHeaderContainers(jsonData[0].Source);
        } else {
          console.log("No data found in Source.");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const elementRef = useRef(null);

  const handleRemove = () => {
    if (treeData.length > 0 && treeData[0].Source.length > 0) {
      treeData[0].Source[0].templateArid = "";
    }

    const treeDatarootStyle = treeData[0].Source.find(
      (item) => item.type === "Root"
    );
    const treeDataparentStyles = treeDatarootStyle
      ? JSON.parse(treeDatarootStyle.parentstyles)
      : null;

    updateCustomStyle(
      "rootStyleId",
      {
        ...treeDataparentStyles,
        backgroundImage: null,
        // backgroundColor : null,
        // borderColor : null,
      },
      true
    );

    const treeDataStyles = treeDatarootStyle
      ? JSON.parse(treeDatarootStyle.styles)
      : null;

    updateCustomStyle("rootStyleId", {
      ...treeDataStyles,
      backgroundImage: null,
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
    });

    setBoderColor("#ffffff");
    setBgColor("#ffffff");

    const removeContainers = (controls) => {
      for (let i = controls.length - 1; i >= 0; i--) {
        const control = controls[i];

        if (
          control.option === "Template1HeaderContainer" ||
          control.option === "Template1LeftContainer" ||
          control.option === "Template1RightContainer" ||
          control.option === "Template1FooterContainer" ||
          control.option === "Temp2Footer" ||
          control.option === "Temp2Right" ||
          control.option === "Temp2Left" ||
          control.option === "Temp2Header"
        ) {
          controls.splice(i, 1);
        }

        if (control.control && control.control.length > 0) {
          removeContainers(control.control);
        }
      }
    };
    if (treeData.length > 0 && treeData[0].Source.length > 0) {
      removeContainers(treeData[0].Source[0].control);
    }

    setTreeData([...treeData]);
  };

  const nodeListfocused = document.querySelectorAll(".focusingClass")[0];

  const handlePreViewBack = () => {
    const firstItem = selectedNode.id || "";
    setSelectedNode(firstItem);
    setSelectedItem(firstItem);
    setSelectedItemID(firstItem.id);
    setSelectedControl(firstItem.type);
    const firstItem1 = treeData[0].Source[0];
    setSelectedNode(firstItem1);
    setSelectedItem(firstItem1);
    setSelectedControl(firstItem1);
    setEditedName(firstItem1.name);
    setSelectedItemID(firstItem1.id);
    setControlsVisible(true);
    if (nodeListfocused) {
      nodeListfocused.classList.remove("focusingClass");
    }
  };

  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!location?.state) {
      navigate("/", { replace: true });
      sessionStorage.clear();
    } else {
      setIsAuthorized(true);
    }
  }, [location, navigate]);

  const fetchData = useCallback(async () => {
    try {
      const jsonData = await ReadArray(10, accessKey);
      const fetchedData = jsonData[0].Source;
      setReadArrayStyles(fetchedData);

      const styledDataWithIds = fetchedData.map((style, index) => ({
        id: index + 1,
        ...style,
      }));

      setDefaultStyle(styledDataWithIds);
    } catch (error) {
      console.error("Error during request:", error);
      toast.error("Error during request.");
    }
  }, [accessKey]);

  useEffect(() => {
    // Only fetch data if the user is authorized
    if (isAuthorized) {
      fetchData();
    }
  }, [isAuthorized, fetchData]);

  const arrayName =
    location?.state?.ArrayName || location?.state?.arrayName || "";
  const description =
    location?.state?.Description || location?.state?.description || "";

  const category = location?.state?.Category || location?.state?.category || "";

  const dataSourceArID =
    location?.state?.DataSourceArID || location?.state?.dataSourceArID || "";

  const isTemplate = "1";

  async function onSaveClick(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);
      let isValid = true;

      function validateControls(controls) {
        controls.forEach((controlItem) => {
          if (controlItem.tab === "Phone Number") {
            const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            if (!phoneNumberRegex.test(controlItem.data)) {
              isValid = false;
              console.error("Invalid phone number:", controlItem.data);
              toast.error("Invalid phone number");
            }
          } else if (controlItem.tab === "Email") {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!emailRegex.test(controlItem.data)) {
              isValid = false;
              console.error("Invalid email address:", controlItem.data);
              toast.error("Invalid email address");
            }
          }

          if (controlItem.control && controlItem.control.length > 0) {
            validateControls(controlItem.control);
          }
        });
      }

      function traverseAndValidate(data) {
        data.forEach((item) => {
          item.Source.forEach((sourceItem) => {
            validateControls(sourceItem.control);
          });
        });
      }

      traverseAndValidate(treeData);

      if (isRequiredFullName) {
        if (editedDataFirstNameTextbox.trim() === "") {
          isValid = false;
          toast.error("First Name is required");
          console.error("First Name is required");
        }
        if (editedDataLastNameTextbox.trim() === "") {
          isValid = false;
          toast.error("Last Name is required");
          console.error("Last Name is required");
        }
      }

      if (isValid) {
        const response = await WriteArray(
          treeData,
          arId,
          arrayName,
          description,
          category,
          htmlFileContent,
          dataSourceArID,
          accessKey
        );

        if (response.ok) {
          console.log("Data saved successfully");

          if (showToast) toast.success("Changes saved successfully");
        } else {
          console.error("Failed to save data to the backend");

          if (showToast) toast.error("Failed to save data to the backend");
        }
      }
    } catch (error) {
      console.error("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data");
    }
  }

  async function onSaveClick1(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);
      let isValid = true;

      treeData.forEach((item) => {
        item.Source.forEach((sourceItem) => {
          sourceItem.control.forEach((controlItem) => {
            if (controlItem.tab === "Phone Number") {
              const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
              if (!phoneNumberRegex.test(controlItem.data)) {
                isValid = false;
                console.error("Invalid phone number:", controlItem.data);
                toast.error("Invalid phone number");
              }
            } else if (controlItem.tab === "Email") {
              const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
              if (!emailRegex.test(controlItem.data)) {
                isValid = false;
                console.error("Invalid email address:", controlItem.data);
                toast.error("Invalid email address");
              }
            }
          });
        });
      });

      if (isValid) {
        const response = await WriteArray1(
          treeData,
          arId,
          arrayName,
          description,
          category,
          htmlFileContent,
          dataSourceArID,
          accessKey
        );

        if (response.ok) {
          console.log("Data saved successfully");

          if (showToast) toast.success("Changes saved successfully");
        } else {
          console.error("Failed to save data to the backend");

          if (showToast) toast.error("Failed to save data to the backend");
        }
      }
    } catch (error) {
      console.error("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data:");
    }
  }

  async function onSaveClickTheme(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);
      let isValid = true;

      function validateControls(controls) {
        controls.forEach((controlItem) => {
          if (controlItem.tab === "Phone Number") {
            const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            if (!phoneNumberRegex.test(controlItem.data)) {
              isValid = false;
              console.error("Invalid phone number:", controlItem.data);
              toast.error("Invalid phone number");
            }
          } else if (controlItem.tab === "Email") {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!emailRegex.test(controlItem.data)) {
              isValid = false;
              console.error("Invalid email address:", controlItem.data);
              toast.error("Invalid email address");
            }
          }

          if (controlItem.control && controlItem.control.length > 0) {
            validateControls(controlItem.control);
          }
        });
      }

      function traverseAndValidate(data) {
        data.forEach((item) => {
          item.Source.forEach((sourceItem) => {
            validateControls(sourceItem.control);
          });
        });
      }

      traverseAndValidate(treeData);

      if (isRequiredFullName) {
        if (editedDataFirstNameTextbox.trim() === "") {
          isValid = false;
          toast.error("First Name is required");
          console.error("First Name is required");
        }
        if (editedDataLastNameTextbox.trim() === "") {
          isValid = false;
          toast.error("Last Name is required");
          console.error("Last Name is required");
        }
      }

      if (isValid) {
        const response = await WriteArrayTheme(
          treeData,
          arId,
          arrayName,
          description,
          category,
          htmlFileContent,
          dataSourceArID,
          accessKey
        );

        if (response.ok) {
          console.log("Data saved successfully");

          if (showToast) toast.success("Changes saved successfully");
        } else {
          console.error("Failed to save data to the backend");

          if (showToast) toast.error("Failed to save data to the backend");
        }
      }
    } catch (error) {
      console.error("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data");
    }
  }

  const templateArid = treeData[0]?.Source[0]?.templateArid;

  // const contentRefs = useRef({});

  // const getRefForId = (id) => {
  //   if (!contentRefs.current[id]) {
  //     contentRefs.current[id] = React.createRef();
  //   }
  //   return contentRefs.current[id];
  // };

  function collectControlTypes(data, optionValue) {
    let controlTypes = [];

    function traverse(node) {
      if (!node) return; // Safety check to avoid accessing properties of undefined/null

      // Check if the current node's option matches
      if (node.option === optionValue) {
        // Start collecting all "controltype" and their corresponding "id" recursively
        function collectAllControlTypes(controlNode) {
          if (!controlNode) return; // Safety check
          if (controlNode.controltype) {
            controlTypes.push({
              controltype: controlNode.controltype,
              id: controlNode.id,
            });
          }
          if (controlNode.control && controlNode.control.length > 0) {
            controlNode.control.forEach(collectAllControlTypes);
          }
        }
        if (node.control && node.control.length > 0) {
          node.control.forEach(collectAllControlTypes);
        }
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach(traverse);
      }
    }

    traverse(data);

    console.log("Collected Control Types and IDs:", controlTypes);

    return controlTypes;
  }

  const collectedTypes = collectControlTypes(
    treeData?.[0]?.Source?.[0],
    "Template1HeaderContainer"
  );
  console.log("Collected Types with IDs:", collectedTypes);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="containertopsection">
          {controlsVisible && (
            <FormTopSection
              searchQuery={searchQuery}
              handleSearch={handleSearch}
              handleControls={handleControls}
              handleMenuButtonClick={handleMenuButtonClick}
              handleTabshwButtonClick={handleTabshwButtonClick}
              onSaveClick={onSaveClick}
              handleSaveToFile={handleSaveToFile}
              handleGoBack={handleGoBack}
              openConfirmation={openConfirmation}
              handleCloseConfirmation={handleCloseConfirmation}
              handleConfirmGoBackWithSave={handleConfirmGoBackWithSave}
              treeData={treeData}
              renderControl={renderControl}
              arId={arId}
              setControlsVisible={setControlsVisible}
              controlsVisible={controlsVisible}
              onSaveClick1={onSaveClick1}
              templateSource={templateSource}
              tempNum={tempNum}
              dataSourceArID={dataSourceArID}
              category={category}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              setSelectedItemID={setSelectedItemID}
              setSelectedItem={setSelectedItem}
              htmlFileContent={generateHTMLFile(htmlContent)}
              selectedItem={selectedItem}
              setEditedName={setEditedName}
              setSelectedControl={setSelectedControl}
              showTheme={showTheme}
              onSaveClickTheme={onSaveClickTheme}
              handleGoBackTheme={handleGoBackTheme}
              authData={authData}
            />
          )}

          <div
            className="bg-img container-scrol-icon"
            style={{
              backgroundImage: parentstyleObject.backgroundImage,
              backgroundColor: boderColor,
              ...(templateArid === 8387 && { overflow: "auto" }),
            }}
          >
            {showTheme && (
              <div
                className="prnt-header"
                style={{
                  // display: "flex",
                  // flexDirection: "column",
                  zIndex: "3",
                }}
              >
                {/* {treeData.map((item) => {
                  return item.Source[0].control
                    .filter(
                      (control) => control.option === "Template1HeaderContainer"
                    )
                    .map((headerControl) => renderControl(headerControl));
                })} */}
                {treeData.map((item) => {
                  return item.Source[0].control
                    .filter(
                      (control) => control.option === "Template1HeaderContainer"
                    )
                    .map((headerControl) => {
                      const isHeader =
                        headerControl.option === "Template1HeaderContainer";
                      const renderedControl = renderControl(headerControl);
                      if (isHeader) {
                        const iconContainer = document.getElementById(
                          `iconContainer-${headerControl.id}`
                        );
                        if (iconContainer) {
                          const element = document.getElementById(
                            headerControl.id
                          );
                          if (element) {
                            const rect = element.getBoundingClientRect();
                            iconContainer.style.top = `${rect.bottom + window.scrollY + 10}px`;
                            iconContainer.style.left = `${rect.left + window.scrollX + rect.width / 2 - iconContainer.offsetWidth / 2}px`;
                            iconContainer.style.zIndex = "4";
                            iconContainer.style.marginTop = "-9px";
                            // iconContainer.style.flexDirection = "initial";
                          }
                        }
                      }

                      return renderedControl;
                    });
                })}
              </div>
            )}

            {loading ? (
              <CircularProgress
                style={{
                  position: "relative",
                  minHeight: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            ) : (
              <div
                className={
                  "container-top" +
                  (controlsVisible && !showmenu ? " lftpanelopened" : "") +
                  (showmenu ? " leftpanel" : "")
                }
                // style={{
                //   backgroundImage: parentstyleObject.backgroundImage,
                //   backgroundColor: boderColor,
                // }}
              >
                {controlsVisible && (
                  <div
                    id="sidebar"
                    className={
                      "sidebar" +
                      (showmenu ? " show" : "") +
                      (showmenudsk ? " showdsk" : "")
                    }
                  >
                    {controlsVisible && (
                      <div className="sidebardata">
                        <DialogComponent
                          open={openDialog}
                          onClose={() => setOpenDialog(false)}
                          handleAddControl={handleAddControl}
                          searchQuery={searchQuery}
                          filterTreeData={filterTreeData}
                          setContainerIcon={setContainerIcon}
                          ContainerIcon={ContainerIcon}
                          setInputClicked={setInputClicked}
                          InputClicked={InputClicked}
                          setDisplayClicked={setDisplayClicked}
                          displayClicked={displayClicked}
                          setIconClicked={setIconClicked}
                          IconsClicked={IconsClicked}
                          DataClicked={DataClicked}
                          setDataClicked={setDataClicked}
                          onDragStart={onDragStart}
                          onDragEnd={onDragEnd}
                          showTheme={showTheme}
                          authData={authData}
                          selectedItem={selectedItem}
                        />
                      </div>
                    )}
                    {controlsVisible && (
                      <div className="treeitem">
                        {treeData.length > 0 && (
                          <DragDropContext>
                            <Droppable droppableId="tree" type="CONTROL">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {treeData[0].Source &&
                                  treeData[0].Source.length > 0 &&
                                  treeData[0].Source &&
                                  filterTreeData(
                                    treeData[0].Source,
                                    searchQuery
                                  ).length > 0 ? (
                                    filterTreeData(
                                      treeData[0].Source,
                                      searchQuery
                                    ).map((item, index) => {
                                      const uniqueKey = `${item.type}-${index}`;
                                      return (
                                        <TreeItem
                                          key={uniqueKey}
                                          item={item}
                                          addItem={handleAddItem}
                                          selectedNode={selectedNode}
                                          setSelectedNode={setSelectedNode}
                                          index={index}
                                          treeData={treeData}
                                          setTreeData={setTreeData}
                                          handleSelectItem={handleSelectItem}
                                          setIconsforFocus={setIconsforFocus}
                                          selectedItem={selectedItem}
                                          setEditedName={setEditedName}
                                          setSelectedItem={setSelectedItem}
                                          setSelectedControl={
                                            setSelectedControl
                                          }
                                          setSelectedItemID={setSelectedItemID}
                                          setSelectedOptioncheck={
                                            setSelectedOptioncheck
                                          }
                                          setSelectedOptionSwitchcheck={
                                            setSelectedOptionSwitchcheck
                                          }
                                          setSelectedPositioncheck={
                                            setSelectedPositioncheck
                                          }
                                          editedName={editedName}
                                          setEditedData={setEditedData}
                                          setSelectedOption={setSelectedOption}
                                          settabSelect={settabSelect}
                                          setTableHeader={setTableHeader}
                                          setSourceData={setSourceData}
                                          setSelectedControlType={
                                            setSelectedControlType
                                          }
                                          setIsOptionSelected={
                                            setIsOptionSelected
                                          }
                                          isOptionSelected={isOptionSelected}
                                          settableProp={settableProp}
                                          authData={authData}
                                        />
                                      );
                                    })
                                  ) : (
                                    <p
                                      style={{
                                        textAlignLast: "center",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      No Results
                                    </p>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {controlsVisible && (
                  <div
                    className={
                      "tgl-menu-panel" + (showmenudsk ? " reverticn" : "")
                    }
                    onClick={handleMenushwDskButtonClick}
                  >
                    <Tooltip
                      id="tooltipstyleLft"
                      arrow
                      title={
                        <span className="tooltipstyleLft">
                          Show/Hide left panel
                        </span>
                      }
                      placement="right-end"
                    >
                      {Theme.ChevronRight}
                    </Tooltip>
                  </div>
                )}

                {showTheme && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {treeData.map((item) => {
                      return item.Source[0].control
                        .filter(
                          (control) =>
                            control.option === "Template1LeftContainer" ||
                            control.option === "Temp2Left"
                        )
                        .map((headerControl) => renderControl(headerControl));
                    })}
                  </div>
                )}

                <div
                  className="middle-section container-scrol-icon"
                  id="0"
                  styleid="rootStyleId"
                  style={{
                    width: styleObject.width,
                    maxWidth: `${styleObject.maxWidth}px`,
                    // minWidth: `${styleObject.minWidth}px`,
                    margin: "auto",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    boxShadow: "0 0 8px #00000040",
                    ...(templateArid !== 8387 && { overflow: "auto" }),
                  }}
                >
                  <div className="temp-sec">
                    {showTheme && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {treeData.map((item) => {
                          return item.Source[0].control
                            .filter(
                              (control) => control.option === "Temp2Header"
                            )
                            .map((headerControl) =>
                              renderControl(headerControl)
                            );
                        })}
                      </div>
                    )}

                    <div
                      className="frst-prnt-cont container-scrol-icon"
                      styleid="rootStyleId"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: styleObject.backgroundColor,
                        backgroundImage: styleObject.backgroundImage,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        paddingLeft: styleObject.paddingLeft,
                        paddingBottom: styleObject.paddingBottom,
                        paddingRight: styleObject.paddingRight,
                        paddingTop: styleObject.paddingTop,
                      }}
                    >
                      {treeData
                        .filter(
                          (control) =>
                            !showTheme ||
                            (control.controltype !== "HeaderContainer" &&
                              control.option !== "Template1HeaderContainer" &&
                              control.option !== "Template1FooterContainer" &&
                              control.option !== "Template1LeftContainer" &&
                              control.option !== "Template1RightContainer" &&
                              control.option !== "Temp2Right" &&
                              control.option !== "Temp2Left" &&
                              control.option !== "Temp2Footer" &&
                              control.option !== "Temp2Header")
                        )
                        .map((item) => {
                          if (!controlsVisible) {
                            const filteredFields = fieldData
                              ? fieldData.filter(
                                  (field) =>
                                    field.fieldtype === "text" ||
                                    field.fieldtype === "datetime" ||
                                    field.fieldtype === "date" ||
                                    field.fieldtype === "time" ||
                                    field.fieldtype === "Image" ||
                                    field.fieldtype === "Iframe" ||
                                    field.fieldtype === "boolean" ||
                                    field.fieldtype === "number"
                                )
                              : [];

                            const filterTable = fieldData.filter(
                              (field) =>
                                field.elementtype === "array" &&
                                field.type === "table"
                            );

                            const processControl = (control) => {
                              const hasCurlyBrackets = (str) =>
                                /{.*?}/.test(str);

                              const getCleanedData = (data) => {
                                return data.replace(/{|}/g, "").toLowerCase();
                              };

                              const replaceDataIfNeeded = (
                                data,
                                matchingField
                              ) => {
                                return hasCurlyBrackets(data) && matchingField
                                  ? matchingField.fieldvalue
                                  : data;
                              };

                              if (
                                control.type === "TextBox" ||
                                control.type === "TextArea" ||
                                control.type === "Header" ||
                                control.type === "SubHeader" ||
                                control.type === "Checkbox" ||
                                control.type === "Radio" ||
                                control.htmlTag === "td" ||
                                control.type === "Label"
                              ) {
                                const cleanedData = getCleanedData(
                                  control.data
                                );
                                let matchingField;

                                if (control.type !== "Table") {
                                  matchingField = filteredFields.find(
                                    (field) =>
                                      field.fieldname.toLowerCase() ===
                                      cleanedData
                                  );
                                } else {
                                  matchingField = filterTable[0]?.elements.find(
                                    (field) =>
                                      field.fieldname.toLowerCase() ===
                                      cleanedData
                                  );
                                }

                                return {
                                  ...control,
                                  data: replaceDataIfNeeded(
                                    control.data,
                                    matchingField
                                  ),
                                };
                              } else if (control.type === "Date") {
                                const cleanedData = getCleanedData(
                                  control.inputText
                                );
                                let matchingField;

                                if (control.type !== "Table") {
                                  matchingField = filteredFields.find(
                                    (field) =>
                                      field.fieldname.toLowerCase() ===
                                      cleanedData
                                  );
                                } else {
                                  matchingField = filterTable[0]?.elements.find(
                                    (field) =>
                                      field.fieldname.toLowerCase() ===
                                      cleanedData
                                  );
                                }

                                return {
                                  ...control,
                                  inputText: replaceDataIfNeeded(
                                    control.inputText,
                                    matchingField
                                  ),
                                };
                              } else if (
                                control.type === "Image" ||
                                control.type === "Iframe" ||
                                control.type === "Switch"
                              ) {
                                const cleanedData = getCleanedData(
                                  control.imageText
                                );
                                let matchingField;

                                if (control.type !== "Table") {
                                  matchingField = filteredFields.find(
                                    (field) =>
                                      field.fieldname.toLowerCase() ===
                                      cleanedData
                                  );
                                } else {
                                  matchingField = filterTable[0]?.elements.find(
                                    (field) =>
                                      field.fieldname.toLowerCase() ===
                                      cleanedData
                                  );
                                }

                                return {
                                  ...control,
                                  imageText: replaceDataIfNeeded(
                                    control.imageText,
                                    matchingField
                                  ),
                                };
                              } else {
                                return control;
                              }
                            };

                            const processControls = (controls) => {
                              return controls
                                .filter(
                                  (control) =>
                                    !showTheme ||
                                    (control.controltype !==
                                      "HeaderContainer" &&
                                      control.option !==
                                        "Template1HeaderContainer" &&
                                      control.option !==
                                        "Template1FooterContainer" &&
                                      control.option !==
                                        "Template1LeftContainer" &&
                                      control.option !== "Temp2Footer" &&
                                      control.option !== "Temp2Header" &&
                                      control.option !== "Temp2Right" &&
                                      control.option !== "Temp2Left" &&
                                      control.option !==
                                        "Template1RightContainer")
                                )
                                .map((control) => {
                                  if (
                                    control.control &&
                                    control.control.length > 0
                                  ) {
                                    const processedNestedControls =
                                      processControls(control.control);

                                    return {
                                      ...control,
                                      control: processedNestedControls
                                        .filter(
                                          (control) =>
                                            control.option !==
                                              "Template1HeaderContainer" &&
                                            control.option !==
                                              "Template1FooterContainer" &&
                                            control.option !== "Temp2Footer" &&
                                            control.option !== "Temp2Header" &&
                                            control.option !==
                                              "Template1LeftContainer" &&
                                            control.option !== "Temp2Right" &&
                                            control.option !== "Temp2Left" &&
                                            control.option !==
                                              "Template1RightContainer"
                                        )
                                        .map((nestedControl) => {
                                          if (
                                            nestedControl.type === "Date" &&
                                            nestedControl.inputText
                                          ) {
                                            const dateParts =
                                              nestedControl.inputText.split(
                                                "/"
                                              );
                                            if (dateParts.length === 3) {
                                              const month =
                                                dateParts[0].padStart(2, "0");
                                              const day = dateParts[1].padStart(
                                                2,
                                                "0"
                                              );
                                              const year = dateParts[2];
                                              const formattedDate = `${year}-${month}-${day}`;
                                              nestedControl.data =
                                                formattedDate;
                                              nestedControl.inputType = "date";
                                              nestedControl.inputText = "";
                                            } else {
                                              console.error(
                                                "Invalid date format:",
                                                nestedControl.inputText
                                              );
                                            }
                                          } else if (
                                            nestedControl.type === "Image" &&
                                            nestedControl.imageText
                                          ) {
                                            nestedControl.media =
                                              nestedControl.imageText;
                                            nestedControl.imageText = "";
                                          } else if (
                                            nestedControl.type === "Iframe" &&
                                            nestedControl.imageText
                                          ) {
                                            nestedControl.data =
                                              nestedControl.imageText;
                                            nestedControl.imageText = "";
                                          } else if (
                                            nestedControl.type === "Checkbox" &&
                                            (nestedControl.data === "true" ||
                                              nestedControl.data === "false" ||
                                              nestedControl.data === 0 ||
                                              nestedControl.data === 1)
                                          ) {
                                            nestedControl.check =
                                              nestedControl.data === "true" ||
                                              nestedControl.data === 1;
                                          } else if (
                                            nestedControl.type === "Radio" &&
                                            (nestedControl.data === "true" ||
                                              nestedControl.data === "false" ||
                                              nestedControl.data === 0 ||
                                              nestedControl.data === 1)
                                          ) {
                                            nestedControl.check =
                                              nestedControl.data === "true" ||
                                              nestedControl.data === 1;
                                          } else if (
                                            nestedControl.type === "Switch" &&
                                            nestedControl.imageText
                                          ) {
                                            nestedControl.switchcheck =
                                              nestedControl.imageText ===
                                                "true" ||
                                              nestedControl.data === 1;

                                            nestedControl.imageText = "";
                                          } else if (
                                            nestedControl.type === "Radio" &&
                                            nestedControl.imageText
                                          ) {
                                            nestedControl.check =
                                              nestedControl.imageText ===
                                                "true" ||
                                              nestedControl.data === 1;

                                            nestedControl.imageText = "";
                                          }
                                          return nestedControl;
                                        }),
                                    };
                                  } else {
                                    return processControl(control);
                                  }
                                });
                            };

                            const processedControls = item.Source.filter(
                              (control) =>
                                !showTheme ||
                                (control.controltype !== "HeaderContainer" &&
                                  control.option !==
                                    "Template1HeaderContainer" &&
                                  control.option !==
                                    "Template1FooterContainer" &&
                                  control.option !== "Temp2Footer" &&
                                  control.option !== "Temp2Header" &&
                                  control.option !== "Template1LeftContainer" &&
                                  control.option !== "Temp2Right" &&
                                  control.option !== "Temp2Left" &&
                                  control.option !== "Template1RightContainer")
                            ).map((source) => {
                              return {
                                ...source,
                                control: processControls(source.control),
                              };
                            });
                            return processedControls.map((source) =>
                              source.control.map((control) => {
                                if (
                                  control.type === "Date" &&
                                  control.inputText
                                ) {
                                  const dateParts =
                                    control.inputText.split("/");
                                  if (dateParts.length === 3) {
                                    const month = dateParts[0].padStart(2, "0");
                                    const day = dateParts[1].padStart(2, "0");
                                    const year = dateParts[2];
                                    const formattedDate = `${year}-${month}-${day}`;
                                    control.data = formattedDate;
                                    control.inputType = "date";
                                    control.inputText = "";
                                  } else {
                                    console.error(
                                      "Invalid date format:",
                                      control.inputText
                                    );
                                  }
                                } else if (
                                  control.type === "Image" &&
                                  control.imageText
                                ) {
                                  control.media = control.imageText;
                                  control.imageText = "";
                                } else if (
                                  control.type === "Iframe" &&
                                  control.imageText
                                ) {
                                  control.data = control.imageText;
                                  control.imageText = "";
                                } else if (
                                  control.type === "Switch" &&
                                  control.imageText
                                ) {
                                  control.switchcheck =
                                    control.imageText === "true" ||
                                    control.imageText === 1;
                                  control.imageText = "";
                                } else if (
                                  control.type === "Checkbox" &&
                                  (control.data === "true" ||
                                    control.data === "false" ||
                                    control.data === 0 ||
                                    control.data === 1)
                                ) {
                                  control.check =
                                    control.data === "true" ||
                                    control.data === 1;
                                } else if (
                                  control.type === "Radio" &&
                                  control.data
                                ) {
                                  control.check =
                                    control.data === "true" ||
                                    control.data === 1;
                                }
                                return renderControl(control);
                              })
                            );
                          } else {
                            return (
                              <DragDropContext
                                onDragStart={handleondragStart}
                                onDragEnd={handleOnDragEnd}
                              >
                                <Droppable droppableId="controls">
                                  {(provided) => (
                                    <div
                                      className="parentOfControls"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {item.Source[0].control
                                        .filter(
                                          (control) =>
                                            !showTheme ||
                                            (control.controltype !==
                                              "HeaderContainer" &&
                                              control.option !==
                                                "Template1HeaderContainer" &&
                                              control.option !==
                                                "Template1FooterContainer" &&
                                              control.option !==
                                                "Temp2Footer" &&
                                              control.option !==
                                                "Temp2Header" &&
                                              control.option !==
                                                "Template1LeftContainer" &&
                                              control.option !== "Temp2Right" &&
                                              control.option !== "Temp2Left" &&
                                              control.option !==
                                                "Template1RightContainer")
                                        )
                                        .map((control, index) => (
                                          <Draggable
                                            key={control.id || index}
                                            draggableId={`draggable-${control.id || index}`}
                                            index={index}
                                          >
                                            {(provided) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  ...provided.draggableProps
                                                    .style,
                                                }}
                                              >
                                                {renderControl(control)}
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            );
                          }
                        })}
                    </div>

                    {showTheme && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {treeData.map((item) => {
                          return item.Source[0].control
                            .filter(
                              (control) => control.option === "Temp2Footer"
                            )
                            .map((headerControl) =>
                              renderControl(headerControl)
                            );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                {showTheme && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {treeData.map((item) => {
                      return item.Source[0].control
                        .filter(
                          (control) =>
                            control.option === "Template1RightContainer" ||
                            control.option === "Temp2Right"
                        )
                        .map((headerControl) => renderControl(headerControl));
                    })}
                  </div>
                )}

                {controlsVisible && (
                  <div
                    className={
                      "tgl-right-panel " + (showtabdsk ? "reverticn" : "")
                    }
                    onClick={handleTabshwDskButtonClick}
                  >
                    <Tooltip
                      id="tooltipstyleRht"
                      arrow
                      title={
                        <span className="tooltipstyleRht">
                          Show/Hide right panel
                        </span>
                      }
                      placement="left-end"
                    >
                      {Theme.ChevronRight}
                    </Tooltip>
                  </div>
                )}

                {controlsVisible && (
                  <div
                    id="stylesection"
                    className={
                      ""
                      /* "style-section" +
                    (showtab ? " show" : "") +
                    (showtabdsk ? " showdsk" : "") */
                    }
                  >
                    {/* <div className="stng-drowr">
                  <div>{selectedItem?.controltype} Properties</div>
                  <div style={{cursor:"pointer"}}
                  onClick={handleTabshwButtonClick}>
                    {Theme.Cancel}</div>
                  </div> */}
                    <Drawer
                      anchor="right"
                      open={openpaint}
                      onClose={toggleDrawer(false)}
                      PaperProps={{
                        className: "cstm-drwr-stl-rgt",
                      }}
                    >
                      {!showtabdsk && (
                        <>
                          <div
                            className="dwr-right-heading"
                            onClick={toggleDrawer(false)}
                          >
                            <div>Form Designer</div>
                            <div style={{ cursor: "pointer" }}>
                              {Theme.Cancel}
                            </div>
                          </div>
                          <Box
                            className="dwr-box"
                            sx={{
                              width: 380,
                              padding: 0,
                              position: "relative",
                              overflow: "auto",
                            }}
                            role="presentation"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="dwr-box-tab">
                              <Tabs
                                className="dwr-inside-tab"
                                value={tabValue1}
                                onChange={handleTabChange}
                                centered
                                TabIndicatorProps={{
                                  className: "custom-tab-indicator",
                                }}
                              >
                                <Tab
                                  className={`dwr-inside-tab-btn ${tabValue1 === 0 ? "tab-selected" : ""}`}
                                  label="Style Settings"
                                />
                                <Tab
                                  className={`dwr-inside-tab-btn ${tabValue1 === 1 ? "tab-selected" : ""}`}
                                  label="Color Settings"
                                />
                                <Tab
                                  className={`dwr-inside-tab-btn ${tabValue1 === 2 ? "tab-selected" : ""}`}
                                  label="Background"
                                />
                              </Tabs>
                            </div>
                            {tabValue1 === 0 && (
                              <Box sx={{ padding: 2 }}>
                                <div style={{ marginTop: "7px" }}>
                                  {/* Style Theme Options */}
                                  <div className="drowerseparater">
                                    <div className="frm-inp-wrap">
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <div className="theme-row">
                                            <label> Form Width </label>
                                            <div className="inpt-cntr-unit">
                                              <input
                                                className="theme-input-select"
                                                type="number"
                                                value={FormWidth}
                                                onChange={(e) =>
                                                  setFormWidth(
                                                    parseInt(e.target.value, 10)
                                                  )
                                                }
                                              />
                                              <span className="inpt-unit">
                                                px
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Label Alignment</label>
                                          <div className="inpt-cntr-unit">
                                            <select
                                              className="theme-input-slt"
                                              value={alignMent}
                                              onChange={handleSelectChange1}
                                            >
                                              <option value="left">Left</option>
                                              <option value="column">
                                                Top
                                              </option>
                                              <option value="right">
                                                Right
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="drowerseparater">
                                    <div className="frm-inp-wrap">
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Question Spacing</label>
                                          <div className="inpt-cntr-unit">
                                            <input
                                              className="theme-input-select"
                                              type="number"
                                              value={marginTop}
                                              onChange={(e) =>
                                                setMarginTop(
                                                  parseInt(e.target.value, 10)
                                                )
                                              }
                                            />
                                            <span className="inpt-unit">
                                              px
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="drowerseparater">
                                    <div className="frm-inp-wrap">
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Font</label>
                                          <div className="inpt-cntr-unit">
                                            <select
                                              className="theme-input-slt"
                                              value={fontFamily}
                                              onChange={handleSelectfontfamily}
                                            >
                                              {fontFamilies.map(
                                                (font, index) => (
                                                  <option
                                                    key={index}
                                                    value={font}
                                                    style={{ fontFamily: font }}
                                                  >
                                                    {font}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Font Size</label>
                                          <div className="inpt-cntr-unit">
                                            <input
                                              className="theme-input-select"
                                              type="number"
                                              value={FontSize}
                                              onChange={(e) =>
                                                setFontSize(
                                                  parseInt(e.target.value, 10)
                                                )
                                              }
                                            />
                                            <span className="inpt-unit">
                                              px
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {showButtonStyles && (
                                    <div>
                                      Button Styles
                                      <Tabs
                                        value={tabValue2}
                                        onChange={handleNestedTabChange}
                                      >
                                        <Tab label="Flat Styles" />
                                        <Tab label="Gradient Styles" />
                                      </Tabs>
                                      {tabValue2 === 0 && (
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            padding: 0,
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {buttonStylesFlat.map(
                                            (style, index) => (
                                              <li
                                                key={index}
                                                className="color-scheme"
                                                style={{
                                                  display: "inline-block",
                                                  margin: "5px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  borderWidth: "2px",
                                                  borderStyle: "solid",
                                                  backgroundColor:
                                                    style.backgroundColor,
                                                  color: style.color,
                                                  borderRadius: "4px",
                                                  border: "none",
                                                }}
                                                onClick={() =>
                                                  handleButtonClick(
                                                    style.backgroundColor
                                                  )
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  style={{
                                                    backgroundColor:
                                                      "transparent",
                                                    border: "none",
                                                    color: "inherit",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {style.text}
                                                </button>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                      {tabValue2 === 1 && (
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            padding: 0,
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {buttonStylesGradient.map(
                                            (style, index) => (
                                              <li
                                                key={index}
                                                aria-label={style.label}
                                                className="color-scheme"
                                                style={{
                                                  display: "inline-block",
                                                  margin: "5px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  borderWidth: "2px",
                                                  borderStyle: "solid",
                                                  background: style.background,
                                                  color: style.color,
                                                  borderRadius: "4px",
                                                  border: "none",
                                                }}
                                                onClick={() =>
                                                  handleButtonClickGradient(
                                                    style.background
                                                  )
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  style={{
                                                    backgroundColor:
                                                      "transparent",
                                                    border: "none",
                                                    color: "inherit",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {style.text}
                                                </button>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Box>
                            )}

                            {tabValue1 === 1 && (
                              <Box sx={{ padding: 2 }}>
                                <div className="clr-schm-text">
                                  Color Scheme
                                </div>
                                <ul
                                  className="drowerseparater"
                                  style={{
                                    listStyleType: "none",
                                    // padding: 0,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    paddingTop: "0px",
                                  }}
                                >
                                  {colorSchemes.map((scheme, index) => (
                                    <li
                                      key={index}
                                      aria-label={scheme.label}
                                      className="color-scheme"
                                      style={{
                                        display: "inline-block",
                                        margin: "5px",
                                        padding: "3px 5px",
                                        cursor: "pointer",
                                        borderWidth: "4px",
                                        borderStyle: "solid",
                                        borderColor: scheme.borderColor,
                                        backgroundColor: scheme.backgroundColor,
                                        color: scheme.color,
                                        borderRadius: "4px",
                                      }}
                                      onClick={() =>
                                        handleColorSchemeChange(scheme)
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          color: "inherit",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {scheme.buttonLabel}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                                <div className="drowerseparater">
                                  <div className="frm-inp-wrap">
                                    <div className="clr-schm-text">
                                      <span>Form Color</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          value={bgColor}
                                          onChange={(e) =>
                                            setBgColor(e.target.value)
                                          }
                                          className="custom-input-wap text-input"
                                        />
                                        <input
                                          type="color"
                                          value={bgColor}
                                          onChange={(e) =>
                                            setBgColor(e.target.value)
                                          }
                                          className="custom-input-text color-input"
                                        />
                                      </div>
                                    </div>
                                    <div className="clr-schm-text">
                                      <span>Form Image</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          type="file"
                                          id="fileInput"
                                          onChange={handleImageUpload}
                                          style={{ display: "none" }}
                                        />
                                        <label
                                          htmlFor="fileInput"
                                          className="cst-file-chose"
                                        >
                                          <ImFilePicture className="file-icon" />
                                          <span>
                                            {fileName
                                              ? fileName
                                              : "Choose a file"}
                                          </span>
                                        </label>
                                        <span
                                          onClick={handleRemoveBackgroundImage}
                                        >
                                          <Tooltip
                                            title={
                                              <Typography
                                                fontSize={11}
                                                color="#fff"
                                              >
                                                Remove Background image
                                              </Typography>
                                            }
                                            arrow
                                            placement="bottom"
                                            PopperProps={{
                                              sx: {
                                                "& .MuiTooltip-tooltip": {
                                                  backgroundColor:
                                                    "rgba(97, 97, 97, 0.9);",
                                                  color: "#fff",
                                                  fontSize: 11,
                                                  boxShadow:
                                                    "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                },
                                                "& .MuiTooltip-arrow": {
                                                  Color:
                                                    "rgba(97, 97, 97, 0.9);",
                                                },
                                              },
                                            }}
                                          >
                                            <span className="file-icon-cancel">
                                              {Theme.photocancel}
                                            </span>
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="drowerseparater">
                                  <div className="frm-inp-wrap">
                                    <div className="clr-schm-text">
                                      <span>Font Color</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          value={fontColor}
                                          onChange={(e) =>
                                            setFontColor(e.target.value)
                                          }
                                          className="custom-input-wap text-input"
                                        />
                                        <input
                                          type="color"
                                          value={fontColor}
                                          onChange={(e) =>
                                            setFontColor(e.target.value)
                                          }
                                          className="custom-input-text color-input"
                                        />
                                      </div>
                                    </div>
                                    <div className="clr-schm-text">
                                      <span>Input Background</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          value={inputColor}
                                          onChange={(e) =>
                                            setInputColor(e.target.value)
                                          }
                                          className="custom-input-wap text-input"
                                        />
                                        <input
                                          type="color"
                                          value={inputColor}
                                          onChange={(e) =>
                                            setInputColor(e.target.value)
                                          }
                                          className="custom-input-text color-input"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="btn-rmov-img">
                                  {/* <input type="file" onChange={handleImageUpload} /> */}
                                </div>

                                <div></div>
                              </Box>
                            )}

                            {tabValue1 === 2 && (
                              <>
                                <div className="bg-clr-inpt">
                                  <input
                                    type="file"
                                    id="fileInput"
                                    onChange={handleImageUploadparent}
                                    style={{ display: "none" }}
                                  />
                                  <label
                                    htmlFor="fileInput"
                                    className="cst-file-chose"
                                  >
                                    <ImFilePicture className="file-icon" />
                                    <span>
                                      {fileName ? fileName : "Choose a file"}
                                    </span>
                                  </label>
                                  <span
                                    onClick={handleRemoveParentBackgroundImage}
                                  >
                                    <Tooltip
                                      title={
                                        <Typography fontSize={11} color="#fff">
                                          Remove Background image
                                        </Typography>
                                      }
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        sx: {
                                          "& .MuiTooltip-tooltip": {
                                            backgroundColor:
                                              "rgba(97, 97, 97, 0.9);",
                                            color: "#fff",
                                            fontSize: 11,
                                            boxShadow:
                                              "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                          },
                                          "& .MuiTooltip-arrow": {
                                            Color: "rgba(97, 97, 97, 0.9);",
                                          },
                                        },
                                      }}
                                    >
                                      <span className="file-icon-cancel">
                                        {Theme.photocancel}
                                      </span>
                                    </Tooltip>
                                  </span>
                                </div>

                                {showTheme && (
                                  <>
                                    {/* <div>
                                      <Box
                                        sx={{ padding: "18px 18px 17px 32px" }}
                                       
                                      > */}
                                    {/* <Grid container spacing={2}>
                                          {themeArray.map((item) => {
                                            const matchingHtmlOutput =
                                              themehtmlouput.find(
                                                (output) =>
                                                  output.arID === item.ArID
                                              );

                                            return (
                                              <Grid
                                                item
                                                key={item.ArID}
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                style={{
                                                  padding: "0px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                <Card
                                                  sx={{
                                                    minWidth: 200,
                                                    boxShadow: 3,
                                                  }}
                                                >
                                                  <CardContent>
                                                    <Typography
                                                      variant="h5"
                                                      component="div"
                                                      gutterBottom
                                                    >
                                                      {matchingHtmlOutput && (
                                                        <>
                                                    

                                                          <iframe
  srcDoc={matchingHtmlOutput.html}
  style={{ width: "100%", height: "auto", border: "none" }}
/>

                                                        </>
                                                      )}
                                                    </Typography>

                                                    <Button
                                                      variant="contained"
                                                      onClick={(event) =>
                                                        handleSelect(
                                                          item.ArID,
                                                          event
                                                        )
                                                      }
                                                      sx={{ marginTop: 2 }}
                                                    >
                                                      Select
                                                    </Button>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            );
                                          })}
                                        </Grid> */}
                                    <div>
                                      <Box
                                        sx={{ padding: "18px 18px 17px 14px" }}
                                      >
                                        <div
                                          container
                                          spacing={2}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {themeArray.map((item) => {
                                            const matchingHtmlOutput =
                                              themehtmlouput.find(
                                                (output) =>
                                                  output.arID === item.ArID
                                              );

                                            return (
                                              <div
                                                item
                                                key={item.ArID}
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                onMouseEnter={(e) => {
                                                  e.currentTarget.style.border =
                                                    "2px solid #007BFF";
                                                  e.currentTarget.style.backgroundColor =
                                                    "#f0f8ff";
                                                }}
                                                onMouseLeave={(e) => {
                                                  e.currentTarget.style.border =
                                                    "2px solid transparent";
                                                  e.currentTarget.style.backgroundColor =
                                                    "transparent";
                                                }}
                                                style={{
                                                  padding: "0px",
                                                  marginBottom: "10px",
                                                  border:
                                                    "2px solid transparent",
                                                  transition:
                                                    "border 0.3s ease",
                                                  cursor: "pointer",
                                                  borderRadius: "6px",
                                                }}
                                              >
                                                <Card
                                                  sx={{
                                                    minWidth: 68,
                                                    maxWidth: 168,
                                                    height: 190,
                                                    boxShadow: 3,
                                                  }}
                                                >
                                                  <CardContent
                                                    style={{ padding: "0px" }}
                                                  >
                                                    <div
                                                      variant="h5"
                                                      component="div"
                                                      gutterBottom
                                                    >
                                                      {matchingHtmlOutput && (
                                                        <>
                                                          <iframe
                                                            srcDoc={
                                                              matchingHtmlOutput.html
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              height: "149px",
                                                              border: "none",
                                                            }}
                                                          />
                                                          <div className="theme-btn-varint">
                                                            <Button
                                                              // variant="contained"
                                                              onClick={(
                                                                event
                                                              ) =>
                                                                handleSelect(
                                                                  item.ArID,
                                                                  event
                                                                )
                                                              }
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                            >
                                                              Select
                                                            </Button>
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                  </CardContent>
                                                </Card>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </Box>
                                    </div>

                                    <div className="rmv-hndle-them">
                                      <button onClick={handleRemove}>
                                        Remove Theme
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                        </>
                      )}
                      {controlsVisible && showtabdsk && (
                        <div
                          id="stylesection"
                          className={
                            "" +
                            /* "style-section" + 
                    (showtab ? " show" : "") +*/
                            (showtabdsk ? " showdsk" : "")
                          }
                        >
                          {/* <div className="dwr-right-heading">
                  <div>Full Name Properties</div>
                  <div style={{cursor:"pointer"}}
                  onClick={toggleDrawer(false)}>
                    {Theme.Cancel}</div>
                  </div> */}

                          <div className="stng-drowr">
                            <div>
                              {selectedItem?.type === "Root"
                                ? "Root"
                                : selectedItem?.id === "0.2L"
                                  ? "Left Container"
                                  : selectedItem?.id === "0.2R"
                                    ? "Right Container"
                                    : selectedItem?.controltype}{" "}
                              Properties
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={toggleDrawer(false)}
                            >
                              {Theme.Cancel}
                            </div>
                          </div>

                          {controlsVisible && (
                            <GeneralSection
                              tabValue={tabValue}
                              setTabValue={setTabValue}
                              rightsidetabs={rightsidetabs}
                              selectedItem={selectedItem}
                              isRootItem={isRootItem}
                              treeData={treeData}
                              editedData={editedData}
                              handleEditChange={handleEditChange}
                              handleEditChangeforTable={
                                handleEditChangeforTable
                              }
                              tabSelect={tabSelect}
                              handleEnterForNumber={handleEnterForNumber}
                              handleAgree={handleAgree}
                              handlePopupClick={handlePopupClick}
                              isPopupVisible={isPopupVisible}
                              handleClose={handleClose}
                              selectedOption={selectedOption}
                              dataSourceArID={dataSourceArID}
                              fieldNames={fieldNames}
                              fieldNamestable={fieldNamestable}
                              handleChange={handleChange}
                              inputValueRadio={inputValueRadio}
                              handleInputChangeRadio={handleInputChangeRadio}
                              handleAddControlRadio={handleAddControlRadio}
                              handleRadioPositionChange={
                                handleRadioPositionChange
                              }
                              inputValue={inputValue}
                              handleInputChange={handleInputChange}
                              handleAddControlCheckbox={
                                handleAddControlCheckbox
                              }
                              selectedOptioncheck={selectedOptioncheck}
                              handleCheckboxChange={handleCheckboxChange}
                              handleRadioChange={handleRadioChange}
                              selectedPositioncheck={selectedPositioncheck}
                              handleCheckboxPositonChange={
                                handleCheckboxPositonChange
                              }
                              selectedOptionSwitchcheck={
                                selectedOptionSwitchcheck
                              }
                              isSwitched={isSwitched}
                              handleSwitchChange={handleSwitchChange}
                              inputRef={inputRef}
                              handleImageChange={handleImageChange}
                              handleImageClick={handleImageClick}
                              handleIFrameChange={handleIFrameChange}
                              handleIFrameClick={handleIFrameClick}
                              handleKeyPress={handleKeyPress}
                              handleDateChange={handleDateChange}
                              isOpen={isOpen}
                              handleEditChangeName1={handleEditChangeName1}
                              handleRenameConfirm={handleRenameConfirm}
                              editedName={editedName}
                              isEditing={isEditing}
                              setControlsVisible={setControlsVisible}
                              controlsVisible={controlsVisible}
                              inputType={inputType}
                              addNewColumn={addNewColumn}
                              setTreeData={setTreeData}
                              selectedItemID={selectedItemID}
                              selectedControlType={selectedControlType}
                              setSelectedControlType={setSelectedControlType}
                              handleControlTypeChange={handleControlTypeChange}
                              tableProp={tableProp}
                              setEditedData={setEditedData}
                              setEditedName={setEditedName}
                              fieldData={fieldData}
                              setFieldNames={setFieldNames}
                              inputText={inputText}
                              url={url}
                              selectedControl={selectedControl}
                              defaultStyle={defaultStyle}
                              selectedNode={selectedNode}
                              setTableHeader={setTableHeader}
                              tableHeader={tableHeader}
                              settableProp={settableProp}
                              rightsideStyletab={rightsideStyletab}
                              updateCustomStyle={updateCustomStyle}
                              setAlignMent={setAlignMent}
                              alignMent={alignMent}
                              isRequiredFullName={isRequiredFullName}
                              setIsRequiredFullName={setIsRequiredFullName}
                              isRequiredAddress={isRequiredAddress}
                              setIsRequiredAddress={setIsRequiredAddress}
                              CheckboxCheckedStreet1={CheckboxCheckedStreet1}
                              setCheckboxCheckedStreet1={
                                setCheckboxCheckedStreet1
                              }
                              CheckboxCheckedStreet2={CheckboxCheckedStreet2}
                              setCheckboxCheckedStreet2={
                                setCheckboxCheckedStreet2
                              }
                              setCheckboxCheckedCity={setCheckboxCheckedCity}
                              CheckboxCheckedCity={CheckboxCheckedCity}
                              setCheckboxCheckedState={setCheckboxCheckedState}
                              CheckboxCheckedState={CheckboxCheckedState}
                              setCheckboxCheckedPostal={
                                setCheckboxCheckedPostal
                              }
                              CheckboxCheckedPostal={CheckboxCheckedPostal}
                              CheckboxCheckedCountry={CheckboxCheckedCountry}
                              setCheckboxCheckedCountry={
                                setCheckboxCheckedCountry
                              }
                              CheckboxCheckedPrefix={CheckboxCheckedPrefix}
                              setCheckboxCheckedPrefix={
                                setCheckboxCheckedPrefix
                              }
                              FontSize={FontSize}
                              setFontSize={setFontSize}
                              authData={authData.accessKey || ""}
                              setFieldData={setFieldData}
                              setSelectedItem={setSelectedItem}
                            />
                          )}
                          {controlsVisible && (
                            <AdvanceSection
                              selectedItem={selectedItem}
                              treeData={treeData}
                              controlsVisible={controlsVisible}
                              setTreeData={setTreeData}
                              selectedItemID={selectedItemID}
                              rightsideStyletab={rightsideStyletab}
                              defaultStyle={defaultStyle}
                              inputRef={inputRef}
                              selectedNode={selectedNode}
                              editedDataFirstNameTextbox={
                                editedDataFirstNameTextbox
                              }
                              setEditedDataFirstNameTextbox={
                                setEditedDataFirstNameTextbox
                              }
                              editedDataLastNameTextbox={
                                editedDataLastNameTextbox
                              }
                              setEditedDataLastNameTextbox={
                                setEditedDataLastNameTextbox
                              }
                              CheckboxCheckedStreet1={CheckboxCheckedStreet1}
                              setCheckboxCheckedStreet1={
                                setCheckboxCheckedStreet1
                              }
                              CheckboxCheckedStreet2={CheckboxCheckedStreet2}
                              setCheckboxCheckedStreet2={
                                setCheckboxCheckedStreet2
                              }
                              setCheckboxCheckedCity={setCheckboxCheckedCity}
                              CheckboxCheckedCity={CheckboxCheckedCity}
                              setCheckboxCheckedState={setCheckboxCheckedState}
                              CheckboxCheckedState={CheckboxCheckedState}
                              setCheckboxCheckedPostal={
                                setCheckboxCheckedPostal
                              }
                              CheckboxCheckedPostal={CheckboxCheckedPostal}
                              CheckboxCheckedCountry={CheckboxCheckedCountry}
                              setCheckboxCheckedCountry={
                                setCheckboxCheckedCountry
                              }
                              CheckboxCheckedPrefix={CheckboxCheckedPrefix}
                              setCheckboxCheckedPrefix={
                                setCheckboxCheckedPrefix
                              }
                              dataSourceArID={dataSourceArID}
                              fieldData={fieldData}
                              setFieldNames={setFieldNames}
                              fieldNames={fieldNames}
                              authData={authData.accessKey || ""}
                              updateCustomStyle={updateCustomStyle}
                            />
                          )}

                          {controlsVisible && (
                            <OptionSection
                              tabValue={tabValue}
                              setTabValue={setTabValue}
                              rightsidetabs={rightsidetabs}
                              selectedItem={selectedItem}
                              isRootItem={isRootItem}
                              treeData={treeData}
                              editedData={editedData}
                              handleEditChange={handleEditChange}
                              handleEditChangeforTable={
                                handleEditChangeforTable
                              }
                              tabSelect={tabSelect}
                              handleEnterForNumber={handleEnterForNumber}
                              handleAgree={handleAgree}
                              handlePopupClick={handlePopupClick}
                              isPopupVisible={isPopupVisible}
                              handleClose={handleClose}
                              selectedOption={selectedOption}
                              dataSourceArID={dataSourceArID}
                              fieldNames={fieldNames}
                              fieldNamestable={fieldNamestable}
                              handleChange={handleChange}
                              inputValueRadio={inputValueRadio}
                              handleInputChangeRadio={handleInputChangeRadio}
                              handleAddControlRadio={handleAddControlRadio}
                              handleRadioPositionChange={
                                handleRadioPositionChange
                              }
                              inputValue={inputValue}
                              handleInputChange={handleInputChange}
                              handleAddControlCheckbox={
                                handleAddControlCheckbox
                              }
                              selectedOptioncheck={selectedOptioncheck}
                              handleCheckboxChange={handleCheckboxChange}
                              handleRadioChange={handleRadioChange}
                              selectedPositioncheck={selectedPositioncheck}
                              handleCheckboxPositonChange={
                                handleCheckboxPositonChange
                              }
                              selectedOptionSwitchcheck={
                                selectedOptionSwitchcheck
                              }
                              isSwitched={isSwitched}
                              handleSwitchChange={handleSwitchChange}
                              inputRef={inputRef}
                              handleImageChange={handleImageChange}
                              handleImageClick={handleImageClick}
                              handleIFrameChange={handleIFrameChange}
                              handleIFrameClick={handleIFrameClick}
                              handleKeyPress={handleKeyPress}
                              handleDateChange={handleDateChange}
                              isOpen={isOpen}
                              handleEditChangeName1={handleEditChangeName1}
                              handleRenameConfirm={handleRenameConfirm}
                              editedName={editedName}
                              isEditing={isEditing}
                              setControlsVisible={setControlsVisible}
                              controlsVisible={controlsVisible}
                              inputType={inputType}
                              addNewColumn={addNewColumn}
                              setTreeData={setTreeData}
                              selectedItemID={selectedItemID}
                              selectedControlType={selectedControlType}
                              setSelectedControlType={setSelectedControlType}
                              handleControlTypeChange={handleControlTypeChange}
                              tableProp={tableProp}
                              setEditedData={setEditedData}
                              setEditedName={setEditedName}
                              fieldData={fieldData}
                              setFieldNames={setFieldNames}
                              inputText={inputText}
                              url={url}
                              selectedControl={selectedControl}
                              defaultStyle={defaultStyle}
                              selectedNode={selectedNode}
                              setTableHeader={setTableHeader}
                              tableHeader={tableHeader}
                              settableProp={settableProp}
                              rightsideStyletab={rightsideStyletab}
                              updateCustomStyle={updateCustomStyle}
                              CheckboxCheckedStreet1={CheckboxCheckedStreet1}
                              setCheckboxCheckedStreet1={
                                setCheckboxCheckedStreet1
                              }
                              CheckboxCheckedStreet2={CheckboxCheckedStreet2}
                              setCheckboxCheckedStreet2={
                                setCheckboxCheckedStreet2
                              }
                              setCheckboxCheckedCity={setCheckboxCheckedCity}
                              CheckboxCheckedCity={CheckboxCheckedCity}
                              setCheckboxCheckedState={setCheckboxCheckedState}
                              CheckboxCheckedState={CheckboxCheckedState}
                              setCheckboxCheckedPostal={
                                setCheckboxCheckedPostal
                              }
                              CheckboxCheckedPostal={CheckboxCheckedPostal}
                              CheckboxCheckedCountry={CheckboxCheckedCountry}
                              setCheckboxCheckedCountry={
                                setCheckboxCheckedCountry
                              }
                              authData={authData.accessKey || ""}
                            />
                          )}
                        </div>
                      )}
                    </Drawer>
                  </div>
                )}
              </div>
            )}
            {showTheme && (
              <>
                {/* {treeData.map((item) => {
                  return item.Source[0].control
                    .filter(
                      (control) => control.option === "Template1FooterContainer"
                    )
                    .map((headerControl) => renderControl(headerControl));
                })} */}
                {treeData.map((item) => {
                  return item.Source[0].control
                    .filter(
                      (control) => control.option === "Template1FooterContainer"
                    )
                    .map((footerControl) => {
                      const isFooter =
                        footerControl.option === "Template1FooterContainer";
                      const renderedFooterControl =
                        renderControl(footerControl);

                      if (isFooter) {
                        const iconContainer = document.getElementById(
                          `iconContainer-${footerControl.id}`
                        );
                        if (iconContainer) {
                          const element = document.getElementById(
                            footerControl.id
                          );
                          if (element) {
                            const rect = element.getBoundingClientRect();
                            const iconRect =
                              iconContainer.getBoundingClientRect();

                            // Fix icon position to the top of the footer element (positioned fixed)
                            iconContainer.style.position = "fixed"; // Fix the position to the viewport
                            iconContainer.style.top = `${rect.top + window.scrollY - iconRect.height - 10}px`; // Set the top position with scroll offset
                            iconContainer.style.left = `${rect.left + window.scrollX + rect.width / 2 - iconRect.width / 2}px`; // Center horizontally
                            iconContainer.style.zIndex = "0";
                            iconContainer.style.marginTop = "0px";
                          }
                        }
                      }

                      return renderedFooterControl;
                    });
                })}
              </>
            )}
          </div>

          {controlsVisible && (
            <div className="stl-right-drower">
              <span className="custom-tooltip-form" onClick={paintIconClick}>
                <span className="paint-icon">{Theme.Paint}</span>
                <span className="tooltip-text-dgn-frm">Form Designer</span>
              </span>
            </div>
          )}

          {!controlsVisible && (
            <div className="stl-right-back">
              <span
                className="custom-tooltip-form-back"
                onClick={handlePreViewBack}
              >
                <span className="paint-icon">{Theme.Back}</span>
                <span className="tooltip-text-dgn-frm">Preview Back</span>
              </span>
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};
export default RecursiveTreeView;
