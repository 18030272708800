import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Collapse,
} from "@mui/material";
import BoxTemplate from "./BoxTemplate";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import { toast } from "react-toastify";

const ParamsComponent = ({
  nodes,
  setNodes,
  nodeData,
  selectedNode,
  onClose,
}) => {
  // console.log("diagramDetails ", diagramDetails);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showExplanations, setShowExplanations] = useState({});
  const [notes, setNotes] = useState({});
  const [showNotes, setShowNotes] = useState({});

  const nodeInstance = nodes?.find(
    (node) => node?.id?.toString() === selectedNode?.id?.toString()
  );

  const params = React.useMemo(() => {
    return nodeInstance?.data[0]?.params || [];
  }, [nodeInstance]);

  const generateKey = (param) => `${param?.taskParamID}`;

  useEffect(() => {
    const initialData = {};
    const initialNotes = {};

    params.forEach((param) => {
      const key = generateKey(param);
      initialData[key] = param.paramValue || "";
      initialNotes[key] = param.internalNotes || "";
    });

    setFormData(initialData);
    setNotes(initialNotes);
    setErrors({});
    setShowExplanations({});
    setShowNotes({});
  }, [params]);

  const handleInputChange = ({ target: { name, value } }) => {
   
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleNoteChange = ({ target: { name, value } }) => {
    setNotes((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("params ", params);
    console.log("nodes ", nodes);
    console.log("formData ", formData);
  };

  const handleCancel = () => {
    const initialData = {};
    const initialNotes = {};

    params.forEach((param) => {
      const key = generateKey(param);
      initialData[key] = param.paramValue || "";
      initialNotes[key] = param.internalNotes || "";
    });

    setFormData(initialData);
    setNotes(initialNotes);
    setErrors({});
  };

  const toggleExplanation = (key) => {
    setShowExplanations((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const toggleNotes = (key) => {
    setShowNotes((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  if (!params.length)
    return (
      <Typography>No parameters available for the selected node</Typography>
    );


    const nodeNameColor = selectedNode.data.find(
      (dataItem) => dataItem.nodename
    )?.nodename.color;

  return (
    <BoxTemplate
      // icon={<SettingsIcon style={{ marginRight: "0.5rem" }} />}
      // title="Parameters"
      // subTitle="Pass information to and from systems."
      icon={
        <div style={{ display: "flex", alignItems: "center" }}>
          <SettingsIcon style={{ marginRight: "0.5rem" , color : nodeNameColor }} />
          <div>
            <div className="parameter-heading" style={{color : nodeNameColor}}>
              Parameters
            </div>
            <div className="parameter-paragraph" style={{color : nodeNameColor}}>
              Pass information to and from systems.
            </div>
          </div>
        </div>
      }
      selectedNode={selectedNode}
      >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {params.map(
            ({
              ParamName,
              paramName,
              ParamExplanation,
              paramExplanation,
              taskParamID,
              dToolParamID,
            }) => {
              const key = generateKey({
                ParamName,
                paramName,
                taskParamID,
                dToolParamID,
              });
              return (
                <Grid item xs={12} key={key}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{ParamName || paramName}</Typography>
                      <Box>
                        <IconButton
                          onClick={() => toggleNotes(key)}
                          size="small"
                          color="secondary"
                        >
                          <DescriptionIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => toggleExplanation(key)}
                          size="small"
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Box>
                    </Box>

                    <Collapse in={showExplanations[key]}>
                      <Box
                        sx={{
                          backgroundColor: "#f0f0f0",
                          p: 1,
                          mt: 1,
                          mb: 1,
                          borderRadius: "4px",
                        }}
                      >
                        <Typography variant="body2">
                          {ParamExplanation || paramExplanation}
                        </Typography>
                      </Box>
                    </Collapse>

                    <TextField
                      fullWidth
                      size="small"
                      name={key}
                      value={formData[key] || ""}
                      onChange={handleInputChange}
                      variant="outlined"
                      error={!!errors[key]}
                      helperText={errors[key]}
                      multiline
                    />

                    {/* Conditionally render the notes text field */}
                    <Collapse in={showNotes[key]}>
                      <TextField
                        fullWidth
                        size="small"
                        name={key}
                        label="Notes"
                        value={notes[key] || ""}
                        onChange={handleNoteChange}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        multiline
                      />
                    </Collapse>
                  </Box>
                </Grid>
              );
            }
          )}
        </Grid>

        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleCancel} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </form>
    </BoxTemplate>
  );
};

export default ParamsComponent;
