import { useState, useEffect } from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import "./MultiSelectorStyles.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function MultiSelector({ data, selected, setSelected }) {
  const isAllSelected = data.length > 0 && selected.length === data.length;
  

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter((section) => {
    if (searchTerm === "") {
      return true;
    }
    return section.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const handleToggle = (value) => () => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.some((section) => section.name === value);

      if (isSelected) {
        // If the item is already in the selected array, remove all occurrences
        return prevSelected.filter((section) => section.name !== value);
      } else {
        // If the item is not in the selected array, add all occurrences at the correct position
        const selectedSections = filteredData.filter(
          (section) => section.name === value
        );
        const insertIndex = filteredData.findIndex(
          (section) => section.name === value
        );

        return [
          ...prevSelected.slice(0, insertIndex),
          ...selectedSections,
          ...prevSelected.slice(insertIndex),
        ];
      }
    });
  };

  const handleSelectAll = () => {
    setSelected((prevSelected) => {
      if (prevSelected.length === filteredData.length) {
        // If all items are currently selected, deselect all
        return [];
      } else {
        // If some or none are selected, select all
        return [...filteredData];
      }
    });
  };

  const handleFilterClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    event.stopPropagation();
    setSearchTerm(event.target.value);
  };

  return (
    <List>
      <ListItem button onClick={handleSelectAll}>
        <ListItemIcon>
          <Checkbox
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < filteredData.length
            }
          />
        </ListItemIcon>
        <ListItemText primary={isAllSelected ? "Unselect All" : "Select All"} />
        <IconButton edge="end" onClick={(event) => handleFilterClick(event)}>
          <MoreVertIcon />
        </IconButton>
      </ListItem>
      {filteredData.map((section) => {
        if (section.diagID === 0) {
          return (
            <ListItem
              key={section.diagTempID}
              button
              onClick={handleToggle(section.name)}
            >
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(section) > -1} />
              </ListItemIcon>
              <ListItemText primary={section.name} />
            </ListItem>
          );
        }
        return null; // If DiagID is not 0, return null (or an empty fragment)
      })}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          <TextField
            label="Search"
            variant="standard"
            size="small"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            onClick={(event) => event.stopPropagation()} // Prevent ListItem from handling click
            onKeyDown={(event) => event.stopPropagation()}
          />
        </MenuItem>
        {/* Additional menu items can be added here */}
      </Menu>
    </List>
  );
}
