import React, { useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconBox from "./IconBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import LineStyleDropdown from "./edgeProperties/LineStyleDropDown";
import {
  useFlipArrow,
  useDeleteEdge,
  useChangeColor,
  useChangeMarkerEndColor,
  useChangeLineStyle,
  useChangeThickness,
  useChangeType,
  useChangeLabel,
  useChangeMarkerEnd,
  useChangeMarkerEndSVG,
  useChangeMarkerStart,
  useChangeMarkerStartSVG,
  useChangeLabelFontStyle,
  useChangeLabelBgStyle,
  useChangeLabelColorStyle,
} from "../../functions/EdgeFunctions";
import TabsComponent from "./TabsComponent";
import EdgeTypeDropdown from "./edgeProperties/EdgeTypeDropdown";
import MarkerEndDropdown from "./edgeProperties/MarkerDropDown";

const DrawerComponent = ({
  isOpen,
  onClose,
  edges,
  setEdges,
  clickedEdge,
  diagramDetails,
  setDiagramDetails,
  setClickedEdge,
}) => {
  console.log("diagramDetails ", diagramDetails);


  const [formData, setFormData] = useState({
    flipEdge: 0,
    deleteEdge: 0,
    color: clickedEdge?.style?.stroke || "#d3d3d3",
    markerEndColor: clickedEdge?.markerEnd?.color || "#d3d3d3",
    lineStyle: clickedEdge?.animated ? "dashed" : "solid",
    thickness: clickedEdge?.style?.strokeWidth || 3,
    markerEnd:
      typeof clickedEdge?.markerEnd === "object"
        ? clickedEdge?.markerEnd.type
        : typeof clickedEdge?.markerEnd === "string"
          ? clickedEdge?.markerEnd
          : "none",
    markerStart:
      typeof clickedEdge?.markerStart === "object"
        ? clickedEdge?.markerStart.type
        : typeof clickedEdge?.markerStart === "string"
          ? clickedEdge?.markerStart
          : "none",
    type: clickedEdge?.type || "floating",
    label: clickedEdge?.label || "",
  });


  const [labelStyle, setLabelStyle] = useState({
    labelFontSize: clickedEdge.labelStyle?.fontSize || 15,
    labelBackgroundColor:
      clickedEdge.labelBgStyle?.fill || "rgba(255, 255, 255, 0)",
    labelColor: clickedEdge.labelStyle?.fill || "rgba(0, 0, 0, 0)",
  });


  useEffect(() => {
    if (clickedEdge) {
      setFormData({
        flipEdge: 0,
        deleteEdge: 0,
        color: clickedEdge?.style?.stroke || "#d3d3d3",
        markerEndColor: clickedEdge?.markerEnd?.color || "#d3d3d3",
        lineStyle: clickedEdge?.animated ? "dashed" : "solid",
        thickness: clickedEdge?.style?.strokeWidth || 3,
        markerEnd:
          typeof clickedEdge?.markerEnd === "object"
            ? clickedEdge?.markerEnd.type
            : typeof clickedEdge?.markerEnd === "string"
            ? clickedEdge?.markerEnd
            : "none",
        markerStart:
          typeof clickedEdge?.markerStart === "object"
            ? clickedEdge?.markerStart.type
            : typeof clickedEdge?.markerStart === "string"
            ? clickedEdge?.markerStart
            : "none",
        type: clickedEdge?.type || "floating",
        label: clickedEdge?.label || "",
      });

      setLabelStyle({
        labelFontSize: clickedEdge?.labelStyle?.fontSize || 15,
        labelBackgroundColor:
          clickedEdge?.labelBgStyle?.fill || "rgba(255, 255, 255, 0)",
        labelColor: clickedEdge?.labelStyle?.fill || "rgba(0, 0, 0, 0)",
      });
    }
  }, [clickedEdge]);


  const handleFlipArrow = useFlipArrow(
    setEdges,
    diagramDetails,
    setDiagramDetails
  );

  console.log("setClickedEdge",clickedEdge)
  const handleDeleteEdge = useDeleteEdge(setEdges, setClickedEdge);
  const handleChangeColor = useChangeColor(setEdges);
  const handleChangeMarkerEndColor = useChangeMarkerEndColor(setEdges);
  const handleChangeLineStyle = useChangeLineStyle(setEdges);
  const handleChangeThickness = useChangeThickness(setEdges);
  const handleChangeType = useChangeType(setEdges);
  const handleChangeLabel = useChangeLabel(setEdges);
  const handleChangeMarkerEnd = useChangeMarkerEnd(setEdges);
  const handleChangeMarkerEndSVG = useChangeMarkerEndSVG(setEdges);
  const handleChangeMarkerStart = useChangeMarkerStart(setEdges);
  const handleChangeMarkerStartSVG = useChangeMarkerStartSVG(setEdges);
  const handleChangeLabelFontStyle = useChangeLabelFontStyle(setEdges);
  const handleChangeLabelBgStyle = useChangeLabelBgStyle(setEdges);
  const handleChangeLabelColorStyle = useChangeLabelColorStyle(setEdges);
  
  
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
      ...(name === "color" && { markerEndColor: value }),
    });
    if (name === "labelFontSize") {
      setLabelStyle({
        ...labelStyle,
        labelFontSize: value,
      });
    }
    if (name === "labelBackgroundColor") {
      setLabelStyle({
        ...labelStyle,
        labelBackgroundColor: value,
      });
    }
    if (name === "labelColor") {
      setLabelStyle({
        ...labelStyle,
        labelColor: value,
      });
    }
    if (name === "flipEdge") {
      handleFlipArrow(clickedEdge.id);
    } else if (name === "deleteEdge") {
      handleDeleteEdge(clickedEdge.id);
    } else {
      switch (name) {
        case "color":
          handleChangeColor(clickedEdge.id, value);
          handleChangeMarkerEndColor(clickedEdge.id, value);
          break;
        case "markerEndColor":
          handleChangeMarkerEndColor(clickedEdge.id, value);
          break;
        case "lineStyle":
          handleChangeLineStyle(clickedEdge.id, value);
          break;
        case "thickness":
          handleChangeThickness(clickedEdge.id, value);
          break;
        case "type":
          handleChangeType(clickedEdge.id, value);
          break;
        case "label":
          handleChangeLabel(clickedEdge.id, value);
          break;
        case "labelFontSize":
          handleChangeLabelFontStyle(clickedEdge.id, value);
          break;
        case "labelBackgroundColor":
          handleChangeLabelBgStyle(clickedEdge.id, {
            fill: value,
            fillOpacity: 0.7,
          });
          break;
        case "labelColor":
          handleChangeLabelColorStyle(clickedEdge.id, value);
          break;
        case "markerEnd":
          if (value === "arrowclosed" || value === "arrow") {
            handleChangeMarkerEnd(clickedEdge.id, value);
          } else {
            handleChangeMarkerEndSVG(clickedEdge.id, value);
          }
          break;
        case "markerStart":
          if (value === "arrowclosed" || value === "arrow") {
            handleChangeMarkerStart(clickedEdge.id, value);
          } else {
            handleChangeMarkerStartSVG(clickedEdge.id, value);
          }
          break;
        default:
          break;
      }
    }
  };

  

console.log("formData",formData)

  const handlestyletabclose = () => {
    setClickedEdge();
  };
  return (
    // <Drawer anchor="right" open={isOpen} onClose={() => onClose(1)}>
    <div
      style={{
        // width: "500px",
        // padding: "16px",
        // overflow: "auto",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{ display: "flex", alignItems: "center", fontSize: "12px" }}
        >
          Edit Edge
        </div>
       
      </div>
      {/* <TabsComponent
        tabValue="1"
        setTabValue={() => {}}
        tabs={[{ label: "Style", value: "1" }]}
      > */}
        <form key="1">
          <div className="drowerseparater">
            <div class="inpt-fild-test">
              <IconBox
                handleChange={handleChange}
                buttonList={[
                  {
                    icon: <FlipCameraAndroidIcon />,
                    name: "flipEdge",
                    value: formData.flipEdge,
                  },
                  {
                    icon: <DeleteForeverIcon />,
                    name: "deleteEdge",
                    value: formData.deleteEdge,
                  },
                ]}
              />
            </div>
          </div>
          <div className="drowerseparater">
            <div class="inpt-fild-test">
              <div className="color-picker-container">
                <div className="color-picker color-picker-style">
                  <label htmlFor="color" className="inpt-fild-test">
                    Color:
                  </label>
                  <input
                    type="color"
                    id="color"
                    name="color"
                    value={formData.color}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
                {clickedEdge.markerEnd && (
                  <div className="color-picker color-picker-style">
                    <label htmlFor="markerEndColor" className="inpt-fild-test">
                      Marker End Color:
                    </label>
                    <input
                      type="color"
                      id="markerEndColor"
                      name="markerEndColor"
                      value={formData.markerEndColor}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                )}
                <div className="color-picker color-picker-style">
                  <label htmlFor="labelColor" className="inpt-fild-test">
                    Label Color:
                  </label>
                  <input
                    type="color"
                    id="labelColor"
                    name="labelColor"
                    value={labelStyle.labelColor}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
                <div className="color-picker color-picker-style">
                  <label
                    htmlFor="labelBackgroundColor"
                    className="inpt-fild-test"
                  >
                    Label Background Color:
                  </label>
                  <input
                    type="color"
                    id="labelBackgroundColor"
                    name="labelBackgroundColor"
                    value={labelStyle.labelBackgroundColor}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* 
        <div className="backgrounclr">
          Color
          <input
            type="color"
            name="color"
            value={formData.color}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
        {clickedEdge.markerEnd && (
          <div className="backgrounclr">
            Marker End Color
            <input
              type="color"
              name="markerEndColor"
              value={formData.markerEndColor}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
        )}
        <div className="backgrounclr">
          Label Color
          <input
            type="color"
            name="labelColor"
            value={labelStyle.labelColor}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="backgrounclr">
          Label Background Color
          <input
            type="color"
            name="labelBackgroundColor"
            value={labelStyle.labelBackgroundColor}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div> */}
          <div class="drowerseparater">
            <div style={{ display: "flex", alignItems: "center" }}>
              <LineStyleDropdown
                formData={formData}
                onChange={(style) => handleChange("lineStyle", style)}
              />

              <div>
                <div className="rht-info fontlft-size">Thickness</div>
                <TextField
                  className="rht-select-field-flow"
                  InputProps={{ style: { height: "30px" } }}
                  size="small"
                  type="number"
                  name="thickness"
                  value={formData.thickness}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                  style={{
                    width: "100%",
                    margin: 0,
                    // marginLeft: "0.2rem",
                  }}
                />
              </div>
            </div>
          </div>
          <div class="drowerseparater">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <MarkerEndDropdown
                formData={formData}
                isEnd={false}
                onChange={(style) => handleChange("markerStart", style)}
              />
              <div>
                <MarkerEndDropdown
                  formData={formData}
                  isEnd={true}
                  onChange={(style) => handleChange("markerEnd", style)}
                  style={{
                    width: "100%",
                    margin: 0,
                    marginLeft: "0.2rem",
                  }}
                />
              </div>
            </div>
          </div>
          <div class="drowerseparater">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <EdgeTypeDropdown
                formData={formData}
                onChange={(style) => handleChange("type", style)}
                style={{
                  width: "100%",
                  margin: 0,
                  // marginLeft: "0.2rem",
                }}
              />

              <div>
                <div className="rht-info fontlft-size"> Label Size</div>
                <TextField
                  className="rht-select-field-flow"
                  InputProps={{ style: { height: "30px" } }}
                  size="small"
                  type="number"
                  name="labelFontSize"
                  value={labelStyle.labelFontSize}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                  style={{
                    width: "100%",
                    margin: 0,
                    // marginLeft: "0.2rem",
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
          <EdgeTypeDropdown
            formData={formData}
            onChange={(style) => handleChange("type", style)}
          />
          <div style={{ marginLeft: "1rem" }}>
            <div className="rht-info fontlft-size"> Label Size</div>
            <TextField
              InputProps={{ style: { height: "30px" } }}
              size="small"
              type="number"
              name="labelFontSize"
              value={labelStyle.labelFontSize}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              style={{
                width: "80%",
                margin: 0,
                marginLeft: "1rem",
              }}
            />
          </div>
        </div> */}
          <div class="drowerseparater">
            <div class="inpt-fild-test" style={{ marginLeft: "-5px" }}>
              <div className="rht-info fontlft-size">Label</div>
              <TextField
                className="rht-select-field-flow"
                InputProps={{ style: { height: "30px" } }}
                size="small"
                name="label"
                value={formData.label}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                style={{
                  width: "97%",
                  margin: 0,
                  marginLeft: "0.7rem",
                }}
              />
            </div>
          </div>
        </form>
      {/* </TabsComponent> */}
    </div>
    // </Drawer>
  );
};

export default DrawerComponent;
