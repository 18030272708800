import React, { useState, useEffect } from "react";
import { ThemeProvider, Typography, Box } from "@mui/material";
import themeProvider from "../../../ThemeProvider";
import SidebarCollapse from "../../SideBarCollapseComponents/SidebarCollapse";
import { NodePanel } from "./NodePanel";
import "../../../styles.css"

const DesktopSidebar = ({
  showmenudsk,
  setShowdskmenu,
  nodes,
  properties,
  diagID,
  region,
  startPanelsOpen = false,
  searchQuery = "",
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filteredNodes = nodes?.filter((node) =>
    searchQuery === "" || searchQuery === undefined || searchQuery === null
      ? true // Return all nodes when searchQuery is empty
      : node?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  // Group nodes by category
  const groupedNodes =
    filteredNodes?.reduce((acc, node) => {
      const category = node?.category || "Others";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(node);
      return acc;
    }, {}) || {};
  
  const [favorites, setFavorites] = useState([]);

  const toggleFavorite = (nodeId) => {
    let newFavorites;
    if (favorites.includes(nodeId)) {
      newFavorites = favorites.filter((id) => id !== nodeId);
    } else {
      newFavorites = [...favorites, nodeId];
    }
    setFavorites(newFavorites);
    localStorage.setItem(
      `diagramFavorites_${diagID}`,
      JSON.stringify(newFavorites)
    );
  };

  useEffect(() => {
    const storedFavorites = localStorage.getItem(`diagramFavorites_${diagID}`);
    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    } else {
      setFavorites([]); // Reset favorites if no stored data for this diagID
    }
  }, [diagID]);

  // Filter favorite nodes
  const favoriteNodes = nodes?.filter((node) =>
    favorites.includes(node?.diagtoolid)
  );

  const [collapsedPanels, setCollapsedPanels] = useState();
  
  useEffect(() => {
    const initialCollapsedState = {};
    Object.keys(groupedNodes).forEach((category) => {
      initialCollapsedState[category] = searchQuery ? false : !startPanelsOpen;
    });
    initialCollapsedState["Favorites"] = searchQuery ? false : !startPanelsOpen;
    setCollapsedPanels(initialCollapsedState);
  }, [searchQuery, nodes]);

  const togglePanel = (category) => {
    setCollapsedPanels((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const onDragStart = (event, node) => {
    let nodeType = node.name;
    event.dataTransfer.setData("application/reactflow", nodeType);
    const category = node.category;
    var matchedData = properties[category];
    const nodeId = node?.diagtoolid?.toString();
    const nodeData =
      properties?.Nodes.find((node) => node.id.toString() === nodeId) || null;
    event.dataTransfer.setData(
      "customData",
      JSON.stringify(nodeData ? nodeData.customstyle : matchedData)
    );
    event.dataTransfer.setData("data", JSON.stringify(nodes));
    event.dataTransfer.effectAllowed = "move";
  };

  const handleMenushwDskButtonClick = () => {
    setShowdskmenu(!showmenudsk);
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <aside
        className="no-select"
        style={{
          width: showmenudsk ? 0 : "100%",
          height: showmenudsk && isSmallScreen ? 0 : "auto",
          flexShrink: 0,
          transition: "all 0.3s ease",
          padding: showmenudsk ? 0 : "0.5rem",
          backgroundColor: themeProvider.palette.bg.lightWhite,
          overflowY: "auto",
          maxHeight: "100vh",
        }}
      >
        <div
          style={{
            width: showmenudsk ? 0 : "100%",
            height: showmenudsk ? 0 : "100%",
          }}
        >
          {region && (
            <Box>
              <Typography
                style={{
                  color: themeProvider.palette.primary.main,
                  fontSize: "22px",
                  display: "flex",
                  justifyContent: "center",
                  whiteSpace: "nowrap",
                }}
              >
                {region}
              </Typography>
              <Box
                sx={{
                  height: "1px",
                  backgroundColor: "#ccc",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  marginBottom: "3px",
                }}
              />
            </Box>
          )}
          {favoriteNodes?.length > 0 && (
            <NodePanel
              title="Favorites"
              items={favoriteNodes}
              collapsed={collapsedPanels["Favorites"]}
              onToggle={() => togglePanel("Favorites")}
              onDragStart={onDragStart}
              toggleFavorite={toggleFavorite}
              favoriteNodes={favoriteNodes}
              groupedNodes={groupedNodes}
              properties={properties}
              className="draggable-item"
            />
          )}

          {Object.keys(groupedNodes).map((category) => (
            <NodePanel
              key={category}
              title={category}
              items={groupedNodes[category]}
              collapsed={collapsedPanels[category]}
              onToggle={() => togglePanel(category)}
              onDragStart={onDragStart}
              toggleFavorite={toggleFavorite}
              favoriteNodes={favoriteNodes}
              groupedNodes={groupedNodes}
              properties={properties}
              className="draggable-item"
            />
          ))}
        </div>
      </aside>
      <div
        style={{
          backgroundColor: themeProvider.palette.bg.lightWhite,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SidebarCollapse
          showmenudsk={showmenudsk}
          setShowdskmenu={setShowdskmenu}
          direction={isSmallScreen ? 2 : 0}
          handleMenushwDskButtonClick={handleMenushwDskButtonClick}
        />
      </div>
    </ThemeProvider>
  );
};

export default DesktopSidebar;