import React, { useState, useEffect, useContext,  } from "react";
import { SplitScreen } from "./SplitScreen";
import CardContentData from "./CardContentData";
import { CircularProgress, Grid } from "@mui/material";
import SelectorData from "./SelectorData";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



function CreateCards({
  handleDetails,
  handleEditDiagram,
  searchQuery,
  caller,
  handleClick,
  handleCreate,
  isMenuOpenleft,
  setOpenCreateInfo,
  openCreateInfo,
  setOpenViewCreateInfo,
  openViewCreateInfo,
  openDiscoverPage,
  setOpenDiscoverPage,
  authData,
}) {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  let APIProvider = window.APIProvider;

const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`

  useEffect(() => {
    async function fetchData() {
      const scriptElement = document.createElement("script");
      scriptElement.src = `FormJson.js?v=${Date.now()}`;

      scriptElement.onload = () => {
        const fetchedData = window.MyVar || [];
        setData(fetchedData);

        const extractedData = fetchedData.map((item) => ({
          Name: item.Name,
          Explanation: item.Explanation,
          ArID: item.ArID,
          DiagID: item.DiagID,
        }));

        setSelected(extractedData);
        setFetched(true);
        setLoading(false);
      };

      document.head.appendChild(scriptElement);
    }

    if (!fetched) {
      fetchData();
    }
  }, [fetched]);

  
  const navigate = useNavigate();

  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
    }
  }, [authData, navigate]);

  async function GetQuickInfo(params) {
    try {
      const response = await fetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
  
      if (response.ok) {
        const resultData = await response.json();
  
        if (resultData.apiResponse) {
          const apiData = JSON.parse(resultData.apiResponse);
          setApiData(apiData);
          selected
            .flat()
            .filter(
              (item) => !apiData.some((apiItem) => apiItem.ArID === item.ArID)
            );
        } else {
          console.error("Error: apiResponse is missing");
        }
      } else {
        const errorText = await response.text();
        console.error(`Error fetching data: ${response.status} - ${response.statusText}`, errorText);
        // toast.error(`Error fetching data: ${response.statusText} - ${errorText}`);
      }
    } catch (error) {
      console.error("Error during request:", error);
      toast.error("Error during request.");
    }
  }
  
  
  

  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    const params = {
      accessKey: authData.accessKey ,
      guid:APIProvider.GUID.ReadArrayAllGUID,
      param1: "", 
      param2: "",
      param3: "",
      param4: "",
      recordSet: "", 
      body: "", 
    };
  
    const fetchData = async () => {
      setLoading(true);
      try {
        
        const data = await GetQuickInfo(params);
       
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };
  
      fetchData();

  }, [fetched]);
  



  const itemsToDisplay = selected
  .flat()
  .filter(section => 
    section.ArrayName && section.ArrayName.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .sort((a, b) =>
    a.IsTemplate !== b.IsTemplate ? (a.IsTemplate ? 1 : -1) : a.ArID - b.ArID
  );

  const handleDelete = (arIDToDelete) => {
    const updatedSelected = selected.filter(
      (item) => item.ArID !== arIDToDelete
    );
    setSelected(updatedSelected);

    const updatedApiData = apiData.filter((item) => item.ArID !== arIDToDelete);
    setApiData(updatedApiData);
  };

  const handleEditArray = async (
    ArID,
    editedArrayName,
    editedDescription,
    editedCategory,
    editeddataSourceArID
  ) => {

    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
    
    try {
      // const response = await fetch(
      //   // APIProvider.baseURL + APIProvider.recordPath.EditArray,
      //   // `https://aicloud.agiline.com:7352/EditArray`,
      //   {
      //     method: "PUT",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       ArID: ArID,
      //       ArrayName: editedArrayName,
      //       Description: editedDescription,
      //       Category: editedCategory,
      //       IsTemplate: "0",
      //       DataSourceArID: editeddataSourceArID,
      //     }),
      //   }
      // );
      const requestBody = {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.EditArrayGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: JSON.stringify({
          ArID: ArID,
          ArrayName: editedArrayName,
          Description: editedDescription,
          Category: editedCategory,
          IsTemplate: "0",
          DataSourceArID: editeddataSourceArID,
        }),
      };
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        const updatedData = data.map((item) =>
          item.ArID === ArID
            ? {
                ...item,
                ArrayName: editedArrayName,
                Description: editedDescription,
                Category: editedCategory,
                DataSourceArID: editeddataSourceArID,
              }
            : item
        );
        setData(updatedData);

        const updatedSelected = selected.map((section) => {
          if (Array.isArray(section)) {
            return section.map((item) =>
              item.ArID === ArID
                ? {
                    ...item,
                    ArrayName: editedArrayName,
                    Description: editedDescription,
                    Category: editedCategory,
                    DataSourceArID: editeddataSourceArID,
                  }
                : item
            );
          } else if (section && typeof section === "object") {
            return {
              ...section,
              ArrayName:
                section.ArID === ArID ? editedArrayName : section.ArrayName,
              Description:
                section.ArID === ArID ? editedDescription : section.Description,
              Category:
                section.ArID === ArID ? editedCategory : section.Category,
              DataSourceArID:
                section.ArID === ArID
                  ? editeddataSourceArID
                  : section.DataSourceArID,
            };
          } else {
            return section;
          }
        });

        setSelected(updatedSelected);
      } else {
        console.error("Failed to edit item.");
        toast.error("Failed to edit item.");
      }
    } catch (error) {
      console.error("Error editing item:", error);
      toast.error("Error editing item:");
    }
  };


  return (
    <>
      <SplitScreen
        leftWeight={1}
        rightWeight={3}
        isMenuOpenleft={isMenuOpenleft}
      >
        <SelectorData
          apiData={fetched ? apiData : []}
          selected={fetched ? selected : []}
          setSelected={setSelected}
          handleDelete={handleDelete}
        />
        
        {loading ? (
          <CircularProgress size={48} color="success"
          style={{
            position: 'relative',
            minHeight: '100vh',
            display: 'flex', 
            alignItems: 'center',
            justifyContent:'center',
            marginLeft:'auto',
            marginRight:'auto'
           }}
          />
         ) : (
          <Grid sx={{ flexGrow: 1 }} container padding={1}>
            {itemsToDisplay.map((item, index) => (
          <CardContentData
          key={index}
                data={item}
                size={325}
                textSize={17}
                isMobile={window.innerWidth < 750 ? true : false}
                handleDetails={handleDetails}
                handleEditDiagram={handleEditDiagram}
                // caller={caller}
                ArrayName={item.ArrayName}
                Description={item.Description}
                Category={item.Category}
                DataSourceArID={item.DataSourceArID}
                ArID={item.ArID}
                handleDelete={handleDelete}
                handleEditArray={handleEditArray}
                isTemplate={item.IsTemplate}
                handleClick={handleClick}
                handleCreate={handleCreate}
                setSelected={setSelected}
                setOpenCreateInfo={setOpenCreateInfo}
                openCreateInfo={openCreateInfo}
                setOpenViewCreateInfo={setOpenViewCreateInfo}
                openViewCreateInfo={openViewCreateInfo}
                openDiscoverPage={openDiscoverPage}
                setOpenDiscoverPage={setOpenDiscoverPage}
                //   isDeleteConfirmation={isDeleteConfirmation}
                // DeleteConfirmation={DeleteConfirmation}
                apiData={apiData}
                authData={authData}
              />
            ))}
          </Grid>
        )}
        
      </SplitScreen>

      <ToastContainer position="bottom-right" autoClose={3000} />
    </>
  );
}

export default CreateCards;