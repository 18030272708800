import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";
import { Theme } from "../Components/IncludeFile/Theme";

const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const Layout = ({
  matchedStyles,
  setDefaultStyleState,
  updateDefaultStyle,
  flexDirection,
  setFlexDirection,
  alignItems,
  setAlignItems,
  alignContent,
  setAlignContent,
  justifyItems,
  setJustifyItems,
  justifyContent,
  setJustifyContent,
  defaultStyle,
  setDefaultStyle,
  styleType,

}) => {



  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );

  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: {
              ...style.style,
              flexDirection: flexDirection,
              alignItems: alignItems,
              alignContent: alignContent,
              justifyItems: justifyItems,
              justifyContent: justifyContent,
            },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
  
      // setDefaultStyleState(updatedStyle);
    }
  }, [
    flexDirection,
    alignItems,
    alignContent,
    justifyItems,
    justifyContent,
    styleType,
  ]);

  const handleRadioChangeflex = (value) => {
    setFlexDirection(value);
  };

  const handleAlignItemContent = (value) => {
    setAlignContent(value);
    setAlignItems(value);
  };

  const handleJustifyItemContent = (value) => {
    setJustifyItems(value);
    setJustifyContent(value);
  };

  return (
    <StyledAlignItems className="bor-remove-right-edit">
      <div>
        <PropertiesAccordion title={"Layout"}>
          <div className="laout lauot-edit">
            <div className="vrtical">
              <div>
                <input
                  type="radio"
                  value="column"
                  checked={flexDirection === "column"}
                  onChange={() => handleRadioChangeflex("column")}
                />
                Vertical
              </div>
            </div>
            <div className="horizlt lauot-edit">
              <div>
                <input
                  type="radio"
                  value="row"
                  checked={flexDirection === "row"}
                  onChange={() => handleRadioChangeflex("row")}
                />
                Horizontal
              </div>
            </div>
          </div>
          <br />
          {flexDirection === "column" && (
            <>
          <div className="rightalinitm lauot-edit">
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("flex-start");
                }}
                className={
                  alignItems === "flex-start"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.HorizontalLeft, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Left</span>
              </button>
            </div>

            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("center");
                }}
                className={
                  alignItems === "center" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.HorizontalCenter, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Center</span>
              </button>
            </div>

            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("flex-end");
                }}
                className={
                  alignItems === "flex-end" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.HorizontalRight, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>

                <span className="tooltiptext">Right</span>
              </button>
            </div>

            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("stretch");
                }}
                className={
                  alignItems === "stretch" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                {" "}
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.ViewColumn, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Stretch</span>
              </button>
            </div>

            <br />
          </div>
          <div className="rightalinitmdown lauot-edit">
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleJustifyItemContent("flex-start");
                }}
                className={
                  justifyItems === "flex-start"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.VerticalTop, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Top</span>
              </button>
            </div>
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleJustifyItemContent("center");
                }}
                className={
                  justifyItems === "center" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {" "}
                  {React.cloneElement(Theme.VerticalCenter, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Middle</span>
              </button>
            </div>
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleJustifyItemContent("flex-end");
                }}
                className={
                  justifyItems === "flex-end"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                {" "}
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.VerticalBottom, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Bottom</span>
              </button>
            </div>
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleJustifyItemContent("space-between");
                }}
                className={
                  justifyItems === "space-between"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.RoadSharp, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Distribute</span>
              </button>
            </div>
            <br />
          </div>
          </>
          )}
          {flexDirection === "row" && (
            <>
          <div className="rightalinitm lauot-edit">
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("flex-start");
                }}
                className={
                  alignItems === "flex-start"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.VerticalTop, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Top</span>
              </button>
            </div>

            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("center");
                }}
                className={
                  alignItems === "center" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.VerticalCenter, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Middle</span>
              </button>
            </div>

            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("flex-end");
                }}
                className={
                  alignItems === "flex-end" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.VerticalBottom, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>

                <span className="tooltiptext">Bottom</span>
              </button>
            </div>

            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAlignItemContent("stretch");
                }}
                className={
                  alignItems === "stretch" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                {" "}
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.RoadSharp, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Stretch</span>
              </button>
            </div>

            <br />
          </div>
          <div className="rightalinitmdown lauot-edit">
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleJustifyItemContent("flex-start");
                }}
                className={
                  justifyItems === "flex-start"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.HorizontalLeft, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Left</span>
              </button>
            </div>
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleJustifyItemContent("center");
                }}
                className={
                  justifyItems === "center" ? "iconbtnflx-active" : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {" "}
                  {React.cloneElement(Theme.HorizontalCenter, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Center</span>
              </button>
            </div>
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleJustifyItemContent("flex-end");
                }}
                className={
                  justifyItems === "flex-end"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                {" "}
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.HorizontalRight, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Right</span>
              </button>
            </div>
            <div className="iconsetmiddle icon-edit-cont">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleJustifyItemContent("space-between");
                }}
                className={
                  justifyItems === "space-between"
                    ? "iconbtnflx-active"
                    : "iconbtnflx"
                }
              >
                <div className="icon-small layout-icon">
                  {React.cloneElement(Theme.ViewColumn, {
                    style: {
                      width: "16px",
                      height: "16px",
                    },
                  })}
                </div>
                <span className="tooltiptext">Distribute</span>
              </button>
            </div>
            <br />
          </div>
          </>
          )}
        </PropertiesAccordion>
      </div>
    </StyledAlignItems>
  );
};

export default Layout;
