import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import themeProvider from "../../ThemeProvider";
import { ToastContainer, toast } from "react-toastify";
import NavBar from "../../Components/navbarComponents/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";


import {
  DataGrid,

  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { Theme } from "../../Components/IncludeFile/Theme";
import { ReadArray } from "../../Components/Api/ApiCalls";
import { loadLoginData } from "../../apiCalls/DiagramsAPI";

const EditControls = () => {
  const navigate = useNavigate();
  // let APIProvider = window.APIProvider;

  const location = useLocation();
  const authData = loadLoginData();


  const accessKey = authData.accessKey

  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(`/Landing`);
      },
    },
  ];

  const [selectedNodes, setSelectedNodes] = useState([]);

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const [defaultStyle, setDefaultStyle] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = await ReadArray(10, accessKey);

        const fetchedData = jsonData[0].Source
   

        const styledDataWithIds = fetchedData.map((style, index) => ({
          id: index + 1,
          ...style,
        }));

        setDefaultStyle(styledDataWithIds);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching source data:", error);
      }
    };
    fetchData();
  }, []);

  console.log("defaultStyle",defaultStyle)

  const filteredRows = defaultStyle
  .filter((style) => {
    const name = style.styleType.toLowerCase();
    return (
      name.includes(searchQuery.toLowerCase()) &&
      name !== "tablelabel" &&
      name !== "radiocontainer" &&
      name !== "tableheader" &&
      name !== "tablehead"
    );
  })
  .sort((a, b) => {
    const nameA = a.styleType.toLowerCase();
    const nameB = b.styleType.toLowerCase();
    return nameA.localeCompare(nameB); 
  })
  .map((style) => ({
    id: style.styleType,
    name: style.styleType,
    style: style.style || {},
  }));


  const [isSmallScreen, setIsSmallScreen] = useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    { field: "name", headerName: "Name", editable: true, flex: 1 },
  ];


  const handleNavigateToStyles = () => {
    navigate("/Styles", { state: { selectedNodes} });
  };

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    if (newRowSelectionModel.length > 1) {
      toast.error("Please select only one control.");
    } else {
      setSelectedNodes(newRowSelectionModel);
    }
  };
  const [loading, setLoading] = useState(true);

  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        theme="colored"
      />
      <NavBar
        isSearchBar={true}
        rightButtons={rightButtons}
        title="Choose Controls"
        isMenuOpenleft={false}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
      />
      {loading ? (
        <CircularProgress
          size={48}
          color="success"
          style={{
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      ) : (
        <div style={{ height: "94vh", overflowX: "auto" }}>
          <Box
            sx={{
              width: "100%",
              padding: isSmallScreen ? "1rem" : "1rem 2rem",
            }}
          >
            <DataGrid
              rows={filteredRows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection
              rowSelectionModel={selectedNodes}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              disableRowSelectionOnClick
              sx={{
                "& .MuiDataGrid-root": {
                  width: "100%",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  color: themeProvider.palette.primary.dark,
                },
              }}
            />
          </Box>
          <div
            style={{
              margin: "1rem",
              display: "flex",
            }}
          >
            <div className="control-property-btn">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={selectedNodes.length === 0}
                onClick={handleNavigateToStyles}
              >
                Edit Controls Properties
              </Button>
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default EditControls;